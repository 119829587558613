import axios from './axios'

export const LaboratoryService = {
  postLaboratory(postData) {
    return axios.post('/laboratories', postData).then(resp => resp.data)
  },
  putLaboratory(laboratoryId, postData) {
    return axios
      .put(`/laboratories/${laboratoryId}`, postData)
      .then(resp => resp.data)
  },
  postDraftLaboratory(laboratoryId, postData) {
    return axios.post(`/laboratories/${laboratoryId}/draft`, postData)
  },
  postPublishLaboratory(laboratoryId, postData) {
    return axios.post(`/laboratories/${laboratoryId}/publish`, postData)
  },
  postPublishLaboratoryFromList(laboratoryId) {
    return axios.post(`/laboratories/${laboratoryId}/publish/action`)
  },
  postUnpublishLaboratory(laboratoryId) {
    return axios.post(`/laboratories/${laboratoryId}/unpublish`)
  },
  getTypeLaboratoryList() {
    return axios.get('/typeLaboratory').then(resp => resp.data)
  },
  getAllMine() {
    return axios.get('/laboratories/user')
  },
  getAll({ pageSize, pageNumber, filter }, requestOptions) {
    return axios.get('/laboratories', {
      params: {
        page: pageNumber,
        size: pageSize,
        ...filter
      },
      ...{ requestOptions }
    })
  },
  // getLaboratoryListPubished(id) {
  //   return axios.get(`laboratories/${id}/published`);
  // },
  getById(id, isPublished) {
    if (isPublished) {
      return axios.get(`/laboratories/${id}/?published=true`)
    } else {
      return axios.get('/laboratories/' + id)
    }
  },
  getChangelog(id) {
    return axios.get(`/laboratories/${id}/changelog`)
  },
  getByIdPublic(id) {
    return axios.get('/assets/laboratory/' + id).then(resp => resp.data)
  },
  getOrganizationalEstructure(laboratoryId) {
    return axios.get(`organizationalStructure/laboratory/${laboratoryId}`)
  },
  verifyLaboratory(verifyParams) {
    return axios.get('/laboratories/exists', { params: verifyParams })
  },
  postApproval(laboratoryId) {
    return axios.post(`/laboratories/${laboratoryId}/approve`)
  },
  canDelete(laboratoryId) {
    return axios.get(`laboratories/${laboratoryId}/can-delete`)
  },
  deleteLaboratoryById(laboratoryId) {
    return axios.delete(`/laboratories/${laboratoryId}`)
  },
  deleteLaboratory(laboratoryId, description) {
    return axios.delete(`/laboratories/${laboratoryId}`, {
      data: { description }
    })
  },
  postEditApproval(laboratoryId) {
    return axios.post(`/laboratories/${laboratoryId}/approveEdit`)
  },
  postRefusedEdit(laboratoryId, postData) {
    return axios.post(`/laboratories/${laboratoryId}/refusedEdit`, postData)
  },
  postRefusal(laboratoryId, postData) {
    return axios.post(`/laboratories/${laboratoryId}/refuse`, postData)
  },
  uploadRepresentativeDocument(laboratoryId, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(
      `/laboratories/${laboratoryId}/document/representative`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  removeRepresentativeDocument(documentId) {
    return axios.delete(`/laboratories/document/${documentId}`)
  },
  uploadRefusalDocument(laboratoryId, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(
      `/laboratories/${laboratoryId}/document/representative`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  uploadRefusalDocumentList(laboratoryId, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(
      `/laboratories/${laboratoryId}/document/refuse`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  uploadLaboratoryImage(laboratoryId, file) {
    var formData = new FormData()

    formData.append('cropImage', file.cropImage)
    formData.append('image', file.image)
    formData.append('positionX', file.positionX)
    formData.append('positionY', file.positionY)

    return axios.post(`/laboratories/${laboratoryId}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updateLaboratoryImage(file) {
    const idFile = file.image.id

    var formData = new FormData()
    formData.append('cropImage', file.cropImage)
    formData.append('positionX', file.positionX)
    formData.append('positionY', file.positionY)

    return axios.post(`/laboratories/image/${idFile}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  removeImage(imageId) {
    return axios.delete(`/laboratories/image/${imageId}`)
  },
  getRefuseReasons() {
    return axios.get('/reason-refuse-laboratory').then(resp => resp.data)
  },
  getFiltersSitutation() {
    return axios.get('laboratories/filter')
  },
  getResponsibles(laboratoryId) {
    return axios
      .get(`laboratories/${laboratoryId}/responsibles`)
      .then(resp => resp.data)
  },
  getResponsibleByCpf(laboratoryId, value) {
    return axios.get(`laboratories/${laboratoryId}/cpf?cpf=${value}`)
  },
  getResponsibleByName(laboratoryId, value) {
    return axios.get(`laboratories/${laboratoryId}/name?name=${value}`)
  },
  postRepresentativeList(laboratoryId, data) {
    return axios.post(`/laboratories/${laboratoryId}/representative-list`, data)
  },
  getEmailAlreadySent(id, email) {
    return axios.get('/laboratories/verify/', {
      params: { id, email }
    })
  },

  getEquipmentListByLaboratory(id, pageSize, pageNumber) {
    return axios.get(`laboratories/${id}/equipments`, {
      params: {
        page: pageNumber,
        size: pageSize
      }
    })
  },

  getEquipmentListByLaboratoryPublic(id, pageSize, pageNumber) {
    return axios
      .get(`assets/laboratory/${id}/equipments`, {
        params: {
          page: pageNumber,
          size: pageSize
        }
      })
      .then(resp => resp.data)
  },

  uploadTermsOfUse(laboratoryId, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(
      `/laboratories/${laboratoryId}/document/terms-of-use`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  getLabByCnpj(cnpj, initials) {
    return axios
      .get('/laboratories/exists', {
        params: {
          cnpj,
          initials
        }
      })
      .then(resp => resp.data)
  },
  reactiveLaboratory(id, payload) {
    return axios
      .post(`/laboratories/${id}/reactive`, payload)
      .then(resp => resp.data)
  }
}
export default LaboratoryService
