import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  margin-bottom: 0.4rem;
  font-size: 14px;
  font-weight: 600;
`

export const DocumentWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 2px;
  height: 5rem;
  margin-bottom: 16px;
  width: 100%;
`

export const Content = styled.div`
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
`

export const Text = styled.span`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  flex-grow: 1;
  flex-shrink: 1;
  word-break: break-all;
  padding-right: 20px;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 1;

  & img:not(:last-child) {
    margin-right: 10px;
  }
`

export const EyeIcon = styled.img`
  cursor: pointer;
  height: 16px;
  &:hover {
    filter: invert(37%) sepia(86%) saturate(827%) hue-rotate(131deg)
      brightness(97%) contrast(101%);
  }
`

export const TrashIcon = styled.img`
  cursor: pointer;
  height: 20px;
  &:hover {
    filter: invert(37%) sepia(86%) saturate(827%) hue-rotate(131deg)
      brightness(97%) contrast(101%);
  }
`
