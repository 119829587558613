import React, { useContext, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'

import { GenericNotification } from './GenericNotification'

const InviteUserAdminCmp = ({ notification, ...rest }) => {
  return null;
  // const { translate } = useContext(GlobalizationContext)

  // const messageContent = useMemo(() => {
  //   return (<>
  //     <Trans t={translate} i18nKey='INVITE_USER_ADMIN' values={notification} />
  //     <ul>
  //       {
          
  //       }
  //       <li></li>
  //     </ul>
  //   </>)
  // }, [notification, translate])

  // return (
  //   <GenericNotification
  //     titleKey="INVITE_USER_ADMIN_TITLE"
  //     message={messageContent}
  //     {...rest}
  //   />
  // )
}

const InviteUserAdmin = wrapper(InviteUserAdminCmp, {
  namespace: 'NOTIFICATIONS'
})

export { InviteUserAdmin }
