import { mountPrivatePath } from 'helpers'

import InstitutionList from './Subviews/Institution'
import InstitutionAnalysis from './Subviews/InstitutionAnalysis'
import InstitutionEdit from './Subviews/InstitutionEdit'
import LaboratoryList from './Subviews/Laboratory'
import LaboratoryEdit from './Subviews/LaboratoryResponsibleManagement'
import UserList from './Subviews/User'
import UserEdit from './Subviews/UserEdit'

import { ADMIN_ROUTES } from './constants/routes'

export const ROUTES = [
  { path: mountPrivatePath(ADMIN_ROUTES.USERS), component: UserList },
  { path: mountPrivatePath(ADMIN_ROUTES.USERS_EDIT), component: UserEdit },
  {
    path: mountPrivatePath(ADMIN_ROUTES.INSTITUTIONS),
    component: InstitutionList
  },
  {
    path: mountPrivatePath(ADMIN_ROUTES.INSTITUTIONS_EDIT),
    component: InstitutionEdit
  },
  {
    path: mountPrivatePath(ADMIN_ROUTES.INSTITUTION_ANALYSIS),
    component: InstitutionAnalysis
  },
  {
    path: mountPrivatePath(ADMIN_ROUTES.LABORATORIES),
    component: LaboratoryList
  },
  {
    path: mountPrivatePath(ADMIN_ROUTES.LABORATORIES_EDIT),
    component: LaboratoryEdit
  }
]
