export default {
  COMMONS: {
    CANCEL: 'Cancel',
    SAVE: 'Save',
    CLOSE: 'Fechar',
    DELETE: 'Excluir',
    GOBACK: 'Voltar',
    ADD: 'Adicionar',

    YES: 'Yes',
    NO: 'No',

    OK: 'Ok',

    ACTIONS: 'Actions',
    NO_RESULTS: 'No record found',

    ACTIVE: 'Active',
    INACTIVE: 'Inactive',

    UPDATED: 'Updated',
    OUT_OF_DATE: 'Out of date',
    UPDATE_NEEDED: 'Update needed',

    LABORATORIO: 'Laboratory',
    INSTITUICAO: 'Institution',

    CREATION_DATE: 'Creation date',
    LAST_UPDATED: 'Last updated',

    DEFAULT_SUCCESS: 'Operation has completed successfully!',

    REPRESENTATIVE_LIST: {
      ARIA_LABEL: 'Responsible table'
    },

    LANGUAGES: {
      PT: 'Portuguese - BR',
      EN: 'English'
    }
  },
  REGIONS: {
    MIDWEST: 'Centro-oeste',
    NORTHEAST: 'Nordeste',
    NORTH: 'Norte',
    SOUTHEAST: 'Sudeste',
    SOUTH: 'Sul'
  },
  STATES: {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins',
    DF: 'Distrito Federal'
  },
  VALIDATION: {
    REQUIRED_ERROR: 'Required',
    EMAIL_ERROR: 'Invalid e-mail',
    CPF_ERROR: 'Invalid CPF',
    CNPJ_ERROR: 'Invalid company number',
    INVALID_DATE: 'Invalid date',
    PHONE_ERROR: 'Telefone inválido.',

    PASSWORD_CONTAINS_EMAIL_OR_NAME: 'Password cannot contains email or name',
    PASSWORD_MUST_MATCH: 'Password must match',
    FORMAT_PASSWORD: 'Invalid password format',
    INITIALS_ALREADY_TAKEN: 'Initials already taken',
    NAME_ALREADY_TAKEN: 'Name already taken',
    INITIALS_AWAITING: 'Waiting approval. Please, wait.',
    NAME_AWAITING: 'Waiting approval. Please, wait.',
    NAME_AWAITING_VALIDATION:
      'Laboratory has pending Analysis request. Please wait for the return',
    ZIPCODE_NOT_FOUND: 'Postal code not found',
    ZIPCODE_INVALID: 'Invalid postal code',
    FORM_NEEDS_SAVING: 'Save the form to persist the changes.'
  },
  NOTIFICATIONS: {
    PERMISSION: {
      ACTIVE: 'Activate',
      INACTIVE: 'Inactivate'
    },
    OK: 'Ok',
    COMPLEMENT: 'Complement',
    NOTIFICATION_EMPTY_LIST: 'There are no notifications at this moment',
    NOTIFICATION_ACCEPT: 'Accept',
    NOTIFICATION_REJECT: 'Reject',

    INVITE_INSTITUTION:
      'An invitation to integrate the management of the institution <b>{{institution}}</b> awaits your approval',
    CREATE_LAB: 'Request for inclusion of laboratory awaits your approval',
    MODIFICATION_LAB: 'Request for laboratory edition awaits your approval',
    INVITE_LABORATORY:
      '{{author}} invited him to compose the management of the Laboratory: <b>{{laboratory}}.</b> ',

    COMPLEMENT_INSTITUTION:
      'Complement the institution information, to add a new responsible or add the address inn the menu <strong>“Institution</strong>',
    USER_REMOVED_OWN_INSTITUTION_PERMISSION:
      'The user <strong>{{userName}}</strong> has removed the <strong>{{permission}}</strong> responsible permission from their own assignments, therefore he is no longer part of the management of the institution <strong>{{institutionName}}.</strong>',
    USER_PERMISSION_INSTITUTION_REMOVED:
      'Your permission as responsible for the institution <strong>{{institutionName}}</strong> has been revoked',
    USER_PERMISSION_INSTITUTION_CHANGED:
      'Your permission as responsible for the institution <strong>{{institutionName}}</strong> was changed to responsible <strong>{{permission}}</strong>',
    USER_SITUATION_INSTITUTION_CHANGED:
      'Your permission as responsible <strong>{{permission}}</strong> for the institution <strong>{{institutionName}}</strong> was <strong>{{situation}}</strong>',
    COMPLEMENT_LABORATORY:
      'Your laboratory <strong>{{laboratoryName}}</strong> has been approved and is awaiting publication. Edit your laboratory page and start registering your equipment to publish it on the platform.',
    USER_REMOVED_OWN_LABORATORY_PERMISSION:
      'The user <strong>{{userName}}</strong> has removed the <strong>{{permission}}</strong> responsible permission from their own assignments, therefore he is no longer part of the management of the laboratory <strong>{{laboratoryName}}</strong>.',
    USER_PERMISSION_LABORATORY_REMOVED:
      'Your permission as responsible for the laboratory <strong>{{laboratoryName}}</strong> has been revoked',
    USER_PERMISSION_LABORATORY_CHANGED:
      'Your permission as responsible for the laboratory <strong>{{laboratoryName}}</strong> was changed to responsible <strong>{{permission}}</strong>',
    USER_LABORATORY_SITUATION_CHANGED:
      'Your permission as responsible <strong>{{permission}}</strong> for the laboratory <strong>{{laboratoryName}}</strong> was <strong>{{situation}}</strong>.'
  },
  ERRORS: {
    DEFAULT_ERROR:
      'The operation could not be performed. If the problem persists, contact your system administrator.',
    INVALID_USER_SITUATION: 'The user is inactive in this platform.',
    USER_LABORATORY_DELETE_ERROR:
      'This user is a laboratory main responsible, for this reason he could not be deleted.',
    USER_LABORATORY_INACTIVATE_ERROR:
      'This user is a laboratory main responsible, for this reason he could not be inactivated.',
    USER_INSTITUTION_DELETE_ERROR:
      'This user is an institution main responsible, for this reason he could not be deleted.',
    USER_INSTITUTION_INACTIVATE_ERROR:
      'This user is an institution main responsible, for this reason he could not be inactivated.',
    USER_ADMINISTRATOR_INACTIVATE_ERROR:
      'For being the only one system administrator, the user cannot be inactivate',
    USER_ADMINISTRATOR_DELETED_ERROR:
      'For being the only one system administrator, the user cannot be deleted',
    EXISTING_EMAIL_USER_ERROR: 'Email already used on the platform.',
    SPECIAL_CHARS: `The following special characters are not allowed <>"{}&@+`
  },
  EQUIPMENT: {
    SITUATION: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
      UPDATED: 'Updated',
      OUT_OF_DATE: 'Out of date',
      UPDATE_NEEDED: 'Update needed',
      REPLICATED: 'Replicated'
    }
  },

  ADMIN_EDIT_USER: {
    TITLE: 'Manage user',
    USER_DATA_TITLE: 'Register data',
    PENDING_INVITES_TITLE: 'Pending invites',
    NAME: 'Name',
    LAST_NAME: 'Last name',
    EMAIL: 'E-mail',
    CPF: 'CPF',
    PERMISSIONS_TITLE: 'User permissions',
    INSTITUTION: 'Link unit',
    INSTITUTION_TYPE: 'Type',
    PERMISSION: 'Permission',
    STATUS: 'Situation',

    LABORATORIO: 'Laboratory',
    INSTITUICAO: 'Institution',
    INTEGRANTE: 'Member',

    MAIN_RESPONSIBLE: 'Main responsible',
    ONLY_RESPONSIBLE: 'Only responsible',

    TOTAL: 'Total',
    PARTIAL: 'Partial',
    PARCIAL: 'Partial',
    LIMITED: 'Limited',
    LIMITADO: 'Limited',
    LIMITADA: 'Limited',

    NO_PERMISSIONS: 'No permissions',

    PENDING_INVITES_INSTITUTION: 'Link unit',
    PENDING_INVITES_PERMISSION: 'Permission',
    PENDING_INVITES_DATE: 'Pending invites data',
    PENDING_INVITES_ACTION: 'Resend',
    PENDING_INVITES_NO_INVITES: 'There is no pending invite at moment.'
  },
  ADDRESS: {
    TITLE: 'Address',
    ZIP_CODE: 'Zip code',
    STREET: 'Street',
    NUMBER: 'Number',
    COMPLEMENT: 'Complement',
    DISTRICT: 'District',
    CITY: 'City',
    STATE_SELECT: 'Select a state',
    INITIALS: 'State',
    REGION: 'Region',
    REGION_SELECT: 'Select a region',
    ZIP_CODE_MASK: 'XXXXX-XXX'
  },
  EQUIPMENT_REGISTER_DATA: {
    DATA: 'Equipment info',
    CODE: 'Equipment code on the platform',
    LABORATORY_LIST_DEFAULT: 'Laboratory',
    LABORATORY_LIST_LABEL: 'Laboratory',
    SITUATION_LIST_DEFAULT: 'Choose',
    SITUATION_LIST_LABEL: 'Equipment situation',
    OTHER_REASON: 'Inactivation reason',
    NAME: 'Equipment name',
    NAME_INITIALS: 'Equipment initials',
    OTHER_DENOMINATION_LABEL: 'Other equipment names',
    OTHER_DENOMINATION_PLACEHOLDER: 'Add...',
    OTHER_DENOMINATION_HINT:
      'Inform other names that the equipment might have. Inform also its name in English.',
    TYPE_IDENTIFIER: 'Type of persistent identifier',
    IDENTIFIER: 'Persistent identifier number',
    TYPE_IDENTIFIER_HINT: 'Ex. ISBN, ISSN, DOI...',
    IDENTIFIER_HINT: 'Universal identifier of the equipment',
    BRAND: 'Brand',
    MODEL: 'Model',
    YEAR: 'Year of acquisition',
    MANUFACTURER: 'Manufacturer',
    LINK: 'Equipment Link at the manufacturer’s website',
    USAGE_PROFILE: 'Equipment usage profile',
    MULTIUSER_LABEL: 'Multiuser',
    MULTIUSER_HINT:
      'Percentage of equipment workload (e.g. weekly, monthly, etc.) that can be available to external users (under established conditions for each case).',
    SLIDER_LABEL: 'Dedicated to the laboratory’s internal projects',
    SLIDER_LIMIT_MESSAGE: 'The sum of the percentages cannot exceed 100%',
    SLIDER_HINT:
      'Percentage of equipment workload (e.g. weekly, monthly, etc.) dedicated to the laboratory’s internal projects.'
  },
  SEARCH: {
    SEARCH_TYPE_LAB: 'Laboratories',
    SEARCH_TYPE_EQUIP: 'Equipments',
    INSTITUTION: 'Institution',
    LOCATION: 'Location',

    MULTI_USER: 'Multi-user equipment',
    MULTI_USER_HINT:
      'Equipamento de uso compartilhado para atividades de P&D, acessíveis a usuários não vinculados ao laboratório (mediante condições estabelecidas para cada caso).',
    CODE: 'Platform Code',
    LABORATORY: 'Laboratory',
    BRAND: 'Brand',
    MODEL: 'Model',

    VIEW: 'View'
  },
  HOME: {
    INSTITUTION: 'Institutions',
    LABORATORY: 'Laboratories',
    EQUIPMENTS: 'Equipments',
    LAB_SUBTITLE:
      'Confira as informações dos laboratórios cadastrados na plataforma',
    EQUIP_SUBTITLE:
      'Confira as informações dos equipamentos catalogados na plataforma.',
    INFO_SECTION_TITLE: 'About the initiative',
    INFO_SECTION_INTRO_TEXT:
      'The PNIPE MCTI aims to gather information about the research infrastructure in Scientific, Technological and Innovation Institutions (ICTs) in Brazil, enabling access to the scientific/technological community and companies, promoting their shared use.',
    BE_PART_BUTTON: 'Be Part!',
    SCIENCE_SECTION_TITLE: 'Science in focus',
    SCIENCE_SECTION_INTRO_TEXT:
      'PNIPE MCTI seeks to give visibility to the Brazilian infrastructure and optimize resources. If you are part of an institution or laboratory, see <Link>how to participate</Link>',
    SCIENCE_SECTION_SUPPORT_TO_SCIENCE_TITLE: 'Support for science',
    SCIENCE_SECTION_INFRAESTRUCTURE_TITLE: 'Infrastructure Catalog',
    SCIENCE_SECTION_COLLAB_TITLE: 'Collaboration',
    SCIENCE_SECTION_SUPPORT_TO_SCIENCE_TEXT:
      'With this initiative, the MCTI aims to obtain information from the research infrastructure to support public policies.',
    SCIENCE_SECTION_INFRAESTRUCTURE_TEXT:
      'Get to know the institutions, laboratories and equipment that make up the Brazilian research infrastructure.',
    SCIENCE_SECTION_COLLAB_TEXT:
      'Manage or request the sharing of multi-user equipment across the platform.',
    SEARCH_BUTTON: 'Search'
  },
  BANNER_COVID: {
    TITLE: 'SUPPORT THE NATIONAL OPERATING PLAN FOR COVID-19 VACCINATION',
    CONTENT: `MCTI provides the National Research Infrastructure Platform (PNIPE MCTI) to support the Ministry of Health's National Operating Plan for Covid-19 Vaccination`,
    HOW_TO_PARTICIPATE: 'More details'
  },
  CAMPAIGN_COVID: {
    TITLE:
      'PNIPE MCTI in support of the National Operating Plan for Covid-19 Vaccination',
    ABOUT_TITLE: 'The initiative',
    ABOUT: `
      In order to provide Ultra Low Temperature Freezers existing in the Institutions of Science and
      Technology (ICTs) of the Country, The Ministry of Science, Technology and Innovations, in
      partnership with the Ministry of Health, is carrying out the survey of this infrastructure to
      verify vaccine storage capacity for the National Operating Plan for Covid-19 Vaccination.
      <br/><br/>
      The Ministry of Science, Technology and Innovations will check the availability and provide the
      location of this equipment. Thus, the Ministry of Health will be able to consider them as
      strategic support in the distribution of vaccines for each region of the country.`,

    WHANT_TO_HELP_TITLE: 'I want to provide Ultra Low Temperature Freezers',
    WHANT_TO_HELP: `
      Include the equipment you want to provide for the campaign as follows:
      <br/>
      <strong>STEP 1</strong> Access the platform
      <br/>
      <strong>STEP 2</strong> Access the equipment page from the side menu
      <br/>
      <strong>STEP 3</strong> Click on “add covid Ultra Low Temperature Freezer” or “edit equipment” if the freezer is already registered on the platform.
      <br/>
      <strong>STEP 4</strong> Fill in the fields “other names” and “parameters” present in the form as shown in the illustrations below:
      <br/>
      <strong>IMPORTANT:</strong> Remember to publish your laboratory to make the equipment available.
    `,

    PROCEDURE_REGISTER_EQUIPMENT_TITLE: 'Filling in to register in the system',
    OTHER_DENOMINATION_LABEL: 'Outras denominações do equipamento',
    OTHER_DENOMINATION_PLACEHOLDER: 'Adicione...',
    OTHER_DENOMINATION_HINT:
      'Informe outros nomes que o equipamento é conhecido. Informe também seu nome em inglês.',
    PROCEDURE_REGISTER_NEW_EQUIPMENT_TITLE:
      'Filling in to register in the system (new equipment)',
    PARAMETERS: 'Parâmetros',
    SUBTITLE_TITLE: 'Subtitle',
    SUBTITLE: `
      <strong>#ultracongeladores-70covid</strong>: inform the minimum temperature in ºC as “value”. Consider freezers with minimum temperature from -70ºC.
        <br/>
      <strong>#ultracongeladordisponivel</strong>: inform in “value” the total capacity in liters of the freezer.
    `,
    UNAVAILABLE_FREEZERS_TITLE: 'Ultra Low Temperature Freezers unavailable',
    UNAVAILABLE_FREEZERS: `
      Ultra Low Temperature Freezers that are not available due to damage or lack of structure to be
      used can also be registered in the campaign as long as their status is indicated as “inactive” at
      the time of registration.
      <br/>
      For additional information, contact us via email: pnipe@mctic.gov.br
    `
  },
  COVID_CAMPAIN: {
    TITLE: 'Ultracongeladores disponíveis para o plano nacional de Vacinação'
  },
  ADVERTISE_PLATFORM: {
    TITLE: 'Disseminate the platform',
    SUBTITLE:
      'Envie ou reenvie um e-mail de divulgação para que outros laboratórios possam fazer parte da plataforma.',
    PREVIEW_EMAIL: 'Confira uma prévia do e-mail',
    NEW_INVITE_SUBTITLE: 'Invite new contact',
    LAB_NAME: 'Laboratory name',
    LAB_EMAIL: 'E-mail',
    LAB_RESPONSIBLE: 'Responsible name',
    SEND: 'Send',

    INVITE_DATE: 'Last sent',
    RESEND_INVITE: 'Resend e-mail',
    FILTER: 'Filter by laboratory',
    INVITE_AGAIN_SUBTITLE: 'Reinvite',

    INVITE_SUCCESS: 'E-mail sent successfully.',
    INVITE_ERROR:
      'This operation failed. If the problem persists, contact the system administrator.',
    DUPLICATED_INVITE_ERROR: 'An e-mail has been already sent to this user. ',
    INVITE_RESEND_SUCCESS: 'E-mail resent successfully'
  },

  LABORATORY_LIST: {
    // LIST LABORATORY
    FILTER_STATUS: 'Filter by',
    SEARCH_DEFAULT:
      'Search laboratory by name, acronym, area of operation, location...',
    COLUMNS: {
      LAB_NAME: 'Laboratory name',
      INITIALS: 'Initials',
      AREAS_EXPERTISE: 'Occupation area',
      STATE: 'Locality',
      STATUS: 'Status'
    },
    TITLE: 'Laboratories',
    LAB_NAME: 'Laboratory name',
    INITIALS: 'Initials',
    RESPONSIBLE: 'Responsible',
    SITUATION: 'Situation',
    NEW_LAB_BUTTON: 'New laboratory',

    ACTION: {
      PUBLISH: 'Publish',
      UNPUBLISH: 'Unpublish',
      EDIT: 'Edit',
      APPROVE: 'Analyze',
      EXCLUDE: 'Delete',
      APPROVE_EDIT: 'Approve changes'
    }
  },
  LABORATORY: {
    SITUATION: {
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
      DELETED: 'Rejected',
      REJECTED: 'Rejected',
      WAITING_APPROVAL: 'Waiting for approval',
      WAITING_PUBLICATION: 'Waiting for publication',
      PENDING: 'Pending',
      PUBLISHED: 'Published',
      UNPUBLISHED: 'Unpublished',
      WAITING_EDIT_APPROVAL: 'Awaiting changes approval',
      EDIT_REJECTED: 'Changes rejected'
    },
    CHANGELOG: {
      NAME: 'Laboratory name',
      INITIALS: 'Initials',
      ABOUT: 'About',
      CONTACTEMAIL: 'E-mail',
      WEBSITE: 'Website',
      ZIPCODE: 'Postal code',
      STREET: 'Street',
      COMPLEMENT: 'Additional details',
      DISTRICT: 'District',
      CITY: 'County',
      STATE: 'State',
      NUMBER: 'Number',
      REGION: 'Region',
      LATITUDE: 'Latitude',
      LONGITUDE: 'Longitude',
      INSTITUTION: 'Institution',
      RESPONSIBLE: 'Responsible',
      TECHNIQUELIST: 'Main techniques',
      AREASEXPERTISELIST: 'Fields of research',
      DOCUMENTLIST: 'Documents'
    }
  },

  LOGIN: {
    TITLE: 'Login',
    SUBTITLE:
      'Preencha os formulário abaixo. Em seguida enviaremos um e-mail para que validar o seu cadastro.',
    USER_NAME: 'CPF ou E-mail',
    PASSWORD: 'Senha',
    SIGN_IN: 'Entrar',
    NEEDS_HELP: 'Se precisar de ajuda contate o',
    NEEDS_HELP_LINK: 'suporte',
    FORGOT_PASSWORD: 'Esqueci a senha',
    SIGN_UP: 'Criar a conta'
  },

  PUBLIC_LABORATORY_DETAILS: {
    ABOUT_LAB: 'About this laboratory',
    ADRESS: 'Address',
    ZIP_CODE: 'Zip code',
    LOCATION: 'How to get there?',
    CONTACT: 'Contact',
    RESPONSIBLE: 'Responsible',
    TELEPHONE: 'Phone number',
    EMAIL: 'E-mail',
    WEBSITE: 'Website',
    AREA_EXPERTISE: 'Fields of research',
    TECHNIQUE: 'Main techniques'
  },

  PUBLIC_EQUIPMENT_DETAILS: {
    TECHINIQUE_LIST: 'Techniques used with this equipment:',
    CODE_PLATFORM: 'Equipment code on the platform',
    OTHER_DENOMANATION: 'Other equipment names',
    SITUATION: 'Equipment situation',
    ORIGIN: 'Equipment origin',
    ABOUT: 'About the equipment',
    DATA: 'Equipment info',
    BRAND: 'Brand',
    MODEL: 'Model',
    LINK: 'Equipment link on manufacturer site',
    PURCHASEYEAR: 'Purchase year',
    PARAMETER: 'Parameters',
    CONTACT: 'Contact',
    RESPONSIBLE: 'Responsible',
    TELEPHONE: 'Telephone',
    EMAIL: 'Email',
    WEBSITE: 'Website',
    MULTIUSER_HINT:
      'Equipment to be shared with external users. The sharing procedures and criteria are established by the laboratory',
    MULTIUSER: 'Equipment Multiuser'
  },

  REQUEST_SHARING: {
    TITLE: 'Solicitar utilização',
    DESCRIPTION: `Você pode entrar em contato com este laboratório enviando uma
                    solicitação de utilização por meio do botão abaixo. É necessário
                    estar logado para submeter o formulário`,
    ACTION: `Solicitar utilização`,
    DESCRIPTION_OWN_SHARING: `O laboratório possui procedimentos próprios de utilização, para saber mais acesse o site do laboratório`,
    ACTION_OWN_SHARING: `Site do laboratório`
  },

  PUBLIC_AREA_REGISTER: {
    TITLE: 'Registration',
    SUBTITLE_1: 'Who is eligible?',
    CONTENT_1:
      'The National Platform for Research Infrastructure is destined to teachers, researchers and anyone interested in knowing the R&D equipment belonging to the Brazilian research infrastructure.',
    SUBTITLE_2: 'Why participate?',
    CONTENT_2:
      "The National Infrastructure Platform will open new opportunities for partnerships and collaborations for you and your institution\\'s labs. In addition, it is a free equipment management tool, provided by the Ministry of Science, Technology and Innovations.",
    SUBTITLE_3: 'I want to sign up',
    CONTENT_3:
      '<strong>CREATE YOUR ACCOUNT</strong> by clicking <Link>here</Link> or by the access button at the top.',

    SUBTITLE_4: 'I received an invitation',
    CONTENT_4:
      '<strong>ACTIVATE</strong> your account using the same email address you received the invitation on.',

    REQUEST_SHARING: {
      TITLE: 'I want to use equipment',
      COLUMN_SUBTITLE_1: 'Search for multi-user equipment',
      COLUMN_TEXT_1: `
        Search for Laboratories or Equipment of interest. Check if it contains the label <strong>“Sharing enabled”</strong>.
        Access the sharing module button within the lab or equipment page.`,
      COLUMN_SUBTITLE_2: 'Request sharing',
      COLUMN_TEXT_2: `
        Labs can offer two sharing options on the platform: sharing through the PNIPE platform or through its
        own process. In this case, it will be available through a link indicated by the laboratory.`,
      COLUMN_SUBTITLE_3: 'Track your process through PNIPE platform',
      COLUMN_TEXT_3: `
        If sharing is via the PNIPE platform, the request page will be available. Fill out the form and wait for the
        analysis. You can track your request through the "Sharing" option located in the user panel.`,
      FOOTNOTE: `Note: "broken" and "under maintenance" equipment are also available for request, if you can support
        the laboratory in any way on fixing it.`
    },

    LAB_RESPONSIBLE: {
      TITLE: "I'm in charge of a laboratory",
      COLUMN_SUBTITLE_1: 'Request the registration of your laboratory',
      COLUMN_TEXT_1:
        'Access the platform and request the inclusion of the laboratory in which you are responsible in the side menu. The register will be submitted to the analysis of your institution and the result will be sent to the requester email.',
      COLUMN_SUBTITLE_2: 'Edit your laboratory page and register equipment',
      COLUMN_TEXT_2:
        'Once approved, you need to edit your laboratory page to publish it and register equipment.',
      COLUMN_SUBTITLE_3: 'Publish laboratory',
      COLUMN_TEXT_3:
        'Publish your lab via the edit page. Your laboratory will now have a public page on the platform.',
      NOTE_TITLE:
        '<strong>If your institution is not already on the platform</strong>',
      NOTE_TEXT:
        'If your institution is eligible to take part in the PNIPE initiative and is not yet registered, the inclusion can be requested through the Contact Us option at the "Support" menu. It is important to inform in the subject field "Information for registration request" and in the body message information such as the name of the institution, CNPJ and top director’s contact. After the analysis, a message with the registration guidelines or reason for refusal will be sent to the person responsible for the contact.'
    },

    INSTITUTION_RESPONSIBLE: {
      TITLE: "I'm in charge of an institution",
      COLUMN_SUBTITLE_1: 'Request the registration of your institution',
      COLUMN_TEXT_1:
        'Access the platform and request the inclusion of your institution in “research infrastructure”. The request must be made by the top manager with the institutional email. If you already have access to the platform with another email, you can change it in "My profile". When registering, it will be possible to add other representatives to help you manage the institution.',
      COLUMN_SUBTITLE_2: 'Complete the information',
      COLUMN_TEXT_2:
        "The registration will be submitted for analysis by the MCTI. Once approved, you will receive an email with the result and those responsible will be notified. Don't forget to supplement your institution's information.",
      COLUMN_SUBTITLE_3: 'Manage the institution',
      COLUMN_TEXT_3:
        'You can now add representatives to help you manage the institution on the platform, disseminate it to your institutions labs and analyze inclusion requests. It will be up to each laboratory to manage their respective responsibilities and equipment.'
    },

    BASE_SUBTITLE: 'If your institution is not already on the platform',
    BASE_SUBTITLE_TEXT:
      'If your institution is eligible to take part in the PNIPE initiative and is not yet registered, the inclusion can be requested through the <Link>Contact Us</Link> option at the "Support" menu. It is important to inform in the subject field "Information for registration request" and in the body message information such as the name of the institution, CNPJ and top director’s contact. After the analysis, a message with the registration guidelines or reason for refusal will be sent to the person responsible for the contact.'
  },

  OLD_DEFAULT: {
    LANGUAGE: 'PORTUGUÊS',
    SIGN_IN: 'Sign in',
    SIGN_OUT: 'Sign out',
    YES: 'Yes',
    NO: 'No',

    COMMONS_SELECT: 'Select',
    COMMONS_CHIP_INPUT_MAX_LENGTH_REACHED:
      'The Limit of 20 items have been reached',

    // PAGINATION
    PAGINATION_ITENS_SHOWING: 'Showing',
    PAGINATION_OF: 'from',
    PAGINATION_ITENS_PER_PAGE: 'Items per page',

    // MENU
    ABOUT_PLATAFORM: 'About the Platform',
    GOVERNMENT_PORTAL: ' Brazilian Government Portal ',
    INFRASTRUCTURE: 'Infrastructure',
    LABS: 'Laboratories',
    EQUIPMENTS: 'Equipment',
    SUPORT: 'Support',
    CONTACT_US: 'Contact us',
    ALL_RESULTS: 'Search results',
    WANT_JOIN: 'Register',
    REGISTER: 'Register',
    HOME_LOGO_PART1: 'National Research',
    HOME_LOGO_PART2: 'Infrastructure Platform MCTI',

    // HOME
    HOME_TITLE: 'Get to know the infrastructure of Brazilian science',
    HOME_BANNER_TEXT:
      'Find laboratories and equipment from institutions that make up the Brazilian research infrastructure',
    HOME_SEARCH_ASSETS_BANNER: 'National Research Infrastructure Platform MCTI',
    HOME_SEARCH_ASSETS_PLACEHOLDER: 'Search for laboratories or equipment',
    NEWSLETTER_SIGN: 'Subscribe to our newsletter',
    NEWSLETTER_FREQUENCY: "Don't worry, we do not send too many emails!",
    NEWSLETTER_PLACEHOLDER: 'Enter your email',
    NEWSLETTER_SUBMIT: 'Subscribe',
    NEWSLETTER_COPYRIGHT: '© 2020. All rights reserved',
    NEWSLETTER_TERMS: 'Terms of Use',
    SEE_MORE: 'View more!',
    EQUIPMENT: 'Equipment',

    // SEARCH
    SEARCH_LAB: 'Laboratories',
    SEARCH_EQUIP: 'Equipment',
    SEARCH_ALL: 'Search results',

    //MY PROFILE
    MY_PROFILE_PAGE: 'My profile',

    // HOME SLIDER
    INSTITUTION: 'Institution',
    ADDRESS: 'Address',
    VIEW: 'View {{type}}',
    VIEW_EQUIP: 'equipment!',
    VIEW_LAB: 'laboratory!',

    // CARD
    LABEL_LAB: 'Laboratory',
    LABEL_INSTITUTION: 'Institution',

    // REGISTER USER
    REGISTER_USER_TITLE: 'Register new user',
    REGISTER_USER_NAME: 'Name',
    REGISTER_USER_NAME_PLACEHOLDER: 'Insert your name',
    REGISTER_USER_LAST_NAME: 'Last name',
    REGISTER_USER_LAST_NAME_PLACEHOLDER: 'Insert your last name',
    REGISTER_USER_CPF: 'Brazilian social security number',
    REGISTER_USER_CPF_PLACEHOLDER: '999.999.999-99',
    REGISTER_USER_EMAIL: 'E-mail',
    REGISTER_USER_EMAIL_PLACEHOLDER: 'Insert your e-mail',
    REGISTER_USER_ACCEPT_TERMS: 'I accept the',
    REGISTER_USER_TERMS: 'Terms of use',
    REGISTER_USER_REGISTER_BUTTON: 'Register',
    REGISTER_USER_LATTES_CURRICULUM_LINK: 'Link for the Lattes Curriculum',
    REGISTER_USER_LATTES_CURRICULUM_LINK_PLACEHOLDER:
      'Paste here the URL for your CV Lattes ',
    REGISTER_USER_VALIDATION: 'User validation',
    REGISTER_USER_ACCOUNT_SUCCESSFULLY_REGISTERED:
      'Account successfully registered! An e-mail was sent to your personal account. Follow the instructions to active your account.',
    REGISTER_USER_VALIDATION_CODE: 'Validation code',
    REGISTER_USER_SEND_VALIDATION_CODE:
      'We have sent a validation code to the registered e-mail to authenticate the user. Please, insert the validation code in the field below ',
    REGISTER_USER_VALIDATION_ERROR:
      'CPF or e-mail already registered on the platform ',

    // REGISTER INSTITUTION
    REGISTER_INSTITUTION_TITLE: 'Register Institution',
    REGISTER_INSTITUTION_DATA: 'Institution info',
    REGISTER_INSTITUTION_COMPANY_CODE:
      'CNPJ (National Registry of Legal Entities)',
    REGISTER_INSTITUTION_COMPANY_CODE_PLACEHOLDER: 'Insert the CNPJ',
    REGISTER_INSTITUTION_COMPANY_INITIALS: 'Initials',
    REGISTER_INSTITUTION_COMPANY_NAME: 'Corporate Name',
    REGISTER_INSTITUTION_ADDRESS: 'Address',
    REGISTER_INSTITUTION_ZIP_CODE: 'Zip code',
    REGISTER_INSTITUTION_STREET: 'Street Address',
    REGISTER_INSTITUTION_NUMBER: 'Number',
    REGISTER_INSTITUTION_COMPLEMENT: 'Complement',
    REGISTER_INSTITUTION_DISTRICT: 'District',
    REGISTER_INSTITUTION_COUNTRY: 'County',
    REGISTER_INSTITUTION_ESTATE_INITIALS: 'State',
    REGISTER_INSTITUTION_SELECT_ESTATE: 'Select a state',
    REGISTER_INSTITUTION_REGION: 'Region',
    REGISTER_INSTITUTION_SELECT_REGION: 'Select a region',
    REGISTER_INSTITUTION_ADD_ORGANIZATIONAL_STRUCTURE_TEXT:
      'Click here to add an organizational structure',
    REGISTER_INSTITUTION_ADD_ORGANIZATIONAL_STRUCTURE:
      'Organizational structure',
    REGISTER_INSTITUTION_ADD_ORGANIZATIONAL_STRUCTURE_INFO:
      'Register the types of existing unities in your organization (board, coordination, secretariat, laboratory, etc.) where your research infrastructure is hierarchically positioned. Drag to position the items hierarchically correct.',
    REGISTER_INSTITUTION_ADD_ITEM_ORGANIZATIONAL_STRUCTURE: 'Add a new item',
    REGISTER_INSTITUTION_ORGANIZATIONAL_STRUCTURE: 'Organizational Structure',
    REGISTER_INSTITUTION_MANAGE_REPRESENTATIVES: 'Manage Representatives',
    REGISTER_INSTITUTION_INVITE_REPRESENTATIVES: 'Invite Responsible',
    REGISTER_INSTITUTION_INVITE: 'Invite',
    REGISTER_INSTITUTION_ADD_EMAIL: 'Insert e-mail',
    REGISTER_INSTITUTION_ADD_INVITE: 'Add invitation',
    REGISTER_INSTITUTION_ADD_INVITE_LIMIT_EXCEEDED:
      'The Limit of 10 representatives have been reached',
    REGISTER_INSTITUTION_REPRESENTATIVES_EMAIL: 'Email',
    REGISTER_INSTITUTION_REPRESENTATIVES_NAME: 'Name',
    REGISTER_INSTITUTION_REPRESENTATIVES_PERMISSION: 'Permission',
    REGISTER_INSTITUTION_REPRESENTATIVES_TOTAL: 'Total',
    REGISTER_INSTITUTION_REPRESENTATIVES_PARTIAL: 'Partial',
    REGISTER_INSTITUTION_REPRESENTATIVES_LIMITED: 'Limited',
    REGISTER_INSTITUTION_DIALOG_TOTAL:
      'Higher representative from an institution in the system. They have access to all functionalities available for the organization in the system.',
    REGISTER_INSTITUTION_DIALOG_PARTIAL:
      'Responsible to validate (to approve or reject) if a registered laboratory and its representatives are part of the institution.',
    REGISTER_INSTITUTION_DIALOG_LIMITED: 'Can only view information.',
    REGISTER_INSTITUTION_REPRESENTATIVES_STATUS: 'Situation',
    REGISTER_INSTITUTION_INCLUDE: 'Include',
    REGISTER_INSTITUTION_SEND_INVITE: 'Send invitation',
    REGISTER_INSTITUTION_ADD_BUTTON: 'Add',
    REGISTER_INSTITUTION_SAVE: 'Save',
    REGISTER_INSTITUTION_SAVE_SUCCESS: 'Successfully saved',
    REGISTER_INSTITUTION_SAVE_ERROR:
      'It was not possible to perform this operation. If the problem persists, contact the system administrator',
    REGISTER_INSTITUTION_CANCEL: 'Cancel',
    REGISTER_INSTITUTION_TERMS: 'Terms of use',
    REGISTER_INSTITUTION_DECLARE_HAVE_READ_THE_TERMS:
      'I declare that I am a member of this institution and that I have read the ',
    REGISTER_INSTITUTION_CONFIRM_DIALOG:
      'The information filled will be lost. Do you wish to continue?',
    REGISTER_INSTITUTION_ALERT_EMAIL_INVITE_DIALOG:
      'An invitation e-mail was informed, complete the invitation or clean the fields to continue.',
    REGISTER_INSTITUTION_USER_ALREADY_INVITED: 'User already invited',
    REGISTER_INSTITUTION_USER_ALREADY_INVITED_OTHER_INSTITUTION:
      'User already invited by another institution. Wish to continue?',
    REGISTER_INSTITUTION_AT_LEAST_ONE_TOTAL:
      'Cant complete request. There must be at least one active responsible with total access in this institution.',
    REGISTER_INSTITUTION_REFUSE_INVITE:
      'Do you really want to refuse this invite?',
    REGISTER_INSTITUTION_ACCEPT_INVITE:
      'Do you really want to accept this invite?',
    REGISTER_INSTITUTION_ACCEPT_INVITE_ALREADY_IN_INSTITUTION:
      'Accepting this invite will remove access to the institution <strong>{{institution}}</strong>. Do you want to proceed?',
    REGISTER_INSTITUTION_ACCEPT_INVITE_ERROR:
      'To accept this invite, you need to apoint a new responsible your current institution.',

    // REGISTER LABORATORY
    POSITION_OF_THE_LABORATORY_ON_THE_MAP:
      'Mark the laboratory’s position on the map',
    LABORATORY_RESPONSIBLE_DATA: 'Responsible of laboratory info',
    REGISTER_LABORATORY_INVALID_CNPJ:
      ' The informed number for the institution is not yet registered on the platform. Wait for this registration to request the inclusion of your laboratory',
    REGISTER_LABORATORY_ADD_IMAGE: 'Click here to add an image',
    REGISTER_LABORATORY_ADD_EQUIPMENT: 'Click here to add an equipment',
    REGISTER_LABORATORY_ADD_TAG: 'Click here to insert...',
    REGISTER_LABORATORY_ADDRESS: 'Address',
    REGISTER_LABORATORY_DATA: 'Laboratory info',
    REGISTER_LABORATORY_TAG_ADD_TECHNIQUE_TITLE: 'Insert the main techniques',
    REGISTER_LABORATORY_TAG_TECHNIQUE_TITLE: 'Main techniques',
    REGISTER_LABORATORY_TAG_ADD_AREAEXPERTISE_TITLE:
      'Insert one or more fields of research',
    REGISTER_LABORATORY_TAG_AREAEXPERTISE_TITLE: 'Fields of research',
    REGISTER_LABORATORY_TYPE: 'Laboratory type',
    REGISTER_LABORATORY_ACTIVITIES:
      'Nature of activities developed in the laboratory',
    REGISTER_LABORATORY_OTHER_TYPE: 'Inform another activity',
    REGISTER_LABORATORY_CHANGELOG_LABORATORYCUSTOMTYPE:
      'Inform another type of laboratory',
    REGISTER_LABORATORY_PROOF_LINK: 'Proof of link with the laboratory ',
    REGISTER_LABORATORY_INCLUSION_REQUEST: 'Laboratory inclusion request',
    REGISTER_LABORATORY_APPROVE_REQUEST:
      ' Approval of Laboratory inclusion request ',
    REGISTER_LABORATORY_HINT: `<strong>Laboratory:</strong>
      <br/><br/>
      Laboratory unity, group or research center invested with physical, instrumental and material infrastructure with the ability to develop and perform assays, methods, analysis, exams, etc., having as object the variety of material and/or samples in various fields of knowledge that require experimental research and instrumental analysis.`,
    REGISTER_LABORATORY_ATTACH_LAB_COMPROVATION:
      'Click or drag here to add a document that proves your link with the laboratory',

    REGISTER_LABORATORY_SAVE_REGISTER: 'Send',
    REGISTER_LABORATORY_SAVE_EDITING: 'Update',
    REGISTER_LABORATORY_SAVE_APPROVING: 'Complete',
    REGISTER_LABORATORY_SAVE_APPROVING_EDIT: 'Complete',

    REGISTER_LABORATORY_SUCCESS:
      'Laboratory successfully registered. Wait for the institution’s approval to register your equipment.',
    REGISTER_LABORATORY_ERROR:
      'This operation failed. If the problem persists, contact the system administrator.',
    REGISTER_LABORATORY_APPROVAL: 'Laboratory successfully approved',
    REGISTER_LABORATORY_REFUSED: 'Operation successfully performed',
    REGISTER_LABORATORY_UPDATED: 'Laboratory successfully updated',

    REGISTER_LABORATORY_CONFIRM_DIALOG:
      'The information filled will be lost. Do you want to continue?',
    REGISTER_LABORATORY_CONFIRM_DIALOG_AGREE: 'Yes',
    REGISTER_LABORATORY_CONFIRM_DIALOG_DISAGREE: 'No',
    REGISTER_LABORATORY_CONFIRM_EDITING_DIALOG:
      'The changes will be submitted to a new analysis of your institution. Do you want to continue?',

    COMMONS_REGISTER_ADD_BUTTON: 'Add',
    COMMONS_REGISTER_NONE_REGISTER: 'No record found.',

    SELECT_PROFILE: 'Select profile',
    ROLE_INSTITUICAO_TOTAL: 'Total institution representative',
    ROLE_LABORATORIO_TOTAL: 'Total laboratory representative',
    ROLE_INSTITUICAO_PARCIAL: 'Partial institution representative',
    ROLE_INSTITUICAO_LIMITADA: 'Limited institution representative',
    ROLE_INTEGRANTE: 'Member',
    NO_ACTION: 'No action',
    REGISTER_INSTITUTION_PUBLISH: 'Publish',

    TABLE_COMPARATION_TITLE: 'Requested changes',
    TABLE_COMPARATION_FIELD: 'Field',
    TABLE_COMPARATION_PREVIOUS_VALUE: 'Previous value',
    TABLE_COMPARATION_CHANGED_VALUE: 'value',

    LABORATORY_APPROVE_OPINION: ' Evaluation of the changes requested',
    LABORATORY_APPROVE_REQUEST_OPINION:
      'Do you want to approve all the fields that have been modified? ',
    LABORATORY_REASON_REFUSE: 'Justify the rejected items',

    // EDIT LABORATORY
    EDIT_LABORATORY_ABOUT_LAB: 'About this laboratory',
    EDIT_LABORATORY_ABOUT_LAB_PLACEHOLDER:
      'Insert a brief description about this laboratory',
    EDIT_LABORATORY_ADRESS: 'Address',
    EDIT_LABORATORY_CONTACT: 'Contact',
    EDIT_LABORATORY_ZIP_CODE: 'Zip code',
    EDIT_LABORATORY_RESPONSIBLE: 'Responsible',
    EDIT_LABORATORY_TELEPHONE: 'Phone number',
    EDIT_LABORATORY_EMAIL: 'E-mail',
    EDIT_LABORATORY_EMAIL_ADD: 'Insert the laboratory E-mail',
    EDIT_LABORATORY_WEBSITE: 'Website',
    EDIT_LABORATORY_WEBSITE_ADD: 'Insert the laboratory URL',
    EDIT_LABORATORY_AREA_EXPERTISE: 'Fields of research',
    EDIT_LABORATORY_TECHINIQUE: 'Main techniques',
    EDIT_LABORATORY_INDEX_SLIDER: 'from',
    EDIT_LABORATORY_ADD_RESPONSIBLE: 'Include Responsible',
    EDIT_LABORATORY_ADD_INITIAL: 'Include initials',
    EDIT_LABORATORY_LOCATION: 'How to get there?',
    EDIT_LABORATORY_REQUIRED_IMAGE_ERROR_MESSAGE:
      'Add at least one image in order to publish the laboratory',

    FILE_UPLOAD_MAX_LIMIT_REACHED:
      'The limit of {{number}} attached files have been reached',
    FILE_UPLOAD_MAX_SIZE_LIMIT_REACHED:
      'The maximum size limit per file has been reached',
    FILE_UPLOAD_INVALID_EXTENSION:
      'Invalid format! Only {{extensions}} files can be attached.',
    FILE_UPLOAD_NEW_FOTO: 'New picture',
    FILE_UPLOAD_INCLUDE_BUTTON: 'Include',
    FILE_UPLOAD_CANCEL_BUTTON: 'Cancel',

    // REGISTER EQUIPMENT
    REGISTER_EQUIPMENT_INCLUSION_REQUEST: 'Register equipment',
    REGISTER_EQUIPMENT_HINT: `
      Main equipment for R&D existent in research infrastructures with high acquisition value and essential for the laboratory research activities.
      <br/>
      The focus is on relevant equipment for the research infrastructure, excluding the ones with a supplementary role on performing the research.`,
    REGISTER_EQUIPMENT_DATA: 'Equipment info',
    REGISTER_EQUIPMENT_PHOTOS: 'Equipment pictures',
    REGISTER_EQUIPMENT_ABOUT: 'About the equipment',
    REGISTER_EQUIPMENT_PARAMETERS: 'Parameters',
    REGISTER_EQUIPMENT_RESPONSABLE: 'Responsible',
    REGISTER_EQUIPMENT_LABORATORY_LIST_DEFAULT: 'Laboratory',
    REGISTER_EQUIPMENT_LABORATORY_LIST_LABEL: 'Laboratory',
    REGISTER_EQUIPMENT_NAME: 'Equipment name',
    REGISTER_EQUIPMENT_NAME_INITIALS: 'Equipment initials',
    REGISTER_EQUIPMENT_OTHER_DENOMINATION_LABEL: 'Other equipment names',
    REGISTER_EQUIPMENT_OTHER_DENOMINATION_PLACEHOLDER: 'Add...',
    REGISTER_EQUIPMENT_PARAMETER_LIMIT:
      'The limit of {{maxParameter}} items have been reached',
    REGISTER_EQUIPMENT_OTHER_DENOMINATION_ADD: 'Add',
    REGISTER_EQUIPMENT_OTHER_DENOMINATION_HINT:
      'Inform other names that the equipment might have. Inform also its name in English.',

    REGISTER_EQUIPMENT_TYPE_IDENTIFIER: 'Type of persistent identifier',
    REGISTER_EQUIPMENT_IDENTIFIER: 'Persistent identifier number',
    REGISTER_EQUIPMENT_TYPE_IDENTIFIER_HINT: 'Ex. ISBN, ISSN, DOI...',
    REGISTER_EQUIPMENT_IDENTIFIER_HINT: 'Universal identifier of the equipment',

    REGISTER_EQUIPMENT_BRAND: 'Brand',
    REGISTER_EQUIPMENT_MODEL: 'Model',
    REGISTER_EQUIPMENT_YEAR: 'Year of acquisition',
    REGISTER_EQUIPMENT_MANUFACTURER: 'Manufacturer',
    REGISTER_EQUIPMENT_LINK: 'Equipment Link at the manufacturer’s website',
    REGISTER_EQUIPMENT_FINANCING: 'Funding source',
    REGISTER_EQUIPMENT_FINANCING_ORIGIN_TYPE: 'Type of funding source',
    REGISTER_EQUIPMENT_FINANCING_ORIGIN_NAME: 'Funding source name',
    REGISTER_EQUIPMENT_FINANCING_ORIGIN_PLACE_HOLDER: 'Specify...',
    REGISTER_EQUIPMENT_MULTIUSER_LABEL: 'Multiuser',
    REGISTER_EQUIPMENT_MULTIUSER_HINT:
      'Percentage of equipment workload (e.g. weekly, monthly, etc.) that can be available to external users (under established conditions for each case).',
    REGISTER_EQUIPMENT_MULTIUSER_CHECKBOX: 'Is the equipment multiuser?',
    REGISTER_EQUIPMENT_USAGE_PROFILE: 'Equipment usage profile',
    REGISTER_EQUIPMENT_SLIDER_LABEL:
      'Dedicated to the laboratory’s internal projects',
    REGISTER_EQUIPMENT_SLIDER_HINT:
      ' Percentage of equipment workload (e.g. weekly, monthly, etc.) dedicated to the laboratory’s internal projects.',
    REGISTER_EQUIPMENT_ABOUT_LABEL: 'About the equipment',
    REGISTER_EQUIPMENT_ABOUT_DESCRIPTION: 'Equipment description',
    REGISTER_EQUIPMENT_BOX_UPLOAD:
      'Click here to add pictures of the equipment',
    REGISTER_EQUIPMENT_MAIN_TECHNIQUE: 'Techniques used with this equipment',
    REGISTER_EQUIPMENT_ADD_MAIN_TECHNIQUE: 'Insert one or more techniques',

    REGISTER_EQUIPMENT_PARAMETERS_LABEL: 'Parameters',
    REGISTER_EQUIPMENT_PARAMETERS_NAME_PLACEHOLDER:
      'e.g. power, capacity, etc.',
    REGISTER_EQUIPMENT_PARAMETERS_VALUE_PLACEHOLDER:
      'Insert values and/or attributes that characterize the parameter',
    REGISTER_EQUIPMENT_PARAMETERS_UNIT_PLACEHOLDER: 'e.g. km, m³',
    REGISTER_EQUIPMENT_PARAMETERS_NAME: 'Parameter name',
    REGISTER_EQUIPMENT_PARAMETERS_VALUE: 'Value',
    REGISTER_EQUIPMENT_PARAMETERS_UNIT: 'Measuring unity (if applicable)',
    REGISTER_EQUIPMENT_CANCEL_CONFIRM:
      'The information filled will be lost. Do you want to continue?',

    EQUIPMENT_LIST_SELECT_LAB: 'Select the laboratory',

    LIST_EQUIPMENT_ACTION_EDIT: 'Edit',
    LIST_EQUIPMENT_ACTION_DELETE: 'Delete',
    LIST_EQUIPMENT_ACTION_INACTIVATE: 'Inactivate',
    LIST_EQUIPMENT_ACTION_ACTIVATE: 'Activate',

    REGISTER_EQUIPMENT_CARACTERISTICS: 'Equipment parameters',
    REGISTER_EQUIPMENT_CARACTERISTICS_HINT:
      'Describe the characteristics and specifications of the equipment that are relevant for its use',

    REGISTER_EQUIPMENT_RESPONSIBLE: 'Responsible',
    REGISTER_EQUIPMENT_ADD_RESPONSIBLE: 'Add responsible',
    REGISTER_EQUIPMENT_ADD_RESPONSIBLE_PLACEHOLDER:
      'Insert the name of the responsible',
    REGISTER_EQUIPMENT_SAVE: 'Save',
    REGISTER_EQUIPMENT_CANCEL_DIALOG:
      'The information filled will be lost. Do you want to continue? Yes/No',

    EXCLUDE_EQUIPMENT_DIALOG: 'Do you really want to delete the equipment?',

    REGISTER_EQUIPMENT_ITEMS_MAX_QUANTITY_REACHED:
      'Maximum number of {{limit}} items already added',

    // EQUIPMENT LIST
    EQUIPMENT_LIST_HEADER: 'Equipment',
    EQUIPMENT_LIST_FILTER_LABORATORY: 'Select the laboratory',
    EQUIPMENT_LIST_FILTER_LABORATORY_DEFAULT: 'All',
    EQUIPMENT_LIST_FILTER_STATUS: 'Filter by',
    EQUIPMENT_LIST_SEARCH_DEFAULT:
      'Search equipment by code, name, brand, model, manufacturer...',
    EQUIPMENT_LIST_ADD_EQUIPMENT_BUTTON: 'Add equipment',

    EQUIPMENT_LIST_TABLE_CODE: 'Code',
    EQUIPMENT_LIST_TABLE_NAME: 'Name',
    EQUIPMENT_LIST_TABLE_BRAND: 'Brand',
    EQUIPMENT_LIST_TABLE_MODEL: 'Model',
    EQUIPMENT_LIST_TABLE_MANUFACTURE: 'Manufacturer',
    EQUIPMENT_LIST_TABLE_LABORATORY: 'Laboratory',

    // EQUIPMENT DETAIL
    EQUIPMENT_DETAIL_TECHINIQUE_LIST: 'Techniques used with this equipment:',
    EQUIPMENT_DETAIL_CODE_PLATFORM: 'Equipment code on the platform',
    EQUIPMENT_DETAIL_OTHER_DENOMANATION: 'Other equipment names',
    EQUIPMENT_DETAIL_ORIGIN: 'Equipment origin',
    EQUIPMENT_DETAIL_ABOUT: 'About the equipment',
    EQUIPMENT_DETAIL_DATA: 'Equipment info',
    EQUIPMENT_DETAIL_BRAND: 'Brand',
    EQUIPMENT_DETAIL_MODEL: 'Model',
    EQUIPMENT_DETAIL_LINK: 'Equipment link on manufacturer site',
    EQUIPMENT_DETAIL_PURCHASEYEAR: 'Purchase year',
    EQUIPMENT_DETAIL_PARAMETER: 'Parameters',
    EQUIPMENT_DETAIL_CONTACT: 'Contact',
    EQUIPMENT_DETAIL_RESPONSIBLE: 'Responsible',
    EQUIPMENT_DETAIL_TELEPHONE: 'Telephone',
    EQUIPMENT_DETAIL_EMAIL: 'Email',
    EQUIPMENT_DETAIL_WEBSITE: 'Website',
    EQUIPMENT_DETAIL_HINT_MULTIUSER:
      'Equipment to be shared with external users. The sharing procedures and criteria are established by the laboratory',
    EQUIPMENT_DETAIL_MULTIUSER_LABEL: 'Equipment Multiuser',

    // EQUIPMENT DIALOG ACTION
    EQUIPMENT_DIALOG_MOTIVE: 'Reason',
    EQUIPMENT_DIALOG_INFORM_MOTIVE: 'Inform another reason (if applicable)',
    EQUIPMENT_DIALOG_DETAIL_MOTIVE: 'Detail the reason',

    EQUIPMENT_DIALOG_EXCLUDE_TITLE: 'Delete equipment',
    EQUIPMENT_DIALOG_INACTIVE_TITLE: 'Inactivate equipment',
    EQUIPMENT_DIALOG_OK: 'OK',

    // REGION LIST
    REGION_MIDWEST: 'Middle west',
    REGION_NORTHEAST: 'Northwest',
    REGION_NORTH: 'North',
    REGION_SOUTHEAST: 'Southeast',
    REGION_SOUTH: 'South',

    // LOGIN
    LOGIN_TITLE: 'Login',
    LOGIN_USER_LABEL: 'User',
    LOGIN_USER_PLACEHOLDER: 'Insert your CPF or e-mail', //'Insira seu CPF ou E-mail'
    LOGIN_PASSWORD_LABEL: 'Password', //'Senha'
    LOGIN_PASSWORD_PLACEHOLDER: 'Insert your password', //'Insira sua senha'
    LOGIN_SIGN_IN: 'Sign in', // Entrar
    LOGIN_FORGOT_PASSWORD: 'Forgot password', // Esqueci minha senha
    LOGIN_NO_ACCOUNT: "Don't have access?", // Não possui acesso?
    LOGIN_SIGN_UP: 'Create account', // Crie sua conta

    LOGIN_CHANGE_PASSWORD_TITLE: 'Change Password',
    LOGIN_CHANGE_PASSWORD_SEND: 'Redefine',
    LOGIN_CHANGE_PASSWORD_NEW_LABEL: 'New Password',
    LOGIN_CHANGE_PASSWORD_NEW_PLACEHOLDER: 'New Password',
    LOGIN_CHANGE_PASSWORD_REPEAT_LABEL: 'Repeat password',
    LOGIN_CHANGE_PASSWORD_REPEAT_PLACEHOLDER: 'Repeat password',
    LOGIN_INVALID_CREDENTIALS: 'Invalid user or password',
    LOGIN_TOKEN_ALREAD_USED:
      'Request already used! Make a new request if you wish to change your password again.',
    LOGIN_CHANGE_PASSWORD_CONFIRM: 'Confirm password',
    LOGIN_CHANGE_PASSWORD_MUST_MATCH: 'Passwords must match',

    LOGIN_MUST_CONTAIN_LOWER_CASE: 'Must contain a lower-case letter',
    LOGIN_MUST_CONTAIN_UPPER_CASE: 'Must contain an upper-case letter',
    LOGIN_MUST_CONTAIN_NUMBER: 'Must contain a number',
    LOGIN_MUST_CONTAIN_SPECIAL_CHARACTER: 'Must contain a special character',
    LOGIN_MUST_CONTAIN8_CHARACTERS: 'Must contain at least 8 characters',

    // LOGIN VALIDATION
    LOGIN_VALIDATION_CONFIRMED_USER:
      'User confirmed! Insert your CPF or E-mail and password to access!',
    LOGIN_CODE_RESENT_SUCCESSFULLY:
      'An e-mail with the code was successfully resent!',
    LOGIN_VALIDATION_ERROR_INVALID_CODE:
      'Invalid code! Verify the received code and try again or request a new code through the option “Resend Code”.',
    LOGIN_VALIDATION_ERROR_USER_NOT_FOUND: 'User not registered!',
    LOGIN_VALIDATION_ERROR_USER_BLOCK:
      'Too many requests were made. Please verify your spam folder in your e-mail. If the message has not been sent, please wait to try again or contact the Support.',

    // RESET PASSWORD
    RESET_PASSWORD_TITLE: 'Define a new password',

    // REQUEST NEW PASSWORD
    RESET_PASSWORD_CPF_LABEL: 'CPF',
    RESET_PASSWORD_CPF_PLACEHOLDER: 'CPF',
    RESET_PASSWORD_NEW_LABEL: 'Password',
    RESET_PASSWORD_NEW_PLACEHOLDER: 'New Password',
    RESET_PASSWORD_REPEAT_LABEL: 'Repeat Password',
    RESET_PASSWORD_REPEAT_PLACEHOLDER: 'Repeat Password',
    RESET_PASSWORD_SEND: 'Redefine',
    RESET_PASSWORD_SUCCESS: 'Password has been reset successfully',

    // REQUEST NEW PASSWORD
    REQUEST_NEW_PASSWORD_TITLE: 'Reset password',
    REQUEST_NEW_PASSWORD_SUB_TITLE:
      'Insert the CPF or E-mail that you used to register. We will send you an e-mail with a link   to reset your password.',
    REQUEST_NEW_PASSWORD_USER_LABEL: 'CPF or e-mail',
    REQUEST_NEW_PASSWORD_USER_PLACEHOLDER: 'Insert your CPF or e-mail',
    REQUEST_NEW_PASSWORD_SEND: 'Send',
    REQUEST_NEW_PASSWORD_SUCCESS:
      'Verify your inbox to proceed with the password redefinition.',

    // VALIDATION ERROR
    VALIDATION_ERROR_PASSWORD_CONTAINS_EMAIL_OR_NAME:
      'The Password cannot contain your name or e-mail',
    VALIDATION_ERROR_PASSWORD_VALIDATION: 'Passwords doesn’t match',
    VALIDATION_INVALID_FORMAR_PASSWORD: 'Invalid password format',

    // PRIVATE
    PORTAL_VIEW: 'View portal',

    ACCEPT: 'Accept',
    REJECT: 'Reject',

    // PRIVATE NOTIFICATION

    INVITE_INSTITUTION:
      'An invitation to integrate the management of <b>{{institution}}</b> awaits your approval',
    CREATE_LAB: 'A Request for inclusion of laboratory awaits your approval',

    // PRIVATE NAV
    NAV_PROFILE: 'My profile',
    NAV_INSTITUTION: 'Institution',
    NAV_LAB: 'Laboratory',
    NAV_ADDLAB: 'Request for inclusion of laboratory',
    NAV_EQUIPMENT: 'Equipment',
    NAV_ADD_EQUIPMENT: 'Register new equipment',
    NAV_ADVERTISE_PLATFORM: 'Disseminate the platform',
    NAV_USER: 'Users management',
    NAV_SHARE: 'Sharing',
    NAV_INVITE_LAB: 'Invite laboratory',

    // OPINION OF REQUEST
    REQUEST_OPINION_LABORATORY_REQUEST_OPNION: 'Opinion on this Solicitation',
    REQUEST_OPINION_UNIT_QUESTION:
      'Is the research laboratory part of this institution?',
    REQUEST_OPINION_RESEARCHER_QUESTION:
      'Is the requester part of the laboratory in question?',
    REQUEST_OPINION_REFUSAL_REASON: 'Reasons for rejection',
    REQUEST_OPINION_SELECT: 'Select',
    REQUEST_OPINION_DETAIL_REASON: 'Detail the reasons',
    REQUEST_OPINION_DETAIL_REASON_PLACEHOLDER: 'Insert a brief description',
    REQUEST_OPINION_ATTACH_FILE: 'Attach file',
    REQUEST_OPINION_ATTACH_LAB_COMPROVATION:
      'Click or drag here to add a document that proves your link with this laboratory',

    // PUBLIC_AREA
    PUBLIC_AREA_ABOUT_TITLE_1: 'About the platform',
    PUBLIC_AREA_ABOUT_SUBTITLE_1:
      'What is the National Research Infrastructure Platform MCTI?',
    PUBLIC_AREA_ABOUT_1: `The National Research Infrastructure Platform (PNIPE) aims to systematically map and gather information on research infrastructures in Scientific, Technological and Innovation Institutions (STIs) in the country, enabling access for the scientific and technological community and companies to existing laboratory facilities and research equipment, promoting their shared use.
      <br/><br/>
      <strong>Thus, PNIPE intends to:</strong>`,
    PUBLIC_AREA_ABOUT_BULLET_LIST_1: `
      <ul>
        <li>Provide the scientific and technological community access to information on existing research infrastructures, including their location, applications and conditions of use;</li>
        <li>Foster the research infrastructure’s potential of innovation by giving visibility and insights for companies to cooperatively improve their products and develop new technologies;</li>
        <li>Encourage the shared use of resources and collaboration between research groups from different areas, institutions and regions, consolidating collaborations between them;</li>
        <li>Optimize the use of laboratory facilities and scientific research equipment to avoid fragmentation and duplicated efforts; and</li>
        <li>Optimize the use of public resources in the preventive and corrective maintenance of existing infrastructures, as well as in the creation of new facilities and new equipment acquisition.</li>
      </ul>`,
    PUBLIC_AREA_ABOUT_SUBTITLE_2: 'What are research infrastructures?',
    PUBLIC_AREA_ABOUT_2: `
      Research infrastructures are physical or virtual facilities that provide the scientific community inputs, equipment and services to carry out research and experimental development (R&D) activities and to foster innovation.
      <br/><br/>
      <strong>This concept involves the following elements:</strong>`,
    PUBLIC_AREA_ABOUT_BULLET_LIST_2: `
      <ul>
        <li>Physical facilities and their gear and equipment used in R&D activities;</li>
        <li>Knowledge-based resources (such as collections, files and databases) used in scientific research;</li>
        <li>Information and communication technology resources (such as grids, high performance networks and specific software); and </li>
        <li>Any other infrastructure of unique application in R&D activities.</li>
      </ul>`,
    PUBLIC_AREA_ABOUT_3: `
      These research infrastructures can be on a single or distributed location or can be virtual platforms, and may be part of a regional, national or international network.
      <br/><br/>
      Examples of research infrastructures are: large research facilities, laboratories, pilot plants, animal facilities, clean rooms, high-performance computer networks, databases, collections, observatories, telescopes, research ships, natural reserves, experimental stations, among others.`,
    PUBLIC_AREA_ABOUT_SOURCE: 'Source:',

    FAQ: 'Frequently asked questions',

    PUBLIC_AREA_QUESTION_1:
      'What is the National Research Infrastructure Platform MCTI?',
    PUBLIC_AREA_ANSWER_1: `
      The National Research Infrastructure Platform MCTI (PNIPE) that aims to systematically map and gather information on research infrastructures in Scientific, Technological and Innovation Institutions (STIs) in the country, enabling access for the scientific and technological community and companies to existing laboratory facilities and research equipment, promoting their shared use.
      <br/><br/>
      <strong>Main goals:</strong>
      <br/><br/>
      <ul>
        <li>Create a system with updated information on the research infrastructure in STIs, in the country;</li>
        <li>Provide the scientific and technological community access to information on existing research infrastructures, including their location, applications and conditions of use;</li>
        <li>Encourage the shared use of resources and collaboration between research groups from different areas, institutions and regions, consolidating collaborations between them;</li>
        <li>Rationalize and optimize the use of laboratory facilities and scientific research equipment to avoid fragmentation and duplicated efforts;</li>
        <li>Foster research infrastructure’s potential of innovation by giving visibility and insights for companies to cooperatively improve their products and develop new technologies; and</li>
        <li>Optimize the use of public resources in the preventive and corrective maintenance of existing infrastructures, as well as in the creation of new facilities and new equipment acquisition.</li>
      </ul>`,

    PUBLIC_AREA_QUESTION_2: 'What are research infrastructure?',
    PUBLIC_AREA_ANSWER_2: `
      Research infrastructures are physical or virtual facilities that provide the scientific community with inputs, equipment, and services to carry out research and development (R&D) activities and to promote innovation.
      <br/><br/>
      <strong>This concept involves the following elements:</strong>
      <br/><br/>
      <ul>
        <li>Physical facilities, equipment and instruments used in R&D activities;<br/></li>
        <li>Knowledge-based resources (such as collections, files and databases) used in scientific research;<br/></li>
        <li>Information and communication technology resources (such as grids, high performance networks and specific software); and<br/></li>
        <li>
          Any other infrastructure of a singular nature used in R&D activities.
          <br/><br/>
          These research infrastructures can be on a single or distributed location or can be virtual platforms, and may be part of a regional, national or international network.
          <br/><br/>
          Examples of research infrastructures are: large research facilities, laboratories, pilot plants, animal facilities, clean rooms, high performance computer networks, databases, collections, observatories, telescopes, research ships, natural reserves, experimental stations, among others.
        </li>
      </ul>
    `,

    PUBLIC_AREA_QUESTION_3: 'Why participate?',
    PUBLIC_AREA_ANSWER_3: `
      Providing access to information over existing research laboratories and their possibilities of use for the scientific / technological community and companies enables opportunities of new partnerships and collaborations.
      <br/><br/>
      Moreover, it will be a laboratory management tool for participating institutions.
      <br/><br/>
      In terms of public policies for ST&I, it opens up for the possibility of optimizing or using public resources in the preventive and corrective maintenance of existing research infrastructures, as well as in the construction of new facilities and the acquisition of new equipment.`,

    PUBLIC_AREA_QUESTION_4: 'Who is eligible?',
    PUBLIC_AREA_ANSWER_4:
      'Scientific, Technological and Innovation Institutions in Brazil that develop R&D activities through their facilities and research laboratories.',

    PUBLIC_AREA_QUESTION_5:
      'How to register an institution and its laboratories/equipment?',
    PUBLIC_AREA_ANSWER_5:
      'The guidelines on how to register your institution can be accessed through the Register menu',

    PUBLIC_AREA_QUESTION_6: 'Who is the research laboratory coordinator?',
    PUBLIC_AREA_ANSWER_6: `
      The coordinator is responsible, with the institution, for the management of the laboratory as well as the inclusion and updating of data in the PNIPE.
      <br/><br/>
      <strong>The duties involve:</strong>
      <br/>
      <ul>
        <li>Management of the use of physical space and available equipment and instruments;</li>
        <li>Research and the technical support teams supervision;</li>
        <li>Dialogue and contact with other institutions.</li>
      </ul>`,

    PUBLIC_AREA_QUESTION_7:
      'What information should be shown in the field “About the laboratory?”',
    PUBLIC_AREA_ANSWER_7:
      'In this field, a brief description of the laboratory and its characteristics should be made.',

    PUBLIC_AREA_QUESTION_8: 'What are multi-user laboratories?',
    PUBLIC_AREA_ANSWER_8:
      'Multi-user laboratories are environments equipped with staff and facilities to support R&D activities that bring together specialized equipment for their shared use, offering services to internal and external users, performed by specialist technicians or by the user after training. They are managed by a directed committee and must have clear rules for access and use.',

    PUBLIC_AREA_QUESTION_9: 'What are multi-user equipment?',
    PUBLIC_AREA_ANSWER_9:
      'They are equipment available for shared use in R&D activities, for both internal and external users.',

    PUBLIC_AREA_QUESTION_10: 'What kind of equipment can be registered?',
    PUBLIC_AREA_ANSWER_10:
      'Equipment considered essential for the development of laboratory activities must be registered, minor accessory equipment should not be included.',

    PUBLIC_AREA_CONTACT_US_NAME: 'Full name',
    PUBLIC_AREA_CONTACT_US_ADD_NAME: 'Insert your name',

    PUBLIC_AREA_CONTACT_US_EMAIL: 'E-mail',
    PUBLIC_AREA_CONTACT_US_ADD_EMAIL: 'Insert your e-mail',

    PUBLIC_AREA_CONTACT_US_SUBJECT: 'Subject',
    PUBLIC_AREA_CONTACT_US_ADD_SUBJECT: 'Insert the subject',

    PUBLIC_AREA_CONTACT_US_MESSAGE: 'Message',
    PUBLIC_AREA_CONTACT_US_ADD_MESSAGE: 'Write your message',

    PUBLIC_AREA_CONTACT_US_SEND: 'Send',

    PUBLIC_AREA_CONTACT_US_SUCCESS:
      'Message successfully sent! We will contact you by e-mail soon.',

    PUBLIC_AREA_CONTACT_US_ERROR:
      'It was not possible to perform this operation. Try again later',
    EXTERNAL_MENU_PAINEL: 'My panel',
    EXTERNAL_MENU_LOGOUT: 'Logout',
    REGISTER_LABORATORY_SOCIAL_REASON: 'Institution name',
    REGISTER_LABORATORY_SOCIAL_REASON_NAME_INSTITUTION:
      'Insert the institution name',

    REQUEST_OPINION_ATTACH_LAB_REFUSED:
      'Click or drag here any document detailing the reasons of your rejection',

    USER_TERMS_DIALOG_OK: 'OK',
    USE_TERMS: `MCTI National Research Infrastructure Platform Terms of Use Agreement
    <br><br>
    1. Acceptance of the Terms of Use
    <br><br>
    1.1.This Agreement aims to establish the use of the service offered by the National Research Infrastructure Platform of MCTI (PNIPE). By using this service offered by PNIPE, the user is aware that will be subject to the rules applicable to him. By submitting information to PNIPE, the user assumes that has
    read and agreed with the terms and agreed, automatically and irrevocably, to the rules contained therein.
    <br><br>
    1.2. The service offered does not involve any cost to the user, except those of the remote connection for access to PNIPE, which will be up to the user. Similarly, there will be no financial reward or advantage to the user by the information and data registered in the PNIPE and the providing of them made by MCTI at any time.
    <br><br>
    2. Description of the service
    <br><br>
    The MCTI, through the PNIPE, aims to systematically map and gather information on research infrastructures in Scientific, Technological and Innovation Institutions (STIs) in the country, enabling access for the scientific and technological community and companies to existing laboratory facilities and
    research equipment, promoting their shared use.
    <br><br>
    3. Password and Security
    <br><br>
    3.1. Every user is responsible for the safe custody and the confidentiality of his password as well as fully responsible for all activities, supplies and register of information arising from the use of it, even for legal
    purposes.
    <br>
    3.2. The user agrees to immediately notify the MCTI on any unauthorized use of his password or any
    breach of security that takes knowledge.                       
    <br>
    3.3. The MCTI will not be responsible for any loss that may occur as a result of non-authorized use by
    others of your password, with or without your knowledge.
    <br>
    3.4. To protect the confidentiality of your password, it is recommended to the user:
    <br><br>
    a) Log out at the end of each session and ensure that it is not accessible to others, and
    b) Do not share your password, not even to MCTI, by e-mail, telephone or other means.
    <br><br>
    4. Sharing of information
    <br><br>
    4.1. All information sent to MCTI may be available for access by the MCTI staff. They may also be
    disclosed through the Internet or other means, except the following data regarding the identification of the
    user, for which the MCTI undertakes not to make it public:
    <br><br>
    a) CPF
    <br><br>
    4.2. The MCTI can provide all the information related to the research infrastructures received from users to
    other federal, municipal and state governmental agencies, safeguarding the commitment of not publicly
    displaying the information related to the identification data listed above.
    <br>
    4.3. The MCTI is expressly authorized to publish the information related to the infrastructures contained in
    the PNIPE in any way and to register and share it or integrate it with other MCTI databases or other
    interested parties for the development of indicators and studies on science and technology policies,
    without any resulting burden or obligation for the MCTI, except to preserve the integrity, loyalty, precision
    and accuracy of the data and personal information records, as they are originally registered in PNIPE by
    the user.
    <br><br>
    5. Conduct and Obligations of the User
    <br><br>
    As a condition for using the service, you agree to:
    <br><br>
    a) provide true and accurate information;
    <br>
    b) accept that the user is solely responsible for any and all information registered and are subject to the
    consequences, administrative and legal, arising from false or incorrect statements or in which will cause
    damage to the MCTI, government or of third parties;
    <br>
    c) not use the service for illegal or prohibited purposes;
    <br>
    d) not use the service to transmit / disclose illegal, prohibited or defamatory material, which violates the
    privacy of others, or which is abusive, threatening, discriminatory, harmful or libelous;
    <br>
    e) not use the service to transmit / disseminate material that encourages discrimination or violence;
    <br>
    f) not transmit and / or disclose any material that violates rights of third parties, including intellectual property rights;
    <br>
    g) not obtain or attempt to obtain non-authorized access to other systems or networks of computers connected to the service (hacker activities);
    <br>
    h) not interfere with or disrupt the service, the networks or servers connected to the service;
    <br>
    i) not to create a false identity or to use subterfuge to deceive others or obtain benefits;
    <br>
    j) request authorization to include links to other sites and / or databases, and
    <br>
    k) immediately communicate any discrepancies found by the user in the data and information registered and released by PNIPE, concomitant to the correction made by him.
    <br><br>
    6. Conduct and Obligations of MCTI
    <br><br>
    6.1. The MCTI reserves the right to:
    <br><br>
    a) share and display data from users of the service, as described in Section 4;
    <br>
    b) without notice, carry out periodic audits on the information registered by the user;
    <br>
    c) cancel the user’s access to the service, and remove the registration of the information provided by the user whenever identifies incorrect use of the system, or the practice of abuses in its use or in the
    registration of information. Abuse is understood as any activity that causes harm or damage to the rights of third parties. The practice of a criminal act through PNIPE will cause its investigation and if the user&#39;s
    responsibility is found, repressive administrative measures will be adopted.
    <br><br>
    6.2. The MCTI is not responsible for any false or incorrect information provided by the user that will cause damage to third parties, the government or to the service itself.
    <br><br>
    7. Modifications of the Terms of Use
    <br><br>
    7.1. The MCTI reserves the right to change the content of this term, and the user is responsible to consult it regularly.
    <br><br>
    7.2. The continued use of the service involves the acceptance of all rules, conditions and warnings of the Terms of Use, while the personal information of the user is still present.
    <br><br>
    8. Legislation
    <br><br>
    It is applied to this term, and the responsibilities therein, the entire federal legislation that it is related to this.`,
    REGISTER_EQUIPMENT_CODE: 'Equipment code on the platform',

    // LABORATORY REPRESENTANT LIST
    NAV_LIST_REPRESENTANTS: 'Responsible for the laboratory',
    LABORATORY_LIST_REPRESENTANT: 'Manage responsible for the laboratory',

    LABORATORY_LIST_REPRESENTANT_SELECT: 'Laboratory',
    LABORATORY_LIST_REPRESENTANT_DIALOG:
      'This user is linked as the main responsible for the laboratory. This change can be made through the option "Advanced edition" on the page \'Laboratory Edition\'.',

    //LABORATORY DETAIL EQUIPMENT LIST
    LABORATORY_DETAIL_EQUIPMENT_LIST_NAME: 'Name',
    LABORATORY_DETAIL_EQUIPMENT_LIST_BRAND: 'Brand',
    LABORATORY_DETAIL_EQUIPMENT_LIST_MODEL: 'Model',
    LABORATORY_DETAIL_EQUIPMENT_LIST_MANUFACTURER: 'Manufacturer',

    LABORATORY_LIST_REPRESENTANT_DIALOG_TOTAL:
      'Same profile as the maximum representative of the laboratory in front of the system. You have access to all the functionalities related to the Laboratory in the system.',
    LABORATORY_LIST_REPRESENTANT_DIALOG_PARTIAL:
      'Responsible for supporting the management of equipment (registering and updating) and for the laboratory editing and publishing.',
    LABORATORY_LIST_REPRESENTANT_DIALOG_LIMITED: 'Can only view information.',
    EDIT_LABORATORY_REQUIRED_EQUIPMENT_ERROR_MESSAGE:
      'At least one piece of equipment must be added to publish your laboratory.',

    ROLE_LABORATORIO_PARCIAL: 'Partial laboratory representative',
    ROLE_LABORATORIO_LIMITADA: 'Limited laboratory representative',
    REGISTER_EQUIPMENT_EDIT_REQUEST: 'Edit equipment',

    //LIST EQUIPMENT INSIDE LABORATORY EDIT
    LIST_EQUIPMENT_LABORATORY_CODE: 'Code',
    LIST_EQUIPMENT_LABORATORY_NAME: 'Name',
    LIST_EQUIPMENT_LABORATORY_MANUFACTURER: 'Manufacturer',
    LIST_EQUIPMENT_LABORATORY_MODEL: 'Model',
    LIST_EQUIPMENT_LABORATORY_LABORATORY: 'Laboratory',

    // EDIT LABORATORY
    DETAIL_LABORATORY_ABOUT_LAB: 'About this laboratory',
    DETAIL_LABORATORY_ADRESS: 'Address',
    DETAIL_LABORATORY_CONTACT: 'Contact',
    DETAIL_LABORATORY_ZIP_CODE: 'Zip code',
    DETAIL_LABORATORY_RESPONSIBLE: 'Responsible',
    DETAIL_LABORATORY_TELEPHONE: 'Phone number',
    DETAIL_LABORATORY_EMAIL: 'Email',
    DETAIL_LABORATORY_EMAIL_ADD: 'Insert the laboratory e-mail',
    DETAIL_LABORATORY_WEBSITE: 'WEBSITE',
    DETAIL_LABORATORY_WEBSITE_ADD: 'Insert the Laboratory URL',
    DETAIL_LABORATORY_AREA_EXPERTISE: 'Field of research',
    DETAIL_LABORATORY_TECHINIQUE: 'Main techniques',
    DETAIL_LABORATORY_INDEX_SLIDER: 'from',

    ADVANCED_EDIT: 'Advanced Edit',
    SAVE_DRAFT: 'Save as Draft',

    // ADMIN USER LIST
    LIST_USER_TITLE: 'Users',
    LIST_USER_COLUMN_NAME: 'User name',
    LIST_USER_COLUMN_EMAIL: 'E-mail',
    LIST_USER_COLUMN_CPF: 'CPF',
    LIST_USER_COLUMN_STATUS: 'Situation',
    LIST_USER_COLUMN_ACTIONS: 'Actions',
    LIST_USER_ACTIVE: 'Active',
    LIST_USER_INACTIVE: 'Inactive',
    LIST_USER_PENDING: 'Pending',
    LIST_USER_FILTER_STATUS: 'View by situation',
    LIST_USER_SEARCH_DEFAULT: 'Search by name, e-mail, cpf...',
    LIST_USER_ACTIONS_MANAGE: 'Manage user',
    LIST_USER_ACTIONS_RESEND: 'Resend temporary password',
    LIST_USER_ACTIONS_ACTIVATE: 'Activate user',
    LIST_USER_ACTIONS_INACTIVATE: 'Inactivate user',
    LIST_USER_ACTIONS_DELETE: 'Exclude user',

    // ADMIN USER LIST DELETE MODAL
    LIST_USER_DELETE_MODAL_TITLE: 'Exclude user',
    LIST_USER_DELETE_MODAL_TEXT:
      'Do you want exclude user <strong>{{user}}</strong> from the system? Detail the reason and attach relevant documents in order to confirm the deletion of this user.',
    LIST_USER_DELETE_MODAL_LABEL_DETAILS: 'Detail reasons',
    LIST_USER_DELETE_MODAL_LABEL_DETAILS_PLACEHOLDER: 'Detail deletion reasons',
    LIST_USER_DELETE_MODAL_LABEL_ATTACH: 'Attach document',
    LIST_USER_DELETE_MODAL_BUTTON_EXCLUDE: 'Exclude',
    LIST_USER_DELETE_MODAL_BUTTON_CANCEL: 'Cancel',
    LIST_USER_NOT_AUTHORIZED: 'User not authorized',

    // ADMIN USER EDIT
    EDIT_USER_TITLE: 'Manage user',
    EDIT_USER_USER_DATA_TITLE: 'Registration Data',
    EDIT_USER_PENDING_INVITES_TITLE: 'Pending Invites',
    EDIT_USER_NAME: 'Name',
    EDIT_USER_LAST_NAME: 'Last name',
    EDIT_USER_EMAIL: 'E-mail',
    EDIT_USER_CPF: 'CPF',
    EDIT_USER_PERMISSIONS_TITLE: 'User permissions',
    EDIT_USER_INSTITUTION: 'Link unit',
    EDIT_USER_INSTITUTION_TYPE: 'Type',
    EDIT_USER_PERMISSION: 'Permission',
    EDIT_USER_STATUS: 'Situation',
    EDIT_USER_CANCEL: 'Cancel',
    EDIT_USER_SAVE: 'Save',

    EDIT_USER_PENDING_INVITES_INSTITUTION: 'Link unit',
    EDIT_USER_PENDING_INVITES_PERMISSION: 'Permission',
    EDIT_USER_PENDING_INVITES_DATE: 'Last send date',
    EDIT_USER_PENDING_INVITES_ACTION: 'Resend',
    EDIT_USER_PENDING_INVITES_NO_INVITES:
      'There are currently no pending invitations'
  }
}
