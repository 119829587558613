import React from 'react'

import {
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

import { previewFile } from 'ui/atoms/AddedDocuments'
import { useGlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle } from 'ui/atoms/Title'

const LabChangeLog = ({ laboratoryData }) => {
  const { translate } = useGlobalizationContext()

  const { changelogList } = laboratoryData

  const renderValue = (type, changedValue) => {
    if (changedValue.field === 'documentList') {
      return renderDocument(type, changedValue)
    } else {
      return type === 'OLD'
        ? changedValue.oldValue
        : changedValue.newValue || '-'
    }
  }

  const renderDocument = (type, changedValue) => {
    const value =
      type === 'OLD'
        ? changedValue.oldDocumentList
        : changedValue.newDocumentList || '-'
    return (
      <>
        {!value?.length
          ? '-'
          : value.map((item, index) => (
              <>
                <Link
                  key={item.id}
                  component="button"
                  variant="body2"
                  onClick={() => previewFile(item)}
                >
                  {item.fileName}
                </Link>
                {index < value.length - 1 ? ', ' : null}
              </>
            ))}
      </>
    )
  }

  return (
    <Grid container spacing={2}>
      <Subtitle item xs={12}>
        {translate('CHANGELOG.TITLE')}
      </Subtitle>
      <Grid item xs={12}>
        <TableContainer style={{ marginBottom: '40px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate('CHANGELOG.FIELD')}</TableCell>
                <TableCell>{translate('CHANGELOG.PREVIOUS_VALUE')}</TableCell>
                <TableCell>{translate('CHANGELOG.CHANGED_VALUE')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {changelogList.map(change => (
                <TableRow key={change.field}>
                  <TableCell>
                    {translate(
                      `LABORATORY:CHANGELOG.${change.field.toUpperCase()}`
                    )}
                  </TableCell>
                  <TableCell>{renderValue('OLD', change)}</TableCell>
                  <TableCell>{renderValue('NEW', change)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default LabChangeLog
