import React, { useContext } from 'react'

import { DocumentService } from 'api'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import Eye from 'assets/icons/eye.svg'
import Trash from 'assets/icons/trash.svg'

import {
  Content,
  Label,
  DocumentWrapper,
  Text,
  Actions,
  EyeIcon,
  TrashIcon
} from './styles'

export const previewFile = async file => {
  if (file.id) {
    try {
      let fileUrl
      if (
        ['PRIVATE_DOCUMENT', 'PRIVATE_REFUSE_DOCUMENT'].includes(
          file.documentArea
        )
      ) {
        fileUrl = await DocumentService.getUrl(file.id).then(resp => resp.data)
      } else {
        fileUrl = `/${file.path}`
      }
      window.open(fileUrl, '_blank')
    } catch (error) {}
  } else {
    const blobUrl = URL.createObjectURL(file)
    window.open(blobUrl, '_blank')
  }
}

function AddedDocuments(props) {
  const { translate } = useContext(GlobalizationContext)

  const { docs, label, removeFile, viewFile } = props
  return (
    <>
      {docs && docs.length > 0 && <Label>{translate(label)}</Label>}
      {docs &&
        docs.map((item, index) => (
          <DocumentWrapper key={index}>
            <Content>
              <Text>{item.fileName || item.file.name}</Text>
              <Actions>
                {viewFile ? (
                  <EyeIcon
                    src={Eye}
                    alt={'Visualizar'}
                    onClick={() => viewFile(item.file || item)}
                  />
                ) : null}
                {removeFile ? (
                  <TrashIcon
                    src={Trash}
                    alt={'Remover'}
                    onClick={() => removeFile(item)}
                  />
                ) : null}
              </Actions>
            </Content>
          </DocumentWrapper>
        ))}
    </>
  )
}

export default AddedDocuments
