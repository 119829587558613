import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Grid } from '@material-ui/core'

import { fileUploadConfig } from 'helpers/constants'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import institutionTypeList from 'helpers/institutionTypeList'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import { previewFile } from 'ui/atoms/AddedDocuments'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle } from 'ui/atoms/Title'

import { requestSharingUseCases } from '../../providers'
import { formSchema } from './formSchema'
import Laboratory from './Fragments/Laboratory'
import Requester from './Fragments/Requester'
import RequesterInstitution from './Fragments/RequesterInstitution'
import Sample from './Fragments/Sample'
import Solicitation from './Fragments/Solicitation'

const RequestSharingForm = ({
  params,
  initialData = {},
  readOnly = false,
  onSubmit: onSubmitProp = async () => {},
  onCancel: onCancelProp = () => {}
}) => {
  const { translate } = useContext(GlobalizationContext)
  const [currentLaboratory, setCurrentLaboratory] = useState(null)

  const [complementaryFiles, setComplementaryFiles] = useState([])
  const [deletedComplementaryFiles, setDeletedComplementaryFiles] = useState([])

  const [materialDataSafetyFiles, setMaterialDataSafetyFiles] = useState([])
  const [deletedMaterialDataSafetyFiles, setDeletedMaterialDataSafetyFiles] =
    useState([])

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    context: { currentLaboratory, institutionTypeList },
    resolver,
    defaultValues: {}
  })

  const { clearErrors, handleSubmit, getValues, reset } = formMethods

  const handleLaboratoryChange = useCallback(
    async (evt, option, controllerOnChange) => {
      controllerOnChange(option)
      const response = await requestSharingUseCases.getLabById(option.id)
      if (response) {
        reset({
          ...getValues(),
          specificEquipment: [],
          requiresSpecificEquipment: null,
          laboratory: option
        })
        setCurrentLaboratory(response)
      }
    },
    [getValues, reset]
  )

  useEffect(() => {
    const { initialFormData = {}, currentLaboratory } = initialData

    const { additionalDocumentList, msdsDocumentList, ...formData } =
      initialFormData

    if (currentLaboratory) {
      setCurrentLaboratory(currentLaboratory)
    }

    setComplementaryFiles(additionalDocumentList || [])
    setMaterialDataSafetyFiles(msdsDocumentList || [])

    reset(formData)
  }, [
    params,
    initialData,
    setComplementaryFiles,
    setMaterialDataSafetyFiles,
    reset
  ])

  const onSubmit = async formData => {
    onSubmitProp({
      formData,
      formMethods,
      complementaryFiles,
      deletedComplementaryFiles,
      materialDataSafetyFiles,
      deletedMaterialDataSafetyFiles
    })
  }

  const handleSelectedFile = ({ field, setter }) => {
    return fileList => {
      clearErrors(field)
      if (!fileList || !fileList.length) return
      setter(documents => documents.concat(fileList))
    }
  }

  const handleRemoveFile = ({ field, setter, removedSetter }) => {
    return file => {
      clearErrors(field)

      if (file.id) {
        removedSetter(fileList => fileList.concat(file.id))
      }

      setter(currentFiles => currentFiles.filter(item => item !== file))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Subtitle item xs={12}>
          {translate('REGISTER.LABORATORY.SUBTITLE')}
        </Subtitle>

        <Laboratory
          readOnly={readOnly}
          formMethods={formMethods}
          onChange={handleLaboratoryChange}
        />

        <Subtitle item xs={12}>
          {translate('REGISTER.SOLICITATION.SUBTITLE')}
        </Subtitle>

        <Solicitation
          readOnly={readOnly}
          equipmentList={currentLaboratory?.equipmentList || []}
          removedEquipmentList={initialData.removedEquipments}
          formMethods={formMethods}
          relatedDocumentsConfig={{
            fileConfig: fileUploadConfig.sharingComplementaryFiles,
            onFileSelected: handleSelectedFile({
              field: 'complementaryFiles',
              setter: setComplementaryFiles
            }),
            onRemoveFile: handleRemoveFile({
              field: 'complementaryFiles',
              setter: setComplementaryFiles,
              removedSetter: setDeletedComplementaryFiles
            }),
            documents: complementaryFiles,
            previewFile
          }}
        />

        <Subtitle item xs={12}>
          {translate('REGISTER.SAMPLE.SUBTITLE')}
        </Subtitle>

        <Sample
          readOnly={readOnly}
          params={params}
          formMethods={formMethods}
          materialDataSafetyConfig={{
            fileConfig: fileUploadConfig.sharingMaterialDataSafetyFiles,
            onFileSelected: handleSelectedFile({
              field: 'materialDataSafetyFiles',
              setter: setMaterialDataSafetyFiles
            }),
            onRemoveFile: handleRemoveFile({
              field: 'materialDataSafetyFiles',
              setter: setMaterialDataSafetyFiles,
              removedSetter: setDeletedMaterialDataSafetyFiles
            }),
            documents: materialDataSafetyFiles,
            previewFile
          }}
        />

        <Subtitle item xs={12}>
          {translate('REGISTER.REQUESTER.SUBTITLE')}
        </Subtitle>

        <Requester readOnly={readOnly} formMethods={formMethods} />

        <Subtitle item xs={12}>
          {translate('REGISTER.REQUESTER_INSTITUTION.SUBTITLE')}
        </Subtitle>

        <RequesterInstitution
          readOnly={readOnly}
          formMethods={formMethods}
          currentLaboratory={currentLaboratory}
        />
        {!readOnly ? (
          <Grid item container justify="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={onCancelProp}
              >
                {translate('COMMONS:CANCEL')}
              </Button>
            </Grid>
            <Grid item style={{ paddingLeft: '12px' }}>
              <Button variant="contained" color="primary" type="submit">
                {translate('REGISTER.SEND')}
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </form>
  )
}

export default RequestSharingForm
