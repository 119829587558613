import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import ControlLabel from 'ui/atoms/ControlLabel'
import { useGlobalizationContext } from 'ui/atoms/Globalization'

const UserRoles = ({ formMethods }) => {
  const { translate } = useGlobalizationContext()

  const { control } = formMethods

  return (
    <Grid container spacing={3} style={{ marginBottom: '20px' }}>
      <Grid item xs={12}>
        <Typography variant="h4">{translate('USER_ROLES.TITLE')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {translate('USER_ROLES.DESCRIPTION')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ControlLabel control={control} name="hasRoleAdmin" type="Switch">
          {translate('USER_ROLES.ROLE.ADMIN')}
        </ControlLabel>
      </Grid>
      <Grid item xs={12}>
        <ControlLabel control={control} name="hasRoleGestor" type="Switch">
          {translate('USER_ROLES.ROLE.GESTOR_ACOES')}
        </ControlLabel>
      </Grid>
    </Grid>
  )
}

export default UserRoles
