import React from 'react'
import { Trans } from 'react-i18next'

import { Grid, SvgIcon, Typography } from '@material-ui/core'

import TitleIcon from 'ui/atoms/BasePublic/TitleIcon'
import { useGlobalizationContext } from 'ui/atoms/Globalization'
import { NoResults } from 'ui/atoms/Table'

import { ReactComponent as AtomIcon } from 'assets/icons/atom.svg'
import AvatarIcon from 'assets/icons/avatar.svg'
import RequestSharingIcon from 'assets/icons/requestSharing.svg'
import ShareIcon from 'assets/icons/share.svg'

import { Text, CardContainer, Card, ImageContainer } from '../../style'
import CardTitle from '../CardTitle'

const RequestSharing = () => {
  const { translate } = useGlobalizationContext()

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <TitleIcon
            icon={
              <SvgIcon
                component={AtomIcon}
                fontSize="large"
                viewBox="0 0 32 32"
              />
            }
            content="REQUEST_SHARING.TITLE"
            iconName={undefined}
            size={undefined}
          />
        </Grid>
      </Grid>

      <CardContainer>
        <Card>
          <CardTitle
            text={translate('REQUEST_SHARING.COLUMN_SUBTITLE_1')}
            titleNumber={1}
          />
          <ImageContainer>
            <img src={ShareIcon} alt="" />
          </ImageContainer>
          <Trans
            parent={Text}
            t={translate}
            i18nKey="REQUEST_SHARING.COLUMN_TEXT_1"
          />
        </Card>
        <Card>
          <CardTitle
            text={translate('REQUEST_SHARING.COLUMN_SUBTITLE_2')}
            titleNumber={2}
          />
          <ImageContainer>
            <img src={RequestSharingIcon} alt="" />
          </ImageContainer>
          <Trans
            parent={Text}
            t={translate}
            i18nKey="REQUEST_SHARING.COLUMN_TEXT_2"
          />
        </Card>
        <Card>
          <CardTitle
            text={translate('REQUEST_SHARING.COLUMN_SUBTITLE_3')}
            titleNumber={3}
          />
          <ImageContainer>
            <img src={AvatarIcon} alt="" />
          </ImageContainer>
          <Trans
            parent={Text}
            t={translate}
            i18nKey="REQUEST_SHARING.COLUMN_TEXT_3"
          />
        </Card>
      </CardContainer>
      <NoResults>
        <Trans
          parent={Typography}
          // @ts-ignore
          variant="subtitle2"
          color="inherit"
          t={translate}
          i18nKey="REQUEST_SHARING.FOOTNOTE"
        />
      </NoResults>
      {/* <Box py={3}>
        <Paper
          elevation={3}
          style={{ background: primaryColor, color: 'white' }}
        >
          <Box p={2}>
            <Trans
              parent={Typography}
              // @ts-ignore
              variant="subtitle2"
              color="inherit"
              t={translate}
              i18nKey="REQUEST_SHARING.FOOTNOTE"
            />
          </Box>
        </Paper>
      </Box> */}
    </>
  )
}

export default RequestSharing
