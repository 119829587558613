import styled from 'styled-components'

import Select from '@material-ui/core/Select'

import { fontSize, textPrimaryColor } from 'helpers/constants'

export const Label = styled.label`
  color: ${textPrimaryColor};
  display: block;
  font-weight: 600;
  font-size: ${fontSize};
  line-height: 16px;
  margin-bottom: 8px;
  margin-top: 3px;
`

export const RequiredSelect = styled(Select)`
  &:before {
    content: '\f069';
    font-family: 'Font Awesome 5 Free';
    font-size: 5px;
    font-weight: 600;
    position: absolute;
    left: -12px;
    top: 9px;
  }
`

export const EmailContent = styled.div`
  margin-top: 32px;
`
