import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useDropzone } from 'react-dropzone'

import { Grid } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import Add from 'assets/icons/add.svg'

import { AddIcon, Label, DropContainer, Text, UploadInput } from './styles'

function DocumentUploader(props) {
  const { translate } = useContext(GlobalizationContext)

  const {
    onChange: handleOnChange,
    currentListLength,
    filesConfig,
    label,
    wrapperDescription,
    marginBottom,
    errorStyle
  } = props

  const [maxFilesExceededError, setMaxFilesExceededError] = useState(false)
  const [fileMaxSizeExceededError, setFileMaxSizeExceededError] =
    useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)

  let extensions = Object.keys(filesConfig.extensions)
  const accept = extensions.length ? extensions.join(', ') : '*/*'
  const acceptAll = accept.indexOf('*/*') >= 0

  useEffect(() => {
    setMaxFilesExceededError(false)
  }, [currentListLength])

  const onFilesSelected = useCallback(
    selectedFiles => {
      setFileMaxSizeExceededError(false)
      setFileExtensionError(false)

      const files = selectedFiles.filter(file => {
        if (acceptAll) return true
        if (extensions.indexOf(file.type) >= 0) return true
        else setFileExtensionError(true)
      })

      if (files && files.length) {
        if (currentListLength + files.length > filesConfig.maxFiles) {
          setMaxFilesExceededError(true)
          return
        }

        const fileList = [...files]

        const biggerThanAllowedList = fileList.filter(file => {
          return filesConfig.extensions[file.type] < file.size
        })

        if (biggerThanAllowedList.length) {
          setFileMaxSizeExceededError(true)
          return
        }

        handleOnChange(fileList.map(file => ({ file })))
      }
    },
    [
      acceptAll,
      currentListLength,
      extensions,
      filesConfig.extensions,
      filesConfig.maxFiles,
      handleOnChange
    ]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFilesSelected
  })

  const extensionsName = extensions.map(extension => {
    return extension.replace('image/', '').replace('application/', '')
  })

  return (
    <>
      <Label>{translate(label)}</Label>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: marginBottom || '20px' }}
      >
        <Grid item xs={12} md={12}>
          <DropContainer {...getRootProps()}>
            <UploadInput
              id="file"
              type="file"
              {...getInputProps({
                multiple: true,
                // onChange: onFilesChanged,
                accept: accept
              })}
            />
            <Text errorStyle={errorStyle}>
              {translate(wrapperDescription)}
              <AddIcon src={Add} alt={'Adicionar'} />
            </Text>
          </DropContainer>
        </Grid>
        {maxFilesExceededError ? (
          <Grid item xs={12} md={12}>
            <div>
              <FormHelperText error>
                {translate('FILE_UPLOAD_MAX_LIMIT_REACHED', {
                  number: filesConfig.maxFiles
                })}
              </FormHelperText>
            </div>
          </Grid>
        ) : null}
        {fileMaxSizeExceededError ? (
          <Grid item xs={12} md={12}>
            <div>
              <FormHelperText error>
                {translate('FILE_UPLOAD_MAX_SIZE_LIMIT_REACHED')}
              </FormHelperText>
            </div>
          </Grid>
        ) : null}
        {fileExtensionError ? (
          <Grid item xs={12} md={12}>
            <div>
              <FormHelperText error>
                {translate('FILE_UPLOAD_INVALID_EXTENSION', {
                  extensions: extensionsName.join(', ')
                })}
              </FormHelperText>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </>
  )
}

export const uploadFilesInSequence = async (uploadMethod, id, list) => {
  const uploadedFiles = await list.reduce(
    async (promise, { file, ...savedFile }, index) => {
      return promise
        .then(uploadedFiles => {
          if (savedFile && savedFile.id) {
            return Promise.resolve(uploadedFiles.concat(file))
          } else {
            return uploadMethod(id, file)
              .then(resp => {
                return uploadedFiles.concat(resp.data)
              })
              .catch(() => {
                return uploadedFiles.concat(file)
              })
          }
        })
        .then(data => {
          return data
        })
    },
    Promise.resolve([])
  )

  return uploadedFiles
}

export const uploadFiles = async (uploadMethod, id, list) => {
  return Promise.all(
    list.map(async ({ file, ...savedFile }) => {
      if (savedFile && savedFile.id) {
        return savedFile
      } else {
        try {
          const uploadedFile = await uploadMethod(id, file)
          return uploadedFile.data
        } catch {
          return { file }
        }
      }
    })
  )
}

export const uploadImages = async (uploadMethod, id, list) => {
  return Promise.all(
    list.map(async file => {
      try {
        const uploadedFile = await uploadMethod(id, file)
        return uploadedFile.data
      } catch {
        return { file }
      }
    })
  )
}

export const updateImages = async (updateMethod, list) => {
  return Promise.all(
    list.map(async file => {
      try {
        const uploadedFile = await updateMethod(file)
        return uploadedFile.data
      } catch {
        return { file }
      }
    })
  )
}

export const deleteFilesInSequence = async (deleteMethod, list) => {
  const deletedFiles = await list.reduce(async (promise, fileId) => {
    return promise.then(deletedFiles => {
      return deleteMethod(fileId)
        .then(resp => deletedFiles.concat(resp.data))
        .catch(error => {
          return deletedFiles.concat({
            fileId,
            error: error
          })
        })
    })
  }, Promise.resolve([]))

  return deletedFiles
}

export const deleteFiles = async (deleteMethod, list) => {
  return Promise.all(
    list.map(fileId => {
      return deleteMethod(fileId).catch(error => {
        return {
          fileId,
          error: error
        }
      })
    })
  )
}

export default DocumentUploader
