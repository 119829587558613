import styled from 'styled-components'

import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'

import {
  fontFamily,
  fontSize,
  primaryColor,
  textPrimaryColor
} from 'helpers/constants'

import { Subtitle } from 'ui/atoms/Title'

export const Text = styled.p`
  color: #333;
  font-size: 14px;
  a {
    //text-decoration: none;
    color: ${primaryColor};
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
`

export const TitleText = styled.p`
  color: #009c80;
  font-size: 14px;
  font-weight: bold;
`

export const TextBox = styled(Box)`
  margin: 20px;
`

export const TextContainer = styled(Grid)`
  &.MuiGrid-item {
    padding: 0 12px;
  }
`
export const TextSubTitle = styled(Subtitle)`
  padding-bottom: 0;
  padding-left: 0;
`

export const CardContainer = styled(Grid).attrs({
  container: true,
  spacing: 3
})`
  justify-content: center;
  flex-wrap: wrap;
`

export const Card = styled(Grid).attrs({
  item: true,
  sm: 6,
  md: 4
})``

export const ImageContainer = styled.div`
  height: 120px;
  text-align: center;
  img {
    margin: 20px;
  }
`

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
`

export const TextCounter = styled.span`
  background-color: ${primaryColor};
  border-radius: 50%;
  color: #fff;
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: 600;
  margin-right: 10px;
  padding: 5px 10px;
`

export const SectionSubtitle = styled.h4`
  color: ${textPrimaryColor};
  font-weight: 600;
  margin: 15px 0;
`
