import React, { useContext } from 'react'

import { Grid } from '@material-ui/core'

import { phone } from 'helpers/generalInputMasks'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const Requester = ({ readOnly, formMethods }) => {
  const { translate } = useContext(GlobalizationContext)
  const { control, errors } = formMethods

  return (
    <>
      <Grid item xs={12} md={3}>
        <BaseTextField
          name="requesterCPF"
          label={translate('REGISTER.REQUESTER.CPF')}
          control={control}
          errors={errors}
          required
          disabled
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BaseTextField
          name="requesterFirstName"
          label={translate('REGISTER.REQUESTER.FIRST_NAME')}
          control={control}
          errors={errors}
          required
          disabled
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <BaseTextField
          name="requesterLastName"
          label={translate('REGISTER.REQUESTER.LAST_NAME')}
          control={control}
          errors={errors}
          required
          disabled
        />
      </Grid>

      <Grid item xs={12} md={7}>
        <BaseTextField
          disabled={readOnly}
          name="requesterEmail"
          label={translate('REGISTER.REQUESTER.EMAIL')}
          placeholder={translate('COMMONS:TYPE')}
          control={control}
          errors={errors}
          required
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <BaseTextField
          disabled={readOnly}
          name="requesterPhone"
          label={translate('REGISTER.REQUESTER.PHONE')}
          placeholder={translate('COMMONS:TYPE')}
          control={control}
          errors={errors}
          required
          handleInput={phone}
        />
      </Grid>

      <Grid item xs={12}>
        <BaseTextField
          disabled={readOnly}
          name="requesterLattesLink"
          label={translate('REGISTER.REQUESTER.LATTES_LINK')}
          placeholder={translate('COMMONS:TYPE_URL')}
          control={control}
          errors={errors}
        />
      </Grid>
    </>
  )
}

export default Requester
