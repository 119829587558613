import styled from 'styled-components'

import MuiAvatar from '@material-ui/core/Avatar'

import { primaryColorLight } from 'helpers/constants'

export default styled(MuiAvatar)`
  background-color: ${primaryColorLight};
  color: #ffffff;
  font-weight: 600;
`
