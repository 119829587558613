// @ts-ignore
import { Auth } from 'aws-amplify'
import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import { Hidden, Typography } from '@material-ui/core'
import { Link as MuiLink } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { UsersService } from 'api'
import { AuthContext } from 'context/AuthContext'
import { generatePrivatePath } from 'helpers'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Loader from 'ui/atoms/Loader'
import wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import Header from 'ui/organisms/Header/Private'
import useStyles from 'ui/styles'

const formSchema = Yup.object().shape({
  userName: Yup.string().required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório')
})

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()
  const redirectTo = query.get('redirectTo')
  const { translate } = useContext(GlobalizationContext)
  const [hasLoginError, setHasLoginError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { setChallengedUser } = useContext(AuthContext)

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onSubmit',
    resolver
  })

  const { control, errors, handleSubmit } = formMethods

  const handleOnClose = () => {
    history.push('/')
  }

  const onSubmit = async ({ userName, password }) => {
    try {
      setHasLoginError(false)
      setIsLoading(true)

      const user = await Auth.signIn(userName, password)

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setChallengedUser(user)
        history.push('/change-password')
        return
      }

      await UsersService.getMe()

      if (redirectTo) {
        history.push(redirectTo)
      } else {
        history.push(generatePrivatePath(''))
      }
    } catch (error) {
      await Auth.signOut()
      setIsLoading(false)
      setHasLoginError(true)
    }
  }

  return (
    <React.Fragment>
      <Loader open={isLoading}></Loader>
      <Header hideActions={true} showBurger={false} />
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} sm={10}>
            <BaseContainerHeader
              label={translate('TITLE')}
              showCloseButton={true}
              onClose={handleOnClose}
              style={{ paddingBottom: '20px' }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={10}
            container
            style={{ paddingBottom: '30px' }}
          >
            <Hidden smDown>
              <Grid item sm={2} />
            </Hidden>
            <Grid item xs={12} sm={8}>
              <Typography variant="body2">{translate('SUBTITLE')}</Typography>
            </Grid>
            <Hidden smDown>
              <Grid item sm={2} />
            </Hidden>
          </Grid>

          <Grid
            item
            container
            spacing={3}
            xs={12}
            sm={10}
            justify="center"
            className={classes.defaultContainer}
          >
            <Grid item md={6}>
              <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
                <input type="hidden" autoComplete="false" />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <BaseTextField
                      required={true}
                      maxLength={255}
                      label={translate('USER_NAME')}
                      placeholder={translate('COMMONS:TYPE')}
                      errors={errors}
                      control={control}
                      name="userName"
                      autoComplete="off"
                    ></BaseTextField>
                  </Grid>

                  <Grid item xs={12}>
                    <BaseTextField
                      required={true}
                      label={translate('PASSWORD')}
                      placeholder={translate('COMMONS:TYPE')}
                      errors={errors}
                      control={control}
                      type="password"
                      name="password"
                      autoComplete="off"
                    ></BaseTextField>
                  </Grid>

                  {hasLoginError ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" color="error">
                        {translate('LOGIN_INVALID_CREDENTIALS')}
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      type={'submit'}
                      variant="contained"
                      className={classes.baseButton}
                    >
                      {translate('SIGN_IN')}
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {translate('NEEDS_HELP')}{' '}
                      <MuiLink component={Link} to="/suport/contact-us">
                        suporte
                      </MuiLink>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <MuiLink
                      component={Link}
                      variant="h4"
                      to="/request-new-password"
                    >
                      {translate('FORGOT_PASSWORD')}
                    </MuiLink>
                  </Grid>

                  <Grid item xs={12}>
                    <MuiLink component={Link} variant="h4" to="/register">
                      {translate('SIGN_UP')}
                    </MuiLink>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}
export default wrapper(Login, { namespace: 'LOGIN' })
