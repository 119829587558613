import axios from './axios'

export const InstitutionalInvite = {
  postInvite(objInvite) {
    return axios.post('/institution-user/', objInvite)
  },
  getUsersByCnpj(cnpj) {
    return axios.get('/institution-user/institution/' + cnpj)
  },
  getEmailAlreadySent(cnpj, email) {
    return axios.get(`/institution-user/verify/institution/${cnpj}`, {
      params: { email }
    })
  }
}
export default InstitutionalInvite
