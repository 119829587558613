import React, { useContext } from 'react'
import styled from 'styled-components'

import { Grid } from '@material-ui/core'

import { EquipmentService } from 'api'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import AutoCompleteChipInput from 'ui/molecules/commons/AutoCompleteChipInput'

const Title = styled.h3`
  margin-top: 25px;
`

const EquipmentAbout = props => {
  const { fieldDisabled, classes, formMethods } = props
  const { translate } = useContext(GlobalizationContext)
  const { control, errors } = formMethods

  const mainTechniqueSuggestionGetter = ({ value }) => {
    return EquipmentService.getAllMainTechnique(value).then(resp => resp.data)
  }

  return (
    <>
      <Title className={classes.h3}>
        {translate('REGISTER_EQUIPMENT_ABOUT_LABEL')}
      </Title>
      <Grid item xs={12}>
        <BaseTextField
          disabled={fieldDisabled['about']}
          rows={5}
          required={true}
          label={translate('REGISTER_EQUIPMENT_ABOUT_DESCRIPTION')}
          placeholder={translate('REGISTER_EQUIPMENT_ABOUT_DESCRIPTION')}
          errors={errors}
          control={control}
          name="about"
          maxLength={500}
        ></BaseTextField>
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: '20px' }}>
        <AutoCompleteChipInput
          name="techniqueList"
          control={control}
          disabled={fieldDisabled['techniqueList']}
          suggestionGetter={mainTechniqueSuggestionGetter}
          titleAdd={translate('REGISTER_EQUIPMENT_ADD_MAIN_TECHNIQUE')}
          title={translate('REGISTER_EQUIPMENT_MAIN_TECHNIQUE')}
          errors={errors}
          maxLength="20"
        />
      </Grid>
    </>
  )
}

export default EquipmentAbout
