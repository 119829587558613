const translate = {
  COMMONS: {
    CANCEL: 'Cancelar',
    SAVE: 'Salvar',
    CLOSE: 'Fechar',
    DELETE: 'Excluir',
    SEND: 'Enviar',
    EDIT: 'Editar',
    WARNING: 'Atenção',
    GOBACK: 'Voltar',
    ADD: 'Adicionar',
    CONTINUE: 'Continuar',

    YES: 'Sim',
    NO: 'Não',
    DOES_NOT_APPLY: 'Não se aplica',

    OK: 'Ciente',

    ACTIONS: 'Ações',
    NO_RESULTS: 'Nenhum registro encontrado',

    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',

    UPDATED: 'Atualizados',
    OUT_OF_DATE: 'Desatualizados',
    UPDATE_NEEDED: 'Necessitando de atualização',
    FORBIDDEN_TEXT:
      'Usuário sem permissão de acesso à funcionalidade.<br /> <Link>“Ir para o meu painel”</Link>.',

    // PENDING: 'Pendente',
    // DELETED: 'Deletado',
    // APPROVED: 'Aprovado',
    // WAITING_APPROVAL: 'Pendente de análise',
    // WAITING_PUBLICATION: 'Aguardando Publicação',
    // WAITING_EDIT_APPROVAL: 'Aguardando aprovação de edição',
    // EDIT_REJECTED: 'Edição Rejeitada',
    // PUBLISHED: 'Publicado',
    // UNPUBLISHED: 'Despublicado',
    // REJECTED: 'Recusado',
    // EXCLUDED: 'Excluído',
    // HISTORY: 'Histórico',

    LABORATORY: 'Laboratório',
    LABORATORIES: 'Laboratórios',
    INSTITUTION: 'Instituição',

    CREATION_DATE: 'Data de criação',
    LAST_UPDATED: 'Última atualização',

    TOTAL: 'Total',
    PARTIAL: 'Parcial',
    LIMITED: 'Limitado',

    DEFAULT_SUCCESS: 'Operação realizada com sucesso!',

    SELECT: 'Selecione...',
    INFORM: 'Informe...',
    TYPE: 'Insira...',
    TYPE_DATE: 'dd/mm/aaaa',
    TYPE_PHONE: 'Insira número celular ou fixo',
    TYPE_URL: 'Insira a URL',
    NO_REGISTER_FOUND: 'Nenhum registro encontrado',
    ADD_NEW: 'Adicionar novo {{newValue}}',
    TYPE_AT_LEAST: 'Digite ao menos {{qty}} caracteres',

    REGISTER_ADD_BUTTON: 'Adicionar',
    REGISTER_NONE_REGISTER: 'Nenhum registro encontrado',
    MAX_LIMIT_REACHED: 'Limite máximo atingido',
    MAX_ITEMS_LIMIT_REACHED:
      'Quantidade máxima de {{limit}} itens já adicionados',
    REPRESENTATIVE_LIST: {
      ARIA_LABEL: 'Tabela de responsáveis'
    },
    FILTER: 'Filtrar por',
    LANGUAGES: {
      PT: 'Português - BR',
      EN: 'English'
    },
    ADDRESS: 'Endereço',
    CONTACT: 'Contato',
    PHONE: 'Telefone',
    SITUATION: {
      ACTIVE: 'Ativo',
      INACTIVE: 'Inativo',
      DELETED: 'Excluido',
      REJECTED: 'Recusado',
      WAITING_APPROVAL: 'Aguardando aprovação',
      WAITING_PUBLICATION: 'Aguardando publicação',
      PENDING: 'Pendente',
      PUBLISHED: 'Publicado',
      UNPUBLISHED: 'Despublicado',
      WAITING_EDIT_APPROVAL: 'Aguardando aprovação de alterações',
      EDIT_REJECTED: 'Alterações rejeitadas',
      IN_ACTIVATION: 'Em ativação'
    }
  },
  REGIONS: {
    MIDWEST: 'Centro-oeste',
    NORTHEAST: 'Nordeste',
    NORTH: 'Norte',
    SOUTHEAST: 'Sudeste',
    SOUTH: 'Sul'
  },
  STATES: {
    AC: 'Acre',
    AL: 'Alagoas',
    AP: 'Amapá',
    AM: 'Amazonas',
    BA: 'Bahia',
    CE: 'Ceará',
    ES: 'Espírito Santo',
    GO: 'Goiás',
    MA: 'Maranhão',
    MT: 'Mato Grosso',
    MS: 'Mato Grosso do Sul',
    MG: 'Minas Gerais',
    PA: 'Pará',
    PB: 'Paraíba',
    PR: 'Paraná',
    PE: 'Pernambuco',
    PI: 'Piauí',
    RJ: 'Rio de Janeiro',
    RN: 'Rio Grande do Norte',
    RS: 'Rio Grande do Sul',
    RO: 'Rondônia',
    RR: 'Roraima',
    SC: 'Santa Catarina',
    SP: 'São Paulo',
    SE: 'Sergipe',
    TO: 'Tocantins',
    DF: 'Distrito Federal'
  },
  VALIDATION: {
    REQUIRED_ERROR: 'Campo obrigatório',
    EMAIL_ERROR: 'E-mail inválido',
    CPF_ERROR: 'CPF inválido',
    CNPJ_ERROR: 'CNPJ inválido',
    INVALID_DATE: 'Data inválida',
    PHONE_ERROR: 'Telefone inválido.',

    MAX_EXCEEDED: 'Deve ser no máximo {{max}}',
    MIN_EXCEEDED: 'Deve ser ao menos {{min}}',

    GREATER_THAN_TODAY_ERROR: 'A data deve ser maior que a data atual',
    LOWER_THAN_TODAY_ERROR: 'A data deve ser menor que a data atual',
    GREATER_OR_EQUAL_THAN_TODAY_ERROR:
      'A data deve ser maior ou igual a data atual',
    LOWER_OR_EQUAL_THAN_TODAY_ERROR:
      'A data deve ser menor ou igual a data atual',
    GREATER_OR_EQUAL_THAN_INITIAL_ERROR:
      'A data inicial não pode ser maior que a data final',

    PASSWORD_CONTAINS_EMAIL_OR_NAME: 'Senha não pode conter Email ou seu Nome',
    PASSWORD_MUST_MATCH: 'As senhas não conferem',
    FORMAT_PASSWORD: 'Senha com formato inválido',
    INITIALS_ALREADY_TAKEN: 'Laboratório já cadastrado',
    NAME_ALREADY_TAKEN: 'Laboratório já cadastrado',
    INITIALS_AWAITING:
      'Laboratório possui solicitação pendente de análise. Por gentileza, aguarde o retorno.',
    NAME_AWAITING:
      'Laboratório possui solicitação pendente de análise. Por gentileza, aguarde o retorno.',
    NAME_AWAITING_VALIDATION:
      'Laboratório possui solicitação pendente de Análise. Por gentileza, aguarde o retorno',
    ZIPCODE_NOT_FOUND: 'CEP não encontrado',
    ZIPCODE_INVALID: 'CEP inválido',
    FORM_NEEDS_SAVING:
      'É necessário salvar esse formulário para concluir a operação.'
  },
  NOTIFICATIONS: {
    PERMISSION: {
      ACTIVE: 'Ativada',
      INACTIVE: 'Inativada'
    },

    OK: 'Ciente',
    VERIFY: 'Verificar',
    COMPLEMENT: 'Complementar',
    NOTIFICATION_EMPTY_LIST: 'Não há notificações no momento',
    NOTIFICATION_ACCEPT: 'Aceitar',
    NOTIFICATION_REJECT: 'Recusar',

    INVITE_INSTITUTION_TITLE: 'Convite para colaborar',
    INVITE_INSTITUTION:
      'Um convite para integrar a gestão da instituição <strong>{{institution}}</strong> aguarda seu aceite',

    CREATE_LAB_TITLE: 'Solicitação de inclusão de laboratório',
    CREATE_LAB: 'Solicitação de inclusão de laboratório aguarda aprovação',

    MODIFICATION_LAB_TITLE: 'Solicitação de Ajuste',
    MODIFICATION_LAB: 'Solicitação de Ajuste de laboratório aguarda aprovação',

    INVITE_LABORATORY_TITLE: 'Convite para colaborar',
    INVITE_LABORATORY:
      'Um convite para integrar a gestão do laboratório <strong>{{laboratory}}.</strong> aguarda seu aceite.',

    COMPLEMENT_INSTITUTION_TITLE: 'Complementar cadastro',
    COMPLEMENT_INSTITUTION:
      'Complemente o cadastro da instituição, para adicionar novos responsáveis e incluir o endereço na opção <strong>“Instituição”</strong>',

    EXCLUSION_USER_ADMIN_TITLE: 'Exclusão de permissão',
    EXCLUSION_USER_ADMIN:
      'Sua permissão como gestor da plataforma foi revogada:.<br />Perfil(is): <strong>{{roles}}</strong>',

    USER_REMOVED_OWN_INSTITUTION_PERMISSION_TITLE: 'Atualização de responsável',
    USER_REMOVED_OWN_INSTITUTION_PERMISSION:
      'O usuário <strong>{{userName}}</strong> removeu de suas próprias atribuições a permissão responsável <strong>{{permission}}</strong>, portanto não faz mais parte da gestão da instituição <strong>{{institutionName}}.</strong>',
    EXCLUSION_OWN_USER_ADMIN_TITLE:
      'Exclusão de própria permissão de usuário para instituição',
    EXCLUSION_OWN_USER_ADMIN:
      'O usuário <strong>{{userName}}</strong> removeu de suas próprias atribuições a permissão de <strong>{{roles}}</strong>, portanto não faz mais parte da gestão da plataforma.',

    USER_PERMISSION_INSTITUTION_REMOVED_TITLE: 'Atualização de responsável',
    USER_PERMISSION_INSTITUTION_REMOVED:
      'A sua permissão como responsável da instituição <strong>{{institutionName}}</strong> foi revogada',

    USER_PERMISSION_INSTITUTION_CHANGED_TITLE: 'Atualização de responsável',
    USER_PERMISSION_INSTITUTION_CHANGED:
      'A sua permissão da instituição <strong>{{institutionName}}</strong> foi alterada para responsável <strong>{{permission}}</strong>',

    USER_SITUATION_INSTITUTION_CHANGED_TITLE: 'Atualização de responsável',
    USER_SITUATION_INSTITUTION_CHANGED:
      'A sua permissão como responsável <strong>{{permission}}</strong> da instituição <strong>{{institutionName}}</strong> foi <strong>{{situation}}</strong>',

    COMPLEMENT_LABORATORY_TITLE: 'Publicar laboratório',
    COMPLEMENT_LABORATORY:
      'Seu laboratório <strong>{{laboratoryName}}</strong> foi aprovado e encontra-se aguardando publicação. Edite a página do seu laboratório e comece a cadastrar seus equipamentos para publicá-lo na plataforma.',

    USER_REMOVED_OWN_LABORATORY_PERMISSION_TITLE: 'Atualização de responsável',
    USER_REMOVED_OWN_LABORATORY_PERMISSION:
      'O usuário <strong>{{userName}}</strong> removeu de suas próprias atribuições a permissão responsável <strong>{{permission}}</strong>, portanto não faz mais parte da gestão do laboratório <strong>{{laboratoryName}}</strong>.',

    USER_PERMISSION_LABORATORY_REMOVED_TITLE: 'Atualização de responsável',
    USER_PERMISSION_LABORATORY_REMOVED:
      'A sua permissão como responsável do laboratório <strong>{{laboratoryName}}</strong> foi revogada',

    USER_PERMISSION_LABORATORY_CHANGED_TITLE: 'Atualização de responsável',
    USER_PERMISSION_LABORATORY_CHANGED:
      'A sua permissão do laboratorio <strong>{{laboratoryName}}</strong> foi alterada para responsável <strong>{{permission}}</strong>',

    USER_LABORATORY_SITUATION_CHANGED_TITLE: 'Atualização de responsável',
    USER_LABORATORY_SITUATION_CHANGED:
      'A sua permissão como responsável <strong>{{permission}}</strong> do laboratório <strong>{{laboratoryName}}</strong> foi <strong>{{situation}}</strong>.',

    INVITE_USER_ADMIN_TITLE: 'Atribuição de perfil',
    INVITE_USER_ADMIN:
      'Foi atribuído ao seu usuário permissões como gestor da plataforma.<br />Perfil(is): <strong>{{roles}}</strong>',

    LABORATORY_EXCLUDED_TITLE: 'Laboratório excluído',
    LABORATORY_EXCLUDED:
      'O laboratório <strong>{{laboratoryName}}</strong> foi excluído do sistema pelo usuário <strong>{{author}}</strong><br/ >Motivo: {{description}}',

    USAGE_REQUEST_TITLE: 'Solicitações de utilização',
    USAGE_REQUEST:
      'Existem solicitações de utilização a laboratório aguardando análise.',

    REQUEST_SHARING_ANALYSIS_TITLE: 'Análise da solicitação',
    REQUEST_SHARING_ANALYSIS:
      'A análise da solicitação de utilização foi concluída.',

    REQUEST_SHARING_CHANGE_RESULT_ANALYSIS_TITLE:
      'Solicitação de compartilhamento',
    REQUEST_SHARING_CHANGE_RESULT_ANALYSIS:
      'A análise da solicitação de uso {{code}} da foi alterada pela gestão do laboratório.',

    REQUEST_SHARING_CANCELLATION_TITLE: 'Solicitação de compartilhamento',
    REQUEST_SHARING_CANCELLATION:
      'Existem solicitações que uso que foram canceladas. Acesse a página de acompanhamento de cada solicitação para verificar o motivo',
    INSTITUTION_ANALYSIS_TITLE: 'Solicitação de inclusão de instituição',
    INSTITUTION_ANALYSIS:
      'Solicitação de inclusão de instituição aguarda aprovação',
    INSTITUTION_INACTIVATION_TITLE: 'Inativação de instituição',
    INSTITUTION_INACTIVATION:
      'A instituição  <strong>{{institutionName}}</strong> encerrou sua participação na Plataforma, portanto seus laboratórios e equipamentos não fazem mais partedo catálogo. E suas solicitações de uso a equipamentos foram canceladas.',
    REFUSE_INVITE_LABORATORY_TITLE: 'Recusa ao ativar laboratório.',
    REFUSE_INVITE_LABORATORY:
      'O usuário <strong>{{userName}}</strong> , recusou o convite para gerenciar o laboratório <strong>{{laboratoryName}}</strong> , portanto o laboratório continua inativo para a plataforma por não ter representante.'
  },
  EQUIPMENT: {
    SITUATION: {
      ACTIVE: 'Ativo',
      INACTIVE: 'Inativo',
      UPDATED: 'Atualizado',
      OUT_OF_DATE: 'Desatualizado',
      UPDATE_NEEDED: 'Necessitando de atualização',
      REPLICATED: 'Replicado',
      DELETED: 'Excluido'
    }
  },
  USER_HEADER_SUBMENU: {
    HELLO_USER: 'Olá, {{firstName}} {{lastName}}',
    MY_PROFILE: 'Meus Dados',
    MY_PANEL: 'Meu Painel',
    LOGOUT: 'Sair'
  },
  MY_PANEL: {
    TITLE: 'Meu painel',
    NOTIFICATION_LIST_EMPTY: 'Nenhuma notificação encontrada',

    FILTER_TITLE: 'Filtrar por permissão',

    ROLE_INSTITUICAO_TOTAL_HINT:
      'Representante máximo da instituição frente ao sistema. Tem acesso a todas as funcionalidades referentes a instituição no sistema',

    ROLE_INSTITUICAO_PARCIAL_HINT:
      'Responsável por validar (aprovar ou recusar) se um laboratório cadastrado e seus responsáveis fazem parte da instituição.',

    ROLE_INSTITUICAO_LIMITADA_HINT:
      'Pode visualizar informações da instituição',

    ROLE_LABORATORIO_TOTAL_HINT:
      'Representante máximo do laboratório frente ao sistema. Tem acesso a todas as funcionalidades referentes ao laboratório no sistema',

    ROLE_LABORATORIO_PARCIAL_HINT:
      'Responsável por apoiar na gestão do laboratório.',

    ROLE_LABORATORIO_LIMITADA_HINT:
      'Pode visualizar informações. do laboratório',

    ROLE_INTEGRANTE_HINT:
      'Perfil padrão do sistema, pode solicitar inclusão de novo laboratório.',

    ROLE_ADMIN_HINT:
      'Perfil administrativo que permite gerenciar os dados de todo o sistema.'
  },
  ACADEMIC_EDUCATION: {
    HIGH_SCHOOL_TECHNICAL_PROFESSIONAL:
      'Ensino médio/Profissional de nível técnico',
    GRADUATION: 'Graduação',
    SPECIALIZATION: 'Especialização',
    MASTERS: 'Mestrado',
    PROFESSIONAL_MASTERS: 'Mestrado Profissional',
    DOCTORATE: 'Doutorado',
    POSTDOC: 'Pós-doutorado'
  },
  MY_PROFILE: {
    TITLE: 'Meus dados',

    PROFILE_DATA_TITLE: 'Dados cadastrais',
    NAME: 'Nome',
    LAST_NAME: 'Sobrenome',
    CPF: 'CPF',
    NATIONALITY: 'Nacionalidade',
    BIRTHDATE: 'Data de nascimento',

    COMPLEMENTARY_DATA_TITLE: 'Dados complementares',
    GRADUATION: 'Formação',
    LINK_LATTES: 'Link para o currículo Lattes',
    ORCID_ID: 'Identificador ORCID',
    EMAIL: 'E-mail',
    TELEPHONE: 'Telefone',

    PERMISSION_LIST: 'Perfis de usuário',

    TYPE: 'Perfil',
    PERMISSION: 'Permissão',
    INSTITUTION: 'Instituição',
    LABORATORY: 'Laboratório',
    ONLY_RESPONSIBLE: 'Único responsável',
    MAIN_RESPONSIBLE: 'Responsável principal',
    NO_PERMISSIONS: 'Nenhum perfil encontrado',

    DROP_OR_CLICK_TO_CHANGE: 'Clique ou Arraste uma imagem',
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',

    CONFIRM_EMAIL_CHANGE:
      'Ao alterar seu e-mail, será necessário revalidar sua conta seguindo os passos enviados para o novo e-mail cadastrado. Deseja continuar?'
  },
  ERRORS: {
    DEFAULT_ERROR:
      'Não foi possível realizar a operação. Caso o problema persista contate o administrador do sistema.',
    INVALID_USER_SITUATION: 'Usuário inativo na plataforma. ',
    USER_LABORATORY_DELETE_ERROR:
      'Por ser o responsável principal de um laboratório, o usuário não pode ser excluído.',
    USER_LABORATORY_INACTIVATE_ERROR:
      'Por ser o responsável principal de um laboratório, o usuário não pode ser inativado.',
    USER_INSTITUTION_DELETE_ERROR:
      'Por ser o único responsável total de uma instituição, o usuário não pode ser excluído.',
    USER_INSTITUTION_INACTIVATE_ERROR:
      'Por ser o único responsável total de uma instituição, o usuário não pode ser inativado.',
    USER_ADMIN_INACTIVATE_ERROR:
      'Por ser o único administrador do sistema, o usuário não pode ser inativado',
    USER_ADMIN_DELETE_ERROR:
      'Por ser o único administrador do sistema, o usuário não pode ser excluído',
    EXISTING_EMAIL_USER_ERROR: 'E-mail informado já utilizado na plataforma.',
    SPECIAL_CHARS: `Não é permitido o uso dos caracteres especiais <>"{}&@+ neste formulário.`,
    AT_LEAST_ONE_IMAGE_ERROR: 'Ao menos uma imagem deve ser adicionada.',
    EXISTING_USER_ERROR: 'Usuário ja cadastrado',
    INVALID_DATE_BIRTH: 'Data de nascimento inválida',
    RESPONSIBLE_CANNOT_BE_CHANGED: 'Responsável não pode ser alterado',
    DISCLOSE_PLATFORM_ERROR:
      'E-mail de divulgação já enviado para o usuário informado. ',
    DISCLOSE_PLATFORM_DEFAULT_ERROR: 'Erro ao enviar e-mail de divulgação.',
    USER_ADMIN_UNIQUE_ERROR:
      'É obrigatório ter ao menos um Administrador na Plataforma.',
    INVALID_LABORATORY_SITUATION:
      'Já existe solicitação de ajuste pendente de aceite. Aguarde aprovação para realizar uma nova.'
  },
  BREADCRUMBS: {
    ABOUT: 'Sobre a Plataforma',
    SUPPORT: 'Suporte',
    CONTACT_US: 'Entre em Contato',
    EQUIPMENT: 'Equipamento',
    FAQ: 'Perguntas Frequentes',
    HOME: 'Página Inicial',
    INFRASTRUCTURE: 'Infraestrutura',
    LABORATORY: 'Laboratório',
    REGISTER: 'Como Participar', //change here
    REGISTER_USER: 'Criar Conta',
    REQUEST_NEW_PASSWORD: 'Redefinir Senha',
    RESET_PASSWORD: 'Redefinir Senha',
    SEARCH: 'Busca',
    COVID_CAMPAIGN:
      'Plano Nacional de Operacionalização da Vacinação contra a COVID-19.'
  },
  ADDRESS: {
    TITLE: 'Endereço',
    ZIP_CODE: 'CEP',
    STREET: 'Logradouro',
    NUMBER: 'Número',
    COMPLEMENT: 'Complemento',
    DISTRICT: 'Bairro',
    CITY: 'Município',
    STATE_SELECT: 'Selecione um estado',
    INITIALS: 'UF',
    REGION: 'Região',
    REGION_SELECT: 'Selecione uma região',
    ZIP_CODE_MASK: 'XXXXX-XXX'
  },
  EQUIPMENT_REGISTER_DATA: {
    DATA: 'Dados do equipamento',
    CODE: 'Código do equipamento na plataforma',
    LABORATORY_LIST_DEFAULT: 'Laboratório',
    LABORATORY_LIST_LABEL: 'Laboratório',
    SITUATION_LIST_DEFAULT: 'Selecione',
    SITUATION_LIST_LABEL: 'Situação do equipamento',
    OTHER_REASON: 'Outra situação',
    NAME: 'Nome do equipamento',
    NAME_INITIALS: 'Sigla do equipamento',
    OTHER_DENOMINATION_LABEL: 'Outras denominações do equipamento',
    OTHER_DENOMINATION_PLACEHOLDER: 'Adicione...',
    OTHER_DENOMINATION_HINT:
      'Informe outros nomes que o equipamento é conhecido. Informe também seu nome em inglês.',
    TYPE_IDENTIFIER: 'Tipo de identificador persistente',
    IDENTIFIER: 'Identificador persistente do equipamento',
    TYPE_IDENTIFIER_HINT: 'Ex. ISBN, ISSN, DOI...',
    IDENTIFIER_HINT: 'Identificador universal do equipamento',
    BRAND: 'Marca',
    MODEL: 'Modelo',
    YEAR: 'Ano de aquisição',
    MANUFACTURER: 'Fabricante',
    LINK: 'Link do equipamento no site do fabricante',
    USAGE_PROFILE: 'Perfil de uso do equipamento',
    MULTIUSER_LABEL: 'Multiusuário',
    MULTIUSER_HINT:
      'Percentual de carga horária de uso do equipamento (Ex.: semanal, mensal, etc) que pode ser disponibilizada a usuários externos (mediante condições estabelecidas para cada caso).',
    SLIDER_LABEL: 'Dedicado aos projetos internos do laboratório',
    SLIDER_LIMIT_MESSAGE: 'A soma das porcentagens não podem ultrapassar 100%',
    SLIDER_HINT:
      'Percentual de carga horária de uso do equipamento (Ex.: semanal, mensal, etc) dedicada à atividades internas do laboratório.'
  },
  HOME: {
    INSTITUTION: 'Instituições',
    LABORATORY: 'Laboratórios',
    EQUIPMENTS: 'Equipamentos',
    LAB_SUBTITLE:
      'Confira as informações dos laboratórios cadastrados na plataforma',
    EQUIP_SUBTITLE:
      'Confira as informações dos equipamentos catalogados na plataforma.',
    INFO_SECTION_TITLE: 'Sobre a iniciativa',
    INFO_SECTION_INTRO_TEXT:
      'A PNIPE MCTI tem como objetivo reunir informações sobre a infraestrutura de pesquisa nas Instituições Científicas, Tecnológicas e de Inovação (ICTs) no País, possibilitando o acesso da comunidade científica/tecnológica e de empresas e promovendo seu uso compartilhado.',
    BE_PART_BUTTON: 'Faça parte!',
    SCIENCE_SECTION_TITLE: 'Ciência em foco',
    SCIENCE_SECTION_INTRO_TEXT:
      'A PNIPE busca otimizar recursos e dar visibilidade à infraestrutura brasileira, caso faça parte de uma instituição ou laboratório veja <Link>como participar</Link>',
    SCIENCE_SECTION_SUPPORT_TO_SCIENCE_TITLE: 'Apoio à ciência',
    SCIENCE_SECTION_INFRAESTRUCTURE_TITLE: 'Catálogo de Infraestrutura',
    SCIENCE_SECTION_COLLAB_TITLE: 'Colaboração',
    SCIENCE_SECTION_SUPPORT_TO_SCIENCE_TEXT:
      'Com esta iniciativa o MCTI visa obter informações da infraestrutura laboratorial para propiciar políticas públicas.',
    SCIENCE_SECTION_INFRAESTRUCTURE_TEXT:
      'Conheça as instituições, laboratórios e equipamentos que compõem a infraestrutura de pesquisa brasileira. ',
    SCIENCE_SECTION_COLLAB_TEXT:
      'Gerencie ou solicite o compartilhamento de de equipamentos multiusuários pela plataforma.',
    SEARCH_BUTTON: 'Pesquisar'
  },
  BANNER_COVID: {
    TITLE:
      'APOIE O PLANO NACIONAL DE OPERACIONALIZAÇÃO DA VACINAÇÃO CONTRA COVID-19',
    CONTENT:
      'O MCTI disponibiliza a Plataforma Nacional de Infraestrutura de Pesquisa (PNIPE MCTI) para apoio ao Plano Nacional de Operacionalização da Vacinação do MS contra a COVID-19',
    HOW_TO_PARTICIPATE: 'Saiba mais'
  },
  CAMPAIGN_COVID: {
    TITLE:
      'PNIPE MCTI no apoio ao Plano Nacional de Operacionalização da Vacinação contra a COVID-19.',
    ABOUT_TITLE: 'Sobre a iniciativa',
    ABOUT: `
      Com o objetivo de disponibilizar os ultracongeladores/ultrafreezeres existentes nas Instituições de Ciência e Tecnologia (ICTs) do País, O Ministério da Ciência, Tecnologia e Inovações (MCTI), em parceria com o Ministério da Saúde (MS), está realizando o levantamento dessa infraestrutura para verificar a capacidade de armazenamento de vacinas para o Plano Nacional de Operacionalização da Vacinação contra a COVID-19.
      <br/><br/>
      O MCTI irá verificar a disponibilidade e fornecer a localização desses equipamentos, para que o Ministério da Saúde possa considerá-los no apoio estratégico na distribuição das vacinas para cada região do País.`,

    WHANT_TO_HELP_TITLE:
      'Quero disponibilizar ultracongeladores/ultrafreezeres',
    WHANT_TO_HELP: `
      Inclua os equipamentos que deseja disponibilizar para a campanha da seguinte forma:
      <br/>
      <strong>PASSO 1</strong> Faça o login na plataforma
      <br/>
      <strong>PASSO 2</strong> Acesse a página de equipamentos a partir do menu lateral
      <br/>
      <strong>PASSO 3</strong> Clique em <strong>“adicionar ultracongelador covid”</strong> ou em <strong>“editar equipamento”</strong> no caso do ultracongelador já esteja cadastrado na plataforma. 
      <br/>
      <strong>PASSO 4</strong> Preencha os campos “outras denominações” e “parâmetros” presentes no formulário conforme as ilustrações abaixo:
      <br/><br/>
      <strong>IMPORTANTE:</strong> Lembre-se de publicar seu laboratório para efetivar a disponibilização dos equipamentos. 
    `,

    PROCEDURE_REGISTER_EQUIPMENT_TITLE:
      'Preenchimento para cadastrar no sistema',
    OTHER_DENOMINATION_LABEL: 'Outras denominações do equipamento',
    OTHER_DENOMINATION_PLACEHOLDER: 'Adicione...',
    OTHER_DENOMINATION_HINT:
      'Informe outros nomes que o equipamento é conhecido. Informe também seu nome em inglês.',
    PROCEDURE_REGISTER_NEW_EQUIPMENT_TITLE:
      'Preenchimento para cadastrar no sistema (novo equipamento)',
    PARAMETERS: 'Parâmetros',
    SUBTITLE_TITLE: 'Legenda',
    SUBTITLE: `
      <strong>#ultracongeladores-70covid</strong>: informar em “valor” a temperatura minima em ºC. Considerar ultracongeladores/ultrafreezers com temperatura minima a partir de -70ºC.
      <br/>
      <strong>#ultracongeladordisponivel</strong>: informar em “valor” a capacidade total em litros do ultracongelador/ultrafreezer
    `,
    UNAVAILABLE_FREEZERS_TITLE: 'Ultracongeladores/Ultrafreezers indisponíveis',
    UNAVAILABLE_FREEZERS: `
      Ultracongeladores/ultrafreezers que não estejam disponíveis por avaria ou falta de estrutura para serem utilizados também podem ser cadastrados na campanha desde que indicada sua situação como “inativo” no momento do cadastro.
      <br/>
      Para informações adicionais entre em contato via email: pnipe@mctic.gov.br
    `
  },
  SHARING: {
    SHARING_ENABLED: 'Compartilhamento habilitado',
    MULTIUSER: {
      TITLE: 'Equipamento multiusuário',
      HINT: 'Equipamento de uso compartilhado para atividades de P&D, acessíveis a usuários não vinculados ao laboratório (mediante condições estabelecidas para cada caso).'
    }
  },
  SEARCH: {
    FILTER_PLACEHOLDER:
      'Buscar por instituição, laboratórios, equipamentos, marca, modelo, técnicas, código...',
    ONLY_MULTIUSER_FILTER: 'Equipamento multiusuário',
    ONLY_SHARING_LAB: 'Compartilhamento habilitado',
    CLEAR_ALL: 'Limpar tudo',
    SEARCH_TYPE_LAB: 'Laboratórios',
    SEARCH_TYPE_EQUIP: 'Equipamentos',
    INSTITUTION: 'Instituição',
    LOCATION: 'Local',
    NO_RESULTS: 'Nenhum registro encontrado',

    CODE: 'Código na plataforma',
    LABORATORY: 'Laboratório',
    BRAND: 'Marca',
    MODEL: 'Modelo',

    EDIT_FILTERS: 'Busca avançada'
  },

  LAB_EQUIP_CARD: {
    INSTITUTION: 'Instituição',
    LOCATION: 'Local',
    CODE: 'Código na plataforma',
    LABORATORY: 'Laboratório',
    BRAND: 'Marca',
    MODEL: 'Modelo',

    VIEW: 'Visualizar'
  },

  SEARCH_ADVANCED_FILTER: {
    ADVANCED_SEARCH: 'Busca avançada',
    ADVANCED_SEARCH_HELPER_TEXT:
      'Mostrar resultados de laboratórios e equipamentos para:',
    FILTER_LOCATION: 'Filtros de localização',
    REGION: 'Região',
    STATE: 'Estado',
    CITY: 'Município',
    FILTER_INSTITUTION: 'Filtros de Instituição',
    FILTER_LABORATORY: 'Filtros de laboratório',
    LAB_EXPERTISE_AREA_LIST: 'Área de atuação',
    LAB_TECHNIQUES_USED: 'Técnicas utilizadas',
    LAB_ACTIVITY_TYPE_LIST: 'Selecione o tipo de atividade',
    ONLY_SHARING_USER:
      'Mostrar apenas  laboratórios com compartilhamento habilitado',
    FILTER_EQUIPMENT: 'Filtros de equipamentos',
    EQUIP_TECHNIQUES_USED: 'Técnicas utilizadas',
    EQUIP_OTHER_DENOMITATION: 'Outras denominações',
    ONLY_MULTI_USER: 'Mostrar apenas equipamentos multiusuário',
    MULTI_USER_HINT: '$t(SHARING:MULTIUSER.HINT)',
    APPLY: 'Aplicar'
  },

  COVID_CAMPAIN: {
    TITLE: 'Ultracongeladores disponíveis para o plano nacional de Vacinação'
  },
  ADVERTISE_PLATFORM: {
    TITLE: 'Divulgar a plataforma',
    SUBTITLE:
      'Envie ou reenvie um e-mail de divulgação para que outras instituições ou laboratórios possam fazer parte da plataforma.',
    PREVIEW_EMAIL: 'Confira uma prévia do e-mail',

    NAME: 'Nome',
    EMAIL: 'E-mail',
    RESPONSIBLE: 'Nome do responsável',
    TYPE: 'Tipo',
    SEND: 'Enviar',

    INSTITUTION_NAME: 'Nome da instituição',
    LAB_NAME: 'Nome do laboratório',

    INVITE_AGAIN_SUBTITLE: 'Convidar novamente',
    FILTER: 'Filtrar por laboratório',
    INVITE_DATE: 'Data do envio',
    RESEND_INVITE: 'Reenviar',

    INVITE_SUCCESS: 'E-mail enviado com sucesso.',
    INVITE_RESEND_SUCCESS: 'E-mail reenviado com sucesso',
    INVITE_RESEND_ERROR: 'Erro ao reenviar o convite',
    MAX_INVITES_SEND:
      'Aguarde para enviar este e-mail. O sistema permite o envio de no máximo 5 e-mails subsequentes. Aguarde por 1h para voltar a enviar',
    EMAIL_ALREADY_SENT:
      'E-mail de divulgação já enviado para o usuário informado.',

    PREVIEW_TAB_INSTITUTION: 'Instituições',
    PREVIEW_TAB_LABORATORY: 'Laboratórios',
    PREVIEW_EMAIL_TITLE: 'Prévia do e-mail a ser enviado',
    PREVIEW_INSTITUTION_EMAIL_CONTENT: `
    A instituição [nomedolaboratório] 
    <br/><br/>
    A/C [nome da pessoa]
    <br/><br/>
    <strong>Faça parte da Plataforma Nacional de Infraestrutura de Pesquisa – MCTI (PNIPE).</strong>
    <br/><br/>
    A Plataforma tem como objetivo catalogar a infraestrutura de pesquisa existente no país para promover visibilidade e cooperação entre cientistas e técnicos, otimizando a utilização da capacidade instalada nas diversas instituições além de subsidiar a definição de políticas públicas de apoio e fomento.
    <br/><br/>
    Para seu cadastro bem como do instituição sob sua responsabilidade, basta seguir os passos abaixo:
    <br/><br/>
    <strong>1.</strong> Inicialmente, deve-se realizar o registro na plataforma, por meio do endereço https://pnipe.mctic.gov.br/.
    <br/><br/>
    <strong>2.</strong> Após o login na plataforma, você poderá solicitar a inclusão de sua instituição laboratório no botão “Nova instituição” na aba “Infraestutura”, disponível no menu lateral esquerdo.
    <br/><br/>
    <strong>3.</strong> Após o registro da instituição e aprovação pelo Ministerio da Ciência e Tecnologia - MCTI, os laboratórios de sua inistituição poderão se cadastrar na plataforma para dar visibilidade a sua infraestrutura de pesquisa.
    <br/><br/>
    Em caso de dúvidas ou problemas, acesse o menu "Suporte" ou consulte a opção "Dúvidas frequentes". Se preferir, encaminhe sua mensagem pela opção "Fale conosco".
    <br/><br/>
    Atenciosamente,
    <br/><br/>
    [nome do usuário que submeteu o convite]
    <br/>
    [e-mail do usuário que sumeteu o convite]
    `,
    PREVIEW_LABORATORY_EMAIL_CONTENT: `
      Ao [nomedolaboratório] 
      <br/><br/>
      A/C [nome da pessoa]
      <br/><br/>
      <strong>Faça parte da Plataforma Nacional de Infraestrutura de Pesquisa – MCTI (PNIPE).</strong>
      <br/><br/>
      A Plataforma tem como objetivo catalogar a infraestrutura de pesquisa existente no país para promover visibilidade e cooperação entre cientistas e técnicos, otimizando a utilização da capacidade instalada nas diversas instituições além de subsidiar a definição de políticas públicas de apoio e fomento.
      <br/><br/>
      Para seu cadastro bem como do laboratório sob sua responsabilidade, basta seguir os passos abaixo:
      <br/><br/>
      <strong>1.</strong> Inicialmente, deve-se realizar o registro na plataforma, por meio do endereço https://pnipe.mctic.gov.br/.
      <br/><br/>
      <strong>2.</strong> Após o login na plataforma, você poderá solicitar a inclusão do seu laboratório no botão “Novo laboratório” na aba “Laboratório”, disponível no menu lateral esquerdo.
      <br/><br/>
      <strong>3.</strong> Após o registro do laboratório e aprovação pelo representante da instituição, equipamentos poderão ser cadastrados e o laboratório publicado para que fique visível ao público.
      <br/><br/>
      Em caso de dúvidas ou problemas, acesse o menu "Suporte" ou consulte a opção "Dúvidas frequentes". Se preferir, encaminhe sua mensagem pela opção "Fale conosco".
      <br/><br/>
      Atenciosamente,
      <br/><br/>
      [nome do usuário que submeteu o convite]
      <br/>
      [e-mail do usuário que sumeteu o convite]
      `
  },
  LABORATORY_LIST: {
    TITLE: 'Laboratórios',
    FILTER_STATUS: 'Filtrar por',
    SEARCH_DEFAULT:
      'Buscar por sigla, nome laboratório, sigla ou nome instituição....',
    CONFIRM_DELETE_SHARINGS:
      'Ao excluir este laboratório, todas as solicitações de utilização enviadas a esse laboratório serão canceladas. Deseja continuar?',

    COLUMNS: {
      LAB_NAME: 'Nome do Laboratório',
      INSTITUTION: 'Institutição',
      INITIALS: 'Sigla',
      AREAS_EXPERTISE: 'Área de atuação',
      STATE: 'Localidade',
      ACTIVE_RESPONSIBLE: 'Responsáveis ativos',
      STATUS: 'Situação'
    },

    LAB_NAME: 'Nome do laboratório',
    INITIALS: 'Sigla',
    RESPONSIBLE: 'Responsável',
    SITUATION: 'Situação',
    NEW_LAB_BUTTON: 'Novo laboratório',
    ACTION: {
      PUBLISH: 'Publicar',
      UNPUBLISH: 'Despublicar',
      EDIT: 'Editar',
      APPROVE: 'Analisar',
      EXCLUDE: 'Excluir',
      APPROVE_EDIT: 'Aprovar ajustes',
      VIEW: 'Visualizar',
      VISUALIZE: 'Visualizar',
      ACTIVATE: 'Ativar'
    },
    CONFIRM_DELETE_MODAL: {
      TITLE: 'Excluir laboratório',
      DESCRIPTION: `
        Você deseja excluir o laboratório <strong>{{labName}}</strong> do sistema?  Todos os dados do laboratórios serão perdidos e esta ação não poderá ser desfeita. Detalhe os motivos para confirmar a exclusão.
      `,
      REASON_LABEL: 'Detalhar motivo',
      REASON_PLACEHOLDER: 'Detalhe o motivo de exclusão'
    },
    DIALOG_ACTIVATION: {
      ACTIVATION: 'Ativar',
      ACTIVATION_TITLE: 'Ativar laboratório',
      ACTIVATION_TEXT:
        'Você deseja ativar a <strong>{{name}}</strong> do sistema? Com esta ação, o laboratório e seus equipamentos voltam a ficar visiveis para serem gerenciados na plataforma.',
      REASON: 'Detalhar motivo',
      REASON_ACTIVATION_PLACEHOLDER: 'Detalhe o motivo da ativação',
      RESPONSIBLE_EMAIL_LABEL: 'E-mail do responsável total ',
      RESPONSIBLE_EMAIL_TOOLTIP:
        'Informe o e-mail do usuário que deverá assumir  a retomada do laboratório frente ao sistema.'
    }
  },
  LABORATORY_RESPONSIBLE_MANAGEMENT: {
    TITLE: 'Administrar Laboratório',
    LABORATORY_DATA: 'Dados do laboratório',
    LABORATORY: 'Laboratório',
    INSTITUITION: 'Instituição',
    CNPJ: 'CNPJ',
    LABORATORY_RESPONSIBLE: 'Responsáveis do laboratório',
    INVITE: {
      ADD_EMAIL: 'Adicionar e-mail',
      CONFIRM_INVITE: 'Convidar'
    },
    REPRESENTATIVE_LIST: {
      ARIA_LABEL: 'Tabela de responsáveis de laboratório',
      EMAIL: 'E-mail',
      NAME: 'Nome',
      PERMISSION: 'Permissão',
      SITUATION: 'Situação',
      INACTIVE: 'Inativo na plataforma',
      ACTIONS: {
        DELETE: 'Excluir',
        RESEND_INVITE: 'Reenviar convite'
      }
    },
    MESSAGES: {
      USER_ALREADY_INVITED: 'Usuário já convidado',
      FORM_NEEDS_SAVING:
        'É necessário salvar esse formulário para concluir a operação.'
    }
  },
  LABORATORY: {
    SITUATION: {
      ACTIVE: 'Ativo',
      INACTIVE: 'Inativo',
      DELETED: 'Excluido',
      REJECTED: 'Recusado',
      WAITING_APPROVAL: 'Aguardando aprovação',
      WAITING_PUBLICATION: 'Aguardando publicação',
      PENDING: 'Pendente',
      PUBLISHED: 'Publicado',
      UNPUBLISHED: 'Despublicado',
      WAITING_EDIT_APPROVAL: 'Aguardando aprovação de alterações',
      EDIT_REJECTED: 'Alterações rejeitadas',
      IN_ACTIVATION: 'Em ativação'
    },
    CHANGELOG: {
      NAME: 'Nome do laboratório',
      INITIALS: 'Sigla',
      ABOUT: 'Sobre',
      CONTACTEMAIL: 'Email',
      WEBSITE: 'Website',
      ZIPCODE: 'CEP',
      STREET: 'Rua',
      COMPLEMENT: 'Complemento',
      DISTRICT: 'Bairro',
      CITY: 'Cidade',
      STATE: 'Estado',
      NUMBER: 'Número',
      REGION: 'Região',
      LATITUDE: 'Latitude',
      LONGITUDE: 'Longitude',
      INSTITUTION: 'Instituição',
      RESPONSIBLE: 'Responsável',
      TECHNIQUELIST: 'Principais técnicas',
      AREASEXPERTISELIST: 'Áreas de atuação',
      TYPELABORATORYLIST: 'Tipo de laboratório',
      DOCUMENTLIST: 'Documentos'
    }
  },
  LABORATORY_SITATION: {
    TITLE: {
      WAITING_APPROVAL: 'Visualizar análise inclusão de laboratório',
      WAITING_EDIT_APPROVAL:
        'Visualizar análise de edição avançada de laboratório',
      REJECTED: 'Visualizar análise inclusão de laboratório'
    },
    TABS: {
      SITUATION: 'Análise',
      SOLICITATION: 'Solicitação'
    },
    SITUATION: { TITLE: 'Situação da análise' },
    CONTACTS: {
      TITLE: 'Contato dos responsáveis pela análise',
      NAME: 'Nome do responsável',
      EMAIL: 'E-mail'
    },
    CHANGELOG: {
      TITLE: 'Alterações solicitadas',
      FIELD: 'Campo',
      PREVIOUS_VALUE: 'Valor anterior',
      CHANGED_VALUE: 'Valor alterado'
    },
    REJECTED: {
      TITLE: 'Resultado da análise',
      REASON: 'Motivo da recusa',
      DETAIL: 'Descrição',
      FILE: 'Arquivo',
      AUTHOR: 'Responsável pela análise'
    }
  },
  USER: {
    SITUATION: {
      ACTIVE: 'Ativo',
      INACTIVE: 'Inativo',
      PENDING: 'Pendente'
    },
    ROLE_SHORT: {
      ROLE_INSTITUICAO_TOTAL: 'Total',
      ROLE_INSTITUICAO_PARCIAL: 'Parcial',
      ROLE_INSTITUICAO_LIMITADA: 'Limitado',
      ROLE_LABORATORIO_TOTAL: 'Total',
      ROLE_LABORATORIO_PARCIAL: 'Parcial',
      ROLE_LABORATORIO_LIMITADA: 'Limitado',
      ROLE_INTEGRANTE: 'Integrante',
      ROLE_ADMIN: 'Administrador',
      ROLE_GESTOR_ACOES: 'Gestor de ações'
    },
    ROLE: {
      ROLE_INSTITUICAO_TOTAL: 'Representante de instituição total',
      ROLE_INSTITUICAO_PARCIAL: 'Representante de instituição parcial',
      ROLE_INSTITUICAO_LIMITADA: 'Representante de instituição limitada',
      ROLE_LABORATORIO_TOTAL: 'Representante de laboratório total',
      ROLE_LABORATORIO_PARCIAL: 'Representante de laboratório parcial',
      ROLE_LABORATORIO_LIMITADA: 'Representante de laboratório limitado',
      ROLE_INTEGRANTE: 'Integrante',
      ROLE_ADMIN: 'Administrador',
      ROLE_GESTOR_ACOES: 'Gestor de ações'
    }
  },
  LOGIN: {
    TITLE: 'Login',
    SUBTITLE:
      'Preencha os formulário abaixo. Em seguida enviaremos um e-mail para que validar o seu cadastro.',
    USER_NAME: 'CPF ou E-mail',
    PASSWORD: 'Senha',
    SIGN_IN: 'Entrar',
    NEEDS_HELP: 'Se precisar de ajuda contate o',
    NEEDS_HELP_LINK: 'suporte',
    FORGOT_PASSWORD: 'Esqueci a senha',
    SIGN_UP: 'Criar a conta'
  },

  PUBLIC_INSTITUTION_VIEW: {
    TOTAL_LAB: 'Total de laboratórios',
    TOTAL_EQUP: 'Total de equipamentos',
    TABLE: {
      QUANTITY_EQUIPS: 'Qnt. Equipamentos',
      QUANTITY_EQUIPS_MULTIUSER: 'Qnt. Equipamentos Multiusuário',
      SHARING: 'Compartilhamento Habilitado',
      ACTION: 'Ação'
    }
  },

  PUBLIC_LABORATORY_DETAILS: {
    ABOUT_LAB: 'Sobre este laboratório',
    ADRESS: 'Endereço',
    ZIP_CODE: 'CEP',
    LOCATION: 'Veja como chegar',
    CONTACT: 'Contato',
    RESPONSIBLE: 'Responsável',
    TELEPHONE: 'Telefone',
    EMAIL: 'Email',
    WEBSITE: 'Website',
    AREA_EXPERTISE: 'Área de atuação',
    TECHNIQUE: 'Principais Técnicas',

    SITUATION: {
      BROKEN: 'Quebrado.',
      MAINTANCE: 'Em manutenção.',
      ACTIVE: 'Ativo.'
    }
  },

  PUBLIC_EQUIPMENT_DETAILS: {
    TITLE: 'Visualizar equipamento',
    TECHINIQUE_LIST: 'Técnicas Utilizadas nesse equipamento:',
    CODE_PLATFORM: 'Código na plataforma',
    OTHER_DENOMANATION: 'Outras denominações',
    SITUATION: 'Situação do equipamento',
    ORIGIN: 'Origem do equipamento',
    ABOUT: 'Sobre o equipamento',
    DATA: 'Dados do equipamento',
    BRAND: 'Marca',
    MODEL: 'Modelo',
    MANUFACTURER: 'Fabricante',
    MANUFACTURER_LINK: 'Link do equipamento no site do fabricante',
    LINK: 'Link do equipamento no site do fabricante',
    PURCHASEYEAR: 'Ano de aquisição',
    FINANCING: 'Fonte de financiamento',
    PARAMETER: 'Parâmetros',
    CONTACT: 'Contato',
    RESPONSIBLE: 'Responsável',
    TELEPHONE: 'Telefone',
    EMAIL: 'Email',
    WEBSITE: 'Website',
    MULTIUSER_HINT:
      'Equipamento possível de ser compartilhado com usuários externos. Os procedimentos e critérios de compartilhamento são estabelecidos pelo próprio laboratório',
    MULTIUSER: 'Equipamento Multiusuário',
    USAGE_PROFILE: 'Perfil de uso do equipamento',
    INTERNAL_PROJECTS: 'Dedicado aos projetos internos do laboratório',
    MULTIUSER_INDEX: 'Multiusuário'
  },

  INSTITUTION_TYPE: {
    UNIVERSITY: 'Instituição de ensino superior',
    INSTITUTE_RESEARCH_CENTER: 'Instituto/Centro de pesquisa',
    COMPANY: 'Empresa',
    GOVERNMENT_AGENCY: 'Agência governamental',
    OTHERS: 'Outros'
  },

  REQUEST_SHARING_PUBLIC: {
    TITLE: 'Solicitar utilização',
    DESCRIPTION: `Você pode entrar em contato com este laboratório enviando uma
                  solicitação de utilização por meio do botão abaixo. É necessário
                  estar logado para submeter o formulário`,
    ACTION: `Solicitar utilização`,
    DESCRIPTION_OWN_SHARING: `O laboratório possui procedimentos próprios de utilização, para saber mais acesse o site do laboratório`,
    ACTION_OWN_SHARING: `Site do laboratório`
  },

  REQUEST_SHARING: {
    PROFILE: {
      MEMBER: 'Integrante',
      APPROVER: 'Aprovador'
    },

    SITUATION: {
      PENDING_APPROVAL: 'Pendente de aprovação',
      COMPLETION_PENDING: 'Pendente de complementação',
      CONFIRM_USAGE: 'Confirmar utilização',
      DECLARE_USE: 'Declarar uso',
      FINISHED: 'Finalizado',
      REFUSED: 'Recusado',
      CANCELED: 'Cancelado'
    },
    EDIT: {
      TITLE: {
        PENDING_APPROVAL: 'Editar solicitação',
        COMPLETION_PENDING: 'Complementar solicitação'
      },
      COMPLEITION_DATA: {
        TITLE: 'Complementação de dados',
        DESCRIPTION: 'A análise deste pedido solicitou complementação:',
        RESPONSIBLE: 'Responsável  pela analise:',
        DETAILS: 'Detalhes:'
      }
    },
    LIST: {
      TITLE: 'Compartilhamento',
      SELECT_PROFILE: 'Visualizar por perfil',
      FILTER_STATUS: 'Visualizar por situação',
      SEARCH:
        'Buscar por solicitante, laboratório, técnica ou pelos códigos da solicitação ou do equipamento',
      NEW_ITEM_BUTTON: 'Solicitar utilização',
      CONFIRM_EXCLUDE:
        'As informações preenchidas, serão perdidas. Deseja continuar?',
      ACTIONS: {
        ANALYSE: 'Analisar',
        VIEW: 'Acompanhar',
        DIRECT_COMMUNICATION: 'Comunicação direta',
        EXCLUDE: 'Excluir',
        EDIT: 'Editar'
      },

      COLUMNS: {
        CODE: 'Código',
        TITLE: 'Título',
        REQUESTER: 'Solicitante',
        LABORATORY: 'Laboratório',
        DATE: 'Data',
        SITUATION: 'Situação'
      }
    },
    REGISTER: {
      TITLE: 'Solicitar utilização',
      SEND: 'Enviar',
      CONFIRM_CANCEL:
        'As informações preenchidas, serão perdidas. Deseja continuar?',

      ERRORS: {
        FILE_UPLOAD_ERROR:
          'Sua solicitação foi criada porem houveram erros ao subir 1 ou mais arquivos, por favor edite a solicitação com os arquivos faltantes'
      },

      LABORATORY: {
        SUBTITLE: 'Dados do laboratório',
        LABEL: 'Laboratório',
        PLACEHOLDER: '$t(COMMONS:SELECT)',
        RESPONSIBLE: 'Nome do responsável'
      },

      SOLICITATION: {
        SUBTITLE: 'Dados da solicitação',
        TITLE_LABEL: 'Título da solicitação',
        TITLE_PLACEHOLDER:
          'Título da proposta / problema a ser analisado/ análise a ser executada',
        SUMMARY_LABEL: 'Resumo da solicitação',
        SUMMARY_PLACEHOLDER:
          'Detalhe quais são os objetivos do seu experimento',
        JUSTIFICATION_LABEL: 'Justificativa',
        JUSTIFICATION_PLACEHOLDER:
          'Detalhe a relevância científica ou tecnológica deste problema e a justificativa paro uso desta técnica',

        ESTIMATED_TIME_LABEL: 'Tempo previsto de execução do experimento',
        ESTIMATED_TIME_PLACEHOLDER: 'em horas, dias e etc',
        SUGGESTED_DATE_SUBTITLE: 'Sugestão de data para utilização',
        SUGGESTED_DATE_HINT:
          'A definição da data é exclusiva do laboratório. Sua sugestão pode ajudá-lo no processo de análise.',
        SUGGESTED_DATE_FROM_LABEL: 'De',
        SUGGESTED_DATE_FROM_PLACEHOLDER: 'dd/mm/aaaa',
        SUGGESTED_DATE_TO_LABEL: 'Até',
        SUGGESTED_DATE_TO_PLACEHOLDER: 'dd/mm/aaaa',
        RELATED_DOCUMENTS_LABEL: 'Anexar documento',
        RELATED_DOCUMENTS_BUTTON:
          'Clique ou arraste para incluir documentos relevantes para complementar sua solicitação de uso',
        REQUIRES_SPECIFIC_EQUIPMENT:
          'A sua solicitação demanda um equipamento específico?',
        REMOVED_EQUIPMENTS_MESSAGE:
          'Existem equipamentos que foram inativados ou excluidos do sistema. Entre em contato com o laboratório pela comunicação direta para saber mais ou altereo equipamento sugerido.',
        REMOVED_EQUIPMENTS_LABEL: 'Equipamentos retirados desta solicitação:',

        SPECIFIC_EQUIPMENT: 'Selecione o equipamento',
        MAIN_TECHNIQUE: 'Técnica a ser aplicada',
        MAIN_TECHNIQUE_PLACEHOLDER: 'Principais técnicas',
        HAS_EXPERIENCE_WITH_TECHNIQUE: 'Possui experiência com a técnica?',
        JUSTIFY_EXPERIENCE_WITH_TECHNIQUE: 'Especifique',
        JUSTIFY_EXPERIENCE_WITH_TECHNIQUE_PLACEHOLDER: '',
        NEEDS_COMPLEMENTARY_EQUIPMENT:
          'A sua solicitação necessita de instrumentação complementar?',
        JUSTIFY_NEEDS_COMPLEMENTARY_EQUIPMENT: 'Especifique',
        JUSTIFY_NEEDS_COMPLEMENTARY_EQUIPMENT_PLACEHOLDER: ''
      },

      SAMPLE: {
        SUBTITLE: 'Amostra',

        NEEDS_TO_BRING_SAMPLE:
          'A sua solicitação implica em levar uma amostra ao laboratório?',
        JUSTIFY_NEEDS_TO_BRING_SAMPLE: 'Especifique',
        JUSTIFY_NEEDS_TO_BRING_SAMPLE_PLACEHOLDER: '',
        SAMPLE_AMOUNT_LABEL:
          'Qual a quantidade de amostra disponível para esta solicitação?',
        SAMPLE_AMOUNT_PLACEHOLDER: 'em milímetros, gramas, etc.',
        NEEDS_SAMPLE_PREPARATION:
          'A solicitação necessita de preparação de amostra?',
        JUSTIFY_NEEDS_SAMPLE_PREPARATION: 'Especifique',
        JUSTIFY_NEEDS_SAMPLE_PREPARATION_PLACEHOLDER: '',
        HAS_SECURITY_HAZARD: 'A amostra apresenta riscos de segurança?',
        BIOLOGICAL_HAZARD: 'Risco biológico',
        CHEMICAL_HAZARD: 'Risco químico',
        RADIOLOGICAL_HAZARD: 'Risco radiológico',
        OTHER_HAZARD: 'Outros',
        JUSTIFY_SECURITY_HAZARD: 'Especifique',
        JUSTIFY_SECURITY_HAZARD_PLACEHOLDER: '',
        MATERIAL_DATA_SAFETY_LABEL: 'Anexar MSDS - Material Data Safety',
        MATERIAL_DATA_SAFETY_BUTTON:
          'Clique aqui ou arraste o documento que detalhe os riscos de segurança'
      },

      REQUESTER: {
        SUBTITLE: 'Dados do solicitante',

        CPF: 'CPF',
        FIRST_NAME: 'Nome',
        LAST_NAME: 'Sobrenome',
        EMAIL: 'E-mail',
        PHONE: 'Telefone',
        LATTES_LINK: 'Link do currículo Lattes'
      },

      REQUESTER_INSTITUTION: {
        SUBTITLE: 'Vìnculo principal do solicitante',

        ADD_NEW_INSTITUTION: 'Adicionar nova "{{newValue}}"',
        LABEL: 'Nome da instituição',
        PLACEHOLDER: '',
        CNPJ_LABEL: 'CNPJ da instituição',
        CNPJ_PLACEHOLDER: '',
        TYPE: 'Tipo de instituição',
        PUBLIC: 'Pública',
        PRIVATE: 'Privada',
        SPECIFY_INSTITUTION_TYPE_LABEL: 'Especifique',
        SPECIFY_INSTITUTION_TYPE_PLACEHOLDER: '$t(COMMONS:SELECT)',
        HAS_READ_THE_TERMS: 'Declaro que estou ciente sobre os Termos de uso do Laboratório',
        LAB_TERMS: 'Termos de uso do Laboratório',
        IS_AWARE_OF_COSTS:
          'Declaro que estou ciente que a minha solicitação pode acarretar em custos determinados pelo laboratório e que a plataforma não é responsável por essa operação'
      }
    },
    ANALYZE: {
      TITLE: 'Análise de solicitação de utilização',
      SUBTITLE: 'Parecer sobre esta solicitação',
      REMOVED_EQUIPMENTS_MESSAGE:
        'Existem equipamentos que foram inativados ou excluidos do sistema. Sugira outro equipamento no parecer ou recuse esta análise',
      REMOVED_EQUIPMENTS_LABEL: 'Equipamentos retirados desta solicitação:',
      CONFIRM_CANCEL:
        'As informações preenchidas, serão perdidas. Deseja continuar?',
      APPROVE_LABEL: 'O compartilhamento poderá ser realizado?',

      COMPLEITION_DATA: {
        TITLE: 'Análise anterior',
        DESCRIPTION: `
          A análise deste pedido necessita de complementação, confira os
          detalhes abaixo e analise novamente ao final do formulário as
          alterações da solicitação
        `,
        RESPONSIBLE: 'Responsável  pela analise:',
        DETAILS: 'Detalhes:'
      },

      APPROVE: {
        EQUIPMENTS:
          'Selecione os equipamentos disponibilizados para essa solicitação',
        RESPONSIBLE_LABEL: 'Responsável pelo atendimento',
        RESPONSIBLE_PLACEHOLDER: '$t(COMMONS:TYPE)',
        EMAIL_LABEL: 'E-mail',
        EMAIL_PLACEHOLDER: '$t(COMMONS:TYPE)',
        PHONE: 'Telefone',
        SUGGESTED_DATE: 'Período de utilização',
        SUGGESTED_DATE_FROM_LABEL: 'De',
        SUGGESTED_DATE_FROM_PLACEHOLDER: '$t(COMMONS:TYPE_DATE)',
        SUGGESTED_DATE_TO_LABEL: 'Até',
        SUGGESTED_DATE_TO_PLACEHOLDER: '$t(COMMONS:TYPE_DATE)'
      },
      REPROVE: {
        NEEDS_COMPLEMENATION:
          'A análise desta solicitação demanda outras informações?',
        COMPLEMENTATION_LABEL: 'Detalhar',
        COMPLEMENTATION_PLACEHOLDER: '',
        REASON_TYPE: 'Motivo da recusa',
        REASON_TYPE_PLACEHOLDER: '$t(COMMONS:SELECT)',
        REASON: 'Detalhar motivo',
        REASON_PLACEHOLDER: '$t(COMMONS:TYPE)'
      }
    },
    DETAILS: {
      TITLE: 'Acompanhar solicitação de utilização',
      CANCEL: 'Cancelar solicitação',
      TABS: {
        SITUATION: 'Situação',
        VIEW: 'Visualizar solicitação',
        COMMUNICATION: 'Comunicação direta ({{unreadCount}})'
      },
      COMMUNICATION: { LABEL: 'Nova mensagem' },
      PENDING_APPROVAL: {
        ANALYZE: 'Analisar',
        TITLE: 'Responsáveis pela análise',
        RESPONSIBLE_NAME: 'Nome do responsável',
        RESPONSIBLE_EMAIL: 'E-mail'
      },
      COMPLETION_PENDING: {
        TITLE: 'Próximos passos',
        DESCRIPTION: `
          A análise deste pedido necessita de complementação do solicitante por meio da edição do formulário. Em seguida ele será submetido a nova análise pelos responsáveis de laboratório.
        `,
        SUBTITLE: 'Detalhamento da solicitação',

        REASON: '<strong>Motivo:</strong>: Necessita de complementação',
        DETAILS: '<strong>Detalhes:</strong> {{details}}',
        CANCEL_REQUEST: 'Cancelar solicitação',
        EDIT_REQUEST: 'Editar formulário'
      },
      CONFIRM_USAGE: {
        TITLE: 'Próximos passos',
        DESCRIPTION: `
          O pedido de compartilhamento foi <strong>aprovado</strong>. O processo encontra-se <strong>aguardando o aceite do solicitante quanto a data indicada</strong>. Caso não seja possível realizar o experimento na data proposta, entre em contato com o laboratório pela <LinkCommunication>Comunicação direta</LinkCommunication> para definir uma nova data de utilização. 
        `,
        SHARING_DATA: 'Detalhamento da solicitação',
        CONFIRM: 'Confirmar utilização'
      },
      DECLARE_USAGE: {
        TITLE: 'Próximos passos',
        DESCRIPTION: `
          Para finalizar esta solicitação, o solicitante deverá declar se este compartilhamento foi realizado pelo laboratório.
        `,
        SHARING_DATA: 'Detalhamento da solicitação',

        WAS_SHARED: 'O compartilhamento foi realizado?',
        SHARED_DATE_LABEL:
          'Identifique o período em que o experimento foi executado',
        SHARED_DATE: '{{from}} até {{to}}',
        SHARED_DATE_FROM: 'De',
        SHARED_DATE_FROM_PLACEHOLDER: '$t(COMMONS:TYPE_DATE)',
        SHARED_DATE_TO: 'Até',
        SHARED_DATE_TO_PLACEHOLDER: '$t(COMMONS:TYPE_DATE)'
      },
      EDIT_USAGE: {
        EQUIPMENTS:
          'Selecione os equipamentos disponibilizados para essa solicitação',
        EQUIPMENTS_AVAILABLE: 'Equipamentos disponibilizados',
        RESPONSIBLE: 'Responsável pelo atendimento',
        EMAIL: 'E-mail',
        PHONE: 'Telefone',
        SUGGESTED_DATE_LABEL: 'Período de utilização',
        SUGGESTED_DATE: '{{from}} até {{to}}',
        SUGGESTED_DATE_FROM: 'De',
        SUGGESTED_DATE_FROM_PLACEHOLDER: '$t(COMMONS:TYPE_DATE)',
        SUGGESTED_DATE_TO: 'Até',
        SUGGESTED_DATE_TO_PLACEHOLDER: '$t(COMMONS:TYPE_DATE)'
      },
      CANCELED: {
        TITLE: 'Resultado da análise',
        SHARING_DATA: 'Detalhamento da solicitação',

        RESPONSIBLE: 'Responsável pelo cancelamento',
        REASON: 'Detalhes'
      },
      REFUSED: {
        TITLE: 'Resultado da análise',
        SHARING_DATA: 'Detalhamento da solicitação',

        SITUATION: 'Situação',
        REFUSED: 'Recusado',
        REASON: 'Detalhes'
      },
      FINISHED: {
        TITLE: 'Resultado da análise',
        SHARING_DATA: 'Detalhamento da solicitação',

        EQUIPMENTS: 'Equipamentos disponibilizados',
        RESPONSIBLE: 'Responsável pelo atendimento',
        EMAIL: 'E-mail',
        PHONE: 'Telefone',
        DATE_LABEL: 'Período de utilização:',
        DATE: '{{from}} até {{to}}'
      },
      CANCEL_MODAL: {
        TITLE: 'Cancelar solicitação de compartilhamento',
        DESCRIPTION: `
          Ao cancelar esta solicitação o processo de compartilhamento será descartado e 
          esta ação não poderá ser desfeita.
          <br />
          Ao solicitante: Para solicitar mudança na data de utilização entre em contato 
          com o responsável de laboratório pela Comunicação direta. Após atualizada a 
          data pelo responsável você poderá confirmar a utilização com a nova data.
          <br /><br />
          <strong>Detalhe o motivo para prosseguir com esta operação</strong>
        `,
        REASON: 'Detalhar motivo',
        REASON_PLACEHOLDER: 'Detalhe o motivo'
      }
    }
  },

  PREVIEW_SHARING_MODAL: {
    TITLE: 'Formulário de solicitação de compartilhamento',
    REGISTER: {
      /* reference REQUEST_SHARING.REGISTER after instantiation of the object */
    }
  },

  PUBLIC_AREA_REGISTER: {
    TITLE: 'Como Participar',
    SUBTITLE_1: 'Quem pode participar?',
    CONTENT_1:
      'A Plataforma Nacional de Infraestrutura de Pesquisa é destinada a docentes, pesquisadores e qualquer pessoa interessada em conhecer os equipamentos de PD&I que compõem a infraestrutura de pesquisa brasileira',
    SUBTITLE_2: 'Por que participar?',
    CONTENT_2:
      'A Plataforma Nacional de Infraestrutura vai abrir novas oportunidades de parcerias e colaborações para você e para os laboratórios de sua instituição. Além disso, é um instrumento de gestão de equipamentos gratuito, viabilizado pelo Ministério da Ciência, Tecnologia e Inovações.',
    SUBTITLE_3: 'Quero me cadastrar',
    CONTENT_3:
      '<strong>Crie sua conta</strong> clicando <Link>aqui</Link> ou pelo botão de acesso no topo.',

    SUBTITLE_4: 'Recebi um convite',
    CONTENT_4:
      '<strong>Ative</strong> sua conta, utilizando o mesmo email em que recebeu o convite.',

    REQUEST_SHARING: {
      TITLE: 'Quero utilizar equipamentos',
      COLUMN_SUBTITLE_1: 'Consultar equipamentos multiusuários',
      COLUMN_TEXT_1: `
      Consulte por Laboratórios ou Equipamentos do seu interesse. Verifique se contém o rótulo <strong>Compartilhamento habilitado</strong>. Acesse o botão do módulo de compartilhamento dentro da página do laboratório ou do equipamento. 
      `,
      COLUMN_SUBTITLE_2: 'Solicitar compartilhamento',
      COLUMN_TEXT_2: `
      Os laboratórios podem oferecer uma das duas opções de compartilhamento na plataforma: compartilhamento pela plataforma PNIPE ou por processo próprio disponibilizado por link indicado pelo laboratório.
      `,
      COLUMN_SUBTITLE_3: 'Acompanhar processo via plataforma',
      COLUMN_TEXT_3: `
      Caso o compartilhamento seja pela plataforma, você será direcionado para o processo de solicitação de utilização. Preencha o formulário e aguarde o retorno do laboratório para prosseguir. O processo poderá ser acompanhado por meio da opção “Compartilhamento” no seu painel de usuário.
      `,
      FOOTNOTE: `Nota: equipamentos "quebrados" e "em manutenção" também estão disponíveis para
        solicitação, caso possa apoiar o laboratório de alguma forma na liberação deste.`
    },

    LAB_RESPONSIBLE: {
      TITLE: 'Sou responsável de laboratório',
      COLUMN_SUBTITLE_1: 'Solicitar a inclusão de laboratório',
      COLUMN_TEXT_1:
        'Acesse a plataforma e solicite a inclusão do seu laboratório em “infraestrutura de pesquisa”, no menu lateral. <strong>O cadastro será submetido à análise da sua instituição e o resultado será enviado para o e-mail do solicitante.</strong>',
      COLUMN_SUBTITLE_2:
        'Editar página do laboratório e cadastrar equipamentos',
      COLUMN_TEXT_2:
        'Após aprovado você precisará editar a página do seu laboratório para publicá-la e cadastrar equipamentos.',
      COLUMN_SUBTITLE_3: 'Publicar laboratório',
      COLUMN_TEXT_3:
        'Publique seu laboratório por meio da página de edição. Seu laboratório passará a ter uma página pública na plataforma.',
      NOTE_TITLE:
        '<strong>Se sua instituição ainda não estiver na plataforma</strong>',
      NOTE_TEXT:
        'Caso a sua instituição não esteja na plataforma, você pode enviar um e-mail em “Convidar instituição” no menu lateral da sua área de usuário.'
    },

    INSTITUTION_RESPONSIBLE: {
      TITLE: 'Sou responsável de instituição',
      COLUMN_SUBTITLE_1: 'Solicitar a inclusão da sua instituição',
      COLUMN_TEXT_1:
        'Acesse a plataforma e solicite a inclusão da sua instituição em “infraestrutura de pesquisa”. O pedido deverá ser feito pelo dirigente máximo com o e-mail institucional, caso já possua acesso à plataforma com outro e-mail, é possível alterá-lo em “Meu perfil”. No momento do cadastro será possível adicionar outros representantes para ajudá-lo a gerenciar a instituição',
      COLUMN_SUBTITLE_2: 'Complementar dados',
      COLUMN_TEXT_2:
        'O cadastro será submetido à análise pelo MCTI. Após aprovado, você receberá um e-mail com o resultado e os responsáveis indicados serão notificados. Não se esqueça de complementar os dados da sua instituição.',
      COLUMN_SUBTITLE_3: 'Gerenciar instituição',
      COLUMN_TEXT_3:
        'Agora você e os representantes da sua instituição cadastrados na plataforma poderão “Convidar laboratórios” e analisar as solicitações de inclusão para que eles possam efetivar a participação. Caberá a cada laboratório gerenciar seus respectivos responsáveis e equipamentos.'
    },

    BASE_SUBTITLE: 'Se sua instituição ainda não estiver na plataforma',
    BASE_SUBTITLE_TEXT:
      'A inclusão poderá ser solicitada por meio da opção <Link>Fale Conosco</Link> no menu "Suporte".  Para tanto, é importante informar no campo assunto o texto <strong>"Solicitação de Informações para Cadastro"</strong> e no corpo da mensagem <strong>o nome da instituição, CNPJ e o contato do dirigente máximo.</strong> Após análise, uma mensagem contendo as orientações para cadastro ou motivo da recusa será enviada ao <strong>dirigente máximo.</strong>'
  },

  ADMIN: {
    TITLE: 'Administrar plataforma',
    TABS: {
      USERS: 'Usuários',
      INSTITUTION: 'Instituições',
      LABORATORY: 'Laboratórios'
    }
  },

  USER_LIST: {
    TITLE: 'Usuários',
    NO_RESULTS: 'Nenhum registro encontrado',
    FILTER_STATUS: 'Filtrar por',
    FILTER_PROFILE: 'Visualizar por perfil',
    FILTER_ALL: 'Todos',
    SEARCH_DEFAULT:
      'Buscar por nome, e-mail, cpf, instituição, laboratório....',
    ADD_NEW_ADMIN_BUTTON: 'Novo administrador',

    COLUMN: {
      NAME: 'Nome do usuário',
      EMAIL: 'E-mail',
      CPF: 'CPF',
      STATUS: 'Situação',
      ACTIONS: '$t(COMMONS:ACTIONS)'
    },

    ACTIONS: {
      MANAGE: 'Gerenciar usuário',
      RESEND: 'Reenviar senha provisória',
      ACTIVATE: 'Ativar usuário',
      INACTIVATE: 'Inativar usuário',
      DELETE: 'Excluir usuário'
    },

    CONFIRM_ACTIONS: {
      INACTIVATE: 'inativar',
      EXCLUDE: 'excluir'
    },
    CONFIRM_ACTION:
      'Existem solicitações de utilização em processamento para esse usuário. Ao {{action}} este usuário as solicitações serão canceladas. Deseja continuar?',

    DELETE_MODAL: {
      TITLE: 'Excluir usuário',
      TEXT: 'Você deseja excluir o usuário <strong>{{user}}</strong> do sistema? Detalhe o motivo e anexe documentos relevantes para poder confirmar a exclusão deste usuário.',
      LABEL_DETAILS: 'Detalhar motivo',
      LABEL_DETAILS_PLACEHOLDER: 'Detalhe os motivos de exclusão',
      LABEL_ATTACH: 'Anexar documento',
      BUTTON_EXCLUDE: 'Excluir',
      BUTTON_CANCEL: 'Cancelar'
    },

    ROLE: {
      ADMIN: 'Administrador',
      ADMIN_HINT: 'Gerencia os usuários, instituições e laboratório do sistema',
      GESTOR_ACOES: 'Gestor de ações',
      GESTOR_ACOES_HINT:
        'Acesso a funcionalidades temporárias de monitoramento e acompanhamento do sistema'
    },
    CREATE_ADMIN_MODAL: {
      TITLE: 'Novo administrador',
      DESCRIPTION:
        'Convide um novo usuário ou um usuário já cadastrado  para ter acesso como gestor do sistema. ',
      EMAIL: 'E-mail',
      NAME: 'Nome',
      PERMISSION: 'Permissão'
    },

    NOT_AUTHORIZED: 'Usuário não autorizado'
  },

  USER_EDIT: {
    TITLE: 'Gerenciar usuário',
    USER_DATA_TITLE: 'Dados cadastrais',
    PENDING_INVITES_TITLE: 'Convites pendentes',
    NAME: 'Nome',
    LAST_NAME: 'Sobrenome',
    EMAIL: 'E-mail',
    CPF: 'CPF',
    PERMISSIONS_TITLE: 'Permissões do usuário',
    INSTITUTION: 'Unidade de vínculo',
    INSTITUTION_TYPE: 'Tipo',
    PERMISSION: 'Permissão',
    STATUS: 'Situação',

    LABORATORIO: 'Laboratório',
    INSTITUICAO: 'Instituição',
    INTEGRANTE: 'Integrante',

    MAIN_RESPONSIBLE: 'Responsável principal',
    ONLY_RESPONSIBLE: 'Único responsável',

    PARCIAL: 'Parcial',
    LIMITADO: 'Limitado',
    LIMITADA: 'Limitada',

    NO_PERMISSIONS: 'Nenhuma Permissão',

    USER_ROLES: {
      TITLE: 'Perfis de gerenciamento da plataforma',
      DESCRIPTION:
        'Marque caso o usuário gerencie a plataforma. O perfil Gestor de ação tem acesso a funcionalidades temporárias de monitoramento e acompanhamento do sistema e o Administrador gerencia os usuários, instituições e laboratório do sistema em nome do Ministério.',
      ROLE: {
        ADMIN: '$t(USER_LIST:ROLE.ADMIN)',
        GESTOR_ACOES: '$t(USER_LIST:ROLE.GESTOR_ACOES)'
      },
      MESSAGES: {
        DELETED_ERROR:
          'É obrigatório ter ao menos um Administrador na Plataforma.'
      }
    },

    PENDING_INVITES_INSTITUTION: 'Unidade de vínculo',
    PENDING_INVITES_PERMISSION: 'Permissão',
    PENDING_INVITES_DATE: 'Data do último de envio',
    PENDING_INVITES_ACTION: 'Reenviar',
    PENDING_INVITES_NO_INVITES: 'Não existem convites pendentes no momento',

    MESSAGES: {
      CONFIRM_OWN_PERMISSION_EXCLUSION_DIALOG:
        'Está removendo seu próprio perfil e perderá acesso a administração do sistema. Deseja continuar? ',
      CONFIRM_DIALOG:
        'Este usuário será notificado sobre a permissão removida. Deseja continuar?'
    }
  },

  INSTITUTION_LIST: {
    TITLE: 'Usuários',
    NO_RESULTS: 'Nenhum registro encontrado',
    SEARCH_DEFAULT: 'Buscar por sigla, razão social , CNPJ....',

    COLUMNS: {
      SOCIAL_REASON: 'Razão social',
      CNPJ: 'CNPJ',
      ACTIVE_RESPONSIBLE: 'Responsáveis ativos',
      ACTIONS: '$t(COMMONS:ACTIONS)',
      SITUATION: 'Situação'
    },
    ACTIONS_LIST: {
      MANAGE: 'Administrar',
      ANALYSE: 'Analisar',
      VIEW: 'Visualizar',
      ACTIVATE: 'Ativar',
      INACTIVATE: 'Inativar'
    },
    SITUATION_LIST: {
      ACTIVE: 'Ativo',
      INACTIVE: 'Inativo',
      DELETED: 'Excluido',
      REJECTED: 'Recusado',
      PENDING: 'Pendente de análise',
      IN_ACTIVATION: 'Em ativação'
    },
    DIALOG_ACTIVATION: {
      ACTIVATION_TITLE: 'Ativar instituição',
      INACTIVATION_TITLE: 'Inativar instituição',
      ACTIVATION_TEXT:
        'Você deseja ativar a <strong>{{institution}}</strong> do sistema?  Com esta ação, a instituição volta a ficar visivel na plataforma, e seu responsável total poderá ativar os laboratórios que desejar. ',
      INACTIVATION_TEXT:
        'Você deseja inativar a <strong>{{institution}}</strong> do sistema?  Todos os dados desta instituição, seus  laboratórios, equipamentos serão indisponibilizados para o sistema e as solicitações de uso dos laborarórios serão canceladas.. Detalhe os motivos para confirmar a inativação.',
      ACTIVATION: 'Ativar',
      INACTIVATION: 'Inativar',
      DOCUMENT_UPLOAD_LABEL: 'Anexar documento',
      REASON: 'Detalhar motivo',
      REASON_ACTIVATION_PLACEHOLDER: 'Detalhe os motivos da ativação',
      REASON_INACTIVATION_PLACEHOLDER: 'Detalhe os motivos de exclusão',
      RESPONSIBLE_EMAIL_LABEL: 'E-mail do responsável total ',
      RESPONSIBLE_EMAIL_TOOLTIP:
        'Informe o e-mail do usuário que deverá assumir  a retomada da instituição frente ao sistema.'
    }
  },

  INSTITUTION_EDIT: {
    TITLE: 'Administrar instituição',
    INSTITUTION_DATA: 'Dados da instituição',
    COMPANY_NAME: 'Razão social',
    CODE: 'CNPJ',
    INITIALS: 'Sigla',
    PHONE_NUMBER: 'Telefone Institucional',

    ROLES: {
      TOTAL: 'Total',
      TOTAL_HINT:
        'Representante máximo da instituição frente ao sistema. Tem acesso a todas as funcionalidades referentes a instituição no sistema',
      PARTIAL: 'Parcial',
      PARTIAL_HINT:
        'Responsável por validar (aprovar ou recusar) se um laboratório cadastrado e seus responsáveis fazem parte da instituição.',
      LIMITED: 'Limitado',
      LIMITED_HINT: 'Pode apenas visualizar informações.'
    },

    INVITE: {
      MANAGE_REPRESENTATIVES: 'Gerenciar Responsáveis',
      ADD_EMAIL: 'Adicionar email',
      INVITE: 'Convidar'
    },

    INVITE_MODAL: {
      TITLE: 'Convidar Responsável',
      EMAIL: 'Email',
      NAME: 'Nome',
      PERMISSION: 'Permissão',
      ADD_INVITE: 'Adicionar convite'
    },

    REPRESENTATIVE_LIST: {
      EMAIL: 'Email',
      NAME: 'Nome',
      PERMISSION: 'Permissão',
      STATUS: 'Situação',
      INACTIVE: 'Inativo na plataforma',

      ACTIONS: {
        DELETE: 'Excluir',
        RESEND_INVITE: 'Reenviar convite'
      }
    },

    MESSAGES: {
      USER_ALREADY_INVITED: 'Usuário já convidado',
      ADD_INVITE_LIMIT_EXCEEDED: 'Limite de 10 representantes atingido',
      USER_ALREADY_INVITED_OTHER_INSTITUTION:
        'Usuário já convidado para outra instituição. Deseja continuar?',
      REMOVE_SELF:
        'Está  removendo  seu  próprio  perfil  e  perderá  acesso  a  essa  instituição, deseja continuar?',
      AT_LEAST_ONE_TOTAL:
        'Não foi possível realizar a operação. É necessário ter ao menos um responsável total ativo na sua instituição.',
      FORM_NEEDS_SAVING:
        'É necessário salvar esse formulário para concluir a operação.',
      CONFIRM_DIALOG:
        'As informações preenchidas, serão perdidas. Deseja continuar?',
      ALERT_EMAIL_INVITE_DIALOG:
        'Um email de convite foi informado, realize o convite ou limpe o campo para continuar',
      INSTITUTION_ALREADY_EXISTS:
        'Já existe instituição cadastrada com esse nome na plataforma.',
      INITIALS_ALREADY_EXISTS:
        'Já existe instituição cadastrada com essa sigla na plataforma.'
    }
  },
  INSTITUTION_ANALYSIS: {
    TITLE: 'Analisar solicitação de inclusão de instituição',
    INSTITUTION_DATA: 'Dados da instituição',
    RESPONSIBLE_DATA: 'Dados do dirigente máximo',
    RESPONSIBLE: 'Responsáveis',
    INSTITUTION_BOND_TEXT: 'Comprovação de vínculo com a instituição',
    ANALYSIS: {
      SHOULD_INSTITUTION_BE_PART:
        'A instituição deve compor a plataforma PNIPE?',
      INSTITUTION_BOND_IS_CONFIRMED:
        'O requisitante é o dirigente máximo da instituição solicitada?',
      REFUSAL_MOTIVE: 'Informe o motivo',
      REFUSAL_MOTIVE_PLACEHOLDER: 'Informe',
      REFUSAL_FILE_UPLOAD: 'Anexar arquivo',
      DOCUMENT_UPLOAD:
        'Clique para adicionar um documente ou arraste-o para este campo'
    },
    REPRESENTATIVE_LIST: {
      EMAIL: 'Email',
      NAME: 'Nome',
      PERMISSION: 'Permissão',
      SITUATION: 'Situação',
      INACTIVE: 'Inativo na plataforma',

      ACTIONS: {
        DELETE: 'Excluir',
        RESEND_INVITE: 'Reenviar convite'
      }
    },
    MESSAGES: {
      REGISTRATION_SUCCESS: 'Operação realizada com sucesso!',
      SOLICITATION_ERROR: 'Erro ao processar a solicitação'
    }
  },

  INSTITUTION_SOLICITATION_VIEW: {
    TITLE: 'Visualizar solicitação de inclusão de instituição',
    INSTITUTION_DATA: 'Dados da instituição',
    ADDRESS: 'Endereço',
    RESPONSIBLE_DATA: 'Dados do dirigente máximo',
    RESPONSIBLE: 'Responsáveis',
    INSTITUTION_BOND_TEXT: 'Comprovação de vínculo com a instituição',
    REPRESENTATIVE_LIST: {
      EMAIL: 'Email',
      NAME: 'Nome',
      PERMISSION: 'Permissão',
      SITUATION: 'Situação',
      INACTIVE: 'Inativo na plataforma'
    },
    ACTIONS: {
      BACK: 'Voltar'
    },
    ANALYSIS: {
      ANALYZE: 'Análise',
      SOLICITATION: 'Solicitação',
      SITUATION: 'Situação da análise',
      RESULT: 'Resultado da análise',
      RESULT_DESCRIPTION: 'Descrição',
      FILE: 'Arquivo',
      ANALYSIS_RESPONSIBLE: 'Responsável pela análise: ',
      REFUSED: 'Recusado'
    }
  },

  REGISTER_NEW_INSTITUTION: {
    TITLE: 'Cadastro de instituição',
    INSTITUTION_DATA: 'Dados da instituição',
    RESPONSIBLE_DATA: 'Dados do dirigente máximo',
    ADD_NEW_RESPONSIBLE: 'Adicionar Responsáveis',
    INSTITUTION_BOND_TEXT: 'Comprovação de vínculo com a instituição',
    DOCUMENT_UPLOAD:
      'Clique ou arraste para este campo um documento que comprove seu vinculo com a instituição',
    INVITE: {
      ADD_EMAIL: 'Adicionar email',
      INVITE: 'Adicionar'
    },

    REPRESENTATIVE_LIST: {
      EMAIL: 'Email',
      NAME: 'Nome',
      PERMISSION: 'Permissão',
      SITUATION: 'Situação',
      INACTIVE: 'Inativo na plataforma',

      ACTIONS: {
        DELETE: 'Excluir',
        RESEND_INVITE: 'Reenviar convite'
      }
    },
    MESSAGES: {
      CNPJ_ALREADY_EXISTS: 'CNPJ já cadastrado na plataforma.',
      INVALID_CNPJ:
        'Instituição já cadastrada na plataforma. Entre em contato com o MCTI pelo Fale Conosco solicitando seu vínculo a instituição.',
      REGISTRATION_SUCCESS: 'Operação realizada com sucesso!',
      SOLICITATION_ERROR: 'Erro ao processar a solicitação',
      NEEDS_MORE_RESPONSIBLE:
        'Ao menos um responsável total deve ser adicionado.',
      ALREADY_RESPONSIBLE:
        'Não foi possível aprovar essa instituição. O usuário vinculado como dirigente máximo já possui vínculo com outra instituição.',
      INSTITUTION_ALREADY_EXISTS:
        'Já existe instituição cadastrada com esse nome na plataforma.',
      INITIALS_ALREADY_EXISTS:
        'Já existe instituição cadastrada com essa sigla na plataforma.',
        NEEDS_ONE_RESPONSIBLE_MAX:
        'Ocorreu um erro ao processar esta requisição, atualize a página e tente novamente. Caso o erro persista, entre em contato com o suporte.'
    },
    WARNING_MESSAGE:
      'É obrigatório indicar o e-mail institucional para atestar a veracidade do cadastro junto ao MCTI. Caso o seu e-mail de cadastro não seja o institucional, altere em “meu perfil”'
  },

  INFRAESTRUCTURE: {
    TITLE: 'Infraestrutura de pesquisa',
    INTRO_TEXT:
      'Caso seja algum representante vinculado a pesquisa identifique abaixo a modalidade que você se enquadra. A plataforma tem por objetivo catalogar a infraestrutura de pesquisa científica do país, em especial equipamentos de P&D de grande relevância e com potencial multiusuário, ou seja, passíveis de serem disponibilizados para usuários não vinculados ao laboratório.',
    NEW_INSTITUTION: 'Nova Instituição',
    NEW_INSTITUTION_TEXT:
      'Inclua a sua instituição na plataforma para dar visibilidade à sua infraestrutura de pesquisa e para analisar e aprovar os laboratórios que solicitarem cadastro. Caberá a cada laboratório gerenciar seus respectivos responsáveis e equipamentos.',
    NEW_INSTITUTION_TEXT_DISCLAIMER:
      'IMPORTANTE: Seu usuário poderá estar vinculado a apenas uma instituição.',
    NEW_INSTITUTION_BUTTON: 'Incluir instituição',
    NEW_INSTITUTION_DIALOG_TEXT:
      'Para validação junto ao MCTI é necessário que você seja o dirigente máximo e esteja cadastrado na plataforma com o seu e-mail institucional. Você pode alterar o e-mail cadastrado na plataforma em <Link>“Meu perfil”</Link>.',
    ALREADY_IN_INSTITUTION:
      'Para cadastrar uma nova instituição é necessário excluir seu vínculo com a instituição atual.',
    HAS_INSTITUTION_REQUEST:
      'Para cadastrar uma nova instituição é necessário excluir a solicitação já enviada.',
    NEW_LABORATORY: 'Novo Laboratório',
    NEW_LABORATORY_TEXT:
      'Gerencie o seu laboratório e equipamentos, sendo possível também disponibilizá-lo para compartilhamento externo. Lembre-se que para cadastrar um laboratório é necessário que a sua instituição esteja na plataforma. Caso ainda não esteja cadastrada, <LinkInvite>envie um convite à sua instituição</LinkInvite> para participar.',
    NEW_LABORATORY_BUTTON: 'Incluir laboratório',
    SOLICITATIONS_LIST: 'Solicitações pendentes',
    SITUATION_LIST: {
      PENDING: 'Pendente de Análise',
      REJECT: 'Recusado'
    },
    TYPE_LIST: {
      INSTITUTION: 'Instituição',
      LABORATORY: 'Laboratório'
    },
    ACTIONS_LIST: {
      CANCEL: 'Cancelar',
      CONTINUE: 'Continuar',
      EXCLUDE: 'Excluir',
      VIEW: 'Visualizar'
    },
    NO_RESULTS: 'Nenhuma solicitação encontrada',
    MESSAGES: {
      SUCCESS: 'Operação realizada com sucesso.',
      SOLICITATION_ERROR: 'Erro ao processar a solicitação'
    }
  },

  OLD_DEFAULT: {
    LANGUAGE: 'ENGLISH',
    SIGN_IN: 'Acesso',
    SIGN_OUT: 'Sair',

    COMMONS_SELECT: 'Selecione',
    COMMONS_CHIP_INPUT_MAX_LENGTH_REACHED:
      'Quantidade máxima de 20 itens já adicionado.',

    // PAGINATION
    PAGINATION_ITENS_SHOWING: 'Mostrando',
    PAGINATION_OF: 'de',
    PAGINATION_ITENS_PER_PAGE: 'Itens por página',

    // MENU
    ABOUT_PLATAFORM: 'Sobre a Plataforma',
    GOVERNMENT_PORTAL: 'Portal do Governo Brasileiro',
    INFRASTRUCTURE: 'Infraestrutura',
    LABS: 'Laboratórios',
    SUPORT: 'Suporte',
    CONTACT_US: 'Fale Conosco',
    ALL_RESULTS: 'Resultado de busca',
    WANT_JOIN: 'Quero Participar!',
    REGISTER: 'Como Participar', //change here
    HOME_LOGO_PART1: 'Plataforma Nacional',
    HOME_LOGO_PART2: 'de Infraestrutura de Pesquisa MCTI',

    // HOME
    HOME_TITLE: 'Conheça a infraestrutura da ciência brasileira',
    HOME_BANNER_TEXT:
      'Encontre laboratórios e equipamentos de Instituições que compõem a infraestrutura de pesquisa científica brasileira',
    HOME_SEARCH_ASSETS_BANNER:
      'Plataforma Nacional de Infraestrutura de Pesquisa MCTI',
    HOME_SEARCH_ASSETS_PLACEHOLDER:
      'pesquise por laboratórios, equipamentos...',
    NEWSLETTER_SIGN: 'Assine nossa newsletter!',
    NEWSLETTER_FREQUENCY:
      'Não se preocupe, nossa frequência de envios é pequena.',
    NEWSLETTER_PLACEHOLDER: 'Insira seu email',
    NEWSLETTER_SUBMIT: 'Assinar',
    NEWSLETTER_COPYRIGHT: '© 2020. Todos os direitos reservados',
    NEWSLETTER_TERMS: 'Termos de uso',
    NEWSLETTER_SIGNIN: 'Cadastrado com sucesso',
    SEE_MORE: 'Veja Todos',
    EQUIPMENT: 'Equipamentos',

    // SEARCH
    SEARCH_LAB: 'Laboratórios',
    SEARCH_EQUIP: 'Equipamentos',
    SEARCH_ALL: 'Resultado de busca',

    //MY PROFILE
    MY_PROFILE_PAGE: 'Meu painel',

    // HOME SLIDER
    INSTITUTION: 'Instituição',
    ADDRESS: 'Local',
    VIEW: 'Visualizar',

    // CARD
    LABEL_LAB: 'Laboratório',
    LABEL_INSTITUTION: 'Instituição',

    // REGISTER USER
    REGISTER_USER_TITLE: 'Cadastrar novo usuário',
    REGISTER_USER_NAME: 'Nome',
    REGISTER_USER_NAME_PLACEHOLDER: 'Insira seu nome',
    REGISTER_USER_LAST_NAME: 'Sobrenome',
    REGISTER_USER_LAST_NAME_PLACEHOLDER: 'Insira seu sobrenome',
    REGISTER_USER_CPF: 'CPF',
    REGISTER_USER_CPF_PLACEHOLDER: '999.999.999-99',
    REGISTER_USER_EMAIL: 'Email',
    REGISTER_USER_EMAIL_PLACEHOLDER: 'Insira seu email',
    REGISTER_USER_ACCEPT_TERMS: 'Eu aceito os',
    REGISTER_USER_TERMS: 'Termos de uso',
    REGISTER_USER_REGISTER_BUTTON: 'Cadastrar',
    REGISTER_USER_LATTES_CURRICULUM_LINK: 'Link do currículo lattes',
    REGISTER_USER_LATTES_CURRICULUM_LINK_PLACEHOLDER:
      'Cole aqui a URL para seu currículo Lattes',
    REGISTER_USER_LATTES_CURRICULUM_LINK_HINT:
      'Recomendamos o uso da URL amigável, disponível junto a seus dados pessoais na página do currículo lattes. Exemplo: “Endereço para acessar este CV: http://lattes.cnpq.br/68583027832159094”',
    REGISTER_USER_VALIDATION: 'Validação de Usuário',
    REGISTER_USER_ACCOUNT_SUCCESSFULLY_REGISTERED:
      'Conta cadastrada com sucesso! Foi enviado um e-mail para sua conta pessoal. Siga suas instruções para ativar sua conta',
    REGISTER_USER_VALIDATION_CODE: 'Código de Validação',
    REGISTER_USER_SEND_VALIDATION_CODE:
      'Enviamos um código de validação para o e-mail cadastrado para que seja possível validar seu usuário. Por favor, insira o código de validação no campo abaixo',
    REGISTER_USER_VALIDATION_ERROR: 'CPF ou Email já cadastrado na plataforma',

    // REGISTER INSTITUTION
    REGISTER_INSTITUTION_TITLE: 'Editar Instituição',
    REGISTER_INSTITUTION_DATA: 'Dados da instituição',
    REGISTER_INSTITUTION_COMPANY_CODE: 'CNPJ',
    REGISTER_INSTITUTION_COMPANY_CODE_PLACEHOLDER: 'Insira o CNPJ',
    REGISTER_INSTITUTION_COMPANY_PHONE_NUMBER: 'Telefone institucional',
    REGISTER_INSTITUTION_COMPANY_INITIALS: 'Sigla',
    REGISTER_INSTITUTION_COMPANY_NAME: 'Razão social',
    REGISTER_INSTITUTION_ADDRESS: 'Endereço',
    REGISTER_INSTITUTION_ZIP_CODE: 'CEP',
    REGISTER_INSTITUTION_STREET: 'Logradouro',
    REGISTER_INSTITUTION_NUMBER: 'Número',
    REGISTER_INSTITUTION_COMPLEMENT: 'Complemento',
    REGISTER_INSTITUTION_DISTRICT: 'Bairro',
    REGISTER_INSTITUTION_COUNTRY: 'Município',
    REGISTER_INSTITUTION_ESTATE_INITIALS: 'UF',
    REGISTER_INSTITUTION_SELECT_ESTATE: 'Selecione um estado',
    REGISTER_INSTITUTION_REGION: 'Região',
    REGISTER_INSTITUTION_SELECT_REGION: 'Selecione uma região',
    REGISTER_INSTITUTION_ADD_ORGANIZATIONAL_STRUCTURE_TEXT:
      'Clique para adicionar uma estrutura organizacional',
    REGISTER_INSTITUTION_ADD_ORGANIZATIONAL_STRUCTURE:
      'Estrutura organizacional',
    REGISTER_INSTITUTION_ADD_ORGANIZATIONAL_STRUCTURE_INFO:
      'Cadastre os tipos de unidade existentes em sua organização (Diretoria, Coordenação, Secretaria, Laboratório etc.) na qual sua infraestrutura de pesquisa encontra hierarquicamente. Arraste para posicionar os itens na disposição hierárquica correta.',
    REGISTER_INSTITUTION_ADD_ITEM_ORGANIZATIONAL_STRUCTURE:
      'Adicione novo item de estrutura',
    REGISTER_INSTITUTION_ORGANIZATIONAL_STRUCTURE: 'Estrutura Organizacional',
    REGISTER_INSTITUTION_MANAGE_REPRESENTATIVES: 'Gerenciar Responsáveis',
    REGISTER_INSTITUTION_INVITE_REPRESENTATIVES: 'Convidar Responsável',
    REGISTER_INSTITUTION_INVITE: 'Convidar',
    REGISTER_INSTITUTION_ADD_EMAIL: 'Adicionar email',
    REGISTER_INSTITUTION_ADD_INVITE: 'Adicionar convite',
    REGISTER_INSTITUTION_ADD_INVITE_LIMIT_EXCEEDED:
      'Limite de 10 representantes atingido',
    REGISTER_INSTITUTION_REPRESENTATIVES_EMAIL: 'Email:',
    REGISTER_INSTITUTION_REPRESENTATIVES_CPF: 'CPF:',
    REGISTER_INSTITUTION_REPRESENTATIVES_INACTIVE: 'Inativo na plataforma',
    REGISTER_INSTITUTION_REPRESENTATIVES_NAME: 'Nome',
    REGISTER_INSTITUTION_REPRESENTATIVES_PERMISSION: 'Permissão',
    REGISTER_INSTITUTION_REPRESENTATIVES_TOTAL: 'Total',
    REGISTER_INSTITUTION_REPRESENTATIVES_PARTIAL: 'Parcial',
    REGISTER_INSTITUTION_REPRESENTATIVES_LIMITED: 'Limitado',
    REGISTER_INSTITUTION_DIALOG_TOTAL:
      'Representante máximo da instituição frente ao sistema. Tem acesso a todas as funcionalidades referentes a instituição no sistema',
    REGISTER_INSTITUTION_DIALOG_PARTIAL:
      'Responsável por validar (aprovar ou recusar) se um laboratório cadastrado e seus responsáveis fazem parte da instituição.',
    REGISTER_INSTITUTION_DIALOG_LIMITED: 'Pode apenas visualizar informações.',
    REGISTER_INSTITUTION_REMOVE_SELF:
      'Está  removendo  seu  próprio  perfil  e  perderá  acesso  a  essa  instituição, deseja continuar?',
    REGISTER_INSTITUTION_REPRESENTATIVES_STATUS: 'Situação',
    REGISTER_INSTITUTION_INCLUDE: 'Incluir',
    REGISTER_INSTITUTION_SEND_INVITE: 'Enviar convite',
    REGISTER_INSTITUTION_ADD_BUTTON: 'Adicionar',
    REGISTER_INSTITUTION_SAVE: 'Salvar',
    REGISTER_INSTITUTION_SAVE_SUCCESS: 'Salvo com sucesso',
    REGISTER_INSTITUTION_SAVE_ERROR:
      'Não foi possível realizar a operação. Caso o problema persista, contate o administrador do sistema',
    REGISTER_INSTITUTION_CANCEL: 'Cancelar',
    REGISTER_INSTITUTION_TERMS: 'Termos de uso',
    REGISTER_INSTITUTION_DECLARE_HAVE_READ_THE_TERMS:
      'Declaro que sou membro dessa instituição e que li o ',
    REGISTER_INSTITUTION_CONFIRM_DIALOG:
      'As informações preenchidas, serão perdidas. Deseja continuar?',
    REGISTER_INSTITUTION_ALERT_EMAIL_INVITE_DIALOG:
      'Um email de convite foi informado, realize o convite ou limpe o campo para continuar',
    REGISTER_INSTITUTION_USER_ALREADY_INVITED: 'Usuário já convidado',
    REGISTER_INSTITUTION_USER_ALREADY_INVITED_OTHER_INSTITUTION:
      'Usuário já convidado para outra instituição. Deseja continuar?',
    REGISTER_INSTITUTION_MAXIMUM_USERS_REACH:
      'Limite de 10 representantes atingido.',
    REGISTER_INSTITUTION_ALREADY_IN_OTHER_INSTITUTION:
      'Não é possível realizar a ativação, pois este usuário já possui vínculo com outra instituição. Caso deseje remova-o da lista e faça novo convite. ',
    REGISTER_INSTITUTION_AT_LEAST_ONE_TOTAL:
      'Não foi possível realizar a operação. É necessário ter ao menos um responsável total ativo na sua instituição.',
    REGISTER_INSTITUTION_REFUSE_INVITE:
      'Deseja realmente recusar esse convite?',
    REGISTER_INSTITUTION_ACCEPT_INVITE:
      'Deseja realmente aceitar esse convite?',
    REGISTER_INSTITUTION_ACCEPT_INVITE_ALREADY_IN_INSTITUTION:
      'Ao aceitar esse convite, você perderá seu vínculo com sua instituição <strong>{{institution}}</strong>. Deseja continuar?',
    REGISTER_INSTITUTION_ACCEPT_INVITE_ERROR:
      'Para aceitar esse convite, é necessário vincular outro responsável total para a instituição que gerencia.',
    REGISTER_RESPONSIBLE_DATA: 'Dados do dirigente máximo',
    REGISTER_RESPONSIBLE: 'Responsáveis',
    REGISTER_RESPONSIBLE_NAME: 'Nome do responsável',
    REGISTER_INSTITUTION_BOND_TEXT: 'Comprovação de vínculo com a instituição',
    REGISTER_DOCUMENT_UPLOAD:
      'Clique para adicionar um documente ou arraste-o para este campo',
    REGISTER_RESPONSIBLE_WARNING_MESSAGE:
      'Salve este formulário para concluir a alteração do dirigente máximo. Uma notificação será enviada aos demais responsáveis',

    // REGISTER LABORATORY
    POSITION_OF_THE_LABORATORY_ON_THE_MAP:
      'Marque a posição do laboratório no mapa',
    LABORATORY_RESPONSIBLE_DATA: 'Dados do Responsável pelo Laboratório',
    REGISTER_LABORATORY_SAVED_AS_DRAFT: 'Salvo como rascunho',
    REGISTER_LABORATORY_VIEW_LAB: 'Visualizar laboratório',
    REGISTER_LABORATORY_EDIT_LAB_PAGE: 'Editar página do laboratório',
    REGISTER_LABORATORY_INITIALS: 'Sigla',
    REGISTER_LABORATORY_LAB_NAME: 'Nome do laboratório',
    REGISTER_LABORATORY_INVALID_CNPJ:
      'O CNPJ informado ainda não está cadastrado na Plataforma. Aguarde esse cadastro para solicitar que seu laboratório seja incluído.',
    REGISTER_LABORTORY_SOCIAL_REASON_NOT_FOUND:
      'A Instituição informada ainda não está cadastrado na Plataforma. Aguarde esse cadastro para solicitar que seu laboratório seja incluído.',
    REGISTER_LABORATORY_ADD_IMAGE: 'Clique para adicionar uma imagem',
    REGISTER_LABORATORY_ADD_EQUIPMENT: 'Clique para adicionar um equipamento',
    REGISTER_LABORATORY_ADD_TAG: 'Clique aqui para inserir...',
    REGISTER_LABORATORY_ADDRESS: 'Endereço',
    REGISTER_LABORATORY_DATA: 'Dados do Laboratório',
    REGISTER_LABORATORY_TAG_ADD_TECHNIQUE_TITLE:
      'Insira as Principais Técnicas',
    REGISTER_LABORATORY_TAG_TECHNIQUE_TITLE: 'Principais Técnicas',
    REGISTER_LABORATORY_TAG_ADD_AREAEXPERTISE_TITLE:
      'Insira uma ou mais áreas de atuação',
    REGISTER_LABORATORY_TAG_AREAEXPERTISE_TITLE: 'Áreas de atuação',
    REGISTER_LABORATORY_TYPE: 'Tipo de Laboratório',
    REGISTER_LABORATORY_ACTIVITIES:
      'Natureza das atividades desenvolvidas no laboratório    ',
    REGISTER_LABORATORY_OTHER_TYPE: 'Informe outra natureza de atividade',
    REGISTER_LABORATORY_CHANGELOG_LABORATORYCUSTOMTYPE:
      'Informe outro tipo de laboratório',
    REGISTER_LABORATORY_PROOF_LINK: 'Comprovação de vínculo com o laboratório',
    REGISTER_LABORATORY_INCLUSION_REQUEST:
      'Solicitação de Inclusão de Laboratório',
    REGISTER_LABORATORY_APPROVE_REQUEST:
      'Aprovação de Solicitação de inclusão de laboratório',
    REGISTER_LABORATORY_HINT: `<strong>Laboratório:</strong>
      <br/><br/>
      Unidade laboratorial, núcleo ou centro de pesquisa dotado de infraestrutura física, instrumental e material com capacidade para o desenvolvimento e realização de ensaio, métodos, análises, exames etc., tendo como objeto os mais diversos materiais e/ou amostras nas várias áreas do conhecimento que requerem pesquisa experimental e análise instrumental.`,
    REGISTER_LABORATORY_ATTACH_LAB_COMPROVATION:
      'Clique para adicionar um documento ou arraste para este campo que comprove seu vínculo com este laboratório',

    REGISTER_LABORATORY_SAVE_REGISTER: 'Enviar',
    REGISTER_LABORATORY_SAVE_EDITING: 'Atualizar',
    REGISTER_LABORATORY_SAVE_APPROVING: 'Concluir',
    REGISTER_LABORATORY_SAVE_APPROVING_EDIT: 'Concluir',

    REGISTER_LABORATORY_SUCCESS:
      'Laboratório cadastrado com sucesso. Aguarde a aprovação da instituição para cadastrar seus equipamentos.',
    REGISTER_LABORATORY_ERROR:
      'Não foi possível realizar a operação. Caso o problema persista, contate o administrador do sistema',
    REGISTER_LABORATORY_APPROVAL: 'Laboratório aprovado com sucesso',
    REGISTER_LABORATORY_REFUSED: 'Operação realizada com sucesso',
    REGISTER_LABORATORY_UPDATED: 'Laboratório atualizado com sucesso',

    REGISTER_LABORATORY_CONFIRM_DIALOG:
      'As informações preenchidas, serão perdidas. Deseja continuar?',
    REGISTER_LABORATORY_CONFIRM_DIALOG_AGREE: 'Sim',
    REGISTER_LABORATORY_CONFIRM_DIALOG_DISAGREE: 'Não',
    REGISTER_LABORATORY_CONFIRM_EDITING_DIALOG:
      'As alterações realizadas serão submetidas para nova análise da instituição. Deseja continuar?',
    REGISTER_LABORATORY_REMOVE_SELF:
      'Está  removendo  seu  próprio  perfil  e  perderá  acesso  a  esse  laboratório, deseja continuar?',

    COMMONS_REGISTER_ADD_BUTTON: 'Adicionar',
    COMMONS_REGISTER_NONE_REGISTER: 'Nenhum registro encontrado',
    COMMONS_MAX_LIMIT_REACHED: 'Limite máximo atingido',
    COMMONS_MAX_ITEMS_LIMIT_REACHED:
      'Quantidade máxima de {{limit}} itens já adicionados',

    SELECT_PROFILE: 'Selecione o perfil',
    NO_ACTION: 'Sem ação',
    REGISTER_INSTITUTION_PUBLISH: 'Publicar',

    TABLE_COMPARATION_TITLE: 'Alterações solicitadas',
    TABLE_COMPARATION_FIELD: 'Campo',
    TABLE_COMPARATION_PREVIOUS_VALUE: 'Valor anterior',
    TABLE_COMPARATION_CHANGED_VALUE: 'Valor alterado',

    LABORATORY_APPROVE_OPINION: 'Parecer sobre as alterações solicitadas',
    LABORATORY_APPROVE_REQUEST_OPINION:
      'Deseja aprovar todos os campos que foram modificados?',
    LABORATORY_REASON_REFUSE: 'Justifique os itens recusados',

    // EDIT LABORATORY
    EDIT_LABORATORY_TITLE: 'Edição avançada',
    EDIT_LABORATORY_ABOUT_LAB: 'Sobre este laboratório',
    EDIT_LABORATORY_ABOUT_LAB_PLACEHOLDER:
      'Insira uma breve descrição sobre o que é esse laboratório',
    EDIT_LABORATORY_ADRESS: 'Endereço',
    EDIT_LABORATORY_CONTACT: 'Contato',
    EDIT_LABORATORY_ZIP_CODE: 'CEP',
    EDIT_LABORATORY_RESPONSIBLE: 'Responsável',
    EDIT_LABORATORY_TELEPHONE: 'Telefone',
    EDIT_LABORATORY_EMAIL: 'Email',
    EDIT_LABORATORY_EMAIL_ADD: 'Insira o email do laboratório',
    EDIT_LABORATORY_WEBSITE: 'Website',
    EDIT_LABORATORY_WEBSITE_ADD: 'Insira a URL do site do laboratório',
    EDIT_LABORATORY_AREA_EXPERTISE: 'Área de atuação',
    EDIT_LABORATORY_TECHINIQUE: 'Principais Técnicas',
    EDIT_LABORATORY_INDEX_SLIDER: 'de',
    EDIT_LABORATORY_ADD_RESPONSIBLE: 'Incluir responsável',
    EDIT_LABORATORY_ADD_INITIAL: 'Incluir sigla',
    EDIT_LABORATORY_LOCATION: 'Veja como chegar',
    EDIT_LABORATORY_REQUIRED_IMAGE_ERROR_MESSAGE:
      'Ao menos uma imagem deve ser adicionada para publicar seu laboratório',

    EDIT_LABORATORY_SHARING_CONFIG_TITLE: 'Configurar Compartilhamento',
    EDIT_LABORATORY_SHARING_CONFIG_HAS_SHARING:
      'Marque para habilitar e configurar a seção de compartilhamento do seu laboratório.',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE: 'Forma de compartilhamento',

    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_SHARING:
      'Quero utilizar o serviço de solicitação de utilização da plataforma.',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_SHARING_LINK:
      'Visualizar padrão do formulário de compartilhamento.',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_TITLE:
      'Compartilhamento na plataforma',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_SUBTITLE:
      'Termos de uso do Laboratório e de seus equipamentos',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_DESC:
      'Descreva as regras para compartilhamento e agendamento. Importante informar quais as responsabilidades sobre eventuais danos físicos na estrutura do laboratório e em seus equipamentos.',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_TERMS: 'Termos de uso',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_TERMS_BUTTON:
      'Clique aqui para adicionar os termos de uso',
    EDIT_LABORATORY_SHARING_CONFIG_TERMS_REQUIRED_ERROR:
      'Os termos de uso são obrigatórios',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_TERMS_HAS_COSTS:
      'Marque caso o compartilhamento possa acarretar custos ao solicitante',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_TERMS_HAS_COSTS_HINT:
      'À definir',
      EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_USE_STATEMENT:
      'Declaro que estou ciente que a PNIPE MCTI fornece uma interface de comunicação entre o interessado e o responsável pelo laboratório detentor do equipamento, mas não interfere nos termos dessa negociação. Dessa forma, as instituições/laboratórios deverão elaborar seus próprios termos, considerando as especificidades de cada caso.',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_OWN_SHARING:
      'Possuo processo de compartilhamento/agendamento próprio (portal, formulário, etc.)',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_OWN_SHARING_TITLE:
      'Compartilhamento próprio',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_OWN_SHARING_LABEL:
      'Link de acesso para o processo de compartilhamento próprio do laboratório',
    EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_OWN_SHARING_PLACEHOLDER:
      'Informe o link',

    EDIT_LABORATORY_SHARING_VIEW: 'Informações de compartilhamento',
    EDIT_LABORATORY_SHARING_VIEW_OWN_SHARING_TITLE: 'Compartilhamento próprio',
    EDIT_LABORATORY_SHARING_VIEW_OWN_SHARING_DESC:
      'Link de acesso para o processo de compartilhamento próprio do laboratório',
    EDIT_LABORATORY_SHARING_VIEW_TITLE: 'Compartilhamento na plataforma',
    EDIT_LABORATORY_SHARING_VIEW_DESC:
      'Termos de uso do Laboratório e de seus equipamentos',

    FILE_UPLOAD_MAX_LIMIT_REACHED:
      'Limite de {{number}} arquivos anexados atingidos',
    FILE_UPLOAD_MAX_SIZE_LIMIT_REACHED:
      'Limite máximo de tamanho por arquivo atingido',
    FILE_UPLOAD_INVALID_EXTENSION:
      'Formato inválido! Apenas arquivos em {{extensions}} podem ser anexados.',
    FILE_UPLOAD_NEW_FOTO: 'Nova foto',
    FILE_UPLOAD_INCLUDE_BUTTON: 'Incluir',
    FILE_UPLOAD_UPDATE_BUTTON: 'Atualizar',
    FILE_UPLOAD_CANCEL_BUTTON: 'Cancelar',

    // REGISTER EQUIPMENT
    REGISTER_EQUIPMENT_INCLUSION_REQUEST: 'Cadastrar equipamento',
    REGISTER_EQUIPMENT_HINT: `
      Principais equipamentos de P&D existentes na infraestrutura de pesquisa, com custo elevado de aquisição e que são cruciais para as atividades de pesquisa do laboratório.
      <br/>
      O foco são equipamentos de grande relevância para a infraestrutura de pesquisa e não aqueles que possuem papel acessório nas pesquisas realizadas.`,
    REGISTER_EQUIPMENT_DATA: 'Dados do equipamento',
    REGISTER_EQUIPMENT_PHOTOS: 'Fotos do equipamento',
    REGISTER_EQUIPMENT_ABOUT: 'Sobre o equipamento',
    REGISTER_EQUIPMENT_PARAMETERS: 'Parâmetros',
    REGISTER_EQUIPMENT_RESPONSABLE: 'Responsáveis',
    REGISTER_EQUIPMENT_LABORATORY_LIST_DEFAULT: 'Laboratório',
    REGISTER_EQUIPMENT_LABORATORY_LIST_LABEL: 'Laboratório',
    REGISTER_EQUIPMENT_NAME: 'Nome do equipamento',
    REGISTER_EQUIPMENT_NAME_INITIALS: 'Sigla do equipamento',
    REGISTER_EQUIPMENT_OTHER_DENOMINATION_LABEL:
      'Outras denominações do equipamento',
    REGISTER_EQUIPMENT_OTHER_DENOMINATION_PLACEHOLDER: 'Adicione...',
    REGISTER_EQUIPMENT_PARAMETER_LIMIT:
      'Quantidade máxima de {{maxParameter}} itens já adicionados',
    REGISTER_EQUIPMENT_OTHER_DENOMINATION_ADD: 'Adicionar',
    REGISTER_EQUIPMENT_OTHER_DENOMINATION_HINT:
      'Informe outros nomes que o equipamento é conhecido. Informe também seu nome em inglês.',

    REGISTER_EQUIPMENT_TYPE_IDENTIFIER: 'Tipo de identificador persistente',
    REGISTER_EQUIPMENT_IDENTIFIER: 'Identificador persistente do equipamento',
    REGISTER_EQUIPMENT_TYPE_IDENTIFIER_HINT: 'Ex. ISBN, ISSN, DOI...',
    REGISTER_EQUIPMENT_IDENTIFIER_HINT:
      'Identificador universal do equipamento',

    REGISTER_EQUIPMENT_BRAND: 'Marca',
    REGISTER_EQUIPMENT_MODEL: 'Modelo',
    REGISTER_EQUIPMENT_YEAR: 'Ano de aquisição',
    REGISTER_EQUIPMENT_MANUFACTURER: 'Fabricante',
    REGISTER_EQUIPMENT_LINK: 'Link do equipamento no site do fabricante',
    REGISTER_EQUIPMENT_FINANCING: 'Fonte de Financiamento',
    REGISTER_EQUIPMENT_FINANCING_ORIGIN_TYPE: 'Tipo da fonte',
    REGISTER_EQUIPMENT_FINANCING_ORIGIN_NAME: 'Nome da fonte',
    REGISTER_EQUIPMENT_FINANCING_ORIGIN_PLACE_HOLDER: 'Especifique...',
    REGISTER_EQUIPMENT_MULTIUSER_LABEL: 'Multiusuário',
    REGISTER_EQUIPMENT_MULTIUSER_HINT:
      'Percentual de carga horária de uso do equipamento (Ex.: semanal, mensal, etc) que pode ser disponibilizada a usuários externos (mediante condições estabelecidas para cada caso).',
    REGISTER_EQUIPMENT_MULTIUSER_CHECKBOX: 'O equipamento é multiusuário?',
    REGISTER_EQUIPMENT_USAGE_PROFILE: 'Perfil de uso do equipamento',
    REGISTER_EQUIPMENT_SLIDER_LABEL:
      'Dedicado aos projetos internos do laboratório',
    REGISTER_EQUIPMENT_SLIDER_LIMIT_MESSAGE:
      'A soma das porcentagens não podem ultrapassar 100%',
    REGISTER_EQUIPMENT_SLIDER_HINT:
      'Percentual de carga horária de uso do equipamento (Ex.: semanal, mensal, etc) dedicada à atividades internas do laboratório.',
    REGISTER_EQUIPMENT_ABOUT_LABEL: 'Sobre o equipamento',
    REGISTER_EQUIPMENT_ABOUT_DESCRIPTION: 'Descrição do equipamento',
    REGISTER_EQUIPMENT_BOX_UPLOAD:
      'Clique aqui para adicionar fotos do equipamento',
    REGISTER_EQUIPMENT_MAIN_TECHNIQUE: 'Técnicas utilizadas nesse equipamento',
    REGISTER_EQUIPMENT_ADD_MAIN_TECHNIQUE: 'Insira uma ou mais técnicas',

    REGISTER_EQUIPMENT_PARAMETERS_LABEL: 'Parâmetros',
    REGISTER_EQUIPMENT_PARAMETERS_NAME_PLACEHOLDER:
      'Ex. Potência, capacidade, etc.',
    REGISTER_EQUIPMENT_PARAMETERS_VALUE_PLACEHOLDER:
      'Digite valores e/ou atributos que caracterizam o parâmetro',
    REGISTER_EQUIPMENT_PARAMETERS_UNIT_PLACEHOLDER: 'Ex. km, m³',
    REGISTER_EQUIPMENT_PARAMETERS_NAME: 'Nome do parâmetro',
    REGISTER_EQUIPMENT_PARAMETERS_VALUE: 'Valor',
    REGISTER_EQUIPMENT_PARAMETERS_UNIT: 'Unidade (se houver)',
    REGISTER_EQUIPMENT_CANCEL_CONFIRM:
      'As informações preenchidas, serão perdidas. Deseja continuar? ',

    EQUIPMENT_LIST_SELECT_LAB: 'Selecione o laboratório',

    LIST_EQUIPMENT_ACTION_VIEW: 'Visualizar',
    LIST_EQUIPMENT_ACTION_EDIT: 'Editar',
    LIST_EQUIPMENT_ACTION_DELETE: 'Deletar',
    LIST_EQUIPMENT_ACTION_INACTIVATE: 'Inativar',
    LIST_EQUIPMENT_ACTION_ACTIVATE: 'Ativar',
    LIST_EQUIPMENT_ACTION_REPLICATE: 'Replicar',
    LIST_EQUIPMENT_ACTION_CANCEL: 'Cancelar',
    LIST_EQUIPMENT_ACTION_EXCLUDE: 'Excluir',
    LIST_EQUIPMENT_ACTION_COPY: 'Replicar equipamento',

    FORM_EQUIPMENT_REPLICATE_TITLE: 'Replicar equipamento',
    FORM_EQUIPMENT_TEXT_LABEL: 'Quantidade de equipamentos iguais:',
    FORM_EQUIPMENT_PLACEHOLDER_LABEL: 'Informe...',
    FORM_EQUIPMENT_REPLICATE_MODAL_LABEL:
      'Utilize o cadastro desse equipamento para gerar cópias para equipamentos iguais. Após replicar, será possivel editar para realizar mudanças, caso considere necessário.',

    REGISTER_EQUIPMENT_CARACTERISTICS:
      'Parâmetro de característica do equipamento',
    REGISTER_EQUIPMENT_CARACTERISTICS_HINT:
      'Descreva o conjunto de características e especificações do equipamento que são relevantes para sua utilização',

    REGISTER_EQUIPMENT_RESPONSIBLE: 'Responsáveis',
    REGISTER_EQUIPMENT_ADD_RESPONSIBLE: 'Adicionar responsável',
    REGISTER_EQUIPMENT_ADD_RESPONSIBLE_PLACEHOLDER:
      'Insira o nome do responsável',
    REGISTER_EQUIPMENT_SAVE: 'Salvar',
    REGISTER_EQUIPMENT_CANCEL_DIALOG:
      'As informações preenchidas, serão perdidas. Deseja continuar? (Sim/Não) ',

    EXCLUDE_EQUIPMENT_DIALOG: 'Deseja realmente excluir o equipamento?  ',
    ACTIVATE_EQUIPMENT_DIALOG: 'Deseja realmente ativar o equipamento?  ',

    // EQUIPMENT LIST
    EQUIPMENT_LIST_HEADER: 'Equipamentos',
    EQUIPMENT_LIST_FILTER_LABORATORY: 'Selecione o Laboratório',
    EQUIPMENT_LIST_FILTER_LABORATORY_DEFAULT: 'Todos',
    EQUIPMENT_LIST_FILTER_STATUS: 'Filtrar por',
    EQUIPMENT_LIST_SEARCH_DEFAULT:
      'Buscar equipamentos por código, nome, marca, modelo, fabricante',
    EQUIPMENT_LIST_ADD_EQUIPMENT_BUTTON: 'Novo equipamento',
    EQUIPMENT_LIST_ADD_COVID_FREEZER_BUTTON: 'Ultracongelador COVID',
    EQUIPMENT_LIST_ACTION: 'Ações',
    EQUIPMENT_LIST_UPDATE: 'Atualizar',
    EQUIPMENT_LIST_SELECTED_ITEMS: 'Selecionados',
    EQUIPMENT_LIST_CONFIRM_UPDATE_TITLE: 'Atualizar equipamentos',
    EQUIPMENT_LIST_CONFIRM_UPDATE_TEXT:
      'Declaro que os equipamentos não sofreram mudanças/atualizações em sua composição desde seu cadastro.',

    EQUIPMENT_LIST_TABLE_SITUATION: 'Situação',
    EQUIPMENT_LIST_TABLE_CODE: 'Código',
    EQUIPMENT_LIST_TABLE_NAME: 'Nome',
    EQUIPMENT_LIST_TABLE_BRAND: 'Marca',
    EQUIPMENT_LIST_TABLE_MODEL: 'Modelo',
    EQUIPMENT_LIST_TABLE_MANUFACTURE: 'Fabricante',
    EQUIPMENT_LIST_TABLE_LABORATORY: 'Laboratório',

    // EQUIPMENT DIALOG ACTION
    EQUIPMENT_DIALOG_MOTIVE: 'Motivo',
    EQUIPMENT_DIALOG_INFORM_MOTIVE: 'Informe o outro motivo (caso exista)',
    EQUIPMENT_DIALOG_DETAIL_MOTIVE: 'Detalhar o motivo',

    EQUIPMENT_DIALOG_EXCLUDE_TITLE: 'Excluir equipamento',
    EQUIPMENT_DIALOG_INACTIVE_TITLE: 'Inativar equipamento',
    EQUIPMENT_DIALOG_REPLICATE_TITLE: 'Replicar equipamento',
    EQUIPMENT_DIALOG_OK: 'OK',
    EQUIPMENT_LIST_EQUIPMENT_HAS_SHARING_ACTION: {
      EXCLUDE_ACTION: 'excluir',
      INACTIVATE_ACTION: 'inativar'
    },
    EQUIPMENT_LIST_EQUIPMENT_HAS_SHARING: `
      Não é possivel <strong>{{action}}</strong> o equipamento <strong>{{equipment}}</strong> porque existem solicitações de compartilhamento em andamento.
      Consulte a <SharingLink>lista de compartilhamento</SharingLink> e altere ou cancele as solicitações que o contenham.
      Utilize o código do equipamento para buscar pelas suas solicitações.`,

    // LOGIN
    LOGIN_TITLE: 'Login',
    LOGIN_USER_LABEL: 'Usuário',
    LOGIN_USER_PLACEHOLDER: 'Insira seu CPF ou E-mail',
    LOGIN_PASSWORD_LABEL: 'Senha',
    LOGIN_PASSWORD_PLACEHOLDER: 'Insira sua senha',
    LOGIN_SIGN_IN: 'Entrar',
    LOGIN_FORGOT_PASSWORD: 'Esqueci minha senha',
    LOGIN_NO_ACCOUNT: 'Não possui acesso?',
    LOGIN_SIGN_UP: 'Crie sua conta',

    LOGIN_CHANGE_PASSWORD_TITLE: 'Criar senha',
    LOGIN_CHANGE_PASSWORD_SEND: 'REDEFINIR',
    LOGIN_CHANGE_PASSWORD_NEW_LABEL: 'Nova Senha',
    LOGIN_CHANGE_PASSWORD_NEW_PLACEHOLDER: 'Nova Senha',
    LOGIN_CHANGE_PASSWORD_REPEAT_LABEL: 'Repita a senha',
    LOGIN_CHANGE_PASSWORD_REPEAT_PLACEHOLDER: 'Repita a senha',
    LOGIN_INVALID_CREDENTIALS: 'Login ou senha incorretos',
    LOGIN_TOKEN_ALREAD_USED:
      'Solicitação já utilizada! Caso deseje alterar novamente sua senha, faça nova solicitação.',
    LOGIN_CHANGE_PASSWORD_CONFIRM: 'Confirme a senha',
    LOGIN_CHANGE_PASSWORD_MUST_MATCH:
      'As senhas não coincidem. Tente novamente.',

    LOGIN_MUST_CONTAIN_LOWER_CASE: 'Deve conter uma letra minúscula',
    LOGIN_MUST_CONTAIN_UPPER_CASE: 'Deve conter uma letra maiúscula',
    LOGIN_MUST_CONTAIN_NUMBER: 'Deve conter um número',
    LOGIN_MUST_CONTAIN_SPECIAL_CHARACTER: 'Deve conter um caractere especial',
    LOGIN_MUST_CONTAIN8_CHARACTERS: 'Deve conter ao menos 8 caracteres',

    // LOGIN VALIDATION
    LOGIN_VALIDATION_CONFIRMED_USER:
      'Usuário confirmado! Insira seu CPF ou E-mail e senha para acessar!',
    LOGIN_CODE_RESENT_SUCCESSFULLY: 'E-mail com código reenviado com sucesso!',
    LOGIN_VALIDATION_ERROR_INVALID_CODE:
      'Código inválido! Verifique o código recebido e tente novamente ou solicite outro código por meio da opção “Reenviar Código”.',
    LOGIN_VALIDATION_ERROR_USER_NOT_FOUND: 'Usuário não cadastrado!',
    LOGIN_VALIDATION_ERROR_USER_BLOCK:
      'Foram feitas muitas solicitações. Por gentileza verifique em seu e-mail na caixa de spam. Caso não encontre a mensagem, pedimos que aguarde para tentar novamente ou que entre em contato com o suporte.',

    // RESET PASSWORD
    RESET_PASSWORD_TITLE: 'Redefina sua senha',

    // REQUEST NEW PASSWORD
    RESET_PASSWORD_CPF_LABEL: 'CPF',
    RESET_PASSWORD_CPF_PLACEHOLDER: 'CPF',
    RESET_PASSWORD_NEW_LABEL: 'Senha',
    RESET_PASSWORD_NEW_PLACEHOLDER: 'Nova senha',
    RESET_PASSWORD_REPEAT_LABEL: 'Repita a senha',
    RESET_PASSWORD_REPEAT_PLACEHOLDER: 'Repita a senha',
    RESET_PASSWORD_SEND: 'Redefinir',
    RESET_PASSWORD_SUCCESS: 'Senha redefinida com sucesso',

    // REQUEST NEW PASSWORD
    REQUEST_NEW_PASSWORD_TITLE: 'Redefinir senha',
    REQUEST_NEW_PASSWORD_SUB_TITLE:
      'Insira seu CPF ou o e-mail que você usou no cadastro. Nós enviaremos um e-mail com um link para redefinir a sua senha.',
    REQUEST_NEW_PASSWORD_USER_LABEL: 'CPF ou e-mail',
    REQUEST_NEW_PASSWORD_USER_PLACEHOLDER: 'Digite se CPF ou e-mail',
    REQUEST_NEW_PASSWORD_SEND: 'Mandar',
    REQUEST_NEW_PASSWORD_SUCCESS:
      'Verifique sua caixa de e-mail para prosseguir com a redefinição da senha',

    // VALIDATION ERROR
    VALIDATION_ERROR_PASSWORD_CONTAINS_EMAIL_OR_NAME:
      'Senha não pode conter Email ou seu Nome',
    VALIDATION_ERROR_PASSWORD_VALIDATION: 'As senhas não conferem',
    VALIDATION_INVALID_FORMAT_PASSWORD: 'Senha com formato inválido',

    // PRIVATE
    PORTAL_VIEW: 'Visualizar portal',

    // PRIVATE NAV
    NAV_PROFILE: 'Meu painel',
    NAV_INSTITUTION: 'Instituição',
    NAV_INFRAESTRUCTURE: 'Infraestrutura',
    NAV_LAB: 'Laboratório',
    NAV_ADDLAB: 'Solicitar Inclusão de Laboratório',
    NAV_LIST_REPRESENTANTS: 'Responsáveis de laboratório',
    NAV_EQUIPMENT: 'Equipamentos',
    NAV_ADD_EQUIPMENT: 'Cadastrar novo Equipamento',
    NAV_ADVERTISE_PLATFORM: 'Divulgar a plataforma',
    NAV_ADMIN: 'Administrar plataforma',
    NAV_SHARE: 'Compartilhamento',
    NAV_INVITE_LAB: 'Convidar laboratório',
    NAV_SHARING: 'Solicitação de utilização',
    NAV_PNIPE_CAMPAIGN: 'Ultracongeladores COVID-19',

    // OPINION OF REQUEST
    REQUEST_OPINION_LABORATORY_REQUEST_OPNION: 'Parecer sobre essa Solicitação',
    REQUEST_OPINION_UNIT_QUESTION: 'O laboratório faz parte desta instituição?',
    REQUEST_OPINION_RESEARCHER_QUESTION:
      'O requisitante faz parte do laboratório em questão?',
    REQUEST_OPINION_REFUSAL_REASON: 'Motivo da recusa',
    REQUEST_OPINION_SELECT: 'Selecione',
    REQUEST_OPINION_DETAIL_REASON: 'Detalhar motivo',
    REQUEST_OPINION_DETAIL_REASON_PLACEHOLDER: 'Insira uma breve descrição',
    REQUEST_OPINION_ATTACH_FILE: 'Anexar arquivo',
    REQUEST_OPINION_ATTACH_LAB_COMPROVATION:
      'Clique para adicionar um documento ou arraste para este campo que comprove seu vínculo com este laboratório',

    // PUBLIC_AREA
    PUBLIC_AREA_ABOUT_TITLE_1: 'Sobre a plataforma',
    PUBLIC_AREA_ABOUT_SUBTITLE_1:
      'O que é a Plataforma Nacional de Infraestrutura de Pesquisa – MCTI',
    PUBLIC_AREA_ABOUT_1: `A Plataforma Nacional de Infraestrutura de Pesquisa – MCTI (PNIPE) é um instrumento que tem por objetivo mapear e reunir, de maneira sistemática, informações sobre a infraestrutura de pesquisa nas Instituições Científicas, Tecnológicas e de Inovação (ICTs) no País, possibilitando o acesso da comunidade científica/tecnológica e de empresas às instalações laboratoriais e aos equipamentos de pesquisa existentes e promovendo seu uso compartilhado.
      <br/><br/>
      <strong>Dessa forma a PNIPE pretende:<strong> `,
    PUBLIC_AREA_ABOUT_BULLET_LIST_1: `
      <ul>
        <li>Fornecer, à comunidade científica e tecnológica, o acesso a informações sobre as infraestruturas de pesquisa existentes, sua localização, possibilidades e condições de uso;</li>
        <li>Fomentar o potencial de inovação das infraestruturas de pesquisa, dando visibilidade junto às empresas das oportunidades oferecidas para melhorar seus produtos e desenvolver novas tecnologias em cooperação;</li>
        <li>Incentivar o uso compartilhado de recursos e a colaboração entre grupos de pesquisa de diferentes áreas, instituições e regiões, fortalecendo a colaboração entre eles;</li>
        <li>Otimizar o uso de instalações laboratoriais e equipamentos de pesquisa científica, evitando afragmentação e a duplicação de esforços, e;</li>
        <li>Otimizar o investimento de recursos públicos na manutenção preventiva e corretiva das infraestruturas existentes, bem como na construção de novas instalações e aquisição de novos equipamentos.</li>
      </ul>`,
    PUBLIC_AREA_ABOUT_SUBTITLE_2: 'O que são infraestruturas de pesquisa',
    PUBLIC_AREA_ABOUT_2: `Infraestruturas de pesquisa são instalações físicas ou virtuais que fornecem à comunidade científica insumos, equipamentos e serviços para realizar atividades de pesquisa e desenvolvimento experimental (P&D) e fomentar a inovação.
      <br/><br/>
      <strong>Esse conceito envolve os seguintes elementos:</strong> `,
    PUBLIC_AREA_ABOUT_BULLET_LIST_2: `
      <ul>
        <li>Instalações físicas e seus equipamentos e instrumentos utilizados nas atividades de P&D;</li>
        <li>Recursos baseados em conhecimento (como coleções, arquivos e base de dados) utilizados em pesquisas científicas;</li>
        <li>Recursos de tecnologia da informação e comunicação (como grids, redes de alto desempenho e softwares específicos); e</li>
        <li>Qualquer outra infraestrutura de natureza singular utilizada em atividades de P&D.</li>
      </ul>`,
    PUBLIC_AREA_ABOUT_3: `
      Essas infraestruturas de pesquisa podem ter localização única ou distribuída ou ainda serem plataformas virtuais, podendo fazer parte de uma rede regional, nacional ou internacional.
      <br/><br/>
      São exemplos de infraestruturas de pesquisa: grandes instalações de pesquisa, laboratórios, plantas piloto, biotérios, salas limpas, redes de informática de alto desempenho, bases de dados, coleções, observatórios, telescópios, navios de pesquisa, reservas e estações experimentais, entre outras.`,
    PUBLIC_AREA_ABOUT_SOURCE: 'Fonte:',

    // FAQ
    FAQ: 'Dúvidas frequentes',
    PUBLIC_AREA_FAQ_TITLE_1: 'Dúvidas frequentes',

    PUBLIC_AREA_QUESTION_1:
      'O que é a Plataforma de Infraestrutura de Pesquisa MCTI?',
    PUBLIC_AREA_ANSWER_1: `
      A Plataforma Nacional de Infraestrutura de Pesquisa MCTI (PNIPE) é um instrumento que tem por objetivo mapear e reunir, de maneira sistemática, informações sobre a infraestrutura de pesquisa nas Instituições Científicas, Tecnológicas e de Inovação (ICTs) no País, possibilitando o acesso da comunidade científica/tecnológica e de empresas às instalações laboratoriais e aos equipamentos de pesquisa existentes e promovendo seu uso compartilhado.
      <br/><br/>
      <strong>Objetivos específicos:</strong>
      <br/>
      <ul>
        <li>Constituir um sistema com informações atualizadas sobre a infraestrutura de pesquisa nas ICTs no País;</li>
        <li>Fornecer à comunidade científica e tecnológica o acesso a informações sobre as infraestruturas de pesquisa existentes, sua localização, possibilidades e condições de uso;</li>
        <li>Incentivar o uso compartilhado de recursos e a colaboração entre grupos de pesquisa de diferentes áreas, instituições e regiões, fortalecendo a colaboração entre eles;</li>
        <li>Racionalizar e otimizar o uso de instalações laboratoriais e equipamentos de pesquisa científica, evitando a fragmentação e a duplicação de esforços;</li>
        <li>Fomentar o potencial de inovação das infraestruturas de pesquisa, dando visibilidade junto às empresas das oportunidades oferecidas para melhorar seus produtos e desenvolver novas tecnologias em cooperação; e</li>
        <li>Otimizar o uso de recursos públicos na manutenção preventiva e corretiva das infraestruturas existentes, bem como na construção de novas instalações e aquisição de novos equipamentos.</li>
      </ul>`,

    PUBLIC_AREA_QUESTION_2: 'O que é uma infraestrutura de pesquisa?',
    PUBLIC_AREA_ANSWER_2: `
      Infraestruturas de pesquisa são instalações físicas ou virtuais que fornecem à comunidade científica insumos, equipamentos e serviços para realizar atividades de pesquisa e desenvolvimento experimental (P&D) e fomentar a inovação.
      <br/><br/>
      <strong>Esse conceito envolve os seguintes elementos:</strong>
      <br/>
      <ul>
        <li>Instalações físicas e seus equipamentos e instrumentos utilizados nas atividades de P&D;</li>
        <li>Recursos baseados em conhecimento (como coleções, arquivos e base de dados) utilizados em pesquisas científicas;</li>
        <li>Recursos de tecnologia da informação e comunicação (como grids, redes de alto desempenho e softwares específicos); e</li>
        <li>Qualquer outra infraestrutura de natureza singular utilizada em atividades de P&D.
        <br/><br/>Essas infraestruturas de pesquisa podem ter localização única ou distribuída ou ainda serem plataformas virtuais, podendo fazer parte de uma rede regional, nacional ou internacional.
        <br/><br/>São exemplos de infraestruturas de pesquisa: grandes instalações de pesquisa, laboratórios, plantas piloto, biotérios, salas limpas, redes de informática de alto desempenho, bases de dados, coleções, observatórios, telescópios, navios de pesquisa, reservas e estações experimentais, entre outras.</li>
      </ul>`,

    PUBLIC_AREA_QUESTION_3: 'Por que participar?',
    PUBLIC_AREA_ANSWER_3: `Ao fornecer à comunidade científica/tecnológica e às empresas o acesso a informações sobre os laboratórios de pesquisa existentes e possibilidades de uso, abrem-se oportunidades de novas parcerias e colaborações.
      <br/><br/>
      Além disso, cria-se um instrumento de gestão do laboratório para as próprias instituições participantes.
      <br/><br/>
      Em termos de políticas públicas para a CT&I, abre-se a possibilidade de otimizar o uso de recursos públicos na manutenção preventiva e corretiva das infraestruturas existentes, bem como na construção de novas instalações e aquisição de novos equipamentos.`,

    PUBLIC_AREA_QUESTION_4: 'Quem pode se cadastrar?',
    PUBLIC_AREA_ANSWER_4: `Instituições Científicas, Tecnológicas e de Inovação sediadas no Brasil que desenvolvam atividades de P&D por meio de suas instalações e laboratórios de pesquisa.`,

    PUBLIC_AREA_QUESTION_5:
      'Como realizar o cadastro de instituição?',
    PUBLIC_AREA_ANSWER_5: `Para realizar o cadastro de novas instituições, acesse a plataforma PNIPE e siga o fluxo para solicitação de cadastro:
   <ul>
      <li>Clique no menu lateral esquerdo  <strong>“Infraestrutura”</strong>;</li>
      <li>Na seção  <strong>“Nova Instituição”</strong> clique no botão  <strong>“Incluir Instituição”</strong>, quando abrir o popup, clique em  <strong>“continuar”</strong> se você for o dirigente máximo da sua instituição;</li>
      <li>Na página  <strong>“Cadastro de Instituição”</strong>, preencha os campos com os dados da sua instituição e clique no botão  <strong>“Salvar”</strong>;</li>
      <li>Nesse ponto sua solicitação já foi enviada para o gestor MCTI, ele irá analisar a solicitação;</li>
      <li>Para acompanhar o status do cadastro da sua instituição, acesse a página https://pnipe.mctic.gov.br/back-office/infraestructure .</li>
      <li>Será enviado um e-mail informando o resultado da análise do cadastro da sua instituição também.</li>
    </ul>
    E-mails adicionais serão encaminhados no decorrer do processo
    `,

    PUBLIC_AREA_QUESTION_6: 'De que forma realizo alteração do responsável da instituição?',
    PUBLIC_AREA_ANSWER_6: `
    O usuário com o perfil de representante total da <strong>Instituição</strong> deverá seguir os seguintes passos abaixo:
    <ul>
      <li> Realizar o login no sistema;</li>
      <li> Acessar no menu lateral esquerdo e clicar na opção <strong>“Instituição”</strong>;</li>
      <li> Localizar na tela a área <strong>“Gerenciar Responsáveis”</strong>;</li>
      <li> Insira o e-mail do novo responsável da instituição e clique no botão  <strong>"Convidar"</strong>;</li>
      <li> Uma janela será aberta onde você deve inserir o nome e a permissão;</li>
    </ul>
    Selecione o nível de permissão, sendo eles:
    <ul>
      <li> <strong>Total</strong>: Poderá gerenciar toda a instituição, gerenciar responsáveis e aprovar novos laboratórios
        ou suas
        atualizações.</li>
      <li> <strong>Parcial</strong>: Poderá aprovar novos laboratórios ou suas alterações. Não poderá gerenciar responsáveis
        de instituição
      </li>
      <li> <strong>Limitado</strong>: Poderá apenas visualizar informações.</li>
    </ul>
    Depois de escolher a permissão, clique no botão <strong>"Adicionar convite"</strong> e logo após no botão <strong>“Salvar”</strong>.
    </br>
    Após realizado, o cadastro estará salvo e o e-mail para convite será encaminhado para usuário informado.
      `,


      PUBLIC_AREA_QUESTION_7: 'De que forma realizo o cadastro de laboratório?',
      PUBLIC_AREA_ANSWER_7: `
      É necessário que a instituição que o seu laboratório esteja vinculado aprove essa solicitação.
      <br><br>
      O resultado será enviado via e-mail para o solicitante e após, poderá prosseguir para publicação deste laboratório.
      <br><br>
      Oriento que acesse o e-mail para verificação da aprovação.
      <br><br>
      Depois da aprovação da instituição vinculada basta acessar a plataforma e no menu lateral esquerdo será apresentado a
      aba de <strong>"Laboratório"</strong>, ao clicar basta se direcionar ao campo <strong>"Novo Laboratório"</strong> e preencher os dados solicitados e ao
      final da página é necessário realizar a publicação deste laboratório para ficar visível ao público.
      <br><br>
      Esclareço que a responsabilidade pelo cadastro do laboratório e de seus equipamentos é do próprio responsável pelo
      laboratório, que pode ser feito acessando a Plataforma no endereço https://pnipe.mctic.gov.br/, por meio da opção
      <strong>“Acesso”</strong>.
      <br><br>
      Caberá ao representante institucional, indicado pelo dirigente máximo de sua instituição, homologar os dados do
      responsável e do laboratório.
        `,

    PUBLIC_AREA_QUESTION_8:'Que informações devem ser apresentadas no campo “Sobre o Laboratório”?',
    PUBLIC_AREA_ANSWER_8:'Nesse campo deve ser feita uma breve descrição sobre o laboratório e suas características. ',
         
    PUBLIC_AREA_QUESTION_9: 'O que são laboratórios multiusuários?',
    PUBLIC_AREA_ANSWER_9: `Laboratórios multiusuários são ambientes dotados de equipe e instalações de apoio às atividades de P&D que congregam equipamentos especializados para seu uso compartilhado, oferecendo serviços aos usuários internos e externos, executados por técnicos especialistas ou pelo próprio usuário após treinamento. São geridos por um comitê gestor e devem ter regras claras de acesso e utilização.`,

    PUBLIC_AREA_QUESTION_10: 'Quem é o coordenador do laboratório de pesquisa?',
    PUBLIC_AREA_ANSWER_10: `
      O coordenador é o responsável, junto à instituição, pelo gerenciamento do laboratório e pela inclusão e atualização dos dados na PNIPE.
      <br/><br/>
      <strong>Suas atribuições envolvem:</strong>
      <br/>
      <ul>
        <li>Gerenciamento da utilização do espaço físico e dos equipamentos e instrumentos disponíveis;</li>
        <li>Supervisão da equipe de pesquisadores e da equipe de apoio técnico;</li>
        <li>Interlocução e contato com outras instituições.</li>
      </ul>
      `,

    PUBLIC_AREA_QUESTION_11: 'Como faço para adicionar responsável/membro de laboratório?',
    PUBLIC_AREA_ANSWER_11: `O <strong>responsável do laboratório</strong> deve seguir os passos abaixo para incluir um novo membro:
    <ul>
      <li>Realize o login na plataforma e acesso o menu lateral esquerdo <strong>“Responsáveis de Laboratório”</strong>
        (https://pnipe.mctic.gov.br/back-office/list-representants-laboratory);</li>
      <li>Na página Gerenciar Responsáveis de Laboratório, escolha o laboratório que você deseja incluir o novo responsável;
      </li>
      <li>Depois de escolhido o laboratório, no campo <strong>“Incluir Responsável”</strong>, informe o e-mail no campo <strong>“Adicionar e-mail”</strong>, e
        seguida clique no botão <strong>“Convidar”</strong>; </li>
      <li>Um popup será aberto onde você colocará o <strong>Nome do responsável de laboratório</strong> que será convidado a sua <strong>permissão</strong>
        (Total, Parcial ou Limitado), e depois de preencher esses campos clique no botão <strong>“Adicionar Convite”</strong>;</li>
      <li>Depois de feito todos os passos acima, clique no botão <strong>“Salvar”</strong>;</li>
    </ul>`,
   

    PUBLIC_AREA_QUESTION_12: 'O que são equipamentos multiusuários?',
    PUBLIC_AREA_ANSWER_12:
      'São equipamentos de uso compartilhado para atividades de P&D, acessíveis a usuários internos e externos.',


      PUBLIC_AREA_QUESTION_13: 'De que maneira cadastro equipamentos?',
      PUBLIC_AREA_ANSWER_13: `
      Após o cadastro do laboratório ser aprovado, o sistema irá disponibilizar a opção para o cadastro dos equipamentos,
      conforme descrito abaixo:
      <ul>
        <li> No menu lateral, localize a opção <strong>“Equipamentos”</strong> e clique sobre ela.</li>
        <li> Na tela que será apresentada, localize o botão <strong>“Novo equipamento”</strong> e clique sobre ele.</li>
        <li> Será então apresentada a tela para <strong>“Cadastrar equipamento”</strong>, informe os dados e clique em <strong>“Salvar”</strong>.</li>
      </ul>`,
      
      PUBLIC_AREA_QUESTION_14: 'Como criar conta de usuário?',
      PUBLIC_AREA_ANSWER_14: `
      Para criar conta de usuário na plataforma, realize os seguintes passos:
      <ul>
        <li> Realize o login no sistema, utilizando o endereço: https://pnipe.mctic.gov.br/.</li>
        <li> Na página apresentada, localize a opção <strong>“Criar conta”</strong> e clique sobre ela.</li>
        <li> Em seguida, clique no botão <strong>“Acesso”</strong> na tela inicial conforme imagem abaixo:</li>
        <li> Na tela <strong>“Cadastrar novo usuário”</strong>, informe seus dados.</li>
      </ul>
      <strong>Atenção:</strong> <br /><br />
      Utilize <strong>e-mail institucional</strong>, caso deseje cadastrar uma instituição, pois é um requisito obrigatório no momento da
      validação das informações.<br /><br />
      Ao clicar em cadastrar, será enviado um e-mail para o endereço informado no cadastro para que seja validada sua conta.<br /><br />
      Após, siga os passos descritos no e-mail recebido para que possa realizar o primeiro login.`,      
      
            
      PUBLIC_AREA_QUESTION_15: 'Como recuperar minha senha?',
      PUBLIC_AREA_ANSWER_15: `
      Caso seja necessário realizar a recuperação de sua senha, siga os seguintes passos:
      <ul>
        <li> Realize o login no sistema no endereço: https://pnipe.mctic.gov.br/.</li>
        <li> Após, clique no botão <strong>“Acesso”</strong> na tela inicial.</li>
        <li> Na tela que será apresentada, localize a opção <strong>“Criar conta”</strong> e clique sobre ela.</li>
        <li> Na tela <strong>“Redefinir Senha”</strong>, informe CPF ou o e-mail cadastrado na plataforma para dar início ao processo de
          redefinição de senha.</li>
        <li> Selecione também a opção <strong>“não sou um robô”</strong>.</li>
        <li> Clique em <strong>“Mandar”</strong> para dar sequência e aguarde o e-mail que será enviado para o seu endereço de e-mail
          cadastrado na plataforma.</li>
      </ul>
      Siga os passos detalhados no e-mail para finalizar a redefinição de sua senha.`,

    PUBLIC_AREA_CONTACT_US_NAME: 'Nome completo',
    PUBLIC_AREA_CONTACT_US_ADD_NAME: 'Insira seu nome',

    PUBLIC_AREA_CONTACT_US_EMAIL: 'E-mail',
    PUBLIC_AREA_CONTACT_US_ADD_EMAIL: 'Insira seu e-mail',

    PUBLIC_AREA_CONTACT_US_SUBJECT: 'Assunto',
    PUBLIC_AREA_CONTACT_US_ADD_SUBJECT: 'Insira o assunto',

    PUBLIC_AREA_CONTACT_US_MESSAGE: 'Mensagem',
    PUBLIC_AREA_CONTACT_US_ADD_MESSAGE: 'Escreva sua mensagem',

    PUBLIC_AREA_CONTACT_US_SEND: 'Enviar',

    PUBLIC_AREA_CONTACT_US_SUCCESS:
      'Mensagem enviada com sucesso! Entraremos em contato via e-mail.',

    PUBLIC_AREA_CONTACT_US_ERROR:
      'Não foi possível realizar a operação. Tente novamente mais tarde.',

    EXTERNAL_MENU_PAINEL: 'Meu painel',
    EXTERNAL_MENU_LOGOUT: 'Sair',
    REGISTER_LABORATORY_SOCIAL_REASON: 'Razão social',
    REGISTER_LABORATORY_SOCIAL_REASON_NAME_INSTITUTION:
      'Insira o nome da instituição',

    REQUEST_OPINION_ATTACH_LAB_REFUSED:
      'Clique para adicionar um documento ou arraste para este campo com o motivo de recusa',

    USER_TERMS_DIALOG_OK: 'OK',
    USE_TERMS: `Termo de Adesão e Compromisso da Plataforma Nacional de Infraestrutura de Pesquisa do MCTI
    <br><br>
    1. Aceitação das Condições de Uso
    <br><br>
    1.1.O presente Termo de Adesão e Compromisso tem por finalidade normatizar o uso do serviço oferecido pela Plataforma Nacional de Infraestrutura de Pesquisa do MCTI (PNIPE). Ao usar os serviços
    da PNIPE, o usuário está ciente de que estará sujeito aos manuais e regras aplicáveis a eles. Ao enviar dados para a PNIPE, o usuário assume que leu e concordou com a versão mais recente do Termo e se vincula, automática e irrevogavelmente, às regras nele contidas.
    <br>
    1.2. O serviço oferecido não envolverá quaisquer ônus para o usuário, exceto os da conexão remota para acesso à PNIPE, que caberá àquele ter. Da mesma forma, não haverá qualquer vantagem ou retribuição
    ao usuário pelas informações e dados que cadastrar na PNIPE e pela disposição que deles vier a ser feita pelo MCTI a qualquer tempo.
    <br><br>
    2. Descrição do serviço
    <br><br>
    O MCTI, por meio da PNIPE, tem o objetivo de mapear e reunir, de maneira sistemática, informações sobre a infraestrutura de pesquisa nas Instituições Científicas, Tecnológicas e de Inovação (ICTs) no País, possibilitando o acesso da comunidade científica/tecnológica e de empresas às instalações
    laboratoriais e aos equipamentos de pesquisa existentes e promovendo seu uso compartilhado.
    <br><br>
    3. Senha e segurança                           
    <br><br>
    3.1. Todo usuário que utilizar o serviço é responsável pela guarda segura e pela confidencialidade da sua senha, além de ser inteiramente responsável por toda e qualquer atividade, lançamento e registro de
    informações que ocorram sob o uso da mesma, inclusive para efeitos legais.
    <br>
    3.2. O usuário concorda em notificar imediatamente o MCTI sobre qualquer uso não autorizado da sua
    senha ou qualquer quebra de segurança de que tem conhecimento.
    <br>
    3.3. O MCTI não será responsável por qualquer perda que possa ocorrer como consequência do uso não-autorizado por terceiros de sua senha, com ou sem seu conhecimento.
    <br>
    3.4. Para proteger o sigilo de sua senha, recomenda-se ao usuário:
    <br>
    a) sair de sua conta ao final de cada sessão e assegurar que a mesma não seja acessada por terceiros não autorizados; e,                            
    <br>
    b) não informar sua senha, nem mesmo ao MCTI, por e-mail, telefone ou outros meios.
    <br><br>
    4. Compartilhamento das informações
    <br><br>
    4.1. Todas as informações enviadas ao MCTI poderão ser por este disponibilizadas para acesso interno
    ou exibidas na rede interna do ministério. Poderão também ser divulgadas para o público externo, através
    da Internet ou de outros meios, exceto as seguintes informações relativas aos dados de identificação do
    usuário, pelas quais o MCTI se compromete a sua não divulgação pública:
    <br><br>
    a) CPF
    <br><br>
    4.2. O MCTI poderá fornecer todas as informações relacionadas às infraestruturas recebidas dos usuários
    para outros órgãos governamentais federais, municipais e estaduais, resguardado o compromisso de não
    exibição pública das informações relativas aos dados de identificação relacionados acima.
    <br>
    4.3. O MCTI fica expressamente autorizado a publicar as informações relacionadas às infraestruturas
    contidas na PNIPE, sob qualquer forma e registro, bem como compartilhá-las ou integrá-las a outras
    bases de dados próprias ou de terceiros de interesse para a elaboração de indicadores e estudos sobre
    desenvolvimento e política científica e tecnológica, sem que daí decorra qualquer ônus ou obrigação para
    o MCTI, exceto de preservar a integridade, a fidelidade, a exatidão e a correção dos dados e informações
    pessoais cadastrais, conforme estejam estes originariamente lançados na PNIPE pelo usuário.
    <br><br>
    5. Conduta e Obrigações do Usuário
    <br><br>
    Como condição para utilizar o serviço, o usuário concorda em:
    <br><br>
    a) fornecer informações verdadeiras e exatas;
    <br>
    b) aceitar que o usuário é o único responsável por toda e qualquer informação por ele fornecida, estando sujeito às consequências, administrativas e legais, decorrentes de declarações falsas ou inexatas que vierem a causar prejuízos ao MCTI, à Administração Pública em geral ou a terceiros;
    <br>
    c) não utilizar o serviço para fins ilícitos ou proibidos;
    <br>
    d) não utilizar o serviço para transmitir/divulgar material ilícito, proibido ou difamatório, que viole a
    privacidade de terceiros, ou que seja abusivo, ameaçador, discriminatório, injurioso ou calunioso;
    <br>
    e) não utilizar o serviço para transmitir/divulgar material que incentive discriminação ou violência;
    <br>
    f) não transmitir e/ou divulgar qualquer material que viole direitos de terceiros, incluindo direitos de
    propriedade intelectual;
    <br>
    g) não obter ou tentar obter acesso não-autorizado a outros sistemas ou redes de computadores
    conectados ao serviço (ações de hacker);
    <br>
    h) não interferir ou interromper o serviço, as redes ou os servidores conectados ao serviço;
    <br>
    i) não criar falsa identidade ou utilizar-se de subterfúgios com a finalidade de enganar outras pessoas ou
    de obter benefícios;
    <br>
    j) solicitar autorização para incluir links para outros sites e/ou bases de dados; e
    <br>
    k) comunicar imediatamente qualquer discrepância constatada pelo usuário nos dados e informações
    cadastrados e divulgados pela PNIPE, concomitante à sua correção procedida por ele próprio.
    <br><br>
    6. Conduta e Obrigações do MCTI
    <br><br>
    6.1. O MCTI reserva-se o direito de:
    <br><br>
    a) compartilhar e/ou exibir os dados fornecidos pelos usuários do serviço, consoante descrito no item 4;
    <br>
    b) sem aviso prévio, realizar auditorias periódicas acerca das informações cadastradas pelo usuário;
    <br>
    c) cancelar o acesso do usuário ao serviço, bem como suprimir o registro das informações fornecidas pelo usuário sempre que verificar a má-utilização por este da Plataforma, ou a prática de abusos na sua
    utilização e no lançamento de informações cadastrais. Entende-se por abuso toda e qualquer atividade
    que ocasione prejuízo ou lesão de direitos a terceiros. A prática de ato delituoso por meio da PNIPE
    ocasionará a sua apuração por meio de sindicância e caso constatada a responsabilidade do usuário a
    adoção de medidas administrativas repressivas.
    <br><br>
    6.2. O MCTI não se responsabiliza pelas declarações falsas ou inexatas prestadas pelo usuário que vierem a causar prejuízos a terceiros, à Administração Pública em geral ou ao próprio serviço.
    <br><br>
    7. Modificações deste Termo de Adesão e Compromisso
    <br><br>
    7.1. O MCTI reserva-se o direito de alterar o conteúdo deste Termo, sendo responsabilidade do usuário consultá-lo regularmente.
    <br><br>
    7.2. O uso continuado do serviço implica na concordância do usuário com todas as regras, condições e avisos emanados do MCTI, enquanto ali constar o cadastramento das informações pessoais fornecidas por aquele.
    <br><br>
    8. Legislação Aplicável
    <br><br>
    Aplica-se ao presente Termo, e às responsabilidades nele contidas, toda a legislação federal que lhe for pertinente.`,
    REGISTER_EQUIPMENT_CODE: 'Código do equipamento na plataforma',

    // LABORATORY REPRESENTANT LIST
    LABORATORY_LIST_REPRESENTANT: 'Gerenciar Responsáveis de Laboratório',

    LABORATORY_LIST_REPRESENTANT_SELECT: 'Laboratório',
    LABORATORY_LIST_REPRESENTANT_DIALOG:
      'Usuário vinculado como responsável pelo laboratório. A alteração do responsável pode ser feita por meio da opção "Edição avançada" na página \'Edição de Laboratório.',

    //LABORATORY DETAIL EQUIPMENT LIST
    LABORATORY_DETAIL_EQUIPMENT_LIST_CODE: 'Código',
    LABORATORY_DETAIL_EQUIPMENT_LIST_NAME: 'Nome',
    LABORATORY_DETAIL_EQUIPMENT_LIST_BRAND: 'Marca',
    LABORATORY_DETAIL_EQUIPMENT_LIST_MODEL: 'Modelo',
    LABORATORY_DETAIL_EQUIPMENT_LIST_MANUFACTURER: 'Fabricante',

    LABORATORY_LIST_REPRESENTANT_DIALOG_TOTAL:
      'Mesmo perfil do representante máximo do laboratório frente ao sistema. Tem acesso a todas as funcionalidades referentes a Laboratório no sistema.',
    LABORATORY_LIST_REPRESENTANT_DIALOG_PARTIAL:
      'Responsável por apoiar na gestão dos equipamentos (cadastro e atualização), bem como na edição e publicação do laboratório.',
    LABORATORY_LIST_REPRESENTANT_DIALOG_LIMITED:
      'Pode apenas visualizar informações.',
    EDIT_LABORATORY_REQUIRED_EQUIPMENT_ERROR_MESSAGE:
      'Ao menos um equipamento deve ser adicionado para publicar seu laboratório.',

    EQUIPMENTS: 'Equipamentos',
    REGISTER_EQUIPMENT_EDIT_REQUEST: 'Editar equipamento',

    //LIST EQUIPMENT INSIDE LABORATORY EDIT
    LIST_EQUIPMENT_LABORATORY_CODE: 'Código',
    LIST_EQUIPMENT_LABORATORY_NAME: 'Nome',
    LIST_EQUIPMENT_LABORATORY_MANUFACTURER: 'Fabricante',
    LIST_EQUIPMENT_LABORATORY_MODEL: 'Modelo',
    LIST_EQUIPMENT_LABORATORY_LABORATORY: 'Laboratório',

    // EDIT PROFILE
    EDIT_PROFILE_TITLE: 'Atualizar dados cadastrais',
    EDIT_PROFILE_DATA_TITLE: 'Dados cadastrais',
    EDIT_PROFILE_NAME: 'Nome completo',
    EDIT_PROFILE_CPF: 'CPF',
    EDIT_PROFILE_NATIONALITY: 'Nacionalidade',
    EDIT_PROFILE_BIRTH_YEAR: 'Ano de nascimento',
    EDIT_PROFILE_CONTACT: 'Contato',
    EDIT_PROFILE_EMAIL: 'Email',
    EDIT_PROFILE_TELEPHONE: 'Telefone',
    EDIT_PROFILE_COMPLEMENTARY_DATA_TITLE: 'Dados complementares',
    EDIT_PROFILE_FORMATION: 'Formação',
    EDIT_PROFILE_CURRICULO: 'Link para o currículo Lattes',
    EDIT_PROFILE_ORCID: 'Identificador ORCID',

    // REGISTER USER

    ADVANCED_EDIT: 'Edição avançada',
    SAVE_DRAFT: 'Salvar como Rascunho'
  }
}

translate.PREVIEW_SHARING_MODAL.REGISTER = translate.REQUEST_SHARING.REGISTER

export default translate
