import React, { useContext } from 'react'

import { Grid } from '@material-ui/core'

import BaseSelect from 'ui/atoms/BaseSelect'
import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const DoesntNeedComplementation = ({ params, formMethods }) => {
  const { translate } = useContext(GlobalizationContext)

  const { control, errors } = formMethods

  return (
    <>
      <Grid item xs={12} md={6}>
        <BaseSelect
          required={true}
          label={translate('ANALYZE.REPROVE.REASON_TYPE')}
          placeholder={translate('ANALYZE.REPROVE.REASON_TYPE_PLACEHOLDER')}
          errors={errors}
          control={control}
          name="reasonType"
          options={params.refusalReasonList}
          descriptionKey="description"
          translateOptions
        ></BaseSelect>
      </Grid>

      <Grid item xs={12}>
        <BaseTextareaField
          name="reason"
          label={translate('ANALYZE.REPROVE.REASON')}
          placeholder={translate('ANALYZE.REPROVE.REASON_PLACEHOLDER')}
          control={control}
          errors={errors}
          maxLength={500}
          rows={5}
        />
      </Grid>
    </>
  )
}

export default DoesntNeedComplementation
