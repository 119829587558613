import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  NoResults
} from 'ui/atoms/Table'
import Pagination from 'ui/molecules/commons/Pagination'

import Row from './Row'

const BodyFilters = props => {
  const { classes } = props
  const { translate } = useContext(GlobalizationContext)

  const {
    equipmentList,
    count,
    rowsPerPage,
    rowsPerPageOptions,
    page,
    onChangePage,
    onChangeRowsPerPage,
    emptyList,
    onRowChange: handleRowAction,
    promiseFn
  } = props

  const showPagination = rowsPerPageOptions[0] < count

  const paginationComponent = showPagination ? (
    <Pagination
      rowsPerPageOptions={rowsPerPageOptions}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  ) : null

  const LaboratoryRowList = equipmentList.map(row => {
    return (
      <Row
        item={row}
        onRowAction={handleRowAction}
        key={`${row.id}_${row.situation}`}
      ></Row>
    )
  })

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={12}>
          {!emptyList && paginationComponent}
          <AsyncLoad promiseFn={promiseFn} minHeight={100}>
            {!emptyList ? (
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        {translate('LABORATORY_DETAIL_EQUIPMENT_LIST_CODE')}
                      </TableCell>
                      <TableCell>
                        {translate('LABORATORY_DETAIL_EQUIPMENT_LIST_NAME')}
                      </TableCell>
                      <TableCell>
                        {translate('LABORATORY_DETAIL_EQUIPMENT_LIST_BRAND')}
                      </TableCell>
                      <TableCell>
                        {translate('LABORATORY_DETAIL_EQUIPMENT_LIST_MODEL')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{LaboratoryRowList}</TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoResults>{translate('COMMONS:NO_RESULTS')}</NoResults>
            )}
          </AsyncLoad>
          {!emptyList && paginationComponent}
        </Grid>
      </Grid>
    </>
  )
}

export default BodyFilters
