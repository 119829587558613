import React, { useContext } from 'react'

import { DialogTitle } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

function DialogConfirm({
  open,
  onCancel,
  onConfirm,
  title,
  labelCancelButton,
  labelConfirmButton,
  hideConfirmButton,
  hideCancelButton,
  dialogText,
  children
}) {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <Dialog
        open={Boolean(open)}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        <DialogContent className={classes.dialogOverflowHidden}>
          <DialogContentText id="alert-dialog-description">
            {dialogText ? translate(dialogText) : children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!hideCancelButton && (
            <Button
              onClick={onCancel}
              color="primary"
              className={classes.baseButtonGrey}
              id="DIALOG_CANCEL"
            >
              {labelCancelButton ||
                translate('REGISTER_LABORATORY_CONFIRM_DIALOG_DISAGREE')}
            </Button>
          )}
          {!hideConfirmButton && (
            <Button
              id="DIALOG_CONFIRM"
              onClick={onConfirm}
              color="primary"
              className={classes.baseButton}
              style={{ minWidth: '110px' }}
            >
              {labelConfirmButton ||
                translate('REGISTER_LABORATORY_CONFIRM_DIALOG_AGREE')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DialogConfirm
