import axios from 'axios'
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef
} from 'react'
import { useDebounce } from 'use-debounce/lib'

import { makeStyles } from '@material-ui/core/styles'

import { LaboratoryService } from 'api'
import { history, generatePrivatePath } from 'helpers/history'

import BaseButton from 'ui/atoms/BasePrivate/BaseButton'
import FilterSelect from 'ui/atoms/FilterSelect'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { useProfiles } from 'ui/atoms/useProfiles'
import wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Loader from 'ui/molecules/Loader'

import { notificationUseCases } from 'views/Notification/providers'

import BodyFilter from './Fragments/BodyFilter'
import ConfirmDeleteLaboratoryModal from './Fragments/ConfirmDeleteModal'
import DialogActivation from './Fragments/DialogActivationLab'
import List from './Fragments/List'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

const rowsPerPageList = [10, 20, 50, 100]

function ListLaboratory() {
  const cancelToken = useRef(axios.CancelToken.source())
  const { translate } = useContext(GlobalizationContext)
  const [laboratoryList, setLaboratoryList] = useState([])
  const [profileList] = useProfiles()

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [count, setCount] = useState(0)
  const [pagination, setPagination] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  const [filterProfile, setFilterProfile] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [filterSituation, setFilterSituation] = useState('')

  const [debouncedFilter] = useDebounce(filterValue, 350)

  const emptyList = Boolean(pagination.empty)

  const [confirmDeleteSharing, setConfirmDeleteSharing] = useState(null)
  const [labToExclude, setLabToExclude] = useState(null)

  const [openDialogActivation, setOpenDialogActivation] = useState(false)
  const [lab, setLab] = useState(null)

  const classes = useStyles()

  const getLaboratoryList = useCallback(() => {
    cancelToken.current.cancel()

    const filter = {
      ...(filterProfile ? { permission: filterProfile } : {}),
      ...(filterSituation ? { situation: filterSituation } : {}),
      ...(debouncedFilter ? { search: debouncedFilter } : {})
    }

    LaboratoryService.getAll(
      {
        pageSize,
        pageNumber,
        filter
      },
      { cancelToken: cancelToken.current.token }
    )
      .then(resp => {
        const { content, ...pagination } = resp.data
        setLaboratoryList(content)
        setPagination(pagination)
        setCount(pagination.totalElements)
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request cancelled')
        }
        console.log(error)
      })
  }, [
    cancelToken,
    filterProfile,
    filterSituation,
    debouncedFilter,
    pageSize,
    pageNumber
  ])

  useEffect(() => {
    getLaboratoryList()
  }, [getLaboratoryList])

  const handleSelectChange = event => {
    setPageNumber(0)
    setLaboratoryList([])
    setFilterSituation(event.target.value)
  }

  const handleInputChange = event => {
    setPageNumber(0)
    setFilterValue(event.target.value)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  const deleteLaboratory = async (item, reason) => {
    try {
      await LaboratoryService.deleteLaboratory(item.id, reason)
      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      handleRowChange()
    } catch (error) {
      notificationUseCases.newError(error)
    }
  }

  const checkLabHasSharing = async lab => {
    try {
      await LaboratoryService.canDelete(lab.id)
      return false
    } catch (error) {
      return true
    }
  }

  const handleExcludeLaboratory = async item => {
    if (item.situation !== 'REJECTED') {
      const hasSharing = await checkLabHasSharing(item)

      if (hasSharing) {
        setConfirmDeleteSharing(item)
      } else {
        setLabToExclude(item)
      }
    } else {
      await deleteLaboratory(item)
    }
  }

  const handlePublish = async item => {
    try {
      const response = await LaboratoryService.postPublishLaboratoryFromList(
        item.id
      )
      item.situation = response.data.situation

      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      handleRowChange()
    } catch (error) {
      notificationUseCases.newError(error)
    }
  }

  const handleUnpublish = async item => {
    try {
      const response = await LaboratoryService.postUnpublishLaboratory(item.id)
      item.situation = response.data.situation

      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      handleRowChange()
    } catch (error) {
      notificationUseCases.newError(error)
    }
  }

  const handleOpenDialog = item => {
    if (item) {
      setLab({
        id: item.id,
        name: item.name
      })
    }

    setOpenDialogActivation(!openDialogActivation)
  }

  const handleRowAction = {
    EXCLUDE: handleExcludeLaboratory,
    PUBLISH: handlePublish,
    UNPUBLISH: handleUnpublish,
    ACTIVATE: handleOpenDialog
  }

  const handleRowChange = (action, item) => {
    if (!action) {
      getLaboratoryList()
    } else {
      handleRowAction[action] && handleRowAction[action](item)
    }
  }

  const handleAddNewLaboratory = () => {
    history.push(generatePrivatePath('/register-laboratory'))
  }

  return (
    <>
      <Loader />
      <DialogActivation
        close={handleOpenDialog}
        lab={lab}
        open={openDialogActivation}
        updateList={handleRowChange}
      />

      <DialogConfirm
        open={confirmDeleteSharing}
        title={translate('COMMONS:WARNING')}
        dialogText={'CONFIRM_DELETE_SHARINGS'}
        labelConfirmButton={translate('COMMONS:YES')}
        labelCancelButton={translate('COMMONS:NO')}
        onCancel={() => setConfirmDeleteSharing(null)}
        onConfirm={() => {
          setConfirmDeleteSharing(null)
          setLabToExclude(confirmDeleteSharing)
        }}
      />

      <ConfirmDeleteLaboratoryModal
        laboratory={labToExclude}
        onClose={() => setLabToExclude(null)}
        onAction={(item, reason) => {
          deleteLaboratory(item, reason)
          setLabToExclude(null)
        }}
      />

      <BaseContainerHeader
        label={translate('TITLE')}
        showCloseButton={false}
        filter={
          <FilterSelect
            label={translate('SELECT_PROFILE')}
            value={filterProfile}
            defaultValue={'Todos'}
            options={profileList}
            translateOptions={true}
            onChange={event => {
              setFilterProfile(event.target.value)
            }}
          />
        }
      />
      <BodyFilter
        onSelectChange={handleSelectChange}
        onInputChange={handleInputChange}
      />
      <BaseButton
        id="NEW_LAB_BUTTON"
        label={translate('NEW_LAB_BUTTON')}
        onClick={handleAddNewLaboratory}
      />
      <List
        classes={classes}
        rowsPerPageOptions={rowsPerPageList}
        count={count}
        rowsPerPage={pageSize}
        page={pageNumber}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        equipmentList={laboratoryList}
        emptyList={emptyList}
        onRowChange={handleRowChange}
      />
    </>
  )
}
export default wrapper(ListLaboratory, {
  namespace: 'LABORATORY_LIST'
})
