import React from 'react'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'

const CustomTypography = styled(Typography)`
  flex: 1 0 auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: ${props => props.$maxLines};
`

/**
 * @typedef {Object} MultilineTextProps
 * @property {Number} maxLines
 *
 * @typedef {import('@material-ui/core/Typography/Typography').TypographyProps} Typography
 *
 * @typedef {MultilineTextProps & Typography} MultilineText
 */

/**
 * @param {MultilineText} props
 */
const MultilineText = props => {
  const { maxLines = 0, children, ...rest } = props
  return (
    <CustomTypography $maxLines={maxLines} {...rest}>
      {children}
    </CustomTypography>
  )
}

export default MultilineText
