/**
 * inspired by https://github.com/bondz/react-epic-spinners/blob/master/src/components/AtomSpinner.js
 */

import PropTypes from 'prop-types'
import React from 'react'
import styled, { keyframes, css } from 'styled-components'

const rotate = (quantity, position) => {
  return (360 / quantity) * position
}

const SpinnerInner = styled.div``

const lineAnimation = props => keyframes`
  100% {
    transform: rotateZ(${props.rotation}deg) rotateX(66deg)
      rotateZ(360deg);
  }
`

const rotationKeyframes = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const rotationCss = props => css`
  animation: ${rotationKeyframes} ${props.rotationDuration}ms linear infinite;
`

const SpinnerLine = styled.div`
  animation: ${props => lineAnimation(props)}
    ${props => props.animationDuration}ms linear infinite;
  transform: rotateZ(${props => props.rotation}deg) rotateX(66deg) rotateZ(0deg);
`
const SpinnerCircle = styled.div``

const Atom = styled.div`
  * {
    box-sizing: border-box;
  }

  height: ${props => props.size}px;
  width: ${props => props.size}px;
  overflow: hidden;

  ${props => props.rotationDuration && rotationCss}

  ${SpinnerInner} {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
  }

  ${SpinnerCircle} {
    display: block;
    position: absolute;
    color: ${props => props.color};
    font-size: calc(${props => props.size}px * 0.28);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${SpinnerLine} {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation-duration: ${props => props.animationDuration}ms;
    border-left-width: calc(${props => props.size}px / 17);
    border-top-width: calc(${props => props.size}px / 17);
    border-left-color: ${props => props.color};
    border-left-style: solid;
    border-top-style: solid;
    border-top-color: transparent;
  }
`

const propTypes = {
  size: PropTypes.number,
  animationDuration: PropTypes.number,
  color: PropTypes.string,
  quantity: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object
}

export const AtomSpinner = ({
  size = 60,
  animationDuration = 3000,
  color = '#fff',
  className = '',
  style,
  quantity = 3,
  rotationDuration = 8000,
  ...props
}) => (
  <Atom
    size={size}
    color={color}
    animationDuration={animationDuration}
    rotationDuration={rotationDuration}
    className={`atom-spinner${className ? ' ' + className : ''}`}
    style={style}
    {...props}
  >
    <SpinnerInner>
      {[...new Array(quantity)].map((item, index) => (
        <SpinnerLine
          key={index}
          animationDuration={animationDuration}
          rotation={rotate(quantity, index + 1)}
        />
      ))}

      <SpinnerCircle>&#9679;</SpinnerCircle>
    </SpinnerInner>
  </Atom>
)

AtomSpinner.propTypes = propTypes

export default AtomSpinner
