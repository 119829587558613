// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'

import { Grid } from '@material-ui/core'

import BaseSelectUncontrolled from 'ui/atoms/BaseSelectUncontrolled'
import BaseTextFieldPublic from 'ui/atoms/BaseTextFieldPublic.js'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const BodyFilters = ({ onInputChange, onSelectChange }) => {
  const [newFilterList, setNewFilterList] = useState([])
  const [filterOption, setFilterOption] = useState('')
  const { translate } = useContext(GlobalizationContext)

  useEffect(() => {
    // For now we don't have any point to return all situations
    const list = [
      {
        id: 'ACTIVE',
        name: translate('SITUATION_LIST.ACTIVE')
      },
      {
        id: 'PENDING',
        name: translate('SITUATION_LIST.PENDING')
      },
      {
        id: 'DELETED',
        name: translate('SITUATION_LIST.DELETED')
      },
      {
        id: 'REJECTED',
        name: translate('SITUATION_LIST.REJECTED')
      },
      {
        id: 'IN_ACTIVATION',
        name: translate('SITUATION_LIST.IN_ACTIVATION')
      },
      {
        id: 'INACTIVE',
        name: translate('SITUATION_LIST.INACTIVE')
      }
    ]
    setNewFilterList(list)
  }, [translate])

  const handleChange = event => {
    setFilterOption(event.target.value)
    onSelectChange(event)
  }

  return (
    <Grid
      container
      spacing={3}
      style={{ marginBottom: '20px' }}
      alignItems="baseline"
    >
      <Grid item xs={12} md={3}>
        <BaseSelectUncontrolled
          defaultValue={translate('COMMONS:FILTER')}
          label=""
          name="filterUserStatus"
          options={newFilterList}
          onChange={handleChange}
          value={filterOption}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <BaseTextFieldPublic
          placeholder={translate('SEARCH_DEFAULT')}
          handleChange={onInputChange}
        />
      </Grid>
    </Grid>
  )
}

export default BodyFilters
