import React, { useContext } from 'react'

import NotificationService from 'api/NotificationService'
import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'

import { GenericNotification } from './GenericNotification'
import { LinkStyledAccept } from './style'

const ComplementLaboratoryCmp = ({ notification, itemClicked, ...rest }) => {
  const { translate } = useContext(GlobalizationContext)

  const onAcceptClick = async () => {
    await NotificationService.confirmNotification(notification.id)
    history.push(
      generatePrivatePath(`/laboratory/${notification.laboratory.id}`)
    )
    itemClicked()
  }

  return (
    <GenericNotification
      titleKey="COMPLEMENT_LABORATORY_TITLE"
      messageKey="COMPLEMENT_LABORATORY"
      notification={{
        laboratoryName: `${notification.laboratory?.initials} - ${notification.laboratory?.name}`
      }}
      {...rest}
    >
      <LinkStyledAccept onClick={onAcceptClick}>
        {translate('COMPLEMENT')}
      </LinkStyledAccept>
    </GenericNotification>
  )
}

const ComplementLaboratory = wrapper(ComplementLaboratoryCmp, {
  namespace: 'NOTIFICATIONS'
})

export { ComplementLaboratory }
