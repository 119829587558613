import React from 'react'

import { FormHelperText, createMuiTheme } from '@material-ui/core'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'

import { fontFamily } from 'helpers/constants'

import DefaultHint from 'ui/molecules/commons/DefaultHint'

const useStyles = makeStyles(theme => ({
  baseInput: {
    background: '#f2f2f2!important',
    borderRadius: '2px!important',
    fontSize: '14px',
    '&:hover': {
      background: '#fafafa'
    }
  },
  baseTextField: {
    paddingTop: '10px!important'
  },
  baseTextFieldRequired: {
    '&:before': {
      content: `'*'`,
      position: 'absolute',
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      left: '-11px',
      top: '9px',
      fontSize: '25px'
    }
  },
  baseButton: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#7fcdbf',
    color: '#ffffff',
    minwidth: '80px!important',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#7fcdbf'
    }
  },
  spanError: {
    color: '#eb5757!important'
  },
  baseButtonAlternative: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#ffffff',
    color: '#000000',
    minwidth: '80px!important',
    border: '1px solid #7fcdbf',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#7fcdbf'
    }
  },
  baseText: {
    fontSize: '14px',
    fontWeight: 400
  },
  h3: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: 'normal'
  },
  label: {
    color: '#333',
    marginBottom: '0.4rem',
    fontSize: '14px',
    fontWeight: 600,
    display: 'inline-block'
  }
}))

const theme = createMuiTheme({
  fontFamily: fontFamily,
  fontFeatureSettings: 'lnum',
  typography: {
    fontFamily: fontFamily,
    fontFeatureSettings: 'lnum'
  },

  overrides: {
    MuiFilledInput: {
      input: {
        paddingTop: '10px!important',
        paddingBottom: '10px!important'
      },
      root: {
        borderTopLeftRadius: '0px!important',
        borderTopRightRadius: '0px!important',
        '&$error': {
          border: '1px solid #eb5757'
        }
      }
    },
    MuiButton: {
      root: {
        width: 'auto!important'
      }
    }
  }
})

export function BaseTextField({
  id,
  type = 'text',
  rows,
  required,
  disabled,
  label,
  placeholder,
  errors,
  register,
  name,
  mask,
  handleBlur,
  handleChange,
  val,
  autoComplete,
  readOnly,
  maxLength,
  hint
}) {
  const classes = useStyles()

  const hasErrors = errors && errors[name] ? true : false
  const inputProps = {
    className: hasErrors ? 'hasError' : null,
    autoComplete,
    readOnly,
    maxLength: maxLength,
    hint
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        {label !== '' && <label className={classes.label}>{label}</label>}
        {hint && (
          <div style={{ display: 'inline-block', marginTop: '3px' }}>
            <DefaultHint content={inputProps.hint} />
          </div>
        )}
        <FormControl
          variant="filled"
          size="small"
          fullWidth
          className={required ? classes.baseTextFieldRequired : ''}
        >
          <FilledInput
            id={id}
            rows={rows ? rows : 0}
            multiline={rows ? true : false}
            className={classes.baseInput}
            disabled={disabled}
            inputProps={inputProps}
            disableUnderline
            error={hasErrors}
            placeholder={placeholder}
            inputRef={register}
            name={name}
            inputComponent={mask}
            onBlur={handleBlur}
            onChange={handleChange}
            value={val}
            type={type}
          />
        </FormControl>

        {errors && errors[name] && (
          <div className={name + '-error-container'}>
            <FormHelperText error>{errors[name].message}</FormHelperText>
          </div>
        )}
      </ThemeProvider>
    </>
  )
}

export default BaseTextField
