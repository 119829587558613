import * as uuid from 'uuid'

import { createSlice } from '@reduxjs/toolkit'

export const NOTIFICATION_REDUCER = 'notification'

const notification = createSlice({
  name: NOTIFICATION_REDUCER,
  initialState: {
    notifications: []
  },
  reducers: {
    addNotification: (state, { payload }) => {
      const key = payload.options && payload.options.key

      state.notifications.unshift({
        key: key || uuid.v4(),
        ...payload
      })
    },
    closeNotification: (state, { payload }) => {
      return {
        notifications: [
          ...state.notifications.map(notification =>
            payload.dismissAll || notification.key === payload.key
              ? { ...notification, dismissed: true }
              : { ...notification }
          )
        ]
      }
    },
    removeNotification: (state, { payload }) => {
      return {
        notifications: state.notifications.filter(
          notification => notification.key !== payload
        )
      }
    }
  }
})

export const {
  addNotification,
  closeNotification,
  removeNotification
} = notification.actions

export default notification.reducer
