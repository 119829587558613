import axios from './axios'

export const InstitutionRegister = {
  postInstitution(objInstitution) {
    return axios
      .post(
        'institutions/' + objInstitution.institution.cnpj + '/form',
        objInstitution
      )
      .then(resp => resp.data)
  },
  postAdminInstitution(objInstitution) {
    return axios
      .post(
        'admin/institutions/' + objInstitution.institution.cnpj + '/form',
        objInstitution
      )
      .then(resp => resp.data)
  },
  resendInvite(institutionCnpj, email) {
    return axios
      .post(
        `admin/institutions/${institutionCnpj}/resend-invite`,
        {},
        { params: { email } }
      )
      .then(resp => resp.data)
  },
  verifyCnpj(cnpj) {
    return axios.get(`/institutions/${cnpj}`).then(resp => resp.data)
  },
  checkCnpj(cnpj) {
    return axios
      .get(`/institutions/check-cnpj`, { params: { cnpj } })
      .then(resp => resp.data)
  },
  registerDocument(cnpj, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(`/institutions/${cnpj}/document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  registerInstitution(objInstitution) {
    return axios.post('/institutions', objInstitution).then(resp => resp.data)
  }
}
export default InstitutionRegister
