import React, { useContext, useState } from 'react'
import { useWatch } from 'react-hook-form'

import { FormHelperText, Grid, Link, Typography } from '@material-ui/core'

// import { fileUploadConfig } from 'helpers/constants'

// import AddedDocuments, { previewFile } from 'ui/atoms/AddedDocuments'
import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import BaseTextField from 'ui/atoms/BaseTextField'
import ControlLabel from 'ui/atoms/ControlLabel'
// import DocumentUploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'
import { Title } from 'ui/atoms/Title'
import DefaultHint from 'ui/molecules/commons/DefaultHint'

import PreviewSharingModal from '../PreviewSharingModal'
import {
  SharingConfigContainer,
  MsgErrorCheckBox,
  ErrorCheckBox
} from './style'

const SharingConfig = ({
  formMethods,
  errorMsgCheckTermDecla,
  errorMsgTextAreaTerm
}) => {
  const [showPreview, setShowPreview] = useState(false)
  const { translate } = useContext(GlobalizationContext)

  const { control, errors } = formMethods

  const hasSharing = useWatch({
    control,
    name: 'hasSharing',
    defaultValue: false
  })

  const sharingType = useWatch({
    control,
    name: 'sharingType',
    defaultValue: 'PLATFORM_SHARING'
  })

  const hasSharingUseStatement = useWatch({
    control,
    name: 'hasSharingUseStatement',
    defaultValue: false
  })

  const textAreaUseStatement = useWatch({
    control,
    name: 'textAreaUseStatement',
    defaultValue: ''
  })

  const border = errorMsgTextAreaTerm ? 'red' : ''

  return (
    <>
      <PreviewSharingModal
        open={showPreview}
        onClose={() => {
          setShowPreview(false)
        }}
      />

      <Title item xs={12} variant="h3" $noPaddingBottom={true} $noBorder={true}>
        {translate('EDIT_LABORATORY_SHARING_CONFIG_TITLE')}
      </Title>
      <Grid item xs={12}>
        <ControlLabel control={control} name="hasSharing" type="Switch">
          {translate('EDIT_LABORATORY_SHARING_CONFIG_HAS_SHARING')}
        </ControlLabel>
      </Grid>
      {hasSharing ? (
        <Grid item xs={12}>
          <SharingConfigContainer>
            <Grid container spacing={3}>
              <Title
                item
                xs={12}
                variant="subtitle2"
                $noBorder={true}
                $noPaddingBottom={true}
              >
                {translate('EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE')}
              </Title>
              <Grid container item xs={12}>
                <RadioGroup
                  name="sharingType"
                  control={control}
                  options={[{ id: 'PLATFORM_SHARING' }, { id: 'OWN_SHARING' }]}
                  labelRenderer={option => {
                    return (
                      <Typography component="span" variant="body2">
                        {translate(
                          `EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_${option.id}`
                        )}
                        {option.id === 'PLATFORM_SHARING' ? (
                          <Link
                            style={{ marginLeft: '5px', fontSize: '12px' }}
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                              setShowPreview(true)
                            }}
                          >
                            {translate(
                              'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_SHARING_LINK'
                            )}
                          </Link>
                        ) : null}
                      </Typography>
                    )
                  }}
                />
              </Grid>

              {sharingType === 'PLATFORM_SHARING' ? (
                <>
                  <Title
                    item
                    xs={12}
                    variant="h3"
                    $noPaddingBottom={true}
                    $noBorder={true}
                  >
                    {translate(
                      'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_TITLE'
                    )}
                  </Title>
                  <Title
                    item
                    xs={12}
                    variant="subtitle2"
                    $noBorder={true}
                    $noPaddingBottom={true}
                  >
                    {translate(
                      'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_SUBTITLE'
                    )}
                  </Title>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {translate(
                        'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_DESC'
                      )}
                    </Typography>
                  </Grid>
                  <Title
                    item
                    xs={12}
                    variant="subtitle2"
                    $noBorder={true}
                    $noPaddingBottom={true}
                  >
                    {translate(
                      'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_TERMS'
                    )}
                  </Title>

                  <Grid item xs={12} md={12}>
                    <BaseTextareaField
                      name="textAreaUseStatement"
                      errors={errors}
                      control={control}
                      required={true}
                      maxLength={500}
                      rowsMin={4}
                      style={{ backgroundColor: 'white', borderColor: border }}
                    />

                    {errorMsgTextAreaTerm ? (
                      <ErrorCheckBox>
                        <MsgErrorCheckBox>* Campo obrigatório</MsgErrorCheckBox>
                      </ErrorCheckBox>
                    ) : (
                      ''
                    )}
                  </Grid>
                 

                  <Grid container item xs={12}>
                    <ControlLabel
                      control={control}
                      name="hasSharingCosts"
                      type="Checkbox"
                      required={true}
                    >
                      {translate(
                        'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_TERMS_HAS_COSTS'
                      )}
                      <DefaultHint content="EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_TERMS_HAS_COSTS_HINT" />
                    
                    </ControlLabel>
                  </Grid>

                  <Grid container item xs={12}>
                    <ControlLabel
                      control={control}
                      name="hasSharingUseStatement"
                      type="Checkbox"
                      required={true}
                      errors={errors}
                    >
                      {translate(
                        'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_PLATFORM_USE_STATEMENT'
                      )}

                      {errorMsgCheckTermDecla ? (
                      <ErrorCheckBox>
                        <MsgErrorCheckBox>* Campo obrigatório</MsgErrorCheckBox>
                      </ErrorCheckBox>
                    ) : (
                      ''
                    )}
                    </ControlLabel>
                  </Grid>
                </>
              ) : sharingType === 'OWN_SHARING' ? (
                <>
                  <Title
                    item
                    xs={12}
                    variant="h3"
                    $noPaddingBottom={true}
                    $noBorder={true}
                  >
                    {translate(
                      'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_OWN_SHARING_TITLE'
                    )}
                  </Title>
                  <Grid item xs={12}>
                    <BaseTextField
                      label={translate(
                        'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_OWN_SHARING_LABEL'
                      )}
                      placeholder={translate(
                        'EDIT_LABORATORY_SHARING_CONFIG_SHARING_TYPE_OWN_SHARING_PLACEHOLDER'
                      )}
                      name="ownShareLink"
                      errors={errors}
                      control={control}
                      required={true}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </SharingConfigContainer>
        </Grid>
      ) : null}
    </>
  )
}

export default SharingConfig
