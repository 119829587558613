import React, { useContext } from 'react'
import styled from 'styled-components'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

import { sm } from 'helpers/constants'
import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const Submenu = styled(Paper)`
  border-radius: 2px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
  min-width: 200px;
  z-index: 2;
`
const WrapSubmenu = styled(Popper)`
  z-index: 4;
  @media (max-width: ${sm}) {
    left: 40px !important;
    right: 0 !important;
    max-width: 300px;
  }
`
const DefaultContainer = styled.div`
  width: 200px;
  @media (max-width: ${sm}) {
    max-width: 100%;
    box-sizing: border-box;
  }
`

const CustomListItem = styled(ListItem)`
  padding: 0 15px;
  font-size: 14px;
`

function SubMenuButtonLogin(props) {
  const { translate } = useContext(GlobalizationContext)

  const { anchorEl } = props

  const handleClose = event => {
    props.handleProfileClose(event)
  }

  const signOut = () => {
    props.handleSignOut()
  }

  const handlePainel = () => {
    history.push(generatePrivatePath(''))
  }

  return (
    <WrapSubmenu
      open={props.profileOpen}
      anchorEl={anchorEl}
      role={undefined}
      transition
      placement="top-end"
      className="submenu"
      disablePortal={true}
      modifiers={{
        flip: {
          enabled: true
        },
        preventOverflow: {
          enabled: false,
          boundariesElement: 'scrollParent'
        },
        arrow: {
          enabled: false
        }
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'left top' : 'right top'
          }}
        >
          <Submenu>
            <ClickAwayListener onClickAway={handleClose}>
              <DefaultContainer>
                <Grid container>
                  <Grid item xs={12}>
                    <List
                      component="nav"
                      aria-label="secondary mailbox folders"
                    >
                      <CustomListItem button onClick={handlePainel}>
                        <p>{translate('EXTERNAL_MENU_PAINEL')}</p>
                      </CustomListItem>
                      <CustomListItem button onClick={signOut}>
                        <p>{translate('EXTERNAL_MENU_LOGOUT')}</p>
                      </CustomListItem>
                    </List>
                  </Grid>
                </Grid>
              </DefaultContainer>
            </ClickAwayListener>
          </Submenu>
        </Grow>
      )}
    </WrapSubmenu>
  )
}

export default SubMenuButtonLogin
