import styled, { css } from 'styled-components'

import MuiChip from '@material-ui/core/Chip'

import { COLORS, primaryColor } from 'helpers/constants'

const baseChip = css`
  font-weight: 600;

  &:not(:first-child) {
    margin-left: 5px;
  }
`

export const getFilteredOption = (list, key, translateFn = name => name) => {
  const [first, ...rest] = list
  return `${translateFn(first[key])}${rest.length ? ' +' + rest.length : ''}`
}

export const Chip = styled(MuiChip)`
  ${baseChip}
  background-color: #b2dffb;

  &.MuiChip-deletable:focus {
    background-color: #b2dffb;
  }
`

export const TableChip = styled(MuiChip)`
  ${baseChip}
  ${props =>
    !props.$primary
      ? css`
          background-color: #f59a23;
          color: #333333;
        `
      : css`
          background-color: ${primaryColor};
          color: #fff;
        `}
`

export const TableChipSecondary = styled(MuiChip)`
  ${baseChip}
  background-color: transparent;
  color: #333333;
  border: 1px solid ${primaryColor};
`

export const TableChipError = styled(TableChip)`
  ${baseChip}
  background-color: ${COLORS.ERROR};
  color: white;
`

export const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
