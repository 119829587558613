// @ts-nocheck
import React, { useContext, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AddedDocuments, { previewFile } from 'ui/atoms/AddedDocuments'
import BaseSelect from 'ui/atoms/BaseSelect'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import { Label, Text, TextContainer } from '../../styles'

const Responsible = ({
  documents,
  formMethods,
  onChangeResponsible,
  user,
  userList
}) => {
  const { translate } = useContext(GlobalizationContext)
  const { control, errors, setValue } = formMethods

  useEffect(() => {
    setValue('responsibleSelect', user?.id || '')
  })

  const selectNewResponsible = event => {
    const newResponsible = userList.find(user => user.id === event.target.value)
    onChangeResponsible(newResponsible)
  }

  return (
    <>
      <Typography variant="subtitle1" style={{ marginBottom: '24px' }}>
        {translate('REGISTER_RESPONSIBLE_DATA')}
      </Typography>

      <Typography variant="subtitle2" style={{ margin: '20px 0 8px 0' }}>
        {translate('REGISTER_RESPONSIBLE_NAME')}
      </Typography>
      <Grid item xs={12} md={6}>
        <BaseSelect
          placeholder={translate('REQUEST_OPINION_SELECT')}
          errors={errors}
          control={control}
          name="responsibleSelect"
          options={userList}
          handleChange={selectNewResponsible}
        />
      </Grid>

      <TextContainer>
        <Label>{translate('REGISTER_INSTITUTION_REPRESENTATIVES_EMAIL')}</Label>
        <Text>{user?.email}</Text>

        <Label>{translate('REGISTER_INSTITUTION_REPRESENTATIVES_CPF')}</Label>
        <Text>{user?.cpf}</Text>
      </TextContainer>

      <Grid container style={{ marginTop: '40px' }}>
        <AddedDocuments docs={documents} viewFile={previewFile} />
      </Grid>
    </>
  )
}

export default Responsible
