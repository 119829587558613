import React, { useContext, useState } from 'react'
import { Trans } from 'react-i18next'

import NotificationService from 'api/NotificationService'
import { AuthContext } from 'context/AuthContext'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'

import { GenericNotification } from './GenericNotification'
import { LinkStyledAccept, LinkStyledReject } from './style'

const InviteInstitutionCmp = ({ notification, itemClicked, ...rest }) => {
  const { translate } = useContext(GlobalizationContext)

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [dialogText, setDialogText] = useState('')
  const [dialogParams, setDialogParams] = useState({})
  const [action, setAction] = useState('')
  const [onlyTotalError, setOnlyTotalError] = useState(false)

  const { refreshCurrentUserData } = useContext(AuthContext)

  const acceptNotification = async () => {
    try {
      const {
        alreadyInInstitution,
        onlyTotal,
        socialReason
      } = await NotificationService.verifyAccept(notification.id)

      if (onlyTotal) {
        setOnlyTotalError(true)
      } else {
        setAction('acceptNotification')
        setDialogText(
          alreadyInInstitution
            ? 'REGISTER_INSTITUTION_ACCEPT_INVITE_ALREADY_IN_INSTITUTION'
            : 'REGISTER_INSTITUTION_ACCEPT_INVITE'
        )
        setDialogParams(
          alreadyInInstitution ? { institution: socialReason } : {}
        )
        setOpenConfirmDialog(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const rejectNotification = () => {
    setDialogText('REGISTER_INSTITUTION_REFUSE_INVITE')
    setAction('rejectNotification')
    setOpenConfirmDialog(true)
  }

  const handleConfirm = async () => {
    setOpenConfirmDialog(false)
    await NotificationService[action](notification.id)
    await refreshCurrentUserData()
    itemClicked()
  }

  return (
    <GenericNotification
      titleKey="INVITE_INSTITUTION_TITLE"
      messageKey="INVITE_INSTITUTION"
      notification={{
        institution: `${notification.cnpj.initials} - ${notification.cnpj.social_reason}`
      }}
      {...rest}
    >
      {notification.actions.indexOf('REJECT') >= 0 ? (
        <LinkStyledReject
          onClick={rejectNotification}
          key={`reject` + notification.id}
        >
          {translate('NOTIFICATION_REJECT')}
        </LinkStyledReject>
      ) : null}

      {notification.actions.indexOf('ACCEPT') >= 0 ? (
        <LinkStyledAccept
          onClick={acceptNotification}
          key={`accept` + notification.id}
        >
          {translate('NOTIFICATION_ACCEPT')}
        </LinkStyledAccept>
      ) : null}
      <DialogConfirm
        open={openConfirmDialog}
        onConfirm={handleConfirm}
        onCancel={() => setOpenConfirmDialog(false)}
      >
        <Trans t={translate} i18nKey={dialogText} values={dialogParams} />
      </DialogConfirm>
      <DialogConfirm
        hideCancelButton
        dialogText={'REGISTER_INSTITUTION_ACCEPT_INVITE_ERROR'}
        open={onlyTotalError}
        labelConfirmButton={translate('COMMONS:OK')}
        onConfirm={() => setOnlyTotalError(false)}
      />
    </GenericNotification>
  )
}

const InviteInstitution = wrapper(InviteInstitutionCmp, {
  namespace: 'NOTIFICATIONS'
})

export { InviteInstitution }
