import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import endOfWeek from 'date-fns/endOfWeek'
import startOfWeek from 'date-fns/startOfWeek'

import DateFnsUtils from '@date-io/date-fns'

class DateFnsUtilsCustom extends DateFnsUtils {
  getWeekdays() {
    const now = new Date()

    return eachDayOfInterval({
      start: startOfWeek(now, { locale: this.locale }),
      end: endOfWeek(now, { locale: this.locale })
    }).map(day => this.format(day, 'EEE'))
  }
}

export default DateFnsUtilsCustom
