import React, { Component } from "react";
import MaskedInput from "react-text-mask";

export class yearMask extends Component {
  render() {
    const { inputRef, ...other } = this.props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/]}
        placeholder=""
      />
    );
  }
}
