import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { Button, Grid } from '@material-ui/core'

import { AdminService } from 'api'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { useGlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import InviteModal from '../InviteModal/index'

const FormSchema = Yup.object().shape({
  email_invite: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .email('VALIDATION:EMAIL_ERROR')
    .trim()
})

const RepresentativeInput = ({
  disabled,
  laboratoryId,
  representants,
  setRepresentants,
  showWarning
}) => {
  const classes = useStyles()
  const { translate } = useGlobalizationContext()
  const resolver = useYupValidationResolver(FormSchema, { translate })
  const { setError, getValues, errors, control, formState, handleSubmit } =
    useForm({
      mode: 'onBlur',
      resolver
    })
  const [modalInviteOpen, setModalInviteOpen] = useState(false)

  const { dirtyFields } = formState

  const checkEmailAlreadyInvited = email => {
    return representants.findIndex(item => item.email === email) >= 0
  }

  const onClickHandle = async () => {
    const email = getValues('email_invite').trim()
    const isAlreadyInvited = checkEmailAlreadyInvited(email)

    if (isAlreadyInvited) {
      return setError('email_invite', {
        type: 'emailAlreadyExists',
        message: translate('MESSAGES.USER_ALREADY_INVITED')
      })
    }

    if (
      representants.filter(
        user => user.situation === 'ACTIVE' || user.situation === 'PENDING'
      ).length >= 10
    ) {
      return setError('email_invite', {
        type: 'emailAlreadyExists',
        message: translate('REGISTER_INSTITUTION_MAXIMUM_USERS_REACH')
      })
    }

    try {
      await AdminService.getEmailAlreadySent(laboratoryId, email)
      setModalInviteOpen(true)
    } catch (error) {
      const message = error.response?.data?.message

      if (message === 'invite.already.sent') {
        setError('email_invite', {
          type: 'emailAlreadyExists',
          message: translate('REGISTER_INSTITUTION_USER_ALREADY_INVITED')
        })
      } else if (message === 'invalid.user.situation') {
        setError('email_invite', {
          type: 'invalidUser',
          message: translate('ERRORS:INVALID_USER_SITUATION')
        })
      } else {
        notificationUseCases.newError()
      }
    }
  }

  const handleCloseInvite = () => {
    setModalInviteOpen(false)
  }

  const handleInvite = async invite => {
    setRepresentants(oldList => {
      return [...oldList, invite]
    })
    setModalInviteOpen(false)
    showWarning(true)
  }

  const getEmail = () => {
    return getValues('email_invite')
  }

  return (
    <>
      {modalInviteOpen && (
        <InviteModal
          email={getEmail()}
          onInvite={handleInvite}
          onClose={handleCloseInvite}
        />
      )}
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={12} md={10}>
          <BaseTextField
            label=""
            placeholder={translate('INVITE.ADD_EMAIL')}
            errors={errors}
            control={control}
            name="email_invite"
            style={{
              maxHeight: '40px',
              minHeight: '40px'
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            disabled={!dirtyFields?.email_invite || disabled}
            id="CONFIRM_INVITE"
            fullWidth
            className={classes.baseButton}
            onClick={handleSubmit(onClickHandle)}
            variant="contained"
          >
            {translate('INVITE.CONFIRM_INVITE')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default RepresentativeInput
