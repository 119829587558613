import React, { useRef } from 'react'
import { Controller } from 'react-hook-form'

import { ThemeProvider } from '@material-ui/core'

import theme from 'ui/theme'

import { KeyboardDatePicker, CustomTextField } from './style'

const BaseDatePicker = ({
  error = null,
  control,
  name,
  label,
  defaultValue = null,
  ...rest
}) => {
  const inputRef = useRef()

  const datepickerProps = {
    // variant: 'inline',
    format: 'dd/MM/yyyy',
    // maskChar: ' ',
    margin: 'normal',
    KeyboardButtonProps: {
      'aria-label': 'change date'
    }
  }

  return (
    <Controller
      onFocus={() => {
        return inputRef.current?.focus()
      }}
      render={({ value, ref, onBlur, ...props }) => {
        return (
          <KeyboardDatePicker
            InputLabelProps={{
              ...(value ? { shrink: true } : {})
            }}
            InputProps={{
              inputProps: {
                ref: newRef => {
                  ref.current = newRef
                  inputRef.current = newRef
                }
              }
            }}
            errors={{ [name]: error }}
            error={Boolean(error)}
            TextFieldComponent={CustomTextField}
            id={name}
            value={value}
            label={label}
            invalidDateMessage=""
            minDateMessage=""
            maxDateMessage=""
            onBlur={onBlur}
            onClose={onBlur}
            {...datepickerProps}
            {...rest}
            {...props}
          />
        )
      }}
      control={control}
      name={name}
      defaultValue={defaultValue}
    />
  )
}

export default BaseDatePicker
