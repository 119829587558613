import React, { useContext } from 'react'

import { Grid } from '@material-ui/core'

import BaseSelect from 'ui/atoms/BaseSelect'
import { GlobalizationContext } from 'ui/atoms/Globalization'

function RefusalReason({ formMethods, reasons }) {
  const { translate } = useContext(GlobalizationContext)
  const { errors, control } = formMethods

  return (
    <Grid item xs={12} md={4} style={{ margin: '16px 0' }}>
      <BaseSelect
        required={true}
        placeholder={translate('REQUEST_OPINION_SELECT')}
        label={translate('REQUEST_OPINION_REFUSAL_REASON')}
        errors={errors}
        control={control}
        name="requestOpinionRefusalReasons"
        descriptionKey="description"
        options={reasons}
      ></BaseSelect>
    </Grid>
  )
}

export default RefusalReason
