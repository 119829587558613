import { createSlice } from '@reduxjs/toolkit'

export const BREADCRUM_DATA_REDUCER = 'breadcrumbData'

const slice = createSlice({
  name: BREADCRUM_DATA_REDUCER,
  initialState: { content: {} },
  reducers: {
    setBreadcrumbData: (state, { payload }) => {
      state.content = { ...payload }
    }
  }
})

export const actions = slice.actions
export const { setBreadcrumbData } = slice.actions

export default slice.reducer
