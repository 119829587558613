// @ts-nocheck
import styled from 'styled-components'

export const TrashIcon = styled.img`
  cursor: pointer;
  height: 20px;
  &:hover {
    filter: invert(37%) sepia(86%) saturate(827%) hue-rotate(131deg)
      brightness(97%) contrast(101%);
  }
  &.disabled {
    cursor: default;
    filter: brightness(300%);
  }
`
