import groupByFn from 'lodash/groupBy'
import React, { useRef, useContext, useMemo } from 'react'
import { Controller } from 'react-hook-form'

import {
  Checkbox,
  FormHelperText,
  Input,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'

import { COLORS } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import FormControl from './FormControl'

const useStylesLocal = makeStyles({
  errorDefault: {
    fontWeight: 600,
    color: COLORS.ERROR
  },
  placeholder: {
    color: '#A3A3A3',
    padding: 0
  },
  baseTextFieldRequired: {
    '&:before': {
      content: `'*'`,
      position: 'absolute',
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      left: '-11px',
      top: '9px',
      fontSize: '25px'
    }
  }
})

export function BaseMultipleSelect(props) {
  const {
    label,
    errors,
    options,
    name,
    required,
    placeholder,
    translateOptions,
    selectedValueRenderer,
    optionRenderer,
    disabled,
    control,
    onFocus,
    groupBy,
    translateGroupBy = name => name,
    optionDisabled = () => false
  } = props
  const { translate } = useContext(GlobalizationContext)

  const classes = useStyles()
  const classesLocal = useStylesLocal()

  const [isOpen, setOpen] = React.useState(false)
  const translateFn = translateOptions
    ? typeof translateOptions === 'function'
      ? translateOptions
      : translate
    : name => name

  const inputRef = useRef()

  const validationError = !Array.isArray(errors[name]) && errors[name]
  const hasError = !!validationError

  const idKey = props.idKey || 'id'
  const nameKey = props.nameKey || 'name'

  const ITEM_HEIGHT = 54
  const ITEM_PADDING_TOP = 0
  const MenuProps = {
    variant: 'menu',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        padding: 0
      }
    },
    MenuListProps: {
      disablePadding: true,
      autoFocusItem: false
    }
  }

  const CustomMenuItem = useMemo(() => {
    // eslint-disable-next-line react/display-name
    return React.forwardRef(({ currValue, option, ...rest }, ref) => {
      const isDisabled = optionDisabled(option)
      return (
        <MenuItem
          {...rest}
          disabled={isDisabled}
          disableGutters={true}
          data-value={rest['data-value'][idKey]}
          style={{ paddingRight: '5px' }}
        >
          <Checkbox checked={currValue && currValue.indexOf(option) > -1} />
          <ListItemText
            primary={
              optionRenderer ? (
                optionRenderer(option)
              ) : (
                <Typography variant="body1" noWrap>
                  {translateFn(option[nameKey])}
                </Typography>
              )
            }
            disableTypography={true}
          />
        </MenuItem>
      )
    })
  }, [idKey, nameKey, optionDisabled, translateFn])

  const getGroupedList = ({ options, groupBy, currValue }) => {
    const groups = groupByFn(options, groupBy)

    return Object.keys(groups).map(group => {
      return [
        <ListSubheader key={`GROUP_${group}`} disableSticky={true}>
          {translateGroupBy(group)}
        </ListSubheader>,
        groups[group].map(option => (
          <CustomMenuItem
            key={option[idKey]}
            currValue={currValue}
            value={option}
            option={option}
          />
        ))
      ]
    })
  }

  const renderSelectedOption = selectedValueRenderer
    ? selectedValueRenderer
    : optionRenderer
    ? optionRenderer
    : option => translateFn(option[nameKey])

  return (
    <React.Fragment>
      <label className={classes.label}>{label}</label>
      <FormControl
        className={classes.formControl}
        $required={required}
        error={hasError}
        style={{
          width: '100%',
          backgroundColor: '#f2f2f2',
          height: '2.45rem'
        }}
      >
        <Controller
          name={name}
          control={control}
          defaultValue={[]}
          onFocus={() => {
            if (onFocus) return onFocus(inputRef)
            return inputRef.current?.focus()
          }}
          render={({ ref, value, onBlur, ...controlProps }) => {
            return (
              <Select
                {...controlProps}
                disabled={disabled}
                multiple
                disableUnderline
                label={placeholder}
                inputRef={newRef => {
                  ref.current = newRef
                  inputRef.current = newRef
                }}
                value={value}
                onClose={event => {
                  onBlur(event)
                  setOpen(false)
                }}
                onOpen={() => setOpen(true)}
                open={isOpen}
                displayEmpty={true}
                classes={{
                  root: !value.length ? classesLocal.placeholder : null
                }}
                input={
                  <Input
                    classes={{ root: classes.muiInputRoot }}
                    error={!!hasError}
                  />
                }
                renderValue={selected => {
                  return selected.length
                    ? selected.map(renderSelectedOption).join(', ')
                    : placeholder
                }}
                MenuProps={MenuProps}
              >
                {groupBy
                  ? getGroupedList({
                      options: options,
                      groupBy: groupBy,
                      currValue: value
                    })
                  : options.map(option => (
                      <CustomMenuItem
                        key={option[idKey]}
                        value={option}
                        option={option}
                        currValue={value}
                      />
                    ))}
              </Select>
            )
          }}
        />
      </FormControl>
      {validationError && (
        <span>
          <FormHelperText className={classes.errorDefault} error>
            {validationError.message}
          </FormHelperText>
        </span>
      )}
    </React.Fragment>
  )
}

export default BaseMultipleSelect
