import styled, { css } from 'styled-components'

import { FormControl } from '@material-ui/core'

export default styled(FormControl)`
  ${props =>
    props.$required &&
    css`
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        content: '\f069';
        position: absolute;
        vertical-align: middle;
        display: flex;
        align-items: center;
        left: -11px;
        font-size: 5px;
        height: 100%;
      }
    `}
`
