import React, { useContext, useState } from 'react'
import { Trans } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'

import {
  Box,
  Button,
  Grid,
  Link as MuiLink,
  Typography
} from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle } from 'ui/atoms/Title'
import theme from 'ui/theme'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'

import { requestSharingUseCases } from '../../../../../../providers'
import EditAnalisys from '../EditAnalisys'
import UsageData from '../UsageData'

const ConfirmUsage = ({
  sharingRequest,
  currentLaboratory,
  onCancel: handleCancel
}) => {
  const { translate } = useContext(GlobalizationContext)
  const [isEditing, setIsEditing] = useState(false)
  const { id } = useParams()

  const handleConfirmUsage = async () => {
    try {
      await requestSharingUseCases.confirmUsage(id)
      history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Subtitle item xs={12}>
          {translate('DETAILS.CONFIRM_USAGE.TITLE')}
        </Subtitle>
        <Grid item xs={12}>
          <Trans
            parent={Typography}
            variant="body2"
            t={translate}
            i18nKey={'DETAILS.CONFIRM_USAGE.DESCRIPTION'}
            components={{
              LinkCommunication: (
                <MuiLink
                  component={Link}
                  to={generatePrivatePath(
                    REQUEST_SHARING_ROUTES.DETAILS_COMMUNICATION,
                    { pathParams: { id } }
                  )}
                />
              )
            }}
          />
        </Grid>

        <Subtitle
          item
          xs={12}
          $paddingTop
          $noBorder={false}
          action={[
            <Box key="action2">
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.error.dark,
                  color: theme.palette.error.contrastText
                }}
                onClick={handleCancel}
              >
                {translate('DETAILS.CANCEL')}
              </Button>
            </Box>,
            !sharingRequest.isRequester && !isEditing ? (
              <Box key="action1" pl={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsEditing(true)}
                >
                  {translate('COMMONS:EDIT')}
                </Button>
              </Box>
            ) : null,
            sharingRequest.isRequester ? (
              <Box key="action3" pl={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmUsage}
                >
                  {translate('DETAILS.CONFIRM_USAGE.CONFIRM')}
                </Button>
              </Box>
            ) : null
          ]}
        >
          {translate('DETAILS.CONFIRM_USAGE.SHARING_DATA')}
        </Subtitle>

        <Grid item>
          {isEditing ? (
            <EditAnalisys
              currentLaboratory={currentLaboratory}
              sharingRequest={sharingRequest}
              onCancel={() => setIsEditing(false)}
            />
          ) : (
            <UsageData sharingRequest={sharingRequest} />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default ConfirmUsage
