import styled from 'styled-components'

import {
  Accordion as MaterialAccordion,
  AccordionSummary as MaterialAccordionSummary,
  AccordionDetails as MaterialAccordionDetails
} from '@material-ui/core'

export const AccordionDetails = styled(MaterialAccordionDetails)`
  padding: 20px 30px;
`

export const AccordionSummary = styled(MaterialAccordionSummary)`
  background-color: #f2f2f2;

  .MuiAccordionSummary-expandIcon {
    order: -1;
  }
  .MuiSvgIcon-root {
    font-size: 2.5rem;
    color: #000;
  }
  &.MuiAccordionSummary-root.Mui-expanded {
    min-height: 64px;
    background-color: #009b7f;
    color: #fafafa;
    .MuiSvgIcon-root {
      color: #fafafa;
    }
  }
  .MuiAccordionSummary-content.Mui-expanded {
    & > div {
      color: white;
    }
  }
`

export const Accordion = styled(MaterialAccordion)`
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
`
