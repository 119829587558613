import axios from './axios'

export const NotificationService = {
  getNotification() {
    return axios
      .get('/notifications/user/', {
        skipLoader: true
      })
      .then(resp => resp.data)
  },
  getNotificationsFiltered(filter) {
    return axios
      .get('notifications/user/filter', { skipLoader: true, params: filter })
      .then(resp => resp.data.notificationList)
  },
  acceptNotification(id) {
    return axios.post(`/notifications/${id}/accept`)
  },
  rejectNotification(id) {
    return axios.post(`/notifications/${id}/reject`)
  },
  readAllNotifications() {
    return axios.post('/notifications/user/readAll')
  },
  verifyAccept(id) {
    return axios
      .get(`/notifications/${id}/verifyAccept`)
      .then(resp => resp.data)
  },
  confirmNotification(id) {
    return axios.put(`/notifications/${id}/confirm`)
  }
}

export default NotificationService
