export default class EquipmentDataUseCases {
  /**
   * @param {object} props
   * @param {import('api/EquipmentService').EquipmentService} props.EquipmentService
   */
  constructor({ EquipmentService }) {
    this.equipmentService = EquipmentService
  }

  async getListStates() {
    return await this.equipmentService.getListStates()
  }

  async getListStateReasons(params) {
    return await this.equipmentService.getListStateReasons(params)
  }

  async replicateEquipments(equipmentId, quantity) {
    return await this.equipmentService.replicateEquipments(
      equipmentId,
      quantity
    )
  }
}
