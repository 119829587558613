import React, { useState, useEffect, useContext } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import { generatePrivatePath } from 'helpers'

import Container from 'ui/atoms/Container'
import Header from 'ui/organisms/Header/Private'

import Copyright from 'views/App/Fragments/PublicRoutes/Fragments/Footer/Fragments/Copyright'
import { BottomContainer } from 'views/App/Fragments/PublicRoutes/Fragments/Footer/style'

import Nav from './Fragments/Nav'
import routes from './routes'
import { MainWrapper, ContentWrapper, Row } from './styles'

function BackOffice({ match }) {
  const authData = useContext(AuthContext)
  const { isAuthenticated, isLoading, userData } = authData

  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()

  const handleMenuOpenChange = newState => {
    setMenuOpen(newState)
  }

  useEffect(() => {
    let bodyClass = document.body.classList
    bodyClass.add('private')
    return () => {
      bodyClass.remove('private')
    }
  }, [])

  return (
    <MainWrapper
      display="flex"
      flexDirection="column"
      style={{ maxWidth: '100%' }}
    >
      <Header
        open={menuOpen}
        onMenuOpen={() => handleMenuOpenChange(!menuOpen)}
      />
      <Row>
        <Nav menuOpen={menuOpen} onMenuOpenChange={handleMenuOpenChange} />
        <ContentWrapper id="mainContentContainer">
          <Switch>
            {isAuthenticated
              ? routes
                  .filter(
                    route =>
                      typeof route.rule === 'undefined' || route.rule(userData)
                  )
                  .map(route => (
                    <Route
                      key={route.path}
                      path={`${match.path}${route.path}`}
                      exact={
                        typeof route.exact !== 'undefined' ? route.exact : true
                      }
                      render={props => {
                        const routeParams = route.params || {}
                        return <route.component {...props} {...routeParams} />
                      }}
                    ></Route>
                  ))
              : null}

            {!isLoading ? (
              isAuthenticated ? (
                <Redirect to={generatePrivatePath('/forbidden')} />
              ) : (
                <Redirect to={`/login?redirectTo=${location.pathname}`} />
              )
            ) : null}
          </Switch>
        </ContentWrapper>
      </Row>

      <BottomContainer>
        <Container maxWidth="lg">
          <Copyright />
        </Container>
      </BottomContainer>

    </MainWrapper>
  )
}

export default BackOffice
