import styled from 'styled-components'

import { primaryColor } from 'helpers/constants'

export const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.13);
`

export const GridContainer = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
  text-align: center;

  div {
    border: 1px solid ${primaryColor};
    padding: 12px;
  }

  p {
    color: ${primaryColor};
    font-size: 40px;
    line-height: 150%;
    margin: 0;
  }
`
export const EyeIcon = styled.img`
  cursor: pointer;
  height: 16px;
  &:hover {
    filter: invert(37%) sepia(86%) saturate(827%) hue-rotate(131deg)
      brightness(97%) contrast(101%);
  }
`

export const PCustom = styled.p`
  margin: 0;
  display: inline-block;
  margin-left: 27px;
  font-weight: 600;
  margin-right: 5px;
  font-size: 14px;
`

export const Text = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  display: inline-block;
  border: 0;
  outline: none;
  padding: 0 28px;
  border: 1px solid transparent;

  ${PCustom} + & {
    padding-left: 8px;
  }
`
