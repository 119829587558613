import React, { useCallback, useContext, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useParams } from 'react-router'

import { Box, Button, Grid, Typography } from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { WarningMessage } from 'ui/atoms/Messages'
import RadioGroup from 'ui/atoms/RadioGroup'
import { Title, Subtitle } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { REQUEST_SHARING_ROUTES } from '../../constants/routes'
import RequestSharingForm from '../../fragments/RequestForm'
import { requestSharingUseCases } from '../../providers'
import formSchema from './formSchema'
import Aprove from './Fragments/Approve'
import LastPendingCompletionAnalysis from './Fragments/LastPendingCompletionAnalysis'
import Reprove from './Fragments/Reprove'
import { Container } from './style'

const ApproveRequestSharing = () => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const { id } = useParams()

  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false)

  const [paramsPromise] = useState(async () => {
    const params = await requestSharingUseCases.getParams()
    const refusalReasonList = await requestSharingUseCases.getRefusalReasons()
    return { ...params, refusalReasonList }
  })

  const [analysisParamsPromise] = useState(async () => {
    const refusalReasonList = await requestSharingUseCases.getRefusalReasons()
    return { refusalReasonList }
  })

  const initialDataPromise = useCallback(async () => {
    return await requestSharingUseCases.getRequestData(id)
  }, [id])

  const allPromisses = useCallback(async () => {
    const initialData = await initialDataPromise()
    const params = await paramsPromise
    const analysisParams = await analysisParamsPromise

    return { initialData, params, analysisParams }
  }, [initialDataPromise, paramsPromise, analysisParamsPromise])

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {}
  })

  const { control, errors, handleSubmit } = formMethods

  const reproveSharing = async formData => {
    try {
      const requestData = {
        canSharing: formData.approve,
        needMoreInfo: formData.needsComplementation,
        reasonRefuseSharingId: formData.reasonType,
        detailInfo: formData.reason
      }

      await requestSharingUseCases.reproveSharing(id, requestData)
      history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
    } catch (error) {
      console.log(error)
    }
  }

  const sharingNeedsComplementation = async formData => {
    try {
      const requestData = {
        canSharing: formData.approve,
        needMoreInfo: formData.needsComplementation,
        detailInfo: formData.complementation
      }

      await requestSharingUseCases.reproveSharing(id, requestData)
      history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
    } catch (error) {
      console.log(error)
    }
  }

  const approveSharing = async formData => {
    try {
      const requestData = {
        responsibleName: formData.responsible,
        responsibleEmail: formData.email,
        telephone: formData.phone,
        suggestedDateFrom: formData.suggestedDateFrom,
        suggestedDateTo: formData.suggestedDateTo,
        equipmentList: formData.specificEquipment.map(item => item.id)
      }
      await requestSharingUseCases.approveSharing(id, requestData)
      history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = formData => {
    if (!formData.approve && !formData.needsComplementation) {
      reproveSharing(formData)
    } else if (!formData.approve && formData.needsComplementation) {
      sharingNeedsComplementation(formData)
    } else if (formData.approve) {
      approveSharing(formData)
    }
  }

  const handleConfirmCancel = () => {
    history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
  }

  const approve = useWatch({ control, name: 'approve' })

  return (
    <>
      <Loader />
      <DialogConfirm
        dialogText="ANALYZE.CONFIRM_CANCEL"
        open={confirmCancelOpen}
        onConfirm={handleConfirmCancel}
        onCancel={() => setConfirmCancelOpen(false)}
      />

      <div className={classes.defaultContainer}>
        <div style={{ marginLeft: '15px' }}>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Title item xs={12} $paddingTop onBack={handleConfirmCancel}>
              {translate('ANALYZE.TITLE')}
            </Title>
          </Grid>
          <AsyncLoad promiseFn={allPromisses}>
            {({ params, analysisParams, initialData }) => (
              <>
                <LastPendingCompletionAnalysis initialData={initialData} />

                <RequestSharingForm
                  readOnly
                  initialData={initialData}
                  params={params}
                />

                <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                  <Subtitle item xs={12}>
                    {translate('ANALYZE.SUBTITLE')}
                  </Subtitle>

                  {initialData.removedEquipments.length ? (
                    <Grid item xs={12}>
                      <WarningMessage>
                        {translate('ANALYZE.REMOVED_EQUIPMENTS_MESSAGE')}
                      </WarningMessage>

                      <Typography component={Box} variant="body2" py={2}>
                        <strong>
                          {translate('ANALYZE.REMOVED_EQUIPMENTS_LABEL')}{' '}
                        </strong>
                        {initialData.removedEquipments
                          .map(equip => `${equip.code} - ${equip.name}`)
                          .join(', ')}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  {...disableSubmitOnEnter}
                >
                  <div style={{ margin: '12px' }}>
                    <Grid
                      component={Container}
                      container
                      spacing={3}
                      style={{ marginBottom: '20px' }}
                    >
                      <Grid item xs={12}>
                        <RadioGroup
                          required
                          name="approve"
                          label={translate('ANALYZE.APPROVE_LABEL')}
                          control={control}
                          error={errors}
                          inline
                          row
                          options={[
                            { id: true, name: translate('COMMONS:YES') },
                            { id: false, name: translate('COMMONS:NO') }
                          ]}
                        />
                      </Grid>

                      {String(approve) === 'true' ? (
                        <Aprove
                          formMethods={formMethods}
                          sharingData={initialData}
                        />
                      ) : null}

                      {String(approve) === 'false' ? (
                        <Reprove
                          formMethods={formMethods}
                          params={analysisParams}
                        />
                      ) : null}
                    </Grid>
                  </div>
                  <Grid container justify="flex-end" spacing={3}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setConfirmCancelOpen(true)}
                      >
                        {translate('COMMONS:CANCEL')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit">
                        {translate('REGISTER.SEND')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </>
            )}
          </AsyncLoad>
        </div>
      </div>
    </>
  )
}

export default wrapper(ApproveRequestSharing, { namespace: 'REQUEST_SHARING' })
