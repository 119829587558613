import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'
import DefaultHint from 'ui/molecules/commons/DefaultHint'
import useStyles from 'ui/styles'

import {
  About,
  DataContainer,
  ItemContainer,
  ItemStatus,
  Param,
  ParamsContainer,
  Picture,
  PictureContainer,
  StatusContainer,
  Title
} from '../style'

export const EquipmentItem = ({ content, type }) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  return (
    <ItemContainer>
      <PictureContainer>
        <StatusContainer>
          {['OUT_OF_DATE', 'NEEDS_UPDATE'].includes(
            content.equipmentSituation
          ) && (
            <ItemStatus
              className="warning"
              content={translate(
                `EQUIPMENT:SITUATION.${content.equipmentSituation}`
              )}
            />
          )}
          {content.reasonExclusionInactivationEquip ? (
            <ItemStatus
              className="danger"
              content={content.reasonExclusionInactivationEquip.name}
            />
          ) : null}
        </StatusContainer>

        <Picture src={content.imagePath}>
          <Icon className="fas fa-image" fontSize="inherit" />
        </Picture>
      </PictureContainer>
      <DataContainer>
        <Title>{`${content.initials} - ${content.name}`}</Title>
        {type === 'LIST' || content.multiuser ? (
          <ParamsContainer style={{ marginBottom: '16px', flex: '0 1 auto' }}>
            <Grid container spacing={2} className="line-container">
              {type === 'LIST' ? (
                <Grid item>
                  <Param noWrap>
                    <strong>{translate('CODE')} </strong>
                    {content.code}
                  </Param>
                </Grid>
              ) : null}
              {content.laboratory.sharing ? (
                <Grid item>
                  <Param $situationParam $iconPrimary>
                    <Icon className="icon fas fa-share-alt-square" />
                    {translate('SHARING:SHARING_ENABLED')}
                  </Param>
                </Grid>
              ) : null}

              {content.multiuser ? (
                <Grid item>
                  <Param
                    $situationParam
                    $textPrimary={content.laboratory.sharing}
                    $iconPrimary={content.laboratory.sharing}
                  >
                    <Icon className="icon fas fa-users" />
                    {translate('SHARING:MULTIUSER.TITLE')}
                    <DefaultHint
                      className="hint-icon"
                      content={translate('SHARING:MULTIUSER.HINT')}
                    />
                  </Param>
                </Grid>
              ) : null}
            </Grid>
          </ParamsContainer>
        ) : null}
        <About style={{ marginBottom: '16px' }}>{content.about}</About>
        <ParamsContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Param noWrap>
                <strong>{translate('LABORATORY')} </strong>
                {`${content.laboratory?.initials} - ${content.laboratory?.name}`}
              </Param>
            </Grid>
            <Grid item xs={12}>
              <Param noWrap>
                <strong>{translate('LOCATION')} </strong>
                {`${content.laboratory?.city}/${content.laboratory?.state}`}
              </Param>
            </Grid>
          </Grid>
          <div className="actions">
            <Button
              component={Link}
              className={classes.baseButton}
              to={`/equipment/${content.idAsset}`}
            >
              {translate('VIEW')}
            </Button>
          </div>
        </ParamsContainer>
      </DataContainer>
    </ItemContainer>
  )
}

export default wrapper(EquipmentItem, { namespace: 'LAB_EQUIP_CARD' })
