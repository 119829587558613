import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { Button, Grid } from '@material-ui/core'

import { AdminService } from 'api'
import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { WarningMessage } from 'ui/atoms/Messages'
import wrapper from 'ui/atoms/Wrapper'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import { ADMIN_ROUTES } from '../../constants/routes'
import LaboratoryInformation from './Fragments/LaboratoryInformation'
import RepresentativeInput from './Fragments/RepresantativeInput'
import RepresentativeList from './Fragments/RepresentativeList'

const LaboratoryEdit = () => {
  const classes = useStyles()
  const { laboratoryId } = useParams()
  const { translate } = useContext(GlobalizationContext)

  const [laboratoryData, setLaboratoryData] = useState(null)
  const [representantsList, setRepresentantsList] = useState(null)
  const [showWarning, setShowWarning] = useState(false)
  const [disableForm, setDisableForm] = useState(false)

  const getLaboratoryById = useCallback(async () => {
    const laboratory = await AdminService.getLaboratory(laboratoryId)
    setLaboratoryData(laboratory)
    setRepresentantsList(laboratory?.representativeList)
  }, [laboratoryId])

  useEffect(() => {
    getLaboratoryById()
  }, [getLaboratoryById])

  useEffect(() => {
    const disabled =
      laboratoryData?.situation !== 'WAITING_PUBLICATION' &&
      laboratoryData?.situation !== 'PUBLISHED'

    setDisableForm(disabled)
  }, [laboratoryData])

  const onCancel = () => {
    history.push(generatePrivatePath(ADMIN_ROUTES.LABORATORIES))
  }

  const onSave = async () => {
    try {
      const mappedRepresentantList = representantsList.map(
        ({ deleted, ...item }) => ({
          ...item,
          situation: !deleted ? item.situation : 'DELETED'
        })
      )

      await AdminService.saveRepresentantsList(
        laboratoryId,
        mappedRepresentantList
      )
      setShowWarning(false)
      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
    } catch {
      notificationUseCases.newError()
    }
  }

  const onResendInvite = async row => {
    try {
      await AdminService.resendLabRepresentantInvite(laboratoryId, row.email)
      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
    } catch (error) {
      notificationUseCases.newError()
    }
  }

  return (
    <>
      <Loader />
      <LaboratoryInformation laboratoryData={laboratoryData} />
      <RepresentativeInput
        disabled={disableForm}
        laboratoryId={laboratoryId}
        representants={representantsList}
        setRepresentants={setRepresentantsList}
        showWarning={setShowWarning}
      />

      {showWarning && (
        <Grid container style={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            <WarningMessage>
              {translate('MESSAGES.FORM_NEEDS_SAVING')}
            </WarningMessage>
          </Grid>
        </Grid>
      )}

      <RepresentativeList
        disabled={disableForm}
        representants={representantsList}
        resendInvite={onResendInvite}
        setRepresentants={setRepresentantsList}
        showWarning={setShowWarning}
      />
      <Grid
        container
        justify="flex-end"
        spacing={2}
        style={{ marginTop: '16px' }}
      >
        <Grid item xs={12} md={2}>
          <Button
            className={classes.baseButtonGrey}
            type={'button'}
            variant="contained"
            fullWidth
            onClick={onCancel}
          >
            {translate('COMMONS:CANCEL')}
          </Button>
        </Grid>

        <Grid item xs={12} md={2}>
          <Button
            fullWidth
            type={'submit'}
            variant="contained"
            className={classes.baseButton}
            onClick={onSave}
            disabled={disableForm}
          >
            {translate('COMMONS:SAVE')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default wrapper(LaboratoryEdit, {
  mapState: null,
  mapDispatch: null,
  namespace: 'LABORATORY_RESPONSIBLE_MANAGEMENT'
})
