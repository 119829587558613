import React, { useState, useEffect, useContext } from 'react'

import { Grid } from '@material-ui/core'

import EquipmentService from 'api/EquipmentService'

import BaseSelectUncontrolled from 'ui/atoms/BaseSelectUncontrolled'
import BaseTextFieldPublic from 'ui/atoms/BaseTextFieldPublic.js'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const BodyFilters = props => {
  const [filterList, setFilterList] = useState()
  const [newFilterList, setNewFilterList] = useState()
  const [filterOption, setFilterOption] = useState('')
  const { translate } = useContext(GlobalizationContext)

  const { onSelectChange, onInputChange } = props

  const getEquipmentService = () => {
    EquipmentService.getFiltersSitutation().then(resp => {
      setFilterList(resp.data)
    })
  }

  const handleChange = event => {
    setFilterOption(event.target.value)
    onSelectChange(event)
  }

  useEffect(() => {
    filterList &&
      setNewFilterList(
        filterList.map(item => ({
          id: item.id,
          name: translate(`EQUIPMENT:SITUATION.${item.value}`)
        }))
      )
  }, [filterList, translate])

  useEffect(() => {
    getEquipmentService()
  }, [])

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: '20px' }}
        alignItems="baseline"
      >
        <Grid item xs={12} md={3}>
          {newFilterList && (
            <>
              <BaseSelectUncontrolled
                defaultValue={translate('EQUIPMENT_LIST_FILTER_STATUS')}
                label=""
                name="filterEquipmentStatus"
                options={newFilterList}
                onChange={handleChange}
                value={filterOption}
              ></BaseSelectUncontrolled>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          <BaseTextFieldPublic
            name="filterEquipmentText"
            placeholder={translate('EQUIPMENT_LIST_SEARCH_DEFAULT')}
            handleChange={onInputChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default BodyFilters
