import React from 'react'
import styled, { css } from 'styled-components'

import MaterialFormControlLabel from '@material-ui/core/FormControlLabel'
import MaterialFormHelperText from '@material-ui/core/FormHelperText'
import MaterialFormLabel from '@material-ui/core/FormLabel'
import MaterialRadio from '@material-ui/core/Radio'
import MaterialRadioGroup from '@material-ui/core/RadioGroup'

import { primaryColor } from 'helpers/constants'

import CustomFormControl from 'ui/atoms/FormControl'

export const FormControlLabel = styled(MaterialFormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;

    .MuiFormControlLabel-label {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
  }
`

export const FormHelperText = styled(MaterialFormHelperText)``
export const FormLabel = styled(MaterialFormLabel)``
export const Radio = styled(MaterialRadio)`
  &.MuiRadio-root.Mui-checked {
    color: ${primaryColor};
  }
`
const dashedLine = css`
  ${FormControlLabel}:last-child {
  }
`

const columns = css`
  &.MuiFormGroup-root {
    flex-direction: row;

    ${FormControlLabel} {
      flex-grow: 1;
      width: ${props => 100 / props.columns}%;
      max-width: ${props => 100 / props.columns}%;
      border: none;
    }
  }
`

const row = css`
  ${FormControlLabel}:not(:first-child) {
    margin-left: 12px;
  }
`

export const RadioGroup = styled(({ dashedLine, columns, ...props }) => (
  <MaterialRadioGroup {...props} />
))`
  position: relative;

  ${props => props.dashedLine && dashedLine}
  ${props => props.columns > 1 && columns}
  ${props => props.row && row}
`

const requiredSimbol = css`
  &:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    content: '\f069';
    position: absolute;
    vertical-align: middle;
    display: flex;
    align-items: center;
    left: -11px;
    font-size: 5px;
    height: 100%;
  }
`

const required = css`
  ${props => props.$inline && requiredSimbol}

  ${props =>
    !props.$inline &&
    css`
      &:before {
        content: none;
      }

      .MuiFormGroup-root {
        ${requiredSimbol}
      }
    `}
`

export const FormControl = styled(CustomFormControl)`
  width: 100%;

  ${props => props.$required && required}

  ${props =>
    props.$inline &&
    css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      ${FormLabel} {
        width: auto;
        flex: 0 1 auto;
        padding-right: 16px;
      }

      ${RadioGroup} {
        flex: 1;
      }

      ${props => props.theme.breakpoints.up('md')} {
        ${FormLabel} {
          margin-bottom: 0;
        }
      }

      ${props => props.theme.breakpoints.down('sm')} {
        flex-direction: column;

        ${FormLabel} {
          padding-right: 0;
        }
      }
    `}
`
