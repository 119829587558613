import clsx from 'clsx'
import React, { useCallback, useEffect, useContext, useState } from 'react'

import { Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'

import { textToConstant } from 'helpers/textToConstant'

import { TableChip } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import TableActions from 'ui/atoms/TableActions'
import useStyles from 'ui/styles'

import {
  WrapEmail,
  WrapContent,
  UndoIcon
} from '../../../InstitutionEdit/styles'

const TableRowComponent = ({
  disabled,
  row,
  index,
  showWarning,
  updateRepresentant,
  resendInvite
}) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const [permissionValue, setPermissionValue] = useState(row.permission)
  const [situationValue, setSituationValue] = useState(row.situation)

  useEffect(() => setSituationValue(row.situation), [row.situation])

  const onChangePermission = event => {
    const newPermission = event.target.value
    setPermissionValue(newPermission)

    updateRepresentant(oldUserList => {
      const rowCopy = { ...oldUserList[index], permission: newPermission }
      const listCopy = [...oldUserList]
      listCopy[index] = rowCopy
      return listCopy
    })
    showWarning(true)
  }

  const onChangeSituation = event => {
    const newSituation = event.target.value
    setSituationValue(newSituation)

    updateRepresentant(oldUserList => {
      const rowCopy = { ...oldUserList[index], situation: newSituation }
      const listCopy = [...oldUserList]
      listCopy[index] = rowCopy
      return listCopy
    })
    showWarning(true)
  }

  const remove = useCallback(
    item => {
      if (item.id) {
        updateRepresentant(oldList => {
          const newList = [...oldList]
          const itemIndex = newList.findIndex(obj => obj.id === item.id)
          newList[itemIndex] = {
            ...newList[itemIndex],
            deleted: true
          }
          return newList
        })
      } else {
        updateRepresentant(oldList =>
          oldList.filter(obj => obj.email !== item.email)
        )
      }
      showWarning(true)
    },
    [showWarning, updateRepresentant]
  )

  const undoRemoveInvite = useCallback(
    item => {
      updateRepresentant(oldList => {
        const newList = [...oldList]
        const itemIndex = newList.findIndex(obj => obj.email === item.email)
        delete newList[itemIndex].deleted
        return newList
      })
    },
    [updateRepresentant]
  )
  const actions = () => {
    const actions = { ...actionMap }
    if (
      row.situation === 'INACTIVE' ||
      row.situation === 'PENDING' ||
      row.situation === 'REJECTED'
    ) {
      delete actions.RESEND_INVITE
    }
    return actions
  }

  const actionMap = {
    DELETE: () => remove(row),
    RESEND_INVITE: () => resendInvite(row)
  }

  const rowActionList = Object.keys(actions()).map(action => ({
    code: action,
    name: `REPRESENTATIVE_LIST.ACTIONS.${action}`
  }))

  const handleRowAction = (code, row) => {
    actionMap[code](row)
  }

  const canEditRow = () => {
    return (
      !row.userInactive &&
      !row.deleted &&
      ['PENDING', 'REJECTED', 'DELETED', 'INACTIVE'].indexOf(row.situation) ===
        -1 &&
      !disabled &&
      !row.responsible
    )
  }

  const renderEmail = () => {
    return (
      <>
        {row.situation !== 'REJECTED' && (
          <Tooltip title={translate(`LABORATORY:SITUATION.${row.situation}`)}>
            <div className={classes['statusCircle' + row.situation]}></div>
          </Tooltip>
        )}
        <WrapContent>
          <WrapEmail title={row.email}>{row.email}</WrapEmail>
          {row.situation === 'INACTIVE' && (
            <TableChip
              size="small"
              label={translate('REPRESENTATIVE_LIST.INACTIVE')}
            />
          )}
        </WrapContent>
      </>
    )
  }

  const renderPermission = () => {
    return (
      <div
        style={{
          paddingRight: '15px'
        }}
      >
        <Select
          name="permission"
          disableUnderline={true}
          disabled={!canEditRow()}
          fullWidth
          native
          value={permissionValue}
          onChange={event => onChangePermission(event)}
        >
          {[
            'ROLE_LABORATORIO_TOTAL',
            'ROLE_LABORATORIO_PARCIAL',
            'ROLE_LABORATORIO_LIMITADA'
          ].map(item => (
            <option key={item} value={item}>
              {translate(`USER:ROLE_SHORT.${item}`)}
            </option>
          ))}
        </Select>
      </div>
    )
  }

  const renderSituation = () => {
    return (
      <Grid container>
        <Select
          name="situation"
          disableUnderline={true}
          fullWidth
          disabled={!canEditRow()}
          native
          value={situationValue}
          onChange={event => onChangeSituation(event)}
        >
          <option value="ACTIVE">
            {translate('LABORATORY:SITUATION.ACTIVE')}
          </option>

          <option value="INACTIVE">
            {translate('LABORATORY:SITUATION.INACTIVE')}
          </option>

          {row.deleted || row.situation === 'DELETED' ? (
            <option value="DELETED">
              {translate('LABORATORY:SITUATION.DELETED')}
            </option>
          ) : null}
          {row.situation === 'PENDING' ? (
            <option value="PENDING">
              {translate('LABORATORY:SITUATION.PENDING')}
            </option>
          ) : null}
          {row.situation === 'REJECTED' ? (
            <option value="REJECTED">
              {translate('LABORATORY:SITUATION.REJECTED')}
            </option>
          ) : null}
        </Select>
      </Grid>
    )
  }

  const renderTableActions = () => {
    return (
      <>
        {!row.deleted ? (
          <TableActions
            actionList={rowActionList}
            handleActionSelected={code => handleRowAction(code, row)}
            disabled={disabled || row.responsible}
          />
        ) : (
          <IconButton
            onClick={() => undoRemoveInvite(row)}
            aria-label="undo"
            title="Desfazer exclusão"
            name="UNDO"
          >
            <UndoIcon className="fas fa-undo"></UndoIcon>
          </IconButton>
        )}
      </>
    )
  }

  return (
    <TableRow
      id={`LABORATORY_REPRESENTATIVE_${textToConstant(row.email)}`}
      className={clsx({
        [classes.disableRow]: !canEditRow()
      })}
    >
      <TableCell align="center">{index + 1}</TableCell>
      <TableCell padding={'default'} align="left">
        {renderEmail()}
      </TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="center">{renderPermission()}</TableCell>
      <TableCell>{renderSituation()}</TableCell>
      <TableCell align="center" style={{ width: '20px' }}>
        {renderTableActions()}
      </TableCell>
    </TableRow>
  )
}

export default TableRowComponent
