import React, { useContext } from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const TableHeadComponent = () => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center">#</TableCell>
        <TableCell align="left">
          {translate('REPRESENTATIVE_LIST.EMAIL')}
        </TableCell>
        <TableCell align="left">
          {translate('REPRESENTATIVE_LIST.NAME')}
        </TableCell>
        <TableCell align="left">
          {translate('REPRESENTATIVE_LIST.PERMISSION')}
        </TableCell>
        <TableCell align="left">
          {translate('REPRESENTATIVE_LIST.SITUATION')}
        </TableCell>
        <TableCell align="left"></TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableHeadComponent
