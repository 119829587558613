import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'

import { CNPJ_MASK } from './constants'
export class cnpjMask extends Component {
  render() {
    const { inputRef, ...other } = this.props
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null)
        }}
        mask={CNPJ_MASK}
        placeholder="99.999.999/9999-99"
      />
    )
  }
}
