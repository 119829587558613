import styled from 'styled-components'

import { primaryColor } from 'helpers/constants'

export const StyledBurger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 20px;
  margin-right: 50px;
  height: 31px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  @media screen and (max-width: 640px) {
    margin-right: 25px;
  }

  &:focus {
    outline: none;
  }

  div {
    width: ${({ open }) => (open ? '30px' : '20px')};
    height: 5px;
    background: ${primaryColor};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: ${({ open }) => (open ? '2px' : '1px')};

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`
