import React from 'react'
import { Trans } from 'react-i18next'

import { Grid } from '@material-ui/core'

import TitleIcon from 'ui/atoms/BasePublic/TitleIcon'
import { useGlobalizationContext } from 'ui/atoms/Globalization'

import CorgsIcon from 'assets/icons/corgs.svg'
import UsrLabIcon from 'assets/icons/usrlab.svg'
import WorldIcon from 'assets/icons/world.svg'

import { Text, CardContainer, Card, ImageContainer } from '../../style'
import CardTitle from '../CardTitle'

const LabResponsible = () => {
  const { translate } = useGlobalizationContext()

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <TitleIcon
            size="1"
            iconName="flask"
            content="LAB_RESPONSIBLE.TITLE"
            icon={undefined}
          />
        </Grid>
      </Grid>

      <CardContainer>
        <Card>
          <CardTitle
            text={translate('LAB_RESPONSIBLE.COLUMN_SUBTITLE_1')}
            titleNumber={1}
          />
          <ImageContainer>
            <img src={UsrLabIcon} alt="" />
          </ImageContainer>
          <Trans
            parent={Text}
            t={translate}
            i18nKey="LAB_RESPONSIBLE.COLUMN_TEXT_1"
          />
        </Card>
        <Card>
          <CardTitle
            text={translate('LAB_RESPONSIBLE.COLUMN_SUBTITLE_2')}
            titleNumber={2}
          />
          <ImageContainer>
            <img src={CorgsIcon} alt="" />
          </ImageContainer>
          <Trans
            parent={Text}
            t={translate}
            i18nKey="LAB_RESPONSIBLE.COLUMN_TEXT_2"
          />
        </Card>
        <Card>
          <CardTitle
            text={translate('LAB_RESPONSIBLE.COLUMN_SUBTITLE_3')}
            titleNumber={3}
          />
          <ImageContainer>
            <img src={WorldIcon} alt="" />
          </ImageContainer>
          <Trans
            parent={Text}
            t={translate}
            i18nKey="LAB_RESPONSIBLE.COLUMN_TEXT_3"
          />
        </Card>
      </CardContainer>
      <Trans parent={Text} t={translate} i18nKey="LAB_RESPONSIBLE.NOTE_TITLE" />
      <Trans parent={Text} t={translate} i18nKey="LAB_RESPONSIBLE.NOTE_TEXT" />
    </>
  )
}

export default LabResponsible
