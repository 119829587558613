import clsx from 'clsx'
import React, { useMemo, useCallback, useContext } from 'react'
import styled from 'styled-components'

import { Checkbox } from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Marker, MarkerGreen, MarkerRed } from 'ui/atoms/Marker'
import { TableRow, TableCell } from 'ui/atoms/Table'
import TableActions from 'ui/atoms/TableActions'
import useStyles from 'ui/styles'

import Situation from './Fragments/Situation'

const EquipMarker = styled(Marker)`
  ${props => ['ACTIVE'].indexOf(props.type) >= 0 && MarkerGreen}
  ${props => ['INACTIVE'].indexOf(props.type) >= 0 && MarkerRed}
`

const Row = ({
  item,
  onRowAction: handleRowAction,
  selected,
  handleClick,
  shouldShowSelect
}) => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const redirectUrl = useMemo(() => {
    return generatePrivatePath(`/equipment/${item.id}`)
  }, [item])

  const rowActionList = value =>
    value.map(action => {
      return {
        code: action,
        name: `LIST_EQUIPMENT_ACTION_${action}`
      }
    })

  const onRowAction = useCallback(
    action => {
      switch (true) {
        case ['EDIT', 'VIEW'].includes(action):
          history.push(redirectUrl)
          break
        default:
          handleRowAction(action, item)
          break
      }
    },
    [handleRowAction, item, redirectUrl]
  )

  return (
    <TableRow
      selected={selected}
      className={clsx({
        [classes.selected]: selected,
        [classes.disableRow]: shouldShowSelect && !item.batchUpdate
      })}
    >
      {shouldShowSelect && (
        <TableCell>
          <Checkbox
            onClick={() => handleClick(item.id)}
            checked={selected}
            disabled={!item.batchUpdate}
          />
        </TableCell>
      )}
      <TableCell>
        <EquipMarker
          type={item.situation}
          title={translate(`EQUIPMENT:SITUATION.${item.situation}`)}
        />
      </TableCell>
      <TableCell>{item.code}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.brand}</TableCell>
      <TableCell>{item.model}</TableCell>
      <TableCell>{item.manufacturer}</TableCell>
      <TableCell>
        {item.laboratory.initials} - {item.laboratory.name}
      </TableCell>
      <TableCell>
        <Situation equipment={item} />
      </TableCell>
      <TableCell>
        <TableActions
          actionList={rowActionList(item.actions)}
          handleActionSelected={onRowAction}
        />
      </TableCell>
    </TableRow>
  )
}
export default Row
