import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

export const ProfilePictureContainer = styled(Grid)`
  flex-grow: 0;
  display: flex;
  justify-content: center;
`

export const PersonalData = styled(Grid)``
