import React, { useContext } from 'react'
import styled from 'styled-components'

import { IconButton } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'

import { primaryColor } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const LanguageButton = styled(IconButton)`
  margin: 0 40px;
  padding: 0;

  &:hover {
    background: transparent;
  }
`

const Icon = styled.i`
  color: ${primaryColor};
  cursor: pointer;
  font-size: 22px;
`

const MenuList = styled(Menu)`
  margin-top: 40px;
`

const MenuListItem = styled(MenuItem)`
  font-size: 14px;
`

function MenuButtonLanguage() {
  const { translate, i18n } = useContext(GlobalizationContext)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const changeLanguage = language => {
    i18n.changeLanguage(language)
    setAnchorEl(null)
  }

  const getLanguageHint = () =>
    i18n.language === 'ptBr'
      ? translate('COMMONS:LANGUAGES.PT')
      : translate('COMMONS:LANGUAGES.EN')

  return (
    <>
      <Tooltip title={getLanguageHint()}>
        <LanguageButton
          aria-label={translate('LANGUAGE')}
          onClick={handleClick}
          id="MENU_LANGUAGE"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Icon className="fas fa-globe" />
        </LanguageButton>
      </Tooltip>

      <MenuList
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuListItem onClick={() => changeLanguage('ptBr')}>
          {translate('COMMONS:LANGUAGES.PT')}
        </MenuListItem>
        <MenuListItem onClick={() => changeLanguage('en')}>
          {translate('COMMONS:LANGUAGES.EN')}
        </MenuListItem>
      </MenuList>
    </>
  )
}

export default MenuButtonLanguage
