import { format, parseJSON as parseDate } from 'date-fns'
import React, { useContext } from 'react'

import { Avatar, Typography } from '@material-ui/core'

import { AuthContext } from 'context/AuthContext'

import {
  RowContainer,
  ImageContainer,
  ColumnContainer,
  SenderContainer,
  MessageContainer
} from './style'

const getInitials = fullName => {
  const nameList = fullName.split(' ')

  return (
    nameList.shift().charAt(0).toUpperCase() +
    nameList.pop().charAt(0).toUpperCase()
  )
}

const Message = React.forwardRef(({ data, className }, ref) => {
  const { userData } = useContext(AuthContext)

  const isCurrentUser = data.user.id === userData.userId

  return (
    <RowContainer className={className} ref={ref}>
      <ImageContainer>
        {!isCurrentUser ? (
          <Avatar
            src={data.userProfileImage ? `/${data.userProfileImage}` : null}
          >
            {getInitials(data.user.name)}
          </Avatar>
        ) : null}
      </ImageContainer>
      <ColumnContainer $isCurrentUser={isCurrentUser}>
        <SenderContainer>
          <Typography variant="h4">
            {`${data.user.name} ${format(
              parseDate(data.createdDate),
              'dd/MM/yyyy HH:mm'
            )}`}
          </Typography>
        </SenderContainer>
        <MessageContainer>
          <Typography variant="body2">{data.message}</Typography>
        </MessageContainer>
      </ColumnContainer>
    </RowContainer>
  )
})
Message.displayName = 'Message'

export default Message
