export default class EditUserUseCases {
  /**
   * @param {object} props
   * @param {import('api/UsersService').default} props.UserService
   * @param {import('api/NotificationService').default} props.NotificationService
   * @param {import('views/Notification/providers/notification.useCases').default} props.notificationUseCases
   */
  constructor({ UsersService, NotificationService, notificationUseCases }) {
    this.userService = UsersService
    this.notificationService = NotificationService
    this.notificationUseCases = notificationUseCases
  }

  async getFilters() {
    return await this.userService.getPermissionFilters()
  }

  async getNotifications(permissionList) {
    const selectedItens = permissionList
      .filter(item => item.checked)
      .map(item => item.permission)

    const filterParam = {
      permissionList: selectedItens.length ? selectedItens.join(',') : undefined
    }

    return await this.notificationService.getNotificationsFiltered(filterParam)
  }
}
