/* eslint-disable no-debugger */
import Axios from 'axios'
import { intersection } from 'lodash'
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef
} from 'react'

import Grid from '@material-ui/core/Grid'

import { LaboratoryService, EquipmentService } from 'api'
import { AuthContext } from 'context/AuthContext'
import { history, generatePrivatePath } from 'helpers/history'

import BaseButton from 'ui/atoms/BasePrivate/BaseButton'
import FilterSelect from 'ui/atoms/FilterSelect'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import BodyFilter from './Fragments/BodyFilter'
import ConfirmDialog from './Fragments/ConfirmDialog'
import List from './Fragments/List'
import {
  UpdateEquipmentButton,
  UpdateEquipmentContainer,
  UpdateEquipmentText,
  UpdateEquipmentSelectedText
} from './style'

const rowsPerPageList = [10, 20, 50, 100]

const EquipmentList = () => {
  const cancelToken = useRef(Axios.CancelToken.source())
  const { userData } = useContext(AuthContext)
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const [laboratoryList, setLaboratoryList] = useState([])
  const [equipmentList, setEquipmentList] = useState([])

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [count, setCount] = useState(0)
  const [pagination, setPagination] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  const [filterValue, setFilterValue] = useState('')
  const [filterSituation, setFilterSituation] = useState('')
  const [filterLaboratory, setFilterLaboratory] = useState('')

  const [selected, setSelected] = React.useState([])
  const [openDialog, setOpenDialog] = useState(false)

  const emptyList = Boolean(pagination.empty)

  useEffect(() => {
    LaboratoryService.getAllMine().then(response => {
      setLaboratoryList(response.data)
    })
  }, [])

  const getEquipmentList = useCallback(async () => {
    try {
      const filter = {
        ...(filterLaboratory ? { idLaboratory: filterLaboratory } : {}),
        ...(filterSituation ? { situation: filterSituation } : {}),
        ...(filterValue ? { search: filterValue } : {})
      }

      const response = await EquipmentService.getAll(
        {
          pageSize,
          pageNumber,
          filter
        },
        { cancelToken: cancelToken.current.token }
      )

      const { content, ...pagination } = response
      setEquipmentList(content)
      setPagination(pagination)

      setCount(pagination.totalElements)
    } catch (error) {
      console.log(error)
    }
  }, [filterLaboratory, filterSituation, filterValue, pageNumber, pageSize])

  useEffect(() => {
    getEquipmentList()
  }, [getEquipmentList])

  const handleAddEquipment = () => {
    history.push(generatePrivatePath('/equipment'))
  }

  // const handleAddCovidFrezzer = () => {
  //   history.push(generatePrivatePath('/equipment/congelador-covid'))
  // }

  const handleRowAction = () => {
    getEquipmentList()
  }

  const onFilterLaboratoryChange = event => {
    setPageNumber(0)
    setFilterLaboratory(event.target.value)
    setSelected([])
  }

  const onFilterSituationChange = event => {
    setPageNumber(0)
    setFilterSituation(event.target.value)
    setSelected([])
  }

  const onFilterValueChange = event => {
    setPageNumber(0)
    setFilterValue(event.target.value)
    setSelected([])
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const allSelected = equipmentList
        .filter(equip => equip.batchUpdate)
        .map(equip => equip.id)
      setSelected(allSelected)
    } else {
      setSelected([])
    }
  }

  const handleSelectClick = id => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  const updateEquipment = async () => {
    try {
      await EquipmentService.updateEquipmentsInBatch(selected)

      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      getEquipmentList()
      setSelected([])
      setOpenDialog(false)
    } catch {}
  }

  const showSelectForEquipmentsUpdate = () =>
    userData.roles.includes('ROLE_LABORATORIO_TOTAL') ||
    userData.roles.includes('ROLE_LABORATORIO_PARCIAL')

  return (
    <>
      <BaseContainerHeader
        label={translate('EQUIPMENT_LIST_HEADER')}
        showCloseButton={false}
        filter={
          <FilterSelect
            label={translate('EQUIPMENT_LIST_SELECT_LAB')}
            value={filterLaboratory}
            defaultValue={'Todos'}
            options={laboratoryList}
            translateOptions={true}
            onChange={onFilterLaboratoryChange}
          />
        }
      />
      <BodyFilter
        onSelectChange={onFilterSituationChange}
        onInputChange={onFilterValueChange}
      />

      {intersection(userData.roles, [
        'ROLE_LABORATORIO_TOTAL',
        'ROLE_LABORATORIO_PARCIAL'
      ]).length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={'auto'}>
            <BaseButton
              name="ADD_NEW_EQUIPMENT"
              label={translate('EQUIPMENT_LIST_ADD_EQUIPMENT_BUTTON')}
              onClick={handleAddEquipment}
            />
          </Grid>
          {/* <Grid item xs={'auto'}>
            <BaseButton
              name="ADD_NEW_EQUIPMENT_COVID_FREEZER"
              label={translate('EQUIPMENT_LIST_ADD_COVID_FREEZER_BUTTON')}
              onClick={handleAddCovidFrezzer}
            />
          </Grid> */}
        </Grid>
      )}

      <ConfirmDialog
        open={openDialog}
        handleCloseDialog={() => setOpenDialog(false)}
        handleConfirm={updateEquipment}
      />

      {selected.length > 0 && (
        <UpdateEquipmentContainer>
          <div>
            <UpdateEquipmentText>
              {translate('EQUIPMENT_LIST_ACTION')}
            </UpdateEquipmentText>
            <UpdateEquipmentButton onClick={() => setOpenDialog(true)}>
              {translate('EQUIPMENT_LIST_UPDATE')}
            </UpdateEquipmentButton>
          </div>

          <UpdateEquipmentSelectedText>{`${selected.length} ${translate(
            'EQUIPMENT_LIST_SELECTED_ITEMS'
          )}`}</UpdateEquipmentSelectedText>
        </UpdateEquipmentContainer>
      )}

      <List
        classes={classes}
        rowsPerPageOptions={rowsPerPageList}
        count={count}
        rowsPerPage={pageSize}
        page={pageNumber}
        onRowAction={handleRowAction}
        onChangePage={(event, newPage) => {
          setPageNumber(newPage)
        }}
        onChangeRowsPerPage={event => {
          setPageSize(parseInt(event.target.value, 10))
          setPageNumber(0)
        }}
        equipmentList={equipmentList}
        emptyList={emptyList}
        selected={isSelected}
        handleSelectClick={handleSelectClick}
        handleSelectAllClick={handleSelectAllClick}
        shouldShowSelect={showSelectForEquipmentsUpdate()}
      />
    </>
  )
}

export default EquipmentList
