// @ts-nocheck
import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { TYPES } from 'helpers/constants'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import { Label, RequiredSelect } from '../../styles'

function NewAdvertisePlatform({ formMethods, onTypeChange, type }) {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const { control, errors } = formMethods

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={4}>
          <Label htmlFor="invite_type">Tipo:</Label>
          <RequiredSelect
            name="invite_type"
            fullWidth
            native
            onChange={event => onTypeChange(event)}
            disableUnderline={true}
          >
            <option value="INSTITUTION">Instituição</option>
            <option value="LABORATORY">Laboratório</option>
          </RequiredSelect>
        </Grid>
        <Grid item xs={12} md={8}>
          <BaseTextField
            disabled={false}
            required={true}
            label={
              type === TYPES.INSTITUTION
                ? translate('INSTITUTION_NAME')
                : translate('LAB_NAME')
            }
            placeholder={translate('COMMONS:TYPE')}
            errors={errors}
            control={control}
            name="name"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BaseTextField
            disabled={false}
            required={true}
            label={translate('EMAIL')}
            placeholder={translate('COMMONS:TYPE')}
            errors={errors}
            control={control}
            name="email"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BaseTextField
            disabled={false}
            required={true}
            label={translate('RESPONSIBLE')}
            placeholder={translate('COMMONS:TYPE')}
            errors={errors}
            control={control}
            name="responsible_name"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            name="REQUEST_NEW_SHARING"
            type={'submit'}
            variant="contained"
            className={classes.baseButton}
          >
            {translate('SEND')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
export default NewAdvertisePlatform
