// @ts-nocheck
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { Button, Grid } from '@material-ui/core'

import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { useGlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import InviteModal from 'views/BackOffice/Subviews/InstitutionRegister/Fragments/Invite'
import { notificationUseCases } from 'views/Notification/providers'

const FormSchema = Yup.object().shape({
  email_invite: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .email('VALIDATION:EMAIL_ERROR')
    .trim()
})

const AddNewRepresentant = ({
  disabled,
  representants = [],
  setRepresentants
}) => {
  const classes = useStyles()
  const { translate } = useGlobalizationContext()
  const resolver = useYupValidationResolver(FormSchema, { translate })
  const { setError, getValues, errors, control, formState } = useForm({
    mode: 'onChange',
    resolver
  })
  const [modalInviteOpen, setModalInviteOpen] = useState(false)

  const { isDirty, isValid } = formState

  const onClickHandle = async () => {
    if (
      representants.filter(
        user => user.situation === 'ACTIVE' || user.situation === 'PENDING'
      ).length >= 10
    ) {
      return setError('email_invite', {
        type: 'emailAlreadyExists',
        message: translate('REGISTER_INSTITUTION_MAXIMUM_USERS_REACH')
      })
    }

    try {
      setModalInviteOpen(true)
    } catch (error) {
      const message = error.response?.data?.message
      if (message === 'invite.already.sent') {
        setError('emailInvite', {
          type: 'emailAlreadyExists',
          message: translate('REGISTER_INSTITUTION_USER_ALREADY_INVITED')
        })
      } else if (message === 'invalid.user.situation') {
        setError('emailInvite', {
          type: 'invalidUser',
          message: translate('ERRORS:INVALID_USER_SITUATION')
        })
      } else {
        notificationUseCases.newError()
      }
    }
  }

  const handleCloseInvite = () => {
    setModalInviteOpen(false)
  }

  const handleInvite = async invite => {
    setRepresentants(oldList => {
      return [...oldList, invite]
    })
    setModalInviteOpen(false)
  }

  const getEmail = () => {
    return getValues('email_invite')
  }

  return (
    <>
      {modalInviteOpen && (
        <InviteModal
          email={getEmail()}
          onInvite={handleInvite}
          onClose={handleCloseInvite}
        />
      )}
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={12} md={10}>
          <BaseTextField
            label=""
            placeholder={translate('INVITE.ADD_EMAIL')}
            errors={errors}
            control={control}
            name="email_invite"
            style={{
              maxHeight: '40px',
              minHeight: '40px'
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            disabled={!isDirty || !isValid || disabled}
            id="INVITE"
            fullWidth
            className={classes.baseButton}
            onClick={onClickHandle}
            variant="contained"
          >
            {translate('INVITE.INVITE')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default AddNewRepresentant
