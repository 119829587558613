import React, { useContext } from 'react'
import { Controller } from 'react-hook-form'

import FormHelperText from '@material-ui/core/FormHelperText'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import DialogTerms from 'ui/molecules/DialogTerms'

import {
  AgreeText,
  Checkbox,
  CheckboxContainer,
  ErrorContainer
} from './styles'

const name = 'termsOfUse'

const TermsOfUse = ({ formMethods, disabled }) => {
  const { translate } = useContext(GlobalizationContext)
  const { control, errors } = formMethods

  const hasError = Boolean(errors && errors[name])

  return (
    <>
      <CheckboxContainer>
        <Controller
          render={({ onChange, onBlur, value, ref }) => (
            <Checkbox
              name={name}
              onBlur={onBlur}
              onChange={e => onChange(e.target.checked)}
              checked={value}
              inputRef={ref}
              disabled={disabled}
              hasError={hasError}
            />
          )}
          error={hasError}
          control={control}
          name={name}
          defaultValue={false}
        />
        <AgreeText>
          {translate('REGISTER_INSTITUTION_DECLARE_HAVE_READ_THE_TERMS')}
          <DialogTerms>{translate('REGISTER_INSTITUTION_TERMS')}</DialogTerms>
        </AgreeText>
      </CheckboxContainer>

      {hasError ? (
        <ErrorContainer className={name + '-error-container'}>
          <FormHelperText error>{errors[name].message}</FormHelperText>
        </ErrorContainer>
      ) : null}
    </>
  )
}

export default TermsOfUse
