import React, { useState } from 'react'

import List from '../../../List/Fragments/List'
import Canceled from './Fragments/Canceled'
import CancelModal from './Fragments/CancelModal'
import CompletionPending from './Fragments/CompletionPending'
import ConfirmUsage from './Fragments/ConfirmUsage'
import DeclareUse from './Fragments/DeclareUse'
import Finished from './Fragments/Finished'
import PendingApproval from './Fragments/PendingApproval'
import Refused from './Fragments/Refused'

const Situation = ({ situation }) => {
  const { sharingRequest, currentLaboratory } = situation
  const [open, setOpen] = useState(false)

  const handleCancel = () => {
    setOpen(true)
  }

  return (
    <>
      {sharingRequest && (
        <>
          <List
            count={1}
            list={[sharingRequest]}
            emptyList={false}
            hideActions={true}
          />

          {{
            PENDING_APPROVAL: (
              <PendingApproval sharingRequest={sharingRequest} />
            ),
            COMPLETION_PENDING: (
              <CompletionPending
                sharingRequest={sharingRequest}
                onCancel={handleCancel}
              />
            ),
            CONFIRM_USAGE: (
              <ConfirmUsage
                sharingRequest={sharingRequest}
                currentLaboratory={currentLaboratory}
                onCancel={handleCancel}
              />
            ),
            DECLARE_USE: (
              <DeclareUse
                sharingRequest={sharingRequest}
                currentLaboratory={currentLaboratory}
              />
            ),
            FINISHED: <Finished sharingRequest={sharingRequest} />,
            REFUSED: <Refused sharingRequest={sharingRequest} />,
            CANCELED: <Canceled sharingRequest={sharingRequest} />
          }[sharingRequest.situation] || null}
        </>
      )}
      <CancelModal open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default Situation
