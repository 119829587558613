import React from 'react'

import { SectionSubtitle, TextCounter, TitleContainer } from '../../style'

const CardTitle = ({ text, titleNumber }) => {
  return (
    <TitleContainer>
      <TextCounter>{titleNumber}</TextCounter>
      <SectionSubtitle>{text}</SectionSubtitle>
    </TitleContainer>
  )
}

export default CardTitle
