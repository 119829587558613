import React, { useCallback, useContext, useMemo } from 'react'
import {
  Route,
  Switch,
  Link,
  useRouteMatch,
  useLocation,
  useParams
} from 'react-router-dom'

import { Box, Grid } from '@material-ui/core'

import { history, generatePrivatePath, mountPrivatePath } from 'helpers'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Tab, Tabs } from 'ui/atoms/Tabs'
import { Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'
import Loader from 'ui/molecules/Loader'

import { REQUEST_SHARING_ROUTES } from '../../constants/routes'
import { requestSharingUseCases } from '../../providers'
import Comunication from './Fragments/Comunication'
import Situation from './Fragments/Situation'
import View from './Fragments/View'
import {
  comunication,
  UnreadCountContext
} from './providers/ComunicationContext'

const RequestSharingDetails = () => {
  const { id } = useParams()
  const { translate } = useContext(GlobalizationContext)
  const { count, updateUnreadCount } = useContext(UnreadCountContext)
  const location = useLocation()

  const initialCountPromise = useCallback(async () => {
    await updateUnreadCount()
  }, [updateUnreadCount])

  const situationPromise = useCallback(async () => {
    try {
      const sharingRequest = await requestSharingUseCases.getSituation(id)

      let currentLaboratory = null
      if (
        !sharingRequest.isRequester &&
        ['CONFIRM_USAGE', 'DECLARE_USE'].includes(sharingRequest.situation)
      ) {
        currentLaboratory = await requestSharingUseCases.getLabById(
          sharingRequest.laboratory.id,
          {
            skipLoader: true
          }
        )
      }
      return { sharingRequest, currentLaboratory }
    } catch (error) {
      return null
    }
  }, [id])

  const allPromisses = useCallback(async () => {
    await initialCountPromise()
    return await situationPromise()
  }, [initialCountPromise, situationPromise])

  const handleBack = () => {
    history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
  }

  const TAB_ROUTES = useMemo(
    () => ({
      SITUATION: generatePrivatePath(REQUEST_SHARING_ROUTES.DETAILS, {
        pathParams: { id }
      }),
      VIEW: generatePrivatePath(REQUEST_SHARING_ROUTES.DETAILS_VIEW, {
        pathParams: { id }
      }),
      COMMUNICATION: generatePrivatePath(
        REQUEST_SHARING_ROUTES.DETAILS_COMMUNICATION,
        { pathParams: { id } }
      )
    }),
    [id]
  )

  const isCommunication = useMemo(
    () => location.pathname === TAB_ROUTES.COMMUNICATION,
    [TAB_ROUTES.COMMUNICATION, location.pathname]
  )

  return (
    <>
      <Loader />
      <AsyncLoad promiseFn={allPromisses}>
        {situation => (
          <Box
            display="flex"
            flexDirection="column"
            style={{
              height: '100%',
              overflow: isCommunication ? 'hidden' : 'initial'
            }}
          >
            <Box>
              <Grid container>
                <Title item xs={12} $paddingTop onBack={handleBack}>
                  {translate('DETAILS.TITLE')}
                </Title>
              </Grid>
            </Box>

            <Box>
              <Tabs value={location.pathname}>
                <Tab
                  label={translate('DETAILS.TABS.SITUATION')}
                  component={Link}
                  value={TAB_ROUTES.SITUATION}
                  to={TAB_ROUTES.SITUATION}
                />
                <Tab
                  label={translate('DETAILS.TABS.VIEW')}
                  component={Link}
                  value={TAB_ROUTES.VIEW}
                  to={TAB_ROUTES.VIEW}
                />
                <Tab
                  label={translate('DETAILS.TABS.COMMUNICATION', {
                    unreadCount: count || '0'
                  })}
                  component={Link}
                  value={TAB_ROUTES.COMMUNICATION}
                  to={TAB_ROUTES.COMMUNICATION}
                />
              </Tabs>
            </Box>

            <Box
              flex="1 1 100%"
              style={{
                padding: '16px 0',
                maxHeight: '100%',
                overflow: isCommunication ? 'hidden' : 'initial'
              }}
            >
              <Switch>
                <Route
                  path={mountPrivatePath(REQUEST_SHARING_ROUTES.DETAILS)}
                  exact={true}
                  render={routeProps => (
                    <Situation {...routeProps} situation={situation} />
                  )}
                />
                <Route
                  path={mountPrivatePath(REQUEST_SHARING_ROUTES.DETAILS_VIEW)}
                  component={View}
                />
                <Route
                  path={mountPrivatePath(
                    REQUEST_SHARING_ROUTES.DETAILS_COMMUNICATION
                  )}
                  render={routeProps => (
                    <Comunication {...routeProps} situation={situation} />
                  )}
                />
              </Switch>
            </Box>
          </Box>
        )}
      </AsyncLoad>
    </>
  )
}

export default wrapper(comunication(RequestSharingDetails), {
  namespace: 'REQUEST_SHARING'
})
