import React, { useContext } from 'react'
import { Trans } from 'react-i18next'

import { Box, Button, Grid, Typography } from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle } from 'ui/atoms/Title'
import theme from 'ui/theme'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'
const CompletionPending = ({ sharingRequest, onCancel: handleCancel }) => {
  const { translate } = useContext(GlobalizationContext)

  const handleEdit = () => {
    history.push(
      generatePrivatePath(REQUEST_SHARING_ROUTES.EDIT, {
        pathParams: { id: sharingRequest.id }
      })
    )
  }

  return (
    <>
      <Grid container spacing={2}>
        <Subtitle item xs={12}>
          {translate('DETAILS.COMPLETION_PENDING.TITLE')}
        </Subtitle>
        <Grid item xs={12}>
          <Trans
            parent={Typography}
            variant="body2"
            t={translate}
            i18nKey={'DETAILS.COMPLETION_PENDING.DESCRIPTION'}
          />
        </Grid>

        <Subtitle
          item
          xs={12}
          $paddingTop
          $noBorder={false}
          action={[
            <Box key="action1">
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.error.dark,
                  color: theme.palette.error.contrastText
                }}
                onClick={handleCancel}
              >
                {translate('DETAILS.COMPLETION_PENDING.CANCEL_REQUEST')}
              </Button>
            </Box>,

            sharingRequest.isRequester ? (
              <Box key="action2" pl={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEdit}
                >
                  {translate('DETAILS.COMPLETION_PENDING.EDIT_REQUEST')}
                </Button>
              </Box>
            ) : null
          ]}
        >
          {translate('DETAILS.COMPLETION_PENDING.SUBTITLE')}
        </Subtitle>

        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Trans
              parent={Typography}
              variant="body2"
              t={translate}
              i18nKey="DETAILS.COMPLETION_PENDING.REASON"
            />
          </Grid>
          <Grid item xs={12}>
            <Trans
              parent={Typography}
              variant="body2"
              t={translate}
              i18nKey="DETAILS.COMPLETION_PENDING.DETAILS"
              values={{
                details: sharingRequest.detailInfo
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default CompletionPending
