import { isDevelopment } from 'helpers/environment'

let apiUrl

if (isDevelopment()) {
  apiUrl = process.env.REACT_APP_API_BASE_URL
} else {
  apiUrl = `${process.env.REACT_APP_API_BASE_URL}.${window.location.host}`
}

export const API_URL = apiUrl
export const COGNITO_AUTHORIZE = process.env.REACT_APP_COGNITO_AUTHORIZE
