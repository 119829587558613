import parseISODate from 'date-fns/parseISO'
import React, { useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Grid, Typography } from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { phone } from 'helpers/generalInputMasks'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseDatePicker from 'ui/atoms/BaseDatePicker'
import BaseMultipleSelect from 'ui/atoms/BaseMultipleSelect'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'

import { requestSharingUseCases } from '../../../../../../providers'
import formSchema from './formSchema'
import { Container } from './style'

const EditAnalisys = ({
  sharingRequest,
  currentLaboratory,
  onCancel: handleCancel
}) => {
  const { translate } = useContext(GlobalizationContext)
  console.log({
    sharingRequest,
    currentLaboratory
  })

  const specificEquipment = useMemo(() => {
    return sharingRequest.specificEquipment.map(equip =>
      currentLaboratory.equipmentList.find(item => item.id == equip.id)
    )
  }, [currentLaboratory.equipmentList, sharingRequest.specificEquipment])

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {
      specificEquipment: specificEquipment,
      responsible: sharingRequest.responsibleName,
      email: sharingRequest.responsibleEmail,
      phone: sharingRequest.responsibleTelephone,
      suggestedDateFrom: parseISODate(sharingRequest.suggestedDateFrom),
      suggestedDateTo: parseISODate(sharingRequest.suggestedDateTo)
    }
  })

  const { errors, control, handleSubmit } = formMethods

  const onSubmit = async formData => {
    const requestData = {
      responsibleName: formData.responsible,
      responsibleEmail: formData.email,
      telephone: formData.phone,
      suggestedDateFrom: formData.suggestedDateFrom,
      suggestedDateTo: formData.suggestedDateTo,
      equipmentList: formData.specificEquipment.map(item => item.id)
    }

    try {
      await requestSharingUseCases.updateAnalisys(
        sharingRequest.id,
        requestData
      )
      history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
      <div style={{ margin: '12px' }}>
        <Grid container component={Container} spacing={3}>
          <Grid item xs={12} md={10}>
            <BaseMultipleSelect
              name="specificEquipment"
              label={translate('DETAILS.EDIT_USAGE.EQUIPMENTS')}
              placeholder={translate('COMMONS_SELECT')}
              errors={errors}
              control={control}
              options={currentLaboratory.equipmentList}
              required
              optionRenderer={option => `${option.code} - ${option.name}`}
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <BaseTextField
              name="responsible"
              label={translate('DETAILS.EDIT_USAGE.RESPONSIBLE')}
              placeholder={translate('COMMONS:TYPE')}
              control={control}
              errors={errors}
              required
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <BaseTextField
              name="email"
              label={translate('DETAILS.EDIT_USAGE.EMAIL')}
              placeholder={translate('COMMONS:TYPE')}
              control={control}
              errors={errors}
              required
            />
          </Grid>

          <Grid container item>
            <Grid item xs={12} md={6}>
              <BaseTextField
                name="phone"
                label={translate('DETAILS.EDIT_USAGE.PHONE')}
                placeholder={translate('COMMONS:TYPE')}
                control={control}
                errors={errors}
                required
                handleInput={phone}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={10} md={5}>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  {translate('DETAILS.EDIT_USAGE.SUGGESTED_DATE_LABEL')}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <BaseDatePicker
                  name="suggestedDateFrom"
                  label={translate('DETAILS.EDIT_USAGE.SUGGESTED_DATE_FROM')}
                  placeholder={translate(
                    'DETAILS.EDIT_USAGE.SUGGESTED_DATE_FROM_PLACEHOLDER'
                  )}
                  control={control}
                  error={errors.suggestedDateFrom}
                  required
                  fullWidth
                  disablePast
                  openTo="year"
                  views={['year', 'month', 'date']}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <BaseDatePicker
                  name="suggestedDateTo"
                  label={translate('DETAILS.EDIT_USAGE.SUGGESTED_DATE_TO')}
                  placeholder={translate(
                    'DETAILS.EDIT_USAGE.SUGGESTED_DATE_TO_PLACEHOLDER'
                  )}
                  control={control}
                  error={errors.suggestedDateTo}
                  required
                  fullWidth
                  disablePast
                  openTo="year"
                  views={['year', 'month', 'date']}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
              >
                {translate('COMMONS:CANCEL')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                {translate('REGISTER.SEND')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </form>
  )
}

export default EditAnalisys
