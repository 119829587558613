import styled, { css } from 'styled-components'

export const RowContainer = styled.div`
  display: flex;
  padding: 0 24px 24px 24px;

  &:first-child {
    padding: 24px;
  }
`

export const ImageContainer = styled.div`
  width: 60px;
`

export const SenderContainer = styled.div`
  min-height: 40px;
  align-items: center;
  display: flex;
`
export const MessageContainer = styled.div`
  display: flex;
`

const alignRight = css`
  ${SenderContainer}, ${MessageContainer} {
    justify-content: flex-end;
    text-align: right;
  }
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${props => props.$isCurrentUser && alignRight}
`
