import React, { useState, useRef, useMemo } from 'react'

import AddCircle from '@material-ui/icons/AddCircle'
import CreateIcon from '@material-ui/icons/Create'

import 'assets/scss/customSlickSlider.scss'

import placeholderImage from 'assets/images/carrousselPlaceholder.png'

import UploadImageModal from './Fragments/UploadImageModal/index'
import {
  ImageOverlay,
  ContentContainer,
  SliderContainer,
  CustomSlider,
  ItemCarroussel,
  PlaceholderItemCarroussel,
  NextArrow,
  PrevArrow
} from './styles'

const Carroussel = ({
  imageList,
  onImageListChange,
  dots: dotsProp,
  slidesToShow: slidesToShowProp,
  infinite: infiniteProp,
  height,
  readOnly,
  modalTitle,
  filesConfig,
  setEditedImageList
}) => {
  const sliderRef = useRef()

  const [modalOpen, setModalOpen] = useState(false)

  const dots = typeof dotsProp !== 'undefined' ? dotsProp : true
  const slidesToShow =
    typeof slidesToShowProp !== 'undefined' ? slidesToShowProp : 1
  const infinite = typeof infiniteProp !== 'undefined' ? infiniteProp : true

  var settings = useMemo(
    () => ({
      dots: dots,
      infinite: infinite,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToShow,
      adaptiveHeight: false,
      centerPadding: 0,
      arrows: false
    }),
    [dots, slidesToShow, infinite]
  )

  const images = imageList || []

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleImagesChanged = (newImageList, deletedImageList) => {
    onImageListChange(newImageList, deletedImageList)
    setModalOpen(false)
  }

  const remainder = images.length % slidesToShow
  const placeholderQty = images.length
    ? remainder
      ? slidesToShow - remainder
      : 0
    : slidesToShow

  const placeholderList = [...new Array(placeholderQty)].map((_, index) => (
    <PlaceholderItemCarroussel key={index} height={height}>
      <img src={placeholderImage} />
      <div className="img-overlay"></div>
      <AddCircle fontSize="large"></AddCircle>
    </PlaceholderItemCarroussel>
  ))

  const getImageSrc = image => {
    if (image && image.cropImage && image.cropImage.id)
      return `/${image.cropImage.path}`
    return URL.createObjectURL(image.cropImage)
  }

  return (
    <React.Fragment>
      <UploadImageModal
        images={images}
        modalTitle={modalTitle}
        open={modalOpen}
        filesConfig={filesConfig}
        onImagesChanged={handleImagesChanged}
        onClose={() => {
          setModalOpen(false)
        }}
        setEditedImageList={setEditedImageList}
      />

      <ContentContainer>
        <SliderContainer>
          <CustomSlider ref={sliderRef} {...settings}>
            {images
              .map((image, index) => (
                <ItemCarroussel key={index} height={height}>
                  <img src={getImageSrc(image)} />
                </ItemCarroussel>
              ))
              .concat(placeholderList)}
          </CustomSlider>
          {!readOnly ? (
            <ImageOverlay onClick={() => handleOpenModal()} height={height}>
              <CreateIcon fontSize="large" />
            </ImageOverlay>
          ) : null}
        </SliderContainer>
        <NextArrow onClick={() => sliderRef.current.slickNext()} />
        <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
      </ContentContainer>
    </React.Fragment>
  )
}

export default Carroussel
