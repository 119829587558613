import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import { history, generatePrivatePath } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Marker, MarkerGreen, MarkerOrange, MarkerRed } from 'ui/atoms/Marker'
import { TableRow, TableCell } from 'ui/atoms/Table'
import TableActions from 'ui/atoms/TableActions'

import { ADMIN_ROUTES } from 'views/BackOffice/Subviews/Admin/constants/routes'

import DialogActivation from '../DialogActivation'

const getMarkerColor = situation => {
  switch (situation) {
    case 'PENDING':
    case 'IN_ACTIVATION':
      return MarkerOrange
    case 'ACTIVE':
      return MarkerGreen
    case 'DELETED':
    case 'INACTIVE':
    case 'REJECTED':
      return MarkerRed
    default:
  }
}

// @ts-ignore
const LabMarker = styled(Marker)`
  ${props => getMarkerColor(props.type)}
`
const Row = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [activation, setActivation] = useState(false)
  const { translate } = useContext(GlobalizationContext)
  const { item } = props

  const actionMap = {
    MANAGE: () => redirectToAdmin(item),
    ANALYSE: () => redirectToAnalyse(),
    VIEW: () => redirectToSolicitationView(),
    ACTIVATE: () => activateInstitution(),
    INACTIVATE: () => inactivateInstitution()
  }

  const rowActionList = () =>
    item.actions.map(action => {
      return {
        code: action,
        name: `ACTIONS_LIST.${action}`
      }
    })

  const handleRowAction = (code, row) => {
    actionMap[code](row)
  }

  const redirectToAdmin = item => {
    history.push(
      generatePrivatePath(ADMIN_ROUTES.INSTITUTIONS_EDIT, {
        pathParams: { institutionId: item.cnpj }
      })
    )
  }

  const redirectToAnalyse = () => {
    history.push(
      generatePrivatePath(ADMIN_ROUTES.INSTITUTION_ANALYSIS, {
        pathParams: { institutionId: item.cnpj }
      })
    )
  }

  const redirectToSolicitationView = () => {
    history.push(
      generatePrivatePath(`/institution-solicitation-view/${item.cnpj}`)
    )
  }

  const activateInstitution = () => {
    setActivation(true)
    setIsOpen(true)
  }

  const inactivateInstitution = () => {
    setActivation(false)
    setIsOpen(true)
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <LabMarker type={item.situation} />
        </TableCell>
        <TableCell>{`${item.initials} - ${item.social_reason}`}</TableCell>
        <TableCell>{item.cnpj}</TableCell>
        <TableCell>{`${item.activeResponsible}/${item.totalResponsible}`}</TableCell>
        <TableCell>{translate(`SITUATION_LIST.${item.situation}`)}</TableCell>
        <TableCell style={{ width: '10px' }}>
          <TableActions
            actionList={rowActionList()}
            handleActionSelected={code => handleRowAction(code, item)}
          />
        </TableCell>
      </TableRow>
      {isOpen && (
        <DialogActivation
          activation={activation}
          cnpj={item.cnpj}
          institutionName={item.social_reason}
          close={() => setIsOpen(false)}
          updateList={props.updateList}
        />
      )}
    </>
  )
}
export default Row
