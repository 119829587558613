import clsx from 'clsx'
import React from 'react'

import { ListContainer } from './style'

export * from './LaboratoryItem'
export * from './EquipmentItem'

const TYPE_CLASSES = {
  LIST: 'list',
  GRID: 'grid'
}

const List = ({ className, children, type, ...rest }) => {
  return (
    <ListContainer
      className={clsx(className, TYPE_CLASSES[type] || TYPE_CLASSES.GRID)}
      {...rest}
    >
      {children}
    </ListContainer>
  )
}

export default List
