import React, { useContext } from 'react'

import { Button, Grid } from '@material-ui/core'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const AdvertisePlatformActions = props => {
  const { classes } = props
  const { translate } = useContext(GlobalizationContext)

  return (
    <Grid
      container
      justify="flex-end"
      spacing={2}
      style={{ paddingTop: '2rem' }}
    >
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          type={'submit'}
          variant="contained"
          className={classes.baseButton}
        >
          {translate('PUBLIC_AREA_CONTACT_US_SEND')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default AdvertisePlatformActions
