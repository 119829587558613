export default class BreadcrumbUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('framework/store/slices/breadcrumbData.slice').breadcrumbActions} props.breadcrumbActions
   */
  constructor({ store, breadcrumbActions }) {
    this.store = store
    this.breadcrumbActions = breadcrumbActions
  }

  setBreadcrumbData(data) {
    this.store.dispatch(this.breadcrumbActions.setBreadcrumbData(data))
  }
}
