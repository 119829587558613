import React from 'react'

import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'

import ListNav from './Fragments/List'
import { useStyles } from './styles'

function Nav(props) {
  const { window, menuOpen, onMenuOpenChange } = props
  const classes = useStyles()
  const { drawerClose, drawerOpen, drawerPaperClose, drawerPaperOpen } = classes

  const handleDrawerToggle = () => {
    onMenuOpenChange(!menuOpen)
  }

  const container =
    window !== undefined
      ? () => window().document.gerElementById('mainContentContainer')
      : undefined

  return (
    <React.Fragment>
      {/* Mobile */}
      <Hidden mdUp>
        <Drawer
          open={menuOpen}
          onClose={handleDrawerToggle}
          PaperProps={{ style: { position: 'absolute', width: '300px' } }}
          BackdropProps={{ style: { position: 'absolute' } }}
          ModalProps={{
            keepMounted: true,
            container: container,
            style: { position: 'absolute' }
          }}
          variant="temporary"
        >
          <ListNav open={menuOpen} />
        </Drawer>
      </Hidden>

      {/* Desktop */}
      <Hidden smDown>
        <nav className={menuOpen ? drawerOpen : drawerClose}>
          <Drawer
            classes={{
              paper: menuOpen ? drawerPaperOpen : drawerPaperClose
            }}
            variant="permanent"
            open
          >
            <ListNav open={menuOpen} />
          </Drawer>
        </nav>
      </Hidden>
    </React.Fragment>
  )
}
export default Nav
