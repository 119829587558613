import React, { useContext } from 'react'

import { generatePrivatePath } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import { ADMIN_ROUTES } from 'views/BackOffice/Subviews/Admin/constants/routes'
import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'

import {
  InviteInstitution,
  InviteLaboratory,
  EmptyList,
  ComplementInstitution,
  ComplementLaboratory,
  ConfirmNotification,
  GroupedNotification
} from './NotificationType'
import { MenuListStyled } from './NotificationType/style'

const getRoles = (roles, translate) =>
  roles.map(role => translate(`USER:ROLE_SHORT.${role}`))

const defaultNotificationTransform = (notification, translate) => ({
  ...notification,
  userName: notification.author || '',
  permission: notification?.permission
    ? translate(`USER:ROLE_SHORT.${notification?.permission}`)
    : '',
  institutionName: `${notification?.cnpj?.initials} - ${notification?.cnpj?.social_reason}`,
  laboratoryName: `${notification?.laboratory?.initials} - ${notification?.laboratory?.name}`,
  situation: translate(`NOTIFICATIONS:PERMISSION.${notification?.situation}`),
  roles: getRoles(notification?.rolesAdmin, translate)
})

const genericNotifications = {
  EXCLUSION_OWN_USER_ADMIN: {
    key: 'EXCLUSION_OWN_USER_ADMIN',
    notification: defaultNotificationTransform
  },
  EXCLUSION_USER_OWN_PERMISSION_INSTITUTION: {
    key: 'USER_REMOVED_OWN_INSTITUTION_PERMISSION',
    notification: defaultNotificationTransform
  },
  EXCLUSION_USER_PERMISSION_INSTITUTION: {
    key: 'USER_PERMISSION_INSTITUTION_REMOVED',
    notification: defaultNotificationTransform
  },
  CHANGE_USER_PERMISSION_INSTITUTION: {
    key: 'USER_PERMISSION_INSTITUTION_CHANGED',
    notification: defaultNotificationTransform
  },
  CHANGE_USER_SITUATION_INSTITUTION: {
    key: 'USER_SITUATION_INSTITUTION_CHANGED',
    notification: defaultNotificationTransform
  },
  EXCLUSION_USER_OWN_PERMISSION_LABORATORY: {
    key: 'USER_REMOVED_OWN_LABORATORY_PERMISSION',
    notification: defaultNotificationTransform
  },
  EXCLUSION_USER_ADMIN: {
    key: 'EXCLUSION_USER_ADMIN',
    notification: defaultNotificationTransform
  },
  INVITE_USER_ADMIN: {
    key: 'INVITE_USER_ADMIN',
    notification: defaultNotificationTransform
  },
  EXCLUSION_USER_PERMISSION_LABORATORY: {
    key: 'USER_PERMISSION_LABORATORY_REMOVED',
    notification: defaultNotificationTransform
  },
  CHANGE_USER_PERMISSION_LABORATORY: {
    key: 'USER_PERMISSION_LABORATORY_CHANGED',
    notification: defaultNotificationTransform
  },
  CHANGE_USER_SITUATION_LABORATORY: {
    key: 'USER_LABORATORY_SITUATION_CHANGED',
    notification: defaultNotificationTransform
  },
  LABORATORY_EXCLUSION: {
    key: 'LABORATORY_EXCLUDED',
    notification: defaultNotificationTransform
  },
  REQUEST_SHARING_CHANGE_RESULT_ANALYSIS: {
    key: 'REQUEST_SHARING_CHANGE_RESULT_ANALYSIS',
    notification: defaultNotificationTransform,
    getLink: notification =>
      generatePrivatePath(REQUEST_SHARING_ROUTES.DETAILS, {
        pathParams: { id: notification?.idRequestSharing }
      }),
    confirmKey: 'VERIFY'
  },
  INSTITUTION_INACTIVATION: {
    key: 'INSTITUTION_INACTIVATION',
    notification: defaultNotificationTransform
  },
  REFUSE_INVITE_LABORATORY: {
    key: 'REFUSE_INVITE_LABORATORY',
    notification: defaultNotificationTransform
  }
}

const groupedNotifications = {
  CREATE_LAB: {
    key: 'CREATE_LAB',
    notification: defaultNotificationTransform,
    link: generatePrivatePath('/laboratory')
  },
  LABORATORY_ANALYSIS: {
    key: 'MODIFICATION_LAB',
    notification: defaultNotificationTransform,
    link: generatePrivatePath('/laboratory')
  },
  REQUEST_SHARING_ANALYSIS: {
    key: 'REQUEST_SHARING_ANALYSIS',
    notification: defaultNotificationTransform,
    link: generatePrivatePath(REQUEST_SHARING_ROUTES.LIST)
  },
  USAGE_REQUEST: {
    key: 'USAGE_REQUEST',
    notification: defaultNotificationTransform,
    link: generatePrivatePath(REQUEST_SHARING_ROUTES.LIST)
  },
  REQUEST_SHARING_CANCELLATION: {
    key: 'REQUEST_SHARING_CANCELLATION',
    notification: defaultNotificationTransform,
    link: generatePrivatePath(REQUEST_SHARING_ROUTES.LIST)
  },
  INSTITUTION_ANALYSIS: {
    key: 'INSTITUTION_ANALYSIS',
    notification: defaultNotificationTransform,
    link: generatePrivatePath(ADMIN_ROUTES.INSTITUTIONS)
  }
}

const notifications = {
  INVITE_INSTITUTION: InviteInstitution,
  INVITE_LABORATORY: InviteLaboratory,
  COMPLETE_REGISTRATION_DATA_INSTITUTION: ComplementInstitution,
  COMPLETE_REGISTRATION_DATA_LABORATORY: ComplementLaboratory
}

const NotificationList = ({
  className,
  notificationList,
  emptyListMessage,
  onItemClicked = () => {},
  ...rest
}) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <MenuListStyled className={className} {...rest}>
      {notificationList.map(function (notificationItem) {
        if (notifications[notificationItem.type]) {
          const Notification = notifications[notificationItem.type]
          return (
            <Notification
              key={notificationItem.id}
              notification={notificationItem}
              itemClicked={onItemClicked}
              {...rest}
            />
          )
        } else if (genericNotifications[notificationItem.type]) {
          const { notification, key, confirmKey, getLink } =
            genericNotifications[notificationItem.type]

          const parsedNotification = notification(notificationItem, translate)

          return (
            <ConfirmNotification
              key={parsedNotification.id}
              notification={parsedNotification}
              itemClicked={onItemClicked}
              titleKey={`${key}_TITLE`}
              messageKey={key}
              confirmKey={confirmKey}
              link={getLink && getLink(parsedNotification)}
              {...rest}
            />
          )
        } else if (groupedNotifications[notificationItem.type]) {
          const groupedNotification =
            groupedNotifications[notificationItem.type]
          return (
            <GroupedNotification
              key={notificationItem.type}
              notification={groupedNotification.notification(
                notificationItem,
                translate
              )}
              link={groupedNotification.link}
              itemClicked={onItemClicked}
              titleKey={`${groupedNotification.key}_TITLE`}
              messageKey={groupedNotification.key}
              confirmKey="OK"
              {...rest}
            />
          )
        } else {
          return null
        }
      })}
      {!notificationList.length && (
        <EmptyList emptyListMessage={emptyListMessage} />
      )}
    </MenuListStyled>
  )
}

export default NotificationList
