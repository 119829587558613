import axios from 'axios'
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef
} from 'react'
import { useDebounce } from 'use-debounce/lib'

import { makeStyles } from '@material-ui/core/styles'

import { SharingService } from 'api'
import { history, generatePrivatePath } from 'helpers/history'
import useQueryString from 'helpers/useQueryString'

import BaseButton from 'ui/atoms/BasePrivate/BaseButton'
import FilterSelect from 'ui/atoms/FilterSelect'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { useProfiles } from 'ui/atoms/useProfiles'
import wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Loader from 'ui/molecules/Loader'

import { notificationUseCases } from 'views/Notification/providers'

import { REQUEST_SHARING_ROUTES } from '../../constants/routes'
import BodyFilter from './Fragments/BodyFilter'
import List from './Fragments/List'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

const rowsPerPageList = [10, 20, 50, 100]

function ListRecords() {
  const cancelToken = useRef(axios.CancelToken.source())
  const { translate } = useContext(GlobalizationContext)
  const [recordList, setRecordList] = useState([])

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [count, setCount] = useState(0)
  const [pagination, setPagination] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  const [filterProfile, setFilterProfile] = useState('')
  const [filterValue, setFilterValue] = useQueryString('search')
  const [filterSituation, setFilterSituation] = useState('')
  const [debouncedFilter] = useDebounce(filterValue, 350)

  const [profileList] = useProfiles('REQUEST_SHARING')

  const emptyList = Boolean(pagination.empty)

  const [recordToExclude, setRecordToExclude] = useState(null)

  const classes = useStyles()

  const getList = useCallback(() => {
    cancelToken.current.cancel()

    const filter = {
      ...(filterProfile ? { permission: filterProfile } : {}),
      ...(filterSituation ? { situation: filterSituation } : {}),
      ...(debouncedFilter ? { search: debouncedFilter } : {})
    }

    SharingService.getAll(
      {
        pageSize,
        pageNumber,
        filter
      },
      { cancelToken: cancelToken.current.token }
    )
      .then(resp => {
        const { content, ...pagination } = resp.data
        setRecordList(content)
        setPagination(pagination)

        setCount(pagination.totalElements)
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request cancelled')
        }
        console.log(error)
      })
  }, [
    cancelToken,
    filterProfile,
    filterSituation,
    debouncedFilter,
    pageSize,
    pageNumber
  ])

  useEffect(() => {
    getList()
  }, [getList])

  const handleSelectChange = event => {
    setFilterSituation(event.target.value)
  }

  const handleInputChange = event => {
    setFilterValue(event.target.value)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  const deleteRecord = async item => {
    try {
      await SharingService.delete(item.id)
      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      setRecordToExclude(null)
      handleRowChange()
    } catch (error) {
      notificationUseCases.newError(error)
    }
  }

  const handleExclude = async item => {
    setRecordToExclude(item)
  }

  const handleRowAction = {
    EXCLUDE: handleExclude
  }

  const handleRowChange = (action, item) => {
    if (!action) {
      getList()
    } else {
      handleRowAction[action] && handleRowAction[action](item)
    }
  }

  const handleAddNew = () => {
    history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.NEW))
  }

  return (
    <>
      <Loader />
      <DialogConfirm
        dialogText="LIST.CONFIRM_EXCLUDE"
        open={Boolean(recordToExclude)}
        onConfirm={() => deleteRecord(recordToExclude)}
        onCancel={() => setRecordToExclude(null)}
      />

      <BaseContainerHeader
        label={translate('LIST.TITLE')}
        showCloseButton={false}
        filter={
          <FilterSelect
            label={translate('LIST.SELECT_PROFILE')}
            value={filterProfile}
            defaultValue={'Todos'}
            options={profileList}
            translateOptions={true}
            onChange={event => {
              setFilterProfile(event.target.value)
            }}
          />
        }
      />
      <BodyFilter
        filterValue={filterValue}
        onSelectChange={handleSelectChange}
        onInputChange={handleInputChange}
      />
      <BaseButton
        name="REQUEST_NEW_SHARING"
        label={translate('LIST.NEW_ITEM_BUTTON')}
        onClick={handleAddNew}
      />
      <List
        classes={classes}
        rowsPerPageOptions={rowsPerPageList}
        count={count}
        rowsPerPage={pageSize}
        page={pageNumber}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        list={recordList}
        emptyList={emptyList}
        onRowAction={handleRowChange}
      />
    </>
  )
}
export default wrapper(ListRecords, {
  namespace: 'REQUEST_SHARING'
})
