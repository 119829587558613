import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

import Grid from '@material-ui/core/Grid'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { NoResults } from 'ui/atoms/Table'
import Pagination from 'ui/molecules/commons/Pagination'

import ListType from './Fragments/ListType'
import { ListTypeWrapper } from './style'

const PaginatedList = ({
  hideTypeControls,
  rowsPerPageList,
  listType,
  onListTypeChange,
  pageGetter,
  children
}) => {
  const prevPageGetterRef = useRef(pageGetter)

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [pageCount, setPageCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  const [itemList, setItemList] = useState([])
  const { translate } = useContext(GlobalizationContext)

  useEffect(() => {
    setPageNumber(0)
  }, [pageGetter])

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  const getPagePromise = useCallback(async () => {
    try {
      if (prevPageGetterRef.current !== pageGetter) {
        prevPageGetterRef.current = pageGetter
        if (pageNumber !== 0) {
          setPageNumber(0)
          return
        }
      }

      const { content, ...pagination } = await pageGetter({
        pageSize,
        pageNumber
      })

      setItemList(content || [])
      setPageCount(pagination.totalElements)
      return content || []
    } catch (error) {
      console.log(error)
    }
  }, [pageGetter, pageNumber, pageSize])

  const showPagination = rowsPerPageList[0] < pageCount && itemList.length

  return (
    <>
      {showPagination || !hideTypeControls ? (
        <ListTypeWrapper>
          {!hideTypeControls ? (
            <ListType listType={listType} onListTypeChange={onListTypeChange} />
          ) : null}
          {showPagination ? (
            <Pagination
              rowsPerPageOptions={rowsPerPageList}
              count={pageCount}
              rowsPerPage={pageSize}
              page={pageNumber}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </ListTypeWrapper>
      ) : null}

      <AsyncLoad promiseFn={getPagePromise} minHeight={150}>
        {itemList.length ? (
          typeof children === 'function' ? (
            children({ itemList, listType })
          ) : (
            children
          )
        ) : (
          <NoResults>{translate('NO_RESULTS')}</NoResults>
        )}
      </AsyncLoad>
      {showPagination ? (
        <Grid item xs={12}>
          <Pagination
            rowsPerPageOptions={rowsPerPageList}
            count={pageCount}
            rowsPerPage={pageSize}
            page={pageNumber}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      ) : null}
    </>
  )
}

export default PaginatedList
