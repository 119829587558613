import React, { useCallback, useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const getCroppedImg = (image, crop) => {
  const canvas = document.createElement('canvas')

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const ctx = canvas.getContext('2d')
  const pixelRatio = window.devicePixelRatio

  canvas.width = crop.width * pixelRatio * scaleX
  canvas.height = crop.height * pixelRatio * scaleY

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = 'high'

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  )

  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        if (!blob) {
          console.error('Canvas is empty')
          return
        }
        resolve(blob)
      },
      'image/png',
      1
    )
  })
}

const ImageCrop = ({ image, imagePosition, updateCroppedImage }) => {
  const [crop, setCrop] = useState(null)
  const [currentImg, setCurrentImg] = useState(null)

  const onLoad = useCallback(
    image => {
      setCrop({
        unit: 'px',
        width: 540,
        height: 303,
        x: imagePosition.x ? parseFloat(imagePosition.x) : 0,
        y: imagePosition.y ? parseFloat(imagePosition.y) : 0
      })

      setCurrentImg(image)
      return false // Return false when setting crop state in here.
    },
    [imagePosition.x, imagePosition.y]
  )

  const onCompleteCrop = async crop => {
    const croppedImage = await getCroppedImg(currentImg, crop)

    updateCroppedImage(crop, croppedImage)
  }
  return (
    <div>
      <ReactCrop
        style={{ width: '100%' }}
        imageStyle={{ width: '100%' }}
        src={image}
        crop={crop}
        onChange={c => setCrop(c)}
        onImageLoaded={onLoad}
        onComplete={c => onCompleteCrop(c)}
        locked={true}
      />
    </div>
  )
}

export default ImageCrop
