import OriginalNotificationList from 'ui/molecules/Header/Private/Notification/NotificationList'
import styled from 'styled-components'

export const UserData = styled.div`
  min-height: 80px;
  background: #fafafa;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`

export const Picture = styled.div`
  border-radius: 50%;
  flex: 0 0 44px;
  width: 44px;
  height: 44px;
  background: #c4c4c4;
`

export const UserDataList = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;

  padding: 10px 0;
  margin: -5px 0;
`

export const UserDataListItem = styled.div`
  margin: 5px 20px;

  &:before {
    content: '${props => props.title}';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
  }

  &:after {
    content: '${props => props.value}';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
`

export const UserEdit = styled

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const ContainerMessages = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  flex: 1 1 100%;
  background: #fafafa;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 30px;
`

export const ContainerFilter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 256px;
`

export const NotificationList = styled(OriginalNotificationList)`
  width: 100%;
  margin: 0;

  .notification-container {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:not(:first-child),
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`
