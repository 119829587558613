import styled from 'styled-components'

export const TextContainer = styled.div`
  display: flex;
`

export const Label = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-left: 20px;
  margin-right: 20px;
`
