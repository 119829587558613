import moment from 'moment'
import React, { useContext } from 'react'

import { Grid, Typography } from '@material-ui/core'

import { phone } from 'helpers/generalInputMasks'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle } from 'ui/atoms/Title'

const Finished = ({ sharingRequest }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <Grid container spacing={2}>
        <Subtitle item xs={12}>
          {translate('DETAILS.FINISHED.TITLE')}
        </Subtitle>

        <Subtitle item xs={12} $paddingTop $noBorder={false}>
          {translate('DETAILS.FINISHED.SHARING_DATA')}
        </Subtitle>

        <Grid item>
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} sm="auto">
                <Typography variant="h4">
                  {translate('DETAILS.FINISHED.EQUIPMENTS')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Typography variant="body2">
                  {sharingRequest.specificEquipment
                    .map(item => item.name)
                    .join('; ')}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} sm="auto">
                <Typography variant="h4">
                  {translate('DETAILS.FINISHED.RESPONSIBLE')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Typography variant="body2">
                  {sharingRequest.responsibleName}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} sm="auto">
                <Typography variant="h4">
                  {translate('DETAILS.FINISHED.EMAIL')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Typography variant="body2">
                  {sharingRequest.responsibleEmail}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} sm="auto">
                <Typography variant="h4">
                  {translate('DETAILS.FINISHED.PHONE')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Typography variant="body2">
                  {phone(sharingRequest.responsibleTelephone || '')}
                </Typography>
              </Grid>
            </Grid>

            {sharingRequest.suggestedDateFrom &&
            sharingRequest.suggestedDateTo ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} sm="auto">
                  <Typography variant="h4">
                    {translate('DETAILS.FINISHED.DATE_LABEL')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Typography variant="body2">
                    {translate('DETAILS.FINISHED.DATE', {
                      from: moment(sharingRequest.suggestedDateFrom).format(
                        'DD/MM/YYYY'
                      ),
                      to: moment(sharingRequest.suggestedDateTo).format(
                        'DD/MM/YYYY'
                      )
                    })}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Finished
