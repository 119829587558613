import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

import { DefaultLoader } from '../AsyncLoad/DefaultLoader'

export const GlobalizationContext = React.createContext(null)

export const useGlobalizationContext = () => useContext(GlobalizationContext)

export const Globalization = ({ namespace, children }) => {
  const theme = useContext(ThemeContext)
  const defaultNamespaces = ['COMMONS', 'VALIDATION', 'ERRORS', 'OLD_DEFAULT']
  const namespaceList = [].concat(namespace, defaultNamespaces)

  try {
    const [t, i18n] = useTranslation(namespaceList)

    return (
      <GlobalizationContext.Provider value={{ translate: t, i18n }}>
        {children}
      </GlobalizationContext.Provider>
    )
  } catch (error) {
    return <DefaultLoader color={theme.colors.primary[500]} />
  }
}
// eslint-disable-next-line react/display-name
export const globalization = (Component, namespace) => props => {
  return (
    <Globalization namespace={namespace}>
      <Component {...props} />
    </Globalization>
  )
}
