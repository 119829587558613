import React, { useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'

import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import { BaseSelect } from 'ui/atoms/BaseSelect'
import { BaseTextField } from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import { equipmentUseCases } from '../../../Equipment/providers'

const hasCustomType = (situation, situationList) => {
  return Boolean(
    situationList?.find(item => item.idReason === situation && item.custom)
  )
}

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

const ActionDialogSchema = Yup.object().shape({
  idReason: Yup.number()
    .required('VALIDATION:REQUIRED_ERROR')
    .transform(emptyStringToNull)
    .nullable(),
  customReason: Yup.string().when(['idReason', '$actionList'], {
    is: (id, actionList) => {
      return hasCustomType(id, actionList)
    },
    then: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
    otherwise: Yup.string().trim()
  })
})

export const DIALOG_ACTIONS = {
  exclude: 'EXCLUDE_ACTION',
  inactivate: 'INACTIVATE_ACTION',
  replicate: 'REPLICATE_ACTION'
}

const paramsByAction = {
  INACTIVATE_ACTION: 'INACTIVATION',
  EXCLUDE_ACTION: 'EXCLUSION'
}

function ActionDialog({
  equipmentAction,
  onCloseDialog: handleCloseDialog,
  onDialogAction: handleDialogAction
}) {
  const { translate } = useContext(GlobalizationContext)
  const [actionList, setActionList] = useState([])
  const [actionExcludeButton, setActionExcludeButton] = useState(false)

  const classes = useStyles()

  const resolver = useYupValidationResolver(ActionDialogSchema, { translate })
  const { watch, errors, handleSubmit, control } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {
      idReason: '',
      customReason: '',
      description: ''
    },
    context: {
      actionList
    }
  })

  useEffect(() => {
    if (!equipmentAction?.action) return

    const typeReason = paramsByAction[equipmentAction.action]
    const excludeButton =
      equipmentAction.action === DIALOG_ACTIONS.exclude ? true : false

    equipmentUseCases.getListStateReasons({ typeReason }).then(response => {
      setActionList(response)
      setActionExcludeButton(excludeButton)
    })
  }, [equipmentAction])

  const onSubmit = formData => {
    handleDialogAction({
      data: {
        customReason: '',
        ...formData
      },
      ...equipmentAction
    })
  }

  const idReason = watch('idReason')
  const isCustom = actionList.find(
    item => item.idReason == idReason && item.custom
  )

  return (
    <Dialog
      open={Boolean(equipmentAction)}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
      maxWidth={'xs'}
      fullWidth={true}
    >
      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <DialogTitle id="form-dialog-title">
          {equipmentAction?.title}
        </DialogTitle>

        <DialogContent>
          <Grid container justify="flex-end" style={{ marginBottom: '15px' }}>
            <BaseSelect
              required={true}
              placeholder={translate('REQUEST_OPINION_SELECT')}
              label={translate('EQUIPMENT_DIALOG_MOTIVE')}
              errors={errors}
              control={control}
              name="idReason"
              idKey="idReason"
              descriptionKey="description"
              options={actionList}
            ></BaseSelect>
          </Grid>
          {isCustom && (
            <Grid container justify="flex-end" style={{ marginBottom: '15px' }}>
              <BaseTextField
                label={translate('EQUIPMENT_DIALOG_INFORM_MOTIVE')}
                control={control}
                name="customReason"
                maxLength={255}
                required={true}
                errors={errors}
              ></BaseTextField>
            </Grid>
          )}
          <Grid container justify="flex-end" style={{ marginBottom: '15px' }}>
            <BaseTextField
              label={translate('EQUIPMENT_DIALOG_DETAIL_MOTIVE')}
              control={control}
              name="description"
              maxLength={255}
              rows={5}
            ></BaseTextField>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.baseButtonGrey}
            onClick={handleCloseDialog}
            variant="contained"
          >
            {translate('REGISTER_INSTITUTION_CANCEL')}
          </Button>
          {actionExcludeButton ? (
            <Button
              className={classes.baseButton}
              type={'submit'}
              variant="contained"
            >
              {translate('REGISTER_EQUIPMENT_SAVE')}
            </Button>
          ) : (
            <Button
              className={classes.baseButton}
              type={'submit'}
              variant="contained"
            >
              {translate('REGISTER_EQUIPMENT_SAVE')}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ActionDialog
