import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Link as MuiLink } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import { EquipmentService } from 'api'
import { generatePrivatePath } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { TableContainer, Table, TableBody, NoResults } from 'ui/atoms/Table'
import { TableHead, TableRow, TableCell } from 'ui/atoms/Table'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Pagination from 'ui/molecules/commons/Pagination'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'
import { notificationUseCases } from 'views/Notification/providers'

import ActionDialog, { DIALOG_ACTIONS } from '../Dialog'
import Row from './Row'
import ReplicateEquipments from './Row/Fragments/ReplicateEquipments'

const List = ({
  classes,
  equipmentList,
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  emptyList,
  rowsPerPageOptions,
  onRowAction: handleRowAction,
  selected,
  handleSelectClick,
  handleSelectAllClick,
  shouldShowSelect = false
}) => {
  const { translate } = useContext(GlobalizationContext)

  const showPagination = rowsPerPageOptions[0] < count
  const [actionModal, setActionModal] = useState(null)
  const [replicateRow, setReplicateRow] = useState(null)
  const [excludeEquip, setExcludeEquip] = useState(null)
  const [activateEquip, setActivateEquip] = useState(null)
  const [equipmentHasSharing, setEquipmentHasSharing] = useState(null)

  const paginationComponent = showPagination ? (
    <Pagination
      rowsPerPageOptions={rowsPerPageOptions}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  ) : null

  const handleTableAction = useCallback(
    (action, equipment) => {
      let title = ''

      if (action === DIALOG_ACTIONS.exclude) {
        title = translate('EQUIPMENT_DIALOG_EXCLUDE_TITLE')
      }
      if (action === DIALOG_ACTIONS.inactivate) {
        title = translate('EQUIPMENT_DIALOG_INACTIVE_TITLE')
      }
      if (action === DIALOG_ACTIONS.replicate) {
        title = translate('EQUIPMENT_DIALOG_REPLICATE_TITLE')
      }

      setActionModal({ action, title, equipment })
    },
    [translate]
  )

  const handleActivationModal = useCallback(equip => {
    setActivateEquip(equip)
  }, [])

  const onActionError = error => {
    if (error?.response?.data?.message === 'request.sharing.found') {
      setEquipmentHasSharing(actionModal)
    } else {
      notificationUseCases.newError(error)
    }
    setExcludeEquip(null)
    setActionModal(null)
  }

  const handleDialogAction = async ({ data, equipment, action }) => {
    if (action === DIALOG_ACTIONS.inactivate) {
      try {
        await EquipmentService.inactivate(equipment.id, data)
        handleRowAction()
        setActionModal(null)
      } catch (error) {
        console.log(error)
        onActionError(error)
      }
    }
    if (action === DIALOG_ACTIONS.exclude) {
      setExcludeEquip(data)
    }
  }

  const handleDeleteConfirm = async () => {
    try {
      await EquipmentService.exclude(actionModal.equipment.id, excludeEquip)
      handleRowAction()
    } catch (error) {
      console.log(error)
      onActionError(error)
    }
    setExcludeEquip(null)
    setActionModal(null)
  }

  const handleDelete = useCallback(
    equipment => {
      handleTableAction(DIALOG_ACTIONS.exclude, equipment)
    },
    [handleTableAction]
  )

  const handleReplicate = useCallback(equipment => {
    setReplicateRow(equipment)
  }, [])

  const handleInactivate = useCallback(
    equipment => {
      handleTableAction(DIALOG_ACTIONS.inactivate, equipment)
    },
    [handleTableAction]
  )

  const handleActivate = async () => {
    await EquipmentService.activate(activateEquip?.id)
    handleRowAction()
    setActivateEquip(null)
    notificationUseCases.newSuccess('DEFAULT_SUCCESS')
  }

  const rowActionMap = useMemo(
    () => ({
      EXCLUDE: handleDelete,
      COPY: handleReplicate,
      INACTIVATE: handleInactivate,
      ACTIVATE: handleActivationModal
    }),
    [handleActivationModal, handleDelete, handleInactivate, handleReplicate]
  )

  const handleAction = (action, item) => {
    if (!action) {
      handleRowAction()
    } else {
      rowActionMap[action](item)
    }
  }

  const tableRowList = equipmentList.map(function (row) {
    const isItemSelected = shouldShowSelect ? selected(row.id) : false
    return (
      <Row
        item={row}
        key={row.id}
        onRowAction={handleAction}
        selected={isItemSelected}
        handleClick={handleSelectClick}
        shouldShowSelect={shouldShowSelect}
      />
    )
  })

  return (
    <>
      <DialogConfirm
        hideCancelButton
        title={translate('COMMONS:WARNING')}
        open={equipmentHasSharing}
        labelConfirmButton={translate('COMMONS:CLOSE')}
        onConfirm={() => setEquipmentHasSharing(null)}
      >
        {equipmentHasSharing ? (
          <Trans
            t={translate}
            i18nKey="EQUIPMENT_LIST_EQUIPMENT_HAS_SHARING"
            values={{
              equipment: `${equipmentHasSharing?.equipment?.code} - ${equipmentHasSharing?.equipment?.name}`,
              action: translate(
                `EQUIPMENT_LIST_EQUIPMENT_HAS_SHARING_ACTION.${equipmentHasSharing?.action}`
              )
            }}
            components={{
              SharingLink: (
                <MuiLink
                  component={Link}
                  to={generatePrivatePath(REQUEST_SHARING_ROUTES.LIST, {
                    query: {
                      search: equipmentHasSharing?.equipment?.code
                    }
                  })}
                />
              )
            }}
          />
        ) : null}
      </DialogConfirm>
      <ActionDialog
        equipmentAction={actionModal}
        onCloseDialog={() => {
          setActionModal(null)
        }}
        onDialogAction={handleDialogAction}
      />
      <DialogConfirm
        dialogText="ACTIVATE_EQUIPMENT_DIALOG"
        open={Boolean(activateEquip)}
        onConfirm={handleActivate}
        onCancel={() => {
          setActionModal(null)
          setActivateEquip(null)
        }}
      />
      <DialogConfirm
        dialogText="EXCLUDE_EQUIPMENT_DIALOG"
        open={Boolean(excludeEquip)}
        onConfirm={handleDeleteConfirm}
        onCancel={() => {
          setActionModal(null)
          setExcludeEquip(null)
        }}
      />

      <ReplicateEquipments
        equipment={replicateRow}
        onAction={() => {
          handleRowAction()
          setReplicateRow(null)
        }}
        onClose={() => setReplicateRow(false)}
      />

      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={12}>
          {!emptyList ? (
            <>
              {paginationComponent}
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {shouldShowSelect && (
                        <TableCell>
                          <Checkbox
                            onChange={event => handleSelectAllClick(event)}
                          />
                        </TableCell>
                      )}
                      <TableCell></TableCell>
                      <TableCell>
                        {translate('EQUIPMENT_LIST_TABLE_CODE')}
                      </TableCell>
                      <TableCell>
                        {translate('EQUIPMENT_LIST_TABLE_NAME')}
                      </TableCell>
                      <TableCell>
                        {translate('EQUIPMENT_LIST_TABLE_BRAND')}
                      </TableCell>
                      <TableCell>
                        {translate('EQUIPMENT_LIST_TABLE_MODEL')}
                      </TableCell>
                      <TableCell>
                        {translate('EQUIPMENT_LIST_TABLE_MANUFACTURE')}
                      </TableCell>
                      <TableCell>
                        {translate('EQUIPMENT_LIST_TABLE_LABORATORY')}
                      </TableCell>
                      <TableCell />
                      <TableCell style={{ width: '10px' }}>
                        {translate('ACTIONS')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{tableRowList}</TableBody>
                </Table>
              </TableContainer>
              {paginationComponent}
            </>
          ) : (
            <Grid item style={{ marginTop: '20px' }}>
              <NoResults>{translate('COMMONS:NO_RESULTS')}</NoResults>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default List
