import React, { useContext } from 'react'

import { Grid, Typography } from '@material-ui/core'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'

const LastPendingCompletionAnalysis = ({ initialData }) => {
  const { translate } = useContext(GlobalizationContext)

  if (!initialData.lastPendingCompletionAnalysis) return null

  const {
    detailInfo,
    responsibleName
  } = initialData.lastPendingCompletionAnalysis

  return (
    <Grid container spacing={3} style={{ marginBottom: '20px' }}>
      <Title variant="h3" item xs={12} $noPaddingBottom $noBorder>
        {translate(`ANALYZE.COMPLEITION_DATA.TITLE`)}
      </Title>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm="auto">
          {translate('ANALYZE.COMPLEITION_DATA.DESCRIPTION')}
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm="auto">
          <Typography variant="h4">
            {translate('ANALYZE.COMPLEITION_DATA.RESPONSIBLE')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="body2">{responsibleName}</Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm="auto">
          <Typography variant="h4">
            {translate('ANALYZE.COMPLEITION_DATA.DETAILS')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="body2">{detailInfo}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LastPendingCompletionAnalysis
