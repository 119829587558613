import * as Yup from 'yup'

export default Yup.object().shape({
  institutionShouldBePartOfPlatform: Yup.string().required(),
  requesterIsMaxResponsible: Yup.string().required(),
  refusalDetail: Yup.string().when(
    ['institutionShouldBePartOfPlatform', 'requesterIsMaxResponsible'],
    {
      is: (institutionShouldBePartOfPlatform, requesterIsMaxResponsible) =>
        institutionShouldBePartOfPlatform === 'NO' ||
        requesterIsMaxResponsible === 'NO',
      then: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
      otherwise: Yup.string().notRequired()
    }
  )
})
