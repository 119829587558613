import parseDate from 'date-fns/parse'
import { compact } from 'lodash'
import { conformToMask } from 'react-text-mask'

import { CPF_MASK } from 'helpers/constants'
import { phone } from 'helpers/generalInputMasks'

import {
  deleteFilesInSequence,
  uploadFilesInSequence
} from 'ui/atoms/DocumentUploader'

export class FileUploadError extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'FileUploadError'
  }
}

export default class RequestSharingUseCases {
  /**
   * @param {object} props
   * @param {import('api/SharingService').SharingService} props.SharingService
   * @param {import('views/Notification/providers/notification.useCases').default} props.notificationUseCases
   */
  constructor({ SharingService, notificationUseCases }) {
    this.sharingService = SharingService
    this.notificationUseCases = notificationUseCases
  }

  async getRefusalReasons() {
    return await this.sharingService.getRefusalReasons({ skipLoader: true })
  }

  async getById(id) {
    return await this.sharingService.getById(id, {
      skipLoader: true
    })
  }

  async getLastPendingCompletionAnalysis(id, config) {
    return await this.sharingService.getLastPendingCompletionAnalysis(
      id,
      config
    )
  }

  async getSituation(id) {
    return await this.sharingService.getSituation(id, {
      skipLoader: true
    })
  }

  async getParams() {
    const [securityHazardList] = await Promise.all([
      this.sharingService.getSecurityHazards({ skipLoader: true })
    ])

    return { securityHazardList }
  }

  async getLab(term) {
    return await this.sharingService.getLab(term, { skipLoader: true })
  }

  async getInstitution(term) {
    return await this.sharingService.getInstitution(term, { skipLoader: true })
  }

  async getLabById(labId, config = {}) {
    const { skipLoader = false } = config
    return await this.sharingService.getByLabId(labId, { skipLoader })
  }

  /**
   * @param {"lab"|"equip"} type
   * @param {number} typeId
   */
  async getInitialData({ type, typeId, userData }) {
    let currentLaboratory

    if (type === 'lab' && typeId) {
      currentLaboratory = await this.sharingService.getByLabId(typeId, {
        skipLoader: true
      })
    } else if (type === 'equip' && typeId) {
      currentLaboratory = await this.sharingService.getByEquipId(typeId, {
        skipLoader: true
      })
    } else {
      currentLaboratory = null
    }

    let initialFormData = {
      requesterCPF: conformToMask(userData.cognitoUsername || '', CPF_MASK)
        .conformedValue,
      requesterFirstName: userData.firstName,
      requesterLastName: userData.lastName,
      requesterEmail: userData.email,
      requesterPhone: phone(userData.telephone || '')
    }

    if (currentLaboratory) {
      const laboratory = {
        id: currentLaboratory.id,
        initials: currentLaboratory.initials,
        name: currentLaboratory.name,
        responsibleName: currentLaboratory.responsibleName
      }

      initialFormData = { ...initialFormData, laboratory }
      const specificEquipment =
        type === 'equip'
          ? currentLaboratory.equipmentList.find(item => item.id == typeId)
          : null

      if (specificEquipment) {
        initialFormData = {
          ...initialFormData,
          requiresSpecificEquipment: 'true',
          specificEquipment: [specificEquipment]
        }
      }
    }

    return { initialFormData, currentLaboratory }
  }

  async save({ requestData, additionalInfoDocs, mdsDocs }) {
    let sharingRequestCreated = false
    try {
      const response = await this.sharingService.postSharing(requestData)
      sharingRequestCreated = true
      await uploadFilesInSequence(
        this.sharingService.uploadAdditionalInfo,
        response.id,
        additionalInfoDocs || []
      )

      await uploadFilesInSequence(
        this.sharingService.uploadMDS,
        response.id,
        mdsDocs || []
      )
    } catch (error) {
      if (!sharingRequestCreated) {
        throw error
      } else {
        throw new FileUploadError(error)
      }
    }
  }

  async updateRequest({
    id,
    requestData,
    additionalInfoDocs,
    deletedAdditionalInfoDocs,
    mdsDocs,
    deletedMdsDocs
  }) {
    let sharingRequestCreated = false
    try {
      const response = await this.sharingService.putSharing(id, requestData)
      sharingRequestCreated = true

      await deleteFilesInSequence(
        this.sharingService.deleteDocument,
        deletedAdditionalInfoDocs || []
      )

      await deleteFilesInSequence(
        this.sharingService.deleteDocument,
        deletedMdsDocs || []
      )

      await uploadFilesInSequence(
        this.sharingService.uploadAdditionalInfo,
        response.id,
        additionalInfoDocs || []
      )

      await uploadFilesInSequence(
        this.sharingService.uploadMDS,
        response.id,
        mdsDocs || []
      )
    } catch (error) {
      if (!sharingRequestCreated) {
        throw error
      } else {
        throw new FileUploadError(error)
      }
    }
  }

  async getLastPendingCompetion(id) {
    try {
      const response = await this.sharingService.getLastPendingCompletionAnalysis(
        id,
        {
          skipLoader: true
        }
      )

      return response
    } catch (error) {
      if (
        error.response?.data?.message === 'request.sharing.analysis.not.found'
      ) {
        return null
      }
      throw error
    }
  }

  async getRequestData(id) {
    let sharingData = await this.getById(id)

    const lastPendingCompletionAnalysis = await this.getLastPendingCompetion(id)

    const currentLaboratory = await this.getLabById(sharingData.laboratory.id, {
      skipLoader: true
    })

    sharingData.requiresSpecificEquipment = String(
      sharingData.requiresSpecificEquipment
    )

    sharingData.hasExperienceWithTechnique = String(
      sharingData.hasExperienceWithTechnique
    )

    sharingData.securityHazardList = sharingData.securityHazardList.map(item =>
      String(item.id)
    )

    sharingData.institution = {
      social_reason: sharingData.institution,
      cnpj: sharingData.institutionCNPJ
    }

    sharingData.isPublic = String(sharingData.isPublic)

    sharingData.suggestedDateFrom = parseDate(
      sharingData.suggestedDateFrom,
      'dd/MM/yyyy',
      new Date()
    )
    sharingData.suggestedDateTo = parseDate(
      sharingData.suggestedDateTo,
      'dd/MM/yyyy',
      new Date()
    )

    const removedEquipments = []
    if (sharingData.specificEquipment?.length) {
      sharingData.specificEquipment = compact(
        sharingData.specificEquipment.map(equip => {
          const equipFound = currentLaboratory.equipmentList.find(
            item => item.id == equip.id
          )

          if (!equipFound) {
            removedEquipments.push(equip)
          }

          return equipFound
        })
      )
    }

    return {
      initialFormData: sharingData,
      situation: sharingData.situation,
      removedEquipments,
      currentLaboratory,
      lastPendingCompletionAnalysis
    }
  }

  async reproveSharing(id, requestData) {
    try {
      const response = await this.sharingService.refuseSharing(id, requestData)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      return response
    } catch (error) {
      this.notificationUseCases.newError(error)
      throw error
    }
  }

  async approveSharing(id, requestData) {
    try {
      const response = await this.sharingService.approveSharing(id, requestData)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      return response
    } catch (error) {
      this.notificationUseCases.newError(error)
      throw error
    }
  }

  async updateAnalisys(id, requestData) {
    try {
      const response = await this.sharingService.editApproval(id, requestData)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      return response
    } catch (error) {
      this.notificationUseCases.newError(error)
      throw error
    }
  }

  async confirmUsage(id) {
    try {
      const response = await this.sharingService.confirmUsage(id)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      return response
    } catch (error) {
      this.notificationUseCases.newError(error)
      throw error
    }
  }

  async declareUsage(id, requestData) {
    try {
      const response = await this.sharingService.declareUsage(id, requestData)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      return response
    } catch (error) {
      this.notificationUseCases.newError(error)
      throw error
    }
  }

  async cancelRequest(id, requestData) {
    try {
      const response = await this.sharingService.cancelRequest(id, requestData)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      return response
    } catch (error) {
      this.notificationUseCases.newError(error)
      throw error
    }
  }

  async getUnreadCount(id) {
    try {
      const response = await this.sharingService.getUnreadCount(id, {
        skipLoader: true
      })
      return response
    } catch (error) {
      console.log(error)
      return null
    }
  }

  async getMessagesBefore(id, date) {
    try {
      const response = await this.sharingService.getMessagesBefore(id, date, {
        skipLoader: true
      })
      return response
    } catch (error) {
      console.log(error)
      return null
    }
  }

  async getMessagesAfter(id, date, skipLoader = true) {
    try {
      console.log({ skipLoader })
      const response = await this.sharingService.getMessagesAfter(id, date, {
        skipLoader
      })

      return response
    } catch (error) {
      console.log(error)
      return null
    }
  }

  async sendMessage(id, message) {
    try {
      const response = await this.sharingService.sendMessage(id, message)
      return response
    } catch (error) {
      console.log(error)
      return null
    }
  }
}
