import React from 'react'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import MuiTypography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  root: {
    margin: 0,
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  head: {
    fontSize: 18,
    fontWeight: 700,
    color: '#333333'
  }
})

export const Dialog = withStyles(theme => ({
  paper: {
    boxSizing: 'border-box',
    minWidth: 645,
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      minWidth: '300px'
    }
  }
}))(MuiDialog)

export const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, variant, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MuiTypography variant={variant} className={classes.head}>
        {children}
      </MuiTypography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const DialogContent = withStyles(() => ({
  root: {
    border: 'none',
    padding: 0,
    marginTop: '20px',
    overflow: 'visible'
  }
}))(MuiDialogContent)

export const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: 0
  }
}))(MuiDialogActions)

const defaultFont = {
  fontWeight: 700
}

const stylingTypo = () => ({
  root: {
    ...defaultFont,
    fontSize: 12,
    fontWeight: 500
  },
  h1: {
    ...defaultFont,
    fontSize: 36
  },
  h2: {
    ...defaultFont,
    fontSize: 32
  },
  h3: {
    ...defaultFont,
    fontSize: 28
  },
  h4: {
    ...defaultFont,
    fontSize: 24
  },
  h5: {
    ...defaultFont,
    fontSize: 18
  },
  h6: {
    ...defaultFont,
    fontSize: 14
  },
  error: {
    ...defaultFont,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#f44336'
  }
})

export const Typography = withStyles(stylingTypo)(props => {
  const { children, classes, className, variant, ...other } = props
  const classNaming = variant
    ? classes[variant]
    : classes[className] || classes.root

  return (
    <MuiTypography variant={variant} className={classNaming} {...other}>
      {children}
    </MuiTypography>
  )
})

export const Section = styled.section`
  margin-top: 30px;

  &.area {
    &::after {
      content: '0/500';
      right: 10px;
      bottom: -20px;
      font-size: 12px;
    }
  }

  &.required {
    position: relative;

    &::before,
    &::after {
      display: block;
      position: absolute;
    }

    &::before {
      content: '*';
      left: -15px;
      font-size: 24px;
      top: 55%;
    }
  }
`

export const CustomButton = styled(Button)`
  background-color: transparent;
  font-weight: bold;
  margin: 0;
  text-transform: none;
  transition: background-color 0.4s ease-in-out;
  will-change: background-color;

  &:hover {
    background-color: #f3f3f3;
  }

  &.cancel {
    color: #666666;
  }

  &.exclude {
    color: #009b7f;
  }
`
