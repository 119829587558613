import React, { useContext } from 'react'

import { history } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import { Icon, ListItemStyled } from './styles'

function Item({ item, open }) {
  const { translate } = useContext(GlobalizationContext)

  return (
    <ListItemStyled
      button
      id={item.label}
      onClick={item.action ? () => history.push(item.action) : null}
      open={open}
    >
      {item.icon ? <Icon className={'fas ' + item.icon} /> : null}
      {translate(item.label)}
    </ListItemStyled>
  )
}
export default Item
