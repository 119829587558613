import { Auth, Hub } from 'aws-amplify'
import React, { useCallback } from 'react'

import { UsersService } from 'api'
import { history, generatePrivatePath } from 'helpers/history'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'

export const AuthContext = React.createContext({
  isAuthenticated: false,
  isLoading: true,
  idToken: '',
  userData: null,
  challengedUser: null,
  setChallengedUser: user => {},
  refreshCurrentUserData: () => {},
  signOut: () => {}
})

const AuthListener = async (data, setAuthData) => {
  const authData = data.payload.data

  switch (data.payload.event) {
    case 'signIn':
      setAuthData({
        isAuthenticated: false,
        isLoading: true,
        idToken: null,
        userData: null,
        challengedUser: null
      })

      await UsersService.getMe().then(me => {
        setAuthData({
          isAuthenticated: true,
          isLoading: false,
          idToken: authData.signInUserSession.getIdToken().getJwtToken(),
          userData: me,
          challengedUser: null
        })
      })
      break
    case 'signUp':
      setAuthData({
        isAuthenticated: true,
        isLoading: false,
        userData: null,
        idToken: authData.signUpUserSession.getIdToken().getJwtToken()
      })
      break
    case 'signOut':
      setAuthData({
        isAuthenticated: false,
        isLoading: false,
        userData: null,
        idToken: ''
      })
      break
    case 'signIn_failure':
      console.log('user sign in failed')
      break
    default:
      return
  }
}

export const signOut = async () => {
  await Auth.signOut()
  history.push('/')
}

export const AuthProvider = props => {
  const [authData, setAuthData] = React.useState({
    isAuthenticated: false,
    isLoading: true,
    idToken: '',
    userData: null,
    challengedUser: null
  })

  const setChallengedUser = user => {
    setAuthData(currState => {
      return {
        ...currState,
        challengedUser: user
      }
    })
  }

  const refreshCurrentUserData = useCallback(async () => {
    return await Auth.currentAuthenticatedUser()
      .then(userSession => {
        const signInSessionData = userSession.getSignInUserSession()

        return UsersService.getMe().then(me => {
          return setAuthData({
            isAuthenticated: true,
            isLoading: false,
            idToken: signInSessionData.getIdToken().getJwtToken(),
            userData: me,
            challengedUser: null
          })
        })
      })
      .catch(err => {
        return setAuthData({
          isAuthenticated: false,
          isLoading: false,
          idToken: '',
          userData: null,
          challengedUser: null
        })
      })
  }, [])

  React.useEffect(() => {
    Hub.listen('auth', data => AuthListener(data, setAuthData))
  }, [])

  return (
    <AsyncLoad promiseFn={refreshCurrentUserData}>
      <AuthContext.Provider
        value={{
          ...authData,
          signOut,
          setChallengedUser,
          refreshCurrentUserData
        }}
        {...props}
      />
    </AsyncLoad>
  )
}
