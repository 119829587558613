import { conformToMask } from 'react-text-mask'

import { CPF_MASK } from 'helpers/constants'
import { phone } from 'helpers/generalInputMasks'
import { maskToCpf, } from 'helpers/masks'

const parseData = ({ user, permissions }) => {
  const profileData = {
    birthdate: user.birthDate || null,
    cpf: conformToMask(user.cpf || '', CPF_MASK).conformedValue,
    cpf_mask: conformToMask(maskToCpf(user.cpf, 3, 9, "******") || '', CPF_MASK).conformedValue,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    graduation: user.formation || '',
    ORCID: user.identifier_orcid || '',
    linkLattes: user.linkLattes || '',
    nationality: user.nationality || '',
    telephone: phone(user.telephone || '')
  }

  const permissionList = permissions

  const profileImage = user.image ? `/${user.images.cropImage.path}` : null

  return { profileData, permissionList, profileImage }
}
export default class MyProfileUseCases {
  /**
   * @param {object} props
   * @param {import('api/UsersService').default} props.UserService
   * @param {import('views/Notification/providers/notification.useCases').default} props.notificationUseCases
   */
  constructor({ UsersService, notificationUseCases }) {
    this.userService = UsersService
    this.notificationUseCases = notificationUseCases
  }

  async getMyProfile() {
    const { user, permissions } = await this.userService.getMyProfile()
    return parseData({ user, permissions })
  }

  async updateMyProfile({ formData, permissionList, profileImage }) {
    const formattedPermissionList = permissionList.map(
      ({ deleted, ...rest }) => ({
        ...rest,
        situation: deleted ? 'DELETED' : rest.situation
      })
    )

    const postData = {
      birthdate: formData.birthdate,
      cpf: formData.cpf,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      formation: formData.graduation,
      identifierOrcid: formData.ORCID,
      linkLattes: formData.linkLattes,
      nationality: formData.nationality,
      telephone: formData.telephone,
      permissions: formattedPermissionList
    }

    try {
      if (profileImage?.blob) {
        await this.userService.setMyProfileImage(profileImage.blob)
      }
      const updatedProfile = await this.userService.setMyProfile(postData)

      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      return parseData(updatedProfile)
    } catch (error) {
      this.notificationUseCases.newError(error)
      throw error
    }
  }
}
