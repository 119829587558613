import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  margin-bottom: 0.4rem;
  font-size: 14px;
  font-weight: 600;
`

export const DropContainer = styled.div`
  &:focus {
    outline: none;
  }
`

export const UploadInput = styled.input`
  display: none;
  height: 100%;
  width: 100%;
`

export const Text = styled.label`
  background-color: rgba(0, 155, 127, 1);
  border: ${props => (props.errorStyle ? '1px solid #eb5757' : 'none')};
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: inherit;
  font-weight: 600;
  height: 5rem;
  outline: none;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  transition: all 0.3s;
  vertical-align: middle;
  width: 100%;
  box-sizing: border-box;
  &:hover {
    background-color: rgba(0, 104, 85, 1);
  }
`

export const AddIcon = styled.img`
  margin-left: 5px;
`
