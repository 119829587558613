import React, { useState, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Grid } from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'

const EquipmentResponsible = props => {
  const { classes } = props
  const { translate } = useContext(GlobalizationContext)
  const { labId } = useParams()

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const handleConfirm = () => {
    if (labId) {
      history.push(generatePrivatePath(`/laboratory/${labId}`))
    } else {
      history.push(generatePrivatePath('/equipments'))
    }
  }

  return (
    <div className={classes.defaultContainer}>
      <DialogConfirm
        dialogText="REGISTER_EQUIPMENT_CANCEL_CONFIRM"
        open={openConfirmDialog}
        onConfirm={handleConfirm}
        onCancel={() => setOpenConfirmDialog(false)}
      />
      <Grid
        container
        justify="flex-end"
        spacing={2}
        style={{ paddingTop: '4rem' }}
      >
        <Grid item xs={12} md={2}>
          <Button
            name="EQUIPMENT_CANCEL"
            className={classes.baseButtonGrey}
            type={'button'}
            variant="contained"
            fullWidth
            onClick={() => setOpenConfirmDialog(true)}
          >
            {translate('REGISTER_INSTITUTION_CANCEL')}
          </Button>
        </Grid>

        <Grid item xs={12} md={2}>
          <Button
            name="EQUIPMENT_SAVE"
            fullWidth
            type={'submit'}
            variant="contained"
            className={classes.baseButton}
          >
            {translate('REGISTER_INSTITUTION_SAVE')}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default EquipmentResponsible
