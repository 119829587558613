import React, { useContext } from 'react'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'

import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'

import { PUBLIC_ROUTES } from '../../routes'
import {
  Container as BreadCrumbsWrapper,
  BreadcrumbSeparator,
  BreadcrumbItem,
  BreadCrumbs,
  BreadcrumbItemLink
} from './style'

const routes = [...PUBLIC_ROUTES.filter(route => !!route.breadcrumb)]

const Breadcrumb = ({ breadcrumbs }) => {
  const { translate } = useContext(GlobalizationContext)

  if (breadcrumbs.length === 1) return null

  return (
    <ContentContainer style={{ flex: 0 }}>
      <BreadCrumbsWrapper>
        <BreadCrumbs separator={<BreadcrumbSeparator />}>
          {breadcrumbs.map(({ match, breadcrumb }, index) => {
            const isLast = index == breadcrumbs.length - 1
            const item = isLast ? BreadcrumbItem : BreadcrumbItemLink

            if (breadcrumb.type == 'span') {
              return React.createElement(
                item,
                {
                  key: match.url,
                  to: match.url,
                  ...breadcrumb.props
                },
                {
                  ...breadcrumb,
                  props: {
                    ...breadcrumb.props,
                    children: translate(breadcrumb.props.children)
                  }
                }
              )
            } else {
              return React.createElement(breadcrumb.type, {
                key: match.url,
                match,
                item,
                ...breadcrumb.props
              })
            }
          })}
        </BreadCrumbs>
      </BreadCrumbsWrapper>
    </ContentContainer>
  )
}

export default Wrapper(
  withBreadcrumbs(routes, { disableDefaults: true })(Breadcrumb),
  { namespace: 'BREADCRUMBS' }
)
