import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  NoResults
} from 'ui/atoms/Table'
import Pagination from 'ui/molecules/commons/Pagination'

import Row from './Row'

const List = ({
  classes,
  userRowList,
  count,
  rowsPerPage,
  rowsPerPageOptions,
  page,
  onChangePage,
  onChangeRowsPerPage,
  emptyList,
  onRowChange: handleRowAction
}) => {
  const { translate } = useContext(GlobalizationContext)

  const showPagination = rowsPerPageOptions[0] < count

  const paginationComponent = showPagination ? (
    <Pagination
      rowsPerPageOptions={rowsPerPageOptions}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  ) : null

  const UserRowList = userRowList.map((row, index) => {
    return (
      <Row
        item={row}
        onRowAction={handleRowAction}
        key={`${index}${row.id_user}_${row.situation}`}
      />
    )
  })

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={12}>
          {!emptyList ? (
            <>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '10px' }}></TableCell>
                      <TableCell>{translate('COLUMN.NAME')}</TableCell>
                      <TableCell>{translate('COLUMN.EMAIL')}</TableCell>
                      <TableCell>{translate('COLUMN.CPF')}</TableCell>
                      <TableCell>{translate('COLUMN.STATUS')}</TableCell>
                      <TableCell style={{ width: '10px' }}>
                        {translate('COLUMN.ACTIONS')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{UserRowList}</TableBody>
                </Table>
              </TableContainer>
              {paginationComponent}
            </>
          ) : (
            <NoResults>{translate('NO_RESULTS')}</NoResults>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default List
