import React, { useContext } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import BaseButton from 'ui/atoms/BasePrivate/BaseButton'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

function DialogSliderMaxSize(props) {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ paddingBottom: '30px' }}>
          <DialogContent className={classes.dialogOverflowHidden}>
            <DialogContentText id="alert-dialog-description">
              {translate(props.dialogText)}
            </DialogContentText>
          </DialogContent>
        </div>
        <DialogActions>
          {!props.hideConfirmButton && (
            <BaseButton
              onClick={props.onConfirm}
              label={translate('EQUIPMENT_DIALOG_OK')}
            ></BaseButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
export default DialogSliderMaxSize
