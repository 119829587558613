import React from 'react'
import styled, { css } from 'styled-components'

import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MaterialAutocomplete from '@material-ui/lab/Autocomplete'

import { primaryColor } from 'helpers/constants'

import AtomSpinner from 'ui/atoms/AtomSpinner'

export const Loader = styled(props => (
  <div {...props}>
    <AtomSpinner color={primaryColor} />
  </div>
))`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
`

export const AutocompletePaper = styled(Paper)`
  .MuiAutocomplete-listbox {
    margin: 0;
    padding: 0;
  }

  .MuiAutocomplete-option {
    min-height: 20px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.25;
    display: flex;

    &[aria-disabled='true'] {
      opacity: 1;
    }

    &[aria-selected='true'] {
      background-color: #b2dffb;
    }

    &[aria-selected='true']:hover {
      cursor: default;
    }

    &[aria-selected='false']:hover {
      background-color: rgba(178, 223, 251, 0.5);
    }
  }

  .MuiAutocomplete-noOptions,
  .no-options {
    background-color: white;
    padding: 10px;
    margin: 0;
    font-size: 14px;
  }

  .MuiAutocomplete-loading {
    display: hidden;
  }
`

export const AutocompletePopper = styled(Popper)``

export const Autocomplete = styled(MaterialAutocomplete).attrs({
  PopperComponent: AutocompletePopper,
  PaperComponent: AutocompletePaper
})`
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 0;
  }
`

export const OptionContainer = styled.div`
  display: flex;
  width: 100%;

  ${props =>
    props.disabled &&
    css`
      background: #cccccc;
    `}
`

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 5px 15px;
`
