import React, { useContext } from 'react'

import { TableChip, TableChipSecondary } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const Situation = ({ equipment }) => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <>
      {['OUT_OF_DATE', 'UPDATE_NEEDED'].indexOf(equipment.equipmentSituation) >=
        0 && (
        <TableChip
          size="small"
          label={translate(
            `EQUIPMENT:SITUATION.${equipment.equipmentSituation}`
          )}
        />
      )}

      {equipment.replicated && (
        <TableChipSecondary
          size="small"
          label={translate('EQUIPMENT:SITUATION.REPLICATED')}
        />
      )}
    </>
  )
}

export default Situation
