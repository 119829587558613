import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'index.css'

import 'auth.config'

import { StylesProvider } from '@material-ui/core/styles'

import { AuthProvider } from 'context/AuthContext'
import 'framework/yup'
import 'framework/i18n'
import store from 'framework/store'

import App from 'views/App'

import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <StylesProvider injectFirst>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </StylesProvider>,
  document.getElementById('root')
)
serviceWorker.unregister()
// If you want your app to work offline and load faster, you can change
// unregister() to register(). Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
