import styled from 'styled-components'

import Icon from '@material-ui/core/Icon'
import MaterialTable from '@material-ui/core/Table'
import MaterialTableBody from '@material-ui/core/TableBody'
import MaterialTableCell from '@material-ui/core/TableCell'
import MaterialTableContainer from '@material-ui/core/TableContainer'
import MaterialTableHead from '@material-ui/core/TableHead'
import MaterialTableRow from '@material-ui/core/TableRow'

import { Marker, MarkerGreen, MarkerOrange, MarkerRed } from 'ui/atoms/Marker'

const borderRadius = '3px'

const markerColors = {
  ACTIVE: MarkerGreen,
  PENDING: MarkerOrange,
  INACTIVE: MarkerRed
}

export const StatusMarker = styled(Marker)`
  ${props => markerColors[props.type]}
  margin-right: 8px;
  width: 11px;
  height: 11px;
`

export const MarkerContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TableCell = styled(MaterialTableCell)`
  height: 24px;
  padding: 0px 10px;
  border: none;
`

export const TableRow = styled(MaterialTableRow)`
  ${TableCell}:first-child {
    border-top-left-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
  }

  ${TableCell}:last-child {
    border-top-right-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }
`

export const TableBody = styled(MaterialTableBody)`
  ${TableCell} {
    height: 80px;
    background-color: #f2f2f2;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
  }

  ${TableRow} {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: ${borderRadius};
  }
`

export const TableHead = styled(MaterialTableHead)`
  border: none;

  ${TableCell} {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
  }
`

export const Table = styled(MaterialTable)`
  border: none;
  border-collapse: separate;
  border-spacing: 0 10px;
`

export const TableContainer = styled(MaterialTableContainer)``

export const UndoIcon = styled(Icon).attrs({
  fontSize: 'small',
  className: 'fas fa-undo'
})``
