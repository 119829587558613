import React from 'react'
import styled, { css } from 'styled-components'

import { Checkbox as MuiCheckbox } from '@material-ui/core'

import { primaryColor, COLORS } from 'helpers/constants'

export const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const Checkbox = styled(({ hasError, ...props }) => (
  <MuiCheckbox {...props} />
))`
  ${props =>
    props.hasError &&
    css`
      color: ${COLORS.ERROR};
    `}
`

export const AgreeText = styled.span`
  font-weight: 400;
  color: rgb(153, 153, 153);
  * {
    color: ${primaryColor};
  }
`

export const ErrorContainer = styled.div`
  width: 100%;
  padding-left: 12px;
  margin-top: -5px;

  .MuiFormHelperText-root {
    margin-top: -3px;
  }
`
