import React from 'react'
import { Controller, useWatch } from 'react-hook-form'

import BaseTextField from 'ui/atoms/BaseTextField'
import { TableChip } from 'ui/atoms/Chip'

import {
  Autocomplete,
  OptionContainer,
  OptionWrapper,
  OptionSituation
} from './style'

const ResponsibleOption = ({ option }) => {
  return (
    <OptionContainer disabled={option.userInactive}>
      <OptionWrapper>
        <span className="name">{option.name}</span>
        <span className="email">{option.email}</span>
      </OptionWrapper>
      {option.userInactive ? (
        <OptionSituation>
          <TableChip size="small" label={'Inativo na plataforma'} />
        </OptionSituation>
      ) : null}
    </OptionContainer>
  )
}

const Responsible = ({
  formMethods,
  disabled,
  label,
  placeholder,
  representativeList,
  onSelected
}) => {
  const { control, setValue, getValues } = formMethods
  const responsibleList = representativeList.filter(
    item =>
      item.permission === 'ROLE_LABORATORIO_TOTAL' &&
      item.situation === 'ACTIVE'
  )

  useWatch({ control, name: 'responsibleId' })

  const onOptionSelected = (event, option) => {
    setValue('responsibleId', option.id)
    setValue('responsibleCpf', option.cpf || '')
    setValue('responsibleFirstName', option.firstName)
    setValue('responsibleLastName', option.lastName)
    setValue('responsibleEmail', option.email)
    setValue('responsiblePhone', option.telephone)
    setValue('responsibleLattes', option.link_lattes || '')

    onSelected(true)
  }

  return (
    <Controller
      name="responsibleFirstName"
      control={control}
      defaultValue=""
      render={({ ref, ...controlProps }) => (
        <Autocomplete
          {...controlProps}
          disabled={disabled}
          onChange={onOptionSelected}
          options={responsibleList}
          disableClearable={true}
          getOptionLabel={option =>
            typeof option === 'string' ? option : option.firstName
          }
          getOptionDisabled={option => option.userInactive}
          renderOption={option => <ResponsibleOption option={option} />}
          getOptionSelected={option =>
            option?.id === getValues('responsibleId')
          }
          renderInput={({ inputProps, ...params }) => (
            <BaseTextField
              {...params}
              inputRef={ref}
              label={label}
              required={true}
              placeholder={placeholder}
              inputProps={{
                ...inputProps,
                readOnly: true,
                autoComplete: 'nope'
              }}
            />
          )}
        />
      )}
    />
  )
}

export default Responsible
