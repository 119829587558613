import React, { useState, useEffect, useContext } from 'react'
import AutoSuggest from 'react-autosuggest'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'

import { Button, Grid } from '@material-ui/core'

import { primaryColor } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

const WrapSuggestions = styled.div`
  position: relative;
  z-index: 2;
  .react-autosuggest__suggestions-container {
    position: absolute;
    margin: 0;
    padding: 0;
    font-size: 14px;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: scroll;
    list-style-type: none;
    width: 100%;
    background: #f2f2f2;
    padding: 0;
    ul {
      padding: 0;
    }
    li {
      display: block;
      padding: 6px 15px;
      cursor: pointer;
      &:hover {
        background: #0000000a;
      }
      span {
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
`

const IconDelete = styled.i`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333333;
  margin: 0 auto;
  display: block;

  &:hover {
    color: ${primaryColor};
    cursor: pointer;
  }
`

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  margin-top: 20px;
  li {
    margin-bottom: 15px;
  }
  span {
    background: #d8d8d8;
    display: block;
    color: #999999;
    font-size: 14px;
    padding: 10px;
  }
`

const ListItem = styled.li`
  display: flex;
  min-height: 40px;

  &:not(:last-child) {
    margin-bottom: 7px;
  }
`
const ListLabel = styled.div`
  font-size: 14px;
  flex: 1 0 auto;
  background-color: #eaeaea;
  margin-right: 5px;
  padding-left: 16px;
  color: #999999;
  display: flex;
  align-items: center;
`

const ListAction = styled.div`
  flex: 0 1 auto;
  width: 40px;
  display: flex;
  align-items: center;
`

const InputAddItensAutoSuggestComponent = ({
  value: componentValue,
  name,
  onChange,
  onBlur,
  debounce,
  label,
  suggestionGetter,
  inputPlaceholder,
  maximumLimit,
  inputRef
}) => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()
  const defaultDebounce = 500

  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [noResults, setNoResults] = useState(false)
  const [onBlurNoResults, setOnBlurNoResults] = useState(false)
  const [debounceTimeOut, setDebounceTimeOut] = useState(null)

  const [maximumLimitReached, setMaximumLimitReached] = useState(false)
  const [selectedSuggestion, setSelectedSuggestion] = useState({})

  const [exist, setExist] = useState(false)

  const listItens = componentValue || []

  const handleSuggestionsFetchRequested = ({ value }) => {
    // setInputValue(value)
    setExist(false)

    if (debounceTimeOut) clearTimeout(debounceTimeOut)

    const newDebounceTimeOut = setTimeout(() => {
      suggestionGetter({ value })
        .then(resp => {
          setSuggestions(resp || [])
        })
        .catch(() => {})
    }, debounce || defaultDebounce)
    setDebounceTimeOut(newDebounceTimeOut)
  }

  useEffect(() => {
    verifyEquals()

    const conditional =
      inputValue !== '' &&
      suggestions.length === 0 &&
      !onBlurNoResults &&
      !exist

    conditional ? setNoResults(true) : setNoResults(false)

    setOnBlurNoResults(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions])

  const verifyEquals = () => {
    const searchIndex = suggestions.findIndex(item => item.name === inputValue)
    if (searchIndex >= 0) {
      setSelectedSuggestion(suggestions[searchIndex])
      setOnBlurNoResults(true)
      setExist(true)
    }
  }

  const handleAddItemList = () => {
    setNoResults(false)
    if (isRepeated(selectedSuggestion)) return
    if (maximumLimit) {
      if (listItens.length < maximumLimit) {
        setMaximumLimitReached(false)
        addItemList()
      } else {
        setMaximumLimitReached(true)
      }
    } else {
      addItemList()
    }
  }

  const isRepeated = newValue => {
    return listItens.findIndex(item => item.id == newValue.id) >= 0
  }

  const addItemList = () => {
    const newList = listItens.concat(selectedSuggestion)
    onChange(newList)
    setSelectedSuggestion({})
    setInputValue('')
  }

  const handleDelete = removedItem => {
    const newList = listItens.filter(item => item !== removedItem)
    onChange(newList)
    setMaximumLimitReached(false)
  }

  const handleInputChange = (event, { newValue }) => {
    setInputValue(newValue || '')
    newValue && setSelectedSuggestion(newValue)
    setExist(true)
  }

  const handleInputBlur = (event, { highlightedSuggestion }) => {
    const suggestionBlur = highlightedSuggestion?.name || ''
    setInputValue(suggestionBlur)
    highlightedSuggestion && setSelectedSuggestion(highlightedSuggestion)
    setExist(true)
  }

  return (
    <div className={classes.defaultContainer}>
      <Grid item xs={12} md={12}>
        <label className={classes.label}>{translate(label)}</label>
      </Grid>
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={12} md={10} style={{ marginRight: '10px' }}>
          <WrapSuggestions>
            <AutoSuggest
              className={classes.baseButton}
              suggestions={suggestions}
              onSuggestionsClearRequested={() => setSuggestions([])}
              onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
              onSuggestionSelected={(_, { suggestion }) => {
                setSelectedSuggestion(suggestion)
                setOnBlurNoResults(true)
                setExist(true)
              }}
              onSuggestionHighlighted={suggestion => suggestion}
              getSuggestionValue={suggestion => suggestion.name}
              renderSuggestion={suggestion => (
                <span name={`${name}_suggestion`} data-value={suggestion.name}>
                  {suggestion.name}
                </span>
              )}
              inputProps={{
                name,
                ref: inputRef,
                placeholder: translate(inputPlaceholder),
                value: selectedSuggestion?.name || inputValue,
                className: classes.baseInput,
                maxLength: 255,
                onChange: handleInputChange,
                onBlur: handleInputBlur
              }}
              highlightFirstSuggestion={true}
            />
          </WrapSuggestions>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          style={{
            maxWidth: 'calc(100% - (83.333333% + 10px))',
            flexBasis: 'calc(100% - (83.333333% + 10px))'
          }}
        >
          <Button
            fullWidth
            disabled={
              !selectedSuggestion.name || selectedSuggestion?.userInactive
            }
            className={classes.baseAddButton}
            onClick={handleAddItemList}
            variant="contained"
          >
            {translate('COMMONS_REGISTER_ADD_BUTTON')}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={12} style={{ fontSize: '14px' }}>
          {noResults && translate('COMMONS_REGISTER_NONE_REGISTER')}
          {maximumLimitReached && translate('COMMONS_MAX_LIMIT_REACHED')}
          {selectedSuggestion?.userInactive &&
            translate('INVALID_USER_SITUATION')}
        </Grid>
      </Grid>

      <Grid container>
        <List>
          {listItens.map(item => (
            <ListItem key={item.id}>
              <ListLabel>{item.name}</ListLabel>
              <ListAction>
                <IconDelete
                  className="fas fa-trash-alt"
                  onClick={() => handleDelete(item)}
                />
              </ListAction>
            </ListItem>
          ))}
        </List>
      </Grid>
    </div>
  )
}

const InputAddItensAutoSuggest = ({ name, control, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ ref, ...controlProps }) => (
        <InputAddItensAutoSuggestComponent
          inputRef={ref}
          name={name}
          {...controlProps}
          {...props}
        />
      )}
    />
  )
}
export default InputAddItensAutoSuggest
