import { curryRight, map } from 'lodash'
import compact from 'lodash/compact'

const mapKey = curryRight(map, 2)

export const filterFieldTypes = {
  regionList: {
    type: 'LIST',
    grouped: true,
    translateFn: ({ item, translate }) => translate(`REGIONS:${item.name}`),
    encodeDTO: mapKey('id')
  },
  stateList: {
    type: 'LIST',
    grouped: true,
    translateFn: ({ item, translate }) => translate(`STATES:${item.name}`),
    encodeDTO: mapKey('id')
  },
  cityList: {
    type: 'LIST',
    grouped: true,
    key: 'value',
    encodeDTO: mapKey('id')
  },

  institutionList: {
    type: 'LIST',
    id: 'cnpj',
    translateFn: ({ item }) =>
      typeof item === 'string'
        ? item
        : compact([item.initials || null, item.social_reason]).join(' - '),
    encodeDTO: mapKey('cnpj')
  },

  labActivityTypeList: {
    type: 'LIST',
    key: 'description',
    id: 'id_type_laboratory',
    grouped: true,
    dtoKey: 'typeLaboratoryList',
    encodeDTO: mapKey('id_type_laboratory')
  },
  labExpertiseAreaList: {
    type: 'LIST',
    dtoKey: 'areaExpertiseList',
    encodeDTO: mapKey('id')
  },
  labTechniquesUsed: {
    type: 'LIST',
    dtoKey: 'techniqueLabList',
    encodeDTO: mapKey('id')
  },
  sharing: {
    type: 'BOOL',
    translateFn: ({ translate }) => translate(`ONLY_SHARING_LAB`),
    encodeDTO: value => value
  },

  equipTechniquesUsed: {
    type: 'LIST',
    dtoKey: 'techniqueEquipList',
    encodeDTO: mapKey('id')
  },
  otherDenominationList: {
    type: 'LIST',
    dtoKey: 'otherDenominationList',
    encodeDTO: mapKey('idList')
  },
  onlyMultiUser: {
    type: 'BOOL',
    translateFn: ({ translate }) => translate(`ONLY_MULTIUSER_FILTER`),
    encodeDTO: value => value
  }
}

export const filterFieldTypeList = Object.keys(filterFieldTypes)
export const getNameKey = field => {
  return filterFieldTypes[field].key || 'name'
}

export const getIdKey = field => {
  return filterFieldTypes[field].id || 'id'
}

export const getDefaultValue = field => {
  switch (filterFieldTypes[field].type) {
    case 'LIST':
      return []
    case 'BOOL':
      return false
    default:
      return null
  }
}

export const isGrouped = field => Boolean(filterFieldTypes[field]?.grouped)

export const isList = field => Boolean(filterFieldTypes[field]?.type === 'LIST')

export const getEncodeKey = field => {
  return filterFieldTypes[field].dtoKey || field
}

export const encodeDTO = (field, value) => {
  return filterFieldTypes[field].encodeDTO(value)
}

export const isEmpty = (field, value) => {
  return isEmptyByType[[filterFieldTypes[field].type]](value)
}

export const isEmptyByType = {
  LIST: value => !(value?.length > 0),
  BOOL: value => !value
}

export const hasAnyFilter = filter => {
  return Boolean(
    filterFieldTypeList.reduce((acc, field) => {
      return acc || !isEmpty(field, filter[field])
    }, false)
  )
}

export const clearFilter = ({ field, value, item }) => {
  const idKey = getIdKey(field)
  if (isList(field) && !isGrouped(field)) {
    return (value || []).filter(option => option[idKey] !== item[idKey])
  } else {
    return getDefaultValue(field)
  }
}

export const getChipLabel = ({ field, filter, item, translate }) => {
  const translateFn =
    filterFieldTypes[field].translateFn || (({ name }) => name)

  switch (filterFieldTypes[field].type) {
    case 'LIST':
      if (isGrouped(field)) {
        const [first, ...rest] = filter[field]
        return `${translateFn({
          name: first[getNameKey(field)],
          item: first,
          translate
        })}${rest.length ? ' +' + rest.length : ''}`
      } else {
        return translateFn({ name: item[getNameKey(field)], item, translate })
      }
    default:
      return translateFn({ item: filter[field], translate })
  }
}
