import startOfToday from 'date-fns/startOfToday'
import * as Yup from 'yup'

export const formSchema = Yup.object().shape({
  laboratory: Yup.object().nullable().required(),
  solicitationTitle: Yup.string().required(),
  summary: Yup.string().required(),
  justification: Yup.string().required(),
  estimatedTime: Yup.string().required(),
  suggestedDateFrom: Yup.date()
    .nullable()
    .typeError('VALIDATION:INVALID_DATE')
    .required()
    .min(startOfToday(), 'VALIDATION:GREATER_OR_EQUAL_THAN_TODAY_ERROR'),
  suggestedDateTo: Yup.date()
    .nullable()
    .typeError('VALIDATION:INVALID_DATE')
    .required()
    .min(startOfToday(), 'VALIDATION:GREATER_OR_EQUAL_THAN_TODAY_ERROR')
    .greaterThan('suggestedDateFrom'),

  requiresSpecificEquipment: Yup.boolean().fromString().nullable().required(),
  specificEquipment: Yup.array()
    .of(Yup.object())
    .when('requiresSpecificEquipment', {
      is: true,
      then: Yup.array().of(Yup.object()).required(),
      otherwise: Yup.array().of(Yup.object()).notRequired()
    }),

  techniqueList: Yup.array().of(Yup.object()).required(),

  hasExperienceWithTechnique: Yup.boolean().fromString().nullable().required(),
  justifyExperienceWithTechnique: Yup.string().when(
    'hasExperienceWithTechnique',
    {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired()
    }
  ),

  needsComplementaryEquipment: Yup.string().required(),
  justifyNeedsComplementaryEquipment: Yup.string().when(
    'needsComplementaryEquipment',
    {
      is: 'YES',
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired()
    }
  ),

  needsToBringSample: Yup.string().required(),
  justifyNeedsToBringSample: Yup.string().when('needsToBringSample', {
    is: 'YES',
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired()
  }),
  sampleAmount: Yup.string().when('needsToBringSample', {
    is: 'YES',
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired()
  }),

  needsSamplePreparation: Yup.string().when('needsToBringSample', {
    is: 'YES',
    then: Yup.string().required(),
    otherwise: Yup.string().nullable().notRequired()
  }),
  justifyNeedsSamplePreparation: Yup.string().when('needsSamplePreparation', {
    is: 'YES',
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired()
  }),

  hasSecurityHazard: Yup.string().when('needsToBringSample', {
    is: 'YES',
    then: Yup.string().required(),
    otherwise: Yup.string().nullable().notRequired()
  }),
  securityHazardList: Yup.array()
    .of(Yup.string())
    .when('hasSecurityHazard', {
      is: 'YES',
      then: Yup.array().of(Yup.string()).nullable().required(),
      otherwise: Yup.array().of(Yup.string()).nullable().notRequired()
    }),
  justifySecurityHazard: Yup.string().when('hasSecurityHazard', {
    is: 'YES',
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired()
  }),

  requesterCPF: Yup.string().digitsOnly().cpf().required(),
  requesterFirstName: Yup.string().required(),
  requesterLastName: Yup.string().required(),
  requesterEmail: Yup.string().email().required(),
  requesterPhone: Yup.string().digitsOnly().required(),
  requesterLattesLink: Yup.string(),
  institution: Yup.object().nullable().required(),
  institutionCNPJ: Yup.string().digitsOnly().required().cnpj(),

  institutionType: Yup.string().required(),
  isPublic: Yup.boolean()
    .fromString()
    .nullable()
    .when(
      ['institutionType', '$institutionTypeList'],
      (institutionType, institutionTypeList, schema) =>
        institutionTypeList.find(item => item.id === institutionType)?.isCompany
          ? schema.required()
          : schema.notRequired()
    ),
  specifyInstitutionType: Yup.string().when(
    ['institutionType', '$institutionTypeList'],
    (institutionType, institutionTypeList, schema) =>
      institutionTypeList.find(item => item.id === institutionType)?.isOther
        ? schema.required()
        : schema.notRequired()
  ),

  hasReadTermsOfUse: Yup.boolean().oneOf([true], 'VALIDATION:REQUIRED_ERROR'),
  isAwareOfCosts: Yup.boolean().when(
    '$currentLaboratory.hasSharingCosts',
    (hasSharingCosts, schema) =>
      hasSharingCosts
        ? schema.oneOf([true], 'VALIDATION:REQUIRED_ERROR')
        : schema
  )
})
