import ptbrLocale from 'date-fns/locale/pt-BR'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider as SCThemeProvider } from 'styled-components'

import {
  makeStyles,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles'
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider'

import DateFnsUtils from 'framework/dateFns'
import { history } from 'helpers'
import { PRIVATE_ROUTE_PATH } from 'helpers/constants'
import { mapRoutesPath } from 'helpers/mapRoutes'

import ScrollToTop from 'ui/atoms/ScrollToTop'
import Wrapper from 'ui/atoms/Wrapper'
import muiTheme from 'ui/theme'

import BackOffice from 'views/BackOffice'
import NotificationList from 'views/Notification/fragments/NotificationList'
import {
  messageStyles,
  messageIcons
} from 'views/Notification/fragments/NotificationList/style'

import NoWrappingRoutes from './Fragments/NoWrappingRoutes'
import { NO_WRAPPING_ROUTES } from './Fragments/NoWrappingRoutes/routes'
import PublicRoutes from './Fragments/PublicRoutes'
import { PUBLIC_ROUTES } from './Fragments/PublicRoutes/routes'

const useStyles = makeStyles(messageStyles)

const publicRoutes = mapRoutesPath(PUBLIC_ROUTES)
const noWrappingRoutes = mapRoutesPath(NO_WRAPPING_ROUTES)

function App() {
  const classes = useStyles()

  return (
    <MuiThemeProvider theme={muiTheme}>
      <SCThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptbrLocale}>
          <SnackbarProvider
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            classes={{
              containerAnchorOriginTopRight:
                classes.containerAnchorOriginTopRight,
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info
            }}
            iconVariant={messageIcons}
          >
            <NotificationList />
            <Router history={history}>
              <ScrollToTop id="mainContentContainer">
                <Switch>
                  <Route
                    path={publicRoutes}
                    component={PublicRoutes}
                    exact={true}
                  />
                  <Route
                    path={noWrappingRoutes}
                    component={NoWrappingRoutes}
                    exact={true}
                  />

                  <Route
                    path={PRIVATE_ROUTE_PATH}
                    exact={false}
                    component={BackOffice}
                  />
                  <Redirect to="/" />
                </Switch>
              </ScrollToTop>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </SCThemeProvider>
    </MuiThemeProvider>
  )
}

export default Wrapper(App, {
  namespace: 'OLD_DEFAULT'
})
