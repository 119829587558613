// import 'assets/font/rawline/index.css'

import { grey, red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

import {
  fontFamily,
  COLORS,
  primaryColorDark,
  primaryColor,
  primaryColorLight,
  textPrimaryColor
} from 'helpers/constants'

const theme = createMuiTheme({
  fontFamily: fontFamily,
  fontFeatureSettings: 'lnum',
  typography: {
    fontFamily: fontFamily,
    fontFeatureSettings: 'lnum'
  },
  palette: {
    primary: {
      light: primaryColorLight,
      main: primaryColor,
      dark: primaryColorDark,
      contrastText: '#fff'
    },
    text: {
      primary: textPrimaryColor
    }
  },
  measures: {
    sideNav: {
      top: '78px',
      listItem: {
        padding: '20px 5px 20px 18px',
        icon: {
          fontSize: '24px',
          width: '30px',
          marginRight: '12px'
        }
      },
      closed: {
        width: '70px'
      },
      open: {
        width: '290px'
      }
    }
  },
  props: {
    MuiCheckbox: {
      color: 'primary'
    },
    MuiRadio: {
      size: 'small',
      color: 'primary'
    },
    MuiLink: {
      underline: 'always'
    },
    MuiTypography: {
      color: 'textPrimary',
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h2',
        subtitle2: 'h3',
        body1: 'p',
        body2: 'p'
      }
    }
  },
  overrides: {
    MuiTypography: {
      subtitle1: {
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '24px'
      },
      subtitle2: {
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '21px'
      },
      body2: {
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '21px'
      },
      h1: {
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '32px',
        lineHeight: '38px'
      },
      h2: {
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '20px',
        lineHeight: '30px'
      },
      h3: {
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '24px'
      },
      h4: {
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '21px'
      },
      h5: {
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '18px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '8px',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)'
        }
      }
    },
    MuiButton: {
      root: {
        fontSize: '14px',
        fontWeight: 600,
        textTransform: 'none'
      },
      contained: {
        boxShadow: '2px 2px 5px rgba(64, 64, 64, 0.28)',
        padding: '8px 21px',
        borderRadius: '2px',
        fontWeight: 600,
        '&:hover': {
          boxShadow: '2px 2px 5px rgba(64, 64, 64, 0.28)'
        }
      },
      containedSecondary: {
        backgroundColor: grey[200],
        color: textPrimaryColor,
        '&:hover': {
          backgroundColor: grey[300]
        }
      },
      containedError: {
        backgroundColor: red[400]
      }
    },
    MuiLink: {
      root: {
        fontWeight: 600,
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    MuiDialogActions: {
      root: {
        padding: '8px'
      },
      spacing: {
        padding: '16px 24px 24px 24px',
        '& > :not(:first-child)': {
          marginLeft: '20px'
        }
      }
    },
    MuiCheckbox: {
      root: {
        padding: '4px',
        marginLeft: '4px'
      }
    },
    MuiRadio: {
      root: {
        padding: '6px'
      }
    },
    MuiFormLabel: {
      root: {
        lineHeight: '14px',
        color: textPrimaryColor,
        marginBottom: '0.4rem',
        fontSize: '14px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        '&$focused': {
          color: textPrimaryColor
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        lineHeight: '32px'
      }
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          fontWeight: 600
        }
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none'
      }
    },
    MuiTableBody: { root: { backgroundColor: '#f5f5f5' } },
    MuiTableHead: { root: { backgroundColor: '#fff' } },
    MuiTableCell: {
      root: {
        padding: '5px 10px',
        border: '1px solid #cccccc',
        fontSize: '12px',
        lineHeight: 1.4,
        color: '#333333',
        height: '30px'
      },
      head: {
        fontWeight: 700
      }
    },
    MuiPaper: {
      root: {
        overflowX: 'hidden',
        overflowY: 'hidden'
      }
    },
    MuiDialogContent: {
      root: {
        overflowX: 'hidden'
        // overflowY: "scroll",
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: fontFamily,
        '&$error': {
          border: `1px solid ${COLORS.ERROR}`
        },
        '&.with-placeholder': {
          color: '#9E9E9E',
          '& option:not(:first-of-type)': {
            color: 'black'
          },
          '& option:first-of-type': {
            display: 'none'
          }
        }
      }
    },
    MuiSelect: {
      root: {
        backgroundColor: '#f2f2f2',
        minHeight: '26px',
        lineHeight: '26px',
        paddingLeft: '10px',
        background: '#f2f2f2',
        borderRadius: '2px!important',
        fontSize: '14px',
        '&.$Mui-disabled': {
          backgroundColor: '#dadada!important',
          color: '#999999!important'
        },
        '&.$MuiSelect-select': {
          padding: '6px 10px !important',
          paddingRight: '24px !important'
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: primaryColor
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      }
    },
    MuiPickersDay: {
      day: {
        // color: lightBlue.A700
        fontWeight: 500,
        '&:hover': {
          backgroundColor: primaryColorLight,
          color: '#fff'
        }
      },
      daySelected: {
        backgroundColor: primaryColor,
        color: '#fff',
        '&:hover': {
          backgroundColor: primaryColor
        }
      },
      // dayDisabled: {
      //   color: lightBlue['100']
      // },
      current: {
        color: '#333',
        '& p': {
          fontWeight: 700
        }
      }
    },
    MuiPickersYear: {
      year: {},
      yearSelected: {
        // color: primaryColor,
        fontSize: '24px',
        '& p': {
          fontWeight: 700
        }
      }
    },
    MuiPickersMonth: {
      monthSelected: {
        fontSize: '24px',
        '& p': {
          fontWeight: 700
        }
      }
    },
    MuiPickersModal: {
      dialogAction: {
        // color: lightBlue['400']
      }
    }
  }
})

export default theme
