import React from 'react'

import { Grid } from '@material-ui/core'

import { useGlobalizationContext } from 'ui/atoms/Globalization'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from 'ui/atoms/Table'
import { Subtitle } from 'ui/atoms/Title'

import { LabMarker } from 'views/BackOffice/Subviews/LaboratoryList/Fragments/List/Row'

const LabSituation = ({ laboratoryData }) => {
  const { translate } = useGlobalizationContext()
  const { laboratory } = laboratoryData
  const { situation } = laboratory

  return (
    <Grid container spacing={2}>
      <Subtitle item xs={12}>
        {translate('SITUATION.TITLE')}
      </Subtitle>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '10px' }}>
                  <LabMarker type={situation} />
                </TableCell>
                <TableCell>{`${laboratory.initials} - ${laboratory.name}`}</TableCell>
                <TableCell>
                  {laboratory.areasExpertiseList
                    .map(item => item.name)
                    .join(', ')}
                </TableCell>
                <TableCell>{laboratory.city}</TableCell>
                <TableCell>
                  {translate(`LABORATORY:SITUATION.${laboratory.situation}`)}
                </TableCell>
                {/* <TableCell style={{ width: '10px' }}>
                    <TableActions
                      actionList={rowActions}
                      handleActionSelected={onRowAction}
                    />
                  </TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default LabSituation
