import clsx from 'clsx'
import React, { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { FormHelperText, createMuiTheme } from '@material-ui/core'
import MuiFilledInput from '@material-ui/core/FilledInput'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

import { COLORS, fontFamily } from 'helpers/constants'

import DefaultHint from 'ui/molecules/commons/DefaultHint'

import FormControl from './FormControl'

const useStyles = makeStyles(theme => ({
  errorDefault: {
    fontWeight: 600,
    color: COLORS.ERROR
  },
  baseInput: {
    background: '#f2f2f2',
    border: '1px solid #f2f2f2',
    borderRadius: '2px!important',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: '#fafafa'
      // border: '1px solid #cccccc'
    },
    '&.$Mui-disabled': {
      backgroundColor: '#dadada!important',
      color: '#999999!important'
    },
    '&.$MuiInputBase-multiline': {
      padding: '6px 12px'
    },
    '&.$MuiSelect-select': {
      padding: '6px 10px!important'
    },
    '& > .MuiInputBase-input': {
      resize: 'none'
    }
  },
  baseTextField: {
    paddingTop: '10px!important'
  },
  baseTextFieldRequired: {
    '& .MuiInputBase-root:before': {
      content: `'*'`,
      position: 'absolute',
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      left: '-12px',
      marginTop: '11px',
      fontSize: '25px'
    }
  },
  baseButton: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#7fcdbf',
    color: '#ffffff',
    minwidth: '80px!important',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#7fcdbf'
    }
  },
  spanError: {
    color: '#eb5757!important'
  },
  baseButtonAlternative: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#ffffff',
    color: '#000000',
    minwidth: '80px!important',
    border: '1px solid #7fcdbf',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#7fcdbf'
    }
  },
  baseText: {
    fontSize: '14px',
    fontWeight: 400
  },
  h3: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: 'normal'
  },
  label: {
    color: '#333',
    marginBottom: '0.4rem',
    fontSize: '14px',
    fontWeight: 600,
    display: 'inline-block'
  }
}))

const theme = createMuiTheme({
  fontFamily: fontFamily,
  fontFeatureSettings: 'lnum',
  typography: {
    fontFamily: fontFamily,
    fontFeatureSettings: 'lnum'
  },

  overrides: {
    MuiFilledInput: {
      input: {
        paddingTop: '10px!important',
        paddingBottom: '10px!important'
      },
      root: {
        borderTopLeftRadius: '0px!important',
        borderTopRightRadius: '0px!important',
        '&$error': {
          border: '1px solid #eb5757'
        }
      }
    },
    MuiButton: {
      root: {
        width: 'auto!important'
      }
    }
  }
})

const FilledInput = styled(MuiFilledInput)`
  position: relative;
  textarea {
    resize: none;
  }
`

const MaxLengthCounter = styled(FormHelperText)`
  flex: 0;
`

const ErrorContainer = styled.div`
  flex: 1;
`

const HelperContainer = styled.div`
  display: flex;
`

const BaseTextareaField = ({
  className,
  name,
  label,
  errors,
  hint,
  required,
  control,
  rows,
  rowsMax,
  rowsMin,
  maxLength,
  supressErrors = false,
  ...rest
}) => {
  const classes = useStyles()

  const hasErrors = errors && errors[name]

  const Component = useMemo(() => {
    const component = ({ inputRef, ...props }) => {
      return <TextareaAutosize ref={inputRef} {...props} />
    }

    component.displayName = 'TextareaAutosize'

    return component
  }, [])

  let inputProps = {}

  if (maxLength) {
    inputProps = {
      ...inputProps,
      maxLength
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <FormControl
          variant="filled"
          size="small"
          fullWidth
          className={className}
          $required={required}
        >
          {(label || hint) && (
            <label className={classes.label}>
              {label}
              {hint && (
                <div style={{ display: 'inline-block', marginTop: '3px' }}>
                  <DefaultHint content={hint} />
                </div>
              )}
            </label>
          )}
          <Controller
            name={name}
            control={control}
            render={({ onChange, value, ref, ...props }) => (
              <>
                <FilledInput
                  inputRef={ref}
                  inputComponent={Component}
                  inputProps={{ rows, rowsMax, rowsMin, ...inputProps }}
                  disableUnderline
                  name={name}
                  value={value}
                  onChange={onChange}
                  className={classes.baseInput}
                  error={hasErrors}
                  {...rest}
                  {...props}
                />
                {hasErrors || maxLength ? (
                  <HelperContainer>
                    <ErrorContainer className={name + '-error-container'}>
                      {hasErrors && !supressErrors ? (
                        <FormHelperText
                          className={classes.errorDefault}
                          error
                          style={{ marginLeft: 0 }}
                        >
                          {errors[name].message}
                        </FormHelperText>
                      ) : null}
                    </ErrorContainer>

                    {maxLength ? (
                      <MaxLengthCounter style={{ marginRight: 0 }}>
                        {value?.length || 0}/{maxLength}
                      </MaxLengthCounter>
                    ) : null}
                  </HelperContainer>
                ) : null}
              </>
            )}
          />
        </FormControl>
      </ThemeProvider>
    </>
  )
}

export default BaseTextareaField
