import React, { useState, useContext } from 'react'

import { menuList } from 'helpers/menuList'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import MenuButton from 'ui/atoms/Header/Public/MenuButton'
import MenuButtonLanguage from 'ui/atoms/Header/Public/MenuButtonLanguage'
import MenuButtonLogin from 'ui/atoms/Header/Public/MenuButtonLogin'

function MenuListComposition() {
  const [menuActive, setMenuActive] = useState(false)
  const { translate } = useContext(GlobalizationContext)

  function toggleMenu() {
    setMenuActive(!menuActive)
  }

  const handleClick = () => {
    setMenuActive(false)
  }

  const menuClasses = ['fa', 'btMenuMobile']
  if (menuActive) {
    menuClasses.push('fa-times')
  } else {
    menuClasses.push('fa-bars')
  }

  return (
    <nav className="wrapNav">
      <span className={menuActive ? 'wrapButtons open' : 'wrapButtons'}>
        {menuList.map(item => (
          <MenuButton onClick={handleClick} item={item} key={item.name}>
            {translate(item.name)}
          </MenuButton>
        ))}
      </span>
      <MenuButtonLanguage />
      <MenuButtonLogin />
      <i className={menuClasses.join(' ')} onClick={toggleMenu} />
    </nav>
  )
}

export default MenuListComposition
