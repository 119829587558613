// @ts-nocheck
import React, { useContext, useState } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import useStyles from 'ui/styles'

const ActionsButton = ({ handleClose, handleSubmit }) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  return (
    <>
      <DialogConfirm
        dialogText="REGISTER_INSTITUTION_CONFIRM_DIALOG"
        open={openConfirmDialog}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          setOpenConfirmDialog(false)
          handleClose()
        }}
      />
      <Grid
        container
        justify="flex-end"
        spacing={2}
        style={{ marginTop: '32px' }}
      >
        <Grid item xs={12} md={2}>
          <Button
            className={classes.baseButtonGrey}
            type={'button'}
            variant="contained"
            fullWidth
            onClick={() => setOpenConfirmDialog(true)}
          >
            {translate('COMMONS:CANCEL')}
          </Button>
        </Grid>

        <Grid item xs={12} md={2}>
          <Button
            fullWidth
            type="button"
            variant="contained"
            className={classes.baseButton}
            onClick={handleSubmit}
          >
            {translate('COMMONS:SAVE')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ActionsButton
