import React, { useCallback } from 'react'

import LabEquipService from 'api/LabEquipService'

import List, { EquipmentItem } from 'ui/atoms/PaginatedList'

import { useAdvancedFilter } from 'views/Search/hooks/useAdvancedFilter'

import PaginatedList from '../PaginatedList'

const EquipmentList = ({
  filter: filterString,
  advancedFilter = {},
  rowsPerPageList,
  listType,
  hideTypeControls,
  onListTypeChange: handleListTypeChange
}) => {
  const parsedAdvancedFilter = useAdvancedFilter(advancedFilter)

  const pageGetter = useCallback(
    async ({ pageSize, pageNumber }) => {
      /*
      const filter = {
        ...(filterString ? { search: filterString } : {}),
        type: 'EQUIP'
      }
      try {
        const resp = await LabEquipService.getList({
          pageSize,
          pageNumber,
          filter
        })
*/
      try {
        const filter = {
          ...(filterString ? { term: filterString } : {})
        }

        const resp = await LabEquipService.getAssetList(
          'equip',
          parsedAdvancedFilter,
          {
            pageSize,
            pageNumber,
            filter
          }
        )

        return resp.data
      } catch (error) {
        console.log(error)
      }
    },
    [filterString, parsedAdvancedFilter]
  )

  return (
    <PaginatedList
      hideTypeControls={hideTypeControls}
      pageGetter={pageGetter}
      rowsPerPageList={rowsPerPageList}
      listType={listType}
      onListTypeChange={handleListTypeChange}
    >
      {({ itemList, listType }) => (
        <List type={listType}>
          {itemList.map(item => (
            <EquipmentItem key={item.idAsset} type={listType} content={item} />
          ))}
        </List>
      )}
    </PaginatedList>
  )
}

export default EquipmentList
