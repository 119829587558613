import React, { useContext } from 'react'

import { Link } from '@material-ui/core'
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { fontFamily, primaryColor } from 'helpers/constants'

import { previewFile } from 'ui/atoms/AddedDocuments'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import Line from 'assets/icons/line.svg'

const useStyles = makeStyles(theme => ({}))

// const classes = useStyles();

const theme = createMuiTheme({
  fontFamily: fontFamily,
  fontFeatureSettings: 'lnum',
  typography: {
    fontFamily: fontFamily,
    fontFeatureSettings: 'lnum'
  },
  overrides: {
    MuiInput: {
      input: {
        '&:hover:before': {
          display: 'none'
        }
      },
      root: {
        borderTopLeftRadius: '0px!important'
      },
      underline: {
        '&:before,&:after': {
          display: 'none!important'
        }
      }
    },
    MuiPaper: {
      elevation2: {
        boxShadow: 'none'
      }
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: '0!important'
      }
    },
    MuiTypography: {
      body2: {
        display: 'block!important',
        color: 'rgb(51, 51, 51)',
        fontSize: '14px',
        fontWeight: 600
      },
      caption: {
        display: 'none!important'
      }
    },
    MuiTable: {
      root: {
        border: '1px solid transparent',
        borderBottom: 'none'
      }
    },
    MuiTablePagination: {
      root: {
        border: 'none'
      },
      selectRoot: {
        background: 'rgb(242, 242, 242)',
        margin: '0 25px 0 10px!important',
        padding: '4px',
        borderRadius: '3px',
        fontSize: '14px',
        fontWeight: 600
      }
    },
    MuiTableCell: {
      head: {
        fontSize: '12px',
        fontWeight: 600,
        border: '1px solid #ddd',
        padding: '6px 16px'
      },
      body: {
        fontSize: '14px',
        border: '1px solid #ddd',
        padding: '6px 16px'
      }
    },
    MuiTableRow: {
      root: {
        '&:nth-child(even)': {
          background: '#f2f2f2'
        }
      },
      footer: {
        background: '#fff!important'
      }
    }
  }
})

function ComparsionTable(props) {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  const renderValue = (type, changedValue) => {
    if (changedValue.field === 'documentList') {
      return renderDocument(type, changedValue)
    } else {
      return type === 'OLD'
        ? changedValue.oldValue
        : changedValue.newValue || '-'
    }
  }

  const renderDocument = (type, changedValue) => {
    const value =
      type === 'OLD'
        ? changedValue.oldDocumentList
        : changedValue.newDocumentList || '-'
    return (
      <>
        {!value?.length
          ? '-'
          : value.map((item, index) => (
              <>
                <Link
                  key={item.id}
                  component="button"
                  variant="body2"
                  onClick={() => previewFile(item)}
                  style={{ color: primaryColor }}
                >
                  {item.fileName}
                </Link>
                {index < value.length - 1 ? ', ' : null}
              </>
            ))}
      </>
    )
  }

  return (
    <>
      <div className={classes.defaultContainer}>
        <img src={Line} alt="" />
      </div>
      <h3 className={classes.h3}>{translate('TABLE_COMPARATION_TITLE')}</h3>
      <ThemeProvider theme={theme}>
        <TableContainer style={{ marginBottom: '40px' }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{translate('TABLE_COMPARATION_FIELD')}</TableCell>
                <TableCell>
                  {translate('TABLE_COMPARATION_PREVIOUS_VALUE')}
                </TableCell>
                <TableCell>
                  {translate('TABLE_COMPARATION_CHANGED_VALUE')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.changelogList.map(change => (
                <TableRow key={change.field}>
                  <TableCell>
                    {translate(
                      `LABORATORY:CHANGELOG.${change.field.toUpperCase()}`
                    )}
                  </TableCell>
                  <TableCell>{renderValue('OLD', change)}</TableCell>
                  <TableCell>{renderValue('NEW', change)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </>
  )
}
export default ComparsionTable
