import axios from './axios'

export const EquipmentService = {
  getAllMainTechnique(value) {
    return axios.get('/equipments/technique', { params: { term: value } })
  },
  getAllFinancingOrigin() {
    return axios.get('/equipments/typeFinancing')
  },
  getById(equipmentId) {
    return axios.get(`/equipments/${equipmentId}`)
  },
  getByIdPublic(equipmentId) {
    return axios.get(`/assets/equipment/${equipmentId}`).then(resp => resp.data)
  },
  getLaboratories() {
    return axios.get(`/laboratories/user`)
  },
  getAll({ pageSize, pageNumber, filter }, requestOptions = {}) {
    return axios
      .get('/equipments', {
        params: {
          page: pageNumber,
          size: pageSize,
          ...filter
        },
        ...{ requestOptions }
      })
      .then(resp => resp.data)
  },
  getFiltersSitutation() {
    return axios.get(`/equipments/filter`)
  },
  getListStates() {
    return axios
      .get(`/equipments/situations`)
      .then(({ data }) =>
        data.map(item => ({ ...item, idReason: item.idReason || 0 }))
      )
  },
  getListStateReasons(params) {
    return axios
      .get(`/reason-equip`, { params })
      .then(response => response.data)
  },
  postEquipment(postData) {
    return axios.post('/equipments', postData)
  },
  putEquipment(equipmentId, postData) {
    return axios.put(`/equipments/${equipmentId}`, postData)
  },
  inactivate(equipmentId, putData) {
    return axios.put(`/equipments/${equipmentId}/inactivate`, putData)
  },
  activate(equipmentId) {
    return axios.put(`/equipments/${equipmentId}/activate`)
  },
  exclude(equipmentId, putData) {
    return axios.put(`/equipments/${equipmentId}/exclude`, putData)
  },
  uploadEquipmentImage(equipmentId, file) {
    var formData = new FormData()
    formData.append('cropImage', file.cropImage)
    formData.append('image', file.image)
    formData.append('positionX', file.positionX)
    formData.append('positionY', file.positionY)

    return axios.post(`/equipments/${equipmentId}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updateEquipmentImage(file) {
    const idFile = file.image.id

    var formData = new FormData()
    formData.append('cropImage', file.cropImage)
    formData.append('positionX', file.positionX)
    formData.append('positionY', file.positionY)

    return axios.post(`/equipments/image/${idFile}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  removeEquipmentImage(equipmentId, imageId) {
    return axios.delete(`/equipments/${equipmentId}/image/${imageId}`)
  },
  replicateEquipments(equipmentId, quantity) {
    return axios.post(
      `/equipments/${equipmentId}/copy`,
      {},
      { params: { quantity } }
    )
  },
  updateEquipmentsInBatch(equipmentsId) {
    return axios.post(`/equipments/batch-update`, equipmentsId)
  }
}
export default EquipmentService
