import React, { useEffect, useRef, useContext } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import Grid from '@material-ui/core/Grid'

import ContactUsService from 'api/ContactUs'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { recaptchaEnabled } from 'helpers/environment'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import ReCAPTCHAInput from 'ui/atoms/ReCAPTCHAInput'
import { Title } from 'ui/atoms/Title'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import ContactUsActions from './Fragments/ContactUsActions'
import ContactUsForm from './Fragments/ContactUsForm'

const ContactUsSchema = Yup.object().shape({
  contact_name: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  contact_email: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .email('VALIDATION:EMAIL_ERROR'),
  contact_subject: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  contact_message: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  recaptcha: Yup.string().recaptcha()
})

function ContactUs() {
  const { translate } = useContext(GlobalizationContext)
  const recaptchaRef = useRef(null)

  const classes = useStyles()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const resolver = useYupValidationResolver(ContactUsSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver
  })
  const { handleSubmit } = formMethods

  const onSubmit = async formData => {
    let objContactUs = {
      fullName: formData.contact_name,
      email: formData.contact_email,
      subject: formData.contact_subject,
      message: formData.contact_message,
      gRecaptchaResponse: formData.recaptcha
    }

    recaptchaRef.current && recaptchaRef.current.reset()

    ContactUsService.postContactUs(objContactUs)
      .then(() => {
        notificationUseCases.newSuccess(
          translate('PUBLIC_AREA_CONTACT_US_SUCCESS')
        )
        formMethods.reset()
      })
      .catch(error => {
        if (
          error.response.status === 400 &&
          error.response.data.message == 'input.invalid'
        ) {
          notificationUseCases.newError('SPECIAL_CHARS')
        } else {
          notificationUseCases.newError(
            translate('PUBLIC_AREA_CONTACT_US_ERROR')
          )
        }
      })
  }

  return (
    <ContentContainer>
      <Loader />

      <Grid
        container
        spacing={3}
        style={{ marginBottom: '20px' }}
        display="flex"
        justify="center"
      >
        <Title variant="h1" item xs={12} $paddingTop>
          {translate('CONTACT_US')}
        </Title>

        <Grid item xs={12} md={5}>
          <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
            <ContactUsForm formMethods={formMethods} classes={classes} />

            {recaptchaEnabled ? (
              <ReCAPTCHAInput
                control={formMethods.control}
                errors={formMethods.errors}
                recaptchaRef={recaptchaRef}
              />
            ) : null}

            <ContactUsActions classes={classes} />
          </form>
        </Grid>
      </Grid>
    </ContentContainer>
  )
}

export default ContactUs
