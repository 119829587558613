import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'

import formationList from 'helpers/formationList'
import { phone } from 'helpers/generalInputMasks'

import BaseSelect from 'ui/atoms/BaseSelect'
import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

const UserData = ({ formMethods }) => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()
  const { errors, control } = formMethods

  return (
    <>
      <h3 className={classes.h3}>{translate('COMPLEMENTARY_DATA_TITLE')}</h3>
      <Grid container spacing={3} style={{ marginBottom: '12px' }}>
        <Grid item xs={12} md={6}>
          <BaseSelect
            required={true}
            placeholder={translate('COMMONS:SELECT')}
            label={translate('GRADUATION')}
            errors={errors}
            control={control}
            name="graduation"
            options={formationList}
            translateOptions={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BaseTextField
            required={false}
            label={translate('ORCID_ID')}
            placeholder={translate('COMMONS:TYPE')}
            errors={errors}
            control={control}
            name="ORCID"
            maxLength={255}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={6}>
          <BaseTextField
            required={true}
            label={translate('EMAIL')}
            placeholder={translate('COMMONS:TYPE')}
            errors={errors}
            control={control}
            name="email"
            maxLength={255}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BaseTextField
            required={true}
            label={translate('TELEPHONE')}
            placeholder={translate('COMMONS:TYPE_PHONE')}
            errors={errors}
            control={control}
            name="telephone"
            maxLength={255}
            handleInput={phone}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={12}>
          <BaseTextareaField
            required={false}
            label={translate('LINK_LATTES')}
            placeholder={translate('COMMONS:TYPE')}
            errors={errors}
            control={control}
            name="linkLattes"
            maxLength={700}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default UserData
