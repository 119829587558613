import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Typography } from '@material-ui/core'
import { Link as MuiLink } from '@material-ui/core'

import { generatePrivatePath } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import { Container, NewErrorMessage } from './style'

const ForbiddenPage = () => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <Container>
      <NewErrorMessage>
        <Trans
          parent={Typography}
          t={translate}
          // @ts-ignore
          variant={'body2'}
          i18nKey={'COMMONS:FORBIDDEN_TEXT'}
          components={{
            Link: <MuiLink component={Link} to={generatePrivatePath('/')} />
          }}
        />
      </NewErrorMessage>
    </Container>
  )
}

export default ForbiddenPage
