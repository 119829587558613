import clsx from 'clsx'
import React, { useContext } from 'react'
import { Controller } from 'react-hook-form'

import {
  createMuiTheme,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'

import { fontFamily, COLORS } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import FormControl from './FormControl'

const useStylesLocal = makeStyles({
  selector: {
    background: '#F2F2F2',
    fontWeight: 600,
    fontSize: '13px',
    padding: '7px 12px',
    '&:hover': {
      background: '#FAFAFA'
    }
  },
  errorDefault: {
    fontWeight: 600,
    color: COLORS.ERROR
  }
})

const theme = createMuiTheme({
  fontFamily: fontFamily,
  fontFeatureSettings: 'lnum',
  typography: {
    fontFamily: fontFamily,
    fontFeatureSettings: 'lnum'
  },
  overrides: {
    MuiFilledInput: {
      input: {
        paddingTop: '10px!important',
        paddingBottom: '10px!important'
      },
      root: {
        borderTopLeftRadius: '0px!important',
        borderTopRightRadius: '0px!important',
        '&$error': {
          border: '1px solid #eb5757'
        }
      }
    },
    MuiList: {
      root: {
        padding: '0!important'
      }
    }
  }
})

export function BaseSelect(props) {
  const {
    disabled,
    required,
    label,
    errors,
    control,
    name,
    options,
    translateOptions,
    descriptionKey,
    idKey,
    placeholder,
    initialValue,
    optionRenderer
  } = props
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  const translateFn = translateOptions
    ? translate
    : optionRenderer || (name => name)

  const id = idKey || 'id'
  const description = descriptionKey || 'name'
  const classesLocal = useStylesLocal()

  const MenuProps = {
    MenuListProps: { id: `${name}_menu` },
    PaperProps: {
      style: {
        maxHeight: '215px'
      }
    }
  }

  return (
    <React.Fragment>
      <label className={classes.label}>{label}</label>
      <ThemeProvider theme={theme}>
        <FormControl
          variant="filled"
          size="small"
          fullWidth
          $required={required}
        >
          <Controller
            control={control}
            name={name}
            defaultValue=""
            render={({ ref, onChange, ...controlProps }) => (
              <Select
                inputRef={ref}
                {...controlProps}
                disabled={disabled}
                className={clsx(
                  classes.baseSelect,
                  !controlProps.value && classes.emptySelect
                )}
                error={errors ? (errors[name] ? true : false) : false}
                disableUnderline
                displayEmpty={true}
                renderValue={selected => {
                  const selectedOption = options?.find(
                    option => option[id] == selected
                  )
                  return selectedOption
                    ? translateFn(selectedOption[description], selectedOption)
                    : placeholder
                }}
                onChange={evt => {
                  onChange(evt)
                  props.handleChange && props.handleChange(evt)
                }}
                MenuProps={MenuProps}
              >
                {options?.map(obj => (
                  <MenuItem
                    key={obj[id]}
                    value={obj[id]}
                    classes={{ root: classesLocal.selector }}
                  >
                    {translateFn(obj[description], obj)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        {errors && errors[name] && (
          <div className={name + '-error-container'}>
            <FormHelperText className={classesLocal.errorDefault} error>
              {errors[name].message}
            </FormHelperText>
          </div>
        )}
      </ThemeProvider>
    </React.Fragment>
  )
}

export default BaseSelect
