import React, { useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { Grid } from '@material-ui/core'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'

import DoesntNeedComplementation from './Fragments/DoesntNeedComplementation'
import NeedsComplementation from './Fragments/NeedsComplementation'

const Reprove = ({ params, formMethods }) => {
  const { translate } = useContext(GlobalizationContext)

  const { control, errors } = formMethods

  const needsComplementation = useWatch({
    control,
    name: 'needsComplementation'
  })

  return (
    <>
      <Grid item xs={12}>
        <RadioGroup
          required
          name="needsComplementation"
          label={translate('ANALYZE.REPROVE.NEEDS_COMPLEMENATION')}
          control={control}
          error={errors}
          inline
          row
          options={[
            { id: true, name: translate('COMMONS:YES') },
            { id: false, name: translate('COMMONS:NO') }
          ]}
        />
      </Grid>

      {String(needsComplementation) === 'true' ? (
        <NeedsComplementation formMethods={formMethods} />
      ) : null}

      {String(needsComplementation) === 'false' ? (
        <DoesntNeedComplementation formMethods={formMethods} params={params} />
      ) : null}
    </>
  )
}

export default Reprove
