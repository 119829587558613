// @ts-nocheck
import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AddedDocuments, { previewFile } from 'ui/atoms/AddedDocuments'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RepresentantsList from 'ui/atoms/RepresentantsList'

const Responsible = ({
  responsible,
  responsibleDocuments = [],
  representantsList
}) => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <>
      <Typography variant="subtitle1" style={{ marginTop: '32px' }}>
        {translate('RESPONSIBLE_DATA')}
      </Typography>

      <Grid container spacing={3} style={{ marginTop: '22px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            label="CPF"
            name="responsibleCPF"
            value={responsible?.cpf || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Nome"
            name="responsibleName"
            value={responsible?.name || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Sobrenome"
            name="responsibleLastName"
            value={responsible?.last_name || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '24px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Email Institucional"
            name="responsibleEmail"
            value={responsible?.email || ''}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle2" style={{ marginTop: '32px' }}>
        {translate('INSTITUTION_BOND_TEXT')}
      </Typography>

      <AddedDocuments docs={responsibleDocuments} viewFile={previewFile} />

      <Typography variant="subtitle1" style={{ marginTop: '32px' }}>
        {translate('RESPONSIBLE')}
      </Typography>

      <RepresentantsList
        disabled={true}
        representants={representantsList}
        setRepresentants={() => {}}
      />
    </>
  )
}

export default Responsible
