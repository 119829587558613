import React from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'

import Container from 'ui/atoms/Container'
import Logo from 'ui/atoms/Header/Commons/Logo'
import HeaderGov from 'ui/atoms/Header/Public/HeaderGov'
import Menu from 'ui/molecules/Header/Public/Menu'
import 'assets/scss/header.scss'

const WrapHeader = styled.header`
  box-shadow: 5px 5px 5px rgba(51, 51, 51, 0.15);
  > div {
    position: relative;
  }
`

function Header() {
  return (
    <>
      <HeaderGov />
      <WrapHeader>
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Logo />
            <Menu />
          </Box>
        </Container>
      </WrapHeader>
    </>
  )
}

export default Header
