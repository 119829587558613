import styled, { css } from 'styled-components'

import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

import { primaryColor } from 'helpers/constants'

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;

  &.list {
  }

  &.grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-row-gap: 20px;
    grid-column-gap: 20px;

    ${props =>
      !props.onePerLine &&
      css`
        ${props.theme.breakpoints.between('sm', 'md')} {
          grid-template-columns: 1fr 1fr;
        }

        ${props.theme.breakpoints.up('md')} {
          grid-template-columns: 1fr 1fr 1fr;
        }
      `}
  }
`

export const Picture = styled(Avatar).attrs({ variant: 'square' })`
  color: #ffffff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 56px;

  &.MuiAvatar-colorDefault {
    background-color: ${primaryColor};
  }
`

export const StatusContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: grid;
  grid-row-gap: 10px;
  padding-top: 20px;
`
export const ItemStatus = styled.li`
  &:before {
    padding: 3px 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 10px 10px 0px;
    font-weight: 600;

    content: '${props => props.content}';
    width: auto;
  }

  &.warning:before {
    background: #f59a23;
    color: #000000;
  }

  &.danger:before {
    background: #d9001b;
    color: #ffffff;
  }
`

export const PictureContainer = styled.div`
  position: relative;

  ${ListContainer}.list & {
    width: 297px;
    height: 100%;
    flex: 0 0 auto;
  }

  ${ListContainer}.grid & {
    padding-bottom: 56.25%;
    width: 100%;
  }
`

export const Title = styled(Typography).attrs({
  variant: 'h3'
})`
  flex: 1 0 auto;

  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const About = styled(Typography).attrs({
  variant: 'body2'
})`
  flex: 1 0 auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${ListContainer}.list & {
    -webkit-line-clamp: 5;
  }

  ${ListContainer}.grid & {
    -webkit-line-clamp: 3;
  }
`

export const Param = styled(Typography).attrs({
  variant: 'body2'
})`
  strong {
    font-weight: 600;
    padding-right: 5px;
  }

  ${props =>
    props.$situationParam &&
    css`
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;

      color: ${props.$textPrimary
        ? primaryColor
        : props.theme.palette.grey[800]};

      .icon {
        color: ${props.$iconPrimary
          ? primaryColor
          : props.theme.palette.grey[300]};
        font-size: 16px;
        margin-right: 3px;
        margin-top: -1px;

        &.fa-users {
          width: 20px;
        }
      }

      .hint-icon {
        font-size: 15px;
      }
    `}
`

export const ParamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: flex-end;

  & .actions {
    display: flex;
    margin-top: 16px;
  }

  ${ListContainer}.list & {
    & .actions {
      justify-content: flex-end;
    }

    .MuiGrid-item:not(.MuiGrid-container) {
      display: grid;
    }
  }

  ${ListContainer}.grid & {
    & .actions {
      flex-direction: column;
    }

    & .line-container {
      flex-direction: column;

      & > .MuiGrid-item {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1 1 auto;
`

export const ItemContainer = styled.div`
  display: flex;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;

  ${ListContainer}.list & {
    border: 1px solid #cccccc;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex-direction: row;
    min-height: 300px; //382px
  }

  ${ListContainer}.grid & {
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    /* height: 540px; */
  }
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
`
