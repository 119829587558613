import React from 'react'
import styled from 'styled-components'

import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import MuiTypography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  root: {
    margin: 0,
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  head: {
    fontSize: 18,
    fontWeight: 700,
    color: '#333333'
  }
})

export const Typography = styled(MuiTypography)``

export const Dialog = withStyles(theme => ({
  paper: {
    boxSizing: 'border-box',
    minWidth: 645,
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      minWidth: '300px'
    }
  }
}))(MuiDialog)

export const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, variant, ...other } = props

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MuiTypography variant={variant} className={classes.head}>
        {children}
      </MuiTypography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const DialogContent = withStyles(() => ({
  root: {
    border: 'none',
    padding: 0,
    marginTop: '20px',
    overflow: 'visible'
  }
}))(MuiDialogContent)

export const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
    marginTop: '16px'
  }
}))(MuiDialogActions)

export const Section = styled.section`
  margin-top: 30px;

  &.area {
    &::after {
      content: '0/500';
      right: 10px;
      bottom: -20px;
      font-size: 12px;
    }
  }

  &.required {
    position: relative;

    &::before,
    &::after {
      display: block;
      position: absolute;
    }

    &::before {
      content: '*';
      left: -15px;
      font-size: 24px;
      top: 55%;
    }
  }
`
