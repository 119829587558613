import React, { useContext } from 'react'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

import { md } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'
const Title = styled.h4`
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin: 3px;
`
const Card = styled(Grid)`
  background: #fafafa;
  border: 1px solid #cccccc;
  height: 100%;
  width: 100%;
  padding: 17px;
  border-radius: 3px;
  box-sizing: border-box;

  @media (max-width: ${md}) {
    width: 100%;
  }
`
const GridCustom = styled(Grid)`
  box-sizing: border-box;
  &:first-child {
    padding-right: 15px;
  }
  &:last-child {
    padding-left: 15px;
  }
  @media (max-width: ${md}) {
    margin-bottom: 10px;
    padding: 0 !important;
  }
`
const List = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    background: #b2dffb;
    border-radius: 2px;
    padding: 9px 16px;
    font-weight: 500;
    white-space: nowrap;
    margin: 3px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

function TagsList({ customGrid, title, list, ...props }) {
  const { translate } = useContext(GlobalizationContext)

  return (
    <GridCustom item xs={12} md={customGrid || 6} {...props}>
      <Card>
        <Title>{translate(title)}</Title>
        <List>
          {list.map(item => (
            <li key={item.name}>{item.name}</li>
          ))}
        </List>
      </Card>
    </GridCustom>
  )
}
export default TagsList
