import styled from 'styled-components'

export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px;
  background-color: rgba(0, 155, 127, 1);
  border-radius: 2px;
  color: #fff;

  svg {
    padding-left: 8px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 104, 85, 1);
  }
`

export const ErrorContainer = styled.div``

export const Text = styled.label`
  font-size: 16px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`
