import React, { useContext } from 'react'
import styled from 'styled-components'

import { primaryColor } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const Position = styled.div`
  min-width: 24px;
  height: 24px;
  font-size: 14px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    color: #fff;
    content: '${props => props.content}';
  }
`

const TitleCustom = styled.div`
  color: #999999;
  font-size: 20px;
  font-weight: 600;
  min-height: ${props => (props.hsize ? props.hsize : 0)};
  display: flex;
  padding: 10px 0;
  color: #333333;
`

function Title({ content, position, ...rest }) {
  const { translate } = useContext(GlobalizationContext)

  return (
    <TitleCustom {...rest}>
      {position !== undefined && <Position content={position} />}
      {translate(content)}
    </TitleCustom>
  )
}
export default Title
