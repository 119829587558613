import React, { useContext } from 'react'

import { actions as campaignActions } from 'framework/store/slices/campaign.slice'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'

import {
  BannerContent,
  BannerWrapper,
  Button,
  Content,
  Title,
  TitleRow
} from './style'

const BannerCovid = () => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <BannerWrapper>
      <BannerContent>
        <TitleRow>
          <Title>{translate('TITLE')}</Title>
        </TitleRow>

        <Content>{translate('CONTENT')}</Content>

        <Button to="/campanha-covid">{translate('HOW_TO_PARTICIPATE')}</Button>
      </BannerContent>
    </BannerWrapper>
  )
}

const mapState = state => ({
  showBanner: state.campaign.showBanner
})

const mapDispatch = dispatch => ({
  hideBanner: () => dispatch(campaignActions.hideBanner())
})

export default Wrapper(BannerCovid, {
  namespace: 'BANNER_COVID',
  mapState,
  mapDispatch
})
