import styled, { css } from 'styled-components'

import { COLORS } from '../../helpers/constants'

const commonStyle = css`
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
`

export const SuccessMessage = styled.div`
  ${commonStyle}
  border: 1px solid ${COLORS.SUCCESS};
  color: ${COLORS.SUCCESS_DARK};
  background-color: ${COLORS.SUCCESS_LIGHT};
`

export const ErrorMessage = styled.div`
  ${commonStyle}
  border: 1px solid ${COLORS.ERROR};
  color: ${COLORS.ERROR};
  background-color: ${COLORS.ERROR_LIGHT};
`

export const WarningMessage = styled.div`
  ${commonStyle}
  border: 1px solid ${COLORS.WARNING_DARK};
  color: ${COLORS.WARNING};
  background-color: ${COLORS.WARNING_LIGHT};
`

export const InfoMessage = styled.div`
  ${commonStyle}
  border: 1px solid ${COLORS.INFO};
  color: ${COLORS.INFO};
  background-color: ${COLORS.INFO_LIGHT};
`
