import React from 'react'

import LabChangeLog from './Fragments/LabChangeLog'
import LabContacts from './Fragments/LabContacts'
import LabRejected from './Fragments/LabRejected'
import LabSituation from './Fragments/LabSituation'

const Situation = ({ laboratoryData }) => {
  const { situation } = laboratoryData.laboratory

  return (
    <>
      <LabSituation laboratoryData={laboratoryData} />

      {
        {
          REJECTED: <LabRejected laboratoryData={laboratoryData} />,
          WAITING_APPROVAL: <LabContacts laboratoryData={laboratoryData} />,
          WAITING_EDIT_APPROVAL: (
            <>
              <LabContacts laboratoryData={laboratoryData} />
              <LabChangeLog laboratoryData={laboratoryData} />
            </>
          )
        }[situation]
      }
    </>
  )
}

export default Situation
