import styled from 'styled-components'

import { COLORS } from 'helpers/constants'

export const CustomError = styled.div`
  color: ${COLORS.ERROR};
  font-size: 12px;
  margin-top: 7px;
`

export const CustomContainer = styled.div`
  padding-bottom: 1.5rem;
`

export const CustomTitle = styled.div`
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 1rem;
`
