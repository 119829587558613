import React from 'react'

import Icon from '@material-ui/core/Icon'

import { Container, Avatar, AvatarHover } from './style'

const Picture = ({ src, onClick, defaultValue }) => {
  return (
    <Container onClick={onClick}>
      <Avatar src={src} size="160px">
        {defaultValue}
      </Avatar>
      <AvatarHover>
        <Icon fontSize="large" className="fas fa-pen"></Icon>
      </AvatarHover>
    </Container>
  )
}

export default Picture
