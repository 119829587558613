import styled from 'styled-components'

import { COLORS } from 'helpers/constants'

export const WrapSuggestions = styled.div`
  position: relative;
  z-index: 2;
  .react-autosuggest__suggestions-container {
    position: absolute;
    margin: 0;
    padding: 0;
    font-size: 14px;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: scroll;
    list-style-type: none;
    width: 100%;
    background: #f2f2f2;
    padding: 0;
    ul {
      padding: 0;
    }
    li {
      display: block;
      padding: 6px 15px;
      cursor: pointer;
      &:hover {
        background: #0000000a;
      }
      span {
        color: #333;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  input:disabled {
    background: #dadada;
    border-color: #dadada;
    color: #999;
  }
  &.hasError input {
    border-color: ${COLORS.ERROR};
  }
  &:before {
    top: 9px;
    left: -11px;
    content: '*';
    display: flex;
    position: absolute;
    font-size: 25px;
    align-items: center;
    vertical-align: middle;
  }
`
export const InstitutionContainer = styled.div`
  align-items: center;
  background: #f2f2f2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  height: 60px;
  margin-top: 24px;
  padding: 10px 32px;
`
export const TrashIcon = styled.img`
  cursor: pointer;
  height: 20px;
  &:hover {
    filter: invert(37%) sepia(86%) saturate(827%) hue-rotate(131deg)
      brightness(97%) contrast(101%);
  }
`
