import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { InstitutionService } from 'api'
import InstitutionRegister from 'api/InstitutionRegister'
import { AuthContext } from 'context/AuthContext'
import { generatePrivatePath, history } from 'helpers/history'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import RepresentantsList from 'ui/atoms/RepresentantsList'
import TermsOfUse from 'ui/atoms/TermsOfUse'
import wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import Loader from 'ui/molecules/Loader'

import { notificationUseCases } from 'views/Notification/providers'

import formSchema from './formSchema'
import ActionsButton from './Fragments/ActionsButton'
import InstitutionData from './Fragments/InstitutionData'
import NewResponsible from './Fragments/NewResponsible'
import ResponsibleData from './Fragments/ResponsibleData'

const RegisterNewInstitution = () => {
  const { translate } = useContext(GlobalizationContext)
  const { userData } = useContext(AuthContext)
  const [representantsList, setRepresentantsList] = useState([])
  const [documents, setDocuments] = useState([])

  const [termsOfUse, setTermsOfUse] = useState(true)

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver
  })

  useEffect(() => {
    const user = {
      email: userData.email,
      name: userData.firstName,
      permission: 'ROLE_INSTITUICAO_TOTAL',
      responsible: true,
      situation: 'PENDING'
    }
    setRepresentantsList(arr => [...arr, user])
  }, [userData])

  const onFileChange = fileList => {
    if (!fileList || !fileList.length) return
    setDocuments(documents => documents.concat(fileList))
  }

  const onRemoveFile = file => {
    const remaningFiles = documents.filter(item => item !== file)
    setDocuments(remaningFiles)
  }

  const onSubmit = async formData => {
    const cnpj = formData.cnpj.replace(/[^\d]+/g, '')
    formData.representativeList = representantsList
    formData.situation = 'ACTIVE'
    formData.termsOfUse = termsOfUse
    formData.cnpj = cnpj

    const error = await checkIfInstitutionAlreadyExists(formData)
    if (
      error &&
      error.response.data.message === 'social.reason.already.exists'
    ) {
      return formMethods.setError('social_reason', {
        type: 'socialReasonAlreadyExists',
        message: translate('MESSAGES.INSTITUTION_ALREADY_EXISTS'),
        shouldFocus: true
      })
    } else if (
      error &&
      error.response.data.message === 'both.fields.already.exists'
    ) {
      formMethods.setError('social_reason', {
        type: 'fieldValueAlreadyTaken',
        message: translate('MESSAGES.INSTITUTION_ALREADY_EXISTS'),
        shouldFocus: true
      })
      return formMethods.setError('initials', {
        type: 'fieldValueAlreadyTaken',
        message: translate('MESSAGES.INITIALS_ALREADY_EXISTS')
      })
    } else if (
      error &&
      error.response.data.message === 'initials.already.exists'
    ) {
      return formMethods.setError('initials', {
        type: 'fieldValueAlreadyTaken',
        message: translate('MESSAGES.INITIALS_ALREADY_EXISTS'),
        shouldFocus: true
      })
    }

    try {
      await InstitutionRegister.checkCnpj(cnpj)
      submitIfInstitutionIsNotFound(formData, cnpj)
    } catch (error) {
      const message = error.response?.data?.message
      if (['cnpj.inactive.already.exists'].indexOf(message) >= 0) {
        formMethods.setError('cnpj', {
          type: 'fieldValueAlreadyTaken',
          message: translate('MESSAGES.INVALID_CNPJ'),
          shouldFocus: true
        })
      } else if (['cnpj.already.exists'].indexOf(message) >= 0) {
        formMethods.setError('cnpj', {
          type: 'fieldValueAlreadyTaken',
          message: translate('MESSAGES.CNPJ_ALREADY_EXISTS'),
          shouldFocus: true
        })
      } else {
        // @ts-ignore
        notificationUseCases.newError()
      }
    }
  }

  const checkIfInstitutionAlreadyExists = async formData => {
    try {
      const data = await InstitutionService.checkIfInstitutionExists(formData)
      return data
    } catch (error) {
      return error
    }
  }

  const submitIfInstitutionIsNotFound = async (formData, cnpj) => {
    try {
      await InstitutionRegister.registerInstitution(formData)
      await uploadFilesInSequence(
        InstitutionRegister.registerDocument,
        cnpj,
        documents
      )
      notificationUseCases.newSuccess(
        translate('MESSAGES.REGISTRATION_SUCCESS')
      )
      history.push(generatePrivatePath('/infraestructure'))
    } catch (error) {
      const message = error.response?.data?.message

      if (
        ['invalid.total.representative.permission.institution'].indexOf(
          message
        ) >= 0
      ) {
        notificationUseCases.newError(
          translate('MESSAGES.NEEDS_MORE_RESPONSIBLE')
        )
      }

      if (
        ['invalid.total.representativeMax.institution'].indexOf(          
          message
        ) >= 0
      ) {
        notificationUseCases.newError(
          translate('MESSAGES.NEEDS_ONE_RESPONSIBLE_MAX')
        )
      }

      if (message === 'maximum.representative.number') {
        notificationUseCases.newError(
          translate('REGISTER_INSTITUTION_MAXIMUM_USERS_REACH')
        )
      } else if (message === 'user.already.other.institution') {
        notificationUseCases.newError(
          translate('REGISTER_INSTITUTION_ALREADY_IN_OTHER_INSTITUTION')
        )
      }
    }
  }

  const uploadFilesInSequence = async (uploadMethod, id, list) => {
    const uploadedFiles = await list.reduce(
      async (promise, { file, ...savedFile }) => {
        return promise
          .then(uploadedFiles => {
            if (savedFile && savedFile.id) {
              return Promise.resolve(uploadedFiles.concat(file))
            } else {
              return uploadMethod(id, file)
                .then(resp => {
                  return uploadedFiles.concat(resp.data)
                })
                .catch(() => {
                  return uploadedFiles.concat(file)
                })
            }
          })
          .then(data => {
            return data
          })
      },
      Promise.resolve([])
    )

    return uploadedFiles
  }

  const handleClose = () => {
    history.push(generatePrivatePath('/infraestructure'))
  }
  return (
    <>
      <Loader />
      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <InstitutionData formMethods={formMethods} />
        <ResponsibleData
          user={userData}
          onSelectFiles={onFileChange}
          documents={documents}
          onRemoveFile={onRemoveFile}
        />
        <NewResponsible
          setRepresentants={setRepresentantsList}
          representants={representantsList}
        />
        <RepresentantsList
          disabled={false}
          representants={representantsList}
          setRepresentants={setRepresentantsList}
        />
        <div style={{ marginTop: '32px' }}>
          <TermsOfUse formMethods={formMethods} setTermsOfUse={setTermsOfUse} />
        </div>

        <ActionsButton
          handleClose={handleClose}
          handleSubmit={formMethods.handleSubmit(onSubmit)}
        />
      </form>
    </>
  )
}

export default wrapper(RegisterNewInstitution, {
  mapState: null,
  mapDispatch: null,
  namespace: 'REGISTER_NEW_INSTITUTION'
})
