import { SharingService } from 'api'

import { notificationUseCases } from 'views/Notification/providers'

import RequestSharingUseCases from './requestSharing.useCases'
export * from './requestSharing.useCases'

export const requestSharingUseCases = new RequestSharingUseCases({
  SharingService,
  notificationUseCases
})
