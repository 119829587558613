import React from 'react'
import { Controller } from 'react-hook-form'

import {
  FormControlLabel,
  Switch,
  Checkbox,
  Radio,
  FormGroup,
  FormHelperText,
  FormLabel
} from '@material-ui/core'

import FormControl from 'ui/atoms/FormControl'

const typeEnum = {
  Switch,
  Checkbox,
  Radio
}

/**
 * @typedef {"Checkbox"|"Switch"|"Radio"} ControlLabelType
 * @param {object} props
 * @param {ControlLabelType} props.type
 * @param {import('react-hook-form').Control} props.control
 * @param {string} props.name
 */
const ControlLabel = ({
  style,
  control,
  name,
  type,
  errors,
  required,
  disabled,
  children
}) => {
  if (process.env.NODE_ENV !== 'production') {
    if (!type || !typeEnum[type]) {
      throw new Error('type must be either Switch, Checkbox or Radio')
    }
  }

  const ControlComp = typeEnum[type]

  return (
    <FormControl $required={required} style={style} component="div">
      <FormGroup>
        <Controller
          defaultValue={false}
          name={name}
          control={control}
          render={({ value, onChange, ...props }) => (
            <FormControlLabel
              label={children}
              control={
                <ControlComp
                  disabled={disabled}
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  name={name}
                  color="primary"
                  {...props}
                />
              }
            />
          )}
        />
      </FormGroup>
      {errors && errors[name] && (
        <div className={name + '-error-container'}>
          <FormHelperText error>{errors[name].message}</FormHelperText>
        </div>
      )}
    </FormControl>
  )
}

export default ControlLabel
