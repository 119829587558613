import React from 'react'

import { styled } from '@material-ui/core'

import PaginatedList from 'ui/atoms/PaginatedList'
import EquipmentItem from 'ui/atoms/PaginatedList/EquipmentItem'
import LaboratoryItem from 'ui/atoms/PaginatedList/LaboratoryItem'

export const CARD_TYPE = {
  LABORATORY: 'CARD_LABORATORY',
  EQUIPMENT: 'CARD_EQUIPMENT'
}

const CustomPaginatedList = styled(PaginatedList)``

const Card = ({ card, type }) => {
  const Component = type == 'EQUIP' ? EquipmentItem : LaboratoryItem

  return (
    <CustomPaginatedList onePerLine>
      <Component content={card} type="GRID" />
    </CustomPaginatedList>
  )
}
export default Card
