export default class EditUserUseCases {
  /**
   * @param {object} props
   * @param {import('api/AdminService').AdminService} props.AdminService
   * @param {import('views/Notification/providers/notification.useCases').default} props.notificationUseCases
   */
  constructor({ AdminService, notificationUseCases }) {
    this.adminService = AdminService
    this.notificationUseCases = notificationUseCases
  }

  async getUser(userId) {
    return await this.adminService.getUser(userId)
  }

  async updateUser(userId, userData) {
    try {
      const updatedUser = await this.adminService.updateUser(userId, userData)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      return updatedUser
    } catch (error) {
      this.notificationUseCases.newError(error)
      throw error
    }
  }

  async resendInvite(invite) {
    try {
      const inviteData = {
        idLab: invite.id_lab,
        cnpj: invite.cnpj
      }

      const response = await this.adminService.resendInvite(
        invite.id_user_invite,
        inviteData
      )
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      return response
    } catch (error) {
      this.notificationUseCases.newError()
      throw error
    }
  }
}
