import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'

import Card from 'ui/molecules/commons/Card'

import 'slick-carousel/slick/slick.scss'
import 'assets/scss/customSlickSlider.scss'

const CustomSlider = styled(Slider)`
  .slick-track {
    display: flex;

    & > .slick-slide {
      flex-grow: 1;
      height: unset;
      padding: 7px;

      & > div {
        height: 100%;
      }

      .grid {
        height: 100%;
      }
    }
  }
`

const CarrousselItem = ({ items = [], history }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: items.length < 3 ? items.length : 3,
    slidesToScroll: 3,
    adaptiveHeight: false,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <>
      <CustomSlider {...settings}>
        {items.map((card, index) => (
          <Card key={index} card={card} type={history} />
        ))}
      </CustomSlider>
    </>
  )
}
export default CarrousselItem
