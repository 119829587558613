import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo
} from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import {
  Grid,
  Icon,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'

import EquipmentService from 'api/EquipmentService'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Param } from 'ui/atoms/PaginatedList/style'
import RequestSharing from 'ui/atoms/RequestSharing'
import TagsList from 'ui/atoms/TagsList'
import { Subtitle, Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'
import Carroussel from 'ui/molecules/commons/Carroussel'
import DefaultHint from 'ui/molecules/commons/DefaultHint'

import { breadcrumbUseCases } from 'views/App/Fragments/PublicRoutes/providers'

import { PCustom, Text, StyledTableRow } from './style'

function DetailEquipment() {
  const { translate } = useContext(GlobalizationContext)
  const { id: equipmentId } = useParams()

  const [equipment, setEquipment] = useState({})

  useEffect(() => {
    breadcrumbUseCases.setBreadcrumbData({})
    window.scrollTo(0, 0)
  }, [])

  const getEquipment = useCallback(async () => {
    const equipment = await EquipmentService.getByIdPublic(equipmentId)
    breadcrumbUseCases.setBreadcrumbData(equipment)
    setEquipment(equipment)
  }, [equipmentId])

  const RowList = useMemo(
    () =>
      equipment.parameterList
        ? equipment.parameterList.map((parameter, index) => {
            return (
              <StyledTableRow key={parameter.id}>
                <TableCell scope="row" align="center">
                  {index + 1}
                </TableCell>
                <TableCell scope="row" align="left">
                  {parameter.name}
                </TableCell>
                <TableCell scope="row" align="left">
                  {parameter.value}
                </TableCell>
                <TableCell scope="row" align="left">
                  {parameter.unit}
                </TableCell>
              </StyledTableRow>
            )
          })
        : null,
    [equipment.parameterList]
  )

  const handleBack = () => {
    window.history.back()
  }

  return (
    <AsyncLoad promiseFn={getEquipment} minHeight={150}>
      <ContentContainer>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Title
            item
            xs={12}
            $noPaddingBottom
            $paddingTop
            action={
              <Typography variant="h4" noWrap>
                {`${translate('COMMONS:LAST_UPDATED')}: ${
                  equipment.updatedDate || '-'
                }`}
              </Typography>
            }
            onBack={handleBack}
          >
            {equipment.name || ''}
          </Title>

          {equipment.hasSharing || equipment.multiuserIndex ? (
            <Grid
              container
              item
              xs={12}
              style={{ paddingTop: '0' }}
              spacing={2}
            >
              {equipment.hasSharing ? (
                <Grid item>
                  <Param $situationParam $iconPrimary>
                    <Icon className="icon fas fa-share-alt-square" />
                    {translate('SHARING:SHARING_ENABLED')}
                  </Param>
                </Grid>
              ) : null}

              {equipment.multiuserIndex ? (
                <Grid item>
                  <Param
                    variant="h4"
                    $situationParam
                    $textPrimary={equipment.hasSharing}
                    $iconPrimary={equipment.hasSharing}
                  >
                    <Icon className="icon fas fa-users" />
                    {translate('SHARING:MULTIUSER.TITLE')}
                    <DefaultHint
                      className="hint-icon"
                      content={translate('SHARING:MULTIUSER.HINT')}
                    />
                  </Param>
                </Grid>
              ) : null}
            </Grid>
          ) : null}

          {equipment.imgUrlList && equipment.imgUrlList.length > 0 && (
            <Grid item xs={12}>
              <Carroussel
                images={equipment.imgUrlList}
                dots={true}
                infinite={true}
                slidesToShow={3}
              />
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingBottom: '30px' }}
          >
            <Subtitle item xs={12}>
              {translate('ABOUT')}
            </Subtitle>

            <Grid item xs={12}>
              <Text>{equipment.about}</Text>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingBottom: '30px' }}
          >
            <Subtitle item xs={12}>
              {translate('ORIGIN')}
            </Subtitle>

            <Grid item xs={12}>
              <PCustom>
                <MuiLink
                  component={Link}
                  variant="body2"
                  to={`/laboratory/${equipment.idLab}`}
                >
                  {equipment.initialsLab}
                </MuiLink>
              </PCustom>
              <Text>{equipment.nameLab}</Text>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingBottom: '30px' }}
          >
            <Subtitle item xs={12}>
              {translate('DATA')}
            </Subtitle>

            <Grid item xs={12}>
              <PCustom>{translate('BRAND')}</PCustom>
              <Text>{equipment.brand}</Text>
            </Grid>
            <Grid item xs={12}>
              <PCustom>{translate('MODEL')}</PCustom>
              <Text>{equipment.model}</Text>
            </Grid>

            {equipment.link ? (
              <Grid item xs={12}>
                <PCustom>{translate('LINK')}</PCustom>
                <Text>{equipment.link}</Text>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <PCustom>{translate('PURCHASEYEAR')}</PCustom>
              <Text>{equipment.purchaseYear}</Text>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingTop: '24px', paddingBottom: '30px' }}
          >
            <Grid item xs={12}>
              <PCustom>{translate('CODE_PLATFORM')}:</PCustom>
              <Text>{equipment.code}</Text>
            </Grid>

            {equipment?.denominationList?.length ? (
              <Grid item xs={12}>
                <PCustom>{translate('OTHER_DENOMANATION')}:</PCustom>
                <Text>
                  {equipment.denominationList.map(item => item.name).join(' ,')}
                </Text>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <PCustom>{translate('SITUATION')}:</PCustom>
              <Text>
                {translate(`EQUIPMENT:SITUATION.${equipment.situation}`)}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <PCustom>{translate('COMMONS:CREATION_DATE')}:</PCustom>
              <Text>{equipment.createdDate}</Text>
            </Grid>
          </Grid>

          {equipment.techniqueList && (
            <Grid item container xs={12} style={{ marginBottom: '25px' }}>
              <TagsList
                title="TECHINIQUE_LIST"
                list={equipment.techniqueList}
                customGrid={12}
                style={{ paddingLeft: '27px' }}
              />
            </Grid>
          )}

          {equipment?.parameterList?.length ? (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              style={{ paddingBottom: '30px' }}
            >
              <Subtitle item xs={12}>
                {translate('PARAMETER')}
              </Subtitle>
              <Grid item xs={12}>
                <div style={{ marginLeft: '27px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            width: '2%',
                            paddingLeft: '15px',
                            paddingRight: '15px'
                          }}
                          align="center"
                        >
                          #
                        </TableCell>
                        <TableCell align="left">
                          {translate('REGISTER_EQUIPMENT_PARAMETERS_NAME')}
                        </TableCell>
                        <TableCell align="left">
                          {translate('REGISTER_EQUIPMENT_PARAMETERS_VALUE')}
                        </TableCell>
                        <TableCell align="left" colSpan={2}>
                          {translate('REGISTER_EQUIPMENT_PARAMETERS_UNIT')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{RowList}</TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          ) : null}

          {equipment.contact && (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              style={{ paddingBottom: '30px' }}
            >
              <Subtitle item xs={12}>
                {translate('EDIT_LABORATORY_CONTACT')}
              </Subtitle>
              <Grid item xs={12}>
                <PCustom>{translate('EDIT_LABORATORY_RESPONSIBLE')}:</PCustom>
                <Text>{equipment.contact.name}</Text>
              </Grid>
              <Grid item xs={12}>
                <PCustom style={{ width: '89px' }}>
                  {translate('EDIT_LABORATORY_TELEPHONE')}:
                </PCustom>
                <Text>{equipment.contact.phone}</Text>
              </Grid>
              {equipment.email && (
                <Grid item xs={12}>
                  <PCustom style={{ width: '89px' }}>
                    {translate('EDIT_LABORATORY_EMAIL')}:
                  </PCustom>
                  <Text>{equipment.email}</Text>
                </Grid>
              )}
              {equipment.website && (
                <Grid item xs={12}>
                  <PCustom style={{ width: '89px' }}>
                    {translate('EDIT_LABORATORY_WEBSITE')}:
                  </PCustom>
                  <Text>{equipment.website}</Text>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </ContentContainer>
      {equipment.hasSharing ? (
        <RequestSharing item={{ ...equipment, id: equipmentId }} type="equip" />
      ) : null}
    </AsyncLoad>
  )
}

export default wrapper(DetailEquipment, {
  namespace: 'PUBLIC_EQUIPMENT_DETAILS'
})
