import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'
import Loader from 'ui/molecules/Loader'

const FormSchema = Yup.object().shape({
  reason: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .max(50, 'VALIDATION:MAX_EXCEEDED')
    .trim()
})

const ConfirmDeleteLaboratoryModal = ({ laboratory, onClose, onAction }) => {
  const { translate } = useContext(GlobalizationContext)

  const resolver = useYupValidationResolver(FormSchema, { translate })

  const { control, errors, handleSubmit } = useForm({
    mode: 'onChange',
    resolver,
    defaultValues: {
      files: []
    }
  })

  const onSubmit = async formData => {
    onAction(laboratory, formData.reason)
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={Boolean(laboratory)}
    >
      <Loader />
      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <DialogContent>
          <Grid container spacing={3} style={{ marginBottom: '10px' }}>
            <Title item xs={12} $noBorder>
              {translate('CONFIRM_DELETE_MODAL.TITLE')}
            </Title>

            <Grid item xs={12}>
              <Trans
                parent={Typography}
                gutterBottom
                variant="body1"
                style={{ marginBottom: '16px', fontSize: '14px' }}
                i18nKey="CONFIRM_DELETE_MODAL.DESCRIPTION"
                values={{ labName: laboratory?.name }}
                t={translate}
              />
            </Grid>

            <Grid item xs={12}>
              <BaseTextareaField
                required={true}
                errors={errors}
                control={control}
                maxLength={50}
                rows={4}
                name="reason"
                label={translate('CONFIRM_DELETE_MODAL.REASON_LABEL')}
                placeholder={translate(
                  'CONFIRM_DELETE_MODAL.REASON_PLACEHOLDER'
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            name="CONFIRM_DELETE_MODAL_CANCEL"
            onClick={onClose}
            variant="contained"
          >
            {translate('COMMONS:CANCEL')}
          </Button>
          <Button
            name="CONFIRM_DELETE_MODAL_CONFIRM"
            color="primary"
            type="submit"
            variant="contained"
          >
            {translate('COMMONS:DELETE')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ConfirmDeleteLaboratoryModal
