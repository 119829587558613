import clsx from 'clsx'
import React, { useMemo, useContext } from 'react'
import { useWatch } from 'react-hook-form'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'

import { Container, Title, Wrapper } from './styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: '1px solid #cccccc',
    '&.hasError': {
      borderColor: 'red'
    },
    '&:before': {
      content: `'*'`,
      position: 'absolute',
      left: '-15px',
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      top: '25px',
      width: '20px',
      height: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    }
  }
}))

const Question = styled(RadioGroup)`
  &.MuiFormControl-root {
    display: flex;
    flex-direction: row;
  }

  .MuiFormHelperText-root {
    width: 100%;
  }

  .MuiFormGroup-root {
    width: 50%;
    flex: 1 1 auto;
    flex-wrap: nowrap;
  }

  .MuiFormLabel-root {
    width: 50%;
    line-height: 20px;
    flex: 1 1 auto;
    display: inline-flex;
    align-items: center;
    white-space: pre;
  }
`

function RequestOpnion({ formMethods }) {
  const { translate } = useContext(GlobalizationContext)
  const { control, errors } = formMethods

  const questions = useWatch({
    control,
    name: ['requestAdjustmentOpinionUnitQuestion'],
    defaultValue: {
      requestAdjustmentOpinionUnitQuestion: 'false'
    }
  })

  const classes = useStyles()

  const wrapperClasses = clsx(
    classes.wrapper,
    errors && errors['requestAdjustmentOpinionUnitQuestion']
      ? 'hasError'
      : false
  )

  const booleanList = useMemo(() => {
    return [
      {
        id: 'true',
        name: translate(`COMMONS:YES`)
      },
      {
        id: 'false',
        name: translate(`COMMONS:NO`)
      }
    ]
  }, [translate])

  return (
    <Container>
      <Title>{translate('LABORATORY_APPROVE_OPINION')}</Title>
      <Wrapper className={wrapperClasses}>
        <Question
          control={control}
          name="requestAdjustmentOpinionUnitQuestion"
          label={translate('LABORATORY_APPROVE_REQUEST_OPINION')}
          options={booleanList}
          error={errors}
          row
        />

        {questions.requestAdjustmentOpinionUnitQuestion === 'false' && (
          <Grid item xs={12} md={12} style={{ margin: '30px 0 16px' }}>
            <BaseTextField
              rows={5}
              required={true}
              label={translate('LABORATORY_REASON_REFUSE')}
              placeholder={translate(
                'REQUEST_OPINION_DETAIL_REASON_PLACEHOLDER'
              )}
              control={control}
              name="requestAdjustmentOpinionComplement"
              maxLength={500}
              errors={errors}
            />
          </Grid>
        )}
      </Wrapper>
    </Container>
  )
}

export default RequestOpnion
