import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: 'VALIDATION:REQUIRED_ERROR'
  },
  string: {
    required: 'VALIDATION:REQUIRED_ERROR',
    email: 'VALIDATION:EMAIL_ERROR',
    cpf: 'VALIDATION:CPF_ERROR',
    cnpj: 'VALIDATION:CNPJ_ERROR',
    phone: 'VALIDATION:PHONE_ERROR'
  },
  date: {
    required: 'VALIDATION:REQUIRED_ERROR',
    type: 'VALIDATION:INVALID_DATE'
  },
  object: {
    required: 'VALIDATION:REQUIRED_ERROR'
  },
  array: {
    required: 'VALIDATION:REQUIRED_ERROR'
  },
  boolean: {
    required: 'VALIDATION:REQUIRED_ERROR'
  }
})
