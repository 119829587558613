import styled from 'styled-components'

export const SharingConfigContainer = styled.div`
  padding: 24px;
  background-color: ${props => props.theme.palette.grey[200]};
  border: 1px solid ${props => props.theme.palette.grey[300]};
`

export const ErrorCheckBox = styled.div`
`

export const MsgErrorCheckBox = styled.p`
    color: #f44336;
    font-weight: 600;
    margin: 0;
    font-size: 0.9rem !important;
    margin-top: 0px;
    text-align: left;
    font-family: "Raleway Bold", "Raleway Regular", Raleway, sans-serif;
    line-height: 1.66;
`