import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import Icon from '@material-ui/core/Icon'
import MaterialIconButton from '@material-ui/core/IconButton'
import MaterialPopover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'

import { primaryColor } from 'helpers/constants'

export const UserIcon = styled(Icon).attrs({
  className: 'fas fa-user-circle'
})`
  color: ${primaryColor};
`

export const IconButton = styled(MaterialIconButton)``

export const Popover = styled(MaterialPopover)`
  .MuiPaper-root {
    padding: 16px;
    width: 300px;
    display: flex;
    flex-direction: column;
  }
`

export const UserName = styled(Typography).attrs({ noWrap: true })``

export const Divider = styled.div`
  height: 2px;
  margin-bottom: 9px;
  background-color: ${primaryColor};
  margin: 8px 0 5px 0;
`

export const Link = styled(RouterLink)`
  text-decoration: none;
  font-size: 16px;
  margin: 9px;
  color: #666666;
  font-weight: 500;

  &:hover {
    color: ${primaryColor};
    font-weight: 600;
  }
`
