import React, { useState, useEffect, useContext } from 'react'

import { Grid } from '@material-ui/core'

import BaseSelectUncontrolled from 'ui/atoms/BaseSelectUncontrolled'
import BaseTextFieldPublic from 'ui/atoms/BaseTextFieldPublic.js'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import { userListUseCase } from '../../providers'

const BodyFilters = props => {
  const [filterList, setFilterList] = useState()
  const [newFilterList, setNewFilterList] = useState([])
  const [filterOption, setFilterOption] = useState('')
  const { translate } = useContext(GlobalizationContext)

  const { onSelectChange, onInputChange } = props

  const getEquipmentService = async () => {
    try {
      const response = await userListUseCase.getUserSituations()
      setFilterList(response)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = event => {
    setFilterOption(event.target.value)
    onSelectChange(event)
  }

  useEffect(() => {
    if (!filterList) return

    setNewFilterList(
      filterList.map(item => ({
        id: item.id,
        name: translate(`USER:SITUATION.${item.value}`)
      }))
    )
  }, [filterList, translate])

  useEffect(() => {
    getEquipmentService()
  }, [])

  return (
    <Grid
      container
      spacing={3}
      style={{ marginBottom: '20px' }}
      alignItems="baseline"
    >
      <Grid item xs={12} md={3}>
        <BaseSelectUncontrolled
          defaultValue={translate('FILTER_STATUS')}
          label=""
          name="filterUserStatus"
          options={newFilterList}
          onChange={handleChange}
          value={filterOption}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <BaseTextFieldPublic
          placeholder={translate('SEARCH_DEFAULT')}
          handleChange={onInputChange}
        />
      </Grid>
    </Grid>
  )
}

export default BodyFilters
