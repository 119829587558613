import React, { useContext } from 'react'

import { Checkbox } from '@material-ui/core'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import {
  FilterTitle,
  FilterList,
  FilterItem,
  CheckboxContainer,
  DataContainer,
  TitleContainer,
  Title,
  Hint,
  Desc,
  DescItem
} from './style'

const Filter = ({ permissionList, onFilterChanged }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <FilterTitle>{translate('FILTER_TITLE')}</FilterTitle>
      <FilterList>
        {permissionList.map(filter => (
          <FilterItem key={filter.permission}>
            <CheckboxContainer>
              <Checkbox
                onChange={e =>
                  onFilterChanged(filter.permission, e.target.checked)
                }
                checked={filter.checked || false}
              />
            </CheckboxContainer>
            <DataContainer>
              <TitleContainer>
                <Title>{translate(`USER:ROLE.${filter.permission}`)}</Title>
                <Hint content={translate(`${filter.permission}_HINT`)} />
              </TitleContainer>
              <Desc>
                {filter.unitList.map((item, idx) => (
                  <DescItem key={idx}>
                    {item.initials} - {item.name}
                  </DescItem>
                ))}
              </Desc>
            </DataContainer>
          </FilterItem>
        ))}
      </FilterList>
    </>
  )
}

export default Filter
