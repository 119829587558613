import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'

import { Button, Grid } from '@material-ui/core'

import { InstitutionalInvite } from 'api'

import BaseTextField from 'ui/atoms/BaseTextField'
import { useGlobalizationContext } from 'ui/atoms/Globalization'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

const RepresentativeInput = ({ formMethods, userList, setModalInviteOpen }) => {
  const classes = useStyles()
  const { translate } = useGlobalizationContext()
  const [emailAlreadyInInstitution, setEmailAlreadyInInstitution] =
    useState(false)

  const { control, getValues, setError, errors, clearErrors, formState } =
    formMethods

  const { dirtyFields } = formState

  const checkEmailAlreadyInvited = async () => {
    const email = getValues('email_invite')
    const cnpj = getValues('cnpj').replace(/\D/gm, '')

    if (userList.findIndex(item => item.email === email) >= 0) {
      return setError('email_invite', {
        type: 'emailAlreadyExists',
        message: translate('MESSAGES.USER_ALREADY_INVITED')
      })
    }

    try {
      await InstitutionalInvite.getEmailAlreadySent(cnpj, email)

      setEmailAlreadyInInstitution(true)
    } catch (error) {
      const message = error.response?.data?.message

      if (['user.not.found', 'invite.not.found'].indexOf(message) >= 0) {
        handleOpenInvite()
      } else if (message === 'invalid.user.situation') {
        setError('email_invite', {
          type: 'invalidUser',
          message: translate('ERRORS:INVALID_USER_SITUATION')
        })
      } else {
        notificationUseCases.newError()
      }
    }
  }

  const handleOpenInvite = useCallback(async () => {
    clearErrors('email_invite')
    const email = getValues('email_invite')

    if (
      userList.filter(
        user => user.situation === 'ACTIVE' || user.situation === 'PENDING'
      ).length >= 10
    ) {
      return setError('email_invite', {
        type: 'emailAlreadyExists',
        message: translate('REGISTER_INSTITUTION_MAXIMUM_USERS_REACH')
      })
    }

    const valid = await Yup.string().email().isValid(email)
    if (!valid)
      return setError('email_invite', {
        type: 'email',
        message: 'Email inválido'
      })

    if (userList.findIndex(item => item.email === email) >= 0)
      return setError('email_invite', {
        type: 'emailAlreadyExists',
        message: translate('MESSAGES.USER_ALREADY_INVITED')
      })

    setModalInviteOpen(email)
  }, [
    clearErrors,
    getValues,
    userList,
    setError,
    translate,
    setModalInviteOpen
  ])

  return (
    <>
      <DialogConfirm
        dialogText="MESSAGES.USER_ALREADY_INVITED_OTHER_INSTITUTION"
        open={emailAlreadyInInstitution}
        onCancel={() => setEmailAlreadyInInstitution(false)}
        onConfirm={() => {
          setEmailAlreadyInInstitution(false)
          handleOpenInvite()
        }}
      />

      <div className={classes.defaultContainer}>
        <Grid container spacing={0} alignItems="flex-start">
          <Grid item xs={12} md={12}>
            <h3 className={classes.h3}>
              {translate('INVITE.MANAGE_REPRESENTATIVES')}
            </h3>
          </Grid>
          <Grid item xs={12} md={10}>
            <BaseTextField
              label=""
              placeholder={translate('INVITE.ADD_EMAIL')}
              errors={errors}
              control={control}
              name="email_invite"
              style={{
                maxHeight: '40px',
                minHeight: '40px',
                marginRight: '10px'
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              // disabled={!hasTotalRole}
              id="INVITE_TO_INSTITUTION"
              fullWidth
              className={classes.baseButton}
              disabled={!dirtyFields?.email_invite}
              onClick={checkEmailAlreadyInvited}
              variant="contained"
              style={{
                marginTop: errors['email_invite'] ? '1px' : 0,
                maxHeight: '40px',
                minHeight: '40px'
              }}
            >
              {translate('INVITE.INVITE')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default RepresentativeInput
