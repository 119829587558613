import { makeStyles } from '@material-ui/core'

import { primaryColor, hoverPrimaryColor, COLORS } from 'helpers/constants'

const useStyles = makeStyles({
  // style={{ pointerEvents: "none" }}
  disabledContainer: {
    pointerEvents: 'none'
  },
  equipamentinactive: {
    opacity: '0.4'
  },

  equipmentActive: {},

  marker: {
    backgroundImage: "url('https://i.imgur.com/MK4NUzI.png')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    width: '40px',
    height: '60px',
    borderRadius: '50%',
    cursor: 'pointer'
  },

  cnpjtepTwo: {
    '&:before': {
      content: `"/"`,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-15px'
    }
  },

  cnpj: {
    '&:before': {
      content: `"-"`,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-15px'
    }
  },
  baseInput: {
    padding: '10px 12px 10px',
    width: '100%',
    border: '1px solid #f2f2f2',
    borderRadius: '2px',
    color: '#333',
    fontWeight: 400,
    background: '#f2f2f2',
    outline: 'none',
    boxSizing: 'border-box',
    '&:hover': {
      background: '#fff',
      borderColor: '#ccc'
    }
  },
  baseSelect: {
    background: '#f2f2f2',
    borderRadius: '2px!important',
    fontSize: '14px',
    '&:hover': {
      background: '#fafafa'
      // border: '1px solid #cccccc'
    },
    '&.$Mui-disabled': {
      backgroundColor: '#dadada!important',
      color: '#999999!important'
    }
  },
  emptySelect: {
    color: '#a0a0a0'
  },
  greyContainer: {
    background: '#d7d7d7',
    height: '5rem',
    border: '2px dashed #d3d3d3',
    cursor: 'pointer',
    display: 'flex',
    userSelect: 'none',
    msUserSelect: 'none'
  },
  list: {
    width: 350
  },
  fullList: {
    width: 'auto'
  },
  disableRow: {
    backgroundColor: '#dadada'
  },
  selected: {
    backgroundColor: 'rgba(178, 223, 251, 0.6) !important'
  },
  statusCirclePENDING: {
    backgroundColor: '#f59a23',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    float: 'left',
    marginTop: '5px',
    marginRight: '10px'
  },
  statusCircleACTIVE: {
    backgroundColor: '#95f204',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    float: 'left',
    marginTop: '5px',
    marginRight: '10px'
  },
  IconActionsList: {
    '& svg': {
      fontSize: '24px'
    }
  },
  statusCircleINACTIVE: {
    backgroundColor: '#d9001b',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    float: 'left',
    marginTop: '5px',
    marginRight: '10px'
  },
  permissionContainerError: {
    padding: '1rem 1.5rem 1rem 1.5rem',
    border: '1px solid #d9001b'
  },
  customPopup: {
    zIndex: '99999',
    maxWidth: '250px',
    padding: '6px'
  },
  customPaper: {
    padding: '6px'
  },
  customIcon: {
    marginTop: '8px'
  },
  permissionContainer: {
    padding: '1rem 1.5rem 1rem 1.5rem',
    border: '1px solid #d7d7d7'
  },
  defaultModal: {
    overflowY: 'hidden'
  },
  defaultContainer: {
    paddingBottom: '1.5rem'
  },
  h3: {
    fontStyle: 'normal',
    fontSize: '16px',
    paddingBottom: '1rem'
  },
  h4: {
    fontStyle: 'normal',
    fontSize: '14px',
    paddingBottom: '0.5rem'
  },
  defaultButton: {
    backgroundColor: '#7fcdbf!important',
    color: '#ffffff!important'
  },
  dialogOverflowHidden: {
    overflowY: 'hidden'
  },
  baseButtonMaxSize: {
    boxShadow: 'none',
    minWidth: '83px',
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#009B7F',
    color: '#ffffff',
    padding: '9px',
    fontWeight: 'normal',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#006855',
      color: '#ffffff'
    }
  },
  baseButton: {
    height: '40px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#009B7F',
    color: '#ffffff',
    padding: '8px 9px!important',
    fontSize: '14px',
    borderRadius: '2px',
    boxShadow: '0 2px 2px rgba(0,0,0,.3)',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#006855',
      color: '#ffffff'
    }
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  baseButtonGrey: {
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#f2f2f2',
    color: '#333333',
    minWidth: '100px!important',
    padding: '8px 9px!important',
    fontSize: '14px',
    borderRadius: '2px',
    boxShadow: '0 2px 2px rgba(0,0,0,.3)',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#f2f2f2',
      color: '#333333',
      MozBoxShadow: 'none',
      WebkitBoxShadow: 'none'
    }
  },
  spanError: {
    color: '#eb5757!important'
  },
  baseButtonAlternativeInvite: {
    height: '43px',
    boxShadow: 'none',
    border: 'none',
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    color: '#000000',
    minwidth: '80px!important',
    transition: 'none',
    padding: '9px',
    '&:hover': {
      transition: 'none',
      backgroundColor: '#fafafa',
      MozBoxShadow: 'none',
      WebkitBoxShadow: 'none'
    }
  },
  baseButtonAlternative: {
    height: '37px',
    boxShadow: 'none',
    border: 'none',
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    color: '#000000',
    minwidth: '80px!important',
    transition: 'none',
    padding: '9px',
    '&:hover': {
      transition: 'none',
      backgroundColor: '#fafafa',
      MozBoxShadow: 'none',
      WebkitBoxShadow: 'none'
    }
  },
  baseAddButton: {
    height: '37px',
    color: '#fff',
    fontWeight: 600,
    boxShadow: 'none',
    border: 'none',
    borderRadius: '2px',
    float: 'right',
    textTransform: 'none',
    transition: 'none',
    padding: '9px',
    background: primaryColor,
    '&:hover': {
      backgroundColor: hoverPrimaryColor,
      boxShadow: 'none'
    }
  },
  baseText: {
    fontSize: '14px',
    fontWeight: 400
  },
  label: {
    paddingBottom: '0.4rem',
    fontSize: '14px',
    fontWeight: 600,
    display: 'block'
  },
  snackBar: {
    backgroundColor: 'eb5757',
    color: '#fff'
  },
  closeButton: {
    color: '#333333',
    right: '10px',
    position: 'absolute'
  },
  checkbox: {
    color: primaryColor
  },

  terms: {
    color: '#06B399',
    textDecoration: 'none',
    cursor: 'pointer'
  },
  errorDefault: {
    fontWeight: 600,
    color: COLORS.ERROR
  },
  gridLoader: {
    position: 'relative'
  },
  inputLoader: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '20px',
    bottom: '10px',
    zIndex: 2,
    width: '15px !important',
    height: '15px !important'
  },
  selectDropdownList: {
    backgroundColor: '#f2f2f2'
  },
  selectDropdownItem: {
    backgroundColor: '#f2f2f2',
    '&:hover': {
      backgroundColor: '#fafafa'
    }
  }
})

export default useStyles
