import clsx from 'clsx'
import React, { useState, useRef, useContext } from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'

import { Button, Grid } from '@material-ui/core'

import { primaryColor } from 'helpers/constants'
import { isEnter } from 'helpers/disableSubmitOnEnter'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

const IconDelete = styled.i`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333333;
  margin: 0 auto;
  display: block;

  &:hover {
    color: ${primaryColor};
    cursor: pointer;
  }
`

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  margin-top: 20px;

  span {
    background: #d8d8d8;
    display: block;
    color: #999999;
    font-size: 14px;
    padding: 10px;
  }
`

const ListItem = styled.li`
  display: flex;
  min-height: 40px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`
const ListLabel = styled.div`
  font-size: 14px;
  flex: 1 0 auto;
  background-color: #eaeaea;
  margin-right: 5px;
  padding-left: 16px;
  color: #333333;
  font-weight: bold;
  display: flex;
  align-items: center;
`

const ListAction = styled.div`
  flex: 0 1 auto;
  width: 40px;
  display: flex;
  align-items: center;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    height: 40px;
    margin-bottom: 8px;
  }

  button {
    min-height: 40px;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    flex-direction: row;

    input {
      flex: 1 1 100%;
      margin-right: 16px;
      margin-bottom: 0;
    }

    button {
      flex: 0 0 200px;
    }
  }
`

const InputAddItensComponent = ({
  value: componentValue,
  inputPlaceholder,
  onChange,
  className,
  maxLimit = 3,
  disabled = false,
  readOnlyList = []
}) => {
  const { translate } = useContext(GlobalizationContext)

  const classes = useStyles()

  const [hasMaximumLimitReached, setHasMaximumLimitReached] = useState(false)

  const inputEl = useRef(null)
  const listItens = componentValue || []

  const handleAddItemList = () => {
    const value = inputEl.current.value.trim()

    if (value.length <= 3) return
    if (isRepeated(inputEl.current.value)) return
    if (maxLimit) {
      if (listItens.length < maxLimit) {
        setHasMaximumLimitReached(false)
        addItemList()
      } else {
        setHasMaximumLimitReached(true)
      }
    } else {
      addItemList()
    }
  }

  const isRepeated = newValue => {
    return listItens.findIndex(item => item == newValue) >= 0
  }

  const addItemList = () => {
    const newList = listItens.concat(inputEl.current.value)
    onChange(newList)
    inputEl.current.value = ''
  }

  const handleDelete = removedItem => {
    const newList = listItens.filter(item => item !== removedItem)
    onChange(newList)
    setHasMaximumLimitReached(false)
  }

  return (
    <div className={clsx(classes.defaultContainer, className)}>
      <InputContainer>
        <input
          type="text"
          placeholder={translate(inputPlaceholder)}
          name="input"
          ref={inputEl}
          className={classes.baseInput}
          maxLength={255}
          disabled={disabled}
          onKeyDown={
            !disabled
              ? e => {
                  isEnter(e) && handleAddItemList(e)
                }
              : () => {}
          }
        />
        <Button
          fullWidth
          className={classes.baseAddButton}
          onClick={!disabled ? handleAddItemList : () => {}}
          variant="contained"
        >
          {translate('REGISTER_ADD_BUTTON')}
        </Button>
      </InputContainer>
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={12} style={{ fontSize: '14px' }}>
          {hasMaximumLimitReached &&
            translate('MAX_ITEMS_LIMIT_REACHED', {
              limit: maxLimit
            })}
        </Grid>
      </Grid>

      <Grid container>
        <List>
          {listItens.map(item => (
            <ListItem key={item}>
              <ListLabel>{item}</ListLabel>
              {readOnlyList.indexOf(item) < 0 ? (
                <ListAction>
                  <IconDelete
                    className="fas fa-trash-alt"
                    onClick={() => handleDelete(item)}
                  />
                </ListAction>
              ) : null}
            </ListItem>
          ))}
        </List>
      </Grid>
    </div>
  )
}

const InputAddItens = ({ control, name, ...props }) => {
  return control ? (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ ref, ...controllerProps }) => (
        <InputAddItensComponent {...controllerProps} {...props} />
      )}
    />
  ) : (
    <InputAddItensComponent {...{ name }} {...props} />
  )
}

export default InputAddItens
