import React, { useContext, useState } from 'react'
import { Trans } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'

import { DialogTitle } from 'ui/atoms/Dialog'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Tab, Tabs } from 'ui/atoms/Tabs'

import { EmailContent } from '../../styles'

const EmailTemplateModal = ({ open, onClose: handleClose }) => {
  const { translate } = useContext(GlobalizationContext)

  const [currTab, setCurrTab] = useState('INSTITUTION')

  const renderInstitutionEmailPreview = () => {
    return (
      <Trans
        style={{ marginLeft: '12px' }}
        parent={Typography}
        // @ts-ignore
        variant="body2"
        t={translate}
        i18nKey="PREVIEW_INSTITUTION_EMAIL_CONTENT"
        components={{ strong: <strong />, br: <br /> }}
      />
    )
  }

  const renderLabEmailPreview = () => {
    return (
      <Trans
        style={{ marginLeft: '12px' }}
        parent={Typography}
        // @ts-ignore
        variant="body2"
        t={translate}
        i18nKey="PREVIEW_LABORATORY_EMAIL_CONTENT"
        components={{ strong: <strong />, br: <br /> }}
      />
    )
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>{translate('PREVIEW_EMAIL_TITLE')}</DialogTitle>
      <DialogContent>
        <Tabs
          onChange={(
            // @ts-ignore
            event,
            newTab
          ) => setCurrTab(newTab)}
          value={currTab}
        >
          <Tab
            label={translate('PREVIEW_TAB_INSTITUTION')}
            value={'INSTITUTION'}
          />
          <Tab
            label={translate('PREVIEW_TAB_LABORATORY')}
            value={'LABORATORY'}
          />
        </Tabs>
        <EmailContent>
          {{
            INSTITUTION: renderInstitutionEmailPreview(),
            LABORATORY: renderLabEmailPreview()
          }[currTab] || null}
        </EmailContent>
        {}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>
          {translate('COMMONS:CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EmailTemplateModal
