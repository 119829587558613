import React, { useContext } from 'react'
import styled from 'styled-components'

import { Box } from '@material-ui/core'

import { primaryColor } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const TitleIconCustom = styled.div`
  color: #999999;
  font-size: 32px;
  font-weight: 600;
  display: flex;
  border-bottom: 1px solid ${primaryColor};
  padding: 10px 0;
  color: #333333;
`

const CustomIcon = styled.i`
  color: #009c80;
  margin-right: 20px;
`

const CustomH3Title = styled.h3`
  display: inline-block;
  margin: 0;
  padding: 8px 0;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  outline: none;
`

function TitleIcon({ iconName, size, content, icon }) {
  const { translate } = useContext(GlobalizationContext)

  return (
    <TitleIconCustom>
      {icon ? (
        <Box style={{ paddingRight: '20px' }}>{icon}</Box>
      ) : (
        <CustomIcon className={`fas fa-${iconName} fa-${size}x`}></CustomIcon>
      )}
      <CustomH3Title>{translate(content)}</CustomH3Title>
    </TitleIconCustom>
  )
}

export default TitleIcon
