import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import MuiButton from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import ContentContainer from 'ui/atoms/ContentContainer'

export const BannerWrapper = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: transparent;
`

const BannerBackground = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(6, 179, 153, 0.8);
`

export const BannerContent = styled(({ className, children }) => {
  return (
    <BannerBackground>
      <ContentContainer>
        <Grid container justify="space-around">
          <Grid {...{ className }} item xs={12}>
            {children}
          </Grid>
        </Grid>
      </ContentContainer>
    </BannerBackground>
  )
})`
  display: flex;
  flex-direction: column;
  color: #fff;
  opacity: 1;

  margin: 20px 0 35px;
`

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`
export const Title = styled.div`
  flex: 1 1 100%;
  display: flex;
  align-items: center;

  font-size: 22px;
  font-weight: 700;
`
export const Close = styled.div`
  display: flex;
  align-items: center;
`

export const CloseButton = styled(props => {
  return (
    <IconButton {...props}>
      <Icon className="fas fa-times" />
    </IconButton>
  )
})`
  color: #fff;
`

export const ContentRow = styled.div`
  display: flex;

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`
export const Content = styled.div`
  flex: 1 1 100%;
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 600;
`

export const Button = styled(MuiButton).attrs({ component: Link })`
  white-space: nowrap;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;

  text-decoration: none;
  text-transform: none;
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-top: 16px;
  width: 92px;

  ${props => props.theme.breakpoints.down('xs')} {
    width: 100%;
  }

  &:hover {
    text-decoration: none;
    background: #ffffff;
  }
`
