import React, { useContext } from 'react'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'

import { BreadcrumbSeparator, BreadcrumbItem, BreadCrumbs } from '../style'

const Breadcrumb = ({ breadcrumbData }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <BreadCrumbs separator={<BreadcrumbSeparator />}>
      <BreadcrumbItem>{translate('LABORATORY')}</BreadcrumbItem>
      {breadcrumbData?.name ? (
        <BreadcrumbItem>{breadcrumbData.name}</BreadcrumbItem>
      ) : null}
    </BreadCrumbs>
  )
}

const mapState = state => ({
  breadcrumbData: state.breadcrumbData.content
})

export default Wrapper(Breadcrumb, { mapState })
