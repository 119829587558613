// @ts-nocheck
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'

import LaboratoryService from 'api/LaboratoryService'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

const Schema = Yup.object().shape({
  responsible_email: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .email('Email inválido'),
  reason: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .max(500, 'VALIDATION:MAX_EXCEEDED')
    .trim()
})

const DialogActivation = ({ close, lab, open, updateList }) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  const resolver = useYupValidationResolver(Schema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver
  })

  const { control, errors, handleSubmit } = formMethods

  const onSubmit = async formData => {
    const payload = {
      detail: formData.reason,
      email: formData.responsible_email
    }
    try {
      await LaboratoryService.reactiveLaboratory(lab.id, payload)
      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      close()
      updateList()
    } catch (error) {
      notificationUseCases.newError(error)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogContent>
        <Title item xs={12} $noBorder id="dialog-title">
          {translate('DIALOG_ACTIVATION.ACTIVATION_TITLE')}
        </Title>
        <DialogContentText id="dialog-description">
          {
            <Trans
              t={translate}
              i18nKey="DIALOG_ACTIVATION.ACTIVATION_TEXT"
              values={{ name: lab?.name }}
            />
          }
        </DialogContentText>
        <Grid container style={{ marginTop: '32px ' }}>
          <BaseTextareaField
            required={true}
            errors={errors}
            control={control}
            maxLength={500}
            rows={4}
            name="reason"
            label={translate('DIALOG_ACTIVATION.REASON')}
            placeholder={translate(
              'DIALOG_ACTIVATION.REASON_ACTIVATION_PLACEHOLDER'
            )}
          />
        </Grid>

        <Grid container>
          <Grid item md={6}>
            <BaseTextField
              disabled={false}
              required={true}
              label={translate('DIALOG_ACTIVATION.RESPONSIBLE_EMAIL_LABEL')}
              placeholder={translate('COMMONS:INFORM')}
              errors={errors}
              control={control}
              name="responsible_email"
              hint={translate('DIALOG_ACTIVATION.RESPONSIBLE_EMAIL_TOOLTIP')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.baseButtonGrey}
          id="DIALOG_CANCEL"
          onClick={close}
        >
          {translate('COMMONS:CANCEL')}
        </Button>

        <Button
          id="DIALOG_CONFIRM"
          color="primary"
          className={classes.baseButton}
          onClick={handleSubmit(onSubmit)}
        >
          {translate('DIALOG_ACTIVATION.ACTIVATION')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogActivation
