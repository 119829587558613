// @ts-ignore
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, withRouter } from 'react-router-dom'
import * as Yup from 'yup'

import { Checkbox } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { UsersService } from 'api'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { recaptchaEnabled } from 'helpers/environment'
import { cpfMask } from 'helpers/maskedCPF'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Loader from 'ui/atoms/Loader'
import ReCAPTCHAInput from 'ui/atoms/ReCAPTCHAInput'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import DialogTerms from 'ui/molecules/DialogTerms'
import Header from 'ui/organisms/Header/Private'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import TermsOfUse from '../../../../assets/documents/TermsOfUse.pdf'

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('Campo obrigatório'),
  lastName: Yup.string().required('Campo obrigatório'),
  email: Yup.string()
    .email('VALIDATION:EMAIL_ERROR')
    .required('VALIDATION:REQUIRED_ERROR'),
  cpf: Yup.string()
    // @ts-ignore
    .cpf('VALIDATION:CPF_ERROR')
    .required('VALIDATION:REQUIRED_ERROR'),
  term: Yup.boolean().oneOf([true]),
  recaptcha: Yup.string().recaptcha()
})

function Register() {
  const classes = useStyles()
  const history = useHistory()
  const { translate } = useContext(GlobalizationContext)
  const recaptchaRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [checked, setChecked] = useState(false)

  const resolver = useYupValidationResolver(formSchema, { translate })
  const { control, register, handleSubmit, errors, formState, reset } = useForm(
    {
      mode: 'onBlur',
      resolver
    }
  )

  useEffect(() => {
    register('captcha')
  }, [register])

  const { isValid: isFormValid } = formState

  const handleOnClose = () => {
    history.push('/')
  }

  const onSubmit = formData => {
    const userData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      cpf: formData.cpf.replace(/[.-]/g, ''),
      gRecaptchaResponse: formData.recaptcha
    }

    recaptchaRef.current && recaptchaRef.current.reset()

    setIsLoading(true)
    UsersService.registerUser(userData)
      .then(() => {
        setIsLoading(false)
        notificationUseCases.newSuccess(
          translate('REGISTER_USER_ACCOUNT_SUCCESSFULLY_REGISTERED')
        )
        reset()
      })
      .catch(error => {
        setIsLoading(false)
        if (error.response.status === 406 || error.response.status === 500) {
          notificationUseCases.newError(
            translate('REGISTER_USER_VALIDATION_ERROR')
          )
        } else {
          notificationUseCases.newError(error)
        }
      })
  }

  return (
    <React.Fragment>
      <Loader open={isLoading}></Loader>
      <Header hideActions={true} showBurger={false} />
      <Container>
        <Grid container justify="center">
          <Grid item xs={10}>
            <BaseContainerHeader
              label={translate('REGISTER_USER_TITLE')}
              showCloseButton={true}
              onClose={handleOnClose}
            />

            <Grid
              container
              justify="center"
              className={classes.defaultContainer}
            >
              <Grid item md={6}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  {...disableSubmitOnEnter}
                >
                  <input type="hidden" autoComplete="false" />
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <BaseTextField
                        required={true}
                        maxLength={255}
                        label={translate('REGISTER_USER_NAME')}
                        placeholder={translate(
                          'REGISTER_USER_NAME_PLACEHOLDER'
                        )}
                        errors={errors}
                        control={control}
                        name="firstName"
                        autoComplete="off"
                      ></BaseTextField>
                    </Grid>

                    <Grid item xs={12}>
                      <BaseTextField
                        required={true}
                        maxLength={255}
                        label={translate('REGISTER_USER_LAST_NAME')}
                        placeholder={translate(
                          'REGISTER_USER_LAST_NAME_PLACEHOLDER'
                        )}
                        errors={errors}
                        control={control}
                        name="lastName"
                        autoComplete="off"
                      ></BaseTextField>
                    </Grid>

                    <Grid item xs={12}>
                      <BaseTextField
                        required={true}
                        label={translate('REGISTER_USER_CPF')}
                        placeholder={translate('REGISTER_USER_CPF_PLACEHOLDER')}
                        errors={errors}
                        control={control}
                        name="cpf"
                        autoComplete="off"
                        mask={cpfMask}
                      ></BaseTextField>
                    </Grid>

                    <Grid item xs={12}>
                      <BaseTextField
                        required={true}
                        label={translate('REGISTER_USER_EMAIL')}
                        placeholder={translate(
                          'REGISTER_USER_EMAIL_PLACEHOLDER'
                        )}
                        errors={errors}
                        control={control}
                        name="email"
                        autoComplete="off"
                      ></BaseTextField>
                    </Grid>

                    {recaptchaEnabled ? (
                      <Grid item xs={12}>
                        <ReCAPTCHAInput
                          control={control}
                          errors={errors}
                          recaptchaRef={recaptchaRef}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <Checkbox
                        name="term"
                        onChange={() => setChecked(!checked)}
                      />
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: '14px'
                        }}
                      >
                        {translate('REGISTER_USER_ACCEPT_TERMS')}{' '}                 
                        <a href={TermsOfUse} target="_blank" rel="noreferrer" style={{ color: "rgb(6, 179, 153)", cursor: "pointer", textDecoration: 'none'}} >
                            {translate('REGISTER_USER_TERMS')}
                        </a>            
                      </span>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        type={'submit'}
                        variant="contained"
                        disabled={!isFormValid || !checked}
                        className={classes.baseButton}
                      >
                        {translate('REGISTER_USER_REGISTER_BUTTON')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}
export default withRouter(Register)
