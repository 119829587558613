import React, { useState, useRef, useContext } from 'react'
import AutoSuggest from 'react-autosuggest'
import { Controller } from 'react-hook-form'
import { conformToMask } from 'react-text-mask'

import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'

import { CNPJ_MASK } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import { WrapSuggestions } from '../styles'

const AutoSuggestSocialReason = ({
  onSelected,
  suggestionGetter,
  disabled,
  errorNoResults,
  formMethods
}) => {
  const { translate } = useContext(GlobalizationContext)

  const name = 'socialReason'

  const inputRef = useRef()
  const classes = useStyles()
  const defaultDebounce = 500

  const { control, errors, setValue } = formMethods

  const [currValue, setCurrValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [debounceTimeOut, setDebounceTimeOut] = useState(null)

  const handleSuggestionsSocialReasonFetchRequested = ({ value }) => {
    if (debounceTimeOut) clearTimeout(debounceTimeOut)

    const newDebounceTimeOut = setTimeout(() => {
      suggestionGetter({ value })
        .then(resp => {
          setSuggestions(resp || [])
          errorNoResults(false)
        })
        .catch(() => {
          setSuggestions([])
          errorNoResults(true)
        })
    }, defaultDebounce)
    setDebounceTimeOut(newDebounceTimeOut)
  }

  const handleOnChange = (newValue, onChange) => {
    onChange(newValue)
  }

  const handleOnBlur = (event, onChange, onBlur) => {
    if (event.target.value !== currValue) {
      handleSuggestionSelected({ cnpj: '', initials: '', social_reason: '' })
    } else {
      onBlur(event)
    }
  }

  const handleSuggestionSelected = suggestion => {
    setCurrValue(suggestion.social_reason)
    setValue(name, suggestion.social_reason)
    onSelected(suggestion)
  }

  const renderSuggestion = suggestion => (
    <span>
      {`${suggestion.initials} - ${suggestion.social_reason} - ${
        conformToMask(suggestion.cnpj, CNPJ_MASK).conformedValue
      }`}
    </span>
  )

  return (
    <>
      <Grid item xs={12} md={12}>
        <label className={classes.label}>
          {translate('REGISTER_LABORATORY_SOCIAL_REASON')}
        </label>
      </Grid>
      <Grid container spacing={0} alignItems="flex-start">
        <Grid item xs={12} md={12}>
          <WrapSuggestions
            className={errors && errors[name] ? 'hasError' : null}
          >
            <Controller
              control={control}
              name={name}
              onFocus={() => {
                inputRef.current && inputRef.current.focus()
              }}
              render={({ value, onChange, onBlur }) => (
                <AutoSuggest
                  className={classes.baseButton}
                  suggestions={suggestions}
                  onSuggestionsClearRequested={() => setSuggestions([])}
                  onSuggestionsFetchRequested={
                    handleSuggestionsSocialReasonFetchRequested
                  }
                  onSuggestionSelected={(evt, { suggestion }) => {
                    handleSuggestionSelected(suggestion)
                  }}
                  getSuggestionValue={suggestion => suggestion.social_reason}
                  renderSuggestion={suggestion => renderSuggestion(suggestion)}
                  inputProps={{
                    name: 'socialReason',
                    placeholder: translate(
                      'REGISTER_LABORATORY_SOCIAL_REASON_NAME_INSTITUTION'
                    ),
                    onChange: (evt, { newValue }) =>
                      handleOnChange(newValue, onChange, onBlur),
                    onBlur: evt => handleOnBlur(evt, onBlur, onChange),
                    onFocus: evt => setCurrValue(evt.target.value),
                    ref: ref => {
                      inputRef.current = ref
                    },
                    value: value || '',
                    className: classes.baseInput,
                    maxLength: 255,
                    disabled: disabled
                  }}
                  highlightFirstSuggestion={true}
                />
              )}
            />
          </WrapSuggestions>
        </Grid>
        {errors && errors[name] && (
          <div className={name + '-error-container'}>
            <FormHelperText error>{errors[name].message}</FormHelperText>
          </div>
        )}
      </Grid>
    </>
  )
}
export default AutoSuggestSocialReason
