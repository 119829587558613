import React, { useCallback, useContext, useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { conformToMask } from 'react-text-mask'

import { FormHelperText, Grid, Link, TextareaAutosize, Typography } from '@material-ui/core'
import { Title } from '@material-ui/icons'

import { CNPJ_MASK } from 'helpers/constants'
import institutionTypeList from 'helpers/institutionTypeList'
import { cnpjMask } from 'helpers/maskedCNPJ'

import { previewFile } from 'ui/atoms/AddedDocuments'
import Autocomplete from 'ui/atoms/Autocomplete'
import BaseSelect from 'ui/atoms/BaseSelect'
import BaseTextField from 'ui/atoms/BaseTextField'
import ControlLabel from 'ui/atoms/ControlLabel'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'
import { Subtitle } from 'ui/atoms/Title'

import { requestSharingUseCases } from '../../../../providers'

const RequesterInstitution = ({ readOnly, formMethods, currentLaboratory }) => {
  const { translate } = useContext(GlobalizationContext)
  const { control, setValue, errors } = formMethods

  const institution = useWatch({
    control,
    name: 'institution'
  })

  const institutionType = useWatch({
    control,
    name: 'institutionType'
  })

  const currType = institutionTypeList.find(item => item.id === institutionType)

  useEffect(() => {
    if (institution && !institution.isNew && institution.cnpj) {
      setValue(
        'institutionCNPJ',
        conformToMask(institution.cnpj, CNPJ_MASK).conformedValue
      )
    }
  }, [institution, setValue])

  const getOptions = useCallback(async ({ input }) => {
    return await requestSharingUseCases.getInstitution(input)
  }, [])

  const getOptionLabel = useCallback(option => option.social_reason, [])


  return (
    <>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={readOnly}
          name="institution"
          label={translate('REGISTER.REQUESTER_INSTITUTION.LABEL')}
          placeholder={translate('COMMONS:TYPE')}
          control={control}
          error={errors.institution}
          optionGetter={getOptions}
          createNewLabelRenderer={newValue =>
            translate('REGISTER.REQUESTER_INSTITUTION.ADD_NEW_INSTITUTION', {
              newValue
            })
          }
          createNewLabelKey="social_reason"
          createNew={newValue => ({
            id: null,
            social_reason: newValue,
            isNew: true
          })}
          optionRenderer={({ option }) => (
            <Typography style={{ fontSize: '14px' }}>
              {option.initials
                ? `${option.initials} - ${option.social_reason}`
                : `${option.social_reason}`}
            </Typography>
          )}
          AutocompleteProps={{
            getOptionSelected(item, value) {
              return (
                item.social_reason === value.social_reason ||
                item.inputValue === value.social_reason
              )
            },
            getOptionLabel
          }}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BaseTextField
          name="institutionCNPJ"
          label={translate('REGISTER.REQUESTER_INSTITUTION.CNPJ_LABEL')}
          placeholder={translate('COMMONS:TYPE')}
          mask={cnpjMask}
          control={control}
          errors={errors}
          disabled={
            readOnly ||
            (institution && !institution.isNew && Boolean(institution.cnpj))
          }
          required
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <BaseSelect
          disabled={readOnly}
          required={true}
          label={translate('REGISTER.REQUESTER_INSTITUTION.TYPE')}
          placeholder={translate('COMMONS:SELECT')}
          errors={errors}
          control={control}
          name="institutionType"
          options={institutionTypeList}
          translateOptions
        ></BaseSelect>
      </Grid>

      {currType?.isCompany ? (
        <Grid item xs={12} md={6}>
          <RadioGroup
            disabled={readOnly}
            required
            label="&nbsp;"
            name="isPublic"
            control={control}
            error={errors}
            row
            options={[
              {
                id: true,
                name: translate('REGISTER.REQUESTER_INSTITUTION.PUBLIC')
              },
              {
                id: false,
                name: translate('REGISTER.REQUESTER_INSTITUTION.PRIVATE')
              }
            ]}
          />
        </Grid>
      ) : null}

      {currType?.isOther ? (
        <Grid item xs={12} md={6}>
          <BaseTextField
            disabled={readOnly}
            name="specifyInstitutionType"
            label={translate(
              'REGISTER.REQUESTER_INSTITUTION.SPECIFY_INSTITUTION_TYPE_LABEL'
            )}
            placeholder={translate(
              'REGISTER.REQUESTER_INSTITUTION.SPECIFY_INSTITUTION_TYPE_PLACEHOLDER'
            )}
            control={control}
            errors={errors}
            required
          />
        </Grid>
      ) : null}

      {/* {currentLaboratory?.sharingTermsDocumentList?.length ? (
        <Grid item xs={12}>
          <ControlLabel
            disabled={readOnly}
            required
            type="Checkbox"
            control={control}
            name="hasReadTermsOfUse"
          >
            <Typography variant="body2">
              {translate('REGISTER.REQUESTER_INSTITUTION.HAS_READ_THE_TERMS')}{' '}
              <Link
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  previewFile(currentLaboratory.sharingTermsDocumentList[0])
                }}
              >
                {translate('REGISTER.REQUESTER_INSTITUTION.LAB_TERMS')}
              </Link>
            </Typography>
          </ControlLabel>
          {errors?.hasReadTermsOfUse?.message && (
            <FormHelperText error>
              {errors.hasReadTermsOfUse.message}
            </FormHelperText>
          )}
        </Grid>
      ) : null} */}


{currentLaboratory?.hasSharingUseStatement ? (

        <Grid item xs={12}>

      <Subtitle item xs={12}>
      Termos de uso
        </Subtitle>

          <TextareaAutosize
          maxRows={10}
          minRows={5}
          aria-label="maximum height"
          defaultValue={currentLaboratory.textAreaUseStatement}
          style={{ resize: 'none', width: "100%", border: "none", marginBottom: "1%", backgroundColor: "#fff" }}
          disabled={true}
          /> 

          <ControlLabel
            disabled={readOnly}
            required
            type="Checkbox"
            control={control}
            name="hasReadTermsOfUse"
          >
            <Typography variant="body2">
              {translate('REGISTER.REQUESTER_INSTITUTION.HAS_READ_THE_TERMS')}{' '}
            
            </Typography>
          </ControlLabel>
          {errors?.hasReadTermsOfUse?.message && (
            <FormHelperText error>
              {errors.hasReadTermsOfUse.message}
            </FormHelperText>
          )}
        </Grid>
     
      ) : null}

      {currentLaboratory?.hasSharingCosts ? (
        <Grid item xs={12}>
          <ControlLabel
            disabled={readOnly}
            required
            type="Checkbox"
            control={control}
            name="isAwareOfCosts"
          >
            <Typography variant="body2">
              {translate('REGISTER.REQUESTER_INSTITUTION.IS_AWARE_OF_COSTS')}
            </Typography>
          </ControlLabel>
          {errors?.isAwareOfCosts?.message && (
            <FormHelperText error>
              {errors.isAwareOfCosts.message}
            </FormHelperText>
          )}
        </Grid>
      ) : null}
    </>
  )
}

export default RequesterInstitution
