import { AdminService } from 'api'

import { notificationUseCases } from 'views/Notification/providers'

import InstitutionListUseCase from './institutionList.useCases'

export const institutionListUseCase = new InstitutionListUseCase({
  AdminService,
  notificationUseCases
})
