import styled from 'styled-components'

export const WrapEmail = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const WrapContent = styled.div`
  max-width: 450px;
  display: inline-flex;
`

export const OnlyTotalWarning = styled.div`
  font-size: 12px;
  font-weight: 600;
`

export const UndoIcon = styled.i`
  font-size: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextContainer = styled.div`
  margin: 32px 0 20px 0;
`

export const Label = styled.span`
  font-weight: 600;
  font-size: 14px;
  margin-right: 20px;
`

export const Text = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-right: 20px;
`
