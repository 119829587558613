import { createBrowserHistory } from 'history'
import { generatePath } from 'react-router'

import { PRIVATE_ROUTE_PATH } from 'helpers/constants'

export const mountPrivatePath = path => {
  return `${PRIVATE_ROUTE_PATH}${path}`
}

export const generatePrivatePath = (path, { pathParams = {}, query = "" } = {}) => {
  try {
    const privatePath = mountPrivatePath(path)
    const generatedPath = generatePath(privatePath, pathParams)
    const queryParams = query ? `?${new URLSearchParams(query).toString()}` : ''
    return `${generatedPath}${queryParams}`
  } catch {
    return mountPrivatePath('')
  }
}

export const history = createBrowserHistory()
