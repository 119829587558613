import React, { useEffect, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import * as Yup from 'yup'

import { fileUploadConfig } from 'helpers/constants'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import AddedDocuments from 'ui/atoms/AddedDocuments'
import TextArea from 'ui/atoms/BaseTextareaField'
import Uploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import { userListUseCase } from '../../providers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Section,
  CustomButton
} from './styles.jsx'

const FormSchema = Yup.object().shape({
  details: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .max(500, 'VALIDATION:MAX_EXCEEDED')
    .trim(),
  files: Yup.array()
    .max(fileUploadConfig.deleteUser.maxFiles)
    .notRequired()
    .ensure()
})

export default function DeleteUser(props) {
  const { open, user, onCloseDialog, onDialogAction } = props
  const { translate } = useContext(GlobalizationContext)
  const [fileList, setFileList] = useState([])

  const resolver = useYupValidationResolver(FormSchema, { translate })

  const { control, register, setValue, errors, handleSubmit, trigger } =
    useForm({
      mode: 'onBlur',
      resolver,
      defaultValues: {
        files: []
      }
    })

  useEffect(() => {
    register({
      name: 'files',
      type: 'custom'
    })
  }, [register])

  const handleOnFileSelected = fileList => {
    if (!fileList.length) return

    setFileList(imagesList => {
      const newList = imagesList.concat(fileList)

      setValue('files', newList)
      trigger('files')
      return newList
    })
  }

  const handleOnRemoveFile = file => {
    setFileList(fileList => {
      const remaningFiles = fileList.filter(item => item !== file)

      setValue('files', remaningFiles)
      trigger('files')

      return remaningFiles
    })
  }

  const onSubmit = async formData => {
    try {
      onDialogAction(formData)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Dialog
        onClose={onCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
          <DialogTitle id="customized-dialog-title" variant="h5">
            {translate('DELETE_MODAL.TITLE')}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              <Trans
                t={translate}
                i18nKey="DELETE_MODAL.TEXT"
                values={{ user: user.name }}
              />
            </Typography>
            <Section id="details">
              <Typography gutterBottom variant="h6">
                {translate('DELETE_MODAL.LABEL_DETAILS')}
              </Typography>
              <TextArea
                aria-label="detalhes"
                rowsMin={6}
                rowsMax={10}
                placeholder={translate(
                  'DELETE_MODAL.LABEL_DETAILS_PLACEHOLDER'
                )}
                name="details"
                errors={errors}
                required={true}
                control={control}
                maxLength={500}
              />
            </Section>
            <Section id="document">
              <Typography gutterBottom variant="h6">
                {translate('DELETE_MODAL.LABEL_ATTACH')}
              </Typography>
              <Uploader
                onChange={handleOnFileSelected}
                wrapperDescription={translate('DELETE_MODAL.LABEL_ATTACH')}
                filesConfig={fileUploadConfig.deleteUser}
                currentListLength={fileList.length}
                marginBottom={10}
                errorStyle={!!errors.files?.message}
              />
              <Typography className="error">{errors.files?.message}</Typography>
              <AddedDocuments docs={fileList} removeFile={handleOnRemoveFile} />
            </Section>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={onCloseDialog} className="cancel">
              Cancelar
            </CustomButton>
            <CustomButton type="submit" className="exclude">
              Excluir
            </CustomButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
