// @ts-nocheck
import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { fileUploadConfig } from 'helpers/constants'

import AddedDocuments from 'ui/atoms/AddedDocuments'
import BaseTextField from 'ui/atoms/BaseTextField'
import DocumentUploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { WarningMessage } from 'ui/atoms/Messages'

const ResponsibleData = ({ documents, onRemoveFile, onSelectFiles, user }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <Typography variant="subtitle1" style={{ marginBottom: '24px' }}>
        {translate('RESPONSIBLE_DATA')}
      </Typography>

      <WarningMessage>{translate('WARNING_MESSAGE')}</WarningMessage>

      <Grid container spacing={3} style={{ marginTop: '22px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            label="CPF"
            name="responsibleCPF"
            value={user.cognitoUsername}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Nome"
            name="responsibleName"
            value={user?.firstName}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Sobrenome"
            name="responsibleLastName"
            value={user.lastName}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '24px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Email Institucional"
            name="responsibleEmail"
            value={user.email}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '40px' }}>
        <DocumentUploader
          label={'INSTITUTION_BOND_TEXT'}
          wrapperDescription={'DOCUMENT_UPLOAD'}
          onChange={onSelectFiles}
          currentListLength={documents.length}
          filesConfig={fileUploadConfig.institution}
        />
        <AddedDocuments docs={documents} removeFile={onRemoveFile} />
      </Grid>
    </>
  )
}

export default ResponsibleData
