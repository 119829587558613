import { compact } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Button, Grid, Typography } from '@material-ui/core'

import { AdminService } from 'api'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'ui/atoms/Dialog'
import { useGlobalizationContext } from 'ui/atoms/Globalization'

import { notificationUseCases } from 'views/Notification/providers'

import formSchema from './formSchema'
import Permissions from './Fragments/Permissions'

const CreateAdminModal = ({
  open,
  onClose: handleCloseDialog = () => {},
  onNewUserCreated: handleNewUserCreated = () => {}
}) => {
  const { translate } = useGlobalizationContext()
  const previousEmail = useRef(null)

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver
  })

  const { control, errors, reset, getValues, handleSubmit } = formMethods

  useEffect(() => {
    if (open) {
      reset({
        email: '',
        name: '',
        roles: []
      })
    }
  }, [open, reset])

  const onSubmit = async formData => {
    try {
      const { roles, ...rest } = formData
      const payload = {
        ...rest,
        hasRoleAdmin: roles.includes('ADMIN'),
        hasRoleGestor: roles.includes('GESTOR_ACOES')
      }

      await AdminService.createAdmin(payload)
      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      handleNewUserCreated()
    } catch (error) {
      console.log(error)
    }
  }

  const checkEmail = async () => {
    const newEmail = getValues('email')

    if (previousEmail.current !== newEmail) {
      try {
        previousEmail.current = newEmail
        const user = await AdminService.checkEmail(newEmail)

        const roles = compact([
          user.hasRoleAdmin ? 'ADMIN' : null,
          user.hasRoleGestor ? 'GESTOR_ACOES' : null
        ])

        reset({
          email: newEmail,
          name: user.name,
          roles
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <DialogTitle>{translate('CREATE_ADMIN_MODAL.TITLE')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} style={{ marginBottom: '-8px' }}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {translate('CREATE_ADMIN_MODAL.DESCRIPTION')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <BaseTextField
                required={true}
                label={translate('CREATE_ADMIN_MODAL.EMAIL')}
                placeholder={translate('CREATE_ADMIN_MODAL.EMAIL')}
                errors={errors}
                control={control}
                handleBlur={checkEmail}
                name="email"
              />
            </Grid>
            <Grid item xs={6}>
              <BaseTextField
                required={true}
                label={translate('CREATE_ADMIN_MODAL.NAME')}
                placeholder={translate('CREATE_ADMIN_MODAL.NAME')}
                errors={errors}
                control={control}
                name="name"
              />
            </Grid>
            <Grid item xs={12}>
              <Permissions formMethods={formMethods} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button variant="contained" onClick={handleCloseDialog}>
                {translate('COMMONS:CANCEL')}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                {translate('COMMONS:SEND')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CreateAdminModal
