import React, { useState, useContext, useCallback } from 'react'
import { conformToMask } from 'react-text-mask'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { AuthContext } from 'context/AuthContext'
import { CPF_MASK } from 'helpers/constants'
import { history, generatePrivatePath } from 'helpers/history'
import { maskToCpf,maskToEmail } from 'helpers/masks'


import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import Avatar from 'ui/atoms/Avatar'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import Loader from 'ui/molecules/Loader'

import AlertDialog from './Fragments/AlertDialog'
import Filter from './Fragments/Filter'
import { myPanelUseCases } from './providers'
import {
  NotificationList,
  UserData,
  UserDataList,
  UserDataListItem,
  ContainerRow,
  ContainerMessages,
  ContainerFilter
} from './style'

const MyPanel = () => {
  const { translate } = useContext(GlobalizationContext)
  const { userData } = useContext(AuthContext)

  const [permissionList, setPermissionList] = useState([])
  const [refresh, setRefresh] = useState(false)

  const filterListPromise = useCallback(async () => {
    const response = await myPanelUseCases.getFilters()
    setPermissionList(response)
  }, [])

  const notificationsPromise = useCallback(async () => {
    return await myPanelUseCases.getNotifications(permissionList)
  }, [permissionList, refresh])

  const handleEditProfile = () => {
    history.push(generatePrivatePath('/my-profile'))
  }

  const handleFilterChanged = (permission, checked) => {
    setPermissionList(currPermissionList => {
      const newList = [...currPermissionList]
      newList.find(item => item.permission === permission).checked = checked
      return newList
    })
  }

  const handleRefreshList = () => {
    setRefresh(state => !state)
  }

   
  return (
    <>
      <Loader />
      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />

      <UserData>
        <Avatar
          src={userData.profileImage ? `/${userData.profileImage}` : null}
        >
          {userData.firstName[0].toUpperCase()}
        </Avatar>

        <UserDataList>
          <UserDataListItem
            title="Usuario"
            value={`${userData.firstName} ${userData.lastName}`}
          />
          <UserDataListItem
            title="CPF"
            value={
              conformToMask(maskToCpf(userData.cognitoUsername, 3, 9, "******") || '', CPF_MASK)
                .conformedValue
            }
          />
          <UserDataListItem title="E-mail" value={maskToEmail(userData.email)} />
        </UserDataList>

        <IconButton
          onClick={handleEditProfile}
          id="EDIT_PROFILE"
          aria-label="editar"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </UserData>

      <ContainerRow>
        <AsyncLoad promiseFn={filterListPromise} minHeight={150}>
          <ContainerMessages>
            <AsyncLoad promiseFn={notificationsPromise} minHeight={150}>
              {notifications => (
                <NotificationList
                  notificationList={notifications}
                  asList={true}
                  showTitle={true}
                  emptyListMessage={translate('NOTIFICATION_LIST_EMPTY')}
                  onItemClicked={handleRefreshList}
                />
              )}
            </AsyncLoad>
          </ContainerMessages>
          <ContainerFilter>
            <Filter
              permissionList={permissionList}
              onFilterChanged={handleFilterChanged}
            />
          </ContainerFilter>
        </AsyncLoad>
      </ContainerRow>

{  userData.termOfUse ? '' : <AlertDialog userLogData={userData}></AlertDialog> }
     
    </>
  )
}

export default Wrapper(MyPanel, {
  namespace: 'MY_PANEL'
})
