import React, { useState, useContext, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { Hidden } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { UsersService } from 'api'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { recaptchaEnabled } from 'helpers/environment'
import { history } from 'helpers/history'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Loader from 'ui/atoms/Loader'
import { SuccessMessage, ErrorMessage } from 'ui/atoms/Messages'
import ReCAPTCHAInput from 'ui/atoms/ReCAPTCHAInput'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import Header from 'ui/organisms/Header/Private'

const useStyles = makeStyles({
  defaultContainer: {
    marginTop: '1.5rem'
  },
  baseButton: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#009B7F',
    color: '#ffffff',
    padding: '9px',
    fontWeight: 'normal',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#006855',
      color: '#ffffff'
    }
  }
})

const formSchema = Yup.object().shape({
  username: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  recaptcha: Yup.string().recaptcha()
})

function ResetPassword() {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const recaptchaRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [newPasswordRequested, setNewPasswordRequested] = useState(false)
  const [userError, setUserError] = useState('')

  const resolver = useYupValidationResolver(formSchema, { translate })
  const { control, errors, handleSubmit, formState } = useForm({
    mode: 'onChange',
    resolver
  })

  const { isValid } = formState

  const onSubmit = formData => {
    const userName = formData.username.includes('@')
      ? formData.username
      : formData.username.replace(/\D/g, '')

    const userData = {
      cpfOrEmail: userName,
      gRecaptchaResponse: formData.recaptcha
    }

    recaptchaRef.current && recaptchaRef.current.reset()

    setUserError('')
    setIsLoading(true)
    UsersService.requestNewPassword(userData)

      .then(() => {
        setIsLoading(false)
        setNewPasswordRequested(true)
      })
      .catch(error => {
        setIsLoading(false)
        if (error.response.data.message === 'user.not.found') {
          setUserError('Usuário inválido')
        }
      })
  }

  const handleClose = () => {
    history.push(`/`)
  }

  return (
    <React.Fragment>
      <Loader open={isLoading}></Loader>
      <Header hideActions={true} showBurger={false} />
      <Container>
        <Grid container justify="center">
          <Grid item xs={12} sm={10}>
            <BaseContainerHeader
              label={translate('REQUEST_NEW_PASSWORD_TITLE')}
              showCloseButton={true}
              onClose={handleClose}
              style={{ paddingBottom: '20px' }}
            />

            <Grid
              item
              xs={12}
              sm={10}
              container
              style={{ paddingBottom: '30px' }}
            >
              <Hidden smDown>
                <Grid item sm={2} />
              </Hidden>
              <Grid item xs={12} sm={8}>
                <Typography variant="body2">
                  {translate('REQUEST_NEW_PASSWORD_SUB_TITLE')}
                </Typography>
              </Grid>
              <Hidden smDown>
                <Grid item sm={2} />
              </Hidden>
            </Grid>

            <Grid
              item
              container
              spacing={3}
              xs={12}
              sm={10}
              justify="center"
              className={classes.defaultContainer}
            >
              <Grid item md={6}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  {...disableSubmitOnEnter}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <BaseTextField
                        required={true}
                        label={translate('REQUEST_NEW_PASSWORD_USER_LABEL')}
                        placeholder={translate(
                          'REQUEST_NEW_PASSWORD_USER_PLACEHOLDER'
                        )}
                        control={control}
                        name="username"
                      ></BaseTextField>
                    </Grid>
                    {recaptchaEnabled ? (
                      <Grid item xs={12}>
                        <ReCAPTCHAInput
                          control={control}
                          errors={errors}
                          recaptchaRef={recaptchaRef}
                        />
                      </Grid>
                    ) : null}
                    <Grid container justify="center">
                      {userError ? (
                        <Grid xs={12} md={8} item>
                          <ErrorMessage>{userError}</ErrorMessage>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid container justify="center">
                      {newPasswordRequested ? (
                        <Grid xs={12} md={8} item>
                          <SuccessMessage>
                            {translate('REQUEST_NEW_PASSWORD_SUCCESS')}
                          </SuccessMessage>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        // disabled={ formState.isValid ? false : true}
                        fullWidth
                        type={'submit'}
                        variant="contained"
                        disabled={!isValid}
                        className={classes.baseButton}
                      >
                        {translate('REQUEST_NEW_PASSWORD_SEND')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default ResetPassword
