import moment from 'moment'
import React, { useContext } from 'react'

import IconButton from '@material-ui/core/IconButton'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { InfoMessage } from 'ui/atoms/Messages'
import useStyles from 'ui/styles'

import { editUserUseCases } from '../../providers'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from './style'

const PendingInvites = ({ userData }) => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const onResendInvite = async invitation => {
    await editUserUseCases.resendInvite(invitation)
  }

  const getPermission = item => {
    const permission = item.split('_').slice(-1)
    return permission
  }

  return (
    <>
      <h3 className={classes.h3}>{translate('PENDING_INVITES_TITLE')}</h3>

      {userData.invitations.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {translate('PENDING_INVITES_INSTITUTION')}
                </TableCell>
                <TableCell>{translate('PENDING_INVITES_PERMISSION')}</TableCell>
                <TableCell>{translate('PENDING_INVITES_DATE')}</TableCell>
                <TableCell align="center" style={{ width: '60px' }}>
                  {translate('PENDING_INVITES_ACTION')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.invitations.map(invitation => (
                <TableRow key={invitation.id_user_invite}>
                  <TableCell>{invitation.name_unit}</TableCell>
                  <TableCell>
                    {translate(getPermission(invitation.permission))}
                  </TableCell>
                  <TableCell>
                    {moment(invitation.last_send_date).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => onResendInvite(invitation)}>
                      <i
                        className="fas fa-paper-plane"
                        style={{
                          fontSize: '20px',
                          marginLeft: '-2px',
                          marginRight: '2px'
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <InfoMessage>{translate('PENDING_INVITES_NO_INVITES')}</InfoMessage>
      )}
    </>
  )
}

export default PendingInvites
