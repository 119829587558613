import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import wrapper from 'ui/atoms/Wrapper'

import RequestSharingForm from '../../../../fragments/RequestForm'
import { requestSharingUseCases } from '../../../../providers'

const ApproveRequestSharing = () => {
  const { id } = useParams()

  const [paramsPromise] = useState(async () => {
    const params = await requestSharingUseCases.getParams()
    const refusalReasonList = await requestSharingUseCases.getRefusalReasons()
    return { ...params, refusalReasonList }
  })

  const initialDataPromise = useCallback(async () => {
    return await requestSharingUseCases.getRequestData(id)
  }, [id])

  const allPromisses = useCallback(async () => {
    const initialData = await initialDataPromise()
    const params = await paramsPromise

    return { initialData, params }
  }, [initialDataPromise, paramsPromise])

  return (
    <AsyncLoad promiseFn={allPromisses}>
      {({ params, initialData }) => (
        <RequestSharingForm
          readOnly
          initialData={initialData}
          params={params}
        />
      )}
    </AsyncLoad>
  )
}

export default wrapper(ApproveRequestSharing, { namespace: 'REQUEST_SHARING' })
