import React, { useContext } from 'react'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'

import { NotificationContainer, NotificationMessage } from './style'

const EmptyListCmp = ({ emptyListMessage }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <NotificationContainer>
      <NotificationMessage>
        {translate(emptyListMessage || 'NOTIFICATION_EMPTY_LIST')}
      </NotificationMessage>
    </NotificationContainer>
  )
}

const EmptyList = wrapper(EmptyListCmp, {
  namespace: 'NOTIFICATIONS'
})

export { EmptyList }
