import React from 'react'

import { Typography } from '@material-ui/core'

const InfoBox = ({ text, value }) => {
  return (
    <div>
      <Typography
        variant="caption"
        style={{ display: 'block', fontWeight: 'bold' }}
      >
        {text}
      </Typography>
      <p>{value}</p>
    </div>
  )
}

export default InfoBox
