import { NotificationService, UsersService } from 'api'

import { notificationUseCases } from 'views/Notification/providers'

import MyPanelUseCases from './myPanel.useCases'

export const myPanelUseCases = new MyPanelUseCases({
  UsersService,
  NotificationService,
  notificationUseCases
})
