import startOfToday from 'date-fns/startOfToday'
import * as Yup from 'yup'
export default Yup.object().shape({
  approve: Yup.boolean()
    .fromString()
    .nullable()
    .required('VALIDATION:REQUIRED_ERROR'),
  needsComplementation: Yup.boolean().when('approve', (aprove, schema) =>
    aprove ? schema.notRequired() : schema.required()
  ),

  reason: Yup.string().ensure(),
  reasonType: Yup.number()
    .allowEmptyString()
    .when(
      ['approve', 'needsComplementation'],
      (approve, needsComplementation, schema) => {
        return !approve && !needsComplementation
          ? schema.required('VALIDATION:REQUIRED_ERROR')
          : schema.notRequired()
      }
    ),

  complementation: Yup.string().when(
    ['approve', 'needsComplementation'],
    (approve, needsComplementation, schema) => {
      return !approve && needsComplementation
        ? schema.required()
        : schema.notRequired()
    }
  ),

  specificEquipment: Yup.array()
    .of(Yup.object())
    .when('approve', (aprove, schema) =>
      aprove
        ? schema.required('VALIDATION:REQUIRED_ERROR')
        : schema.notRequired()
    ),
  responsible: Yup.string().when('approve', (aprove, schema) =>
    aprove ? schema.required() : schema.notRequired()
  ),
  email: Yup.string()
    .email()
    .when('approve', (aprove, schema) =>
      aprove ? schema.required() : schema.notRequired()
    ),
  phone: Yup.string().when('approve', (aprove, schema) =>
    aprove ? schema.required() : schema.notRequired()
  ),
  suggestedDateFrom: Yup.date()
    .nullable()
    .typeError('VALIDATION:INVALID_DATE')
    .min(startOfToday(), 'VALIDATION:GREATER_OR_EQUAL_THAN_TODAY_ERROR')
    .when('approve', (aprove, schema) =>
      aprove
        ? schema.required('VALIDATION:REQUIRED_ERROR')
        : schema.notRequired()
    ),
  suggestedDateTo: Yup.date()
    .nullable()
    .typeError('VALIDATION:INVALID_DATE')
    .min(startOfToday(), 'VALIDATION:GREATER_OR_EQUAL_THAN_TODAY_ERROR')
    .when('approve', (aprove, schema) =>
      aprove
        ? schema
            .required('VALIDATION:REQUIRED_ERROR')
            .greaterThan('suggestedDateFrom')
        : schema.notRequired()
    )
})
