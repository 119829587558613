import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'

import { primaryColor } from 'helpers/constants'

import DefaultHint from 'ui/molecules/commons/DefaultHint'

export const StyledTableRow = withStyles(theme => ({
  root: {
    height: '40px',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    '&.placeholder': {
      backgroundColor: '#999999'
    }
  }
}))(TableRow)

export const PCustom = styled.p`
  margin: 0;
  display: inline-block;
  margin-left: 27px;
  font-weight: 600;
  margin-right: 5px;
  font-size: 14px;
`

export const Text = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  display: inline-block;
  border: 0;
  outline: none;
  padding: 0 28px;
  border: 1px solid transparent;

  ${PCustom} + & {
    padding-left: 8px;
  }
`

export const WrapperFooter = styled.div`
  width: 100%;
  background-color: ${props => props.theme.palette.grey[200]};
`

export const OpenLab = styled.button`
  border: 0;
  color: ${primaryColor};
  background: none;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  i {
    font-size: 18px;
    margin: 3px 0 0 5px;
  }
`

export const MultiuserHint = styled(DefaultHint).attrs({
  icon: 'fas fa-users',
  color: primaryColor
})`
  margin-left: 0;
  margin-right: 8px;
  font-size: 16px;
`
