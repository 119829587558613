import React, { useState, useContext } from 'react'
import { Trans } from 'react-i18next'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import {
  DialogParagraph,
  DialogButton,
  CloseButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TermsLink
} from './styles'

const DialogTerms = ({ linkAs = TermsLink, children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { translate } = useContext(GlobalizationContext)

  const termsContent = (
    <DialogParagraph style={{ margin: '15px' }}>
      <Trans i18nKey="USE_TERMS"></Trans>
    </DialogParagraph>
  )

  const DialogLink = React.createElement(
    linkAs,
    { onClick: () => setModalOpen(true) },
    children
  )

  return (
    <>
      {DialogLink}
      <Dialog
        maxWidth={'lg'}
        onClose={() => setModalOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={modalOpen}
      >
        <DialogTitle id="customized-dialog-title">
          {translate('REGISTER_USER_TERMS')}

          <CloseButton aria-label="close" onClick={() => setModalOpen(false)} />
        </DialogTitle>
        <DialogContent>{termsContent}</DialogContent>
        <DialogActions>
          <DialogButton variant="contained" onClick={() => setModalOpen(false)}>
            {translate('USER_TERMS_DIALOG_OK')}
          </DialogButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DialogTerms
