import React from 'react'

import {
  InformationContainer,
  InformationTitle,
  InformationNumber
} from '../style'

const Information = ({ name, number }) => {
  return (
    <>
      <InformationContainer>
        <InformationTitle>{name}</InformationTitle>
        <InformationNumber>{number}</InformationNumber>
      </InformationContainer>
    </>
  )
}

export default Information
