import ChangePassword from 'views/Auth/Subviews/ChangePassword'
import Login from 'views/Auth/Subviews/Login'
import RegisterUser from 'views/Auth/Subviews/RegisterUser'
import RequestNewPassword from 'views/Auth/Subviews/RequestNewPassword'
import ResetPassword from 'views/Auth/Subviews/ResetPassword'

export const NO_WRAPPING_ROUTES = [
  {
    path: '/login',
    exact: true,
    component: Login,
    breadcrumb: 'LOGIN'
  },
  {
    path: '/register/user',
    exact: true,
    component: RegisterUser,
    breadcrumb: 'REGISTER_USER'
  },
  {
    path: '/change-password',
    component: ChangePassword,
    waitAuth: false
  },
  {
    path: '/request-new-password',
    exact: true,
    component: RequestNewPassword,
    breadcrumb: 'REQUEST_NEW_PASSWORD'
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPassword,
    breadcrumb: 'RESET_PASSWORD'
  }
]
