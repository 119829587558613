import { useMemo } from 'react'

import {
  filterFieldTypeList,
  isEmpty,
  getEncodeKey,
  encodeDTO
} from '../Fragments/FilterChip/utils'

export const useAdvancedFilter = filter => {
  const result = useMemo(() => {
    return filterFieldTypeList.reduce((acc, field) => {
      const fieldValue = filter[field]
      if (isEmpty(field, fieldValue)) return acc
      return {
        ...acc,
        [getEncodeKey(field)]: encodeDTO(field, fieldValue)
      }
    }, {})
  }, [filter])

  return result
}
