// @ts-nocheck
import styled from 'styled-components'

import { MarkerGreen, MarkerOrange, MarkerRed } from 'ui/atoms/Marker'

const getMarkerColor = situation => {
  switch (situation) {
    case 'Em manutenção':
      return MarkerOrange
    case 'ACTIVE':
      return MarkerGreen
    case 'Quebrado':
      return MarkerRed
    default:
  }
}

export const Marker = styled.span`
  border-radius: 50%;
  display: block;
  width: 16px;
  height: 16px;

  ${props => getMarkerColor(props.type)}
`
