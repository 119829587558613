import React, { useContext } from 'react'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'

import { primaryColor, hoverPrimaryColor } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const BaseButtonStyled = styled(Button)`
  text-transform: none;
  background-color: ${primaryColor};
  color: #ffffff;
  align-self: center;
  box-shadow: 2px 2px 5px rgba(64, 64, 64, 0.28);
  padding: 8px 21px;
  border-radius: 2px;
  font-weight: 600;
  &:hover {
    background: ${hoverPrimaryColor};
    box-shadow: 2px 2px 5px rgba(64, 64, 64, 0.28);
  }

  &.portal-view {
    margin-right: 15px;

    @media screen and (max-width: 640px) {
      margin-right: 10px;

      > span {
        font-size: 12px;
      }
    }
  }
`

function BaseButton({ label, onClick, ...props }) {
  const { translate } = useContext(GlobalizationContext)

  return (
    <BaseButtonStyled onClick={onClick} {...props}>
      {translate(label)}
    </BaseButtonStyled>
  )
}

export default BaseButton
