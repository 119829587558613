import React, { useContext } from 'react'

import { Chip } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import {
  getChipLabel,
  getIdKey,
  isGrouped,
  isEmpty,
  clearFilter,
  isList
} from './utils'

export * from './utils'

const FilterChip = ({ field, filter, onClearFilter = () => {} }) => {
  const { translate } = useContext(GlobalizationContext)

  const isFieldGrouped = isGrouped(field)

  if (isEmpty(field, filter[field])) return null

  return isFieldGrouped || !isList(field) ? (
    <Chip
      label={getChipLabel({ field, filter, translate })}
      onDelete={() => {
        onClearFilter({ field, newValue: clearFilter({ field }) })
      }}
    />
  ) : (
    filter[field].map(item => (
      <Chip
        key={item[getIdKey(field)] || field}
        label={getChipLabel({ field, item, translate })}
        onDelete={() => {
          onClearFilter({
            field,
            newValue: clearFilter({ field, value: filter[field], item })
          })
        }}
      />
    ))
  )
}

export default FilterChip
