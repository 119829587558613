import React, { useCallback, useContext, useEffect, useState } from 'react'

import { Box, Grid } from '@material-ui/core'

import { InfraestrucutureService } from 'api'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import MapImage from 'assets/images/map.png'

import Information from './fragments/Information'
import SearchForm from './SearchForm'
import { Block, BannerText, Image, TitleBanner, WrapBanner } from './style'

function BannerHero() {
  const { translate } = useContext(GlobalizationContext)
  const [infoCount, setInfoCount] = useState(null)

  const getInfo = useCallback(async () => {
    const info = await InfraestrucutureService.getAssetCount()
    setInfoCount(info)
  }, [])

  useEffect(() => {
    getInfo()
  }, [getInfo])

  return (
    <WrapBanner>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ margin: '0 auto', maxWidth: '1150px' }}
      >
        <Grid item xs={12} md={7}>
          <TitleBanner>{translate('HOME_TITLE')}</TitleBanner>
          <BannerText>{translate('HOME_BANNER_TEXT')}</BannerText>
          <SearchForm
            placeholder={translate('HOME_SEARCH_ASSETS_PLACEHOLDER')}
          />
          <Block>
            <Information
              name={translate('INSTITUTION')}
              number={infoCount?.totalInst}
            />
            <Information
              name={translate('LABORATORY')}
              number={infoCount?.totalLab}
            />
            <Information
              name={translate('EQUIPMENTS')}
              number={infoCount?.totalEquip}
            />
          </Block>
        </Grid>

        <Box
          component={Grid}
          // @ts-ignore
          item
          md={5}
          display={{ xs: 'none', md: 'block' }}
        >
          <Image src={MapImage} alt="Map" />
        </Box>
      </Grid>
    </WrapBanner>
  )
}
export default BannerHero
