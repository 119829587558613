import { createSlice } from '@reduxjs/toolkit'

export const REQUEST_REDUCER = 'request'

const request = createSlice({
  name: REQUEST_REDUCER,
  initialState: {
    currentRequests: 0
  },
  reducers: {
    incrementRequest: state => {
      state.currentRequests = state.currentRequests + 1
    },
    decrementRequest: (state, { payload }) => {
      if (state.currentRequests > 0) {
        state.currentRequests = state.currentRequests - 1
      }
    }
  }
})

export const { incrementRequest, decrementRequest } = request.actions

export default request.reducer
