import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Typography } from '@material-ui/core'
import { Link as MuiLink } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import { generatePrivatePath } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const ConfirmDialog = ({ open, handleCloseDialog, handleConfirm }) => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Trans
          parent={Typography}
          t={translate}
          // @ts-ignore
          variant={'body2'}
          i18nKey={'NEW_INSTITUTION_DIALOG_TEXT'}
          components={{
            Link: (
              <MuiLink
                component={Link}
                to={generatePrivatePath('/my-profile')}
              />
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} style={{ color: '#666' }}>
          {translate('ACTIONS_LIST.CANCEL')}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {translate('ACTIONS_LIST.CONTINUE')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
