import React from 'react'

// import { BarLoader } from 'react-spinners'
import { primaryColor } from 'helpers/constants'

import AtomSpinner from 'ui/atoms/AtomSpinner'

import { Container } from './style'

export const DefaultLoader = ({
  color = primaryColor,
  size = 80,
  minHeight = 0
}) => {
  return (
    <Container minHeight={minHeight}>
      {/* <BarLoader width="30%" color={color} /> */}
      <AtomSpinner size={size} color={color} />
    </Container>
  )
}
