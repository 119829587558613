import styled from 'styled-components'

import { primaryColor } from 'helpers/constants'

import DefaultHint from 'ui/molecules/commons/DefaultHint'

export const FilterTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  margin-left: 16px;
  display: flex;
  align-items: center;

  color: #000000;
`

export const FilterList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  margin-left: 5px;
`

export const FilterItem = styled.li`
  display: flex;
`

export const CheckboxContainer = styled.div``

export const DataContainer = styled.div``

export const Hint = styled(DefaultHint)`
  font-size: 18px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #333333;

  display: flex;
  align-items: center;
`

export const TitleContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-top: 12px;

  border-bottom: 1px solid ${primaryColor};
`

export const Desc = styled.ul`
  list-style: none;
  padding: 0;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
`

export const DescItem = styled.li`
  &:not(:first-child) {
    margin-top: 4px;
  }
`
