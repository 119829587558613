import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Grid, Link as MuiLink, SvgIcon, Typography } from '@material-ui/core'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'

import { ReactComponent as AtomIcon } from 'assets/icons/atom.svg'
import { ReactComponent as LabIcon } from 'assets/icons/lab.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg'

const PageInfo = () => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <>
      <BaseContainerHeader
        label={translate('SCIENCE_SECTION_TITLE')}
        showCloseButton={false}
      />

      <Trans
        parent={'p'}
        t={translate}
        i18nKey="SCIENCE_SECTION_INTRO_TEXT"
        components={{ Link: <MuiLink component={Link} to="/register" /> }}
      />

      <Grid
        container
        spacing={2}
        style={{ marginBottom: '24px', marginTop: '24px', textAlign: 'center' }}
      >
        <Grid item xs={12} md={4}>
          <SvgIcon
            component={AtomIcon}
            viewBox="0 0 32 32"
            style={{ fontSize: '72px' }}
          />

          <Typography variant="subtitle1" style={{ margin: '32px 0 24px' }}>
            {translate('SCIENCE_SECTION_SUPPORT_TO_SCIENCE_TITLE')}
          </Typography>
          <Typography variant="body2">
            {translate('SCIENCE_SECTION_SUPPORT_TO_SCIENCE_TEXT')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <SvgIcon
            component={LabIcon}
            viewBox="0 0 87 87"
            style={{ fontSize: '72px' }}
          />

          <Typography variant="subtitle1" style={{ margin: '32px 0 24px' }}>
            {translate('SCIENCE_SECTION_INFRAESTRUCTURE_TITLE')}
          </Typography>
          <Typography variant="body2">
            {translate('SCIENCE_SECTION_INFRAESTRUCTURE_TEXT')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <SvgIcon
            component={ShareIcon}
            viewBox="0 0 74 74"
            style={{ fontSize: '72px' }}
          />
          <Typography variant="subtitle1" style={{ margin: '32px 0 24px' }}>
            {translate('SCIENCE_SECTION_COLLAB_TITLE')}
          </Typography>
          <Typography variant="body2">
            {translate('SCIENCE_SECTION_COLLAB_TEXT')}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default PageInfo
