import styled from 'styled-components'

import MaterialSvgIcon from '@material-ui/core/SvgIcon'

const SvgIcon = styled(MaterialSvgIcon)`
  && {
    &.MuiSvgIcon-fontSizeSmall {
      font-size: 16px;
    }

    &.MuiSvgIcon-fontSizeLarge {
      font-size: 20px;
    }
  }
`

export default SvgIcon
