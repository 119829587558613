import { combineReducers } from '@reduxjs/toolkit'

import breadcrumbData from './slices/breadcrumbData.slice'
import campaign from './slices/campaign.slice'
import notification from './slices/notification.slice'
import request from './slices/request.slice'

const rootReducer = combineReducers({
  request,
  notification,
  breadcrumbData,
  campaign
})

export default rootReducer
