
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react'
import { useParams } from 'react-router'

import { generatePrivatePath, history } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Tab, Tabs } from 'ui/atoms/Tabs'
import Wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import Loader from 'ui/molecules/Loader'

import { ADMIN_ROUTES } from '../../constants/routes'
import PendingInvites from './Fragments/PendingInvites'
import UserData from './Fragments/UserData'
import { editUserUseCases } from './providers'

import {} from './style'

const EditUser = () => {
  const { userId } = useParams()
  const [currTab, setCurrTab] = useState('UserData')
  const [userData, setUserData] = useState(null)
  const { translate } = useContext(GlobalizationContext)

  const onUserChanged = useCallback(userData => {
    // setUserData(userData)
    history.push(generatePrivatePath(ADMIN_ROUTES.USERS))
  }, [])

  const tabs = useMemo(
    () => ({
      UserData: { component: UserData, props: { userData, onUserChanged } },
      PendingInvites: {
        component: PendingInvites,
        props: { userData, onUserChanged }
      }
    }),
    [userData, onUserChanged]
  )

  const loadUser = useCallback(async () => {
    try {
      const userData = await editUserUseCases.getUser(userId)
      setUserData(userData)
    } catch (err) {
      console.log(err)
    }
  }, [userId])

  useEffect(() => {
    loadUser()
  }, [loadUser])

  return (
    <>
      <Loader />
      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />

      <Tabs
        onChange={(event, newTab) => setCurrTab(newTab)}
        value={currTab}
        style={{ paddingBottom: '40px' }}
      >
        <Tab label={translate('USER_DATA_TITLE')} value={'UserData'} />
        <Tab
          label={translate('PENDING_INVITES_TITLE')}
          value={'PendingInvites'}
        />
      </Tabs>

      {userData &&
        React.createElement(tabs[currTab].component, tabs[currTab].props)}
    </>
  )
}

export default Wrapper(EditUser, {
  namespace: 'USER_EDIT'
})
