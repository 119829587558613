import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo
} from 'react'
import { useWatch } from 'react-hook-form'

import Grid from '@material-ui/core/Grid'

import { AddressService } from 'api'
import { zipCode } from 'helpers/generalInputMasks'
import regionList from 'helpers/regionList'
import stateList from 'helpers/stateList'

import BaseSelect from 'ui/atoms/BaseSelect'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'

import { CustomContainer, CustomTitle } from './styles'

export const getAddressType = address => {
  if (address?.addressType) return address.addressType

  if (!address.street && !address.city) {
    return 'CUSTOMIZED'
  } else if (!address.street && address.city) {
    return 'PARTIAL'
  } else {
    return 'COMPLETED'
  }
}

const Address = props => {
  const { disabled = true, onAddressChange, formMethods, required } = props
  const { translate } = useContext(GlobalizationContext)
  const { register, errors, getValues, setValue, clearErrors, control } =
    formMethods

  const [fieldStates, setFieldStates] = useState({})
  const [zipCodeError, setZipCodeError] = useState(null)

  const getStateRegion = state => {
    return stateList.find(stateItem => stateItem.sigla === state)?.region || ''
  }

  const setAddress = address => {
    clearErrors(['district', 'city', 'street', 'state', 'number'])

    setValue('district', address.district || '')
    setValue('city', address.city || '')
    setValue('street', address.street || '')
    setValue('state', address.state || '')
    setValue('region', getStateRegion(address.state) || '')
    setValue('addressType', address.addressType || 'CUSTOMIZED')
  }

  const addressFields = useWatch({
    control,
    name: [
      'zipCode',
      'street',
      'number',
      'complement',
      'district',
      'city',
      'state',
      'region'
    ],
    defaultValue: {
      zipCode: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      region: ''
    }
  })

  const getCEP = useCallback(cep => {
    if (!cep) {
      updateCep({
        street: '',
        city: '',
        district: '',
        state: '',
        region: '',
        addressType: 'CUSTOMIZED'
      })
      setValue('number', '')
      return
    }

    cep = cep.replace(/[^\d]+/g, '')

    if (cep.length !== 8) {
      updateCep({})
      return setZipCodeError('VALIDATION:ZIPCODE_INVALID')
    }

    AddressService.getCep(cep)
      .then(response => {
        setZipCodeError(null)
        updateCep(response.data)
      })
      .catch(error => {
        updateCep({
          street: '',
          city: '',
          district: '',
          state: '',
          region: '',
          addressType: 'CUSTOMIZED'
        })

        if (error && error.response?.data?.message === 'cep.not.found') {
          setZipCodeError('VALIDATION:ZIPCODE_NOT_FOUND')
        } else if (error && error.response?.data?.message === 'cep.invalid') {
          setZipCodeError('VALIDATION:ZIPCODE_INVALID')
        } else if (errors.zipCode) {
          setZipCodeError(null)
        }

        console.error('error', error.response)
      })
  }, [])

  const updateCep = newData => {
    setZipCodeError(null)
    setAddress(newData)
    onAddressChange && onAddressChange(newData)
  }

  const handleAddressChanged = event => {
    const newData = {
      ...addressFields,
      [event.target.name]: event.target.value
    }
    onAddressChange && onAddressChange(newData)
  }

  useEffect(() => {
    let region = ''

    if (addressFields.state) {
      region = getStateRegion(addressFields.state)
    }

    setValue('region', region)
  }, [addressFields.state, setValue])

  useEffect(() => {
    const cleanZipCode = addressFields.zipCode.replace(/\D/gm, '')
    const isZipCodeOk = cleanZipCode && cleanZipCode.length === 8

    const addressType = getValues('addressType')

    setFieldStates({
      zipCode: disabled,
      street:
        disabled || !isZipCodeOk || ['COMPLETED'].indexOf(addressType) >= 0,
      number: disabled || !isZipCodeOk,
      complement: disabled || !isZipCodeOk,
      district:
        disabled || !isZipCodeOk || ['COMPLETED'].indexOf(addressType) >= 0,
      city:
        disabled ||
        !isZipCodeOk ||
        ['COMPLETED', 'PARTIAL'].indexOf(addressType) >= 0,
      state:
        disabled ||
        !isZipCodeOk ||
        ['COMPLETED', 'PARTIAL'].indexOf(addressType) >= 0,
      region: true
    })
  }, [addressFields.zipCode, disabled, getValues])

  const computedZipCodeError = useMemo(() => {
    return zipCodeError || errors.zipCode
      ? { zipCode: errors.zipCode || { message: translate(zipCodeError) } }
      : {}
  }, [translate, errors.zipCode, zipCodeError])

  return (
    <CustomContainer>
      <CustomTitle>{translate('TITLE')}</CustomTitle>
      <input name="addressType" ref={register} type="hidden" />
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={3}>
          <BaseTextField
            disabled={fieldStates.zipCode}
            required={required}
            label={translate('ZIP_CODE')}
            placeholder={translate('ZIP_CODE_MASK')}
            errors={computedZipCodeError}
            control={control}
            name="zipCode"
            handleBlur={() => getCEP(getValues('zipCode'))}
            handleInput={zipCode}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={10}>
          <BaseTextField
            disabled={fieldStates.street}
            required={required}
            label={translate('STREET')}
            placeholder={translate('STREET')}
            errors={errors}
            control={control}
            name="street"
            handleChange={handleAddressChanged}
          ></BaseTextField>
        </Grid>
        <Grid item xs={12} md={2}>
          <BaseTextField
            disabled={fieldStates.number}
            required={required}
            label={translate('NUMBER')}
            placeholder={translate('NUMBER')}
            errors={errors}
            control={control}
            name="number"
            handleChange={handleAddressChanged}
          ></BaseTextField>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={12}>
          <BaseTextField
            disabled={fieldStates.complement}
            required={false}
            label={translate('COMPLEMENT')}
            placeholder={translate('COMPLEMENT')}
            errors={errors}
            control={control}
            name="complement"
            handleChange={handleAddressChanged}
            maxLength={255}
          ></BaseTextField>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            disabled={fieldStates.district}
            required={required}
            label={translate('DISTRICT')}
            placeholder={translate('DISTRICT')}
            errors={errors}
            control={control}
            name="district"
            handleChange={handleAddressChanged}
          ></BaseTextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            disabled={fieldStates.city}
            required={required}
            label={translate('CITY')}
            placeholder={translate('CITY')}
            errors={errors}
            control={control}
            name="city"
            handleChange={handleAddressChanged}
          ></BaseTextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseSelect
            disabled={fieldStates.state}
            required={required}
            placeholder={translate('STATE_SELECT')}
            label={translate('INITIALS')}
            errors={errors}
            control={control}
            name="state"
            handleChange={handleAddressChanged}
            options={stateList}
          ></BaseSelect>
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseSelect
            disabled={true}
            required={required}
            placeholder={translate('REGION_SELECT')}
            label={translate('REGION')}
            control={control}
            handleChange={handleAddressChanged}
            name="region"
            optionRenderer={region => translate(`REGIONS:${region}`)}
            options={regionList}
          />
        </Grid>
      </Grid>
    </CustomContainer>
  )
}

export default Wrapper(Address, {
  namespace: 'ADDRESS'
})
