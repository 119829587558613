import React, { useContext } from 'react'
import styled from 'styled-components'

import MenuItem from '@material-ui/core/MenuItem'
import MaterialSelect from '@material-ui/core/Select'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

const FilterLabel = styled.label`
  font-weight: 600 !important;
  font-size: 14px;
`

const Select = styled(MaterialSelect)`
  margin-left: 10px;
  min-width: 240px;

  .MuiSelect-select {
    display: flex;
    padding: 0;
    font-size: 14px;

    &:focus {
      background-color: white;
    }
  }
`

const Option = styled(MenuItem)`
  background-color: #f2f2f2;
  cursor: pointer;
  padding: 5px;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: #fafafa;
  }
`

export function FilterSelect(props) {
  const { translate } = useContext(GlobalizationContext)

  const classes = useStyles()
  const { label, value, defaultValue, options, onChange: handleChange } = props

  const idKey = props.idKey || 'id'
  const descriptionKey = props.descriptionKey || 'name'
  const translateFn = props.translateOptions ? translate : name => name
  const optionValue = options.map((obj, index) => obj[descriptionKey])

  const dropdownListStyle = {
    classes: {
      paper: classes.selectDropdownItem,
      list: classes.selectDropdownList
    }
  }

  return (
    <>
      <FilterLabel>{label}:</FilterLabel>
      <Select
        name="FILTER_SELECT"
        disableUnderline
        onChange={handleChange}
        value={value}
        MenuProps={dropdownListStyle}
        displayEmpty
      >
        {defaultValue ? <Option value="">{defaultValue}</Option> : null}
        {options.map((obj, index) => (
          <Option key={obj[idKey]} value={obj[idKey]}>
            {translateFn(optionValue[index].toString())}
          </Option>
        ))}
      </Select>
    </>
  )
}

export default FilterSelect
