import React, { useCallback, useContext } from 'react'

import { InstitutionService, LaboratoryService } from 'api'
import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import TableActions from 'ui/atoms/TableActions'

import { notificationUseCases } from 'views/Notification/providers'

import { ListContainer, Marker } from '../../styles'

const RequestList = ({ list, updateList }) => {
  const { translate } = useContext(GlobalizationContext)

  const remove = useCallback(
    async item => {
      if (item.name) {
        try {
          if (item.type === 'LABORATORY') {
            await LaboratoryService.deleteLaboratoryById(item.idLab)
            notificationUseCases.newSuccess(translate('MESSAGES.SUCCESS'))
          } else {
            await InstitutionService.deleteInstitutionByCnpj(item.cnpj)
            notificationUseCases.newSuccess(translate('MESSAGES.SUCCESS'))
          }
          updateList(oldList => {
            const newList = [...oldList]
            const itemIndex = newList.findIndex(obj => obj.name === item.name)
            newList.splice(itemIndex, 1)
            return newList
          })
        } catch {
          notificationUseCases.newError('MESSAGES.SOLICITATION_ERROR')
        }
      }
    },
    [translate, updateList]
  )

  const viewRequest = item => {
    if (item.type === 'LABORATORY') {
      viewLaboratoryRequest(item)
    } else {
      viewinstitutionRequest(item)
    }
  }

  const viewLaboratoryRequest = async item => {
    history.push(generatePrivatePath(`/laboratory/${item.idLab}`))
  }

  const viewinstitutionRequest = async item => {
    history.push(
      generatePrivatePath(`/institution-solicitation-view/${item.cnpj}`)
    )
  }

  const getSituation = situation => {
    switch (situation) {
      case 'PENDING':
      case 'WAITING_APPROVAL':
        return translate('SITUATION_LIST.PENDING')
      case 'REJECTED':
        return translate('SITUATION_LIST.REJECT')
      default:
    }
  }

  const getType = type => {
    switch (type) {
      case 'LABORATORY':
        return translate('TYPE_LIST.LABORATORY')
      case 'INSTITUTION':
        return translate('TYPE_LIST.INSTITUTION')
      default:
    }
  }

  const actionMap = {
    EXCLUDE: row => remove(row),
    VIEW: row => viewRequest(row)
  }

  const rowActionList = value =>
    value.map(action => {
      return {
        code: action,
        name: `ACTIONS_LIST.${action}`
      }
    })

  const handleRowAction = (code, row) => {
    actionMap[code](row)
  }

  return (
    <>
      {list?.map((value, index) => (
        <ListContainer key={index}>
          <Marker
            // @ts-ignore
            type={value.situation}
          />

          <span>{getType(value.type)}</span>

          <span>{value.name}</span>

          <span>{getSituation(value.situation)}</span>

          <TableActions
            actionList={rowActionList(value.actions)}
            handleActionSelected={code => handleRowAction(code, value)}
          />
        </ListContainer>
      ))}
    </>
  )
}

export default RequestList
