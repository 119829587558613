import axios from './axios'

export const InfraestrucutureService = {
  getRequestList() {
    return axios.get('/infrastructures/').then(resp => resp.data)
  },
  getAssetCount() {
    return axios.get('/assets/count').then(resp => resp.data)
  }
}

export default InfraestrucutureService
