import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import clsx from 'clsx'
import ChipInput from 'material-ui-chip-input'
import React, { useState, useRef, useCallback, useContext } from 'react'
import Autosuggest from 'react-autosuggest'
import { Controller } from 'react-hook-form'

import { FormHelperText } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import { primaryColor } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import IconRemove from 'assets/images/remove.png'

function renderInput({
  value,
  onChange,
  translate,
  chips,
  ref,
  classes,
  name,
  ...other
}) {
  const chipInputClasses = {
    root: classes.root,
    inputRoot: classes.inputRoot,
    input: classes.input,
    chipContainer: classes.chipContainer,
    label: classes.label,
    helperText: classes.helperText,
    chip: classes.chip
  }

  return (
    <ChipInput
      clearInputValueOnChange
      onUpdateInput={onChange}
      value={chips}
      inputRef={ref}
      blurBehavior="clear"
      chipRenderer={(chipProps, key) =>
        chipRenderer({ ...chipProps, name }, key)
      }
      disableUnderline={true}
      placeholder={translate('REGISTER_LABORATORY_ADD_TAG')}
      alwaysShowPlaceholder={true}
      classes={chipInputClasses}
      InputProps={{ name }}
      {...other}
    />
  )
}
function chipRenderer(
  { value, isDisabled, isReadOnly, handleClick, handleDelete, className, name },
  key
) {
  const events = !isDisabled
    ? {
        onClick: handleClick,
        onDelete: handleDelete
      }
    : {}

  return (
    <Chip
      {...events}
      key={key}
      className={className}
      label={value.name}
      name={`${name}_chip`}
      data-value={value.name}
      style={{
        pointerEvents: isDisabled || isReadOnly ? 'none' : undefined
      }}
      deleteIcon={
        !isDisabled ? (
          <img src={IconRemove} style={{ width: '10px', height: '14px' }} />
        ) : null
      }
    />
  )
}
function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query)
  const parts = parse(suggestion.name, matches)

  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={e => e.preventDefault()} // prevent the click causing the input to be blurred
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>{part.text}</span>
          )
        })}
      </div>
    </MenuItem>
  )
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  )
}

function getSuggestionValue(suggestion) {
  return suggestion.name
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '-38px'
  },
  imgRemove: {
    width: '10px',
    height: '14px'
  },
  inputRoot: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    flex: 1,
    background: '#f2f2f2',
    padding: '3px 15px',
    fontWeight: '600',
    fontSize: '14px',
    color: '#333333',
    height: '35px',
    width: '285px',
    position: 'absolute',
    left: '-300px',
    marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: 0,
      top: '-47px',
      marginTop: 0
    }
  },
  wrapper: {
    position: 'relative',
    background: '#fafafa',
    border: '1px solid #cccccc',
    padding: '10px 10px 10px 20px',
    '&.hasError': {
      borderColor: 'red'
    },
    '&:before': {
      content: `'*'`,
      position: 'absolute',
      left: '-11px',
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      top: '68px',
      width: '20px',
      height: '20px',
      fontSize: '25px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    }
  },
  wrapButtons: {
    position: 'relative'
  },
  title: {
    margin: 0,
    fontSize: '14px',
    color: '#333',
    fontWeight: '600',
    marginTop: '12px',
    marginBottom: '5px'
  },
  titleAdd: {
    fontSize: '14px',
    color: '#333',
    fontWeight: 600,
    paddingBottom: '0.4rem',
    margin: 0
  },
  addButton: {
    top: 0,
    position: 'absolute',
    left: '252px',
    padding: '11px',
    lineHeight: '20px',
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: primaryColor
    },
    [theme.breakpoints.down('sm')]: {
      left: 'auto',
      right: 0,
      top: '-2px'
    }
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  textField: {
    width: '100%'
  },
  chipContainer: {
    width: 'calc(100% - 300px)',
    marginLeft: '300px',
    display: 'block',
    position: 'relative',
    border: '1px solid #cccccc',
    padding: '10px',
    minHeight: '140px',
    background: '#fff',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '85px 0 0 0'
    }
  },
  chip: {
    margin: '0 8px 8px 0',
    float: 'left',
    background: '#b2dffb',
    padding: '2px',
    height: '40px',
    fontWeight: 500,
    fontSize: '14px',
    borderRadius: '2px',
    color: '#333333',
    boxShadow: '5px 5px 10px rgba(242, 242, 242, 1)',
    maxWidth: '100%'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    background: '#f2f2f2',
    left: '1px',
    top: '38px',
    boxShadow: 'none',
    right: 0,
    fontSize: '14px',
    zIndex: 1,
    maxWidth: '285px'
  },
  suggestion: {
    display: 'block',
    '& span': {
      fontSize: '14px',
      fontWeight: 'bold!important',
      color: '#333'
    }
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    fontSize: '14px',
    maxHeight: '250px',
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
}))

const CustomAutosuggestComponent = ({
  inputRef,
  name,
  errors,
  disabled,
  value: componentValue,
  onBlur: componentBlur,
  onChange,
  debounce,
  suggestionGetter,
  maxLength,
  titleAdd,
  title,
  ...rest
}) => {
  const defaultDebounce = 500
  const addButton = useRef()
  const classes = useStyles()

  const [suggestions, setSuggestions] = useState([])
  const [textFieldInput, setTextFieldInput] = useState('')
  const [debounceTimeOut, setDebounceTimeOut] = useState(null)
  const [maxLengthReached, setMaxLengthReached] = useState(null)
  const value = componentValue || []
  const [isFocused, setIsFocused] = useState(false)

  const { translate } = useContext(GlobalizationContext)

  const handleSuggestionsFetchRequested = ({ value }) => {
    if (debounceTimeOut) clearTimeout(debounceTimeOut)

    const newDebounceTimeOut = setTimeout(function () {
      suggestionGetter({ value })
        .then(resp => {
          setSuggestions(resp || [])
        })
        .catch(() => {
          return
        })
    }, debounce || defaultDebounce)
    setDebounceTimeOut(newDebounceTimeOut)
  }

  const handleSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const handletextFieldInputChange = (event, { newValue }) => {
    setTextFieldInput(newValue)
  }

  const onValueChanged = useCallback(
    newValue => {
      onChange(newValue)
      componentBlur()
    },
    [componentBlur, onChange]
  )

  const AddChipFunction = useCallback(
    chip => {
      const newChip = typeof chip === 'string' ? { name: chip } : chip
      const newValue = value.concat([newChip])
      onValueChanged(newValue)
    },
    [onValueChanged, value]
  )

  const handleAddChip = useCallback(
    chip => {
      const trimmedChip = typeof chip === 'string' ? chip.trim() : chip

      if (trimmedChip === '') return

      let count = 0
      value.map(item => {
        if (trimmedChip === item.name) {
          count = 1
        }
      })
      if (count !== 1) {
        if (maxLength) {
          if (value.length < maxLength) {
            AddChipFunction(trimmedChip)
          } else {
            setMaxLengthReached(true)
          }
        } else {
          AddChipFunction(trimmedChip)
        }
      }
    },
    [AddChipFunction, maxLength, value]
  )

  const handleDeleteChip = useCallback(
    (chip, index) => {
      setMaxLengthReached(false)

      if (isFocused && inputRef.current.autowhatever.input.value === '') {
        return
      }

      const newList = [...value]
      newList.splice(index, 1)
      onValueChanged(newList)
    },
    [inputRef, isFocused, onValueChanged, value]
  )

  const handleBlur = useCallback(
    event => {
      if (event.relatedTarget === addButton.current) {
        handleAddChip(textFieldInput)
      }
      setIsFocused(false)
      setTextFieldInput('')
    },
    [handleAddChip, textFieldInput]
  )

  const wrapperClasses = clsx(
    classes.wrapper,
    errors && errors[name] ? 'hasError' : false
  )

  return (
    <>
      <h3 className={classes.titleAdd}>{titleAdd}</h3>
      <div name={`${name}_container`} className={wrapperClasses}>
        <h4 className={classes.title}>{title}</h4>
        <div className={classes.wrapButtons}>
          <Autosuggest
            theme={{
              container: classes.container,
              suggestionsContainerOpen: classes.suggestionsContainerOpen,
              suggestionsList: classes.suggestionsList,
              suggestion: classes.suggestion
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            renderSuggestionsContainer={renderSuggestionsContainer}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(e, { suggestion }) => {
              handleAddChip(suggestion)
              e.preventDefault()
            }}
            focusInputOnSuggestionClick
            ref={inputRef}
            renderInputComponent={renderInput}
            inputProps={{
              name,
              disabled,
              classes,
              chips: value,
              translate,
              onChange: handletextFieldInputChange,
              value: textFieldInput,
              onAdd: chip => handleAddChip(chip),
              onDelete: (chip, index) => handleDeleteChip(chip, index),
              onBlur: handleBlur,
              onFocus: () => setIsFocused(true),
              ...rest
            }}
          />
          {!disabled ? (
            <button type="button" className={classes.addButton} ref={addButton}>
              <i className="fas fa-plus" />
            </button>
          ) : null}
        </div>
      </div>
      {errors && errors[name] && (
        <div className={name + '-error-container'}>
          <FormHelperText error>{errors[name].message}</FormHelperText>
        </div>
      )}
      {maxLengthReached && (
        <FormHelperText error>
          {translate('COMMONS_CHIP_INPUT_MAX_LENGTH_REACHED')}
        </FormHelperText>
      )}
    </>
  )
}

const CustomAutosuggest = ({ name, control, ...props }) => {
  const inputRef = useRef()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      onFocus={() => {
        return inputRef.current?.autowhatever?.input?.focus()
      }}
      render={({ ref, ...controlProps }) => (
        <CustomAutosuggestComponent
          inputRef={newRef => {
            ref.current = newRef
            inputRef.current = newRef
          }}
          name={name}
          {...props}
          {...controlProps}
        />
      )}
    />
  )
}

export default CustomAutosuggest
