import React, { useContext } from 'react'

import Typography from '@material-ui/core/Typography'

import AddNewRepresentant from 'ui/atoms/AddNewRepresentant'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const NewResponsible = ({ representants, setRepresentants }) => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <>
      <Typography variant="subtitle1" style={{ margin: '32px 0' }}>
        {translate('ADD_NEW_RESPONSIBLE')}
      </Typography>

      <AddNewRepresentant
        disabled={false}
        representants={representants}
        setRepresentants={setRepresentants}
      />
    </>
  )
}

export default NewResponsible
