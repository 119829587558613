import { Auth } from 'aws-amplify'
import React, { useState, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import * as Yup from 'yup'

import { Grid, Button } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { green, red } from '@material-ui/core/colors'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { UsersService } from 'api'
import { AuthContext } from 'context/AuthContext'
import { sm } from 'helpers/constants'
import { history, generatePrivatePath } from 'helpers/history'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const Submenu = styled(Paper)`
  border-radius: 2px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.12);
  min-width: 200px;
  z-index: 2;
`
const WrapSubmenu = styled(Popper)`
  z-index: 4;
  @media (max-width: ${sm}) {
    left: 40px !important;
    right: 0 !important;
    max-width: 300px;
  }
`
const BaseButton = styled(Button)`
  border-radius: 0px;
  float: right;
  text-transform: none;
  background-color: #009b7f;
  color: #ffffff;
  padding: 9px;
  font-weight: normal;
  font-size: 14px;
  &:hover {
    background-color: #006855;
    color: #ffffff;
  }
`
const ForgotPassword = styled(Grid)`
  font-weight: 500;
  color: #006855;
  font-size: 13px;
  text-align: right;
`
const SignUp = styled(Grid)`
  font-size: 13px;
  text-align: right;
`
const SignUpLink = styled(Link)`
  font-weight: 500;
  font-size: 13px;
  color: #006855;
`
const Error = styled(Grid)`
  color: #f44336;
  text-align: center;
`
const DefaultContainer = styled.div`
  padding: 40px;
  width: 250px;
  @media (max-width: ${sm}) {
    padding: 20px;
    max-width: 100%;
    boxsizing: border-box;
  }
`
const useStyles = makeStyles(theme => ({
  passwordValidationInvalid: {
    color: red[500],
    padding: '0'
  },
  PasswordValidationValid: {
    color: green[500],
    padding: '0'
  }
}))

const formSchema = Yup.object().shape({
  username: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  password: Yup.string().required('VALIDATION:REQUIRED_ERROR')
})

function SubMenuButtonLogin(props) {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  const { setChallengedUser } = useContext(AuthContext)
  const [loginError, setLoginError] = useState(false)

  const { anchorEl } = props

  useEffect(() => {
    setLoginError(false)
  }, [props.loginOpen])

  const resolver = useYupValidationResolver(formSchema, { translate })
  const { control, handleSubmit, errors } = useForm({
    resolver
  })

  const handleClose = event => {
    props.handleLoginClose(event)
  }

  const onSubmit = formData => {
    const userName = formData.username.includes('@')
      ? formData.username
      : formData.username.replace(/\D/g, '')
    signIn(userName, formData.password)
  }

  const signIn = async (username, password) => {
    try {
      setLoginError(false)
      const user = await Auth.signIn(username, password)

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setChallengedUser(user)
        history.push('/change-password')
        return
      }

      await UsersService.getMe()
      history.push(generatePrivatePath(''))
    } catch (error) {
      setLoginError(true)
    }
  }

  return (
    <WrapSubmenu
      open={props.loginOpen}
      anchorEl={anchorEl}
      role={undefined}
      transition
      placement="top-end"
      className="submenu"
      disablePortal={true}
      modifiers={{
        flip: {
          enabled: true
        },
        preventOverflow: {
          enabled: false,
          boundariesElement: 'scrollParent'
        },
        arrow: {
          enabled: false
        }
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'left top' : 'right top'
          }}
        >
          <Submenu>
            <ClickAwayListener onClickAway={handleClose}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <DefaultContainer>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        {translate('LOGIN_TITLE')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <BaseTextField
                        required={true}
                        label={translate('LOGIN_USER_LABEL')}
                        placeholder={translate('LOGIN_USER_PLACEHOLDER')}
                        errors={errors}
                        control={control}
                        name="username"
                      ></BaseTextField>
                    </Grid>
                    <Grid item xs={12}>
                      <BaseTextField
                        required={true}
                        label={translate('LOGIN_PASSWORD_LABEL')}
                        placeholder={translate('LOGIN_PASSWORD_PLACEHOLDER')}
                        errors={errors}
                        control={control}
                        name="password"
                        type="password"
                      ></BaseTextField>
                    </Grid>
                    {loginError ? (
                      <Error item xs={12}>
                        {translate('LOGIN_INVALID_CREDENTIALS')}
                      </Error>
                    ) : null}
                    <Grid item xs={12}>
                      <BaseButton
                        fullWidth
                        type={'submit'}
                        variant="contained"
                        id="LOGIN_SIGN_IN"
                      >
                        {translate('LOGIN_SIGN_IN')}
                      </BaseButton>
                    </Grid>
                    <ForgotPassword item xs={12}>
                      <SignUpLink
                        to="/request-new-password"
                        id="LOGIN_FORGOT_PASSWORD"
                      >
                        {translate('LOGIN_FORGOT_PASSWORD')}
                      </SignUpLink>
                    </ForgotPassword>
                    <SignUp item xs={12} className={classes.signUp}>
                      {translate('LOGIN_NO_ACCOUNT')}{' '}
                      <SignUpLink to="/register/user" id="LOGIN_SIGN_UP">
                        {translate('LOGIN_SIGN_UP')}
                      </SignUpLink>
                    </SignUp>
                  </Grid>
                </DefaultContainer>
              </form>
            </ClickAwayListener>
          </Submenu>
        </Grow>
      )}
    </WrapSubmenu>
  )
}

export default SubMenuButtonLogin
