const array = [
  { id: 'AC', name: 'AC', sigla: 'AC', region: 3 },
  { id: 'AL', name: 'AL', sigla: 'AL', region: 2 },
  { id: 'AP', name: 'AP', sigla: 'AP', region: 3 },
  { id: 'AM', name: 'AM', sigla: 'AM', region: 3 },
  { id: 'BA', name: 'BA', sigla: 'BA', region: 2 },
  { id: 'CE', name: 'CE', sigla: 'CE', region: 2 },
  { id: 'DF', name: 'DF', sigla: 'DF', region: 1 },
  { id: 'ES', name: 'ES', sigla: 'ES', region: 4 },
  { id: 'GO', name: 'GO', sigla: 'GO', region: 1 },
  { id: 'MA', name: 'MA', sigla: 'MA', region: 2 },
  { id: 'MT', name: 'MT', sigla: 'MT', region: 1 },
  { id: 'MS', name: 'MS', sigla: 'MS', region: 1 },
  { id: 'MG', name: 'MG', sigla: 'MG', region: 4 },
  { id: 'PA', name: 'PA', sigla: 'PA', region: 3 },
  { id: 'PB', name: 'PB', sigla: 'PB', region: 2 },
  { id: 'PR', name: 'PR', sigla: 'PR', region: 5 },
  { id: 'PE', name: 'PE', sigla: 'PE', region: 2 },
  { id: 'PI', name: 'PI', sigla: 'PI', region: 2 },
  { id: 'RJ', name: 'RJ', sigla: 'RJ', region: 4 },
  { id: 'RN', name: 'RN', sigla: 'RN', region: 2 },
  { id: 'RS', name: 'RS', sigla: 'RS', region: 5 },
  { id: 'RO', name: 'RO', sigla: 'RO', region: 3 },
  { id: 'RR', name: 'RR', sigla: 'RR', region: 3 },
  { id: 'SC', name: 'SC', sigla: 'SC', region: 5 },
  { id: 'SP', name: 'SP', sigla: 'SP', region: 4 },
  { id: 'SE', name: 'SE', sigla: 'SE', region: 2 },
  { id: 'TO', name: 'TO', sigla: 'TO', region: 3 }
]
export default array
