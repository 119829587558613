import React, { useContext } from 'react'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { TableHead, TableRow, TableCell } from 'ui/atoms/Table'

const TableHeader = () => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: '10px' }}></TableCell>
        <TableCell>{translate('COLUMNS.SOCIAL_REASON')}</TableCell>
        <TableCell>{translate('COLUMNS.CNPJ')}</TableCell>
        <TableCell>{translate('COLUMNS.ACTIVE_RESPONSIBLE')}</TableCell>
        <TableCell>{translate('COLUMNS.SITUATION')}</TableCell>
        <TableCell style={{ width: '10px' }}>
          {translate('COMMONS:ACTIONS')}
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
