import * as Yup from 'yup'

Yup.addMethod(Yup.boolean, 'fromString', function () {
  return this.transform(val => {
    if (typeof val === 'boolean') return val

    if (val === 'true') {
      return true
    } else if (val === 'false') {
      return false
    } else {
      return null
    }
  }).nullable()
})
