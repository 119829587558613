// @ts-nocheck
import React, { useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { fileUploadConfig } from 'helpers/constants'

import AddedDocuments from 'ui/atoms/AddedDocuments'
import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import DocumentUploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'

import {
  AnalysisContainer,
  AnalysisInformationContainer,
  AnalysisText
} from '../../styles'

const Analysis = ({ documents, formMethods, onRemoveFile, onSelectFiles }) => {
  const { translate } = useContext(GlobalizationContext)

  const { control, errors } = formMethods

  const watchedFields = useWatch({
    control,
    name: ['institutionShouldBePartOfPlatform', 'requesterIsMaxResponsible']
  })

  const notApproved =
    watchedFields.institutionShouldBePartOfPlatform === 'NO' ||
    watchedFields.requesterIsMaxResponsible === 'NO'

  return (
    <AnalysisContainer>
      <AnalysisInformationContainer>
        <AnalysisText>
          {translate('ANALYSIS.SHOULD_INSTITUTION_BE_PART')}
        </AnalysisText>
        <RadioGroup
          name="institutionShouldBePartOfPlatform"
          inline
          row
          control={control}
          error={errors}
          options={[
            { id: 'YES', name: translate('COMMONS:YES') },
            { id: 'NO', name: translate('COMMONS:NO') }
          ]}
        />
      </AnalysisInformationContainer>
      <AnalysisInformationContainer style={{ marginBottom: '20px' }}>
        <AnalysisText>
          {translate('ANALYSIS.INSTITUTION_BOND_IS_CONFIRMED')}
        </AnalysisText>
        <RadioGroup
          name="requesterIsMaxResponsible"
          inline
          row
          control={control}
          error={errors}
          options={[
            { id: 'YES', name: translate('COMMONS:YES') },
            { id: 'NO', name: translate('COMMONS:NO') }
          ]}
        />
      </AnalysisInformationContainer>
      {notApproved && (
        <>
          <BaseTextareaField
            required={true}
            errors={errors}
            control={control}
            maxLength={500}
            rows={4}
            name="refusalDetail"
            label={translate('ANALYSIS.REFUSAL_MOTIVE')}
            placeholder={translate('ANALYSIS.REFUSAL_MOTIVE_PLACEHOLDER')}
          />
          <DocumentUploader
            label={'ANALYSIS.REFUSAL_FILE_UPLOAD'}
            wrapperDescription={'ANALYSIS.DOCUMENT_UPLOAD'}
            onChange={onSelectFiles}
            currentListLength={documents.length}
            filesConfig={fileUploadConfig.institution}
          />
          <AddedDocuments docs={documents} removeFile={onRemoveFile} />
        </>
      )}
    </AnalysisContainer>
  )
}

export default Analysis
