import React, { useContext } from 'react'

import NotificationService from 'api/NotificationService'
import { history } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'

import { GenericNotification } from './GenericNotification'
import { LinkStyledAccept } from './style'

const ConfirmNotificationCmp = ({
  titlekey,
  messageKey,
  confirmKey,
  notification,
  itemClicked,
  link,
  ...rest
}) => {
  const { translate } = useContext(GlobalizationContext)

  const onAcceptClick = async () => {
    await NotificationService.confirmNotification(notification.id)
    if (link) {
      history.push(link)
    }
    itemClicked()
  }

  return (
    <GenericNotification
      titleKey={titlekey}
      messageKey={messageKey}
      notification={notification}
      {...rest}
    >
      <LinkStyledAccept onClick={onAcceptClick}>
        {translate(confirmKey || 'OK')}
      </LinkStyledAccept>
    </GenericNotification>
  )
}

const ConfirmNotification = wrapper(ConfirmNotificationCmp, {
  namespace: 'NOTIFICATIONS'
})

export { ConfirmNotification }
