import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useCallback, useContext } from 'react'
import { Controller, useWatch, useFieldArray } from 'react-hook-form'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone'

import BaseMultipleSelect from 'ui/atoms/BaseMultipleSelect'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const StyledTableRow = withStyles(theme => ({
  root: {
    height: '40px',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

const InputTableCell = styled(TableCell)`
  padding: 0;
  position: relative;

  input {
    padding-left: 15px;
    height: 44px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  svg {
    position: absolute;
    right: 5px;
    top: calc(50% - 10px);
    color: red;
  }
`

const FinancingInput = props => {
  const { translate } = useContext(GlobalizationContext)
  const { name, error, formMethods, ...rest } = props
  return (
    <>
      <Controller
        as={<input {...rest} />}
        name={name}
        placeholder={translate(
          'REGISTER_EQUIPMENT_FINANCING_ORIGIN_PLACE_HOLDER'
        )}
        control={formMethods.control}
        defaultValue=""
      />

      {error && <ErrorTwoToneIcon fontSize="small" />}
    </>
  )
}

const FinancingOrigin = ({ options, formMethods }) => {
  const { translate } = useContext(GlobalizationContext)
  const { control, setValue, errors } = formMethods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'financingListCustom',
    defaultValue: []
  })

  const financingList = useWatch({
    control,
    name: 'financingList',
    defaultValue: []
  })

  useEffect(() => {
    const prevFinancingType = (fields || []).map(item => item.id)
    const currCustomItens = financingList
      .filter(item => item.custom)
      .map(item => item.id)

    if (isEqual(currCustomItens, prevFinancingType)) return

    const newItems = differenceWith(
      currCustomItens,
      prevFinancingType,
      (a, b) => parseInt(a) === parseInt(b)
    )
    const removedItems = differenceWith(
      prevFinancingType,
      currCustomItens,
      (a, b) => parseInt(a) === parseInt(b)
    )

    if (!newItems.length && !removedItems.length) return

    if (newItems.length) {
      append(
        newItems.map(newItem => {
          const financingType = financingList.find(item => item.id == newItem)
          return {
            id: newItem,
            name: financingType.name,
            customDescription: financingType.customDescription || ''
          }
        }),
        false
      )
    }

    if (removedItems.length) {
      const removedIndexes = removedItems.map(oldItem => {
        return fields.findIndex(item => item.id == oldItem)
      })
      remove(removedIndexes)
    }
  }, [financingList, remove, append, fields])

  const removeFinancingType = useCallback(
    itemToRemove => {
      remove(fields.findIndex(item => item.id === itemToRemove.id))
      setValue(
        'financingList',
        financingList.filter(item => item.id !== itemToRemove.id)
      )
    },
    [fields, financingList, remove, setValue]
  )

  return (
    <>
      <Grid item xs={12}>
        <BaseMultipleSelect
          required={true}
          label={translate('REGISTER_EQUIPMENT_FINANCING')}
          placeholder={translate('COMMONS_SELECT')}
          errors={errors}
          options={options}
          control={control}
          name="financingList"
        />
      </Grid>
      {fields.length ? (
        <div style={{ paddingTop: '2rem' }}>
          <TableContainer component={Paper}>
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: '2%',
                      paddingLeft: '15px',
                      paddingRight: '15px'
                    }}
                    align="center"
                  >
                    #
                  </TableCell>
                  <TableCell style={{ width: '48%' }} align="left">
                    {translate('REGISTER_EQUIPMENT_FINANCING_ORIGIN_TYPE')}
                  </TableCell>
                  <TableCell style={{ width: '48%' }} align="left" colSpan={2}>
                    {translate('REGISTER_EQUIPMENT_FINANCING_ORIGIN_NAME')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field, index) => (
                  <StyledTableRow key={field.id}>
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{field.name}</TableCell>
                    <InputTableCell align="left">
                      <input
                        type="hidden"
                        ref={formMethods.register()}
                        name={`financingListCustom[${index}].id`}
                        defaultValue={field.id}
                      />
                      <FinancingInput
                        formMethods={formMethods}
                        name={`financingListCustom[${index}].customDescription`}
                        error={
                          errors.financingListCustom &&
                          errors.financingListCustom[index]?.customDescription
                        }
                      />
                    </InputTableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => removeFinancingType(field)}
                        aria-label="delete"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}
    </>
  )
}

export default FinancingOrigin
