import React, { useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { Checkbox, Grid } from '@material-ui/core'

import AddedDocuments from 'ui/atoms/AddedDocuments'
import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import BaseTextField from 'ui/atoms/BaseTextField'
import DocumentUploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'

const Sample = ({
  readOnly,
  params,
  formMethods,
  materialDataSafetyConfig
}) => {
  const { translate } = useContext(GlobalizationContext)
  const { control, errors } = formMethods

  const watchedFields = useWatch({
    control,
    name: [
      'needsToBringSample',
      'needsSamplePreparation',
      'hasSecurityHazard',
      'securityHazardList'
    ]
  })

  return (
    <>
      <Grid item xs={12}>
        <RadioGroup
          disabled={readOnly}
          required
          name="needsToBringSample"
          label={translate('REGISTER.SAMPLE.NEEDS_TO_BRING_SAMPLE')}
          control={control}
          error={errors}
          inline
          row
          options={[
            { id: 'YES', name: translate('COMMONS:YES') },
            { id: 'NO', name: translate('COMMONS:NO') },
            {
              id: 'DOES_NOT_APPLY',
              name: translate('COMMONS:DOES_NOT_APPLY')
            }
          ]}
        />
      </Grid>

      {watchedFields.needsToBringSample === 'YES' && (
        <>
          <Grid item xs={12}>
            <BaseTextareaField
              disabled={readOnly}
              name="justifyNeedsToBringSample"
              label={translate('REGISTER.SAMPLE.JUSTIFY_NEEDS_TO_BRING_SAMPLE')}
              placeholder={translate(
                'REGISTER.SAMPLE.JUSTIFY_NEEDS_TO_BRING_SAMPLE_PLACEHOLDER'
              )}
              control={control}
              errors={errors}
              maxLength={500}
              rows={5}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <BaseTextField
              disabled={readOnly}
              name="sampleAmount"
              label={translate('REGISTER.SAMPLE.SAMPLE_AMOUNT_LABEL')}
              placeholder={translate(
                'REGISTER.SAMPLE.SAMPLE_AMOUNT_PLACEHOLDER'
              )}
              control={control}
              errors={errors}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              disabled={readOnly}
              required
              name="needsSamplePreparation"
              label={translate('REGISTER.SAMPLE.NEEDS_SAMPLE_PREPARATION')}
              control={control}
              error={errors}
              inline
              row
              options={[
                { id: 'YES', name: translate('COMMONS:YES') },
                { id: 'NO', name: translate('COMMONS:NO') },
                {
                  id: 'DOES_NOT_APPLY',
                  name: translate('COMMONS:DOES_NOT_APPLY')
                }
              ]}
            />
          </Grid>
          {watchedFields.needsSamplePreparation === 'YES' && (
            <Grid item xs={12}>
              <BaseTextareaField
                disabled={readOnly}
                name="justifyNeedsSamplePreparation"
                label={translate(
                  'REGISTER.SAMPLE.JUSTIFY_NEEDS_SAMPLE_PREPARATION'
                )}
                placeholder={translate(
                  'REGISTER.SAMPLE.JUSTIFY_NEEDS_SAMPLE_PREPARATION_PLACEHOLDER'
                )}
                control={control}
                errors={errors}
                maxLength={500}
                rows={5}
                required
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <RadioGroup
              disabled={readOnly}
              required
              name="hasSecurityHazard"
              label={translate('REGISTER.SAMPLE.HAS_SECURITY_HAZARD')}
              control={control}
              error={errors}
              inline
              row
              options={[
                { id: 'YES', name: translate('COMMONS:YES') },
                { id: 'NO', name: translate('COMMONS:NO') },
                {
                  id: 'DOES_NOT_APPLY',
                  name: translate('COMMONS:DOES_NOT_APPLY')
                }
              ]}
            />
          </Grid>

          {watchedFields.hasSecurityHazard === 'YES' && (
            <>
              <Grid item xs={12}>
                <RadioGroup
                  disabled={readOnly}
                  required
                  name="securityHazardList"
                  controlType="checkbox"
                  control={control}
                  error={errors}
                  inline
                  row
                  options={params.securityHazardList}
                />
              </Grid>

              <Grid item xs={12}>
                <BaseTextareaField
                  disabled={readOnly}
                  name="justifySecurityHazard"
                  label={translate('REGISTER.SAMPLE.JUSTIFY_SECURITY_HAZARD')}
                  placeholder={translate(
                    'REGISTER.SAMPLE.JUSTIFY_SECURITY_HAZARD_PLACEHOLDER'
                  )}
                  control={control}
                  errors={errors}
                  maxLength={500}
                  rows={5}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                {!readOnly ? (
                  <DocumentUploader
                    label="REGISTER.SAMPLE.MATERIAL_DATA_SAFETY_LABEL"
                    wrapperDescription="REGISTER.SAMPLE.MATERIAL_DATA_SAFETY_BUTTON"
                    filesConfig={materialDataSafetyConfig.fileConfig}
                    onChange={materialDataSafetyConfig.onFileSelected}
                    currentListLength={
                      materialDataSafetyConfig.documents.length
                    }
                    marginBottom="0px"
                  />
                ) : null}
                <AddedDocuments
                  docs={materialDataSafetyConfig.documents}
                  viewFile={materialDataSafetyConfig.previewFile}
                  removeFile={
                    !readOnly && materialDataSafetyConfig.onRemoveFile
                  }
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  )
}

export default Sample
