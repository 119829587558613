import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { isDevelopment } from 'helpers/environment'

import translateEN from './en'
import translatePT from './pt'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  ptBr: translatePT,
  en: translateEN
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'ptBr',
    fallbackLng: 'ptBr',
    fallbackNS: ['COMMONS', 'VALIDATION', 'ERRORS', 'OLD_DEFAULT'],
    debug: isDevelopment(),
    resources,
    saveMissing: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
      // defaultTransParent: 'div', // a valid react element - required before react 16
      transEmptyNodeValue: '', // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'ul', 'li']
    }
  })

export default i18n
