import axios from "./axiosMap";

export const MapService = {
  geoCoding(endpoint, search_text, params) {
    return axios.get(
      "/geocoding/v5/" + endpoint + "/" + search_text + ".json",
      {
        params: {
          access_token: process.env.REACT_APP_MAPBOX_KEY,
          ...params,
        },
      }
    );
  },
};
export default MapService;
