import React, { useContext } from 'react'

import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import capitalize from 'lodash/capitalize'

import { TableChip } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { InfoMessage } from 'ui/atoms/Messages'
import useStyles from 'ui/styles'

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  UndoIcon
} from './style'

const UserPermissions = ({ permissionList = [], onRowDeletedStatusChange }) => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const getPermission = item => {
    const permission = item.split('_').slice(-1)
    return capitalize(permission)
  }

  const getPermissionType = item => {
    const [, type] = item.split('_')
    return type
  }

  const setRowDeleted = (row, status) => {
    onRowDeletedStatusChange(row, status)
  }

  return (
    <>
      <h3 className={classes.h3}>{translate('PERMISSION_LIST')}</h3>

      {permissionList.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate('TYPE')}</TableCell>
                <TableCell>{translate('PERMISSION')}</TableCell>
                <TableCell>{translate('INSTITUTION')}</TableCell>
                <TableCell>{translate('LABORATORY')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissionList.map(permission => (
                <TableRow
                  key={`${permission.nameInst}_${permission.nameLab}_${permission.permission}_${permission.situation}`}
                >
                  <TableCell>
                    {translate(getPermissionType(permission.permission))}
                  </TableCell>
                  <TableCell>
                    {translate(getPermission(permission.permission))}
                  </TableCell>
                  <TableCell>
                    {permission.nameInst ? translate(permission.nameInst) : '-'}
                  </TableCell>
                  <TableCell>
                    {permission.nameLab ? translate(permission.nameLab) : '-'}
                  </TableCell>
                  <TableCell style={{ width: '10px', textAlign: 'end' }}>
                    {permission.responsibleOnly ? (
                      <TableChip
                        size="small"
                        label={translate('ONLY_RESPONSIBLE')}
                      />
                    ) : permission.responsibleMain ? (
                      <TableChip
                        size="small"
                        label={translate('MAIN_RESPONSIBLE')}
                      />
                    ) : !permission.deleted ? (
                      <IconButton
                        onClick={() => setRowDeleted(permission, true)}
                        aria-label="delete"
                        title="Excluir"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => setRowDeleted(permission, false)}
                        aria-label="undo"
                        title="Desfazer exclusão"
                      >
                        <UndoIcon className="fas fa-undo"></UndoIcon>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {!permissionList.length ? (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    {translate('NO_PERMISSIONS')}
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <InfoMessage>{translate('NO_PERMISSIONS')}</InfoMessage>
      )}
    </>
  )
}

export default UserPermissions
