import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import MapGL, { Marker, NavigationControl } from 'react-map-gl'
import { useParams } from 'react-router'
import * as Yup from 'yup'

import { IconButton, Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import LaboratoryService from 'api/LaboratoryService'
import { fileUploadConfig } from 'helpers/constants'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { history, generatePrivatePath } from 'helpers/history'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import {
  deleteFiles,
  uploadImages,
  updateImages,
  uploadFilesInSequence
} from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import TagsList from 'ui/atoms/TagsList'
import { Title, Subtitle } from 'ui/atoms/Title'
import CarrousselUploader from 'ui/molecules/CarrousselUploader'
import useStyles from 'ui/styles'

import List from 'views/BackOffice/Subviews/EquipmentList/Fragments/List'
import { PCustom, Text } from 'views/LaboratoryDetail'
import { notificationUseCases } from 'views/Notification/providers'

import SharingConfig from './Fragments/SharingConfig'
import SharingConfigReadOnly from './Fragments/SharingConfigReadOnly'
import { ButtonAddEquipment, WrapInput, BaseTextareaField } from './styles'

const FormSchema = Yup.object().shape({
  website: Yup.string(),
  email: Yup.string().email('VALIDATION:EMAIL_ERROR'),
  about: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  hasSharing: Yup.boolean(),
  sharingType: Yup.string().when('hasSharing', (hasSharing, schema) => {
    return hasSharing
      ? schema.required('VALIDATION:REQUIRED_ERROR')
      : schema.notRequired()
  }),
  hasSharingCosts: Yup.boolean().notRequired(),
  ownShareLink: Yup.string().when(
    ['hasSharing', 'sharingType'],
    (hasSharing, sharingType, schema) => {
      return hasSharing && sharingType === 'OWN_SHARING'
        ? schema.required('VALIDATION:REQUIRED_ERROR')
        : schema.notRequired()
    }
  )
})

const rowsPerPageList = [10, 20, 50, 100]

function LaboratoryEdit({ laboratoryData }) {
  const { laboratoryId } = useParams()

  const [errorMsgCheckTermDecla, setErrorMsgCheckTermDecla] = React.useState(false);
  const [errorMsgTextAreaTerm, setErrorMsgTextAreaTerm] = React.useState(false);

  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const resolver = useYupValidationResolver(FormSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {
      hasSharing: true,
      sharingType: 'PLATFORM_SHARING'
    }
  })

  const {
    control,
    getValues,
    handleSubmit,
    clearErrors,
    setError,
    errors,
    reset
  } = formMethods

  const { laboratory, complements } = laboratoryData
  const { isEditor, responsible } = complements

  const [laboratoryImageList, setLaboratoryImageList] = useState([])
  const [editedImageList, setEditedImageList] = useState([])
  const [deletedLaboratoryImageList, setDeletedLaboratoryImageList] = useState(
    []
  )

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [count, setCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  const [documents, setDocuments] = useState([])
  const [deletedDocuments, setDeletedDocuments] = useState([])
  const [equipmentList, setEquipmentList] = useState([])
  const [showMap, setShowMap] = React.useState(false)
  const [viewport, setViewPort] = useState({})
  const [marker, setMarker] = useState({})

  const handleAdvancedEdit = () => {
    history.push(
      generatePrivatePath(`/laboratory/${laboratoryId}/action/advanced-edit`)
    )
  }

  useEffect(() => {
    async function initLaboratory() {
      try {
        reset({
          about: laboratory.about,
          email: laboratory.contactEmail,
          website: laboratory.website,
          hasSharing: laboratory.hasSharing || false,
          sharingType: laboratory.sharingType || 'PLATFORM_SHARING',
          hasSharingCosts: laboratory.hasSharingCosts || false,
          ownShareLink: laboratory.ownShareLink || '',
          hasSharingUseStatement: laboratory.hasSharingUseStatement || false,
          textAreaUseStatement: laboratory.textAreaUseStatement,
        })

        setLaboratoryImageList(laboratory.imageList)
        setDocuments(laboratory.sharingTermsDocumentList || [])

        setViewPort({
          width: '100%',
          height: '400px',
          latitude: parseFloat(laboratory.latitude),
          longitude: parseFloat(laboratory.longitude),
          zoom: 14
        })

        setMarker({
          latitude: parseFloat(laboratory.latitude),
          longitude: parseFloat(laboratory.longitude)
        })
      } catch (error) {
        if (error.response?.status === 403) {
          notificationUseCases.newError(
            'Voce não tem permissão para visualizar este laboratório'
          )
          history.push(generatePrivatePath('/laboratory'))
        }
      }
    }

    initLaboratory()
  }, [
    laboratory.about,
    laboratory.contactEmail,
    laboratory.hasSharing,
    laboratory.hasSharingCosts,
    laboratory.imageList,
    laboratory.latitude,
    laboratory.longitude,
    laboratory.ownShareLink,
    laboratory.sharingTermsDocumentList,
    laboratory.sharingType,
    laboratory.website,
    laboratory.textAreaUseStatement,
    laboratory.hasSharingUseStatement,
    reset
  ])

  const getEquipmentList = useCallback(() => {
    LaboratoryService.getEquipmentListByLaboratory(
      laboratoryId,
      pageSize,
      pageNumber
    ).then(resp => {
      setEquipmentList(resp.data.content || [])
      setCount(resp.data.totalElements)
      setPageSize(resp.data.size)
    })
  }, [laboratoryId, pageNumber, pageSize])

  useEffect(() => {
    getEquipmentList()
  }, [pageSize, pageNumber, getEquipmentList])

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  const handleRowChange = () => {
    getEquipmentList()
  }

  const handleImageListChanged = useCallback(
    async (newImageList, deletedImageList) => {
      setDeletedLaboratoryImageList(
        deletedLaboratoryImageList.concat(deletedImageList)
      )
      setLaboratoryImageList(newImageList)
    },
    [deletedLaboratoryImageList]
  )

  const LaboratoryImageUploader = useCallback(() => {
    return (
      <CarrousselUploader
        slidesToShow={1}
        modalTitle={'FILE_UPLOAD_NEW_FOTO'}
        imageList={laboratoryImageList}
        setEditedImageList={setEditedImageList}
        onImageListChange={handleImageListChanged}
        filesConfig={fileUploadConfig.laboratoryImages}
        height={530}
        readOnly={!isEditor}
      />
    )
  }, [laboratoryImageList, handleImageListChanged, isEditor])

  const deleteImage = async imageId => {
    return await LaboratoryService.removeImage(imageId)
  }

  // const handleTermSelected = fileList => {
  //   clearErrors('termsDocument')
  //   if (!fileList || !fileList.length) return
  //   setDocuments(documents => documents.concat(fileList))
  // }

  // const handleRemoveTerm = file => {
  //   clearErrors('termsDocument')
  //   if (file.id) {
  //     setDeletedDocuments(deletedDocuments.concat(file.id))
  //   }

  //   const remaningFiles = documents.filter(item => item !== file)
  //   setDocuments(remaningFiles)
  // }

  const saveFormData = async (formData, action) => {
    const postData = {
      ...formData,
      contactEmail: formData.email
    }

    await deleteFiles(deleteImage, deletedLaboratoryImageList)

    const imagesToUpdate = editedImageList.filter(
      file => file.image.id && file.cropImage instanceof File
    )
    const imagesToUpload = editedImageList.filter(
      file => file.image instanceof File
    )

    await uploadImages(
      LaboratoryService.uploadLaboratoryImage,
      laboratoryId,
      imagesToUpload
    )

    await updateImages(LaboratoryService.updateLaboratoryImage, imagesToUpdate)

    let service
    if (action == 'draft' || action == 'draft_redirect') {

      if (formData.hasSharing && formData.sharingType === 'PLATFORM_SHARING' && !formData.hasSharingUseStatement) {
          return
        }   
      if (formData.hasSharing && formData.sharingType === 'PLATFORM_SHARING' && !formData.textAreaUseStatement){        
          return
      }       
        
      service = LaboratoryService.postDraftLaboratory
    } else if (action == 'publish') {
      service = LaboratoryService.postPublishLaboratory
    }

    try {
          

      await service(laboratoryId, postData)
     /* if (postData.hasSharing && postData.sharingType === 'PLATFORM_SHARING') {
    
        await uploadFilesInSequence(
          LaboratoryService.uploadTermsOfUse,
          laboratoryId,
          documents || []
        )
      }*/

      notificationUseCases.newSuccess(translate('REGISTER_LABORATORY_UPDATED'))
      let redirectUrl = generatePrivatePath(`/laboratory`)
      if (action == 'draft_redirect') {
        redirectUrl = generatePrivatePath(
          `/laboratory/${laboratoryId}/equipment`
        )
      }
      history.push(redirectUrl)
    } catch (error) {
      switch (error?.response?.data?.message) {
        case 'input.invalid':
          notificationUseCases.newError('SPECIAL_CHARS')
          break
        case 'equipment.not.found':
          notificationUseCases.newError(
            translate('EDIT_LABORATORY_REQUIRED_EQUIPMENT_ERROR_MESSAGE')
          )
          break
        default:
          notificationUseCases.newError()
          break
      }
    }
  }

  const onSubmit = async action => {

   
    const formData = getValues()

    if (action === 'publish' && !laboratoryImageList.length) {
      notificationUseCases.newError(
        translate('EDIT_LABORATORY_REQUIRED_IMAGE_ERROR_MESSAGE')
      )
      return
    }

    if (
      action === 'publish' &&
      formData.hasSharing &&
      formData.sharingType === 'PLATFORM_SHARING'
    ) {
      if(!formData.hasSharingUseStatement){
        setErrorMsgCheckTermDecla(true)
        notificationUseCases.newError(
          "Você deve aceitar o termo de uso"
        )
        return
      }   
      if(!formData.textAreaUseStatement){
        setErrorMsgTextAreaTerm(true)
        notificationUseCases.newError(
          "Você deve inserir o texto do termo de uso"
        )
        return
      }     

      
    }

    if (action === 'publish') {

      handleSubmit(formData => saveFormData(formData, action))()
    } else {
      if(formData.hasSharing && formData.sharingType === 'PLATFORM_SHARING'){
    
       if(!formData.hasSharingUseStatement){
          setErrorMsgCheckTermDecla(true)
          notificationUseCases.newError(
            "Você deve aceitar o termo de uso"
          )
        }   
        if(!formData.textAreaUseStatement){
          setErrorMsgTextAreaTerm(true)
          notificationUseCases.newError(
            "Você deve inserir o texto do termo de uso"
          )
        }
        else{
          saveFormData(formData, action)
        }

      }else{
        saveFormData(formData, action)
      }     

    }
  }

  const handleBack = () => {
    window.history.back()
  }

  return (
    <>
      <form onSubmit={handleSubmit(() => {})} {...disableSubmitOnEnter}>
        <Grid
          container
          spacing={3}
          style={{ marginBottom: '20px', marginTop: '20px' }}
        >
          <Title
            item
            xs={12}
            action={
              laboratory.isDraft ? (
                <Typography variant="body2" color="primary" noWrap>
                  {translate('REGISTER_LABORATORY_SAVED_AS_DRAFT')}
                </Typography>
              ) : null
            }
            onBack={handleBack}
          >
            {translate(
              isEditor
                ? 'REGISTER_LABORATORY_EDIT_LAB_PAGE'
                : 'REGISTER_LABORATORY_VIEW_LAB'
            )}
          </Title>
          <Grid item xs={12}>
            <LaboratoryImageUploader />
          </Grid>
          <Title
            item
            xs={12}
            style={{ paddingBottom: 0 }}
            action={
              <Typography variant="body2" noWrap>
                {`${translate('COMMONS:CREATION_DATE')}: ${
                  laboratoryData.complements.createdDate || '-'
                }`}
              </Typography>
            }
          >
            {laboratory.initials} - {laboratory.name}
          </Title>
          <Grid item xs={12} style={{ paddingTop: '0' }}>
            <Typography variant="body1">
              {`${laboratoryData.laboratory.institution.institutionInitials} -
              ${laboratoryData.laboratory.institution.socialReason}`}
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={1}
            style={{ paddingBottom: '30px' }}
          >
            <Title variant="subtitle1" $noBorder $noPaddingBottom item xs={12}>
              {translate('EDIT_LABORATORY_ABOUT_LAB')}
            </Title>
            {!isEditor ? (
              <Grid item xs={12}>
                <Text>{laboratory.about}</Text>
              </Grid>
            ) : (
              <Grid item xs={12} style={{ marginLeft: '28px' }}>
                <BaseTextareaField
                  name="about"
                  errors={errors}
                  control={control}
                  required={true}
                  rowsMin={3}
                  maxLength={500}
                />
              </Grid>
            )}
          </Grid>
          <Grid container item spacing={1}>
            <Subtitle>{translate('EDIT_LABORATORY_ADRESS')}</Subtitle>
            <Grid item xs={12}>
              <Text>{laboratory.street}</Text>
            </Grid>
            <Grid item xs={12}>
              <Text>{`${laboratory.district} - ${laboratory.city} - ${laboratory.state}`}</Text>
            </Grid>
            <Grid item xs={12}>
              <Text>{`${translate('EDIT_LABORATORY_ZIP_CODE')} ${
                laboratory.zipCode
              }`}</Text>
            </Grid>
            <Grid item xs={12}>
              <Text>
                <Link
                  variant="body2"
                  onClick={() => setShowMap(currState => !currState)}
                >
                  {translate('EDIT_LABORATORY_LOCATION')}
                </Link>
              </Text>
            </Grid>
            <Grid item xs={12}>
              {showMap ? (
                <MapGL
                  {...viewport}
                  mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
                  mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                  onViewportChange={viewport => setViewPort(viewport)}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      padding: '10px'
                    }}
                  >
                    <NavigationControl />
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      padding: '8px'
                    }}
                  >
                    <IconButton
                      className="fas fa-times"
                      style={{ color: 'white' }}
                      onClick={() => setShowMap(false)}
                    />
                  </div>

                  <Marker
                    longitude={marker.longitude}
                    latitude={marker.latitude}
                    draggable={false}
                  >
                    <div className={classes.marker}></div>
                  </Marker>
                </MapGL>
              ) : null}
            </Grid>
          </Grid>
          {responsible && (
            <Grid container item spacing={1}>
              <Subtitle>{translate('EDIT_LABORATORY_CONTACT')}</Subtitle>
              <Grid item xs={12}>
                <PCustom>{translate('EDIT_LABORATORY_RESPONSIBLE')}:</PCustom>
                <Text>
                  {`${responsible.firstName || ''} ${
                    responsible.lastName || ''
                  }`}
                </Text>
              </Grid>
              <Grid item xs={12}>
                <PCustom>{translate('EDIT_LABORATORY_TELEPHONE')}:</PCustom>
                <Text>{responsible.telephone || ''}</Text>
              </Grid>
              <Grid item xs={12}>
                {!isEditor ? (
                  <>
                    <PCustom>Email:</PCustom>
                    <Text>{laboratory.contactEmail}</Text>
                  </>
                ) : (
                  <WrapInput>
                    <BaseTextField
                      label="Email:"
                      placeholder={translate('EDIT_LABORATORY_EMAIL_ADD')}
                      name="email"
                      errors={errors}
                      control={control}
                    />
                  </WrapInput>
                )}
              </Grid>
              <Grid item xs={12}>
                {!isEditor ? (
                  <>
                    <PCustom>Website:</PCustom>
                    <Text>{laboratory.website}</Text>
                  </>
                ) : (
                  <WrapInput>
                    <BaseTextField
                      label="Website:"
                      placeholder={translate('EDIT_LABORATORY_WEBSITE_ADD')}
                      name="website"
                      errors={errors}
                      control={control}
                    />
                  </WrapInput>
                )}
              </Grid>
            </Grid>
          )}
          {laboratory.areasExpertiseList && (
            <Grid item xs={12}>
              <Grid container>
                <TagsList
                  title="EDIT_LABORATORY_AREA_EXPERTISE"
                  list={laboratory.areasExpertiseList}
                />
                <TagsList
                  title="EDIT_LABORATORY_TECHINIQUE"
                  list={laboratory.techniqueList}
                />
              </Grid>
            </Grid>
          )}

          {isEditor ? (
            <Grid item xs={12}>
              <Subtitle>{translate('EQUIPMENT')}:</Subtitle>
              <ButtonAddEquipment
                fullWidth
                type={'button'}
                onClick={() => onSubmit('draft_redirect')}
                variant="contained"
                className={classes.baseButton}
                style={{ padding: '35px' }}
              >
                {translate('REGISTER_LABORATORY_ADD_EQUIPMENT')}
              </ButtonAddEquipment>
            </Grid>
          ) : null}
        </Grid>

        {equipmentList.length > 0 && (
          <List
            classes={classes}
            rowsPerPageOptions={rowsPerPageList}
            count={count}
            rowsPerPage={pageSize}
            page={pageNumber}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            equipmentList={equipmentList}
            emptyList={!equipmentList.length}
            onRowAction={handleRowChange}
            shouldShowSelect={false}
          />
        )}

        {isEditor ? (
          <>
            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              <SharingConfig
                formMethods={formMethods}
                errorMsgCheckTermDecla={errorMsgCheckTermDecla}
                errorMsgTextAreaTerm={errorMsgTextAreaTerm}
              />
            </Grid>

            <Grid container justify="flex-end" spacing={2}>
              <Grid item xs={12} md={2}>
                <Button
                  type={'button'}
                  variant="contained"
                  fullWidth
                  className={classes.baseButtonGrey}
                  onClick={() => history.push('/back-office/laboratory')}
                >
                  {translate('REGISTER_INSTITUTION_CANCEL')}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  type={'button'}
                  variant="contained"
                  onClick={handleAdvancedEdit}
                  fullWidth
                  className={classes.baseButtonGrey}
                >
                  {translate('ADVANCED_EDIT')}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  fullWidth
                  type={'button'}
                  onClick={() => onSubmit('draft')}
                  variant="contained"
                  className={classes.baseButton}
                >
                  {translate('SAVE_DRAFT')}
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  type={'button'}
                  onClick={() => onSubmit('publish')}
                  variant="contained"
                  className={classes.baseButton}
                >
                  {translate('REGISTER_INSTITUTION_PUBLISH')}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          laboratory?.hasSharing && (laboratory.hasSharingUseStatement || laboratory.ownShareLink) && (
            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              <SharingConfigReadOnly
                textAreaUseStatement={laboratory.textAreaUseStatement}                
                shareLink={laboratory.ownShareLink}
                sharingType={laboratory.sharingType}

              />
            </Grid>
          )
        )}
      </form>
      {!isEditor && (
        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ paddingBottom: '30px' }}
          justify="flex-end"
        >
          <Button
            type={'button'}
            variant="contained"
            className={classes.baseButtonGrey}
            onClick={handleBack}
            disabled={false}
          >
            {translate('COMMONS:GOBACK')}
          </Button>
        </Grid>
      )}
    </>
  )
}
export default LaboratoryEdit
