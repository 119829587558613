import React from 'react'

import { StyledBurger } from './styles'

const Burger = ({ open, toggleMenu, showBurger }) => {
  return showBurger ? (
    <StyledBurger open={open} onClick={toggleMenu}>
      <div />
      <div />
      <div />
    </StyledBurger>
  ) : null
}

export default Burger
