import trim from 'lodash/trim'
import React, { useState, useContext } from 'react'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'

import { isEnter } from 'helpers/disableSubmitOnEnter'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import DefaultHint from 'ui/molecules/commons/DefaultHint'

export const StyledTableRow = withStyles(theme => ({
  root: {
    height: '40px',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    '&.placeholder': {
      backgroundColor: '#dadada'
    }
  }
}))(TableRow)

const InputTableCell = styled(TableCell)`
  padding: 0;
  position: relative;

  input {
    padding-left: 15px;
    height: 44px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }
`

const CellInput = ({ name, defaultValue, formMethods, ...rest }) => {
  return (
    <>
      <Controller
        render={props => <input {...props} {...rest} />}
        name={name}
        control={formMethods.control}
        defaultValue={defaultValue}
      />
    </>
  )
}

const EquipmentParameters = ({ formMethods, classes, maxLength }) => {
  const { translate } = useContext(GlobalizationContext)
  const { register, control, errors } = formMethods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parameterList',
    keyName: 'uuId'
  })

  const newParameter = useWatch({
    control,
    name: 'newParameter',
    defaultValue: ''
  })

  const [maxLimitParameterReached, setMaxLimitParameterReached] = useState(
    false
  )

  const handleAddParameter = () => {
    setMaxLimitParameterReached(false)

    const alreadyExists =
      fields.findIndex(
        parameter => trim(parameter.name) == trim(newParameter)
      ) >= 0
    if (!alreadyExists && fields.length < maxLength) {
      append({
        name: newParameter,
        value: '',
        unit: '',
        isCampaignParam: false
      })
    } else if (fields.length >= maxLength) {
      setMaxLimitParameterReached(true)
    }

    formMethods.setValue('newParameter', '')
  }

  const handleRemoveParameter = parameter => {
    remove(fields.findIndex(item => item === parameter))
  }

  const RowList = fields.length ? (
    fields.map((parameter, index) => {
      const isCampaignParam =
        [true, 'true'].indexOf(parameter.isCampaignParam) >= 0

      return (
        <StyledTableRow key={parameter.uuId}>
          <TableCell scope="row" align="center">
            <input
              type="hidden"
              name={`parameterList[${index}].name`}
              defaultValue={parameter.name}
              ref={register()}
            />
            <input
              type="hidden"
              name={`parameterList[${index}].isCampaignParam`}
              defaultValue={isCampaignParam}
              ref={register()}
            />
            {index + 1}
          </TableCell>
          <TableCell scope="row" align="left">
            {parameter.name}
          </TableCell>
          <InputTableCell scope="row" align="center">
            <CellInput
              placeholder={isCampaignParam ? translate('Informe...') : ''}
              formMethods={formMethods}
              name={`parameterList[${index}].value`}
              defaultValue={parameter.value}
            />
          </InputTableCell>
          <InputTableCell scope="row" align="center">
            <CellInput
              formMethods={formMethods}
              name={`parameterList[${index}].unit`}
              defaultValue={parameter.unit}
              readOnly={isCampaignParam}
            />
          </InputTableCell>
          <TableCell scope="row" align="center">
            <IconButton
              onClick={() => handleRemoveParameter(parameter)}
              aria-label="delete"
              disabled={isCampaignParam}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        </StyledTableRow>
      )
    })
  ) : (
    <StyledTableRow className={'placeholder'}>
      <TableCell scope="row" align="center">
        1
      </TableCell>
      <TableCell scope="row" align="center">
        {translate('REGISTER_EQUIPMENT_PARAMETERS_NAME_PLACEHOLDER')}
      </TableCell>
      <TableCell scope="row" align="center">
        {translate('REGISTER_EQUIPMENT_PARAMETERS_VALUE_PLACEHOLDER')}
      </TableCell>
      <TableCell scope="row" align="center" colSpan={2}>
        {translate('REGISTER_EQUIPMENT_PARAMETERS_UNIT_PLACEHOLDER')}
      </TableCell>
    </StyledTableRow>
  )
  return (
    <>
      <h3 className={classes.h3}>
        {translate('REGISTER_EQUIPMENT_PARAMETERS_LABEL')}
      </h3>
      <Grid item xs={12}>
        <Grid container display="flex" alignItems="baseline">
          <label className={classes.label}>
            {translate('REGISTER_EQUIPMENT_CARACTERISTICS')}
          </label>
          <DefaultHint content="REGISTER_EQUIPMENT_CARACTERISTICS_HINT" />
        </Grid>
        <Grid
          container
          spacing={0}
          alignItems="flex-start"
          style={{ marginBottom: '24px' }}
        >
          <Grid item xs={12} md={10} style={{ marginRight: '10px' }}>
            <BaseTextField
              label=""
              placeholder={translate(
                'REGISTER_EQUIPMENT_OTHER_DENOMINATION_PLACEHOLDER'
              )}
              errors={errors}
              control={control}
              name="newParameter"
              maxLength={255}
              onKeyDown={e => {
                newParameter && isEnter(e) && handleAddParameter(e)
              }}
            ></BaseTextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            style={{
              maxWidth: 'calc(100% - (83.333333% + 10px))',
              flexBasis: 'calc(100% - (83.333333% + 10px))'
            }}
          >
            <Button
              disabled={!newParameter.length}
              fullWidth
              className={classes.baseAddButton}
              onClick={handleAddParameter}
              variant="contained"
            >
              {translate('COMMONS_REGISTER_ADD_BUTTON')}
            </Button>
          </Grid>
          {maxLimitParameterReached && (
            <Grid item xs={12} md={12} style={{ marginBottom: '-18px' }}>
              <span style={{ color: 'red', fontSize: '12px' }}>
                {translate('REGISTER_EQUIPMENT_PARAMETER_LIMIT', {
                  maxParameter: maxLength
                })}
              </span>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Table aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                width: '2%',
                paddingLeft: '15px',
                paddingRight: '15px'
              }}
              align="center"
            >
              #
            </TableCell>
            <TableCell align="left">
              {translate('REGISTER_EQUIPMENT_PARAMETERS_NAME')}
            </TableCell>
            <TableCell align="left">
              {translate('REGISTER_EQUIPMENT_PARAMETERS_VALUE')}
            </TableCell>
            <TableCell align="left" colSpan={2}>
              {translate('REGISTER_EQUIPMENT_PARAMETERS_UNIT')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{RowList}</TableBody>
      </Table>
    </>
  )
}

export default EquipmentParameters
