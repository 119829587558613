import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 1.5rem;
`

export const Title = styled.h3`
  font-style: normal;
  font-size: 16px;
`

export const Wrapper = styled.div`
  border: 1px solid #ccc;
  padding: 10px 20px;
  position: relative;

  &::before {
    display: flex;
    position: absolute;
    align-items: center;
    vertical-align: middle;
  }
`

export const ItemText = styled.h4`
  color: #333;
  font-size: 14px;
  font-weight: 600;
  margin: 12px 0 5px;
`

export const RadioText = styled.span`
  font-weight: 400;
  font-size: 14px;
`
