import React, { useCallback, useMemo, useContext } from 'react'
import styled from 'styled-components'

import Tooltip from '@material-ui/core/Tooltip'

import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Marker, MarkerGreen, MarkerOrange, MarkerRed } from 'ui/atoms/Marker'
import { TableRow, TableCell } from 'ui/atoms/Table'
import TableActions from 'ui/atoms/TableActions'

export const LabMarker = styled(Marker)`
  ${props =>
    ['PUBLISHED', 'WAITING_PUBLICATION'].indexOf(props.type) >= 0 &&
    MarkerGreen}

  ${props =>
    ['WAITING_APPROVAL', 'WAITING_EDIT_APPROVAL', 'IN_ACTIVATION'].indexOf(
      props.type
    ) >= 0 && MarkerOrange}

  ${props =>
    ['REJECTED', 'EDIT_REJECTED', 'UNPUBLISHED', 'INACTIVE'].indexOf(
      props.type
    ) >= 0 && MarkerRed}
`

const StatusCell = styled(TableCell)`
  max-width: 90px;
  font-weight: 600 !important;
`

function Row(props) {
  const { item, onRowAction: handleRowAction } = props
  const { translate } = useContext(GlobalizationContext)

  const redirectUrl = useMemo(() => {
    if (item.actions.includes('APPROVE')) {
      return generatePrivatePath(`/laboratory/${item.id}/action/approve`)
    } else if (item.actions.includes('APPROVE_EDIT')) {
      return generatePrivatePath(`/laboratory/${item.id}/action/approve-edit`)
    } else {
      return generatePrivatePath(`/laboratory/${item.id}`)
    }
  }, [item])

  const rowActions = useMemo(() => {
    if (item.actions.length) {
      return [].concat(
        item.actions.map(actionItem => {
          let link =
            ['VIEW', 'APPROVE', 'APPROVE_EDIT', 'EDIT'].indexOf(actionItem) >= 0
              ? redirectUrl
              : undefined

          return {
            code: actionItem,
            name: `ACTION.${actionItem}`,
            link: link
          }
        })
      )
    } else {
      return [
        {
          code: 'NO_ACTION',
          name: 'ACTION.VIEW',
          link: redirectUrl
        }
      ]
    }
  }, [item, redirectUrl])

  const onRowAction = useCallback(
    action => {
      switch (true) {
        case ['APPROVE', 'APPROVE_EDIT', 'EDIT', 'NO_ACTION'].includes(action):
          history.push(redirectUrl)
          break
        default:
          handleRowAction(action, item)
          break
      }
    },
    [handleRowAction, item, redirectUrl]
  )

  return (
    <TableRow>
      <TableCell>
        <Tooltip title={translate(`LABORATORY:SITUATION.${item.situation}`)}>
          <LabMarker type={item.situation} />
        </Tooltip>
      </TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.initials}</TableCell>
      <TableCell>
        {item.areasExpertiseList.map(item => item.name).join(', ')}
      </TableCell>
      <TableCell>{item.locality}</TableCell>
      <StatusCell type={item.situation}>
        {item.situation
          ? translate(`LABORATORY:SITUATION.${item.situation}`)
          : null}
      </StatusCell>
      <TableCell style={{ width: '10px' }}>
        <TableActions
          actionList={rowActions}
          handleActionSelected={onRowAction}
        />
      </TableCell>
    </TableRow>
  )
}
export default Row
