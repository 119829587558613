import styled from 'styled-components'

import Box from '@material-ui/core/Box'

export const MainWrapper = styled(Box)`
  height: 100%;
`

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: calc(100% - 120px);
`

export const ContentWrapper = styled(Box)`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  position: relative;
`
