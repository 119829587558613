import React, { useContext, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import InfiniteScroll from 'react-infinite-scroller'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { Box, Button, Container, Grid } from '@material-ui/core'

import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import { DefaultLoader } from 'ui/atoms/AsyncLoad/DefaultLoader'
import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import { requestSharingUseCases } from '../../../../providers'
import { ComunicationContext } from '../../providers/ComunicationContext'
import Message from './Fragments/Message'
import {
  BorderContainer,
  MessageContainer,
  FormContainer,
  LoaderContainer
} from './style'

const formSchema = Yup.object().shape({
  newMessage: Yup.string().trim().required()
})

const Comunication = ({ situation }) => {
  const { id } = useParams()
  const { translate } = useContext(GlobalizationContext)
  const scrollRef = useRef()
  const scrollContainerRef = useRef()
  const lastItemRef = useRef()

  const { sharingRequest } = situation

  const {
    messageList,
    listFinished,
    fetchNewMessages,
    fetchOlderMessages
  } = useContext(ComunicationContext)

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver,
    defaultValues: {
      newMessage: ''
    }
  })
  const { reset, control, formState, handleSubmit } = formMethods
  const { isValid } = formState

  const onSubmit = async formData => {
    const now = new Date()
    try {
      await requestSharingUseCases.sendMessage(id, formData.newMessage)
      reset()
      await fetchNewMessages(now, false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchOlderMessages()
  }, [fetchOlderMessages])

  return (
    <Container
      maxWidth="md"
      style={{ padding: 0, height: '100%', overflow: 'hidden' }}
    >
      <Box display="flex" flexDirection="column" component={BorderContainer}>
        <MessageContainer
          ref={scrollContainerRef}
          renderView={props => <div {...props} ref={scrollRef} />}
        >
          <InfiniteScroll
            initialLoad={false}
            loadMore={fetchOlderMessages}
            hasMore={!listFinished}
            loader={
              <LoaderContainer key={0}>
                <DefaultLoader size={40} />
              </LoaderContainer>
            }
            isReverse={true}
            useWindow={false}
            getScrollParent={() => scrollRef.current}
          >
            {messageList.map((message, idx) => (
              <Message
                key={message.id}
                data={message}
                ref={ref => {
                  if (
                    idx === messageList.length - 1 &&
                    lastItemRef.current !== message.id
                  ) {
                    lastItemRef.current = message.id
                    ref.scrollIntoView(false)
                  }
                }}
              />
            ))}
          </InfiniteScroll>
        </MessageContainer>
        {!['FINISHED', 'REFUSED', 'CANCELED'].includes(
          sharingRequest?.situation
        ) ? (
          <FormContainer>
            <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
              <Box>
                <BaseTextareaField
                  name="newMessage"
                  label={translate('DETAILS.COMMUNICATION.LABEL')}
                  control={control}
                  supressErrors
                  maxLength={500}
                  rowsMax={5}
                />
              </Box>

              <Box>
                <Grid container justify="flex-end" spacing={0}>
                  <Grid item xs={12} sm="auto">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!isValid}
                    >
                      {translate('COMMONS:SEND')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </FormContainer>
        ) : null}
      </Box>
    </Container>
  )
}

export default Comunication
