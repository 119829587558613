import styled from 'styled-components'

import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogTitle from '@material-ui/core/DialogTitle'

export const Dialog = styled(MuiDialog)``
export const DialogActions = styled(MuiDialogActions)``
export const DialogContent = styled(MuiDialogContent)``
export const DialogContentText = styled(MuiDialogContentText)``
export const DialogTitle = styled(MuiDialogTitle)``
