// @ts-nocheck
import styled from 'styled-components'

import { MarkerOrange, MarkerRed } from 'ui/atoms/Marker'

const getMarkerColor = situation => {
  switch (situation) {
    case 'PENDING':
    case 'WAITING_APPROVAL':
      return MarkerOrange
    case 'REJECTED':
      return MarkerRed
    default:
  }
}

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 32px 0;
`

export const BoxContainer = styled.div`
  border: 1px solid #dadada;
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-height: 240px;

  &:first-child {
    margin-right: 32px;
  }
`

export const BoxHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const ListContainer = styled.div`
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  color: #333;
  display: grid;
  font-size: 14px;
  grid-template-columns: 10% 30% 30% 20% 10%;
  padding: 32px 24px;
  margin-bottom: 24px;

  & button:hover {
    background-color: rgba(0, 0, 0, 0);
  }
`

export const Marker = styled.span`
  border-radius: 50%;
  display: block;
  width: 16px;
  height: 16px;

  ${props => getMarkerColor(props.type)}
`
