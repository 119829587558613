import clsx from 'clsx'
import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'

import LaboratoryService from 'api/LaboratoryService'
import { AuthContext } from 'context/AuthContext'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { textToConstant } from 'helpers/textToConstant'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseSelect from 'ui/atoms/BaseSelect'
import BaseTextField from 'ui/atoms/BaseTextField'
import { TableChip } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { WarningMessage } from 'ui/atoms/Messages'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import DefaultHint from 'ui/molecules/commons/DefaultHint'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import InviteModal from './Fragments/Invite'

const InstitutionSchema = Yup.object().shape({})

const WrapEmail = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const WrapContent = styled.div`
  max-width: 450px;
  display: inline-flex;
`

const DefaultContainer = styled.div`
  padding-bottom: 1.875rem;
`

const UndoIcon = styled.i`
  font-size: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

function LaboratoryRepresentantList() {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  const { userData } = useContext(AuthContext)

  const [laboratoryList, setLaboratoryList] = useState([])
  const [responsible, setResponsible] = useState('')
  const [userList, setUserList] = useState([])
  const [modalInviteOpen, setModalInviteOpen] = useState(false)
  const [canSendInvite, setCanSendInvite] = useState(true)
  const [formNeedsSaving, setFormNeedsSaving] = useState(false)
  const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false)
  const [confirmRemoveSelf, setConfirmRemoveSelf] = useState(null)

  const resolver = useYupValidationResolver(InstitutionSchema, { translate })
  const {
    getValues,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    errors,
    control,
    reset
  } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {
      laboratory: ''
    }
  })

  const selectedLaboratoryId = watch('laboratory')
  const emailInvite = watch('emailInvite')

  const removeInvite = useCallback(
    item => {
      clearErrors('email_invite')
      if (item.id) {
        setUserList(oldList => {
          const newList = [...oldList]
          const itemIndex = newList.findIndex(obj => obj.id === item.id)
          newList[itemIndex] = {
            ...newList[itemIndex],
            deleted: true
          }
          return newList
        })
      } else {
        setUserList(oldList => oldList.filter(obj => obj.email !== item.email))
      }
      setFormNeedsSaving(true)
    },
    [clearErrors]
  )

  const checkRemoveInvite = useCallback(
    item => {
      if (userData.email !== item.email) {
        removeInvite(item)
      } else {
        setConfirmRemoveSelf(item)
      }
    },
    [removeInvite, userData]
  )

  const undoRemoveInvite = useCallback(item => {
    setUserList(oldList => {
      const newList = [...oldList]
      const itemIndex = newList.findIndex(obj => obj.email === item.email)
      delete newList[itemIndex].deleted
      return newList
    })
  }, [])

  const handlePermission = (event, index) => {
    const newPermission = event.target.value
    setUserList(oldUserList => {
      const rowCopy = { ...oldUserList[index], permission: newPermission }
      const listCopy = [...oldUserList]
      listCopy[index] = rowCopy
      return listCopy
    })
    setFormNeedsSaving(true)
  }

  const handleSituation = (event, index) => {
    const newSituation = event.target.value
    setUserList(oldUserList => {
      const rowCopy = { ...oldUserList[index], situation: newSituation }
      const listCopy = [...oldUserList]
      listCopy[index] = rowCopy
      return listCopy
    })
    setFormNeedsSaving(true)
  }

  const getLaboratoryByUser = async () => {
    let labList = await LaboratoryService.getAllMine().then(resp => resp.data)
    setLaboratoryList(labList)
  }

  useEffect(() => {
    if (selectedLaboratoryId === undefined || selectedLaboratoryId === '')
      return
    if (!userData) return setCanSendInvite(false)

    const currLaboratory = laboratoryList.find(
      lab => lab.id === selectedLaboratoryId
    )

    const roleTotalList = (currLaboratory?.representativeList || []).filter(
      responsible =>
        responsible.email === userData.email &&
        responsible.permission === 'ROLE_LABORATORIO_TOTAL'
    )

    setUserList(currLaboratory?.representativeList)
    setCanSendInvite(Boolean(roleTotalList.length))
  }, [selectedLaboratoryId, laboratoryList, userData])

  useEffect(() => {
    getLaboratoryByUser()
  }, [])

  const resetFormOnSubmit = () => {
    setFormNeedsSaving(false)
    reset()
    setResponsible('')
    setUserList([])
  }

  const onSubmit = async () => {
    try {
      const mappedUseList = userList.map(({ deleted, ...item }) => ({
        ...item,
        situation: !deleted ? item.situation : 'DELETED'
      }))

      await LaboratoryService.postRepresentativeList(
        selectedLaboratoryId,
        mappedUseList
      )
      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      await getLaboratoryByUser()
    } catch {
      notificationUseCases.newError()
    }
    resetFormOnSubmit()
  }

  const checkEmailAlreadyInvited = async () => {
    const email = getValues('emailInvite')

    if (userList.findIndex(item => item.email === email) >= 0) {
      return setError('emailInvite', {
        type: 'emailAlreadyExists',
        message: translate('REGISTER_INSTITUTION_USER_ALREADY_INVITED')
      })
    }

    try {
      await LaboratoryService.getEmailAlreadySent(selectedLaboratoryId, email)
      handleOpenInvite()
    } catch (error) {
      const message = error.response?.data?.message

      if (message === 'invite.already.sent') {
        setError('emailInvite', {
          type: 'emailAlreadyExists',
          message: translate('REGISTER_INSTITUTION_USER_ALREADY_INVITED')
        })
      } else if (message === 'invalid.user.situation') {
        setError('emailInvite', {
          type: 'invalidUser',
          message: translate('ERRORS:INVALID_USER_SITUATION')
        })
      } else {
        notificationUseCases.newError()
      }
    }
  }

  const handleOpenInvite = useCallback(async () => {
    clearErrors('emailInvite')
    const email = getValues('emailInvite')

    const valid = await Yup.string().email().isValid(email)
    if (!valid)
      return setError('emailInvite', {
        type: 'email',
        message: 'Email inválido'
      })

    if (userList.findIndex(item => item.email === email) >= 0)
      return setError('emailInvite', {
        type: 'emailAlreadyExists',
        message: translate('REGISTER_INSTITUTION_USER_ALREADY_INVITED')
      })

    if (
      userList.filter(
        user => user.situation === 'ACTIVE' || user.situation === 'PENDING'
      ).length >= 10
    ) {
      return setError('emailInvite', {
        type: 'emailAlreadyExists',
        message: translate('REGISTER_INSTITUTION_MAXIMUM_USERS_REACH')
      })
    }

    setModalInviteOpen(true)
  }, [clearErrors, getValues, userList, setError, translate])

  const handleCloseInvite = () => {
    setValue('emailInvite', '')
    setModalInviteOpen(false)
  }

  const handleInvite = async invite => {
    setUserList(oldUserList => {
      return [...oldUserList, invite]
    })
    setValue('emailInvite', '')
    setModalInviteOpen(false)
    setFormNeedsSaving(true)
  }

  const handleConfirmCancel = () => {
    resetFormOnSubmit()
    setConfirmCancelDialogOpen(false)
  }

  const canEditRow = useCallback(
    row => {
      return (
        canSendInvite &&
        !row.deleted &&
        !row.responsible &&
        !row.userInactive &&
        ['PENDING', 'REJECTED', 'DELETED'].indexOf(row.situation) == -1
      )
    },
    [canSendInvite]
  )

  return (
    <>
      <Loader />
      {modalInviteOpen ? (
        <InviteModal
          email={emailInvite}
          onInvite={handleInvite}
          onClose={handleCloseInvite}
        />
      ) : null}

      <BaseContainerHeader
        label={translate('LABORATORY_LIST_REPRESENTANT')}
        showCloseButton={false}
      />

      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <DefaultContainer>
          <Grid item xs={12} md={6}>
            <BaseSelect
              required={true}
              placeholder={translate('EQUIPMENT_LIST_SELECT_LAB')}
              label={translate('LABORATORY_LIST_REPRESENTANT_SELECT')}
              errors={errors}
              control={control}
              name="laboratory"
              options={laboratoryList}
              handleChange={() => {}}
              optionRenderer={(desc, option) =>
                `${option.initials} - ${option.name}`
              }
            ></BaseSelect>
          </Grid>
        </DefaultContainer>
        {selectedLaboratoryId && (
          <>
            {userList && userList.length > 0 && (
              <DefaultContainer>
                <Grid container item xs={12} md={12} alignItems="center">
                  <label style={{ float: 'left' }} className={classes.label}>
                    Nome do responsável:
                  </label>
                  <label
                    style={{ marginLeft: '10px' }}
                    className={classes.baseText}
                  >
                    {responsible}
                  </label>
                  <DefaultHint content="LABORATORY_LIST_REPRESENTANT_DIALOG" />
                </Grid>
              </DefaultContainer>
            )}

            <DefaultContainer style={{ paddingBottom: '15px' }}>
              <label style={{ float: 'left' }} className={classes.label}>
                {translate('EDIT_LABORATORY_ADD_RESPONSIBLE')}
              </label>
              <Grid container spacing={0} alignItems="flex-start">
                <Grid item xs={12} md={10}>
                  <BaseTextField
                    disabled={!userList?.length || !canSendInvite}
                    label=""
                    placeholder={translate('REGISTER_INSTITUTION_ADD_EMAIL')}
                    errors={errors}
                    control={control}
                    name="emailInvite"
                    style={{
                      maxHeight: '40px',
                      minHeight: '40px',
                      marginRight: '10px'
                    }}
                  ></BaseTextField>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    name={'INVITE_TO_LABORATORY'}
                    disabled={
                      !userList?.length || !canSendInvite || !emailInvite.length
                    }
                    fullWidth
                    className={classes.baseButton}
                    onClick={checkEmailAlreadyInvited}
                    variant="contained"
                    style={{ marginTop: errors['emailInvite'] ? '1px' : 0 }}
                  >
                    {translate('REGISTER_INSTITUTION_INVITE')}
                  </Button>
                </Grid>
              </Grid>
            </DefaultContainer>

            <DefaultContainer>
              {formNeedsSaving ? (
                <Grid item xs={12}>
                  <WarningMessage>
                    {translate('FORM_NEEDS_SAVING')}
                  </WarningMessage>
                </Grid>
              ) : null}
              <TableContainer component={Paper}>
                <Table aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">#</TableCell>
                      <TableCell align="left">
                        {translate(
                          'REGISTER_INSTITUTION_REPRESENTATIVES_EMAIL'
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {translate('REGISTER_INSTITUTION_REPRESENTATIVES_NAME')}
                      </TableCell>
                      <TableCell align="left">
                        {translate(
                          'REGISTER_INSTITUTION_REPRESENTATIVES_PERMISSION'
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {translate(
                          'REGISTER_INSTITUTION_REPRESENTATIVES_STATUS'
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {userList &&
                      userList.map((row, index) => (
                        <TableRow
                          id={`ROW_${textToConstant(row.email)}`}
                          key={index}
                          className={clsx({
                            [classes.disableRow]: !canEditRow(row)
                          })}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell padding={'default'} align="left">
                            {row.situation != 'REJECTED' && (
                              <div
                                className={
                                  classes['statusCircle' + row.situation]
                                }
                              ></div>
                            )}
                            <WrapContent>
                              <WrapEmail alt={row.email} title={row.email}>
                                {row.email}
                              </WrapEmail>
                              {row.userInactive ? (
                                <TableChip
                                  size="small"
                                  label={translate(
                                    'REGISTER_INSTITUTION_REPRESENTATIVES_INACTIVE'
                                  )}
                                />
                              ) : null}
                            </WrapContent>
                          </TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="center" width="160">
                            <div
                              style={{
                                paddingRight: '15px'
                              }}
                            >
                              <Select
                                name="SELECT_ROLE"
                                disableUnderline={true}
                                disabled={!canEditRow(row)}
                                fullWidth
                                native
                                value={row.permission}
                                onChange={event =>
                                  handlePermission(event, index)
                                }
                              >
                                <option value="ROLE_LABORATORIO_TOTAL">
                                  Total
                                </option>
                                <option value="ROLE_LABORATORIO_PARCIAL">
                                  Parcial
                                </option>
                                <option value="ROLE_LABORATORIO_LIMITADA">
                                  Limitado
                                </option>
                              </Select>
                            </div>
                          </TableCell>
                          <TableCell width="170">
                            <Grid container display="flex">
                              <div
                                style={{
                                  width: 'calc(100% - 47px)',
                                  marginTop: '2px',
                                  paddingRight: '3px'
                                }}
                              >
                                <Select
                                  name="SELECT_SITUATION"
                                  disableUnderline={true}
                                  fullWidth
                                  disabled={!canEditRow(row)}
                                  native
                                  value={
                                    row.deleted ? 'DELETED' : row.situation
                                  }
                                  onChange={event =>
                                    handleSituation(event, index)
                                  }
                                >
                                  <option value="ACTIVE">
                                    {translate('LABORATORY:SITUATION.ACTIVE')}
                                  </option>
                                  <option value="INACTIVE">
                                    {translate('LABORATORY:SITUATION.INACTIVE')}
                                  </option>
                                  {row.deleted ||
                                  row.situation === 'DELETED' ? (
                                    <option value="DELETED">
                                      {translate(
                                        'LABORATORY:SITUATION.DELETED'
                                      )}
                                    </option>
                                  ) : null}
                                  {row.situation === 'PENDING' ? (
                                    <option value="PENDING">
                                      {translate(
                                        'LABORATORY:SITUATION.PENDING'
                                      )}
                                    </option>
                                  ) : null}
                                  {row.situation === 'REJECTED' ? (
                                    <option value="REJECTED">
                                      {translate(
                                        'LABORATORY:SITUATION.REJECTED'
                                      )}
                                    </option>
                                  ) : null}
                                </Select>
                              </div>
                              <div style={{ width: '44px' }}>
                                {!row.deleted ? (
                                  <IconButton
                                    disabled={!canSendInvite || row.responsible}
                                    onClick={() => checkRemoveInvite(row)}
                                    aria-label="delete"
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => undoRemoveInvite(row)}
                                    aria-label="undo"
                                    title="Desfazer exclusão"
                                  >
                                    <UndoIcon className="fas fa-undo"></UndoIcon>
                                  </IconButton>
                                )}
                              </div>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DefaultContainer>
            <DefaultContainer>
              <Grid container spacing={3}>
                <Grid
                  container
                  justify="flex-end"
                  spacing={2}
                  style={{ paddingTop: '4rem' }}
                >
                  <Grid item xs={12} md={2}>
                    <Button
                      name="CANCEL"
                      className={classes.baseButtonGrey}
                      type={'button'}
                      variant="contained"
                      fullWidth
                      onClick={() => setConfirmCancelDialogOpen(true)}
                    >
                      {translate('REGISTER_INSTITUTION_CANCEL')}
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Button
                      name="SAVE"
                      disabled={!formNeedsSaving}
                      fullWidth
                      type={'submit'}
                      variant="contained"
                      className={classes.baseButton}
                    >
                      {translate('REGISTER_INSTITUTION_SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </DefaultContainer>
          </>
        )}
      </form>

      <DialogConfirm
        dialogText="REGISTER_LABORATORY_CONFIRM_DIALOG"
        open={confirmCancelDialogOpen}
        onConfirm={handleConfirmCancel}
        onCancel={() => setConfirmCancelDialogOpen(false)}
      />
      <DialogConfirm
        dialogText="REGISTER_LABORATORY_REMOVE_SELF"
        open={Boolean(confirmRemoveSelf)}
        onCancel={() => setConfirmRemoveSelf(null)}
        onConfirm={() => {
          removeInvite({ ...confirmRemoveSelf })
          setConfirmRemoveSelf(null)
        }}
      />
    </>
  )
}

export default LaboratoryRepresentantList
