// @ts-nocheck
import styled from 'styled-components'

import { ErrorMessage } from 'ui/atoms/Messages'

export const Container = styled.div`
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 42px;
  padding: 20px;
`
export const NewErrorMessage = styled(ErrorMessage)`
  color: #333;
  font-size: 16px;

  margin: 0;

  p {
    font-weight: 500;
  }
`
