export const REQUEST_SHARING_ROUTES = {
  LIST: '/request-sharing',
  DETAILS: '/request-sharing/:id([0-9]+)',
  DETAILS_VIEW: '/request-sharing/:id([0-9]+)/view',
  DETAILS_COMMUNICATION: '/request-sharing/:id([0-9]+)/communication',
  NEW: '/new-request-sharing',
  NEW_WITH_LAB: '/new-request-sharing/:type(lab|equip)/:id([0-9]+)',
  EDIT: '/edit-request-sharing/:id([0-9]+)',
  ANALYZE: '/analyze-request-sharing/:id([0-9]+)'
}
