import React, { useRef } from 'react'
import { Controller } from 'react-hook-form'

import { Checkbox, Typography } from '@material-ui/core'

import {
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as RadioGroupStyled,
  FormHelperText
} from './style'

const controlTypes = {
  radio: Radio,
  checkbox: Checkbox
}

const RadioGroup = ({
  className,
  name,
  label,
  control,
  error,
  options = [],
  idKey,
  descKey,
  helperText,
  disableRipple,
  labelRenderer = null,
  dashedLine = false,
  controlType = 'radio',
  defaultValue = '',
  columns = 1,
  inline,
  row,
  disabled,
  required
}) => {
  const inputRef = useRef()
  const id = idKey || 'id'
  const desc = descKey || 'name'
  const hasError = Boolean(error && error[name])
  const radioHelperText = (hasError && error[name].message) || helperText

  if (!control) return <>No Controller</>

  const handleCheck = (checkedId, currValue) => {
    const ids = currValue
    const newIds = ids?.includes(checkedId)
      ? ids?.filter(id => id !== checkedId)
      : [...(ids ?? []), checkedId]
    return newIds
  }

  const handleChange = (e, option, value, onChange, onBlur) => {
    if (controlType === 'checkbox') {
      onChange(handleCheck(String(option[id]), value))
      onBlur()
    } else {
      onChange(e)
    }
  }

  const ControlType = controlTypes[controlType] || controlTypes.radio

  return (
    <>
      <FormControl
        className={className}
        $inline={inline}
        $required={required}
        component="div"
      >
        {label && <FormLabel component="legend">{label}</FormLabel>}

        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          onFocus={() => inputRef.current?.focus()}
          render={({ ref, onChange, onBlur, ...controlProps }) => (
            <RadioGroupStyled
              {...{ row }}
              {...controlProps}
              columns={columns}
              dashedLine={dashedLine}
            >
              {options.map(option => {
                const optionValue = String(option[id])
                let optionProps = {}
                if (controlType === 'checkbox') {
                  // debugger
                  optionProps = {
                    checked: (controlProps.value || []).includes(optionValue)
                  }
                }

                return (
                  <FormControlLabel
                    key={option[id]}
                    value={optionValue}
                    onChange={e =>
                      handleChange(
                        e,
                        option,
                        controlProps.value,
                        onChange,
                        onBlur
                      )
                    }
                    control={
                      <ControlType
                        name={name}
                        disabled={disabled}
                        {...optionProps}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {labelRenderer ? labelRenderer(option) : option[desc]}
                      </Typography>
                    }
                    inputRef={inputRef}
                  />
                )
              })}
            </RadioGroupStyled>
          )}
        />
      </FormControl>
      <FormHelperText error={hasError}>{radioHelperText}</FormHelperText>
    </>
  )
}

export default RadioGroup
