import React from 'react'
import styled from 'styled-components'
import cross from 'assets/icons/cross.svg'
import {
  Dialog as MaterialDialog,
  DialogTitle as MaterialDialogTitle,
  DialogContent as MaterialDialogContent,
  DialogActions as MaterialDialogActions,
  Button as MaterialButton,
  IconButton as MaterialIconButton,
  Typography as MaterialTypography
} from '@material-ui/core'

export const TermsLink = styled.a`
  color: #06b399;
  textdecoration: none;
  cursor: pointer;
`

export const Dialog = styled(MaterialDialog)``
export const DialogTitle = styled(MaterialDialogTitle)``
export const DialogContent = styled(MaterialDialogContent)``
export const DialogActions = styled(MaterialDialogActions)``
export const DialogParagraph = styled(MaterialTypography)``
export const DialogButton = styled(MaterialButton)`
  border-radius: 0px;
  float: right;
  text-transform: none;
  background-color: #009b7f;
  color: #ffffff;
  padding: 9px;
  font-weight: normal;
  font-size: 14px;
  &:hover: {
    background-color: #006855;
    color: #ffffff;
  }
`
export const CloseButton = styled(props => (
  <MaterialIconButton {...props}>
    <img src={cross} />
  </MaterialIconButton>
))`
  color: #333333;
  right: 10px;
  position: absolute;
  padding-top: 8px;
`
