import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import { primaryColor } from 'helpers/constants'

export const Container = styled.div`
  padding-top: 16px;
  margin-bottom: 12px;
`

export const BreadcrumbSeparator = styled(props => (
  <NavigateNextIcon
    {...props}
    fontSize="small"
    style={{ width: '18px' }}
    viewBox="5 5 16 16"
  />
))``

export const BreadcrumbItem = styled(Typography)`
  font-size: 12px;
`
export const BreadCrumbs = styled(MaterialBreadcrumbs)`
  display: flex;
  flex-direction: row;
  align-items: center;

  .MuiBreadcrumbs-separator {
    color: ${primaryColor};
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  padding: 0 32px;

  height: 40px;

  background: #f5f5f5;
  box-shadow: 0px 1px 2px rgba(0, 22, 34, 0.2);
`
export const BreadcrumbItemLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;

  color: ${primaryColor};
`
