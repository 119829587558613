import React, { useContext } from 'react'

import Typography from '@material-ui/core/Typography'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'

import { Label, Text, TextContainer } from '../../styles'

const LaboratoryInformation = ({ laboratoryData }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />
      <Typography variant="subtitle1" style={{ marginBottom: '32px' }}>
        {translate('LABORATORY_DATA')}
      </Typography>

      <TextContainer>
        <Label>{translate('LABORATORY')}</Label>
        <Text>{`${laboratoryData?.initials} - ${laboratoryData?.name}`}</Text>
      </TextContainer>

      <TextContainer>
        <Label>{translate('INSTITUITION')}</Label>
        <Text>{`${laboratoryData?.institutionInitials} - ${laboratoryData?.institutionName}`}</Text>
        <Label>{translate('CNPJ')}</Label>
        <Text>{laboratoryData?.institutionCnpj}</Text>
      </TextContainer>

      <Typography variant="subtitle1" style={{ margin: '32px 0' }}>
        {translate('LABORATORY_RESPONSIBLE')}
      </Typography>
    </>
  )
}

export default LaboratoryInformation
