import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'

import { AuthContext } from 'context/AuthContext'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { history, generatePrivatePath } from 'helpers/history'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'

import { requestSharingUseCases } from '../../../../../../providers'
import formSchema from './formSchema'

const CancelSolicitationModal = ({ open, onClose }) => {
  const { translate } = useContext(GlobalizationContext)
  const { userData } = useContext(AuthContext)
  const { id } = useParams()

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {}
  })

  const { errors, control, handleSubmit } = formMethods

  const onSubmit = async formData => {
    try {
      const requestData = {
        detailInfo: formData.reason,
        responsibleEmail: userData.email,
        responsibleName: `${userData.firstName} ${userData.lastName}`
      }

      await requestSharingUseCases.cancelRequest(id, requestData)
      history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <DialogTitle disableTypography>
          <Typography variant="h4">
            {translate('DETAILS.CANCEL_MODAL.TITLE')}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: '12px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Trans
                parent={Typography}
                variant="h5"
                t={translate}
                i18nKey="DETAILS.CANCEL_MODAL.DESCRIPTION"
              />
            </Grid>
            <Grid item xs={12}>
              <BaseTextareaField
                name="reason"
                label={translate('DETAILS.CANCEL_MODAL.REASON')}
                placeholder={translate(
                  'DETAILS.CANCEL_MODAL.REASON_PLACEHOLDER'
                )}
                control={control}
                errors={errors}
                maxLength={500}
                rows={5}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="default" onClick={onClose}>
            {translate('COMMONS:CANCEL')}
          </Button>
          <Button variant="contained" color="primary" type="submit">
            {translate('COMMONS:SEND')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CancelSolicitationModal
