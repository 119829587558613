import debounce from 'lodash/debounce'
import React, { useState, useCallback, useContext, useEffect } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import useQueryString from 'helpers/useQueryString'

import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'
import useStyles from 'ui/styles'

import EquipmentList from './Fragments/Equipment'
import Filter from './Fragments/Filter'
import FilterChipList from './Fragments/FilterChipList'
import LaboratoryList from './Fragments/Laboratory'
import { WrapFilter, FilterInput, IconSearch, TabContainer, Tab } from './style'

const TYPES = ['LAB', 'EQUIP']
const rowsPerPageList = [12, 24, 48, 96]

function Search() {
  const theme = useTheme()
  const classes = useStyles()
  const gridOnly = useMediaQuery(theme.breakpoints.only('xs'))

  const { translate } = useContext(GlobalizationContext)

  const [type, setType] = useQueryString('type', TYPES[0])
  const [filterValue, setFilterValue] = useQueryString('term', '')
  const [filterInput, setFilterInput] = useState(filterValue || '')
  const [listType, setListType] = useState('LIST')
  const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false)
  const [advancedFilter, setAdvancedFilter] = useState({})

  useEffect(() => {
    if (gridOnly) {
      setListType('GRID')
    }
  }, [gridOnly])

  useEffect(() => {
    if (!TYPES.includes(type)) {
      setType(TYPES[0])
    }
  }, [setType, type])

  const updateFilter = useCallback(
    debounce(nextValue => setFilterValue(nextValue), 350),
    []
  )

  const handleInputChange = e => {
    setFilterInput(e.currentTarget.value)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      updateFilter(event.currentTarget.value)
    }
  }

  const onClick = () => {
    updateFilter(filterInput)
  }

  const handleFilterChanged = ({ field, newValue }) => {
    setAdvancedFilter(currFilter => ({
      ...currFilter,
      [field]: newValue
    }))
  }

  const handleClearAll = () => {
    setAdvancedFilter({})
  }

  const ListComponent = type === 'LAB' ? LaboratoryList : EquipmentList

  return (
    <ContentContainer>
      <Filter
        open={advancedFilterOpen}
        onClose={() => setAdvancedFilterOpen(false)}
        onFilter={filterData => {
          setAdvancedFilter(filterData)
          setAdvancedFilterOpen(false)
        }}
        filter={advancedFilter}
      />

      <Grid item xs={12}>
        <Grid container spacing={3} style={{ marginBottom: '20px' }}>
          <Box
            display="flex"
            style={{ gap: '24px' }}
            component={Grid}
            item
            xs={12}
          >
            <Box flexGrow={1} component={WrapFilter}>
              <FilterInput
                value={filterInput}
                onChange={handleInputChange}
                placeholder={translate('FILTER_PLACEHOLDER')}
                onKeyDown={handleKeyDown}
              />
              <IconSearch className="fas fa-search" onClick={onClick} />
            </Box>
            <Box>
              <Button
                className={classes.baseButton}
                onClick={() => setAdvancedFilterOpen(true)}
              >
                {translate('EDIT_FILTERS')}
              </Button>
            </Box>
          </Box>

          <FilterChipList
            filter={advancedFilter}
            onFilterChanged={handleFilterChanged}
            onClearAll={handleClearAll}
          />

          <Grid item xs={12}>
            <TabContainer>
              {TYPES.map(typeItem => (
                <Tab
                  key={typeItem}
                  $active={typeItem === type}
                  onClick={() => setType(typeItem)}
                >
                  {translate(`SEARCH_TYPE_${typeItem}`)}
                </Tab>
              ))}
            </TabContainer>
          </Grid>
        </Grid>
      </Grid>

      <Box mb={4}>
        <ListComponent
          hideTypeControls={gridOnly}
          filter={filterValue}
          advancedFilter={advancedFilter}
          rowsPerPageList={rowsPerPageList}
          listType={listType}
          onListTypeChange={setListType}
        />
      </Box>
    </ContentContainer>
  )
}

export default wrapper(Search, { namespace: 'SEARCH' })
