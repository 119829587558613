import React, { useContext } from 'react'
import styled from 'styled-components'

import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles'

import { fontFamily } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const useStyles = makeStyles({
  baseInput: {
    background: '#f2f2f2',
    borderRadius: '2px!important',
    fontSize: '14px',
    '&:hover': {
      background: '#fafafa'
      // border: '1px solid #cccccc'
    },
    '&.$Mui-disabled': {
      backgroundColor: '#dadada!important',
      color: '#999999!important'
    }
  },
  baseButton: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#7fcdbf',
    color: '#ffffff',
    minwidth: '80px!important',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#7fcdbf'
    }
  },
  baseSelectFieldRequired: {
    '&:before': {
      content: `'*'`,
      position: 'absolute',
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      left: '-11px',
      top: '9px',
      fontSize: '25px'
    }
  },
  spanError: {
    color: '#eb5757!important'
  },
  baseButtonAlternative: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#ffffff',
    color: '#000000',
    minwidth: '80px!important',
    border: '1px solid #7fcdbf',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#7fcdbf'
    }
  },
  baseText: {
    fontSize: '14px',
    fontWeight: 400
  },
  h3: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: 'normal'
  },
  label: {
    display: 'block',
    paddingBottom: '0.4rem',
    fontSize: '14px',
    fontWeight: 600
  },
  selectDropdownList: {
    backgroundColor: '#f2f2f2',
    padding: '0 !important'
  },
  selectDropdownItem: {
    backgroundColor: '#f2f2f2',
    '&:hover': {
      backgroundColor: '#fafafa'
    }
  }
})

const theme = createMuiTheme({
  fontFamily: fontFamily,
  fontFeatureSettings: 'lnum',
  typography: {
    fontFamily: fontFamily,
    fontFeatureSettings: 'lnum'
  },

  overrides: {
    MuiFilledInput: {
      input: {
        paddingTop: '10px!important',
        paddingBottom: '10px!important'
      },
      root: {
        borderTopLeftRadius: '0px!important',
        borderTopRightRadius: '0px!important',
        '&$error': {
          border: '1px solid #eb5757'
        }
      }
    },
    MuiButton: {
      root: {
        width: 'auto!important'
      }
    }
  }
})

const Option = styled(MenuItem)`
  background-color: #f2f2f2;
  cursor: pointer;
  padding: 5px;
  transition: all 0.4s ease-in-out;

  &:hover {
    background-color: #fafafa;
  }
`

export function BaseSelect(props) {
  const {
    id,
    disabled,
    required,
    label,
    errors,
    register,
    name,
    options,
    defaultValue,
    translateOptions,
    descriptionKey,
    idKey,
    onChange,
    value
  } = props
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  const translateFn = translateOptions ? translate : name => name

  const dropdownListStyle = {
    classes: {
      paper: classes.selectDropdownItem,
      list: classes.selectDropdownList
    }
  }

  return (
    <>
      <label className={classes.label}>{label}</label>
      <ThemeProvider theme={theme}>
        <FormControl
          variant="filled"
          size="small"
          fullWidth
          className={required ? classes.baseSelectFieldRequired : ''}
        >
          <Select
            id={id}
            disabled={disabled}
            className={classes.baseInput}
            error={errors ? (errors[name] ? true : false) : false}
            disableUnderline
            name={name}
            inputRef={register}
            onChange={onChange}
            MenuProps={dropdownListStyle}
            value={value}
            displayEmpty
          >
            <Option value="">{defaultValue}</Option>
            {options.map(obj => (
              <Option key={obj[idKey || 'id']} value={obj[idKey || 'id']}>
                {translateFn(obj[descriptionKey || 'name'])}
              </Option>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </>
  )
}

export default BaseSelect
