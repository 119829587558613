import React, { useContext, useState } from 'react'

import NotificationService from 'api/NotificationService'
import { AuthContext } from 'context/AuthContext'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'

import { notificationUseCases } from 'views/Notification/providers'

import { GenericNotification } from './GenericNotification'
import { LinkStyledAccept, LinkStyledReject } from './style'

const InviteLaboratoryCmp = ({ notification, itemClicked, ...rest }) => {
  const { translate } = useContext(GlobalizationContext)

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [dialogText, setDialogText] = useState('')
  const [action, setAction] = useState()

  const { refreshCurrentUserData } = useContext(AuthContext)

  const acceptNotification = () => {
    setDialogText('REGISTER_INSTITUTION_ACCEPT_INVITE')
    setAction('acceptNotification')
    setOpenConfirmDialog(true)
  }

  const rejectNotification = () => {
    setDialogText('REGISTER_INSTITUTION_REFUSE_INVITE')
    setAction('rejectNotification')
    setOpenConfirmDialog(true)
  }

  const handleNotificationAction = async actionType => {
    await NotificationService[actionType](notification.id)
    await refreshCurrentUserData()
    itemClicked()
  }

  const handleConfirm = () => {
    handleNotificationAction(action)
    setOpenConfirmDialog(false)
    notificationUseCases.newSuccess('DEFAULT_SUCCESS')
  }

  return (
    <GenericNotification
      titleKey="INVITE_LABORATORY_TITLE"
      messageKey="INVITE_LABORATORY"
      notification={{
        author: `${notification.author}`,
        laboratory: `${notification.laboratory.initials} - ${notification.laboratory.name}`
      }}
      {...rest}
    >
      {notification.actions.indexOf('REJECT') >= 0 ? (
        <LinkStyledReject
          onClick={event => rejectNotification(event)}
          key={`reject` + notification.id}
        >
          {translate('NOTIFICATION_REJECT')}
        </LinkStyledReject>
      ) : null}

      {notification.actions.indexOf('ACCEPT') >= 0 ? (
        <LinkStyledAccept
          onClick={event => acceptNotification(event)}
          key={`accept` + notification.id}
        >
          {translate('NOTIFICATION_ACCEPT')}
        </LinkStyledAccept>
      ) : null}

      <DialogConfirm
        dialogText={dialogText}
        open={openConfirmDialog}
        onConfirm={handleConfirm}
        onCancel={() => setOpenConfirmDialog(false)}
      />
    </GenericNotification>
  )
}

const InviteLaboratory = wrapper(InviteLaboratoryCmp, {
  namespace: 'NOTIFICATIONS'
})

export { InviteLaboratory }
