import React, { useContext } from 'react'
import styled from 'styled-components'

import { Icon, IconButton } from '@material-ui/core'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Marker, MarkerGreen, MarkerOrange, MarkerRed } from 'ui/atoms/Marker'
import { TableRow, TableCell } from 'ui/atoms/Table'

const getMarkerColor = situation => {
  switch(situation){
    case 'WAITING_APPROVAL':
    case 'WAITING_EDIT_APPROVAL':
      return MarkerOrange
    case 'WAITING_PUBLICATION':
    case 'PUBLISHED':
      return MarkerGreen
    case 'UNPUBLISHED':
    case 'REJECTED':
      return MarkerRed
    default:
  }
}

const LabMarker = styled(Marker)`
  ${props => getMarkerColor(props.type)}
`

function Row(props) {
  const { item, onRowAction: handleRowAction } = props
  const { translate } = useContext(GlobalizationContext)

  return (
    <TableRow>
      <TableCell>
        <LabMarker type={item.situation} />
      </TableCell>
      <TableCell>{`${item.initials} - ${item.name}`}</TableCell>
      <TableCell>{`${item.institutionInitials} - ${item.institutionSocialReason}`}</TableCell>
      <TableCell>
        {translate(`LABORATORY:SITUATION.${item.situation}`)}
      </TableCell>
      <TableCell>{item.locality}</TableCell>
      <TableCell>{`${item.activeResponsible}/${item.totalResponsible}`}</TableCell>
      <TableCell style={{ width: '10px' }}>
        <IconButton onClick={() => handleRowAction(item)}>
          <Icon className="fas fa-cog" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
export default Row
