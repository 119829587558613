// @ts-nocheck
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

import BaseButton from 'ui/atoms/BasePrivate/BaseButton'
import DocumentUploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import {
  SliderContainer,
  NextArrow,
  PrevArrow,
  CarrouselItem,
  CustomSlider,
  CloseButton
} from '../../styles'
import ImageCrop from './ImageCrop'

const UploadImageModal = props => {
  const {
    images,
    open: isOpen,
    onClose: handleClose,
    onImagesChanged,
    modalTitle,
    filesConfig,
    setEditedImageList
  } = props

  const { translate } = useContext(GlobalizationContext)
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageList, setImageList] = useState([])
  const [deletedImagesList, setDeletedImagesList] = useState([])
  const sliderRef = useRef()

  useEffect(() => {
    setImageList(images || [])
    setSelectedImage(images[0])
  }, [images])

  useEffect(() => {
    if (isOpen) {
      setDeletedImagesList([])
    }
  }, [isOpen])

  const handleOnFileSelected = fileList => {
    const image = {
      cropImage: fileList[0].file,
      image: fileList[0].file,
      positionX: 0,
      positionY: 0
    }

    const newImageList = imageList.concat(image)
    setImageList(newImageList)
    setSelectedImage(image)
  }

  const handleOnRemoveFile = imgObj => {
    if (imgObj.image && imgObj.image.id) {
      setDeletedImagesList(deletedImagesList.concat(imgObj.image.id))
    }

    const remaningFiles = imageList.filter(item => {
      return item !== imgObj
    })

    setImageList(remaningFiles)

    if (imgObj === selectedImage) {
      setSelectedImage(remaningFiles.length === 0 ? null : imageList[0])
    }
  }

  const handleSave = () => {
    setEditedImageList(imageList)
    onImagesChanged(imageList, deletedImagesList)
  }

  const handleCancelAction = () => {
    setDeletedImagesList([])
    handleClose()
  }

  const getImageSrc = image => {
    if (!image) return
    if (image.image.id) return `/${image.image.path}`
    return URL.createObjectURL(image.image)
  }

  const getImagePosition = () => {
    return {
      x: selectedImage?.positionX,
      y: selectedImage?.positionY
    }
  }

  const updateCroppedImage = useCallback(
    async (crop, image) => {
      const imageFile = new File(
        [image],
        selectedImage.image.fileName
          ? selectedImage.image.fileName
          : selectedImage.image.name,
        {
          type: 'image/png'
        }
      )

      const index = imageList.findIndex(image => {
        return image.image.name === selectedImage.image.name
      })
      const newImageList = [...imageList]
      newImageList[index] = {
        ...selectedImage,
        cropImage: imageFile,
        positionX: crop.x,
        positionY: crop.y
      }

      setImageList(newImageList)
      setSelectedImage({
        ...selectedImage,
        cropImage: imageFile,
        positionX: crop.x,
        positionY: crop.y
      })
    },
    [imageList, selectedImage]
  )

  const selectImage = imgObj => {
    const index = imageList.findIndex(image => {
      if (image.image.fileName)
        return image.image.fileName === imgObj.image.fileName
      return image.image.name === imgObj.image.name
    })
    setSelectedImage(imageList[index])
  }

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    adaptiveHeight: false,
    centerPadding: 0,
    arrows: false,
    infinite: imageList.length > 3
  }

  const renderSlider = () => (
    <SliderContainer>
      <CustomSlider {...settings} ref={sliderRef}>
        {imageList.map((image, index) => (
          <CarrouselItem key={index}>
            <div style={{ position: 'relative' }}>
              <img
                src={getImageSrc(image)}
                onClick={() => selectImage(image)}
              />
              <CloseButton onClick={() => handleOnRemoveFile(image)}>
                x
              </CloseButton>
            </div>
          </CarrouselItem>
        ))}
      </CustomSlider>

      <NextArrow onClick={() => sliderRef.current.slickNext()} />
      <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
    </SliderContainer>
  )

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      open={isOpen}
      onClose={handleCancelAction}
    >
      <DialogTitle>{translate(modalTitle)}</DialogTitle>
      <ImageCrop
        image={getImageSrc(selectedImage)}
        imagePosition={getImagePosition()}
        updateCroppedImage={updateCroppedImage}
      />
      <div style={{ width: '550px', margin: '0 auto' }}>
        {imageList.length !== 0 && renderSlider()}
        <DocumentUploader
          wrapperDescription="Clique ou arraste para adicionar foto"
          onChange={handleOnFileSelected}
          currentListLength={imageList.length}
          filesConfig={filesConfig}
        />
      </div>
      <DialogActions style={{ padding: '8px 24px 24px 24px' }}>
        <BaseButton
          type="button"
          label="FILE_UPLOAD_CANCEL_BUTTON"
          onClick={handleCancelAction}
        />
        <BaseButton
          type="button"
          label="FILE_UPLOAD_UPDATE_BUTTON"
          onClick={handleSave}
        />
      </DialogActions>
    </Dialog>
  )
}

export default UploadImageModal
