import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Controller } from 'react-hook-form'

import { Container, ErrorsContainer } from './style'

const Component = ({ onBlur, onChange, reference }) => (
  <ReCAPTCHA
    ref={reference}
    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
    onChange={token => {
      onChange(token)
      onBlur(token)
    }}
    hl="pt-BR"
  />
)

const ReCAPTCHAInput = ({ control, errors, recaptchaRef }) => {
  return (
    <Container>
      <Controller
        control={control}
        name="recaptcha"
        as={<Component />}
        reference={recaptchaRef}
        defaultValue=""
      />

      <ErrorsContainer>
        {errors.recaptcha ? errors.recaptcha.message : ''}
      </ErrorsContainer>
    </Container>
  )
}

export default ReCAPTCHAInput
