import endOfToday from 'date-fns/endOfToday'
import * as Yup from 'yup'

export default Yup.object().shape({
  wasShared: Yup.boolean().required('VALIDATION:REQUIRED_ERROR'),
  sharedDateFrom: Yup.date()
    .nullable()
    .typeError('VALIDATION:INVALID_DATE')
    .when(['wasShared'], (wasShared, schema) => {
      return wasShared
        ? schema.required('VALIDATION:REQUIRED_ERROR')
        : schema.notRequired()
    })
    .max(endOfToday(), 'VALIDATION:LOWER_OR_EQUAL_THAN_TODAY_ERROR'),
  sharedDateTo: Yup.date()
    .nullable()
    .typeError('VALIDATION:INVALID_DATE')
    .when(['wasShared'], (wasShared, schema) => {
      return wasShared
        ? schema
            .required('VALIDATION:REQUIRED_ERROR')
            .greaterThan('sharedDateFrom')
        : schema.notRequired()
    })
    .max(endOfToday(), 'VALIDATION:LOWER_OR_EQUAL_THAN_TODAY_ERROR')
})
