import * as Yup from 'yup'

Yup.addMethod(Yup.string, "password", function(message='Senha com formato inválido') {
    
    return this.test("password", message,    
    function(password) {

        const { path, createError } = this;

        const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])[A-Za-z\d@$!%*#?&]{0,}$/g;
        const str = password;
        let matches;
        
        while ((matches = regex.exec(str)) !== null) {
            if (matches.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            return true
        }          
        return createError({ path, message })           
  })
})

Yup.addMethod(Yup.string, "passwordNotEqualEmailName", function(message='Senha não pode conter Email ou seu Nome', val) {
    
    return this.test("passwordNotEqualEmailName", message,    
    function(password) {

        const { path, createError } = this;

        if( password.includes(val.firstName) || password.includes(val.lastName) || password.includes(val.email) ){
            return createError({ path, message })  
        }
        else{
            return true
        }                
  })
})

Yup.addMethod(Yup.string, "passwordNotEqual", function(message='As senhas não conferem', objPassword) {
    
    return this.test("passwordNotEqual", message,    
    function() {        
        const { path, createError } = this;

        if( objPassword.password_repeat !== objPassword.password ){
            return createError({ path, message })  
        }
        else{
            return true
        }                 
  })
})