const checkKeyDown = e => {
  if (isEnter(e) && e.target.type !== 'textarea') {
    e.preventDefault()
  }
}

export const isEnter = e => {
  const keyCode = e.keyCode ? e.keyCode : e.which
  return keyCode === 13
}

export const disableSubmitOnEnter = { onKeyDown: checkKeyDown }
