import clsx from 'clsx'
import React, { useCallback, useContext, useState } from 'react'

import { Grid } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { textToConstant } from 'helpers/textToConstant'

import { TableChip } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import {
  WrapEmail,
  WrapContent
} from 'views/BackOffice/Subviews/Admin/Subviews/InstitutionEdit/styles'

import Trash from 'assets/icons/trash.svg'

import { TrashIcon } from './styles'

const TableRowComponent = ({ disabled, row, index, updateRepresentant }) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const [permissionValue, setPermissionValue] = useState(row.permission)
  const [situationValue, setSituationValue] = useState(row.situation)

  const onChangePermission = event => {
    const newPermission = event.target.value
    setPermissionValue(newPermission)

    updateRepresentant(oldUserList => {
      const rowCopy = { ...oldUserList[index], permission: newPermission }
      const listCopy = [...oldUserList]
      listCopy[index] = rowCopy
      return listCopy
    })
  }

  const onChangeSituation = event => {
    const newSituation = event.target.value
    setSituationValue(newSituation)

    updateRepresentant(oldUserList => {
      const rowCopy = { ...oldUserList[index], situation: newSituation }
      const listCopy = [...oldUserList]
      listCopy[index] = rowCopy
      return listCopy
    })
  }

  const remove = useCallback(
    item => {
      if (item.id) {
        updateRepresentant(oldList => {
          const newList = [...oldList]
          const itemIndex = newList.findIndex(obj => obj.id === item.id)
          newList[itemIndex] = {
            ...newList[itemIndex],
            deleted: true
          }
          return newList
        })
      } else {
        updateRepresentant(oldList =>
          oldList.filter(obj => obj.email !== item.email)
        )
      }
    },
    [updateRepresentant]
  )

  const canEditRow = () => {
    return (
      !row.userInactive &&
      !row.deleted &&
      ['PENDING', 'REJECTED', 'DELETED'].indexOf(row.situation) === -1 &&
      !disabled &&
      !row.responsible
    )
  }

  const renderEmail = () => {
    return (
      <>
        {row.situation !== 'REJECTED' && (
          <div className={classes['statusCircle' + row.situation]}></div>
        )}
        <WrapContent>
          <WrapEmail title={row.email}>{row.email}</WrapEmail>
          {row.userInactive && (
            <TableChip
              size="small"
              label={translate('REPRESENTATIVE_LIST.INACTIVE')}
            />
          )}
        </WrapContent>
      </>
    )
  }

  const renderPermission = () => {
    return (
      <div
        style={{
          paddingRight: '15px'
        }}
      >
        <Select
          name="permission"
          disableUnderline={true}
          disabled={!canEditRow()}
          fullWidth
          native
          value={permissionValue}
          onChange={event => onChangePermission(event)}
        >
          {[
            'ROLE_INSTITUICAO_TOTAL',
            'ROLE_INSTITUICAO_PARCIAL',
            'ROLE_INSTITUICAO_LIMITADA'
          ].map(item => (
            <option key={item} value={item}>
              {translate(`USER:ROLE_SHORT.${item}`)}
            </option>
          ))}
        </Select>
      </div>
    )
  }

  const renderSituation = () => {
    return (
      <Grid container>
        <Select
          name="situation"
          disableUnderline={true}
          fullWidth
          disabled={!canEditRow()}
          native
          value={situationValue}
          onChange={event => onChangeSituation(event)}
        >
          <option value="ACTIVE">
            {translate('LABORATORY:SITUATION.ACTIVE')}
          </option>
          <option value="INACTIVE">
            {translate('LABORATORY:SITUATION.INACTIVE')}
          </option>
          {row.deleted || row.situation === 'DELETED' ? (
            <option value="DELETED">
              {translate('LABORATORY:SITUATION.DELETED')}
            </option>
          ) : null}
          {row.situation === 'PENDING' ? (
            <option value="PENDING">
              {translate('LABORATORY:SITUATION.PENDING')}
            </option>
          ) : null}
          {row.situation === 'REJECTED' ? (
            <option value="REJECTED">
              {translate('LABORATORY:SITUATION.REJECTED')}
            </option>
          ) : null}
        </Select>
      </Grid>
    )
  }

  const renderRemove = () => {
    return (
      <TrashIcon
        src={Trash}
        alt={'Remover'}
        onClick={() => (!row.responsible ? remove(row) : null)}
        className={clsx({
          ['disabled']: row.responsible
        })}
      />
    )
  }

  return (
    <TableRow
      id={`LABORATORY_REPRESENTATIVE_${textToConstant(row.email)}`}
      className={clsx({
        [classes.disableRow]: !canEditRow()
      })}
    >
      <TableCell align="center">{index + 1}</TableCell>
      <TableCell padding={'default'} align="left">
        {renderEmail()}
      </TableCell>
      <TableCell align="left">{row.name || row.firstName}</TableCell>
      <TableCell align="center">{renderPermission()}</TableCell>
      <TableCell>{renderSituation()}</TableCell>
      <TableCell align="center" style={{ width: '20px' }}>
        {renderRemove()}
      </TableCell>
    </TableRow>
  )
}

export default TableRowComponent
