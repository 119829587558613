import range from 'lodash/range'
import React, { useEffect, useContext } from 'react'
import { Trans } from 'react-i18next'

import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import SubTitle from 'ui/atoms/BasePublic/SubTitle'
import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'

import { Accordion, AccordionDetails, AccordionSummary } from './styles'

function FAQ() {
  const [expanded, setExpanded] = React.useState(false)
  const { translate } = useContext(GlobalizationContext)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ContentContainer>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Title variant="h1" item xs={12} $paddingTop>
          {translate('FAQ')}
        </Title>
        <Grid item xs={12} md={12}>
          {range(1, 16).map(item => (
            <Accordion
              key={item}
              expanded={expanded === `panel${item}`}
              onChange={handleChange(`panel${item}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <SubTitle content={`PUBLIC_AREA_QUESTION_${item}`} />
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Typography component="div" variant="body2">
                    <Trans
                      t={translate}
                      i18nKey={`PUBLIC_AREA_ANSWER_${item}`}
                    />
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </ContentContainer>
  )
}

export default FAQ
