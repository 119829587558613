import * as Yup from 'yup'

export const hasCustomType = labTypeList =>
  labTypeList && labTypeList.findIndex(labType => labType.custom) >= 0

export const clearCnpj = cnpj => Yup.string().digitsOnly().cast(cnpj)

export const LaboratorySchema = Yup.object().shape({
  // @ts-ignore
  socialReason: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  initials: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  name: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  about: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  zipCode: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .test('len', 'VALIDATION:ZIPCODE_INVALID', val => val.length === 9)
    .trim(),
  street: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  number: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  complement: Yup.string().trim(),
  district: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  city: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  state: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  region: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),

  responsibleId: Yup.string(),
  responsibleCpf: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .cpf('VALIDATION:CPF_ERROR'),
  responsibleFirstName: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .trim(),
  responsibleLastName: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .trim(),
  responsibleEmail: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  responsiblePhone: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  responsibleLattes: Yup.string().trim(),

  typeLaboratoryList: Yup.array().required('VALIDATION:REQUIRED_ERROR'),
  laboratoryCustomType: Yup.string().when('typeLaboratoryList', {
    is: labTypes => labTypes && hasCustomType(labTypes),
    then: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
    otherwise: Yup.string().trim()
  }),
  techniqueList: Yup.array().required('VALIDATION:REQUIRED_ERROR'),
  areasExpertiseList: Yup.array().required('VALIDATION:REQUIRED_ERROR'),
  termsOfUse: Yup.boolean().oneOf([true], 'VALIDATION:REQUIRED_ERROR'),

  isApproving: Yup.boolean(),

  requestOpinionUnitQuestion: Yup.string().when('isApproving', {
    is: true,
    then: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
    otherwise: Yup.string().trim()
  }),
  requestOpinionResearcherQuestion: Yup.string().when('isApproving', {
    is: true,
    then: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
    otherwise: Yup.string().trim()
  }),
  requestOpinionRefusalReasons: Yup.string().when(
    ['requestOpinionUnitQuestion', 'requestOpinionResearcherQuestion'],
    {
      is: (requestOpinionUnitQuestion, requestOpinionResearcherQuestion) =>
        requestOpinionUnitQuestion == 'false' ||
        requestOpinionResearcherQuestion == 'false',
      then: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
      otherwise: Yup.string().trim()
    }
  ),
  requestOpinionComplement: Yup.string().trim(),
  isApprovingEdit: Yup.boolean(),

  requestAdjustmentOpinionUnitQuestion: Yup.string().when('isApprovingEdit', {
    is: true,
    then: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
    otherwise: Yup.string().trim()
  }),
  requestAdjustmentOpinionComplement: Yup.string().when(
    ['requestAdjustmentOpinionUnitQuestion'],
    {
      is: requestAdjustmentOpinionUnitQuestion =>
        requestAdjustmentOpinionUnitQuestion == 'false',
      then: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
      otherwise: Yup.string().trim()
    }
  )
})
