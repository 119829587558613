import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Button, Grid, Icon, Typography } from '@material-ui/core'

import { generatePrivatePath } from 'helpers'

import { GlobalizationContext } from 'ui/atoms//Globalization'
import ContentContainer from 'ui/atoms/ContentContainer'
import { Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'

import NewTabLink from '../NewTabLink'

const WrapperFooter = styled.div`
  width: 100%;
  background-color: ${props => props.theme.palette.grey[200]};
`

const RequestSharing = ({ type, item }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <WrapperFooter>
      <ContentContainer>
        <Grid container spacing={3} style={{ paddingBottom: '30px' }}>
          <Title item xs={12} $noBorder $noPaddingBottom $paddingTop>
            {translate('TITLE')}
          </Title>
          <Grid item xs={12}>
            <Typography variant="body2">
              {translate(
                item.sharingType === 'PLATFORM_SHARING'
                  ? 'DESCRIPTION'
                  : 'DESCRIPTION_OWN_SHARING'
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {item.sharingType === 'PLATFORM_SHARING' ? (
              <Button
                component={Link}
                variant="contained"
                color="primary"
                to={generatePrivatePath(REQUEST_SHARING_ROUTES.NEW_WITH_LAB, {
                  pathParams: {
                    type,
                    id: item.id
                  }
                })}
              >
                {translate('ACTION')}
              </Button>
            ) : (
              <Button
                component={NewTabLink}
                href={item.ownShareLink}
                variant="contained"
                color="primary"
                endIcon={
                  <Icon
                    className="fas fa-external-link-alt"
                    style={{
                      fontSize: '15px',
                      marginLeft: '10px',
                      marginTop: '-2px'
                    }}
                  />
                }
              >
                {translate('ACTION_OWN_SHARING')}
              </Button>
            )}
          </Grid>
        </Grid>
      </ContentContainer>
    </WrapperFooter>
  )
}

export default wrapper(RequestSharing, { namespace: 'REQUEST_SHARING_PUBLIC' })
