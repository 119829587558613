import React, { useEffect, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { conformToMask } from 'react-text-mask'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { AuthContext } from 'context/AuthContext'
import { CPF_MASK } from 'helpers/constants'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { history, generatePrivatePath } from 'helpers/history'
import { cpfMask } from 'helpers/maskedCPF'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import useStyles from 'ui/styles'

import { ADMIN_ROUTES } from 'views/BackOffice/Subviews/Admin/constants/routes'

import { editUserUseCases } from '../../providers'
import formSchema from './formSchema'
import UserPermissions from './Fragments/UserPermissions'
import UserRoles from './Fragments/UserRoles'

const UserData = ({ userData, onUserChanged }) => {
  const auth = useContext(AuthContext)
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {}
  })

  const { control, errors, reset, handleSubmit } = formMethods

  useEffect(() => {
    const { user, groupList } = userData || {}

    const formData = {
      ...user,
      hasRoleAdmin: groupList?.includes('ROLE_ADMIN') || false,
      hasRoleGestor: groupList?.includes('ROLE_GESTOR_ACOES') || false,
      cpf: conformToMask(user.cpf || '', CPF_MASK).conformedValue
    }

    reset(formData)
  }, [reset, userData])

  const onSubmit = async formData => {
    try {
      const updatedData = await editUserUseCases.updateUser(
        userData?.user.id_user,
        formData
      )
      onUserChanged({ ...userData, user: updatedData })
      if (userData?.user.id_user === auth.userData.userId) {
        history.push(generatePrivatePath(''))
        window.location.reload()
      }
    } catch (error) {
      const message = error.response?.data?.message
      if (['user.admin.delete.error'].indexOf(message) >= 0) {
        setShowConfirmationDialog(false)
      }
    }
  }

  const onCancel = () => {
    history.push(generatePrivatePath(ADMIN_ROUTES.USERS))
  }

  const onClickSave = formData => {
    if (
      (userData.groupList?.includes('ROLE_ADMIN') && !formData.hasRoleAdmin) ||
      (userData.groupList?.includes('ROLE_GESTOR_ACOES') &&
        !formData.hasRoleGestor)
    ) {
      setShowConfirmationDialog(true)
    } else {
      onSubmit(formData)
    }
  }

  return (
    <>
      <div className={classes.defaultContainer}>
        <div style={{ marginLeft: '15px' }}>
          <form onSubmit={handleSubmit(onClickSave)} {...disableSubmitOnEnter}>
            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              <Grid item xs={12} md={6}>
                <BaseTextField
                  disabled={false}
                  required={true}
                  label={translate('NAME')}
                  placeholder={translate('NAME')}
                  errors={errors}
                  control={control}
                  name="first_name"
                  maxLength={255}
                ></BaseTextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <BaseTextField
                  disabled={false}
                  label={translate('LAST_NAME')}
                  placeholder={translate('LAST_NAME')}
                  errors={errors}
                  control={control}
                  name="last_name"
                  required={true}
                  maxLength={255}
                ></BaseTextField>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              <Grid item xs={12} md={5}>
                <BaseTextField
                  disabled={false}
                  required={true}
                  label={translate('EMAIL')}
                  placeholder={translate('EMAIL')}
                  errors={errors}
                  control={control}
                  name="email"
                  maxLength={255}
                ></BaseTextField>
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              <Grid item xs={12} md={4}>
                <BaseTextField
                  required={true}
                  label={translate('CPF')}
                  placeholder={translate('CPF')}
                  errors={errors}
                  control={control}
                  name="cpf"
                  autoComplete="off"
                  mask={cpfMask}
                  disabled={true}
                ></BaseTextField>
              </Grid>
            </Grid>

            <UserRoles formMethods={formMethods} />

            <UserPermissions permissions={userData?.permissions || []} />

            <Grid
              container
              justify="flex-end"
              spacing={2}
              style={{ paddingTop: '4rem' }}
            >
              <Grid item xs={12} md={2}>
                <Button
                  className={classes.baseButtonGrey}
                  type={'button'}
                  variant="contained"
                  fullWidth
                  onClick={onCancel}
                >
                  {translate('COMMONS:CANCEL')}
                </Button>
              </Grid>

              <Grid item xs={12} md={2}>
                <Button
                  fullWidth
                  type={'submit'}
                  variant="contained"
                  className={classes.baseButton}
                >
                  {translate('COMMONS:SAVE')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <DialogConfirm
        dialogText={
          userData?.user.id_user === auth.userData.userId
            ? 'MESSAGES.CONFIRM_OWN_PERMISSION_EXCLUSION_DIALOG'
            : 'MESSAGES.CONFIRM_DIALOG'
        }
        open={showConfirmationDialog}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={() => setShowConfirmationDialog(false)}
      />
    </>
  )
}

export default UserData
