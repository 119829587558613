import { assign, compact } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import * as Yup from 'yup'

import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { LabEquipService } from 'api'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import REGION_LIST from 'helpers/regionList'
import STATE_LIST from 'helpers/stateList'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import Autocomplete from 'ui/atoms/Autocomplete'
import BaseMultipleSelect from 'ui/atoms/BaseMultipleSelect'
import ControlLabel from 'ui/atoms/ControlLabel'
import { Dialog, DialogActions, DialogContent } from 'ui/atoms/Dialog'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'
import DefaultHint from 'ui/molecules/commons/DefaultHint'

import FilterChipList from '../FilterChipList'

const FormSchema = Yup.object().shape({})

const defaultValues = {
  regionList: [],
  stateList: [],
  cityList: [],

  institutionList: [],

  labActivityTypeList: [],
  labExpertiseAreaList: [],
  labTechniquesUsed: [],
  sharing: false,

  equipTechniquesUsed: [],
  onlyMultiUser: false,
  otherDenominationList: []
}

const Filter = ({
  open,
  onClose: handleClose,
  filter,
  onFilter: handleFilterChanged
}) => {
  const { translate } = useContext(GlobalizationContext)

  const [pageParamsPromise] = useState(async () => {
    const availableStateList = await LabEquipService.getStateList()
    const labTypeList = await LabEquipService.getLabTypeList()

    const stateList = STATE_LIST.filter(state =>
      availableStateList.find(item => item.id === state.id)
    )

    const regionList = REGION_LIST.filter(region =>
      stateList.find(item => item.region === region.id)
    )

    return { regionList, stateList, labTypeList }
  })

  const resolver = useYupValidationResolver(FormSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {
      regionList: [],
      stateList: [],
      cityList: [],
      institutionList: [],

      labActivityTypeList: [],
      labExpertiseAreaList: [],
      labTechniquesUsed: [],

      equipTechniquesUsed: [],
      otherDenominationList: []
    }
  })

  const { control, setValue, errors, handleSubmit, reset } = formMethods

  useEffect(() => {
    if (open) {
      const mergedFilter = assign({}, defaultValues, filter)
      reset(mergedFilter)
    }
  }, [open, reset, filter])

  const getCity = useCallback(async ({ input }) => {
    try {
      return await LabEquipService.getCityList({ term: input })
    } catch (error) {
      console.log(error)
      return []
    }
  }, [])

  const getInstitution = useCallback(async ({ input }) => {
    try {
      return await LabEquipService.getInstitutionList({ term: input })
    } catch (error) {
      console.log(error)
      return []
    }
  }, [])

  const getLabAreaOfExpertise = useCallback(async ({ input }) => {
    try {
      return await LabEquipService.getLabAreaOfExpertise({ term: input })
    } catch (error) {
      console.log(error)
      return []
    }
  }, [])

  const getLabTechnique = useCallback(async ({ input }) => {
    try {
      return await LabEquipService.getLabTechnique({ term: input })
    } catch (error) {
      console.log(error)
      return []
    }
  }, [])

  const getEquipTechnique = useCallback(async ({ input }) => {
    try {
      return await LabEquipService.getEquipTechnique({ term: input })
    } catch (error) {
      console.log(error)
      return []
    }
  }, [])

  const getOtherDenomination = useCallback(async ({ input }) => {
    try {
      return await LabEquipService.getEquipOtherDenomination({ term: input })
    } catch (error) {
      console.log(error)
      return []
    }
  }, [])

  const checkById = (option, value) => {
    return option.id === value.id
  }

  const checkByCNPJ = (option, value) => {
    return option.cnpj === value.cnpj
  }

  const filteredValues = useWatch({
    control,
    name: [
      'regionList',
      'stateList',
      'cityList',
      'institutionList',
      'labActivityTypeList',
      'labExpertiseAreaList',
      'labTechniquesUsed',
      'equipTechniquesUsed',
      'otherDenominationList'
    ]
  })

  const handleFilterFieldChanged = ({ field, newValue }) => {
    setValue(field, newValue)
  }

  const getInstitutionLabel = useCallback(
    option =>
      typeof option === 'string'
        ? option
        : compact([option.initials || null, option.social_reason]).join(' - '),
    []
  )

  const handleCancel = () => {
    reset()
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <AsyncLoad promise={pageParamsPromise} minHeight={150}>
        {({ regionList, stateList, labTypeList }) => (
          <form
            onSubmit={handleSubmit(handleFilterChanged)}
            {...disableSubmitOnEnter}
          >
            <DialogContent>
              <Grid container spacing={3} style={{ marginBottom: 0 }}>
                <Title item xs={12}>
                  {translate('ADVANCED_SEARCH')}
                </Title>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {translate('ADVANCED_SEARCH_HELPER_TEXT')}
                  </Typography>
                </Grid>

                <Title item xs={12} variant="subtitle2">
                  {translate('FILTER_LOCATION')}
                </Title>
                <Grid item xs={12} sm={6} md={4}>
                  <BaseMultipleSelect
                    name="regionList"
                    label={translate('REGION')}
                    placeholder={translate('COMMONS:SELECT')}
                    translateOptions={region => translate(`REGIONS:${region}`)}
                    errors={errors}
                    options={regionList}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <BaseMultipleSelect
                    name="stateList"
                    label={translate('STATE')}
                    placeholder={translate('COMMONS:SELECT')}
                    errors={errors}
                    translateOptions={stateName =>
                      translate(`STATES:${stateName}`)
                    }
                    groupBy="region"
                    translateGroupBy={region =>
                      translate(
                        `REGIONS:${
                          REGION_LIST.find(
                            item => String(item.id) === String(region)
                          ).name
                        }`
                      )
                    }
                    options={stateList}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    control={control}
                    name="cityList"
                    label={translate('CITY')}
                    placeholder={translate('COMMONS:INFORM')}
                    minInputLength={3}
                    optionGetter={getCity}
                    multiple
                    keepInputOnSelect
                    clearOnClose
                    AutocompleteProps={{
                      renderTags: () => null,
                      getOptionLabel: option =>
                        typeof option === 'string' ? option : option?.value,
                      getOptionSelected: checkById,
                      disableCloseOnSelect: true,
                      blurOnSelect: false
                    }}
                  />
                </Grid>
                <FilterChipList
                  filter={{
                    regionList: filteredValues.regionList,
                    stateList: filteredValues.stateList,
                    cityList: filteredValues.cityList
                  }}
                  onFilterChanged={handleFilterFieldChanged}
                />
              </Grid>

              <Grid container spacing={3} style={{ marginBottom: 0 }}>
                <Title item xs={12} variant="subtitle2">
                  {translate('FILTER_INSTITUTION')}
                </Title>

                <Grid item xs={12} sm={8}>
                  <Autocomplete
                    control={control}
                    name="institutionList"
                    label={translate('INSTITUTION')}
                    placeholder={translate('COMMONS:INFORM')}
                    minInputLength={3}
                    optionGetter={getInstitution}
                    multiple
                    keepInputOnSelect
                    clearOnClose
                    AutocompleteProps={{
                      getOptionLabel: getInstitutionLabel,
                      renderTags: () => null,
                      getOptionSelected: checkByCNPJ,
                      disableCloseOnSelect: true,
                      blurOnSelect: false
                    }}
                  />
                </Grid>
                <FilterChipList
                  filter={{
                    institutionList: filteredValues.institutionList
                  }}
                  onFilterChanged={handleFilterFieldChanged}
                />
              </Grid>

              <Grid container spacing={3} style={{ marginBottom: 0 }}>
                <Title item xs={12} variant="subtitle2">
                  {translate('FILTER_LABORATORY')}
                </Title>

                <Grid item xs={12} sm={6} md={4}>
                  <BaseMultipleSelect
                    name="labActivityTypeList"
                    label={translate('LAB_ACTIVITY_TYPE_LIST')}
                    placeholder={translate('COMMONS:SELECT')}
                    // translateOptions={region => translate(`REGIONS:${region}`)}
                    idKey="id_type_laboratory"
                    nameKey="description"
                    errors={errors}
                    options={labTypeList}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    control={control}
                    name="labExpertiseAreaList"
                    label={translate('LAB_EXPERTISE_AREA_LIST')}
                    placeholder={translate('COMMONS:INFORM')}
                    minInputLength={1}
                    optionGetter={getLabAreaOfExpertise}
                    multiple
                    keepInputOnSelect
                    clearOnClose
                    AutocompleteProps={{
                      renderTags: () => null,
                      getOptionSelected: checkById,
                      disableCloseOnSelect: true,
                      blurOnSelect: false
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    control={control}
                    name="labTechniquesUsed"
                    label={translate('LAB_TECHNIQUES_USED')}
                    placeholder={translate('COMMONS:INFORM')}
                    minInputLength={1}
                    optionGetter={getLabTechnique}
                    multiple
                    keepInputOnSelect
                    clearOnClose
                    AutocompleteProps={{
                      renderTags: () => null,
                      getOptionSelected: checkById,
                      disableCloseOnSelect: true,
                      blurOnSelect: false
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                  }}
                >
                  <ControlLabel
                    type="Checkbox"
                    control={control}
                    name="sharing"
                    style={{
                      minHeight: '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography variant="body2">
                      {translate('ONLY_SHARING_USER')}
                    </Typography>
                  </ControlLabel>
                </Grid>
                <FilterChipList
                  filter={{
                    labActivityTypeList: filteredValues.labActivityTypeList,
                    labExpertiseAreaList: filteredValues.labExpertiseAreaList,
                    labTechniquesUsed: filteredValues.labTechniquesUsed
                  }}
                  onFilterChanged={handleFilterFieldChanged}
                />
              </Grid>

              <Grid container spacing={3} style={{ marginBottom: 0 }}>
                <Title item xs={12} variant="subtitle2">
                  {translate('FILTER_EQUIPMENT')}
                </Title>

                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    control={control}
                    name="equipTechniquesUsed"
                    label={translate('EQUIP_TECHNIQUES_USED')}
                    placeholder={translate('COMMONS:INFORM')}
                    minInputLength={1}
                    optionGetter={getEquipTechnique}
                    multiple
                    keepInputOnSelect
                    clearOnClose
                    AutocompleteProps={{
                      renderTags: () => null,
                      getOptionSelected: checkById,
                      disableCloseOnSelect: true,
                      blurOnSelect: false
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    control={control}
                    name="otherDenominationList"
                    label={translate('EQUIP_OTHER_DENOMITATION')}
                    placeholder={translate('COMMONS:INFORM')}
                    minInputLength={1}
                    optionGetter={getOtherDenomination}
                    multiple
                    keepInputOnSelect
                    clearOnClose
                    AutocompleteProps={{
                      renderTags: () => null,
                      disableCloseOnSelect: true,
                      blurOnSelect: false
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                  }}
                >
                  <ControlLabel
                    type="Checkbox"
                    control={control}
                    name="onlyMultiUser"
                    style={{
                      minHeight: '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }}
                  >
                    <Typography variant="body2">
                      {translate('ONLY_MULTI_USER')}
                    </Typography>
                  </ControlLabel>
                </Grid>
                <FilterChipList
                  filter={{
                    equipTechniquesUsed: filteredValues.equipTechniquesUsed,
                    otherDenominationList: filteredValues.otherDenominationList
                  }}
                  onFilterChanged={handleFilterFieldChanged}
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCancel}>
                {translate('COMMONS:CANCEL')}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {translate('APPLY')}
              </Button>
            </DialogActions>
          </form>
        )}
      </AsyncLoad>
    </Dialog>
  )
}

export default wrapper(Filter, { namespace: 'SEARCH_ADVANCED_FILTER' })
