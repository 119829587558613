import i18n from 'framework/i18n'

export default class ErrorTranslation {
  /**
   * @param {*} error
   * @returns {string|string[]}
   */
  async humanize(error, fallbackErrorMessage) {
    let errorMessage

    if (error?.errors) {
      const errors = error.errors

      errorMessage = await Promise.all(
        errors.map(error => this.getErrorDescription(error.defaultMessage))
      )
      if (errorMessage) return errorMessage
    }
    if (error?.message) {
      const messageKey = error.message.replaceAll('.', '_').toUpperCase()
      errorMessage = await this.getErrorDescription(messageKey)
      if (errorMessage != messageKey) return errorMessage
    }

    if (error?.code) {
      errorMessage = await this.getErrorDescription(error.code)
      if (errorMessage != error.code) return errorMessage
    }

    if (error?.status) {
      errorMessage = await this.getErrorDescription(error.status)
      if (errorMessage != error.status) return errorMessage
    }

    if (typeof error === 'string') {
      errorMessage = await this.getErrorDescription(error)
      return errorMessage
    }

    if (fallbackErrorMessage) {
      errorMessage = await this.getErrorDescription(fallbackErrorMessage)
      return errorMessage
    }

    return await this.getErrorDescription('DEFAULT_ERROR')
  }

  async getErrorDescription(key) {
    return await i18n.t(`ERRORS:${key}`)
  }
}
