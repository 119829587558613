import React, { useContext } from 'react'
import { Trans } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import {
  COVID_OTHER_DENOMINATIONS,
  COVID_PARAMETER_LIST
} from 'helpers/covidCampign'

import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'
import Wrapper from 'ui/atoms/Wrapper'
import useStyles from 'ui/styles'

import { StyledTableRow } from 'views/BackOffice/Subviews/Equipment/Fragments/Form/Fragments/EquipmentParameters'

import bannerImage_mobile from 'assets/images/covidCampaign_mobile.png'
import bannerImage from 'assets/images/covidCampaign.png'

import {
  TextBox,
  TextContainer,
  TextSubTitle,
  ContentBox,
  Hint,
  OtherDenominationInput
} from './style'

const CampaignCovid = () => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const theme = useTheme()
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <ContentContainer>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <img
            src={isNotMobile ? bannerImage : bannerImage_mobile}
            style={{ width: '100%' }}
          />
        </Grid>
        <Title item xs={12} $paddingTop>
          {translate('TITLE')}
        </Title>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <TextContainer item xs={12}>
          <TextSubTitle content="ABOUT_TITLE" />
          <TextBox>
            <Trans t={translate} i18nKey={'ABOUT'}></Trans>
          </TextBox>
        </TextContainer>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <TextContainer item xs={12}>
          <TextSubTitle content="WHANT_TO_HELP_TITLE" />
          <TextBox>
            <Trans t={translate} i18nKey={'WHANT_TO_HELP'}></Trans>
          </TextBox>
        </TextContainer>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <TextContainer item xs={12}>
          <TextSubTitle
            content="PROCEDURE_REGISTER_EQUIPMENT_TITLE"
            $small={true}
          />
          <ContentBox container item xs={12} spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                alignItems: 'baseline',
                display: 'flex',
                paddingBottom: 0
              }}
            >
              <label className={classes.label}>
                {translate('OTHER_DENOMINATION_LABEL')}
              </label>
              <Hint content="OTHER_DENOMINATION_HINT" />
            </Grid>
            <Grid item xs={12}>
              <OtherDenominationInput
                label="OTHER_DENOMINATION_LABEL"
                inputPlaceholder="OTHER_DENOMINATION_PLACEHOLDER"
                maxLimit={1}
                name="denominationList"
                onChange={() => {}}
                value={COVID_OTHER_DENOMINATIONS}
                disabled
              />
            </Grid>
          </ContentBox>
        </TextContainer>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <TextContainer item xs={12}>
          <TextSubTitle
            content="PROCEDURE_REGISTER_NEW_EQUIPMENT_TITLE"
            $small={true}
          />
          <ContentBox container item xs={12} spacing={3}>
            <Grid
              item
              xs={12}
              style={{
                alignItems: 'baseline',
                display: 'flex',
                paddingBottom: 0
              }}
            >
              <label className={classes.label}>{translate('PARAMETERS')}</label>
            </Grid>
            <Grid item xs={12}>
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        width: '2%',
                        paddingLeft: '15px',
                        paddingRight: '15px'
                      }}
                      align="center"
                    >
                      #
                    </TableCell>
                    <TableCell align="left">
                      {translate('REGISTER_EQUIPMENT_PARAMETERS_NAME')}
                    </TableCell>
                    <TableCell align="left" style={{ width: '90px' }}>
                      {translate('REGISTER_EQUIPMENT_PARAMETERS_VALUE')}
                    </TableCell>
                    <TableCell
                      align="left"
                      colSpan={2}
                      style={{ width: '90px' }}
                    >
                      {translate('REGISTER_EQUIPMENT_PARAMETERS_UNIT')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {COVID_PARAMETER_LIST.map((item, index) => (
                    <StyledTableRow key={item.name}>
                      <TableCell scope="row" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell scope="row" align="left">
                        {item.name}
                      </TableCell>
                      <TableCell scope="row" align="left">
                        {item.placeholderValue}
                      </TableCell>
                      <TableCell scope="row" align="left">
                        {item.unit}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </ContentBox>
        </TextContainer>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <TextContainer item xs={12}>
          <TextSubTitle
            content="SUBTITLE_TITLE"
            $small={true}
            style={{ paddingBottom: '16px' }}
          />
          <TextBox>
            <Trans t={translate} i18nKey={'SUBTITLE'}></Trans>
          </TextBox>
        </TextContainer>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <TextContainer item xs={12}>
          <TextSubTitle content="UNAVAILABLE_FREEZERS_TITLE" />
          <TextBox>
            <Trans t={translate} i18nKey={'UNAVAILABLE_FREEZERS'}></Trans>
          </TextBox>
        </TextContainer>
      </Grid>
    </ContentContainer>
  )
}

export default Wrapper(CampaignCovid, {
  namespace: 'CAMPAIGN_COVID'
})
