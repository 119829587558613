import React from 'react'

import Address from './Fragments/Address'
import Institution from './Fragments/Institution'
import Responsible from './Fragments/Responsible'

const InstitutionData = ({ data, representantsList, responsible }) => {
  return (
    <>
      <Institution data={data} />
      <Address data={data} />
      <Responsible
        responsible={responsible}
        representantsList={representantsList}
        responsibleDocuments={data?.documentList}
      />
    </>
  )
}

export default InstitutionData
