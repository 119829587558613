import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import MoreVert from '@material-ui/icons/MoreVert'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

const CustomListItem = styled(ListItem)`
  text-decoration: none;
  text-transform: none;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  align-self: flex-start;
  color: #333333;
`

const TableActions = ({
  actionList,
  handleActionSelected,
  disabled = false
}) => {
  const { translate } = useContext(GlobalizationContext)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const classes = useStyles()

  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleActionClick = (event, action) => {
    event.stopPropagation()
    setAnchorEl(null)
    handleActionSelected(action.code)
  }

  return (
    <>
      <IconButton
        name="ROW_ACTIONS"
        onClick={handleClick}
        className={classes.IconActionsList}
        disabled={disabled}
      >
        <MoreVert />
      </IconButton>
      {open && (
        <Popover
          id={id}
          open={true}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <List component="nav" aria-label="menu actions" name="ACTION_MENU">
            {actionList.map((action, index) => (
              <React.Fragment key={action.code}>
                <CustomListItem
                  component={!action.link ? Button : Link}
                  to={action.link}
                  onClick={
                    !action.link
                      ? event => handleActionClick(event, action)
                      : undefined
                  }
                  name={`ACTION_${action.code}`}
                >
                  <ListItemText primary={translate(action.name)} />
                </CustomListItem>
                {index < actionList.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Popover>
      )}
    </>
  )
}

export default TableActions
