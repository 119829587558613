import React from 'react'

import { ensureProtocol } from 'helpers/ensureProtocol'

const NewTabLink = ({ href, ...props }) => {
  const link = ensureProtocol(href)
  return <a target="_blank" rel="noreferrer" href={link} {...props} />
}

export default NewTabLink
