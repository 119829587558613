import React from 'react'

import Backdrop from '@material-ui/core/Backdrop'

import { primaryColor } from 'helpers/constants'

import AtomSpinner from 'ui/atoms/AtomSpinner'

const Loader = props => {
  return (
    <Backdrop {...props} style={{ zIndex: 9999999 }}>
      <AtomSpinner color={primaryColor} />
    </Backdrop>
  )
}

export default Loader
