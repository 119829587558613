import moment from 'moment'
import React, { useContext } from 'react'

import { Grid, Typography } from '@material-ui/core'

import { phone } from 'helpers/generalInputMasks'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const UsageData = ({ sharingRequest }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm="auto">
          <Typography variant="h4">
            {translate('DETAILS.EDIT_USAGE.EQUIPMENTS_AVAILABLE')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="body2">
            {sharingRequest.specificEquipment
              .map(item => `${item.code} - ${item.name}`)
              .join(', ')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm="auto">
          <Typography variant="h4">
            {translate('DETAILS.EDIT_USAGE.RESPONSIBLE')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="body2">
            {sharingRequest.responsibleName}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm="auto">
          <Typography variant="h4">
            {translate('DETAILS.EDIT_USAGE.EMAIL')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="body2">
            {sharingRequest.responsibleEmail}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm="auto">
          <Typography variant="h4">
            {translate('DETAILS.EDIT_USAGE.PHONE')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="body2">
            {phone(sharingRequest.responsibleTelephone || '')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12} sm="auto">
          <Typography variant="h4">
            {translate('DETAILS.EDIT_USAGE.SUGGESTED_DATE_LABEL')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="body2">
            {translate('DETAILS.EDIT_USAGE.SUGGESTED_DATE', {
              from: moment(sharingRequest.suggestedDateFrom).format(
                'DD/MM/YYYY'
              ),
              to: moment(sharingRequest.suggestedDateTo).format('DD/MM/YYYY')
            })}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UsageData
