import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import BaseButton from 'ui/atoms/BasePrivate/BaseButton'
import FilterSelect from 'ui/atoms/FilterSelect'
import { useGlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'

import BodyFilter from './Fragments/BodyFilter'
import CreateAdminModal from './Fragments/CreateAdminModal'
import List from './Fragments/List'
import { userListUseCase as userCase } from './providers'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

const rowsPerPageList = [10, 20, 50, 100]

const permissionList = [
  'ROLE_ADMIN',
  'ROLE_GESTOR_ACOES',
  'ROLE_INTEGRANTE',
  'ROLE_INSTITUICAO_LIMITADA',
  'ROLE_INSTITUICAO_PARCIAL',
  'ROLE_INSTITUICAO_TOTAL',
  'ROLE_LABORATORIO_LIMITADA',
  'ROLE_LABORATORIO_PARCIAL',
  'ROLE_LABORATORIO_TOTAL'
]

function UserList() {
  const { translate } = useGlobalizationContext()
  const [createAdminModalOpen, setCreateAdminModalOpen] = useState(false)
  const [userList, setUserList] = useState([])

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [count, setCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  const debounceTimeOut = useRef(null)
  const [filterValue, setFilterValue] = useState('')
  const [filterSituation, setFilterSituation] = useState('')
  const [emptyList, setEmptyList] = useState(null)
  const defaultDebounce = 500
  const [resetCount, setResetCount] = useState(0)

  const [filterPermission, setFilterPermission] = useState('')

  const classes = useStyles()

  const permissionOptionList = useMemo(
    () =>
      [
        {
          id: '',
          name: translate(`FILTER_ALL`)
        }
      ].concat(
        permissionList.map(item => ({
          id: item,
          name: translate(`USER:ROLE.${item}`)
        }))
      ),
    [translate]
  )

  const getUserList = useCallback(() => {
    if (debounceTimeOut.current) clearTimeout(debounceTimeOut.current)
    const newDebounceTimeOut = setTimeout(async function () {
      const filter = {
        ...(filterSituation ? { situation: filterSituation } : {}),
        ...(filterValue ? { search: filterValue } : {}),
        ...(filterPermission ? { permission: filterPermission } : {})
      }

      try {
        const params = { pageSize, pageNumber, ...filter }
        const { content, ...pagination } = await userCase.getAllUsers(params)

        setUserList(content)
        setCount(pagination.totalElements)
        if (pagination.empty) {
          setEmptyList(true)
        } else {
          setEmptyList(false)
        }
      } catch (error) {
        console.log(error)
      }
    }, defaultDebounce)
    debounceTimeOut.current = newDebounceTimeOut
  }, [filterPermission, filterSituation, filterValue, pageNumber, pageSize])

  const handleFilterChanged = event => {
    setPageNumber(0)
    setFilterPermission(event.target.value)
  }

  const handleSelectChange = event => {
    setPageNumber(0)
    setFilterSituation(event.target.value)
  }

  const handleInputChange = event => {
    setPageNumber(0)
    setFilterValue(event.target.value)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  const handleRowChange = () => {
    getUserList()
  }

  useEffect(() => {
    getUserList()
  }, [getUserList])

  const handleNewUserCreated = () => {
    setCreateAdminModalOpen(false)
    setResetCount(curr => curr + 1)
  }

  return (
    <>
      <CreateAdminModal
        open={createAdminModalOpen}
        onClose={() => setCreateAdminModalOpen(false)}
        onNewUserCreated={handleNewUserCreated}
      />
      <BodyFilter
        onSelectChange={handleSelectChange}
        onInputChange={handleInputChange}
      />
      <Grid container spacing={2} justify="space-between">
        <Grid item xs="auto">
          <BaseButton
            name="ADD_NEW_ADMIN"
            label={translate('ADD_NEW_ADMIN_BUTTON')}
            onClick={() => setCreateAdminModalOpen(true)}
          />
        </Grid>
        <Grid item xs="auto">
          <FilterSelect
            defaultValue=""
            label={translate('FILTER_PROFILE')}
            name="filterPermission"
            options={permissionOptionList}
            onChange={handleFilterChanged}
            value={filterPermission}
          />
        </Grid>
      </Grid>

      <List
        classes={classes}
        rowsPerPageOptions={rowsPerPageList}
        count={count}
        rowsPerPage={pageSize}
        page={pageNumber}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        userRowList={userList}
        emptyList={emptyList}
        onRowChange={handleRowChange}
      />
    </>
  )
}

export default wrapper(UserList, {
  mapState: null,
  mapDispatch: null,
  namespace: 'USER_LIST'
})
