import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { InstitutionService } from 'api/InstitutionService'
import { history, generatePrivatePath } from 'helpers/history'

import wrapper from 'ui/atoms/Wrapper'
import Loader from 'ui/molecules/Loader'

import PendingSolicitation from './Fragments/PendingSolicitation'
import RefusedSolicitation from './Fragments/RefusedSolicitation'

const InstitutionSolicitationView = () => {
  // @ts-ignore
  const { institutionId } = useParams()

  const [analysis, setAnalysis] = useState({})
  const [institutionData, setInstitutionData] = useState(null)
  const [represantativeList, setRepresantativeList] = useState([])
  const [responsible, setResponsible] = useState(null)

  const initData = useCallback(async () => {
    const institutionData = await InstitutionService.getInstitutionData(
      institutionId
    )

    setInstitutionData(institutionData?.institution)
    setRepresantativeList(institutionData?.representative_list)
    setResponsible(institutionData?.responsible)
  }, [institutionId])

  const checkAnalysis = useCallback(async () => {
    try {
      const response = await InstitutionService.getInstitutionAnalysis(
        institutionId
      )
      setAnalysis(response)
    } catch (error) {
      if (error.response?.data.message === 'institution.analysis.not.found') {
        setAnalysis({})
      }
    }

    initData()
  }, [initData, institutionId])

  useEffect(() => {
    checkAnalysis()
  }, [checkAnalysis])

  const redirectToInfraesctructurePage = () => {
    history.push(generatePrivatePath('/infraestructure'))
  }

  const isRefused = () => {
    const keyLength = Object.keys(analysis).length
    return !!keyLength
  }

  return (
    <>
      <Loader />

      {isRefused() ? (
        <RefusedSolicitation
          analysis={analysis}
          institutionData={institutionData}
          represantativeList={represantativeList}
          responsible={responsible}
        />
      ) : (
        <PendingSolicitation
          institutionData={institutionData}
          represantativeList={represantativeList}
          responsible={responsible}
          redirect={redirectToInfraesctructurePage}
        />
      )}
    </>
  )
}

export default wrapper(InstitutionSolicitationView, {
  mapState: null,
  mapDispatch: null,
  namespace: 'INSTITUTION_SOLICITATION_VIEW'
})
