import clsx from 'clsx'
import produce from 'immer'
import React, { useMemo, useRef } from 'react'
import { Controller } from 'react-hook-form'

import FormHelperText from '@material-ui/core/FormHelperText'
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import { COLORS, fontFamily } from 'helpers/constants'

import DefaultHint from 'ui/molecules/commons/DefaultHint'
import defaultTheme from 'ui/theme'

import FormControl from './FormControl'

const useStyles = makeStyles(() => ({
  errorDefault: {
    fontWeight: 600,
    color: COLORS.ERROR
  },
  baseInput: {
    background: '#f2f2f2',
    border: '1px solid #f2f2f2',
    borderRadius: '2px!important',
    fontSize: '14px',
    '&:hover': {
      background: '#fafafa'
      // border: '1px solid #cccccc'
    },
    '&.$Mui-disabled': {
      backgroundColor: '#dadada!important',
      color: '#999999!important'
    },
    '&.$MuiInputBase-multiline': {
      padding: '6px 12px'
    },
    '&.$MuiSelect-select': {
      padding: '6px 10px!important'
    }
  },
  baseTextField: {
    paddingTop: '10px!important'
  },
  baseTextFieldRequired: {
    '&:before': {
      // content: `'*'`,
      // position: 'absolute',
      // verticalAlign: 'middle',
      // display: 'flex',
      // alignItems: 'center',
      // left: '-11px',
      // top: '9px',
      // fontSize: '25px'

      fontFamily: 'Font Awesome 5 Free',
      fontWeight: 600,
      content: '\f069',
      position: 'absolute',
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      left: '-11px',
      fontSize: '5px',
      height: '100%'
    }
  },
  baseButton: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#7fcdbf',
    color: '#ffffff',
    minwidth: '80px!important',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#7fcdbf'
    }
  },
  spanError: {
    color: '#eb5757!important'
  },
  baseButtonAlternative: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#ffffff',
    color: '#000000',
    minwidth: '80px!important',
    border: '1px solid #7fcdbf',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#7fcdbf'
    }
  },
  baseText: {
    fontSize: '14px',
    fontWeight: 400
  },
  h3: {
    fontSize: '18px',
    fontWeight: 500,
    fontStyle: 'normal'
  },
  label: {
    color: '#333',
    marginBottom: '0.4rem',
    fontSize: '14px',
    fontWeight: 600,
    display: 'inline-block'
  }
}))

const theme = createMuiTheme(
  produce(defaultTheme, newTheme => {
    newTheme.overrides.MuiFilledInput = {
      input: {
        paddingTop: '10px!important',
        paddingBottom: '10px!important'
      },
      root: {
        borderTopLeftRadius: '0px!important',
        borderTopRightRadius: '0px!important',
        '&$error': {
          border: '1px solid #eb5757'
        }
      }
    }

    newTheme.overrides.MuiButton = {
      root: {
        width: 'auto!important'
      }
    }
  })
)

export function BaseTextField({
  className,
  type = 'text',
  rows,
  required,
  disabled,
  label,
  placeholder,
  errors,
  control,
  name,
  mask,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onInput,
  handleChange,
  handleInput,
  handleBlur,
  value,
  autoComplete,
  readOnly,
  maxLength,
  hint,
  endAdornment,
  defaultValue = '',
  InputProps = {},
  ...props
}) {
  const inputRef = useRef()
  const classes = useStyles()
  const hasErrors = errors && errors[name] ? true : false
  const inputProps = {
    className: hasErrors ? 'hasError' : null,
    autoComplete,
    readOnly,
    maxLength: maxLength,
    hint
  }

  const defaultProps = useMemo(() => {
    const {
      endAdornment: InputPropsEndAdornment,
      className: InputPropsClassName,
      ...otherInputProps
    } = InputProps
    return {
      rows: rows ? rows : 0,
      multiline: rows ? true : false,
      disabled,
      inputProps,
      InputProps: {
        ...otherInputProps,
        className: clsx(classes.baseInput, InputPropsClassName),
        disableUnderline: true,
        inputComponent: mask,
        endAdornment: InputPropsEndAdornment || endAdornment
      },
      error: hasErrors,
      placeholder,
      name,
      type,
      ...props
    }
  }, [
    InputProps,
    classes.baseInput,
    disabled,
    endAdornment,
    hasErrors,
    inputProps,
    mask,
    name,
    placeholder,
    props,
    rows,
    type
  ])
  return (
    <>
      <ThemeProvider theme={theme}>
        {label !== '' && <label className={classes.label}>{label}</label>}
        {hint && (
          <div style={{ display: 'inline-block', marginTop: '3px' }}>
            <DefaultHint content={inputProps.hint} />
          </div>
        )}
        <FormControl
          variant="filled"
          size="small"
          fullWidth
          className={className}
          $required={required}
        >
          {control ? (
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              onFocus={() => {
                inputRef.current && inputRef.current.focus()
              }}
              render={({ onChange, onBlur, value, ref }) => {
                return React.createElement(TextField, {
                  ...defaultProps,
                  variant: 'filled',
                  inputRef: newRef => {
                    ref.current = newRef
                    inputRef.current = newRef
                  },
                  value,
                  onBlur: evt => {
                    onBlur(evt)
                    handleBlur && handleBlur(evt)
                  },
                  onChange: evt => {
                    onChange(evt)
                    handleChange && handleChange(evt)
                  },
                  onInput: evt => {
                    if (handleInput)
                      evt.target.value = handleInput(evt.target.value)
                  }
                })
              }}
            />
          ) : (
            React.createElement(TextField, {
              ...defaultProps,
              variant: 'filled',
              value: value,
              onFocus: onFocus,
              onBlur: handleBlur || onBlur,
              onChange: handleChange || onChange,
              onInput: evt => {
                if (onInput) {
                  evt.target.value = onInput(evt.target.value)
                }
                if (handleInput) {
                  evt.target.value = handleInput(evt.target.value)
                }
              }
            })
          )}
        </FormControl>

        {errors && errors[name] && (
          <div className={name + '-error-container'}>
            <FormHelperText className={classes.errorDefault} error>
              {errors[name].message}
            </FormHelperText>
          </div>
        )}
      </ThemeProvider>
    </>
  )
}

export default BaseTextField
