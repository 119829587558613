import React, { useContext } from 'react'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'

import {
  BreadcrumbSeparator,
  BreadcrumbItem,
  BreadCrumbs,
  BreadcrumbItemLink
} from '../style'

const Breadcrumb = ({ match, item, breadcrumbData }) => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <BreadCrumbs separator={<BreadcrumbSeparator />}>
      <BreadcrumbItem>{translate('LABORATORY')}</BreadcrumbItem>
      {breadcrumbData?.nameLab ? (
        <BreadcrumbItemLink to={`/laboratory/${breadcrumbData.idLab}`}>
          {breadcrumbData.nameLab}
        </BreadcrumbItemLink>
      ) : null}
      <BreadcrumbItem>{translate('EQUIPMENT')}</BreadcrumbItem>
      {breadcrumbData?.name ? (
        <BreadcrumbItem>{breadcrumbData.name}</BreadcrumbItem>
      ) : null}
    </BreadCrumbs>
  )
}

const mapState = state => ({
  breadcrumbData: state.breadcrumbData.content
})

export default Wrapper(Breadcrumb, { mapState })
