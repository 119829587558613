// @ts-nocheck
import { throttle } from 'lodash'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import AdvertiseService from 'api/AdvertisePlatform'
import { TYPES } from 'helpers/constants'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'
import Loader from 'ui/molecules/Loader'

import { notificationUseCases } from 'views/Notification/providers'

import AdvertiseAgain from './Fragments/AdvertiseAgain'
import EmailTemplateModal from './Fragments/EmailTemplateModal'
import NewAdvertisePlatform from './Fragments/NewAdvertisePlatform'

const AdvertisePlatformSchema = Yup.object().shape({
  name: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  responsible_name: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  email: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .email('VALIDATION:EMAIL_ERROR')
})

function AdvertisePlatform() {
  const { translate } = useContext(GlobalizationContext)

  const [modalOpen, setModalOpen] = useState(false)
  const request = useRef(null)
  const [advertiseListSent, setAdvertiseListSent] = useState([])
  const [advertiseType, setAdvertiseType] = useState(TYPES.INSTITUTION)
  const [refreshList, setRefreshList] = useState(false)

  const [pageSize, setPageSize] = useState(10)
  const [filterValue, setFilterValue] = useState('')
  const [pageNumber, setPageNumber] = useState(0)
  const [loading, setLoading] = useState(false)

  const resolver = useYupValidationResolver(AdvertisePlatformSchema, {
    translate
  })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver
  })

  const { handleSubmit, reset, getValues } = formMethods

  const fetch = useMemo(
    () =>
      throttle(async (requestParams, callback) => {
        const response = await AdvertiseService.getFiltered(requestParams, {
          skipLoader: true
        })
        callback(response)
      }, 500),
    []
  )

  useEffect(() => {
    let active = true

    if (request.current?.cancel) {
      request.current.cancel()
    }

    setLoading(true)
    request.current = fetch(
      { term: filterValue, size: pageSize, page: pageNumber },
      results => {
        if (active) {
          request.current = null
          setAdvertiseListSent(results.data || {})
          setLoading(false)
        }
      }
    )

    return () => {
      active = false
    }
  }, [fetch, filterValue, pageNumber, pageSize, refreshList])

  const onSubmit = async () => {
    let objAdvertisePlatform = {
      name: getValues('name'),
      email: getValues('email'),
      responsibleName: getValues('responsible_name'),
      type: advertiseType
    }

    try {
      await AdvertiseService.canSend()
      await AdvertiseService.sendAdvertise(objAdvertisePlatform)

      notificationUseCases.newSuccess(translate('INVITE_SUCCESS'))

      reset({
        name: '',
        email: '',
        responsible_name: ''
      })
      setRefreshList(value => !value)
    } catch (error) {
      if (error.response.data.message === 'maximum.emails.exceeded.by.time') {
        notificationUseCases.newError(translate('MAX_INVITES_SEND'))
      } else if (error.response.data.message === 'email.already.sent') {
        notificationUseCases.newError(translate('EMAIL_ALREADY_SENT'))
      } else {
        notificationUseCases.newError(error, 'DISCLOSE_PLATFORM_DEFAULT_ERROR')
      }
    }
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = newPage => {
    setPageNumber(newPage)
  }

  const handleFilterChange = filter => {
    setFilterValue(filter)
  }

  const onTypeChange = event => {
    setAdvertiseType(event.target.value)
  }

  return (
    <>
      <Loader />
      <EmailTemplateModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <Grid container spacing={3} style={{ paddingBottom: '20px' }}>
        <Title item xs={12} $paddingTop>
          {translate('TITLE')}
        </Title>
        <Grid item xs={12} md={12}>
          <Typography variant="body2">
            {translate('SUBTITLE')}{' '}
            <Link
              variant="body2"
              component="button"
              onClick={() => setModalOpen(true)}
            >
              {translate('PREVIEW_EMAIL')}
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <NewAdvertisePlatform
          formMethods={formMethods}
          onTypeChange={onTypeChange}
          type={advertiseType}
        />
      </form>

      <AdvertiseAgain
        advertiseSentList={advertiseListSent.content}
        filterValue={filterValue}
        onFilterChange={handleFilterChange}
        rowsPerPageOptions={[10, 20, 50, 100]}
        count={advertiseListSent.totalElements}
        rowsPerPage={pageSize}
        page={pageNumber}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isEmpty={!advertiseListSent?.content?.length}
        isLoading={loading}
      />
    </>
  )
}
export default wrapper(AdvertisePlatform, {
  namespace: 'ADVERTISE_PLATFORM'
})
