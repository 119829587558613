import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import * as Yup from 'yup'

import { LaboratoryService, EquipmentService } from 'api'
import { fileUploadConfig } from 'helpers/constants'
import {
  COVID_OTHER_DENOMINATIONS,
  COVID_PARAMETER_LIST
} from 'helpers/covidCampign'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { history, generatePrivatePath } from 'helpers/history'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import {
  deleteFiles,
  updateImages,
  uploadImages
} from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import CarrousselUploader from 'ui/molecules/CarrousselUploader'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import { equipmentUseCases } from '../../providers'
import EquipmentAbout from './Fragments/EquipmentAbout'
import EquipmentActions from './Fragments/EquipmentActions'
import EquipmentData from './Fragments/EquipmentData'
import EquipmentParameters from './Fragments/EquipmentParameters'
import EquipmentResponsible from './Fragments/EquipmentResponsible'

const hasCustomType = (situation, situationList) => {
  return Boolean(
    situationList?.find(item => item.idReason === situation && item.custom)
  )
}

const EquipmentSchema = Yup.object().shape({
  laboratoryId: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  name: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  initials: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  techniqueList: Yup.array().required('VALIDATION:REQUIRED_ERROR'),
  brand: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  model: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  purchaseYear: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  multiuserIndex: Yup.number(),
  availabilityIndex: Yup.number(),
  about: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  situation: Yup.number().required('VALIDATION:REQUIRED_ERROR'),
  otherReason: Yup.string().when(['situation', '$situationList'], {
    is: (situation, situationList) => {
      return hasCustomType(situation, situationList)
    },
    then: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
    otherwise: Yup.string().trim()
  }),
  financingList: Yup.array().required('VALIDATION:REQUIRED_ERROR'),
  financingListCustom: Yup.array().of(
    Yup.object().shape({
      customDescription: Yup.string()
        .required('VALIDATION:REQUIRED_ERROR')
        .trim()
    })
  )
})

function RegisterEquipment({ equipment, covidFreezer }) {
  const isCovidFreezer = Boolean(covidFreezer)
  const { id: equipmentId, labId: labIdRedirect } = useParams()

  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const [financingOriginList, setFinancingOriginList] = useState([])
  const [laboratoryList, setLaboratoryList] = useState([])
  const [situationList, setSituationList] = useState([])
  const [selectedLaboratory, setSelectedLaboratory] = useState([])
  const [equipmentImageList, setEquipmentImageList] = useState([])
  const [equipmentCode, setEquipmentCode] = useState()
  const [editedImageList, setEditedImageList] = useState([])
  const [deletedEquipmentImageList, setDeletedEquipmentImageList] = useState([])

  const [headerText, setHeaderText] = useState('')

  const resolver = useYupValidationResolver(EquipmentSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {
      laboratoryId: labIdRedirect || '',
      situation: 0,
      denominationList: !isCovidFreezer ? [] : COVID_OTHER_DENOMINATIONS,
      parameterList: !isCovidFreezer ? [] : COVID_PARAMETER_LIST,
      financingList: [],
      financingListCustom: [],
      responsibleList: []
    },
    context: {
      situationList
    }
  })

  const { handleSubmit, watch, reset, setValue } = formMethods
  const laboratoryId = watch('laboratoryId')

  const loadEquipment = useCallback(
    financingOriginList => {
      const {
        yearPurchase,
        laboratory,
        financingList,
        denominationList,
        imageList,
        code,
        situation,
        idReason,
        customReason,
        ...equipmentData
      } = equipment

      equipmentData.otherReason = customReason
      equipmentData.situation = situation === 'ACTIVE' ? 0 : idReason
      equipmentData.laboratoryId = laboratory.id
      equipmentData.denominationList =
        denominationList.map(item => item.name) || []

      equipmentData.purchaseYear = yearPurchase
      setEquipmentCode(code)

      const financingListIds = financingList.map(item => item.id)
      const currFinancingList = financingOriginList.filter(
        item => financingListIds.indexOf(item.id) >= 0
      )

      equipmentData.financingListCustom = financingList.filter(
        item => item.custom
      )
      equipmentData.financingList = currFinancingList

      setEquipmentImageList(imageList)
      reset(equipmentData)

      setValue(
        'financingListCustom',
        financingList.filter(item => item.custom)
      )
    },
    [equipment, reset, setValue]
  )

  const loadLaboratoryList = useCallback(async () => {
    const { data } = await LaboratoryService.getAllMine()
    setLaboratoryList(data)
    return data
  }, [])

  const loadEquipmentSituationList = useCallback(async () => {
    const data = await equipmentUseCases.getListStates()
    setSituationList(data)
    return data
  }, [])

  const loadFinancingOriginList = useCallback(async () => {
    const { data } = await EquipmentService.getAllFinancingOrigin()
    setFinancingOriginList(data)
    return data
  }, [])

  const getLaboratorySelected = useCallback(async laboratoryId => {
    if (!laboratoryId) {
      setSelectedLaboratory({})
      return
    }

    const { data } = await LaboratoryService.getById(laboratoryId)
    setSelectedLaboratory(data)
    return data
  }, [])

  const initData = useCallback(async () => {
    const [financingOriginList] = await Promise.all([
      loadFinancingOriginList(),
      loadEquipmentSituationList(),
      loadLaboratoryList()
    ])

    if (equipment) {
      await loadEquipment(financingOriginList)
    }
  }, [
    equipment,
    loadEquipment,
    loadEquipmentSituationList,
    loadFinancingOriginList,
    loadLaboratoryList
  ])

  useEffect(() => {
    getLaboratorySelected(laboratoryId)
  }, [getLaboratorySelected, laboratoryId])

  useEffect(() => {
    initData()

    if (equipment) {
      setHeaderText('REGISTER_EQUIPMENT_EDIT_REQUEST')
    } else {
      setHeaderText('REGISTER_EQUIPMENT_INCLUSION_REQUEST')
    }
  }, [equipment, initData])

  const imageDeleteMethod = equipmentId => {
    return async imageId => {
      return await EquipmentService.removeEquipmentImage(equipmentId, imageId)
    }
  }

  const onSubmit = async formData => {
    if (!equipmentImageList.length) {
      notificationUseCases.newError('AT_LEAST_ONE_IMAGE_ERROR')
      return
    }

    const {
      responsibleList,
      financingList,
      financingListCustom,
      situation,
      otherReason,
      ...postData
    } = formData

    const currSituation = situationList.find(
      item => item.idReason === situation
    )

    if (currSituation.custom) {
      postData['customReason'] = otherReason
    }

    postData['idReason'] = currSituation.idReason || null
    postData['situation'] = currSituation.situation
    postData['responsibleIdList'] = (responsibleList || []).map(item => item.id)

    postData['financingList'] = !financingListCustom?.length
      ? financingList
      : financingList.map(item => {
          const customDesc =
            financingListCustom.find(
              customItem => Number(customItem.id) === Number(item.id)
            )?.customDescription || ''
          return {
            ...item,
            customDescription: customDesc
          }
        })

    let currEquipmentId

    try {
      if (!equipmentId) {
        const newEquipment = await EquipmentService.postEquipment(
          postData
        ).then(resp => resp.data)
        currEquipmentId = newEquipment.id
      } else {
        await EquipmentService.putEquipment(equipmentId, postData)
        currEquipmentId = equipmentId
      }

      await deleteFiles(
        imageDeleteMethod(currEquipmentId),
        deletedEquipmentImageList
      )

      const imagesToUpdate = editedImageList.filter(
        file => file.image.id && file.cropImage instanceof File
      )
      const imagesToUpload = editedImageList.filter(
        file => file.image instanceof File
      )

      await uploadImages(
        EquipmentService.uploadEquipmentImage,
        currEquipmentId,
        imagesToUpload
      )

      await updateImages(EquipmentService.updateEquipmentImage, imagesToUpdate)

      notificationUseCases.newSuccess('DEFAULT_SUCCESS')

      if (labIdRedirect) {
        history.push(generatePrivatePath(`/laboratory/${labIdRedirect}`))
      } else {
        history.push(generatePrivatePath('/equipments'))
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message == 'input.invalid'
      ) {
        notificationUseCases.newError('SPECIAL_CHARS')
      } else {
        notificationUseCases.newError()
      }
    }
  }

  const handleImageListChanged = useCallback(
    async (newImageList, deletedImageList) => {
      setDeletedEquipmentImageList(currList =>
        [].concat(currList, deletedImageList)
      )
      setEquipmentImageList(newImageList)
    },
    []
  )

  const EquipmentImageUploader = useCallback(() => {
    return (
      <CarrousselUploader
        slidesToShow={3}
        modalTitle={'FILE_UPLOAD_NEW_FOTO'}
        imageList={equipmentImageList}
        onImageListChange={handleImageListChanged}
        filesConfig={fileUploadConfig.equipment}
        setEditedImageList={setEditedImageList}
      />
    )
  }, [equipmentImageList, handleImageListChanged])

  return (
    <>
      <BaseContainerHeader
        label={translate(headerText)}
        showCloseButton={false}
        hint="REGISTER_EQUIPMENT_HINT"
      />

      <Loader />

      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <div className={classes.defaultContainer}>
          <h3 className={classes.h3}>
            {translate('REGISTER_EQUIPMENT_PHOTOS')}
          </h3>
          <EquipmentImageUploader />
          <EquipmentData
            formMethods={formMethods}
            classes={classes}
            fieldDisabled={labIdRedirect ? true : false}
            laboratoryList={laboratoryList}
            situationList={situationList}
            financingOriginList={financingOriginList}
            equipmentCode={equipmentCode}
            readOnlyDenominations={
              isCovidFreezer ? COVID_OTHER_DENOMINATIONS : []
            }
          />
          <EquipmentParameters
            formMethods={formMethods}
            classes={classes}
            fieldDisabled={{}}
            maxLength={20}
          />
          <EquipmentAbout
            formMethods={formMethods}
            classes={classes}
            fieldDisabled={{}}
          />
          <EquipmentResponsible
            formMethods={formMethods}
            classes={classes}
            fieldDisabled={{}}
            responsibleList={selectedLaboratory.representativeList}
          />
          <EquipmentActions formMethods={formMethods} classes={classes} />
        </div>
      </form>
    </>
  )
}

export default RegisterEquipment
