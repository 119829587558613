import styled from 'styled-components'

export const CopyrightTitle = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`
export const LinkTerms = styled.a`
  color: #02382e;
  font-size: 14px;
  font-weight: 700;
  margin-left: 15px;
  &:hover {
    cursor: pointer;
  }
`

export const WrapCopyright = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
`
export const linkModalStyle = { color: '#000'}

export const copyrightStyle = { maxWidth: '100%', justifyContent: 'center'}
