import React, { useCallback, useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { Grid, Typography } from '@material-ui/core'

import Autocomplete from 'ui/atoms/Autocomplete'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import { requestSharingUseCases } from '../../../../providers'

const Laboratory = ({ readOnly, formMethods, onChange: handleChange }) => {
  const { translate } = useContext(GlobalizationContext)
  const { control, errors } = formMethods
  const laboratory = useWatch({ control, name: 'laboratory' })

  const getOptions = useCallback(async ({ input }) => {
    return await requestSharingUseCases.getLab(input)
  }, [])

  return (
    <>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={readOnly}
          name="laboratory"
          label={translate('REGISTER.LABORATORY.LABEL')}
          placeholder={translate('REGISTER.LABORATORY.PLACEHOLDER')}
          control={control}
          error={errors.laboratory}
          optionGetter={getOptions}
          AutocompleteProps={{
            onChange: handleChange,
            getOptionLabel: option => `${option.initials} - ${option.name}`
          }}
          required
        />
      </Grid>

      {laboratory ? (
        <Grid item xs={12}>
          <Typography component="span" variant="h4">
            {translate('REGISTER.LABORATORY.RESPONSIBLE')}
          </Typography>
          <Typography
            component="span"
            variant="body2"
            style={{ marginLeft: '20px' }}
          >
            {laboratory.responsibleName}
          </Typography>
        </Grid>
      ) : null}
    </>
  )
}

export default Laboratory
