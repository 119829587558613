import styled from 'styled-components'

import MuiAvatar from '@material-ui/core/Avatar'

import { primaryColorLight } from 'helpers/constants'

export const Avatar = styled(MuiAvatar).attrs({})`
  background-color: ${primaryColorLight};
  width: 150px;
  height: 150px;
  font-size: 135px;
`

export const AvatarHover = styled(MuiAvatar).attrs({})`
  background-color: ${primaryColorLight};
  color: #fff;
  width: 150px;
  height: 150px;
  display: none;
`

export const Container = styled.div`
  &:hover {
    cursor: pointer;

    ${AvatarHover} {
      display: flex;
    }

    ${Avatar} {
      display: none;
    }
  }
`
