import axios from './axios'

export const AdminService = {
  // USERS
  getUser(userId) {
    return axios.get(`admin/users/${userId}`).then(resp => resp.data)
  },

  updateUser(userId, userData) {
    return axios.put(`admin/users/${userId}`, userData).then(resp => resp.data)
  },

  resendInvite(userId, inviteData) {
    return axios
      .post(`admin/users/${userId}/resend-invite`, null, { params: inviteData })
      .then(resp => resp.data)
  },

  getAllUsers({ pageSize, pageNumber, ...filter }) {
    return axios
      .get('admin/users', {
        params: { size: pageSize, page: pageNumber, ...filter }
      })
      .then(resp => resp.data)
  },

  changeSituation(id, situation) {
    return axios
      .put(`admin/users/${id}/situation`, { situation })
      .then(resp => resp.data)
  },

  deleteUser(id, reason) {
    return axios.delete(`admin/users/${id}`, {
      data: reason,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  resendPassword(id) {
    return axios.post(`admin/users/${id}/resend-password`)
  },

  hasRequestPending(id) {
    return axios.get(`users/${id}/can-delete`).then(resp => resp.data)
  },

  createAdmin(payload) {
    return axios.post(`/admin/users/roles`, payload)
  },

  checkEmail(email) {
    return axios
      .get(`/admin/users/email`, { params: { email } })
      .then(resp => resp.data)
  },

  //INSTITUTIONS
  getAllInstitutions({ pageSize, pageNumber, ...filter }) {
    return axios
      .get('admin/institutions', {
        params: { size: pageSize, page: pageNumber, ...filter }
      })
      .then(resp => resp.data)
  },

  getInstitution(cnpj) {
    return axios.get(`/admin/institutions/${cnpj}/form`).then(resp => resp.data)
  },

  refuseInstitutionSolicitation(cnpj, payload) {
    return axios
      .put(`/admin/institutions/${cnpj}/refuse`, payload)
      .then(resp => resp.data)
  },

  approveInstitutionSolicitation(cnpj, payload) {
    return axios
      .put(`/admin/institutions/${cnpj}/approve`, payload)
      .then(resp => resp.data)
  },

  sendRefusalDocument(id, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(
      `/admin/institutions/analysis/${id}/document-refuse`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  reactivateInstitution(cnpj, payload) {
    return axios
      .put(`/admin/institutions/${cnpj}/reactive`, payload)
      .then(resp => resp.data)
  },

  sendReactiveDocument(cnpj, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(
      `/admin/institutions/${cnpj}/document/reactive`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  inactivateInstitution(cnpj, payload) {
    return axios
      .put(`/admin/institutions/${cnpj}/inactive`, payload)
      .then(resp => resp.data)
  },

  sendInactiveDocument(cnpj, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(
      `/admin/institutions/${cnpj}/document/inactive`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  //LABORATORIES
  getAllLaboratories({ pageSize, pageNumber, ...filter }) {
    return axios
      .get('/admin/laboratories', {
        params: { size: pageSize, page: pageNumber, ...filter }
      })
      .then(resp => resp.data)
  },

  getLaboratory(id) {
    return axios.get(`/admin/laboratories/${id}`).then(resp => resp.data)
  },

  getEmailAlreadySent(id, email) {
    return axios
      .get(`/admin/laboratories/${id}/verify`, {
        params: { email }
      })
      .then(resp => resp.data)
  },

  saveRepresentantsList(id, data) {
    return axios.post(`/admin/laboratories/${id}/representative-list`, data)
  },

  resendLabRepresentantInvite(id, email) {
    return axios.post(`/admin/laboratories/${id}/resend-invite`, null, {
      params: { email }
    })
  }
}

export default AdminService
