// colors
export const primaryColor = '#009b7f'
export const primaryColorLight = '#06B399'
export const primaryColorDark = '#006855'
export const secundaryColor = '#071d41'
export const hoverPrimaryColor = '#006855'

export const textPrimaryColor = '#333333'

export const COLORS = {
  SUCCESS: '#95f204',
  SUCCESS_LIGHT: '#95f2044d',
  SUCCESS_DARK: '#006855',

  ERROR: '#D90018',
  ERROR_LIGHT: '#D900184D',

  WARNING: '#F49B23',
  WARNING_LIGHT: '#FCE1BD',
  WARNING_DARK: '#FF5C01',

  INFO: '#2C9CDB',
  INFO_LIGHT: '#AECFE3'
}

export const barraBrasilColor = '#7F7F7F'

export const confirmColor = '#95f204'

// responsivo
export const lg = '1280px'
export const md = '960px'
export const sm = '600px'

export const fileUploadConfig = {
  myProfile: {
    maxFiles: 1,
    extensions: {
      'image/jpeg': 3000000,
      'image/png': 3000000
    }
  },
  laboratoryResponsible: {
    maxFiles: 3,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000,
      'application/pdf': 25000000
    }
  },
  laboratoryRefusalOpinion: {
    maxFiles: 3,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000,
      'application/pdf': 25000000
    }
  },
  laboratorySharingTerms: {
    maxFiles: 1,
    extensions: {
      'application/pdf': 25000000
    }
  },

  equipment: {
    maxFiles: 12,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000
    }
  },

  laboratoryImages: {
    maxFiles: 5,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000
    }
  },

  laboratory: {
    maxFiles: 12,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000
    }
  },

  deleteUser: {
    maxFiles: 1,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000,
      'application/pdf': 25000000
    }
  },
  sharingComplementaryFiles: {
    maxFiles: 3,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000,
      'application/pdf': 25000000
    }
  },
  sharingMaterialDataSafetyFiles: {
    maxFiles: 3,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000,
      'application/pdf': 25000000
    }
  },
  institution: {
    maxFiles: 3,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000,
      'application/pdf': 25000000
    }
  },
  activationInstitution: {
    maxFiles: 1,
    extensions: {
      'image/jpeg': 10000000,
      'image/png': 10000000,
      'application/pdf': 25000000
    }
  }
}

export const specialCharactersRegex =
  // eslint-disable-next-line no-useless-escape
  /[\=\+\-\^\$\*\.\]\[\{\}\(\)\?\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~]/
export const specialCharactersList = `= + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \``

// commons
export const fontFamily = `"Raleway Bold", "Raleway Regular", Raleway, sans-serif`
export const fontSize = '14px;'

export const PRIVATE_ROUTE_PATH = '/back-office'

export const TYPES = {
  LABORATORY: 'LABORATORY',
  INSTITUTION: 'INSTITUTION'
}

export const CPF_MASK = [
  /([0-9]|[*])+/,
  /([0-9]|[*])+/,
  /([0-9]|[*])+/,
  '.',
  /([0-9]|[*])+/,
  /([0-9]|[*])+/,
  /([0-9]|[*])+/,
  '.',
  /([0-9]|[*])+/,
  /([0-9]|[*])+/,
  /([0-9]|[*])+/,
  '-',
  /([0-9]|[*])+/,
  /([0-9]|[*])+/
]

export const CNPJ_MASK = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
]
