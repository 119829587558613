import React, { useContext } from 'react'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import {
  BreadcrumbSeparator,
  BreadcrumbItem,
  BreadCrumbs,
  BreadcrumbItemLink
} from '../style'

const Breadcrumb = ({ match, item }) => {
  const { translate } = useContext(GlobalizationContext)

  let crumbs = ['INFRASTRUCTURE']

  if (match.params.type === 'laboratory') {
    crumbs.push('LABORATORY')
  } else if (match.params.type === 'equipment') {
    crumbs.push('EQUIPMENT')
  } else {
    crumbs = ['SEARCH']
  }

  return (
    <BreadCrumbs separator={<BreadcrumbSeparator />}>
      {crumbs.map(crumb => (
        <BreadcrumbItem key={crumb}>{translate(crumb)}</BreadcrumbItem>
      ))}
    </BreadCrumbs>
  )
}

export default Breadcrumb
