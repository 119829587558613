import styled from 'styled-components'

import MaterialTab from '@material-ui/core/Tab'
import MaterialTabs from '@material-ui/core/Tabs'

export const Tab = styled(MaterialTab)`
  flex: 0 1 auto;
  color: #666666;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  padding: 6px 0px;
  min-height: 21px;

  &.Mui-selected {
    color: #009b7f;
  }

  @media (min-width: 600px) {
    min-width: auto;
  }
`

export const Tabs = styled(MaterialTabs)`
  min-height: 21px;

  .MuiTabs-indicator {
    background-color: #009b7f;
  }

  .MuiTab-root:not(:first-child) {
    margin-left: 16px;
  }
`
