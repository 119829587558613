import { usePopupState, bindPopover } from 'material-ui-popup-state/hooks'
import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback
} from 'react'
import styled from 'styled-components'

import MaterialBadge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'

import NotificationService from 'api/NotificationService'
import { AuthContext } from 'context/AuthContext'
import { primaryColor } from 'helpers/constants'

import wrapper from 'ui/atoms/Wrapper'

import NotificationList from './NotificationList'

const IconNotification = styled.i`
  color: ${props => (props.hasNotification ? primaryColor : '#999999')};
  width: 21px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
`

const Badge = styled(MaterialBadge)`
  .MuiBadge-badge {
    font-weight: 700;
    padding-bottom: 3px;
  }
`

const PopoverStyled = styled(Popover)`
  z-index: 3;
`

const StyledButton = styled(Button)`
  @media screen and (max-width: 640px) {
    min-width: auto;
    /* padding: 0 !important; */
  }
`

const DivFlex = styled.div`
  display: flex;
  align-items: center;
`

function Notification() {
  const { userData } = useContext(AuthContext)

  const [notificationList, setNotificationList] = useState([])
  const [unreadCount, setUnreadCount] = useState(0)

  const notificationTimerRef = useRef(null)

  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })

  const cancelTimeout = useCallback(() => {
    const { current } = notificationTimerRef
    clearTimeout(current)
  }, [])

  useEffect(() => {
    return () => {
      cancelTimeout()
    }
  }, [cancelTimeout])

  const getNotifications = useCallback(async () => {
    cancelTimeout()
    try {
      const resp = await NotificationService.getNotification()

      setNotificationList(resp.notificationList)
      setUnreadCount(resp.unread)
    } catch {}

    notificationTimerRef.current = setTimeout(() => {
      getNotifications()
    }, 60000)
  }, [])

  useEffect(() => {
    if (!userData) return
    getNotifications()
  }, [getNotifications, userData])

  const handleButtonClick = async event => {
    popupState.open(event)
    await NotificationService.readAllNotifications(userData.userId)
    setUnreadCount(0)
  }

  const handleClicked = () => {
    getNotifications()
    popupState.close()
  }

  return (
    <DivFlex>
      <StyledButton onClick={handleButtonClick}>
        <Badge color="secondary" badgeContent={unreadCount}>
          <IconNotification
            hasNotification={notificationList.length}
            className="fas fa-bell"
          />
        </Badge>
      </StyledButton>
      <PopoverStyled
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <NotificationList
          notificationList={notificationList}
          onItemClicked={handleClicked}
        />
      </PopoverStyled>
    </DivFlex>
  )
}
export default wrapper(Notification, {
  namespace: ['NOTIFICATIONS', 'translation']
})
