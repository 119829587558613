import React from 'react'
import styled from 'styled-components'

import Link from '@material-ui/core/Link'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import { primaryColor } from 'helpers/constants'

export const LinkStyledAccept = styled(Link)`
  font-weight: 600;
  color: #797979;
  color: ${primaryColor};
  padding: 3px;
  margin: 10px 0 5px 5px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`
export const LinkStyledReject = styled(Link)`
  font-weight: 600;
  color: #797979;
  padding: 3px;
  margin: 10px 0 5px 5px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
`

export const NotificationAction = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const NotificationQuantity = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #333333;
  min-width: 24px;
  height: 24px;
  background: #f2f2f2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`

export const NotificationMessage = styled.div`
  white-space: break-spaces;
  flex: 1 1 100%;
`

export const NotificationMessageContainer = styled.div`
  display: flex;
`

export const NotificationTitle = styled.div`
  white-space: break-spaces;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  flex: 1 1 100%;
`

export const NotificationTitleContainer = styled.div`
  display: flex;
`

export const NotificationTitleDivider = styled.div`
  height: 1px;
  background-color: #009b7f;
  margin: 10px 0;
`

export const NotificationContainer = styled(({ asList, showTitle, ...props }) =>
  asList ? <li {...props} /> : <MenuItem {...props} />
)`
  padding: 20px;
  font-size: 13px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

export const MenuListStyled = styled(({ asList, showTitle, ...props }) =>
  asList ? <ul {...props} /> : <MenuList {...props} />
)`
  padding: 0;
  width: 300px;
`
