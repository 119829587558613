import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router'

import { Container } from '@material-ui/core'

import LaboratoryService from 'api/LaboratoryService'
import { history, generatePrivatePath } from 'helpers/history'

import Loader from 'ui/molecules/Loader'

import { notificationUseCases } from 'views/Notification/providers'

import EditLaboratory from './Fragments/Edit'
import ShowSituation from './Fragments/ShowSituation'

function LaboratoryEdit() {
  const { laboratoryId } = useParams()

  const [laboratoryData, setLaboratoryData] = useState(null)

  const getLaboratoryById = useCallback(async () => {
    try {
      const response = await LaboratoryService.getById(laboratoryId).then(
        ({ data }) => data
      )

      let changelogList = []

      if (response.laboratory.situation === 'WAITING_EDIT_APPROVAL') {
        changelogList = await LaboratoryService.getChangelog(laboratoryId).then(
          ({ data }) => data
        )
      }

      setLaboratoryData({ ...response, changelogList })
    } catch (error) {
      if (error.response?.status === 403) {
        notificationUseCases.newError(
          'Voce não tem permissão para visualizar este laboratório'
        )
        history.push(generatePrivatePath('/laboratory'))
      }
    }
  }, [laboratoryId])

  useEffect(() => {
    getLaboratoryById()
    window.scrollTo(0, 0)
  }, [getLaboratoryById])

  const laboratoryContent = useMemo(() => {
    if (!laboratoryData?.laboratory.situation) return null

    if (
      ['WAITING_APPROVAL', 'WAITING_EDIT_APPROVAL', 'REJECTED'].includes(
        laboratoryData.laboratory.situation
      )
    ) {
      return <ShowSituation laboratoryData={laboratoryData} />
    } else {
      return <EditLaboratory laboratoryData={laboratoryData} />
    }
  }, [laboratoryData])

  return (
    <>
      <Loader />
      <Container maxWidth="lg" disableGutters>
        {laboratoryContent}
      </Container>
    </>
  )
}
export default LaboratoryEdit
