// @ts-nocheck
import Slider from 'react-slick'
import styled from 'styled-components'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { primaryColor } from 'helpers/constants'

export const ImageOverlay = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0, 155, 127, 0.3);
  width: calc(100% - 18px);
  height: ${props => (props.height ? `${props.height}px` : '300px')};
  cursor: pointer;
  box-sizing: border-box;
`

export const ContentContainer = styled.div`
  position: relative;
`

export const SliderContainer = styled.div`
  position: relative;
  padding: 0 35px;

  &:hover {
    & ${ImageOverlay} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & .slick-track {
    width: auto;
  }
`

export const CustomSlider = styled(Slider)`
  & {
    & .slick-slide {
      display: flex;

      & > div {
        display: flex;
        width: 100%;
      }
    }
  }
`

export const ItemCarroussel = styled.div`
  width: 100%;
  height: ${props => (props.height ? `${props.height}px` : '300px')};
  margin: 10px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const PlaceholderItemCarroussel = styled(ItemCarroussel)`
  position: relative;
  color: white;

  .img-overlay {
    position: absolute;
    content: ' ';
    background-color: rgba(6, 179, 153, 0.66);
    width: 100%;
    height: 100%;
    top: 0;
  }

  svg {
    position: absolute;
    left: calc(50% - 17px);
    top: calc(50% - 17px);
  }
`

export const NextArrow = styled(ArrowForwardIosIcon)`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: calc(50% - 12px);
  color: #d0d0d0;
`

export const PrevArrow = styled(ArrowBackIosIcon)`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: calc(50% - 12px);
  color: #d0d0d0;
`
export const CarrouselItem = styled.div`
  margin-right: 12px;
  width: 100%;
  height: 100px;

  img {
    border: 3px solid ${primaryColor};
    border-radius: 3px;
    box-sizing: border-box;
    display: block;
    margin-top: 10px;
    object-fit: cover;
    width: 100%;
    height: 84px;
  }
`

export const CloseButton = styled.button`
  background-color: ${primaryColor};
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  padding: 4px 8px;
  position: absolute;
  top: -4px;
  right: -4px;
`

export const SelectedImage = styled.img`
  margin-bottom: 40px;
  width: 100%;
`
