import React, { useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import Grid from '@material-ui/core/Grid'

import BaseTextField from 'ui/atoms/BaseTextField'

const OtherReason = ({ formMethods, situationList, translate }) => {
  const { control, errors } = formMethods
  const situationId = useWatch({ control, name: 'situation' })

  const hasOther = useMemo(() => {
    const currSituation = situationList.find(
      item => item.idReason === situationId
    )

    return currSituation?.custom
  }, [situationId, situationList])

  return hasOther ? (
    <Grid item xs={12} md={5}>
      <BaseTextField
        disabled={false}
        required={true}
        label={translate('OTHER_REASON')}
        placeholder={translate('OTHER_REASON')}
        errors={errors}
        control={control}
        name="otherReason"
        maxLength={255}
      ></BaseTextField>
    </Grid>
  ) : null
}

export default OtherReason
