import * as Yup from 'yup'

export default Yup.object().shape({
  cnpj: Yup.string().cnpj('CNPJ_ERROR'),
  initials: Yup.string().required('REQUIRED_ERROR'),
  social_reason: Yup.string().required('REQUIRED_ERROR'),
  zipCode: Yup.string(),
  street: Yup.string().when('zipCode', {
    is: zipCode => zipCode.length > 0,
    then: Yup.string().required('REQUIRED_ERROR')
  }),
  number: Yup.string()
    .when('zipCode', {
      is: zipCode => zipCode.length > 0,
      then: Yup.string().required('REQUIRED_ERROR')
    })
    .trim(),
  complement: Yup.string(),
  district: Yup.string().when('zipCode', {
    is: zipCode => zipCode.length > 0,
    then: Yup.string().required('REQUIRED_ERROR')
  }),
  city: Yup.string().when('zipCode', {
    is: zipCode => zipCode.length > 0,
    then: Yup.string().required('REQUIRED_ERROR')
  }),
  state: Yup.string().when('zipCode', {
    is: zipCode => zipCode.length > 0,
    then: Yup.string().required('REQUIRED_ERROR')
  }),
  region: Yup.string().when('zipCode', {
    is: zipCode => zipCode.length > 0,
    then: Yup.string().required('REQUIRED_ERROR')
  })
})
