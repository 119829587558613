import React from 'react'

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

import { useGlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle } from 'ui/atoms/Title'

const LabContacts = ({ laboratoryData }) => {
  const { translate } = useGlobalizationContext()

  const { laboratory } = laboratoryData
  const { institution } = laboratory
  const { institutionRepresentativeList } = institution

  return (
    <Grid container spacing={2}>
      <Subtitle item xs={12}>
        {translate('CONTACTS.TITLE')}
      </Subtitle>
      <Grid item xs={12}>
        <TableContainer>
          <Table style={{ maxWidth: '600px' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '10px' }}>#</TableCell>
                <TableCell>{translate('CONTACTS.NAME')}</TableCell>
                <TableCell>{translate('CONTACTS.EMAIL')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(institutionRepresentativeList || []).map(
                (responsible, index) => (
                  <TableRow key={responsible.email}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{responsible.name}</TableCell>
                    <TableCell>{responsible.email}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default LabContacts
