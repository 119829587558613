import React from 'react'

import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { primaryColor } from 'helpers/constants'

const ButtonResend = ({ id, sent, onClick: handleClick }) => {
  return (
    <>
      <IconButton onClick={() => handleClick(id)} style={{ padding: '6px' }}>
        <Icon
          className="fas fa-paper-plane"
          style={{
            color: sent ? primaryColor : null,
            fontSize: '18px',
            marginLeft: '-2px',
            paddingRight: '2px'
          }}
        />
      </IconButton>
    </>
  )
}

export default ButtonResend
