import { Auth } from 'aws-amplify'
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import React, { useContext, useCallback } from 'react'

import { AuthContext } from 'context/AuthContext'
import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'

import { IconButton, UserIcon, Popover, UserName, Divider, Link } from './style'

const UserButton = () => {
  const { userData, signOut } = useContext(AuthContext)
  const { translate } = useContext(GlobalizationContext)

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover'
  })

  const handleSignOut = useCallback(async () => {
    popupState.close()
    signOut()
  }, [popupState, signOut])

  return !userData ? null : (
    <>
      <IconButton {...bindTrigger(popupState)} name="MENU_BUTTON_MY_PROFILE">
        <UserIcon />
      </IconButton>
      <Popover
        PaperProps={{
          id: 'MY_PROFILE_POPUP'
        }}
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <UserName>{translate('HELLO_USER', userData)}</UserName>
        <Divider />
        <Link
          id="MY_PROFILE_LINK"
          to={generatePrivatePath('/my-profile')}
          onClick={popupState.close}
        >
          {translate('MY_PROFILE')}
        </Link>
        <Link
          id="MY_PANEL_LINK"
          to={generatePrivatePath('')}
          onClick={popupState.close}
        >
          {translate('MY_PANEL')}
        </Link>
        <Link id="LOGOUT_LINK" to="" onClick={handleSignOut}>
          {translate('LOGOUT')}
        </Link>
      </Popover>
    </>
  )
}

export default Wrapper(UserButton, {
  namespace: 'USER_HEADER_SUBMENU'
})
