import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef
} from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { AdminService, InstitutionRegister, InstitutionService } from 'api'
import { AuthContext } from 'context/AuthContext'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { zipCode } from 'helpers/generalInputMasks'
import { history, generatePrivatePath } from 'helpers/history'
import { cnpjMask } from 'helpers/maskedCNPJ'
import { phoneMask } from 'helpers/maskedPhone'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { WarningMessage } from 'ui/atoms/Messages'
import wrapper from 'ui/atoms/Wrapper'
import Address, { getAddressType } from 'ui/molecules/Address/index'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import { ADMIN_ROUTES } from '../../constants/routes'
import formSchema from './formSchema'
import InviteModal from './Fragments/InviteModal'
import RepresentativeInput from './Fragments/RepresentativeInput'
import RepresentativeList from './Fragments/RepresentativeList'
import Responsible from './Fragments/Responsible'

const InstitutionEdit = () => {
  const classes = useStyles()
  const { institutionId } = useParams()
  const { translate } = useContext(GlobalizationContext)
  const { refreshCurrentUserData } = useContext(AuthContext)

  const [activeRepresentantsList, setActiveRepresentantsList] = useState([])
  const [userList, setUserList] = useState([])
  const [documents, setDocuments] = useState([])
  const [responsible, setResponsible] = useState(null)
  const [institutionSituation, setInstitutionSituation] = useState(null)
  const [formNeedsSaving, setFormNeedsSaving] = useState(false)
  const [isEmailInviteEmpty, setIsEmailInviteEmpty] = useState(true)
  const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState(false)
  const [modalInviteOpen, setModalInviteOpen] = useState(null)

  const header = useRef()

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver
  })

  const {
    control,
    handleSubmit,
    reset: resetForm,
    setValue,
    getValues,
    errors,
    setError
  } = formMethods

  const initData = useCallback(
    institutionData => {
      const {
        institution,
        responsible,
        representativeActiveList,
        representative_list
      } = institutionData

      setActiveRepresentantsList(representativeActiveList)
      setUserList(representative_list)
      setResponsible(responsible)
      setDocuments(institution.documentList)
      setInstitutionSituation(institution.situation)
      resetForm({
        ...institution,
        addressType: getAddressType(institutionData),
        zipCode: zipCode(institution.zipCode)
      })
    },
    [resetForm]
  )

  useEffect(() => {
    AdminService.getInstitution(institutionId)
      .then(initData)
      .catch(() => {})
  }, [initData, institutionId])

  const handleInstitutionCancel = () => {
    setOpenConfirmCancelDialog(false)
    history.push(generatePrivatePath(ADMIN_ROUTES.INSTITUTIONS))
  }

  const cleanUserList = () => {
    const newUserList = userList.map(item => ({
      id: item.id || null,
      email: item.email,
      name: item.name,
      permission: item.permission,
      situation: !item.deleted ? item.situation : 'DELETED'
    }))

    return newUserList
  }

  const onSubmit = async formData => {
    if (formData.email_invite) {
      setIsEmailInviteEmpty(false)
      return
    }

    const error = await checkIfInstitutionAlreadyExists(formData)
    if (
      error &&
      error.response.data.message === 'social.reason.already.exists'
    ) {
      return setError('social_reason', {
        type: 'socialReasonAlreadyExists',
        message: translate('MESSAGES.INSTITUTION_ALREADY_EXISTS'),
        shouldFocus: true
      })
    } else if (
      error &&
      error.response.data.message === 'both.fields.already.exists'
    ) {
      setError('social_reason', {
        type: 'fieldValueAlreadyTaken',
        message: translate('MESSAGES.INSTITUTION_ALREADY_EXISTS'),
        shouldFocus: true
      })
      return setError('initials', {
        type: 'fieldValueAlreadyTaken',
        message: translate('MESSAGES.INITIALS_ALREADY_EXISTS')
      })
    } else if (
      error &&
      error.response.data.message === 'initials.already.exists'
    ) {
      return setError('initials', {
        type: 'fieldValueAlreadyTaken',
        message: translate('MESSAGES.INITIALS_ALREADY_EXISTS'),
        shouldFocus: true
      })
    }

    const representativeList = cleanUserList()
    const hasAtLeastOneTotal =
      representativeList.filter(
        representative =>
          representative.permission === 'ROLE_INSTITUICAO_TOTAL' &&
          representative.situation === 'ACTIVE' &&
          !representative.deleted
      ).length > 0

    if (!hasAtLeastOneTotal) {
      notificationUseCases.newError(translate('MESSAGES.AT_LEAST_ONE_TOTAL'))
      return
    }

    formData.situation = institutionSituation

    const objInstitution = {
      institution: formData,
      representative_list: cleanUserList(),
      responsible: responsible
    }

    await InstitutionRegister.postAdminInstitution(objInstitution)
      .then(async data => {
        initData(data)
        notificationUseCases.newSuccess('DEFAULT_SUCCESS')
        if (formNeedsSaving) {
          await refreshCurrentUserData()
        }
        history.push(generatePrivatePath(ADMIN_ROUTES.INSTITUTIONS))
      })
      .catch(error => {
        if (
          error.response.status === 400 &&
          error.response.data.message == 'input.invalid'
        ) {
          notificationUseCases.newError('SPECIAL_CHARS')
        } else if (
          error.response.data.message === 'maximum.representative.number'
        ) {
          notificationUseCases.newError(
            translate('REGISTER_INSTITUTION_MAXIMUM_USERS_REACH')
          )
        } else if (
          error.response.data.message === 'user.already.other.institution'
        ) {
          notificationUseCases.newError(
            translate('REGISTER_INSTITUTION_ALREADY_IN_OTHER_INSTITUTION')
          )
        } else {
          notificationUseCases.newError()
        }
      })

    setFormNeedsSaving(false)
  }

  const checkIfInstitutionAlreadyExists = async formData => {
    try {
      const data = await InstitutionService.checkIfInstitutionExists(formData)
      return data
    } catch (error) {
      return error
    }
  }

  const handleInvite = async newInvite => {
    setFormNeedsSaving(true)
    setUserList(oldUserList => [...oldUserList, newInvite])
    setValue('email_invite', '')
    setModalInviteOpen(null)
  }

  const handleCloseInvite = () => {
    setValue('email_invite', '')
    setModalInviteOpen(null)
  }

  const onChangeResponsible = user => {
    setResponsible(user)
  }

  useEffect(() => {
    const node = header

    if (node && node.current) node.current.focus()
  }, [])

  return (
    <React.Fragment>
      <Loader />

      {modalInviteOpen ? (
        <InviteModal
          email={modalInviteOpen}
          onInvite={handleInvite}
          onClose={handleCloseInvite}
        />
      ) : null}

      {/* Bugfix temporario - problema no scroll  */}
      <input ref={header} style={{ opacity: '0' }} />
      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />
      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <div className={classes.defaultContainer}>
          <h3 className={classes.h3}>{translate('INSTITUTION_DATA')}</h3>
          <Grid
            container
            direction={'row'}
            spacing={3}
            style={{ marginBottom: '20px' }}
          >
            <Grid item xs={12} md={10}>
              <BaseTextField
                label={translate('COMPANY_NAME')}
                placeholder={translate('COMPANY_NAME')}
                errors={errors}
                control={control}
                name="social_reason"
                required={true}
              ></BaseTextField>
            </Grid>

            <Grid item xs={12} md={2}>
              <BaseTextField
                label={translate('INITIALS')}
                placeholder={translate('INITIALS')}
                errors={errors}
                control={control}
                name="initials"
                required={true}
              ></BaseTextField>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <BaseTextField
                disabled
                label={translate('CODE')}
                errors={errors}
                control={control}
                name="cnpj"
                mask={cnpjMask}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseTextField
                errors={errors}
                control={control}
                label={translate('PHONE_NUMBER')}
                mask={phoneMask}
                name="phoneNumber"
              />
            </Grid>
          </Grid>
        </div>

        <Address
          formMethods={formMethods}
          disabled={false}
          required={!!getValues('zipCode')}
        />
        <Responsible
          documents={documents}
          onChangeResponsible={onChangeResponsible}
          user={responsible}
          userList={activeRepresentantsList}
          formMethods={formMethods}
        />

        <RepresentativeInput
          formMethods={formMethods}
          userList={userList}
          setModalInviteOpen={setModalInviteOpen}
        />

        {formNeedsSaving ? (
          <Grid container>
            <Grid item xs={12}>
              <WarningMessage>
                {translate('MESSAGES.FORM_NEEDS_SAVING')}
              </WarningMessage>
            </Grid>
          </Grid>
        ) : null}

        <RepresentativeList
          formMethods={formMethods}
          institutionCnpj={institutionId}
          userList={userList}
          setUserList={setUserList}
          setFormNeedsSaving={setFormNeedsSaving}
        />

        <div className={classes.defaultContainer}>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            style={{ paddingTop: '4rem' }}
          >
            <Grid item xs={12} md={2}>
              <Button
                className={classes.baseButtonGrey}
                type={'button'}
                variant="contained"
                fullWidth
                id="INSTITUTION_CANCEL"
                onClick={() => setOpenConfirmCancelDialog(true)}
              >
                {translate('COMMONS:CANCEL')}
              </Button>
            </Grid>
            <DialogConfirm
              dialogText="MESSAGES.CONFIRM_DIALOG"
              open={openConfirmCancelDialog}
              onConfirm={handleInstitutionCancel}
              onCancel={() => setOpenConfirmCancelDialog(false)}
            />
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                type="button"
                variant="contained"
                className={classes.baseButton}
                id="INSTITUTION_SAVE"
                onClick={handleSubmit(onSubmit)}
              >
                {translate('COMMONS:SAVE')}
              </Button>
            </Grid>
            <DialogConfirm
              dialogText="MESSAGES.ALERT_EMAIL_INVITE_DIALOG"
              open={!isEmailInviteEmpty}
              hideConfirmButton={true}
              labelCancelButton="Continuar"
              onCancel={() => setIsEmailInviteEmpty(true)}
            />
          </Grid>
        </div>
      </form>
    </React.Fragment>
  )
}
export default wrapper(InstitutionEdit, {
  mapState: null,
  mapDispatch: null,
  namespace: 'INSTITUTION_EDIT'
})
