// @ts-nocheck
import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const Address = ({ data }) => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <>
      <Typography variant="subtitle1" style={{ marginTop: '32px' }}>
        {translate('ADDRESS')}
      </Typography>

      <Grid container spacing={3} style={{ marginTop: '32px' }}>
        <Grid item xs={12} md={3}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Insira"
            label="CEP"
            name="zipcode"
            value={data?.zipCode || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '24px' }}>
        <Grid item xs={12} md={10}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Logradouro"
            name="street"
            value={data?.street || ''}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Número"
            name="number"
            value={data?.number || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '32px' }}>
        <Grid item xs={12} md={12}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Complemento"
            name="complement"
            value={data?.complement || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '24px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Bairro"
            name="district"
            value={data?.district || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Município"
            name="city"
            value={data?.city || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="UF"
            name="state"
            value={data?.state || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '32px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Insira"
            label="Região"
            name="region"
            value={data?.region || ''}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Address
