import { intersection, sortBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'

import { UsersService } from 'api'

import { GlobalizationContext } from '../Globalization'

function defaultProfiles(profiles, translate) {
  return profiles.map(profileName => ({
    id: profileName,
    name: translate(`USER:ROLE.${profileName}`)
  }))
}

function requestSharingProfiles(profiles, translate) {
  const profileList = ['ROLE_INTEGRANTE'].concat(
    intersection(profiles, [
      'ROLE_LABORATORIO_TOTAL',
      'ROLE_LABORATORIO_PARCIAL'
    ])
  )

  return profileList.map(profileName => ({
    id: profileName,
    name: translate(`USER:ROLE.${profileName}`)
  }))
}

const types = {
  REQUEST_SHARING: requestSharingProfiles
}

export const useProfiles = profileType => {
  const { translate } = useContext(GlobalizationContext)

  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchProfileList() {
      setLoading(true)
      try {
        const response = await UsersService.getProfiles()

        const profileGetter = types[profileType] || defaultProfiles
        const profileList = profileGetter(response, translate)

        setResult(sortBy(profileList, 'name'))
      } catch {
        setResult([])
      }
      setLoading(false)
    }

    fetchProfileList()
  }, [profileType, translate])

  return [result, loading]
}
