import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { NO_WRAPPING_ROUTES } from './routes'

const NoWrappingRoutes = () => {
  return (
    <>
      <Switch>
        {NO_WRAPPING_ROUTES.map((route, key) => (
          <Route key={key} {...route} />
        ))}
      </Switch>
    </>
  )
}

export default NoWrappingRoutes
