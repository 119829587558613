import React, { useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { Box, Grid, Typography } from '@material-ui/core'

import { EquipmentService } from 'api'

import AddedDocuments from 'ui/atoms/AddedDocuments'
import BaseDatePicker from 'ui/atoms/BaseDatePicker'
import BaseMultipleSelect from 'ui/atoms/BaseMultipleSelect'
import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import BaseTextField from 'ui/atoms/BaseTextField'
import DocumentUploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { WarningMessage } from 'ui/atoms/Messages'
import RadioGroup from 'ui/atoms/RadioGroup'
import { Subtitle } from 'ui/atoms/Title'
import AutoCompleteChipInput from 'ui/molecules/commons/AutoCompleteChipInput'
import Hint from 'ui/molecules/commons/DefaultHint'

import EquipmentOption from 'views/BackOffice/Subviews/RequestSharing/fragments/EquipmentOption'

const Solicitation = ({
  readOnly,
  equipmentList,
  removedEquipmentList,
  formMethods,
  relatedDocumentsConfig
}) => {
  const { translate } = useContext(GlobalizationContext)
  const { control, errors } = formMethods

  const watchedFields = useWatch({
    control,
    name: [
      'specificEquipment',
      'requiresSpecificEquipment',
      'hasExperienceWithTechnique',
      'needsComplementaryEquipment'
    ]
  })

  const mainTechniqueSuggestionGetter = ({ value }) => {
    return EquipmentService.getAllMainTechnique(value).then(resp => resp.data)
  }

  return (
    <>
      <Grid item xs={12}>
        <BaseTextField
          name="solicitationTitle"
          label={translate('REGISTER.SOLICITATION.TITLE_LABEL')}
          placeholder={translate('REGISTER.SOLICITATION.TITLE_PLACEHOLDER')}
          control={control}
          errors={errors}
          required
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12}>
        <BaseTextareaField
          name="summary"
          label={translate('REGISTER.SOLICITATION.SUMMARY_LABEL')}
          placeholder={translate('REGISTER.SOLICITATION.SUMMARY_PLACEHOLDER')}
          control={control}
          errors={errors}
          maxLength={500}
          rows={5}
          required
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12}>
        <BaseTextareaField
          name="justification"
          label={translate('REGISTER.SOLICITATION.JUSTIFICATION_LABEL')}
          placeholder={translate(
            'REGISTER.SOLICITATION.JUSTIFICATION_PLACEHOLDER'
          )}
          control={control}
          errors={errors}
          maxLength={500}
          rows={5}
          required
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12}>
        <BaseTextField
          name="estimatedTime"
          label={translate('REGISTER.SOLICITATION.ESTIMATED_TIME_LABEL')}
          placeholder={translate(
            'REGISTER.SOLICITATION.ESTIMATED_TIME_PLACEHOLDER'
          )}
          control={control}
          errors={errors}
          required
          disabled={readOnly}
        />
      </Grid>

      <Subtitle item xs={12} variant="h4">
        {translate('REGISTER.SOLICITATION.SUGGESTED_DATE_SUBTITLE')}{' '}
        <Hint
          content="REGISTER.SOLICITATION.SUGGESTED_DATE_HINT"
          fontSize="16px"
        />
      </Subtitle>

      <Grid item xs={6} sm={4} md={3}>
        <BaseDatePicker
          name="suggestedDateFrom"
          label={translate('REGISTER.SOLICITATION.SUGGESTED_DATE_FROM_LABEL')}
          placeholder={translate(
            'REGISTER.SOLICITATION.SUGGESTED_DATE_FROM_PLACEHOLDER'
          )}
          control={control}
          error={errors.suggestedDateFrom}
          required
          fullWidth
          disablePast
          openTo="year"
          views={['year', 'month', 'date']}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <BaseDatePicker
          name="suggestedDateTo"
          label={translate('REGISTER.SOLICITATION.SUGGESTED_DATE_TO_LABEL')}
          placeholder={translate(
            'REGISTER.SOLICITATION.SUGGESTED_DATE_TO_PLACEHOLDER'
          )}
          control={control}
          error={errors.suggestedDateTo}
          required
          fullWidth
          disablePast
          openTo="year"
          views={['year', 'month', 'date']}
          disabled={readOnly}
        />
      </Grid>

      <Grid item xs={12}>
        {!readOnly ? (
          <DocumentUploader
            label="REGISTER.SOLICITATION.RELATED_DOCUMENTS_LABEL"
            wrapperDescription="REGISTER.SOLICITATION.RELATED_DOCUMENTS_BUTTON"
            filesConfig={relatedDocumentsConfig.fileConfig}
            onChange={relatedDocumentsConfig.onFileSelected}
            currentListLength={relatedDocumentsConfig.documents.length}
            marginBottom="0px"
          />
        ) : null}
        <AddedDocuments
          docs={relatedDocumentsConfig.documents}
          viewFile={relatedDocumentsConfig.previewFile}
          removeFile={!readOnly && relatedDocumentsConfig.onRemoveFile}
        />
      </Grid>

      <Grid item xs={12}>
        <RadioGroup
          disabled={readOnly}
          required
          name="requiresSpecificEquipment"
          label={translate('REGISTER.SOLICITATION.REQUIRES_SPECIFIC_EQUIPMENT')}
          control={control}
          error={errors}
          inline
          row
          options={[
            { id: true, name: translate('COMMONS:YES') },
            { id: false, name: translate('COMMONS:NO') }
          ]}
        />
      </Grid>

      {String(watchedFields.requiresSpecificEquipment) === 'true' && (
        <>
          {(removedEquipmentList || []).length ? (
            <Grid item xs={12}>
              {!readOnly ? (
                <WarningMessage>
                  {translate(
                    'REGISTER.SOLICITATION.REMOVED_EQUIPMENTS_MESSAGE'
                  )}
                </WarningMessage>
              ) : null}
              <Typography component={Box} variant="body2" py={2}>
                <strong>
                  {translate('REGISTER.SOLICITATION.REMOVED_EQUIPMENTS_LABEL')}{' '}
                </strong>
                {removedEquipmentList
                  .map(equip => `${equip.code} - ${equip.name}`)
                  .join(', ')}
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={12} md={6}>
            <BaseMultipleSelect
              disabled={readOnly}
              required
              label={translate('REGISTER.SOLICITATION.SPECIFIC_EQUIPMENT')}
              placeholder={translate('COMMONS_SELECT')}
              errors={errors}
              options={equipmentList}
              control={control}
              name="specificEquipment"
              selectedValueRenderer={option =>
                `${option.code} - ${option.name}`
              }
              optionRenderer={option => <EquipmentOption {...option} />}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <AutoCompleteChipInput
          disabled={readOnly}
          name="techniqueList"
          control={control}
          suggestionGetter={mainTechniqueSuggestionGetter}
          titleAdd={translate('REGISTER.SOLICITATION.MAIN_TECHNIQUE')}
          title={translate('REGISTER.SOLICITATION.MAIN_TECHNIQUE_PLACEHOLDER')}
          errors={errors}
          maxLength="20"
        />
      </Grid>

      <Grid item xs={12}>
        <RadioGroup
          disabled={readOnly}
          required
          name="hasExperienceWithTechnique"
          label={translate(
            'REGISTER.SOLICITATION.HAS_EXPERIENCE_WITH_TECHNIQUE'
          )}
          control={control}
          error={errors}
          inline
          row
          options={[
            { id: true, name: translate('COMMONS:YES') },
            { id: false, name: translate('COMMONS:NO') }
          ]}
        />
      </Grid>

      {String(watchedFields.hasExperienceWithTechnique) === 'true' && (
        <Grid item xs={12}>
          <BaseTextareaField
            disabled={readOnly}
            name="justifyExperienceWithTechnique"
            label={translate(
              'REGISTER.SOLICITATION.JUSTIFY_EXPERIENCE_WITH_TECHNIQUE'
            )}
            placeholder={translate(
              'REGISTER.SOLICITATION.JUSTIFY_EXPERIENCE_WITH_TECHNIQUE_PLACEHOLDER'
            )}
            control={control}
            errors={errors}
            maxLength={500}
            rows={5}
            required
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <RadioGroup
          disabled={readOnly}
          required
          name="needsComplementaryEquipment"
          label={translate(
            'REGISTER.SOLICITATION.NEEDS_COMPLEMENTARY_EQUIPMENT'
          )}
          control={control}
          error={errors}
          inline
          row
          options={[
            { id: 'YES', name: translate('COMMONS:YES') },
            { id: 'NO', name: translate('COMMONS:NO') },
            {
              id: 'DOES_NOT_APPLY',
              name: translate('COMMONS:DOES_NOT_APPLY')
            }
          ]}
        />
      </Grid>

      {watchedFields.needsComplementaryEquipment === 'YES' && (
        <Grid item xs={12}>
          <BaseTextareaField
            disabled={readOnly}
            name="justifyNeedsComplementaryEquipment"
            label={translate(
              'REGISTER.SOLICITATION.JUSTIFY_NEEDS_COMPLEMENTARY_EQUIPMENT'
            )}
            placeholder={translate(
              'REGISTER.SOLICITATION.JUSTIFY_NEEDS_COMPLEMENTARY_EQUIPMENT_PLACEHOLDER'
            )}
            control={control}
            errors={errors}
            maxLength={500}
            rows={5}
            required
          />
        </Grid>
      )}
    </>
  )
}

export default Solicitation
