import styled, { css } from 'styled-components'

import { primaryColor, primaryColorLight } from 'helpers/constants'

export const WrapFilter = styled.div`
  position: relative;
`

export const FilterInput = styled.input`
  width: 100%;
  background: #f2f2f2;
  border: none;
  font-size: 14px;
  padding: 12px 10px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid transparent;
  outline: 0;

  &:hover {
    background: #f9f9f9;
    border-color: #ddd;
    outline: 0;
  }
`

export const IconSearch = styled.i`
  cursor: pointer;
  position: absolute;
  color: ${primaryColor};
  right: 0;
  margin: 12px;
`

export const TabContainer = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
`

export const Tab = styled.li`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  min-height: 44px;
  border-width: 1px;
  border-style: solid;

  font-size: 14px;
  font-weight: 600;

  ${props =>
    props.$active
      ? css`
          border-color: ${primaryColorLight};
          background-color: ${primaryColorLight};
          color: #ffffff;
        `
      : css`
          border-color: #999999;
          color: #999999;
        `};

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`
