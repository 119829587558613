import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce/lib'

import { makeStyles } from '@material-ui/core/styles'

import { history, generatePrivatePath } from 'helpers'

import wrapper from 'ui/atoms/Wrapper'

import { ADMIN_ROUTES } from '../../constants/routes'
import BodyFilter from './Fragments/BodyFilter'
import List from './Fragments/List'
import { laboratoryListUseCase as useCase } from './providers'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

const rowsPerPageList = [10, 20, 50, 100]

function LaboratoryList() {
  const [list, setList] = useState([])

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [count, setCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  const [filterValue, setFilterValue] = useState('')
  const [emptyList, setEmptyList] = useState(null)

  const classes = useStyles()

  const filterParams = useMemo(() => {
    const filter = {
      ...(filterValue ? { search: filterValue } : {})
    }
    return { pageSize, pageNumber, ...filter }
  }, [filterValue, pageNumber, pageSize])
  const [debouncedFilterParams] = useDebounce(filterParams, 500)

  const getList = useCallback(async () => {
    try {
      const { content, ...pagination } = await useCase.getAll(
        debouncedFilterParams
      )
      setList(content)
      setCount(pagination.totalElements)
      if (pagination.empty) {
        setEmptyList(true)
      } else {
        setEmptyList(false)
      }
    } catch (error) {
      console.log(error)
    }
  }, [debouncedFilterParams])

  const handleInputChange = event => {
    setPageNumber(0)
    setFilterValue(event.target.value)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  const handleRowAction = item => {
    history.push(
      generatePrivatePath(ADMIN_ROUTES.LABORATORIES_EDIT, {
        pathParams: { laboratoryId: item.id }
      })
    )
  }

  useEffect(() => {
    getList()
  }, [getList])

  return (
    <>
      <BodyFilter onInputChange={handleInputChange} />
      <List
        classes={classes}
        rowsPerPageOptions={rowsPerPageList}
        count={count}
        rowsPerPage={pageSize}
        page={pageNumber}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        list={list}
        emptyList={emptyList}
        onRowAction={handleRowAction}
      />
    </>
  )
}

export default wrapper(LaboratoryList, { namespace: 'LABORATORY_LIST' })
