import { connect } from 'react-redux'

import { globalization } from '../Globalization'

/**
 * @param {any} Component
 * @param {object} config
 * @param {function} config.mapState
 * @param {function} config.mapDispatch
 * @param {string|Array<string>} config.namespace
 */
const wrapper = (Component, { mapState, mapDispatch, namespace }) => {
  let component = Component

  if (mapState || mapDispatch) {
    component = connect(mapState, mapDispatch)(component)
  }

  if (namespace) {
    component = globalization(component, namespace)
  }

  return component
}

export default wrapper
