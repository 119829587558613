import React, { useRef } from 'react'
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery'
import Slider from 'react-slick'
import styled from 'styled-components'
import 'lightgallery.js/dist/css/lightgallery.css'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import 'slick-carousel/slick/slick.scss'
import 'assets/scss/customSlickSlider.scss'

const Image = styled.img`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`

const ContentContainer = styled.div`
  position: relative;
`

const SliderContainer = styled.div`
  position: relative;
  margin: 0 35px;
`

const CustomSlider = styled(Slider)`
  & {
    & .slick-slide {
      display: flex;

      & > div {
        display: flex;
        width: 100%;
      }
    }
  }
`

const ItemCarroussel = styled.div`
  width: 100%;
  height: ${props => props.height || '300px'};
  margin: 10px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const NextArrow = styled(ArrowForwardIosIcon)`
  position: absolute;
  right: 0;
  top: calc(50% - 12px);
  color: #d0d0d0;
`

const PrevArrow = styled(ArrowBackIosIcon)`
  position: absolute;
  left: 0;
  top: calc(50% - 12px);
  color: #d0d0d0;
`

function Carroussel(props) {
  const { dots, slidesToShow, infinite } = props
  const imagesLength = props.images.length

  var settings = {
    dots: dots,
    infinite: infinite,
    speed: 500,
    slidesToShow: imagesLength < slidesToShow ? imagesLength : slidesToShow,
    slidesToScroll: imagesLength < slidesToShow ? imagesLength : slidesToShow,
    adaptiveHeight: false,
    centerPadding: 0,
    arrows: false
  }
  const sliderRef = useRef()

  return (
    <>
      <LightgalleryProvider
        lightgallerySettings={{
          thumbnail: false,
          download: false,
          counter: false,
          actualSize: false
        }}
      >
        <ContentContainer>
          <SliderContainer>
            <CustomSlider ref={sliderRef} {...settings}>
              {props.images.map(slide => (
                <ItemCarroussel key={slide}>
                  <LightgalleryItem src={`/${slide}`} group="image">
                    <Image src={`/${slide}`} />
                  </LightgalleryItem>
                </ItemCarroussel>
              ))}
            </CustomSlider>
          </SliderContainer>
          <NextArrow onClick={() => sliderRef.current.slickNext()} />
          <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
        </ContentContainer>
      </LightgalleryProvider>
    </>
  )
}

export default Carroussel
