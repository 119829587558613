import styled from 'styled-components'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { md, sm } from 'helpers/constants'

import bgSearch from 'assets/images/bgSearch.png'

export const WrapBanner = styled.div`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) -22.7%,
      rgba(0, 0, 0, 0.4) 57.21%,
      rgba(0, 0, 0, 0) 124.38%
    ),
    url(${bgSearch}) center center;
  background-size: cover;
  width: 100%;
`
export const TitleBanner = styled.div`
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
`
export const BannerText = styled.p`
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
`

export const Image = styled.img`
  float: right;
  width: 100%;
  max-width: 440px;
`
export const Block = styled.div`
  align-items: center;
  display: flex;
  margin-top: 40px;
`
export const InformationContainer = styled.div`
  background: rgba(6, 179, 153, 0.8);
  border-radius: 8px;
  margin-right: 20px;
  padding: 8px 32px;
  text-align: center;
  width: 100%;
`

export const InformationTitle = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 8px;
`

export const InformationNumber = styled.span`
  color: #fff;
  font-size: 32px;
  font-weight: 600;
`
// Search
export const SearchFormStyled = styled.form`
  position: relative;
`

export const SearchContainer = styled(Container)`
  padding: 0;
  @media (max-width: ${md}) {
    padding: 0 16px 0 0;
  }
`

export const WrapButtons = styled(Grid)`
  position: relative;
  display: flex;
  @media (max-width: ${sm}) {
    .MuiInputBase-input {
      font-size: 11px;
    }
  }
`
