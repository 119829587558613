export default [
  { id: 'UNIVERSITY', name: 'INSTITUTION_TYPE:UNIVERSITY' },
  {
    id: 'INSTITUTE_RESEARCH_CENTER',
    name: 'INSTITUTION_TYPE:INSTITUTE_RESEARCH_CENTER'
  },
  { id: 'COMPANY', name: 'INSTITUTION_TYPE:COMPANY', isCompany: true },
  { id: 'GOVERNMENT_AGENCY', name: 'INSTITUTION_TYPE:GOVERNMENT_AGENCY' },
  { id: 'OTHERS', name: 'INSTITUTION_TYPE:OTHERS', isOther: true }
]
