import React, { useContext } from 'react'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import PrivacyPolicy from '../../../../../../../../assets/documents/PrivacyPolicy.pdf'
import TermsOfUse from '../../../../../../../../assets/documents/TermsOfUse.pdf'
import { CopyrightTitle, linkModalStyle, WrapCopyright, copyrightStyle } from './style'

function Copyright() {
  const { translate } = useContext(GlobalizationContext)

  return (
    <WrapCopyright
      display="flex"
      flexDirection="row"
      style={copyrightStyle}
    >
      <CopyrightTitle>
        {translate('NEWSLETTER_COPYRIGHT')}{' '}
        <a
          href={TermsOfUse}
          target="_blank"
          style={linkModalStyle}
          rel="noreferrer"
        >
          Termo de uso
        </a>{' '}
        e
        <a
          href={PrivacyPolicy}
          target="_blank"
          style={linkModalStyle}
          rel="noreferrer"
        >
          {' '}
          Política de Privacidade
        </a>
        .
      </CopyrightTitle>
    </WrapCopyright>
  )
}

export default Copyright
