import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { equipmentUseCases as EquipmentUseCases } from 'views/BackOffice/Subviews/Equipment/providers'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from './styles.jsx'

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null
  }
  return value
}

const FormSchema = Yup.object().shape({
  quantity: Yup.number()
    .required('VALIDATION:REQUIRED_ERROR')
    .max(100, 'VALIDATION:MAX_EXCEEDED')
    .min(1, 'VALIDATION:MIN_EXCEEDED')
    .transform(emptyStringToNull)
    .nullable()
})

export default function ReplicateEquipments(props) {
  const { equipment, onClose, onAction } = props
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const resolver = useYupValidationResolver(FormSchema, { translate })

  const { control, errors, handleSubmit } = useForm({
    mode: 'onChange',
    resolver,
    defaultValues: {
      files: []
    }
  })

  const onSubmit = async formData => {
    try {
      await EquipmentUseCases.replicateEquipments(
        equipment.id,
        formData.quantity
      )
      onAction()
    } catch (error) {
      onClose()
    }
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={Boolean(equipment)}
    >
      <Loader />
      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <DialogTitle id="customized-dialog-title" variant="h5">
          {translate('FORM_EQUIPMENT_REPLICATE_TITLE')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography
            gutterBottom
            variant="body1"
            style={{ marginBottom: '16px', fontSize: '14px' }}
          >
            {translate('FORM_EQUIPMENT_REPLICATE_MODAL_LABEL')}
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <BaseTextField
                required={true}
                type="number"
                label={translate('FORM_EQUIPMENT_TEXT_LABEL')}
                placeholder={translate('FORM_EQUIPMENT_PLACEHOLDER_LABEL')}
                errors={errors}
                control={control}
                name="quantity"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.baseButtonGrey}
            onClick={onClose}
            variant="contained"
          >
            {translate('LIST_EQUIPMENT_ACTION_CANCEL')}
          </Button>
          <Button
            className={classes.baseButton}
            type="submit"
            variant="contained"
          >
            {translate('LIST_EQUIPMENT_ACTION_REPLICATE')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
