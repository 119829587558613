import React, { useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import { red, grey } from '@material-ui/core/colors'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'

import { UsersService } from 'api'
import { confirmColor, specialCharactersRegex } from 'helpers/constants'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { history } from 'helpers/history'
import { cpfMask } from 'helpers/maskedCPF'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Loader from 'ui/atoms/Loader'
import { SuccessMessage, ErrorMessage } from 'ui/atoms/Messages'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import Header from 'ui/organisms/Header/Private'

const useStyles = makeStyles({
  defaultContainer: {
    marginTop: '1.5rem'
  },
  baseButton: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#009B7F',
    color: '#ffffff',
    padding: '9px',
    fontWeight: 'normal',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#006855',
      color: '#ffffff'
    }
  },
  passwordValidationValid: {
    color: confirmColor,
    padding: '0'
  },
  passwordValidationInvalid: {
    color: red[500],
    padding: '0'
  },
  passwordValidationIcon: {
    fontSize: '18px',
    minWidth: '32px'
  },
  passwordValidationText: {
    fontSize: '14px'
  },
  passwordValidationUntouched: {
    color: `${grey[500]} !important`,
    padding: '0'
  },
  passwordValidationTextClass: {
    color: 'inherit',
    fontWeight: 'inherit'
  }
})

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const formSchema = Yup.object()
  .shape({
    cpf: Yup.string().cpf().digitsOnly(),
    password: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
    confirmPassword: Yup.string()
      .required('LOGIN_CHANGE_PASSWORD_CONFIRM')
      .test(
        'isPasswordEqual',
        'LOGIN_CHANGE_PASSWORD_MUST_MATCH',
        function (value) {
          return this.parent.password === value
        }
      )
  })
  .test('mustContainLowerCase', '', function (data) {
    if (/[a-z]/.test(data.password)) return true
    return new Yup.ValidationError(
      'mustContainLowerCase',
      null,
      'mustContainLowerCase',
      'mustContainLowerCase'
    )
  })
  .test('mustContainUpperCase', '', function (data) {
    if (/[A-Z]/.test(data.password)) return true
    return new Yup.ValidationError(
      'mustContainUpperCase',
      null,
      'mustContainUpperCase',
      'mustContainUpperCase'
    )
  })
  .test('mustContainNumber', '', function (data) {
    if (/[\d]/.test(data.password)) return true
    return new Yup.ValidationError(
      'mustContainNumber',
      null,
      'mustContainNumber',
      'mustContainNumber'
    )
  })
  .test('mustContainSpecialCharacter', '', function (data) {
    if (specialCharactersRegex.test(data.password)) return true
    return new Yup.ValidationError(
      'mustContainSpecialCharacter',
      null,
      'mustContainSpecialCharacter',
      'mustContainSpecialCharacter'
    )
  })
  .test('mustContain8Characters', '', function (data) {
    if (data.password && data.password.length >= 8) return true
    return new Yup.ValidationError(
      'mustContain8Characters',
      null,
      'mustContain8Characters',
      'mustContain8Characters'
    )
  })

function ResetPassword() {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()
  const query = useQuery()

  const [passwordResetSent, setPasswordResetSent] = useState(false)
  const [passwordResetError, setPasswordResetError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const resolver = useYupValidationResolver(formSchema, { translate })

  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    errors,
    formState
  } = useForm({
    mode: 'onChange',
    resolver
  })

  const { isValid, dirtyFields } = formState

  useEffect(() => {
    setIsLoading(true)
    UsersService.getCpfFromToken(query.get('token'))
      .then(resp => {
        setValue('cpf', resp.cpf)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    try {
      trigger([
        'mustContainLowerCase',
        'mustContainUpperCase',
        'mustContainNumber',
        'mustContainSpecialCharacter',
        'mustContain8Characters'
      ])
    } catch (error) {
      console.log(error)
    }
  }, [trigger])

  const onSubmit = formData => {
    setIsLoading(true)
    const postData = {
      token: query.get('token'),
      ...formData
    }

    UsersService.changePassword(postData)
      .then(() => {
        setPasswordResetSent(true)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err.response.data.message === 'reset.token.alread.used')
        if (err.response?.data?.message === 'reset.token.alread.used') {
          setPasswordResetError('LOGIN_TOKEN_ALREAD_USED')
        } else if (err.response?.data?.message) {
          setPasswordResetError(err.response.data.message)
        }
      })
  }

  const handleClose = () => {
    history.push('/')
  }

  return (
    <React.Fragment>
      <Loader open={isLoading}></Loader>
      <Header hideActions={true} showBurger={false} />
      <Container>
        <Grid container justify="center">
          <Grid item xs={10}>
            <BaseContainerHeader
              label={translate('RESET_PASSWORD_TITLE')}
              showCloseButton={true}
              onClose={handleClose}
            />

            <Grid
              container
              justify="center"
              className={classes.defaultContainer}
            >
              <Grid item md={6}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  {...disableSubmitOnEnter}
                >
                  <input type="hidden" autoComplete="false" />
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <BaseTextField
                        required={true}
                        label={translate('RESET_PASSWORD_CPF_LABEL')}
                        placeholder={translate(
                          'RESET_PASSWORD_CPF_PLACEHOLDER'
                        )}
                        control={control}
                        name="cpf"
                        readOnly={true}
                        autoComplete="off"
                        inputComponent={cpfMask}
                      ></BaseTextField>
                    </Grid>
                    <Grid item xs={12}>
                      <BaseTextField
                        readOnly={passwordResetSent}
                        required={true}
                        label={translate('RESET_PASSWORD_NEW_LABEL')}
                        placeholder={translate(
                          'RESET_PASSWORD_NEW_PLACEHOLDER'
                        )}
                        errors={errors}
                        control={control}
                        handleChange={() => {
                          trigger([
                            'mustContainLowerCase',
                            'mustContainUpperCase',
                            'mustContainNumber',
                            'mustContainSpecialCharacter',
                            'mustContain8Characters'
                          ])
                        }}
                        name="password"
                        type="password"
                        autoComplete="off"
                      ></BaseTextField>
                    </Grid>
                    <Grid item xs={12}>
                      <BaseTextField
                        readOnly={passwordResetSent}
                        required={true}
                        label={translate('RESET_PASSWORD_REPEAT_LABEL')}
                        placeholder={translate(
                          'RESET_PASSWORD_REPEAT_PLACEHOLDER'
                        )}
                        errors={errors}
                        control={control}
                        name="confirmPassword"
                        type="password"
                        autoComplete="off"
                      ></BaseTextField>
                    </Grid>
                    <Grid item xs={12}>
                      <List>
                        {[
                          {
                            key: 'mustContainLowerCase',
                            message: translate('LOGIN_MUST_CONTAIN_LOWER_CASE')
                          },
                          {
                            key: 'mustContainUpperCase',
                            message: translate('LOGIN_MUST_CONTAIN_UPPER_CASE')
                          },
                          {
                            key: 'mustContainNumber',
                            message: translate('LOGIN_MUST_CONTAIN_NUMBER')
                          },
                          {
                            key: 'mustContainSpecialCharacter',
                            message: translate(
                              'LOGIN_MUST_CONTAIN_SPECIAL_CHARACTER'
                            )
                          },
                          {
                            key: 'mustContain8Characters',
                            message: translate('LOGIN_MUST_CONTAIN8_CHARACTERS')
                          }
                        ].map(item => {
                          let itemClass = ''
                          if (!dirtyFields.password || errors[item.key]) {
                            itemClass = classes.passwordValidationUntouched
                          } else if (
                            dirtyFields.password &&
                            !errors[item.key]
                          ) {
                            itemClass = classes.passwordValidationValid
                          }

                          return (
                            <ListItem key={item.key} className={itemClass}>
                              <ListItemIcon
                                className={classes.passwordValidationIcon}
                              >
                                <CheckIcon className={itemClass} />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="body2"
                                    className={
                                      classes.passwordValidationTextClass
                                    }
                                  >
                                    {item.message}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          )
                        })}
                      </List>
                    </Grid>
                    <Grid container justify="center">
                      {passwordResetSent ? (
                        <Grid xs={12} md={8} item>
                          <SuccessMessage>
                            {translate('RESET_PASSWORD_SUCCESS')}
                          </SuccessMessage>
                        </Grid>
                      ) : null}
                      {passwordResetError ? (
                        <Grid xs={12} md={8} item>
                          <ErrorMessage>
                            {translate(passwordResetError)}
                          </ErrorMessage>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        // disabled={ formState.isValid ? false : true}
                        fullWidth
                        type={'submit'}
                        variant="contained"
                        disabled={!isValid || passwordResetSent}
                        className={classes.baseButton}
                      >
                        {translate('RESET_PASSWORD_SEND')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default ResetPassword
