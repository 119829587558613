import React, { useContext, useState } from 'react'

import { Button } from '@material-ui/core'

import { history } from 'helpers'

import BaseTextFieldPublic from 'ui/atoms/BaseTextFieldPublic.js'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import { SearchFormStyled, SearchContainer, WrapButtons } from './style'

function SearchForm(props) {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const [value, setValue] = useState('')

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleSubmit = () => {
    history.push(`/search?term=${value}`)
  }

  return (
    <SearchFormStyled onSubmit={handleSubmit}>
      <SearchContainer maxWidth="md">
        <WrapButtons item xs={12}>
          <BaseTextFieldPublic
            placeholder={props.placeholder}
            handleChange={event => handleChange(event)}
          />
          <Button
            className={classes.baseButton}
            onClick={handleSubmit}
            variant="contained"
            style={{ marginLeft: '4px', height: '38px' }}
          >
            {translate('SEARCH_BUTTON')}
          </Button>
        </WrapButtons>
      </SearchContainer>
    </SearchFormStyled>
  )
}

export default SearchForm
