import React from 'react'
import { Trans } from 'react-i18next'

import { Grid } from '@material-ui/core'

import TitleIcon from 'ui/atoms/BasePublic/TitleIcon'
import { useGlobalizationContext } from 'ui/atoms/Globalization'

import Corgs from 'assets/icons/corgs.svg'
import LabIcon from 'assets/icons/lab.svg'
import TempleIcon from 'assets/icons/temple.svg'

import { Text, CardContainer, Card, ImageContainer } from '../../style'
import CardTitle from '../CardTitle'

const InstitutionResponsible = () => {
  const { translate } = useGlobalizationContext()

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <TitleIcon
            size="1"
            iconName="landmark"
            content="INSTITUTION_RESPONSIBLE.TITLE"
            icon={undefined}
          />
        </Grid>
      </Grid>

      <CardContainer>
        <Card>
          <CardTitle
            text={translate('INSTITUTION_RESPONSIBLE.COLUMN_SUBTITLE_1')}
            titleNumber={1}
          />
          <ImageContainer>
            <img src={TempleIcon} alt="" />
          </ImageContainer>
          <Trans
            parent={Text}
            t={translate}
            i18nKey="INSTITUTION_RESPONSIBLE.COLUMN_TEXT_1"
          />
        </Card>
        <Card>
          <CardTitle
            text={translate('INSTITUTION_RESPONSIBLE.COLUMN_SUBTITLE_2')}
            titleNumber={2}
          />
          <ImageContainer>
            <img src={Corgs} alt="" />
          </ImageContainer>
          <Trans
            parent={Text}
            t={translate}
            i18nKey="INSTITUTION_RESPONSIBLE.COLUMN_TEXT_2"
          />
        </Card>
        <Card>
          <CardTitle
            text={translate('INSTITUTION_RESPONSIBLE.COLUMN_SUBTITLE_3')}
            titleNumber={3}
          />
          <ImageContainer>
            <img src={LabIcon} alt="" />
          </ImageContainer>
          <Trans
            parent={Text}
            t={translate}
            i18nKey="INSTITUTION_RESPONSIBLE.COLUMN_TEXT_3"
          />
        </Card>
      </CardContainer>
    </>
  )
}

export default InstitutionResponsible
