import React, { useContext } from 'react'

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { UsersService } from 'api'
import { AuthContext } from 'context/AuthContext'

import PrivacyPolicy from '../../../../../../assets/documents/PrivacyPolicy.pdf'
import TermsOfUse from '../../../../../../assets/documents/TermsOfUse.pdf'
import {
  ModalTitle,
  MsgErrorCheckBox,
  ErrorCheckBox,
  linkModalStyle,
  closeButtonStyle,
  formGroupStyle,
  checkboxStyle,
  requiredLabel
} from './style'
export default function AlertDialog({ userLogData }) {
  const [open, setOpen] = React.useState(!userLogData.termsOfUse)
  const [checkedTerm, setCheckedTerm] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const { userData } = useContext(AuthContext)


  const handleChange = event => {
    setCheckedTerm(event.target.checked)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSave = async () => {
    if (checkedTerm) {
      try {
        await UsersService.putTermOfUse(userLogData.userId)
        userData.termOfUse = true
        setOpen(false)
      } catch (error) {
        console.log(error)
      }
    } else {
      setShowError(true)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <ModalTitle id="alert-dialog-title">
          Termo de ciência de aviso de privacidade e termo de uso da Plataforma
          Nacional de Infraestrutura de Pesquisa – PNIPE
          <IconButton onClick={handleClose} style={closeButtonStyle}>
            <Close />
          </IconButton>
        </ModalTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            color="textPrimaryColor"
            align="justify"
          >
            O MCTI fica autorizado a tratar os dados pessoais para fins de uso
            na comunicação entre as partes envolvidas no compartilhamento de
            laboratórios e equipamentos, respeitando o{' '}
            <a
              href={TermsOfUse}
              target="_blank"
              style={linkModalStyle}
              rel="noreferrer"
            >
              Termo de uso
            </a>{' '}
            e a
            <a
              href={PrivacyPolicy}
              target="_blank"
              style={linkModalStyle}
              rel="noreferrer"
            >
              {' '}
              Política de Privacidade
            </a>
            .
            <FormGroup style={formGroupStyle}>
            <FormLabel component="legend" style={requiredLabel}> * </FormLabel>

              <FormControlLabel
                control={
                  <Checkbox
                    style={checkboxStyle}
                    required={true}
                    name="checkedTerm"
                    onChange={handleChange}
                  />
                }
                label={
                  <Typography>
                    Estou ciente das condições de uso dos dados pessoais
                    previstas na Política de Privacidade e Termo de Uso da
                    Plataforma Nacional de Infraestrutura de Pesquisa – PNIPE.
                  </Typography>
                }
              />

           
            </FormGroup>
            {showError ? (
                <ErrorCheckBox>
                  <MsgErrorCheckBox>* Campo obrigatório</MsgErrorCheckBox>
                </ErrorCheckBox>
              ) : (
                ''
              )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button variant="contained" onClick={handleClose}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSave}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}
