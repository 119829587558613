import styled, { css } from 'styled-components'

export const MarkerGreen = css`
  background-color: #95f204;
`
export const MarkerOrange = css`
  background-color: #f59a23;
`
export const MarkerRed = css`
  background-color: #d9001b;
`

export const Marker = styled.p`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`
