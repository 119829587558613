import { UsersService } from 'api'

import { notificationUseCases } from 'views/Notification/providers'

import MyProfileUseCases from './myProfile.useCases'

export const myProfileUseCases = new MyProfileUseCases({
  UsersService,
  notificationUseCases
})
