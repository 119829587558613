import React from 'react'

import { Grid } from '@material-ui/core'

import Container from './Container'

const ContentContainer = ({ children, ...props }) => {
  return (
    <Container style={{ flex: '1 1 auto' }} maxWidth="lg" {...props}>
      <Grid container justify="space-around">
        <Grid item xs={12} sm={12}>
          {children}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ContentContainer
