import React, { useState, useContext } from 'react'

import { AuthContext } from 'context/AuthContext'

import ChangePictureDialog from './Fragments/ChangePictureDialog'
import Picture from './Fragments/Picture'

const ProfilePicture = ({
  defaultValue,
  src,
  onPictureSelected: handlePictureSelected
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const onPictureSelected = newPicture => {
    setModalOpen(false)
    handlePictureSelected(newPicture)
  }

  const imageSrc = typeof src === 'string' ? src : src?.tempUrl

  return (
    <>
      <Picture
        src={imageSrc}
        onClick={() => setModalOpen(true)}
        defaultValue={defaultValue}
      />
      <ChangePictureDialog
        src={imageSrc}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onPictureSelected={onPictureSelected}
      />
    </>
  )
}

export default ProfilePicture
