import React, { useEffect, useContext } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title, Subtitle } from 'ui/atoms/Title'

import bgAbout from 'assets/images/bgAbout.jpg'

const Text = styled.p`
  color: #333;
  font-size: 14px;
  line-height: 22px;
`

const WrapImage = styled.div`
  background: #141b3b url(${bgAbout}) center center;
  background-size: cover;
  height: 440px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    padding-bottom: 120px;
  }
  @media (max-width: 640px) {
    padding-bottom: 60px;
  }
`

const PhotoCredit = styled.div`
  font-size: 14px;
  text-align: right;
  float: right;
  display: flex;
  align-items: center;
`

const ListContainer = styled.div`
  li {
    margin: 20px 0;
  }
`

function About() {
  const { translate } = useContext(GlobalizationContext)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ContentContainer>
      <Grid container>
        <WrapImage />
      </Grid>
      <Grid container justify="flex-end">
        <PhotoCredit>
          <Text>{translate('PUBLIC_AREA_ABOUT_SOURCE')}</Text>{' '}
          <b style={{ marginLeft: '5px' }}>ASCOM/MCTI</b>
        </PhotoCredit>

        <Title variant="h1" item xs={12} $paddingTop>
          {translate('PUBLIC_AREA_ABOUT_TITLE_1')}
        </Title>
      </Grid>

      <Box m={6} style={{ marginTop: 0 }}>
        <Grid container style={{ marginBottom: '20px' }}>
          <Typography component={Grid} variant="body2" item xs={12}>
            <Subtitle
              variant="h2"
              item
              xs={12}
              $paddingTop
              style={{ paddingBottom: '20px' }}
            >
              {translate('PUBLIC_AREA_ABOUT_SUBTITLE_1')}
            </Subtitle>

            <Trans t={translate} i18nKey="PUBLIC_AREA_ABOUT_1" />

            <Trans
              parent={ListContainer}
              t={translate}
              i18nKey="PUBLIC_AREA_ABOUT_BULLET_LIST_1"
            />
          </Typography>
        </Grid>

        <Grid container style={{ marginBottom: '20px' }}>
          <Subtitle variant="h2" item xs={12} style={{ paddingBottom: '20px' }}>
            {translate('PUBLIC_AREA_ABOUT_SUBTITLE_2')}
          </Subtitle>

          <Typography component={Grid} variant="body2" item xs={12}>
            <Trans t={translate} i18nKey="PUBLIC_AREA_ABOUT_2" />
            <Trans
              parent={ListContainer}
              t={translate}
              i18nKey="PUBLIC_AREA_ABOUT_BULLET_LIST_2"
            />
            <Trans t={translate} i18nKey="PUBLIC_AREA_ABOUT_3" />
          </Typography>
        </Grid>
      </Box>
    </ContentContainer>
  )
}

export default About
