import React, { useContext } from 'react'
import { useForm, useWatch } from 'react-hook-form'

import { Button, Grid, Typography } from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseDatePicker from 'ui/atoms/BaseDatePicker'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'
import { requestSharingUseCases } from 'views/BackOffice/Subviews/RequestSharing/providers'

import formSchema from './formSchema'
import { Container } from './style'

const UsageForm = ({ sharingRequest, onCancel: handleCancel }) => {
  const { translate } = useContext(GlobalizationContext)

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {}
  })

  const { errors, control, handleSubmit } = formMethods

  const wasShared = useWatch({ control, name: 'wasShared' })

  const onSubmit = async formData => {
    const requestData = {
      sharingDone: formData.wasShared,
      endExperimentDate: formData.sharedDateTo,
      startExperimentDate: formData.sharedDateFrom
    }

    try {
      await requestSharingUseCases.declareUsage(sharingRequest.id, requestData)
      history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
      <div style={{ margin: '12px' }}>
        <Grid container component={Container} spacing={3}>
          <Grid item xs={12}>
            <RadioGroup
              required
              name="wasShared"
              label={translate('DETAILS.DECLARE_USAGE.WAS_SHARED')}
              control={control}
              error={errors}
              inline
              row
              options={[
                { id: true, name: translate('COMMONS:YES') },
                { id: false, name: translate('COMMONS:NO') }
              ]}
            />
          </Grid>
          {wasShared === 'true' ? (
            <Grid item xs={12} sm={10} md={5}>
              <Grid container item spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {translate('DETAILS.DECLARE_USAGE.SHARED_DATE_LABEL')}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <BaseDatePicker
                    name="sharedDateFrom"
                    label={translate('DETAILS.DECLARE_USAGE.SHARED_DATE_FROM')}
                    placeholder={translate(
                      'DETAILS.DECLARE_USAGE.SHARED_DATE_FROM_PLACEHOLDER'
                    )}
                    control={control}
                    error={errors.sharedDateFrom}
                    required
                    fullWidth
                    disableFuture
                    openTo="year"
                    views={['year', 'month', 'date']}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <BaseDatePicker
                    name="sharedDateTo"
                    label={translate('DETAILS.DECLARE_USAGE.SHARED_DATE_TO')}
                    placeholder={translate(
                      'DETAILS.DECLARE_USAGE.SHARED_DATE_TO_PLACEHOLDER'
                    )}
                    control={control}
                    error={errors.sharedDateTo}
                    required
                    fullWidth
                    disableFuture
                    openTo="year"
                    views={['year', 'month', 'date']}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {typeof wasShared !== 'undefined' ? (
            <Grid container justify="flex-end" spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {translate('COMMONS:CANCEL')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  {translate('REGISTER.SEND')}
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </div>
    </form>
  )
}

export default UsageForm
