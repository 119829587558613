import React from 'react'
import styled from 'styled-components'

import { BreadcrumbSeparator, BreadCrumbs, BreadcrumbItemLink } from '../style'

const Icon = styled.i`
  font-size: 1rem;
  display: flex;
`

const Breadcrumb = ({ match, item }) => {
  return (
    <BreadCrumbs separator={<BreadcrumbSeparator />}>
      <BreadcrumbItemLink to="/">
        <Icon className="fas fa-home"></Icon>
      </BreadcrumbItemLink>
    </BreadCrumbs>
  )
}

export default Breadcrumb
