import clsx from 'clsx'
import { usePopupState, bindPopover } from 'material-ui-popup-state/hooks'
import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import Popover from '@material-ui/core/Popover'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const Icon = styled.i`
  font-size: 20px;
  margin-left: 8px;
  cursor: pointer;
`

const Content = styled.div`
  font-size: 14px;
  max-width: 385px;
  padding: 20px;
  margin: 0;
`

export default function SimplePopover({
  className,
  fontSize,
  icon,
  color,
  content
}) {
  const { translate } = useContext(GlobalizationContext)

  const popupState = usePopupState({ variant: 'popover', popupId: 'hint' })

  return (
    <>
      <Icon
        className={clsx(className, icon ? icon : 'far fa-question-circle')}
        style={{ color: color ? color : '#000000', ...{ fontSize } }}
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          popupState.toggle(event)
        }}
      />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Content>
          <Trans
            t={translate}
            i18nKey={content}
            components={{ strong: <strong />, br: <br /> }}
          />
        </Content>
      </Popover>
    </>
  )
}
