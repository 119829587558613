import styled from 'styled-components'

import { primaryColor } from 'helpers/constants'


export const ModalTitle = styled.div`
  font-style: normal;
  font-weight: 600;

  display: flex;
  align-items: center;

  font-size: 1.6rem;
  margin-left: 1.5rem;
  margin-top: 2rem;
  color: #000000;
  margin-bottom: 2rem;
`

export const LinkLGPD = styled.a`
  list-style: none;
  padding: 0;
  margin: 0;
  color: #009b7f;
  margin-left: 5px;
`



export const CheckboxContainer = styled.div`
  color: #000000;
`

export const ErrorCheckBox = styled.div`
`

export const MsgErrorCheckBox = styled.p`
    color: #f44336;
    font-weight: 600;
    margin: 0;
    font-size: 0.9 rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Raleway Bold", "Raleway Regular", Raleway, sans-serif;
    line-height: 1.66;
    margin-left: 1%;
`

export const linkModalStyle = { color: '#009b7f', textDecoration: 'none' }
export const formGroupStyle = { marginTop: '3%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center',  alignContent: 'center'}
export const checkboxStyle = { lineHeight: '20px', fontSize: '15px' }
export const requiredLabel = { fontSize: '1.5rem',  marginTop:'2%',  marginTight: '1%',  marginLeft: '1%', marginRight: '1%' }
export const closeButtonStyle = {
  marginTop: '-3rem',
  marginRight: '1rem',
  background: '#009b7f',
  color: '#fff',
  padding: '4px'
}