import clsx from 'clsx'
import React, { useCallback, useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'

import { InstitutionRegister, UsersService } from 'api'
import { InstitutionalInvite } from 'api/InstitutionalInvite'
import { AuthContext } from 'context/AuthContext'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { zipCode } from 'helpers/generalInputMasks'
import { history, generatePrivatePath } from 'helpers/history'
import { cnpjMask } from 'helpers/maskedCNPJ'
import { phoneMask } from 'helpers/maskedPhone'
import { textToConstant } from 'helpers/textToConstant'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { TableChip } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { WarningMessage } from 'ui/atoms/Messages'
import Address, { getAddressType } from 'ui/molecules/Address/index'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import InviteModal from './Fragments/Invite'
import ResponsibleData from './Fragments/ResponsibleData'
import { WrapEmail, WrapContent, UndoIcon } from './styles'

const InstitutionSchema = Yup.object().shape({
  cnpj: Yup.string().required('REQUIRED_ERROR').cnpj('CNPJ_ERROR'),
  initials: Yup.string().required('REQUIRED_ERROR'),
  social_reason: Yup.string().required('REQUIRED_ERROR'),
  zipCode: Yup.string().required('REQUIRED_ERROR'),
  street: Yup.string().required('REQUIRED_ERROR'),
  number: Yup.string().required('REQUIRED_ERROR').trim(),
  complement: Yup.string(),
  district: Yup.string().required('REQUIRED_ERROR'),
  city: Yup.string().required('REQUIRED_ERROR'),
  state: Yup.string().required('REQUIRED_ERROR'),
  region: Yup.string().required('REQUIRED_ERROR'),
  phoneNumber: Yup.string()
    .digitsOnly()
    .required()
    .phone('VALIDATION:PHONE_ERROR')
    .typeError('REQUIRED_ERROR')
})

function Institution() {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const { userData, refreshCurrentUserData } = useContext(AuthContext)

  const [userList, setUserList] = useState([])
  const [responsible, setResponsible] = useState(null)
  const [responsibleDocuments, setResponsibleDocuments] = useState([])
  const [activeRepresentantsList, setActiveRepresentantsList] = useState([])
  const [formNeedsSaving, setFormNeedsSaving] = useState(false)
  const [hasTotalRole, setHasTotalRole] = useState(false)
  const [modalInviteOpen, setModalInviteOpen] = useState(null)
  const [isEmailInviteEmpty, setIsEmailInviteEmpty] = useState(true)
  const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState(false)
  const [emailAlreadyInInstitution, setEmailAlreadyInInstitution] =
    useState(false)
  const [confirmRemoveSelf, setConfirmRemoveSelf] = useState(null)
  const [institutionSituation, setInstitutionSituation] = useState(null)

  const resolver = useYupValidationResolver(InstitutionSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver
  })

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    reset: resetForm,
    errors,
    formState: mainFormState
  } = formMethods

  const { dirtyFields: mainFormDirtyFields } = mainFormState

  const initData = useCallback(
    data => {
      const institutionData = {
        ...data.institution,
        cnpj: data.institution.cnpj,
        addressType: getAddressType(data.institution),
        zipCode: zipCode(data.institution.zipCode)
      }

      setResponsible(data?.responsible)
      setResponsibleDocuments(data?.institution.documentList)
      setUserList(data.representative_list)
      setActiveRepresentantsList(data?.representativeActiveList)
      setInstitutionSituation(data.institution.situation)
      resetForm(institutionData)
    },
    [resetForm]
  )

  useEffect(() => {
    if (!userData) return

    setHasTotalRole(userData.institutionRole === 'ROLE_INSTITUICAO_TOTAL')

    UsersService.getInstitution()
      .then(resp => {
        initData(resp)
      })
      .catch(() => {})
  }, [initData, userData])

  const checkEmailAlreadyInvited = async () => {
    const email = getValues('email_invite')
    const cnpj = getValues('cnpj').replace(/\D/gm, '')

    if (userList.findIndex(item => item.email === email) >= 0) {
      return setError('email_invite', {
        type: 'emailAlreadyExists',
        message: translate('REGISTER_INSTITUTION_USER_ALREADY_INVITED')
      })
    }

    try {
      await InstitutionalInvite.getEmailAlreadySent(cnpj, email)

      setEmailAlreadyInInstitution(true)
    } catch (error) {
      const message = error.response?.data?.message

      if (['user.not.found', 'invite.not.found'].indexOf(message) >= 0) {
        handleOpenInvite()
      } else if (message === 'invalid.user.situation') {
        setError('email_invite', {
          type: 'invalidUser',
          message: translate('ERRORS:INVALID_USER_SITUATION')
        })
      } else {
        notificationUseCases.newError()
      }
    }
  }

  const handleOpenInvite = useCallback(async () => {
    clearErrors('email_invite')
    const email = getValues('email_invite')
    const currentUserActiveLength = userList.filter(
      item => item.situation === 'ACTIVE'
    ).length

    if (
      userList.filter(
        user => user.situation === 'ACTIVE' || user.situation === 'PENDING'
      ).length >= 10
    ) {
      return setError('email_invite', {
        type: 'emailAlreadyExists',
        message: translate('REGISTER_INSTITUTION_MAXIMUM_USERS_REACH')
      })
    }

    if (currentUserActiveLength < 10) {
      const valid = await Yup.string().email().isValid(email)
      if (!valid)
        return setError('email_invite', {
          type: 'email',
          message: 'Email inválido'
        })

      if (userList.findIndex(item => item.email === email) >= 0)
        return setError('email_invite', {
          type: 'emailAlreadyExists',
          message: translate('REGISTER_INSTITUTION_USER_ALREADY_INVITED')
        })

      setModalInviteOpen(email)
    } else {
      return setError('email_invite', {
        type: 'limitExceeded',
        message: translate('REGISTER_INSTITUTION_ADD_INVITE_LIMIT_EXCEEDED')
      })
    }
  }, [clearErrors, getValues, userList, setError, translate])

  const handleInstitutionCancel = () => {
    setOpenConfirmCancelDialog(false)
    history.push(generatePrivatePath('/'))
  }

  const handlePermission = (event, index) => {
    const newValue = event.target.value
    setUserList(oldUserList => {
      setFormNeedsSaving(true)
      const rowCopy = { ...oldUserList[index], permission: newValue }
      const listCopy = [...oldUserList]
      listCopy[index] = rowCopy
      return listCopy
    })
  }

  const handleSituation = useCallback((event, index) => {
    const newValue = event.target.value
    setUserList(oldUserList => {
      setFormNeedsSaving(true)
      const rowCopy = { ...oldUserList[index], situation: newValue }
      const listCopy = [...oldUserList]
      listCopy[index] = rowCopy
      return listCopy
    })
  }, [])

  const handleInvite = async newInvite => {
    setFormNeedsSaving(true)
    setUserList(oldUserList => {
      return [...oldUserList, newInvite]
    })
    setValue('email_invite', '')
    setModalInviteOpen(null)
  }

  const handleCloseInvite = () => {
    setValue('email_invite', '')
    setModalInviteOpen(null)
  }

  const cleanUserList = () => {
    const newUserList = userList.map(item => ({
      id: item.id || null,
      email: item.email,
      name: item.name,
      permission: item.permission,
      situation: !item.deleted ? item.situation : 'DELETED'
    }))

    return newUserList
  }

  const onChangeResponsible = user => {
    setResponsible(user)
  }

  const onSubmit = async formData => {
    if (formData.email_invite) {
      setIsEmailInviteEmpty(false)
      return
    }

    if (!responsible) {
      return setError('responsibleSelect', {
        type: 'responsibleSelect',
        message: translate('REQUIRED_ERROR')
      })
    }

    const representativeList = cleanUserList()
    const hasAtLeastOneTotal =
      representativeList.filter(
        representative =>
          representative.permission === 'ROLE_INSTITUICAO_TOTAL' &&
          representative.situation === 'ACTIVE' &&
          !representative.deleted
      ).length > 0

    if (!hasAtLeastOneTotal) {
      notificationUseCases.newError(
        translate('REGISTER_INSTITUTION_AT_LEAST_ONE_TOTAL')
      )
      return
    }

    const objInstitution = {
      institution: {
        city: getValues('city'),
        complement: getValues('complement'),
        district: getValues('district'),
        number: getValues('number'),
        region: getValues('region'),
        state: getValues('state'),
        street: getValues('street'),
        zipCode: getValues('zipCode').replace(/[^\d]+/g, ''),
        cnpj: getValues('cnpj'),
        initials: getValues('initials'),
        social_reason: getValues('social_reason'),
        phoneNumber: getValues('phoneNumber'),
        documentList: responsibleDocuments,
        situation: institutionSituation
      },
      representative_list: cleanUserList(),
      responsible: responsible
    }

    await InstitutionRegister.postInstitution(objInstitution)
      .then(async data => {
        initData(data)
        notificationUseCases.newSuccess('DEFAULT_SUCCESS')
        if (formNeedsSaving) {
          await refreshCurrentUserData()
        }
        history.push(generatePrivatePath('/'))
      })
      .catch(error => {
        if (
          error.response.status === 400 &&
          error.response.data.message == 'input.invalid'
        ) {
          notificationUseCases.newError('SPECIAL_CHARS')
        } else if (
          error.response.data.message === 'user.already.other.institution'
        ) {
          notificationUseCases.newError(
            translate('REGISTER_INSTITUTION_ALREADY_IN_OTHER_INSTITUTION')
          )
        } else {
          notificationUseCases.newError()
        }
      })

    await uploadFilesInSequence(
      InstitutionRegister.registerDocument,
      formData.cnpj,
      responsibleDocuments
    )

    setFormNeedsSaving(false)
  }

  const uploadFilesInSequence = async (uploadMethod, id, list) => {
    const uploadedFiles = await list.reduce(
      async (promise, { file, ...savedFile }) => {
        return promise
          .then(uploadedFiles => {
            if (savedFile && savedFile.id) {
              return Promise.resolve(uploadedFiles.concat(file))
            } else {
              return uploadMethod(id, file)
                .then(resp => {
                  return uploadedFiles.concat(resp.data)
                })
                .catch(() => {
                  return uploadedFiles.concat(file)
                })
            }
          })
          .then(data => {
            return data
          })
      },
      Promise.resolve([])
    )

    return uploadedFiles
  }

  const removeInvite = useCallback(
    item => {
      clearErrors('email_invite')
      if (item.id) {
        setUserList(oldList => {
          const newList = [...oldList]
          const itemIndex = newList.findIndex(obj => obj.id === item.id)
          newList[itemIndex] = {
            ...newList[itemIndex],
            deleted: true
          }
          return newList
        })
      } else {
        setUserList(oldList => oldList.filter(obj => obj.email !== item.email))
      }
      setFormNeedsSaving(true)
    },
    [clearErrors]
  )

  const checkRemoveInvite = useCallback(
    item => {
      if (userData.email !== item.email) {
        removeInvite(item)
      } else {
        setConfirmRemoveSelf(item)
      }
    },
    [removeInvite, userData]
  )

  const undoRemoveInvite = useCallback(item => {
    setUserList(oldList => {
      const newList = [...oldList]
      const itemIndex = newList.findIndex(obj => obj.email === item.email)
      delete newList[itemIndex].deleted
      return newList
    })
  }, [])

  const canEditRow = row => {
    return (
      userData &&
      userData.institutionRole === 'ROLE_INSTITUICAO_TOTAL' &&
      !row.responsible &&
      !row.userInactive &&
      !row.deleted &&
      ['PENDING', 'REJECTED', 'DELETED'].indexOf(row.situation) == -1
    )
  }

  const onFileChange = fileList => {
    if (!fileList || !fileList.length) return
    setResponsibleDocuments(documents => documents.concat(fileList))
  }

  const onRemoveFile = file => {
    const remaningFiles = responsibleDocuments.filter(item => item !== file)
    setResponsibleDocuments(remaningFiles)
  }

  return (
    <React.Fragment>
      <Loader />
      {modalInviteOpen ? (
        <InviteModal
          email={modalInviteOpen}
          onInvite={handleInvite}
          onClose={handleCloseInvite}
        />
      ) : null}

      <BaseContainerHeader
        label={translate('REGISTER_INSTITUTION_TITLE')}
        showCloseButton={false}
      />
      <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
        <div className={classes.defaultContainer}>
          <h3 className={classes.h3}>
            {translate('REGISTER_INSTITUTION_DATA')}
          </h3>
          <Grid
            container
            direction={'row'}
            spacing={3}
            style={{ marginBottom: '20px' }}
          >
            <Grid item xs={12} md={10}>
              <BaseTextField
                disabled={true}
                required={true}
                label={translate('REGISTER_INSTITUTION_COMPANY_NAME')}
                placeholder={translate('REGISTER_INSTITUTION_COMPANY_NAME')}
                errors={errors}
                control={control}
                name="social_reason"
              ></BaseTextField>
            </Grid>

            <Grid item xs={12} md={2}>
              <BaseTextField
                disabled={true}
                required={true}
                label={translate('REGISTER_INSTITUTION_COMPANY_INITIALS')}
                placeholder={translate('REGISTER_INSTITUTION_COMPANY_INITIALS')}
                errors={errors}
                control={control}
                name="initials"
              ></BaseTextField>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <BaseTextField
                disabled={true}
                required={true}
                label={translate('REGISTER_INSTITUTION_COMPANY_CODE')}
                errors={errors}
                control={control}
                name="cnpj"
                mask={cnpjMask}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <BaseTextField
                disabled={false}
                required={true}
                label={translate('REGISTER_INSTITUTION_COMPANY_PHONE_NUMBER')}
                errors={errors}
                control={control}
                name="phoneNumber"
                mask={phoneMask}
              />
            </Grid>
          </Grid>
        </div>

        <Address
          formMethods={formMethods}
          disabled={!hasTotalRole}
          required={true}
        />
        <ResponsibleData
          documents={responsibleDocuments}
          onChangeResponsible={onChangeResponsible}
          onRemoveFile={onRemoveFile}
          onSelectFiles={onFileChange}
          user={responsible}
          userList={activeRepresentantsList}
          formMethods={formMethods}
        />

        <div className={classes.defaultContainer}>
          <Grid container spacing={0} alignItems="flex-start">
            <Grid item xs={12} md={12}>
              <h3 className={classes.h3}>
                {translate('REGISTER_INSTITUTION_MANAGE_REPRESENTATIVES')}
              </h3>
            </Grid>
            <Grid item xs={12} md={10}>
              <BaseTextField
                disabled={!hasTotalRole}
                label=""
                placeholder={translate('REGISTER_INSTITUTION_ADD_EMAIL')}
                errors={errors}
                control={control}
                name="email_invite"
                style={{
                  maxHeight: '40px',
                  minHeight: '40px',
                  marginRight: '10px'
                }}
              ></BaseTextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                // disabled={!hasTotalRole}
                id="INVITE_TO_INSTITUTION"
                fullWidth
                className={classes.baseButton}
                disabled={!mainFormDirtyFields?.email_invite}
                onClick={checkEmailAlreadyInvited}
                variant="contained"
                style={{
                  marginTop: errors['email_invite'] ? '1px' : 0,
                  maxHeight: '40px',
                  minHeight: '40px'
                }}
              >
                {translate('REGISTER_INSTITUTION_INVITE')}
              </Button>
            </Grid>
          </Grid>
        </div>

        <Grid container>
          {formNeedsSaving && (
            <Grid item xs={12}>
              <WarningMessage>{translate('FORM_NEEDS_SAVING')}</WarningMessage>
            </Grid>
          )}

          <Grid item xs={12} style={{ paddingBottom: '1rem' }}>
            <TableContainer component={Paper}>
              <Table aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="left">
                      {translate('REGISTER_INSTITUTION_REPRESENTATIVES_EMAIL')}
                    </TableCell>
                    <TableCell align="left">
                      {translate('REGISTER_INSTITUTION_REPRESENTATIVES_NAME')}
                    </TableCell>
                    <TableCell align="left">
                      {translate(
                        'REGISTER_INSTITUTION_REPRESENTATIVES_PERMISSION'
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {translate('REGISTER_INSTITUTION_REPRESENTATIVES_STATUS')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((row, index) => (
                    <TableRow
                      id={`REPRESENTATIVE_${textToConstant(row.email)}`}
                      key={index}
                      className={clsx({
                        [classes.disableRow]: !canEditRow(row)
                      })}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell padding={'default'} align="left">
                        {row.situation != 'REJECTED' && (
                          <Tooltip
                            title={translate(
                              `COMMONS:SITUATION.${row.situation}`
                            )}
                          >
                            <div
                              className={
                                classes['statusCircle' + row.situation]
                              }
                            ></div>
                          </Tooltip>
                        )}
                        <WrapContent>
                          <WrapEmail alt={row.email} title={row.email}>
                            {row.email}
                          </WrapEmail>
                          {row.userInactive ? (
                            <TableChip
                              size="small"
                              label={translate(
                                'REGISTER_INSTITUTION_REPRESENTATIVES_INACTIVE'
                              )}
                            />
                          ) : null}
                        </WrapContent>
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="center" width="160">
                        <div
                          style={{
                            paddingRight: '15px'
                          }}
                        >
                          <Select
                            name="permission"
                            disableUnderline={true}
                            disabled={!canEditRow(row)}
                            fullWidth
                            native
                            value={row.permission}
                            onChange={event => handlePermission(event, index)}
                          >
                            {[
                              'ROLE_INSTITUICAO_TOTAL',
                              'ROLE_INSTITUICAO_PARCIAL',
                              'ROLE_INSTITUICAO_LIMITADA'
                            ].map(item => (
                              <option key={item} value={item}>
                                {translate(`USER:ROLE_SHORT.${item}`)}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </TableCell>
                      <TableCell width="170">
                        <Grid container display="flex">
                          <div
                            style={{
                              width: 'calc(100% - 47px)',
                              marginTop: '2px',
                              paddingRight: '3px'
                            }}
                          >
                            <Select
                              name="situation"
                              disableUnderline={true}
                              fullWidth
                              disabled={!canEditRow(row)}
                              native
                              value={row.deleted ? 'DELETED' : row.situation}
                              onChange={event => handleSituation(event, index)}
                            >
                              <option value="ACTIVE">
                                {translate('LABORATORY:SITUATION.ACTIVE')}
                              </option>
                              <option value="INACTIVE">
                                {translate('LABORATORY:SITUATION.INACTIVE')}
                              </option>
                              {row.deleted || row.situation === 'DELETED' ? (
                                <option value="DELETED">
                                  {translate('LABORATORY:SITUATION.DELETED')}
                                </option>
                              ) : null}
                              {row.situation === 'PENDING' ? (
                                <option value="PENDING">
                                  {translate('LABORATORY:SITUATION.PENDING')}
                                </option>
                              ) : null}
                              {row.situation === 'REJECTED' ? (
                                <option value="REJECTED">
                                  {translate('LABORATORY:SITUATION.REJECTED')}
                                </option>
                              ) : null}
                            </Select>
                          </div>
                          <div style={{ width: '44px' }}>
                            {!row.deleted ? (
                              <IconButton
                                disabled={!hasTotalRole || row.responsible}
                                onClick={() => checkRemoveInvite(row)}
                                aria-label="delete"
                                name="DELETE"
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => undoRemoveInvite(row)}
                                aria-label="undo"
                                title="Desfazer exclusão"
                                name="UNDO"
                              >
                                <UndoIcon className="fas fa-undo"></UndoIcon>
                              </IconButton>
                            )}
                          </div>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <div className={classes.defaultContainer}>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            style={{ paddingTop: '4rem' }}
          >
            <Grid item xs={12} md={2}>
              <Button
                className={classes.baseButtonGrey}
                type={'button'}
                variant="contained"
                fullWidth
                id="INSTITUTION_CANCEL"
                onClick={() => setOpenConfirmCancelDialog(true)}
              >
                {translate('REGISTER_INSTITUTION_CANCEL')}
              </Button>
            </Grid>
            <DialogConfirm
              dialogText="REGISTER_INSTITUTION_CONFIRM_DIALOG"
              open={openConfirmCancelDialog}
              onConfirm={handleInstitutionCancel}
              onCancel={() => setOpenConfirmCancelDialog(false)}
            />
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                className={classes.baseButton}
                disabled={!hasTotalRole}
                id="INSTITUTION_SAVE"
              >
                {translate('REGISTER_INSTITUTION_SAVE')}
              </Button>
            </Grid>
            <DialogConfirm
              dialogText="REGISTER_INSTITUTION_ALERT_EMAIL_INVITE_DIALOG"
              open={!isEmailInviteEmpty}
              hideConfirmButton={true}
              labelCancelButton="Continuar"
              onCancel={() => setIsEmailInviteEmpty(true)}
            />
            <DialogConfirm
              dialogText="REGISTER_INSTITUTION_USER_ALREADY_INVITED_OTHER_INSTITUTION"
              open={emailAlreadyInInstitution}
              onCancel={() => setEmailAlreadyInInstitution(false)}
              onConfirm={() => {
                setEmailAlreadyInInstitution(false)
                handleOpenInvite()
              }}
            />
            <DialogConfirm
              dialogText="REGISTER_INSTITUTION_REMOVE_SELF"
              open={Boolean(confirmRemoveSelf)}
              onCancel={() => setConfirmRemoveSelf(null)}
              onConfirm={() => {
                removeInvite({ ...confirmRemoveSelf })
                setConfirmRemoveSelf(null)
              }}
            />
          </Grid>
        </div>
      </form>
    </React.Fragment>
  )
}
export default Institution
