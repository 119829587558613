import styled from 'styled-components'

import ListItem from '@material-ui/core/ListItem'

import { primaryColor } from 'helpers/constants'

export const Icon = styled.i`
  color: ${primaryColor};
  font-size: ${props => props.theme.measures.sideNav.listItem.icon.fontSize};
  width: ${props => props.theme.measures.sideNav.listItem.icon.width};
  text-align: center;
  display: inline-block;
  margin-right: ${props =>
    props.theme.measures.sideNav.listItem.icon.marginRight};
  transition: all 0.4s ease-in-out;
`
export const ListItemStyled = styled(ListItem).attrs({ component: 'li' })`
  font-size: ${({ open }) => (open ? '14px' : '0px')};
  font-weight: 600;
  padding: ${props => props.theme.measures.sideNav.listItem.padding};
  background-color: #fafafa;
  border: 1px solid #f2f2f2;
  transition: all 0.4s ease-in-out;
  z-index: 1;

  &:hover,
  &:focus,
  &:active {
    border-color: ${primaryColor};
    background-color: ${primaryColor};
    color: #fafafa;

    i {
      color: #fafafa;
    }
  }
`
