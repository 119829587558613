import styled from 'styled-components'

import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { sm, primaryColor } from 'helpers/constants'

import CustomBaseTextareaField from 'ui/atoms/BaseTextareaField'

export const ButtonAddEquipment = styled(Button)`
  color: #ffffff;
  float: right;
  height: 60px;
  font-size: 14px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  font-weight: 600;
  border-radius: 2px;
  text-transform: none;
  background-color: #009b7f;
`

export const WrapInput = styled.div`
  & {
    display: flex;
    margin-bottom: 4px;
    label {
      margin: 9px 4px 9px 28px;
      width: 89px;
      min-width: 89px;
    }

    .MuiFormControl-root,
    div[class*='MuiFormControl-root-'] {
      max-width: 395px;
      border-radius: 2px;

      ${props => props.theme.breakpoints.down('sm')} {
        max-width: 100%;
      }
    }

    .email-error-container {
      display: block;
      p {
        margin-left: 123px;
      }
    }
  }
`

export const Text = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  display: inline-block;
  border: 0;
  outline: none;
  padding: 10px 28px;
  border: 1px solid transparent;
  &.openEdit {
    background: #f2f2f2;
    border-radius: 3px;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.3);
  }
`
export const Title = styled.div`
  color: #999999;
  font-size: 32px;
  font-weight: 600;
  display: flex;
  border-bottom: 1px solid ${primaryColor};
  padding: 10px;
  color: #333333;
  h3 {
    display: inline-block;
    margin: 0;
    padding: 0 15px;
    font-weight: 600;
    font-size: 32px;
    color: #999999;
    outline: none;
  }
`
export const SubTitle = styled.h4`
  color: #333333;
  font-size: 18px;
  margin: 15px 0;
`

export const PCustom = styled.p`
  display: inline-block;
  margin-left: 27px;
  font-weight: 600;
  margin-right: 5px;
`
export const OpenMaps = styled.button`
  border: 0;
  color: ${primaryColor};
  background: none;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  i {
    font-size: 18px;
    margin: 3px 0 0 5px;
  }
`
export const BaseTextareaField = styled(CustomBaseTextareaField)`
  color: #bdbfc1;
  font-weight: 600;

  & .MuiFilledInput-root {
    border-radius: 2px;

    &:not(.Mui-error) {
      border: 1px solid #cccccc;
    }
  }

  input {
    font-weight: 600;
    font-size: 14px;
  }
`
