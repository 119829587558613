import { Scrollbars } from 'react-custom-scrollbars'
import styled from 'styled-components'

export const BorderContainer = styled.div`
  /* margin: 16px 0; */
  height: calc(100% - 32px);
  border: 1px solid ${props => props.theme.palette.grey[300]};
  overflow: hidden;
`

export const MessageContainer = styled(Scrollbars)``

export const FormContainer = styled.div`
  flex: 0;
  padding: 24px;
`

export const LoaderContainer = styled.div`
  padding: 16px 0;
`
