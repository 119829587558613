export const COVID_OTHER_DENOMINATIONS = ['#vacinacovid19']

export const COVID_PARAMETER_LIST = [
  {
    name: '#ultracongelador-70covid19',
    value: '',
    placeholderValue: '-70',
    unit: 'ºC',
    isCampaignParam: true
  },
  {
    name: '#ultracongeladordisponivel',
    value: '',
    placeholderValue: '30',
    unit: 'Litros',
    isCampaignParam: true
  }
]
