import { Auth } from 'aws-amplify'
import axios from 'axios'
import cacheWrapper from 'axios-cache-plugin'

import { signOut } from 'context/AuthContext'
import store from 'framework/store'
import {
  decrementRequest,
  incrementRequest
} from 'framework/store/slices/request.slice'
import { API_URL } from 'helpers/variables'

import cachedUrls from './cachedUrls'

const http = axios.create({
  baseURL: API_URL + '/',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
})

const { dispatch } = store

http.interceptors.request.use(
  async function (config) {
    if (!config.skipLoader) dispatch(incrementRequest(config))
    try {
      const currentSession = await Auth.currentSession()
      if (currentSession.isValid()) {
        config.headers.Authorization = `Bearer ${currentSession
          .getIdToken()
          .getJwtToken()}`
      }
    } catch {}

    return config
  },
  function (error) {
    console.error('error', error)
    // Do something with request error
    dispatch(() => decrementRequest(error))
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  async response => {
    if (!response.config?.skipLoader) {
      dispatch(decrementRequest(response))
    }
    return response
  },
  function (error) {
    if (!error.config?.skipLoader) {
      dispatch(decrementRequest(error))
    }

    if (error.response?.status === 401) {
      signOut()
    }

    return Promise.reject(error)
  }
)

const cachedHttp = cacheWrapper(http, {
  maxCacheSize: false
})

cachedUrls.forEach(url => cachedHttp.__addFilter(url))

export default cachedHttp
