import React, { useContext, useState } from 'react'

import Typography from '@material-ui/core/Typography'

import AddedDocuments, { previewFile } from 'ui/atoms/AddedDocuments'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import InstitutionData from 'ui/atoms/InstitutionData'
import { Tab, Tabs } from 'ui/atoms/Tabs'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'

import { ListContainer, Marker, ResponsibleText, Text } from '../../styles'

const RefusedSolicitation = ({
  analysis,
  institutionData,
  represantativeList,
  responsible
}) => {
  const { translate } = useContext(GlobalizationContext)
  const [currTab, setCurrTab] = useState('ANALYZE')

  const renderAnalysis = () => {
    return (
      <>
        <Typography variant="subtitle1" style={{ marginBottom: '32px' }}>
          {translate('ANALYSIS.SITUATION')}
        </Typography>
        <ListContainer>
          <Marker
            // @ts-ignore
            type={institutionData?.situation}
          />
          <span>{`${institutionData?.initials} - ${institutionData?.social_reason}`}</span>
          <span>{institutionData?.cnpj}</span>
          <span>{`${represantativeList?.length}/${represantativeList?.length}`}</span>

          <span>{translate('ANALYSIS.REFUSED')}</span>
        </ListContainer>
        <Typography variant="subtitle1" style={{ marginBottom: '32px' }}>
          {translate('ANALYSIS.RESULT')}
        </Typography>
        <Text>{translate('ANALYSIS.RESULT_DESCRIPTION')}</Text>
        <Typography variant="body2" style={{ marginBottom: '32px' }}>
          {analysis?.refusalDetail}
        </Typography>

        {analysis?.documentList.length !== 0 && (
          <>
            <Text>{translate('ANALYSIS.FILE')}</Text>
            <AddedDocuments
              docs={analysis?.documentList}
              viewFile={previewFile}
            />
          </>
        )}
        <ResponsibleText>
          {translate('ANALYSIS.ANALYSIS_RESPONSIBLE')}
        </ResponsibleText>
        <span>{analysis?.responsibleAnalysis}</span>
      </>
    )
  }

  const renderSolicitation = () => {
    return (
      <div style={{ marginBottom: '32px' }}>
        <InstitutionData
          data={institutionData}
          representantsList={represantativeList}
          responsible={responsible}
        />
      </div>
    )
  }

  return (
    <>
      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />
      <Tabs
        onChange={(
          // @ts-ignore
          event,
          newTab
        ) => setCurrTab(newTab)}
        value={currTab}
        style={{ marginBottom: '32px' }}
      >
        <Tab label={translate('ANALYSIS.ANALYZE')} value={'ANALYZE'} />
        <Tab
          label={translate('ANALYSIS.SOLICITATION')}
          value={'SOLICITATION'}
        />
      </Tabs>
      {{
        ANALYZE: renderAnalysis(),
        SOLICITATION: renderSolicitation()
      }[currTab] || null}
    </>
  )
}

export default RefusedSolicitation
