import React from 'react'
import styled from 'styled-components'

import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

const CloseIcon = styled(Icon).attrs({
  className: 'fas fa-times-circle'
})`
  font-size: 13px;

  &:hover {
    cursor: pointer;
  }
`

const OptionContainer = styled.div`
  display: flex;
  width: 100%;
`
const OptionText = styled.div`
  flex: 1 1 100%;
  min-width: 0;
`
const OptionAction = styled.div`
  flex: 1 1 auto;
`

const Option = ({
  multiple,
  allowClear,
  option,
  state,
  optionRenderer,
  getOptionLabel
}) => {
  return (
    <OptionContainer>
      <OptionText className="optionContent">
        {optionRenderer ? (
          optionRenderer({ option, state })
        ) : (
          <Typography style={{ fontSize: '14px' }}>
            {getOptionLabel(option)}
          </Typography>
        )}
      </OptionText>
      {(multiple || allowClear) && state.selected ? (
        <OptionAction>
          <IconButton className="button-remove" style={{ padding: '5px' }}>
            <CloseIcon className="button-remove" />
          </IconButton>
        </OptionAction>
      ) : null}
    </OptionContainer>
  )
}

export default Option
