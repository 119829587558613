import * as Yup from 'yup'

export default Yup.object().shape({
  first_name: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  last_name: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  email: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .email('VALIDATION:EMAIL_ERROR')
    .trim(),
  cpf: Yup.string()
    .cpf('VALIDATION:CPF_ERROR')
    .required('VALIDATION:REQUIRED_ERROR')
    .digitsOnly(),
  hasRoleAdmin: Yup.boolean(),
  hasRoleGestor: Yup.boolean()
})
