import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

const ActionsButton = ({ handleClose }) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <Grid
        container
        justify="flex-end"
        spacing={2}
        style={{ marginTop: '32px' }}
      >
        <Grid item xs={12} md={2}>
          <Button
            className={classes.baseButtonGrey}
            type={'button'}
            variant="contained"
            fullWidth
            onClick={handleClose}
          >
            {translate('COMMONS:CANCEL')}
          </Button>
        </Grid>

        <Grid item xs={12} md={2}>
          <Button
            fullWidth
            type={'submit'}
            variant="contained"
            className={classes.baseButton}
          >
            {translate('COMMONS:SAVE')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ActionsButton
