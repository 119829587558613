// @ts-nocheck
import styled from 'styled-components'

import { fontSize } from 'helpers/constants'

import { MarkerOrange, MarkerRed } from 'ui/atoms/Marker'

const getMarkerColor = situation => {
  switch (situation) {
    case 'PENDING':
    case 'WAITING_APPROVAL':
      return MarkerOrange
    case 'REJECTED':
    case 'DELETED':
      return MarkerRed
    default:
  }
}

export const ListContainer = styled.div`
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  color: #333;
  display: grid;
  font-size: 14px;
  grid-template-columns: 10% 30% 30% 20% 10%;
  padding: 32px 24px;
  margin-bottom: 24px;

  & button:hover {
    background-color: rgba(0, 0, 0, 0);
  }
`

export const Marker = styled.span`
  border-radius: 50%;
  display: block;
  width: 16px;
  height: 16px;

  ${props => getMarkerColor(props.type)}
`

export const Text = styled.p`
  font-weight: 600;
  font-size: ${fontSize}
  line-height: 16px;
`
export const ResponsibleText = styled.span`
  font-weight: 600;
  font-size: ${fontSize}
  line-height: 16px;
`
