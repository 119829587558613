import React, { useState, useCallback } from 'react'
import { Controller } from 'react-hook-form'

import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'

import { primaryColor } from 'helpers/constants'

import BaseTextField from 'ui/atoms/BaseTextField'
import DialogSliderMaxSize from 'ui/molecules/commons/DialogSliderMaxSize'

const PrettoSlider = withStyles({
  root: {
    color: primaryColor,
    height: 8,
    padding: '20px 0 13px 0'
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    border: '3px solid currentColor',
    marginTop: -5,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-110% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider)

const DefaultSlider = ({
  value,
  onChange,
  onBlur,
  name,
  otherSliders,
  limitExceededMessage,
  formMethods,
  inputRef
}) => {
  const [adjustSlidePosition, setAdjustSlidePosition] = useState({})
  const [dialogSliderLimit, setDialogSliderLimit] = useState(false)

  const handleChange = useCallback(
    newValue => {
      if (otherSliders) {
        const totalSum = [newValue]
          .concat(otherSliders.map(field => formMethods.getValues(field)))
          .reduce((acc, curr) => acc + Number(curr || 0), 0)

        if (totalSum > 100) {
          setDialogSliderLimit(true)
          onChange(100 - totalSum + Number(newValue || 0))
          return
        }
      }

      onChange(newValue)
    },
    [formMethods, onChange, otherSliders]
  )

  const handleSliderChange = useCallback(
    (event, newValue) => {
      handleChange(newValue)
      setAdjustSlidePosition(newValue > 0 ? { marginLeft: '-10px' } : {})
    },
    [handleChange]
  )

  const handleInputChange = useCallback(
    event => {
      const newValue =
        event.target.value === '' ? 0 : Number(event.target.value)

      if (formMethods.getValues(name) !== newValue) {
        handleChange(newValue)
      }
    },
    [formMethods, handleChange, name]
  )

  const handleBlur = useCallback(() => {
    const currValue = formMethods.getValues(name)
    if (currValue < 0) {
      handleChange(0)
      onBlur(0)
    } else if (currValue > 100) {
      handleChange(100)
      onBlur(100)
    }
  }, [formMethods, handleChange, name, onBlur])

  return (
    <>
      <DialogSliderMaxSize
        dialogText={limitExceededMessage}
        open={dialogSliderLimit}
        onConfirm={() => setDialogSliderLimit(false)}
      />

      <Grid item xs={12} md={8}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ margin: '0 4px' }}
        >
          <Grid item xs={10} md={8} style={adjustSlidePosition}>
            <PrettoSlider
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              value={value}
            />
          </Grid>
          <Grid item xs={2} md={1} style={{ minWidth: '100px' }}>
            <BaseTextField
              type={'number'}
              value={value}
              handleChange={handleInputChange}
              handleBlur={handleBlur}
              inputRef={inputRef}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              inputProps={{
                step: 1,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const ControlledSlider = ({ formMethods, name, ...rest }) => {
  return (
    <Controller
      defaultValue={0}
      control={formMethods.control}
      name={name}
      render={({ ref, ...props }) => (
        <DefaultSlider
          formMethods={formMethods}
          inputRef={ref}
          {...rest}
          {...props}
        />
      )}
    />
  )
}

export default ControlledSlider
