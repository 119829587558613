import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'digitsOnly', function (
  message = 'Must cotain only digits'
) {
  return this.transform(val =>
    val != null ? val.replace(/\D/gm, '') : val
  ).test({
    message,
    name: 'onlyDigits',
    test: value => value == null || !value.match(/\D/gm)?.length
  })
})
