import { AdminService } from 'api'

import { notificationUseCases } from 'views/Notification/providers'

import EditUserUseCases from './editUser.useCases'

export const editUserUseCases = new EditUserUseCases({
  AdminService,
  notificationUseCases
})
