import styled from 'styled-components'

import MaterialContainer from '@material-ui/core/Container'

export default styled(MaterialContainer)`
  @media (min-width: 1150px) {
    &.MuiContainer-maxWidthLg {
      max-width: 1150px;
      padding: 0 !important;
      margin: 0 auto;
      width: 95%;
    }
  }
`
