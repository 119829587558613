import React, { useEffect, useState } from 'react'

import Container from 'ui/atoms/Container'

import Copyright from './Fragments/Copyright'
import {
  Wrapper,
  TopContainer,
  BottomContainer,
  LogoContainer,
  Logo
  // LinksContainer,
  // ALink
} from './style'

function getWindowDimensions() {
  const { innerWidth: width } = window
  return {
    width
  }
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

const Footer = () => {
  const { width } = useWindowDimensions()
  return (
    <Wrapper>
      <TopContainer>
        <Container maxWidth="lg">
          {/* <LinksContainer>
            <ALink target="_blank" href="https://twitter.com/mctic" className="fab fa-twitter"></ALink>
            <ALink target="_blank" href="https://www.linkedin.com/company/mctic/" className="fab fa-linkedin-in"></ALink>
            <ALink target="_blank" href="https://www.instagram.com/mctic/" className="fab fa-instagram"></ALink>
            <ALink target="_blank" href="https://www.youtube.com/mctic" className="fab fa-youtube"></ALink>
            <ALink target="_blank" href="https://www.facebook.com/mctic" className="fab fa-facebook-f"></ALink>
          </LinksContainer> */}
          <LogoContainer>
            <Logo
              // @ts-ignore
              imageSize={width}
            />
          </LogoContainer>
        </Container>
      </TopContainer>
      <BottomContainer>
        <Container maxWidth="lg">
          <Copyright />
        </Container>
      </BottomContainer>
    </Wrapper>
  )
}
export default Footer
