import styled from 'styled-components'

import { secundaryColor } from 'helpers/constants'

// import footerLogoImg from 'assets/images/footerLogoImg.png'

import footerMd from 'assets/images/footer-md.png'
import footerSm from 'assets/images/footer-sm.png'

const getImage = size => {
  return size >= 640 ? footerMd : footerSm
}
export const Logo = styled.img.attrs(props => ({
  src: props.imageSize && getImage(props.imageSize)
}))`
  width: 100%;
  @media (max-width: 640px) {
    margin: 0 auto;
    width: 50%;
  }
`

export const LogoContainer = styled.div`
  flex: 0 1 auto;
  display: flex;
  align-items: center;
`

export const Link = styled.i`
  width: 34px;
  height: 34px;
  font-size: 22px;
  border-radius: 17px;
  background: #009b7f;
  color: ${secundaryColor};
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:first-child) {
    margin-left: 20px;
  }
`

export const ALink = styled.a`
  width: 34px;
  height: 34px;
  font-size: 22px;
  border-radius: 17px;
  text-decoration: none;
  background: #009b7f;
  color: ${secundaryColor};
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:first-child) {
    margin-left: 20px;
  }
`

export const LinksContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`

export const TopContainer = styled.div`
  // background: ${secundaryColor};
  border-top: 1px solid #ccc;
  // height: 95px;
  display: block;
  // align-items: center;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`
export const BottomContainer = styled.div`
  background: #03b699;
  padding: 0;
`

export const Wrapper = styled.div``
