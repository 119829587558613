import React, { useCallback } from 'react'
import { useParams } from 'react-router'

import { EquipmentService } from 'api'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'

import Form from './Fragments/Form'
import View from './Fragments/View'

function RegisterEquipment({ covidFreezer }) {
  // const isCovidFreezer = Boolean(covidFreezer)
  const { id: equipmentId } = useParams()

  const equipmentPromiseFn = useCallback(async () => {
    if (!equipmentId) return null

    const { data } = await EquipmentService.getById(equipmentId)
    return data
  }, [equipmentId])

  return (
    <AsyncLoad promiseFn={equipmentPromiseFn}>
      {equipment =>
        !equipmentId || equipment.isEditor ? (
          <Form covidFreezer={covidFreezer} equipment={equipment} />
        ) : (
          <View equipment={equipment} />
        )
      }
    </AsyncLoad>
  )
}

export default RegisterEquipment
