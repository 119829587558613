import React, { useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Grid, Typography } from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import { REQUEST_SHARING_ROUTES } from '../../constants/routes'
import RequestSharingForm from '../../fragments/RequestForm'
import { requestSharingUseCases, FileUploadError } from '../../providers'
import LastPendingCompletionAnalysis from './fragment/LastPendingCompletionAnalysis'

const EditRequestSharing = () => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const { id } = useParams()

  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false)

  const [initialDataPromise] = useState(async () => {
    return await requestSharingUseCases.getRequestData(id)
  })

  const [paramsPromise] = useState(async () => {
    return await requestSharingUseCases.getParams()
  })

  const allPromisses = useCallback(async () => {
    const initialData = await initialDataPromise
    const params = await paramsPromise
    return { initialData, params }
  }, [initialDataPromise, paramsPromise])

  const onSubmit = async ({
    formData,
    complementaryFiles,
    deletedComplementaryFiles,
    materialDataSafetyFiles,
    deletedMaterialDataSafetyFiles
  }) => {
    const requestData = {
      ...formData,
      institution: formData.institution.social_reason,
      laboratory: formData.laboratory.id,
      securityHazardList: (formData.securityHazardList || []).map(item => ({
        id: item
      })),
      specificEquipment: (formData.specificEquipment || []).map(
        item => item.id
      ),
      contactEmail: formData.requesterEmail
    }

    try {
      await requestSharingUseCases.updateRequest({
        id,
        requestData,
        additionalInfoDocs: complementaryFiles,
        deletedAdditionalInfoDocs: deletedComplementaryFiles,
        mdsDocs: materialDataSafetyFiles,
        deletedMdsDocs: deletedMaterialDataSafetyFiles
      })

      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
      history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
    } catch (error) {
      console.log(error)

      if (error instanceof FileUploadError) {
        notificationUseCases.newError(
          translate('REGISTER.ERRORS.FILE_UPLOAD_ERROR')
        )
        history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
      } else {
        notificationUseCases.newError(error)
      }
    }
  }

  const handleConfirmCancel = () => {
    history.push(generatePrivatePath(REQUEST_SHARING_ROUTES.LIST))
  }

  return (
    <>
      <Loader />
      <DialogConfirm
        dialogText="EDIT.CONFIRM_CANCEL"
        open={confirmCancelOpen}
        onConfirm={handleConfirmCancel}
        onCancel={() => setConfirmCancelOpen(false)}
      />

      <div className={classes.defaultContainer}>
        <div style={{ marginLeft: '15px' }}>
          <AsyncLoad promiseFn={allPromisses}>
            {({ params, initialData }) =>
              initialData && (
                <>
                  <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                    <Title item xs={12} $paddingTop>
                      {translate(`EDIT.TITLE.${initialData.situation}`)}
                    </Title>
                  </Grid>

                  <LastPendingCompletionAnalysis initialData={initialData} />

                  <RequestSharingForm
                    initialData={initialData}
                    params={params}
                    onSubmit={onSubmit}
                    onCancel={() => setConfirmCancelOpen(true)}
                  />
                </>
              )
            }
          </AsyncLoad>
        </div>
      </div>
    </>
  )
}

export default wrapper(EditRequestSharing, { namespace: 'REQUEST_SHARING' })
