import React, { useContext, useState } from 'react'
import { Trans } from 'react-i18next'

import { Button, Grid, Typography } from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle } from 'ui/atoms/Title'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'

import EditAnalisys from '../EditAnalisys'
import UsageData from '../UsageData'
import UsageForm from './Fragments/UsageForm'

const DeclareUsage = ({ sharingRequest, currentLaboratory }) => {
  const { translate } = useContext(GlobalizationContext)
  const [isEditing, setIsEditing] = useState(false)

  return (
    <>
      <Grid container spacing={2}>
        <Subtitle item xs={12}>
          {translate('DETAILS.DECLARE_USAGE.TITLE')}
        </Subtitle>
        <Grid item xs={12}>
          <Typography variant="body2">
            <Trans
              t={translate}
              i18nKey={'DETAILS.DECLARE_USAGE.DESCRIPTION'}
            />
          </Typography>
        </Grid>

        <Subtitle
          item
          xs={12}
          $paddingTop
          $noBorder={false}
          action={
            !sharingRequest.isRequester && !isEditing ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsEditing(true)}
              >
                {translate('COMMONS:EDIT')}
              </Button>
            ) : null
          }
        >
          {translate('DETAILS.DECLARE_USAGE.SHARING_DATA')}
        </Subtitle>

        <Grid item xs={12}>
          {isEditing ? (
            <EditAnalisys
              sharingRequest={sharingRequest}
              currentLaboratory={currentLaboratory}
              onCancel={() => setIsEditing(false)}
            />
          ) : (
            <>
              <UsageData sharingRequest={sharingRequest} />
              {sharingRequest.isRequester ? (
                <UsageForm
                  onCancel={() => {
                    history.push(
                      generatePrivatePath(REQUEST_SHARING_ROUTES.LIST)
                    )
                  }}
                  sharingRequest={sharingRequest}
                  style={{ marginTop: '16px' }}
                />
              ) : null}
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default DeclareUsage
