import React, { useCallback, useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import wrapper from 'ui/atoms/Wrapper'

import BodyFilter from './Fragments/BodyFilter'
import List from './Fragments/List'
import { institutionListUseCase as useCase } from './providers'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

const rowsPerPageList = [10, 20, 50, 100]

function InstitutionList() {
  const [list, setList] = useState([])

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [count, setCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  const debounceTimeOut = useRef(null)
  const [filterValue, setFilterValue] = useState('')
  const [filterSituation, setFilterSituation] = useState('')
  const [emptyList, setEmptyList] = useState(null)
  const defaultDebounce = 500

  const classes = useStyles()

  const getList = useCallback(() => {
    if (debounceTimeOut.current) clearTimeout(debounceTimeOut.current)
    const newDebounceTimeOut = setTimeout(async function () {
      const filter = {
        ...(filterSituation ? { situation: filterSituation } : {}),
        ...(filterValue ? { search: filterValue } : {})
      }

      try {
        const params = { pageSize, pageNumber, ...filter }
        const { content, ...pagination } = await useCase.getAll(params)

        setList(content)
        setCount(pagination.totalElements)
        if (pagination.empty) {
          setEmptyList(true)
        } else {
          setEmptyList(false)
        }
      } catch (error) {
        console.log(error)
      }
    }, defaultDebounce)
    debounceTimeOut.current = newDebounceTimeOut
  }, [filterSituation, filterValue, pageNumber, pageSize])

  const handleInputChange = event => {
    setPageNumber(0)
    setFilterValue(event.target.value)
  }

  const handleSelectChange = event => {
    setPageNumber(0)
    setFilterSituation(event.target.value)
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  useEffect(() => {
    getList()
  }, [getList])

  return (
    <>
      <BodyFilter
        onInputChange={handleInputChange}
        onSelectChange={handleSelectChange}
      />

      <List
        classes={classes}
        rowsPerPageOptions={rowsPerPageList}
        count={count}
        rowsPerPage={pageSize}
        page={pageNumber}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        list={list}
        emptyList={emptyList}
        updateList={getList}
      />
    </>
  )
}

export default wrapper(InstitutionList, {
  mapState: null,
  mapDispatch: null,
  namespace: 'INSTITUTION_LIST'
})
