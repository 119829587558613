import clsx from 'clsx'
import React, { useCallback, useContext, useState } from 'react'

import { Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { InstitutionRegister } from 'api'
import { AuthContext } from 'context/AuthContext'
import { textToConstant } from 'helpers/textToConstant'

import { TableChip } from 'ui/atoms/Chip'
import { useGlobalizationContext } from 'ui/atoms/Globalization'
import TableActions from 'ui/atoms/TableActions'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import { WrapEmail, WrapContent, UndoIcon } from '../../styles'

const RepresentativeList = ({
  institutionCnpj,
  formMethods,
  userList,
  setUserList,
  setFormNeedsSaving
}) => {
  const classes = useStyles()
  const { translate } = useGlobalizationContext()
  const { userData } = useContext(AuthContext)
  const [confirmRemoveSelf, setConfirmRemoveSelf] = useState(null)

  const { clearErrors } = formMethods

  const handlePermission = (event, index) => {
    const newValue = event.target.value
    setUserList(oldUserList => {
      setFormNeedsSaving(true)
      const rowCopy = { ...oldUserList[index], permission: newValue }
      const listCopy = [...oldUserList]
      listCopy[index] = rowCopy
      return listCopy
    })
  }

  const handleSituation = useCallback(
    (event, index) => {
      const newValue = event.target.value
      setUserList(oldUserList => {
        setFormNeedsSaving(true)
        const rowCopy = { ...oldUserList[index], situation: newValue }
        const listCopy = [...oldUserList]
        listCopy[index] = rowCopy
        return listCopy
      })
    },
    [setFormNeedsSaving, setUserList]
  )

  const removeInvite = useCallback(
    item => {
      clearErrors('email_invite')
      if (item.id) {
        setUserList(oldList => {
          const newList = [...oldList]
          const itemIndex = newList.findIndex(obj => obj.id === item.id)
          newList[itemIndex] = {
            ...newList[itemIndex],
            deleted: true
          }
          return newList
        })
      } else {
        setUserList(oldList => oldList.filter(obj => obj.email !== item.email))
      }
      setFormNeedsSaving(true)
    },
    [clearErrors, setFormNeedsSaving, setUserList]
  )

  const checkRemoveInvite = useCallback(
    item => {
      if (userData.email !== item.email) {
        removeInvite(item)
      } else {
        setConfirmRemoveSelf(item)
      }
    },
    [removeInvite, userData]
  )

  const undoRemoveInvite = useCallback(
    item => {
      setUserList(oldList => {
        const newList = [...oldList]
        const itemIndex = newList.findIndex(obj => obj.email === item.email)
        delete newList[itemIndex].deleted
        return newList
      })
    },
    [setUserList]
  )

  const canEditRow = row => {
    return (
      !row.responsible &&
      !row.userInactive &&
      !row.deleted &&
      ['PENDING', 'REJECTED', 'DELETED'].indexOf(row.situation) === -1
    )
  }

  const handeResendInvite = async row => {
    try {
      await InstitutionRegister.resendInvite(institutionCnpj, row.email)
      notificationUseCases.newSuccess('DEFAULT_SUCCESS')
    } catch (error) {
      notificationUseCases.newError(error)
    }
  }

  const actionMap = {
    DELETE: checkRemoveInvite,
    RESEND_INVITE: handeResendInvite
  }

  const actions = row => {
    const actions = { ...actionMap }
    if (row.situation === 'ACTIVE' || row.situation === 'REJECTED') {
      delete actions.RESEND_INVITE
    }
    return actions
  }

  const rowActionList = row =>
    Object.keys(actions(row)).map(action => ({
      code: action,
      name: `REPRESENTATIVE_LIST.ACTIONS.${action}`
    }))

  const handleRowAction = (code, row) => {
    actionMap[code](row)
  }

  return (
    <>
      <DialogConfirm
        dialogText="MESSAGES.REMOVE_SELF"
        open={Boolean(confirmRemoveSelf)}
        onCancel={() => setConfirmRemoveSelf(null)}
        onConfirm={() => {
          removeInvite({ ...confirmRemoveSelf })
          setConfirmRemoveSelf(null)
        }}
      />

      <Grid container>
        <Grid item xs={12} style={{ paddingBottom: '1rem' }}>
          <TableContainer component={Paper}>
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="left">
                    {translate('REPRESENTATIVE_LIST.EMAIL')}
                  </TableCell>
                  <TableCell align="left">
                    {translate('REPRESENTATIVE_LIST.NAME')}
                  </TableCell>
                  <TableCell align="left">
                    {translate('REPRESENTATIVE_LIST.PERMISSION')}
                  </TableCell>
                  <TableCell align="left">
                    {translate('REPRESENTATIVE_LIST.STATUS')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.map((row, index) => (
                  <TableRow
                    id={`REPRESENTATIVE_${textToConstant(row.email)}`}
                    key={index}
                    className={clsx({
                      [classes.disableRow]: !canEditRow(row)
                    })}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell padding={'default'} align="left">
                      {row.situation != 'REJECTED' && (
                        <div
                          className={classes['statusCircle' + row.situation]}
                        ></div>
                      )}
                      <WrapContent>
                        <WrapEmail alt={row.email} title={row.email}>
                          {row.email}
                        </WrapEmail>
                        {row.userInactive ? (
                          <TableChip
                            size="small"
                            label={translate('REPRESENTATIVE_LIST.INACTIVE')}
                          />
                        ) : null}
                      </WrapContent>
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="center" width="160">
                      <div
                        style={{
                          paddingRight: '15px'
                        }}
                      >
                        <Select
                          name="permission"
                          disableUnderline={true}
                          disabled={!canEditRow(row)}
                          fullWidth
                          native
                          value={row.permission}
                          onChange={event => handlePermission(event, index)}
                        >
                          {[
                            'ROLE_INSTITUICAO_TOTAL',
                            'ROLE_INSTITUICAO_PARCIAL',
                            'ROLE_INSTITUICAO_LIMITADA'
                          ].map(item => (
                            <option key={item} value={item}>
                              {translate(`USER:ROLE_SHORT.${item}`)}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell width="170">
                      <Grid container display="flex">
                        <div
                          style={{
                            width: 'calc(100% - 47px)',
                            marginTop: '2px',
                            paddingRight: '3px'
                          }}
                        >
                          <Select
                            name="situation"
                            disableUnderline={true}
                            fullWidth
                            disabled={!canEditRow(row)}
                            native
                            value={row.deleted ? 'DELETED' : row.situation}
                            onChange={event => handleSituation(event, index)}
                          >
                            <option value="ACTIVE">
                              {translate('LABORATORY:SITUATION.ACTIVE')}
                            </option>
                            <option value="INACTIVE">
                              {translate('LABORATORY:SITUATION.INACTIVE')}
                            </option>
                            {row.deleted || row.situation === 'DELETED' ? (
                              <option value="DELETED">
                                {translate('LABORATORY:SITUATION.DELETED')}
                              </option>
                            ) : null}
                            {row.situation === 'PENDING' ? (
                              <option value="PENDING">
                                {translate('LABORATORY:SITUATION.PENDING')}
                              </option>
                            ) : null}
                            {row.situation === 'REJECTED' ? (
                              <option value="REJECTED">
                                {translate('LABORATORY:SITUATION.REJECTED')}
                              </option>
                            ) : null}
                          </Select>
                        </div>
                        <div style={{ width: '44px' }}>
                          {!row.deleted ? (
                            <TableActions
                              actionList={rowActionList(row)}
                              handleActionSelected={code =>
                                handleRowAction(code, row)
                              }
                              disabled={row.responsible}
                            />
                          ) : (
                            <IconButton
                              onClick={() => undoRemoveInvite(row)}
                              aria-label="undo"
                              title="Desfazer exclusão"
                              name="UNDO"
                            >
                              <UndoIcon className="fas fa-undo"></UndoIcon>
                            </IconButton>
                          )}
                        </div>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default RepresentativeList
