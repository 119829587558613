import * as Yup from 'yup'

Yup.addMethod(Yup.string, "cpf", function(errorMessage='CPF inválido') {

    return this.test('cpf', errorMessage, 
    function(cpf) {
      const { path, createError } = this;
  
      cpf = cpf.replace(/\D/g,'');

      const characters = new Set(Array.from(cpf))
      if (characters.size === 1) {
        return false
      }

      let soma = 0
      let resto
      for (var i = 1; i <= 9; i++) 
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
      resto = (soma * 10) % 11
      if ((resto === 10) || (resto === 11))  resto = 0
      if (resto !== parseInt(cpf.substring(9, 10)) ) return createError({ path, message: errorMessage });
        soma = 0
      for (var j = 1; j <= 10; j++)
        soma = soma + parseInt(cpf.substring(j-1, j)) * (12 - j)
      resto = (soma * 10) % 11
      if ((resto === 10) || (resto === 11))  resto = 0
      if (resto !== parseInt(cpf.substring(10, 11) ) ) return createError({ path, message: errorMessage });
      return true
  })
})