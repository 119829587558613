export const maskToCpf = (cpf, start, end, substitute) => {
  return cpf.substring(0, start) + substitute + cpf.substring(end)
}

export const maskToEmail = email => {
  let emailIndex = email.indexOf('@')
  let emailAddress = email.substring(0, emailIndex)

  if (emailAddress.length % 2 == 0) {
    let halfEmail = emailAddress.length / 2
    let asterisk = '*'.repeat(halfEmail)
    emailAddress = replaceRange(emailAddress, halfEmail, emailIndex, asterisk)
  } else {
    let halfEmail = parseInt(Math.ceil(parseFloat(emailAddress.length) / 2) - 1)
    let asterisk = '*'.repeat(halfEmail + 1)
    emailAddress = replaceRange(emailAddress, halfEmail, emailIndex, asterisk)
  }

  let at = email.lastIndexOf('@')
  return emailAddress + '@' + email.substring(at + 1)
}

export const replaceRange = (s, start, end, substitute) => {
  return s.substring(0, start) + substitute + s.substring(end)
}
