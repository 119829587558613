import intersection from 'lodash/intersection'
import React, { useContext, useEffect } from 'react'

import { AuthContext } from 'context/AuthContext'
import { generatePrivatePath } from 'helpers/history'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'

import Item from '../Item'
import { ListStyled } from './styles'

function ListNav({ open }) {
  const { userData } = useContext(AuthContext)

  const listItens = [
    {
      label: 'NAV_PROFILE',
      icon: 'fa-user-circle',
      action: generatePrivatePath('')
    },
    {
      label: 'NAV_INFRAESTRUCTURE',
      icon: 'fa-atom',
      action: generatePrivatePath('/infraestructure')
    },
    {
      label: 'NAV_ADMIN',
      icon: 'fa-cog',
      action: generatePrivatePath('/admin'),
      roleValidation: user => {
        return user.roles.indexOf('ROLE_ADMIN') >= 0
      }
    },
    {
      label: 'NAV_INSTITUTION',
      icon: 'fa-university',
      action: generatePrivatePath('/register-institution'),
      roleValidation: user => {
        return user.institutionRole !== null
      }
    },
    {
      label: 'NAV_LAB',
      icon: 'fa-flask',
      action: generatePrivatePath('/laboratory'),
      roleValidation: user => {
        return intersection(user.roles, [
          'ROLE_LABORATORIO_TOTAL',
          'ROLE_LABORATORIO_PARCIAL',
          'ROLE_LABORATORIO_LIMITADA',
          'ROLE_INSTITUICAO_TOTAL',
          'ROLE_INSTITUICAO_PARCIAL',
          'ROLE_INSTITUICAO_LIMITADA'
        ]).length
      }
    },
    {
      label: 'NAV_LIST_REPRESENTANTS',
      icon: 'fa-users-cog',
      action: generatePrivatePath('/list-representants-laboratory'),
      roleValidation: user => {
        return user.showEquipment
      }
    },
    {
      label: 'NAV_EQUIPMENT',
      icon: 'fa-microscope',
      action: generatePrivatePath('/equipments'),
      roleValidation: user => {
        return user.showEquipment
      }
    },
    {
      label: 'NAV_SHARING',
      icon: 'fa-share-alt',
      action: generatePrivatePath(REQUEST_SHARING_ROUTES.LIST)
    },
    {
      label: 'NAV_ADVERTISE_PLATFORM',
      icon: 'fa-paper-plane',
      action: generatePrivatePath('/advertise-platform')
    },
    // {
    //   label: 'NAV_PNIPE_CAMPAIGN',
    //   icon: 'fa-syringe',
    //   action: generatePrivatePath('/covid-campaign'),
    //   roleValidation: user => {
    //     return user.roles.indexOf('ROLE_GESTOR_ACOES') >= 0
    //   }
    // }
  ]

  useEffect(() => {
    if (!userData) return
  }, [userData])

  return (
    <ListStyled>
      {listItens.map(function (item, index) {
        if (!userData) return false

        if (item.roleValidation && !item.roleValidation(userData)) {
          return false
        }

        return <Item key={index} item={item} open={open} />
      })}
    </ListStyled>
  )
}

export default ListNav
