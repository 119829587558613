import React, { useContext } from 'react'

import { Button } from '@material-ui/core'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from 'ui/atoms/Dialog'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'

import RequestSharingForm from 'views/BackOffice/Subviews/RequestSharing/fragments/RequestForm'

const PreviewSharingModal = ({ open, onClose: handleClose }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>{translate('TITLE')}</DialogTitle>
      <DialogContent>
        <RequestSharingForm readOnly initialData={{}} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="default" onClick={handleClose}>
          {translate('COMMONS:CANCEL')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default wrapper(PreviewSharingModal, {
  namespace: ['PREVIEW_SHARING_MODAL', 'REQUEST_SHARING']
})
