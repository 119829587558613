import Admin from './Subviews/Admin'
import { ADMIN_ROUTES } from './Subviews/Admin/constants/routes'
import ForbiddenPage from './Subviews/Admin/Subviews/ForbiddenPage'
import UserEdit from './Subviews/Admin/Subviews/UserEdit'
import AdvertisePlatform from './Subviews/AdvertisePlatform'
import CovidCampaign from './Subviews/CovidCampaign'
import EquipmentRegister from './Subviews/Equipment'
import EquipmentList from './Subviews/EquipmentList'
import Infraestructure from './Subviews/Infraestructure'
import InstitutionRegister from './Subviews/InstitutionRegister'
import InstitutionSolicitationView from './Subviews/InstitutionSolicitationView'
import LaboratoryEdit from './Subviews/LaboratoryEdit'
import LaboratoryList from './Subviews/LaboratoryList'
import LaboratoryRegister from './Subviews/LaboratoryRegister'
import LaboratoryListRepresentant from './Subviews/LaboratoryRepresentant'
import MyPanel from './Subviews/MyPanel'
import Profile from './Subviews/Profile'
import RegisterNewInstitution from './Subviews/RegisterNewInstitution'
import { REQUEST_SHARING_ROUTES } from './Subviews/RequestSharing/constants/routes'
import AnalyzeRequestSharing from './Subviews/RequestSharing/Subviews/Analyze'
import RequestSharingDetails from './Subviews/RequestSharing/Subviews/Details'
import EditRequestSharing from './Subviews/RequestSharing/Subviews/Edit'
import ListRequestSharing from './Subviews/RequestSharing/Subviews/List'
import RegisterRequestSharing from './Subviews/RequestSharing/Subviews/Register'

const routes = [
  {
    path: '',
    component: MyPanel
  },
  {
    path: '/forbidden',
    component: ForbiddenPage
  },
  {
    path: '/my-profile',
    component: Profile
  },
  { path: '/infraestructure', component: Infraestructure },
  { path: '/register-new-institution', component: RegisterNewInstitution },
  {
    path: '/institution-solicitation-view/:institutionId',
    component: InstitutionSolicitationView
  },
  {
    path: '/register-institution',
    component: InstitutionRegister
  },

  {
    path: '/register-laboratory',
    component: LaboratoryRegister
  },
  {
    path: '/laboratory',
    component: LaboratoryList
  },
  {
    path: '/laboratory/:laboratoryId',
    component: LaboratoryEdit
  },
  {
    path: '/laboratory/:laboratoryId/action/:action/:isPublished?',
    component: LaboratoryRegister
  },
  {
    path: '/list-representants-laboratory/',
    component: LaboratoryListRepresentant
  },
  {
    path: '/laboratory/:labId/equipment/:id?',
    component: EquipmentRegister
  },
  {
    path: '/equipment/congelador-covid',
    component: ForbiddenPage,
    params: { covidFreezer: true }
  },
  {
    path: '/equipment/:id?',
    component: EquipmentRegister
  },
  {
    path: '/equipments',
    component: EquipmentList
  },
  {
    path: '/advertise-platform',
    component: AdvertisePlatform
  },
  {
    path: ADMIN_ROUTES.ADMIN,
    component: Admin,
    exact: false,
    rule: user => {
      return user.roles.includes('ROLE_ADMIN')
    }
  },
  {
    path: ADMIN_ROUTES.USERS_EDIT,
    component: UserEdit,
    rule: user => {
      return user.roles.includes('ROLE_ADMIN')
    }
  },
  // {
  //   path: '/covid-campaign',
  //   component: CovidCampaign,
  //   rule: user => {
  //     return user.roles.includes('ROLE_GESTOR_ACOES')
  //   }
  // },
  {
    path: REQUEST_SHARING_ROUTES.LIST,
    component: ListRequestSharing
  },
  {
    path: REQUEST_SHARING_ROUTES.DETAILS,
    component: RequestSharingDetails,
    exact: false
  },
  {
    path: REQUEST_SHARING_ROUTES.NEW,
    component: RegisterRequestSharing
  },
  {
    path: REQUEST_SHARING_ROUTES.NEW_WITH_LAB,
    component: RegisterRequestSharing
  },
  {
    path: REQUEST_SHARING_ROUTES.EDIT,
    component: EditRequestSharing
  },
  {
    path: REQUEST_SHARING_ROUTES.ANALYZE,
    component: AnalyzeRequestSharing
  }
]

export default routes
