import { createSlice } from '@reduxjs/toolkit'

export const CAMPAIGN_REDUCER = 'campaign'

const slice = createSlice({
  name: CAMPAIGN_REDUCER,
  initialState: { showBanner: true },
  reducers: {
    hideBanner: state => {
      state.showBanner = false
    }
  }
})

export const actions = slice.actions
export const { hideBanner } = slice.actions

export default slice.reducer
