import React from 'react'

import { Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { WarningMessage } from 'ui/atoms/Messages'
import useStyles from 'ui/styles'

import { BoxContainer, BoxHeader } from '../../styles'

const InfraestructureCard = ({
  buttonText,
  disabled = false,
  disabledText = '',
  text,
  title,
  onClick
}) => {
  const classes = useStyles()

  return (
    <BoxContainer>
      <BoxHeader>
        <Typography variant="subtitle1">{title}</Typography>
        <Button
          type={'submit'}
          variant="contained"
          className={classes.baseButton}
          size="medium"
          onClick={onClick}
          disabled={disabled}
        >
          {buttonText}
        </Button>
      </BoxHeader>
      {text}
      {disabled && (
        <WarningMessage style={{ marginTop: '24px' }}>
          {disabledText}
        </WarningMessage>
      )}
    </BoxContainer>
  )
}

export default InfraestructureCard
