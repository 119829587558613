export const menuList = [
  {
    name: 'ABOUT_PLATAFORM',
    action: '/about'
  },
  {
    name: 'INFRASTRUCTURE',
    action: '/search'
  },
  {
    name: 'SUPORT',
    itens: [
      {
        name: 'FAQ',
        action: '/suport/faq'
      },
      {
        name: 'CONTACT_US',
        action: '/suport/contact-us'
      }
    ]
  },
  {
    name: 'REGISTER',
    action: '/register'
  }
]
export default menuList
