import React from 'react'
import styled from 'styled-components'

import { FormHelperText } from '@material-ui/core'

import { primaryColor } from 'helpers/constants'

import { useGlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'
import DefaultHint from 'ui/molecules/commons/DefaultHint'
import useStyles from 'ui/styles'

const PermissionRadioGroup = styled(RadioGroup)`
  &.MuiFormControl-root {
    margin-bottom: 0;
  }
`

const roleList = [
  { id: 'ADMIN', label: 'ROLE.ADMIN' },
  { id: 'GESTOR_ACOES', label: 'ROLE.GESTOR_ACOES' }
]

const Permissions = ({ formMethods }) => {
  const classes = useStyles()
  const { translate } = useGlobalizationContext()

  const { control, errors } = formMethods
  return (
    <>
      <label className={classes.label}>{translate('CREATE_ADMIN_MODAL.PERMISSION')}</label>
      <div
        className={
          errors.rolesType
            ? classes.permissionContainerError
            : classes.permissionContainer
        }
      >
        <PermissionRadioGroup
          control={control}
          name="roles"
          controlType="checkbox"
          options={roleList}
          columns={roleList.length}
          labelRenderer={option => (
            <>
              {translate(option.label)}
              <DefaultHint
                icon="fas fa-info-circle"
                color={primaryColor}
                content={`${option.label}_HINT`}
              />
            </>
          )}
        />
      </div>
      {errors.roles && (
        <div className={'permission-error-container'}>
          <FormHelperText error>{errors.roles.message}</FormHelperText>
        </div>
      )}
    </>
  )
}

export default Permissions
