import React from 'react'
import styled from 'styled-components'

import { Loader } from '../../style'

const List = styled.ul`
  overflow-x: hidden;
  overflow-y: auto;
`

const OptionList = React.forwardRef(
  (
    {
      children,
      loading,
      noMinInputLength,
      noMinInputLengthText,
      noOptionsText,
      ...props
    },
    ref
  ) => {
    const childrenArray = React.Children.toArray(children)
    const noOptions = !childrenArray.filter(
      option => !option.props['aria-selected']
    ).length

    return (
      <>
        <List {...props}>
          {childrenArray.map(item => {
            return React.cloneElement(
              item,
              {
                onClick: e => {
                  if (
                    (item.props['aria-selected'] &&
                      !e.target.classList.contains('button-remove')) ||
                    item.props['aria-disabled']
                  ) {
                    e.preventDefault()
                    e.stopPropagation()
                  } else {
                    item.props.onClick(e)
                  }
                }
              },
              item.props.children
            )
          })}
        </List>
        {loading ? <Loader /> : null}
        {!loading && noMinInputLength ? (
          <div className="no-options">{noMinInputLengthText}</div>
        ) : null}
        {!loading && !noMinInputLength && noOptions ? (
          <div className="no-options">{noOptionsText}</div>
        ) : null}
      </>
    )
  }
)
OptionList.displayName = 'OptionList'

export default OptionList
