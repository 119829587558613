// @ts-nocheck
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from 'ui/atoms/Table'
import { Title } from 'ui/atoms/Title'
import Pagination from 'ui/molecules/commons/Pagination'
import useStyles from 'ui/styles'

import { EyeIcon } from 'views/InstitutionView/styles'

import Eye from 'assets/icons/eye.svg'

const List = ({
  count,
  list,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  onChangePage,
  onChangeRowsPerPage
}) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  const showPagination = rowsPerPageOptions[0] < count

  const row = item => {
    return (
      <TableRow>
        <TableCell>
          {item.initials} - {item.name}
        </TableCell>
        <TableCell>{item.totalEquip}</TableCell>
        <TableCell>{item.totalMultiUser}</TableCell>
        <TableCell>
          {item.hasSharing ? translate('COMMONS:YES') : translate('COMMONS:NO')}
        </TableCell>
        <TableCell>
          <Button
            className={classes.noHover}
            component={Link}
            to={`/laboratory/${item.id}`}
          >
            <EyeIcon src={Eye} alt={'Visualizar'} />
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <Title variant="subtitle1" $noBorder style={{ marginTop: '32px' }}>
        {translate('COMMONS:LABORATORIES')}
      </Title>

      {showPagination && (
        <Pagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate('COMMONS:LABORATORY')}</TableCell>
              <TableCell>{translate('TABLE.QUANTITY_EQUIPS')}</TableCell>
              <TableCell>
                {translate('TABLE.QUANTITY_EQUIPS_MULTIUSER')}
              </TableCell>
              <TableCell>{translate('TABLE.SHARING')}</TableCell>
              <TableCell>{translate('TABLE.ACTION')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{list.map(item => row(item))}</TableBody>
        </Table>
      </TableContainer>

      {showPagination && (
        <Pagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}
    </>
  )
}

export default List
