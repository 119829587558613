import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'

import AddedDocuments, { previewFile } from 'ui/atoms/AddedDocuments'
import { useGlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle } from 'ui/atoms/Title'

const LabRejected = ({ laboratoryData }) => {
  const { translate } = useGlobalizationContext()

  const { analysis, laboratory } = laboratoryData
  const { documentRefuseList } = laboratory

  return (
    <Grid container spacing={2}>
      <Subtitle item xs={12}>
        {translate('REJECTED.TITLE')}
      </Subtitle>

      <Grid item>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} sm="auto">
              <Typography variant="h4">
                {translate('REJECTED.REASON')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography variant="body2">
                {analysis.reasonRefuseLaboratory}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {translate('REJECTED.DETAIL')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box pl={1}>
                <Typography variant="body2">
                  {analysis.refusalDetail}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4">{translate('REJECTED.FILE')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <AddedDocuments
                docs={documentRefuseList}
                viewFile={previewFile}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} sm="auto">
              <Typography variant="h4">
                {translate('REJECTED.AUTHOR')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Typography variant="body2">{analysis.author}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LabRejected
