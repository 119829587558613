import React, { useContext } from 'react'

import { Button } from '@material-ui/core'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import InstitutionData from 'ui/atoms/InstitutionData'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import useStyles from 'ui/styles'

const PendingSolicitation = ({
  institutionData,
  represantativeList,
  responsible,
  redirect
}) => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  return (
    <>
      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />
      <InstitutionData
        data={institutionData}
        representantsList={represantativeList}
        responsible={responsible}
      />
      <Button
        type={'button'}
        variant="contained"
        className={classes.baseButton}
        size="medium"
        onClick={redirect}
        disabled={false}
        style={{ margin: '40px 0' }}
      >
        {translate('ACTIONS.BACK')}
      </Button>
    </>
  )
}

export default PendingSolicitation
