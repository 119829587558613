import parseDate from 'date-fns/parseJSON'
import React, { createContext, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { requestSharingUseCases } from '../../../providers'

const ComunicationContext = createContext({
  messageList: [],
  listFinished: false,
  fetchNewMessages: () => {},
  fetchOlderMessages: () => {}
})

const UnreadCountContext = createContext({
  count: 0,
  updateUnreadCount: () => {}
})

function ComunicationProvider({ requestSharingId, children }) {
  const [messageList, setMessageList] = useState([])
  const [listFinished, setListFinished] = useState(false)
  const [unreadCount, setUnreadCount] = useState(0)

  const updateUnreadCount = useCallback(async () => {
    const currCount = await requestSharingUseCases.getUnreadCount(
      requestSharingId
    )
    setUnreadCount(currCount)
  }, [requestSharingId])

  const fetchNewMessages = useCallback(
    async (defaultDate, skipLoader) => {
      const lastMessage = messageList[messageList.length - 1]
      const lastMessageDate = lastMessage
        ? parseDate(lastMessage.createdDate)
        : defaultDate

      const newMessageList = await requestSharingUseCases.getMessagesAfter(
        requestSharingId,
        lastMessageDate,
        skipLoader
      )

      if (newMessageList?.length) {
        setMessageList(currList => [].concat(currList, newMessageList))
      } else {
        setListFinished(true)
      }
    },
    [messageList, requestSharingId]
  )

  const fetchOlderMessages = useCallback(async () => {
    const lastMessage = messageList[0]
    const lastMessageDate = lastMessage
      ? parseDate(`${lastMessage.createdDate}Z`)
      : new Date()

    const olderMessageList = await requestSharingUseCases.getMessagesBefore(
      requestSharingId,
      lastMessageDate
    )

    if (olderMessageList?.length) {
      setMessageList(currList => {
        return [].concat(olderMessageList.reverse(), currList)
      })
    } else {
      setListFinished(() => {
        return true
      })
      await updateUnreadCount()
    }
  }, [messageList, requestSharingId, updateUnreadCount])

  const comunicationContext = useMemo(
    () => ({
      messageList,
      listFinished,
      fetchNewMessages,
      fetchOlderMessages
    }),
    [fetchNewMessages, fetchOlderMessages, listFinished, messageList]
  )

  console.log({ count: unreadCount, updateUnreadCount })
  return (
    <UnreadCountContext.Provider
      value={{ count: unreadCount, updateUnreadCount }}
    >
      <ComunicationContext.Provider value={comunicationContext}>
        {children}
      </ComunicationContext.Provider>
    </UnreadCountContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
export const comunication = Component => props => {
  const { id } = useParams()
  return (
    <ComunicationProvider requestSharingId={id}>
      <Component {...props} />
    </ComunicationProvider>
  )
}

export { ComunicationProvider, ComunicationContext, UnreadCountContext }
