import React from 'react'
import { useHistory } from 'react-router-dom'

import BaseButton from 'ui/atoms/BasePrivate/BaseButton'
import Logo from 'ui/atoms/Header/Commons/Logo'
import Notification from 'ui/molecules/Header/Private/Notification'
import User from 'ui/molecules/Header/Private/User'

import Burger from './Fragments/Burger'
import { WrapHeader, WrapButtons, WrapLogo, WrapBox } from './styles'

function Header({ open, onMenuOpen, hideActions, showBurger = true }) {
  const history = useHistory()

  function redirectHome() {
    history.push('/')
  }

  const Home = !hideActions ? (
    <BaseButton
      label={'PORTAL_VIEW'}
      onClick={redirectHome}
      className={'portal-view'}
    />
  ) : null

  const Actions = !hideActions ? (
    <WrapButtons>
      <Notification />
      <User />
    </WrapButtons>
  ) : null

  return (
    <WrapHeader>
      <WrapBox>
        <WrapBox>
          <Burger open={open} toggleMenu={onMenuOpen} showBurger={showBurger} />
          <WrapLogo>
            <Logo />
          </WrapLogo>
          {Home}
        </WrapBox>
        {Actions}
      </WrapBox>
    </WrapHeader>
  )
}

export default Header
