import styled, { css } from 'styled-components'

import {
  TableContainer as MaterialTableContainer,
  Table as MaterialTable,
  TableRow as MaterialTableRow,
  TableCell as MaterialTableCell,
  TableHead as MaterialTableHead,
  TableBody as MaterialTableBody,
  TablePagination as MaterialTablePagination
} from '@material-ui/core'

import { InfoMessage } from 'ui/atoms/Messages'

export const TableContainer = styled(MaterialTableContainer)``
export const Table = styled(MaterialTable)`
  border-collapse: separate;
  border-spacing: 0px 6px;

  thead {
    th {
      border-bottom: none;
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
      }

      td:first-child {
        border-left: 1px solid #e0e0e0;
      }

      td:last-child {
        border-right: 1px solid #e0e0e0;
      }
    }
  }
`
export const TableHead = styled(MaterialTableHead)`
  th {
    padding: 5px 16px;
  }
  * {
    font-weight: 600;
    font-size: 14px;
  }
`
export const TableBody = styled(MaterialTableBody)`
  * {
    font-size: 14px;
  }
  padding-bottom: 5px;
  background: #f2f2f2;
  border-radius: 2px;
  overflow: hidden;
  td {
    height: 80px;
    padding: 0 16px;
  }
`
export const TableRow = styled(MaterialTableRow)``
export const TableCell = styled(MaterialTableCell)`
  border: 0;
`
export const TablePagination = styled(MaterialTablePagination)``

export const NoResults = styled(InfoMessage)``
