import styled from 'styled-components'

import MaterialTable from '@material-ui/core/Table'
import MaterialTableBody from '@material-ui/core/TableBody'
import MaterialTableCell from '@material-ui/core/TableCell'
import MaterialTableContainer from '@material-ui/core/TableContainer'
import MaterialTableHead from '@material-ui/core/TableHead'
import MaterialTableRow from '@material-ui/core/TableRow'

import { Marker, MarkerGreen, MarkerOrange, MarkerRed } from 'ui/atoms/Marker'

export const TableContainer = styled(MaterialTableContainer)``

export const Table = styled(MaterialTable)``

export const TableHead = styled(MaterialTableHead)``

export const TableRow = styled(MaterialTableRow)``

export const TableCell = styled(MaterialTableCell)`
  height: 40px;
  padding: 0px 10px;
`

export const TableBody = styled(MaterialTableBody)``

const markerColors = {
  ACTIVE: MarkerGreen,
  PENDING: MarkerOrange,
  INACTIVE: MarkerRed
}

export const StatusMarker = styled(Marker)`
  ${props => markerColors[props.type]}
  margin-right: 8px;
  width: 11px;
  height: 11px;
`

export const MarkerContainer = styled.div`
  display: flex;
  align-items: center;
`
