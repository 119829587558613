import { Auth } from 'aws-amplify'
import clsx from 'clsx'
import React, { useEffect, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import { red, grey } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'

import { AuthContext } from 'context/AuthContext'
import { confirmColor, specialCharactersRegex } from 'helpers/constants'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { history, generatePrivatePath } from 'helpers/history'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'

const useStyles = makeStyles({
  defaultContainer: {
    marginTop: '1.5rem'
  },
  baseButton: {
    borderRadius: '0px',
    float: 'right',
    textTransform: 'none',
    backgroundColor: '#009B7F',
    color: '#ffffff',
    padding: '9px',
    fontWeight: 'normal',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#006855',
      color: '#ffffff'
    }
  },

  passwordValidationValid: {
    color: confirmColor,
    padding: '0'
  },
  passwordValidationInvalid: {
    color: red[500],
    padding: '0'
  },
  passwordValidationIcon: {
    fontSize: '18px',
    minWidth: '32px'
  },
  passwordValidationText: {
    fontSize: '14px'
  },
  passwordValidationUntouched: {
    color: `${grey[500]} !important`,
    padding: '0'
  },

  passwordValidationTextClass: {
    color: 'inherit',
    fontWeight: 'inherit'
  }
})

const formSchema = Yup.object()
  .shape({
    password: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
    confirmPassword: Yup.string()
      .required('LOGIN_CHANGE_PASSWORD_CONFIRM')
      .test(
        'isPasswordEqual',
        'LOGIN_CHANGE_PASSWORD_MUST_MATCH',
        function (value) {
          return this.parent.password === value
        }
      )
  })
  .test('mustContainLowerCase', '', function (data) {
    if (/[a-z]/.test(data.password)) return true
    return new Yup.ValidationError(
      'mustContainLowerCase',
      null,
      'mustContainLowerCase',
      'mustContainLowerCase'
    )
  })
  .test('mustContainUpperCase', '', function (data) {
    if (/[A-Z]/.test(data.password)) return true
    return new Yup.ValidationError(
      'mustContainUpperCase',
      null,
      'mustContainUpperCase',
      'mustContainUpperCase'
    )
  })
  .test('mustContainNumber', '', function (data) {
    if (/[\d]/.test(data.password)) return true
    return new Yup.ValidationError(
      'mustContainNumber',
      null,
      'mustContainNumber',
      'mustContainNumber'
    )
  })
  .test('mustContainSpecialCharacter', '', function (data) {
    if (specialCharactersRegex.test(data.password)) return true
    return new Yup.ValidationError(
      'mustContainSpecialCharacter',
      null,
      'mustContainSpecialCharacter',
      'mustContainSpecialCharacter'
    )
  })
  .test('mustContain8Characters', '', function (data) {
    if (data.password && data.password.length >= 8) return true
    return new Yup.ValidationError(
      'mustContain8Characters',
      null,
      'mustContain8Characters',
      'mustContain8Characters'
    )
  })

function ChangePassword() {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()
  const { challengedUser } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)

  const resolver = useYupValidationResolver(formSchema, { translate })
  const { control, handleSubmit, trigger, errors, formState } = useForm({
    mode: 'onChange',
    resolver
  })

  const { isValid, dirtyFields } = formState

  useEffect(() => {
    if (!challengedUser) {
      history.push(generatePrivatePath(`/`))
    }
  }, [challengedUser])

  const onSubmit = async formData => {
    try {
      setIsLoading(true)
      await Auth.completeNewPassword(
        challengedUser,
        formData.password,
        null,
        challengedUser.challengeParam.userAttributes
      )
      setIsLoading(false)
    } catch (error) {
      console.log('error changing password', error)
    }
  }

  const handleClose = () => {
    history.push('/')
  }

  return challengedUser ? (
    <React.Fragment>
      <BaseContainerHeader
        label={translate('LOGIN_CHANGE_PASSWORD_TITLE')}
        showCloseButton={true}
        onClose={handleClose}
      />

      <Grid container justify="center" className={classes.defaultContainer}>
        <Grid item md={6}>
          <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
            <input type="hidden" autoComplete="false" />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BaseTextField
                  required={true}
                  label={translate('LOGIN_CHANGE_PASSWORD_NEW_LABEL')}
                  placeholder={translate(
                    'LOGIN_CHANGE_PASSWORD_NEW_PLACEHOLDER'
                  )}
                  errors={errors}
                  control={control}
                  handleChange={() => {
                    trigger([
                      'mustContainLowerCase',
                      'mustContainUpperCase',
                      'mustContainNumber',
                      'mustContainSpecialCharacter',
                      'mustContain8Characters'
                    ])
                  }}
                  name="password"
                  type="password"
                  autoComplete="false"
                  maxLength="20"
                ></BaseTextField>
              </Grid>
              <Grid item xs={12}>
                <BaseTextField
                  required={true}
                  label={translate('LOGIN_CHANGE_PASSWORD_REPEAT_LABEL')}
                  placeholder={translate(
                    'LOGIN_CHANGE_PASSWORD_REPEAT_PLACEHOLDER'
                  )}
                  errors={errors}
                  control={control}
                  name="confirmPassword"
                  type="password"
                  autoComplete="false"
                  maxLength="20"
                ></BaseTextField>
              </Grid>
              <Grid item xs={12}>
                <List>
                  {[
                    {
                      key: 'mustContainLowerCase',
                      message: translate('LOGIN_MUST_CONTAIN_LOWER_CASE')
                    },
                    {
                      key: 'mustContainUpperCase',
                      message: translate('LOGIN_MUST_CONTAIN_UPPER_CASE')
                    },
                    {
                      key: 'mustContainNumber',
                      message: translate('LOGIN_MUST_CONTAIN_NUMBER')
                    },
                    {
                      key: 'mustContainSpecialCharacter',
                      message: translate('LOGIN_MUST_CONTAIN_SPECIAL_CHARACTER')
                    },
                    {
                      key: 'mustContain8Characters',
                      message: translate('LOGIN_MUST_CONTAIN8_CHARACTERS')
                    }
                  ].map(item => {
                    let itemClass = ''
                    if (!dirtyFields.password || errors[item.key]) {
                      itemClass = classes.passwordValidationUntouched
                    } else if (dirtyFields.password && !errors[item.key]) {
                      itemClass = classes.passwordValidationValid
                    }

                    return (
                      <ListItem key={item.key} className={itemClass}>
                        <ListItemIcon
                          className={classes.passwordValidationIcon}
                        >
                          <CheckIcon className={itemClass} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              className={classes.passwordValidationTextClass}
                            >
                              {item.message}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  })}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type={'submit'}
                  variant="contained"
                  disabled={!isValid}
                  className={classes.baseButton}
                >
                  {translate('LOGIN_CHANGE_PASSWORD_SEND')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  ) : null
}

export default ChangePassword
