import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const MenuButtonStyled = styled(Button)`
  text-decoration: none;
  text-transform: none;
  font-weight: 600;
  font-style: normal;
  margin: 0 12px;
  font-size: 14px;
  align-self: flex-start;
  color: #333333;

  &:hover {
    background: none;
    color: #009b7f;

  }
`
const Submenu = styled(Paper)`
  border-radius: 2px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
  min-width: 200px;
  margin-top: 0px;
`
const MenuListStyled = styled(MenuList)`
  margin-top: 15px;
  @media (max-width: 1150px) {
    margin-top: 0;
  }
  display: flex;
  flex-direction: column;
`
const WrapSubmenu = styled(Popper)`
  z-index: 10;
  &:before {
    content: '';
    display: block;
    width: 40px;
    height: 4px;
    background: #009b7f;
    border-radius: 2px;
    position: absolute;
    left: 8px;
  }

  @media (max-width: 1150px) {
    /* transform: translate3d(0px) !important; */
    position: static !important;
    transform: none !important;
    &:before {
      display: none;
    }
    > div {
      box-shadow: none !important;
      padding: 4px 0 4px 12px !important;
      margin-top: 0 !important;
      border-bottom: 1px solid #ddd;
    }
  }
`

const isModifiedEvent = event => {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

function MenuButton(props) {
  const [open, setOpen] = React.useState(false)
  const { translate } = useContext(GlobalizationContext)

  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleMenuButtonClick = event => {
    if (props.item.itens) {
      event.preventDefault()
      handleToggle()
    } else if (props.onClick) {
      props.onClick(props.item.action)
    }
  }

  const handleMenuItemClick = (event, item) => {
    props.onClick(item)
    if (!isModifiedEvent(event)) {
      handleClose(event)
    }
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  let subMenu
  if (props.item.itens) {
    subMenu = (
      <WrapSubmenu
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'left top' : 'left bottom'
            }}
          >
            <Submenu>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuListStyled
                  autoFocusItem={open}
                  id={`${props.item.name}_MENU_LIST`}
                  onKeyDown={handleListKeyDown}
                >
                  {props.item.itens.map(item => (
                    <MenuButtonStyled
                      component={Link}
                      to={item.action}
                      onClick={event => handleMenuItemClick(event, item)}
                      key={item.name}
                      id={item.name}
                    >
                      {translate(item.name)}
                    </MenuButtonStyled>
                  ))}
                </MenuListStyled>
              </ClickAwayListener>
            </Submenu>
          </Grow>
        )}
      </WrapSubmenu>
    )
  }

  return (
    <>
      <MenuButtonStyled
        component={Link}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleMenuButtonClick}
        to={props.item.action || ''}
        id={props.item.name}
      >
        {props.children}
      </MenuButtonStyled>
      {subMenu || null}
    </>
  )
}

export default MenuButton
