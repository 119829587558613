import React, { useEffect, useContext } from 'react'

import { Typography } from '@material-ui/core'

import { InstitutionService } from 'api'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import Trash from 'assets/icons/trash.svg'

import { InstitutionContainer, TrashIcon } from '../../styles'
import SocialReason from '../SocialReason'

export default function LaboratoryInstitution(props) {
  const {
    classes,
    formMethods,
    formState,
    selectedInstitution,
    setSelectedInstitution
  } = props
  const { translate } = useContext(GlobalizationContext)

  const { setValue, setError, clearErrors } = formMethods

  const handleSocialReasonNoResults = value => {
    if (value) {
      setError('socialReason', {
        type: 'NO_RESULTS',
        message: translate('REGISTER_LABORTORY_SOCIAL_REASON_NOT_FOUND')
      })
      setError('institutionInitials', {})
      setValue('institutionCnpj', '')
    } else {
      clearErrors(['socialReason', 'institutionCnpj', 'institutionInitials'])
    }
  }

  const socialReasonSuggestionGetter = ({ value }) => {
    return InstitutionService.getInstitutionByTerm(value).then(
      resp => resp.data
    )
  }

  const onInstitutionSelected = newValue => {
    clearErrors(['socialReason', 'institutionCnpj', 'institutionInitials'])
    setSelectedInstitution(newValue)
  }

  useEffect(() => {
    setValue('institutionInitials', selectedInstitution?.initials || '')
    setValue('institutionCnpj', selectedInstitution?.cnpj || '')
    setValue('socialReason', selectedInstitution?.social_reason || '')
  }, [selectedInstitution, setValue])

  const clearInstitution = () => {
    setSelectedInstitution(null)
    setValue('institutionInitials', '')
    setValue('institutionCnpj', '')
    setValue('socialReason', '')
  }

  const hasSelectedInstitution =
    selectedInstitution &&
    selectedInstitution.cnpj.length !== 0 &&
    selectedInstitution.initials.length !== 0 &&
    selectedInstitution.social_reason.length !== 0

  const renderInstitutionData = () => (
    <InstitutionContainer>
      <Typography variant="body1">
        {`${selectedInstitution?.initials} - ${selectedInstitution?.social_reason}`}
      </Typography>
      <Typography variant="body1">{`CNPJ - ${selectedInstitution?.cnpj}`}</Typography>
      {formState === 'REGISTER' && (
        <TrashIcon src={Trash} alt={'Remover'} onClick={clearInstitution} />
      )}
    </InstitutionContainer>
  )

  return (
    <>
      <h3 className={classes.h3}>{translate('REGISTER_INSTITUTION_DATA')}</h3>
      {formState === 'REGISTER' && (
        <SocialReason
          disabled={hasSelectedInstitution}
          onSelected={onInstitutionSelected}
          suggestionGetter={socialReasonSuggestionGetter}
          errorNoResults={handleSocialReasonNoResults}
          formMethods={formMethods}
        />
      )}
      {hasSelectedInstitution && renderInstitutionData()}
    </>
  )
}
