import React, { useContext } from 'react'

import NotificationService from 'api/NotificationService'
import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'

import { GenericNotification } from './GenericNotification'
import { LinkStyledAccept } from './style'

const ComplementInstitutionCmp = ({ notification, itemClicked, ...rest }) => {
  const { translate } = useContext(GlobalizationContext)

  const onAcceptClick = async () => {
    await NotificationService.confirmNotification(notification.id)
    history.push(generatePrivatePath('/register-institution'))
    itemClicked()
  }

  return (
    <GenericNotification
      titleKey="COMPLEMENT_INSTITUTION_TITLE"
      messageKey="COMPLEMENT_INSTITUTION"
      notification={{
        institution: `${notification.cnpj.initials} - ${notification.cnpj.social_reason}`
      }}
      {...rest}
    >
      <LinkStyledAccept onClick={onAcceptClick}>
        {translate('COMPLEMENT')}
      </LinkStyledAccept>
    </GenericNotification>
  )
}

const ComplementInstitution = wrapper(ComplementInstitutionCmp, {
  namespace: 'NOTIFICATIONS'
})

export { ComplementInstitution }
