import { AdminService } from 'api'

import { notificationUseCases } from 'views/Notification/providers'

import LaboratoryListUseCase from './laboratoryList.useCases'

export const laboratoryListUseCase = new LaboratoryListUseCase({
  AdminService,
  notificationUseCases
})
