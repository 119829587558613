// @ts-nocheck
import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { cnpjMask } from 'helpers/maskedCNPJ'
import { phoneMask } from 'helpers/maskedPhone'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import { Section } from '../../styles'

const InstitutionData = ({ formMethods }) => {
  const { translate } = useContext(GlobalizationContext)

  const { control, errors } = formMethods
  return (
    <Section>
      <Typography variant="subtitle1">
        {translate('INSTITUTION_DATA')}
      </Typography>

      <Grid container spacing={3} style={{ marginTop: '32px' }}>
        <Grid item xs={12} md={10}>
          <BaseTextField
            required={true}
            disabled={false}
            placeholder="Informe"
            label="Razão Social"
            errors={errors}
            control={control}
            name="social_reason"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <BaseTextField
            required={true}
            disabled={false}
            placeholder="Informe"
            label="Sigla"
            errors={errors}
            control={control}
            name="initials"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '24px' }}>
        <Grid item xs={12} md={3}>
          <BaseTextField
            required={true}
            disabled={false}
            placeholder="Informe"
            label="CNPJ"
            errors={errors}
            control={control}
            mask={cnpjMask}
            name="cnpj"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <BaseTextField
            required={true}
            disabled={false}
            placeholder="Informe"
            label="Telefone Institucional"
            errors={errors}
            control={control}
            mask={phoneMask}
            name="phoneNumber"
          />
        </Grid>
      </Grid>
    </Section>
  )
}

export default InstitutionData
