import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'
import useStyles from 'ui/styles'

import {
  ItemContainer,
  Picture,
  PictureContainer,
  DataContainer,
  Title,
  About,
  ParamsContainer,
  Param
} from '../style'

export const LaboratoryItem = ({ content }) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  return (
    <ItemContainer>
      <PictureContainer>
        <Picture src={content.path}>
          <Icon className="fas fa-image" fontSize="inherit" />
        </Picture>
      </PictureContainer>
      <DataContainer>
        <Title>{`${content.initials} - ${content.name}`}</Title>
        {content.hasSharing ? (
          <ParamsContainer style={{ marginBottom: '16px', flex: '0 1 auto' }}>
            <Grid container spacing={2} className="line-container">
              {content.hasSharing ? (
                <Grid item xs>
                  <Param $situationParam $iconPrimary>
                    <Icon className="icon fas fa-share-alt-square" />
                    {translate('SHARING:SHARING_ENABLED')}
                  </Param>
                </Grid>
              ) : null}
            </Grid>
          </ParamsContainer>
        ) : null}
        <About>{content.about}</About>
        <ParamsContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} md>
              <Param noWrap>
                <strong>{translate('INSTITUTION')} </strong>
                {`${content.institutionInitials} - ${content.institutionName}`}
              </Param>
            </Grid>
            <Grid item xs={12}>
              <Param noWrap>
                <strong>{translate('LOCATION')} </strong>
                {`${content.city}/${content.state}`}
              </Param>
            </Grid>
          </Grid>
          <div className="actions">
            <Button
              component={Link}
              className={classes.baseButton}
              to={`/laboratory/${content.idAsset}`}
            >
              {translate('VIEW')}
            </Button>
          </div>
        </ParamsContainer>
      </DataContainer>
    </ItemContainer>
  )
}

export default wrapper(LaboratoryItem, { namespace: 'LAB_EQUIP_CARD' })
