import React, { useContext } from 'react'

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'

import { fontFamily } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const theme = createMuiTheme({
  fontFamily: fontFamily,
  fontFeatureSettings: 'lnum',
  typography: {
    fontFamily: fontFamily,
    fontFeatureSettings: 'lnum'
  },

  overrides: {
    MuiTypography: {
      body2: {
        display: 'block!important',
        color: 'rgb(51, 51, 51)',
        fontSize: '14px',
        fontWeight: 600
      }
    },
    MuiTablePagination: {
      root: {
        border: 'none'
      },
      selectRoot: {
        background: 'rgb(242, 242, 242)',
        margin: '0 25px 0 10px!important',
        padding: '4px',
        borderRadius: '3px',
        fontSize: '14px',
        fontWeight: 600
      }
    }
  }
})

function Pagination(props) {
  const { translate } = useContext(GlobalizationContext)

  const {
    count,
    page,
    onChangePage,
    rowsPerPage,
    onChangeRowsPerPage,
    rowsPerPageOptions
  } = props

  return (
    <ThemeProvider theme={theme}>
      <TablePagination
        component="div"
        count={count || 0}
        rowsPerPageOptions={rowsPerPageOptions}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) => {
          return `
        ${from} - ${to}
        ${translate('PAGINATION_OF')}
        ${count}`
        }}
        labelRowsPerPage={translate('PAGINATION_ITENS_PER_PAGE')}
      />
    </ThemeProvider>
  )
}
export default Pagination
