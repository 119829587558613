import React from 'react'

import SvgIcon from '../svgIcon'
import { ReactComponent as iconWarning } from './Warning.svg'

const IconWarning = props => (
  <SvgIcon component={iconWarning} viewBox="0 0 16 17" {...props} />
)

export default IconWarning
