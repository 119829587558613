import React from 'react'

import { Box } from '@material-ui/core'

import { TableChip, TableChipError } from 'ui/atoms/Chip'

const EquipmentOption = ({ code, name, isBroken, reason }) => {
  const ChipComponent = isBroken ? TableChipError : TableChip
  return (
    <Box display="flex" alignItems="center">
      <Box component="span" pr={1}>{`${code} - ${name}`}</Box>
      {reason ? <ChipComponent size="small" label={reason} /> : null}
    </Box>
  )
}

export default EquipmentOption
