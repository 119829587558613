import clsx from 'clsx'
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { Grid, ThemeProvider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { LaboratoryService } from 'api'
import { fileUploadConfig } from 'helpers/constants'

import AddedDocuments from 'ui/atoms/AddedDocuments'
import BaseTextField from 'ui/atoms/BaseTextField'
import DocumentUploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'

import RefusalReason from './fragments/RefusalReason'
import { Container, Title, Wrapper } from './styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: '1px solid #cccccc',
    '&.hasError': {
      borderColor: 'red'
    },
    '&:before': {
      content: `'*'`,
      position: 'absolute',
      left: '-15px',
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      top: '38px',
      width: '20px',
      height: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '15px'
    }
  }
}))

const Question = styled(RadioGroup)`
  &.MuiFormControl-root {
    display: flex;
    flex-direction: row;
  }

  .MuiFormHelperText-root {
    width: 100%;
  }

  .MuiFormGroup-root {
    width: 50%;
    flex: 1 1 auto;
    flex-wrap: nowrap;
  }

  .MuiFormLabel-root {
    width: 50%;
    line-height: 20px;
    flex: 1 1 auto;
    display: inline-flex;
    align-items: center;
    white-space: pre;
  }
`

function RequestOpnion({
  formMethods,
  documents,
  onRemoveFile: handleOnRemoveFile,
  onViewFile: handleOnViewFile,
  handleChange
}) {
  const { translate } = useContext(GlobalizationContext)
  const { control, errors } = formMethods

  const [refusalReasonList, setRefusalReasonList] = useState([])
  const classes = useStyles()

  const questions = useWatch({
    control,
    name: ['requestOpinionUnitQuestion', 'requestOpinionResearcherQuestion'],
    defaultValue: {
      requestOpinionUnitQuestion: 'false',
      requestOpinionResearcherQuestion: 'false'
    }
  })

  const booleanList = useMemo(() => {
    return [
      {
        id: 'true',
        name: translate(`COMMONS:YES`)
      },
      {
        id: 'false',
        name: translate(`COMMONS:NO`)
      }
    ]
  }, [translate])

  useEffect(() => {
    LaboratoryService.getRefuseReasons().then(resp => {
      setRefusalReasonList(resp)
    })
  }, [])

  const wrapperClasses = clsx(
    classes.wrapper,
    errors &&
      (errors['requestOpinionUnitQuestion'] ||
        errors['requestOpinionResearcherQuestion'])
      ? 'hasError'
      : false
  )

  return (
    <Container>
      <Title>{translate('REQUEST_OPINION_LABORATORY_REQUEST_OPNION')}</Title>
      <Wrapper className={wrapperClasses}>
        <Question
          control={control}
          name="requestOpinionUnitQuestion"
          label={translate('REQUEST_OPINION_UNIT_QUESTION')}
          options={booleanList}
          error={errors}
          row
        />

        <Question
          control={control}
          name="requestOpinionResearcherQuestion"
          label={translate('REQUEST_OPINION_RESEARCHER_QUESTION')}
          options={booleanList}
          error={errors}
          row
        />

        {(questions.requestOpinionUnitQuestion === 'false' ||
          questions.requestOpinionResearcherQuestion === 'false') && (
          <>
            <RefusalReason
              formMethods={formMethods}
              reasons={refusalReasonList}
            />

            <Grid item xs={12} md={12} style={{ margin: '0 0 16px' }}>
              <BaseTextField
                rows={5}
                label={translate('REQUEST_OPINION_DETAIL_REASON')}
                placeholder={translate(
                  'REQUEST_OPINION_DETAIL_REASON_PLACEHOLDER'
                )}
                control={control}
                name="requestOpinionComplement"
                maxLength={500}
              />
            </Grid>

            <DocumentUploader
              label={'REQUEST_OPINION_ATTACH_FILE'}
              wrapperDescription={'REQUEST_OPINION_ATTACH_LAB_REFUSED'}
              onChange={handleChange}
              filesConfig={fileUploadConfig.laboratoryRefusalOpinion}
              currentListLength={documents.length}
            />
            <AddedDocuments
              docs={documents}
              viewFile={handleOnViewFile}
              removeFile={handleOnRemoveFile}
            />
          </>
        )}
      </Wrapper>
    </Container>
  )
}

export default RequestOpnion
