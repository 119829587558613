import React, { forwardRef, useCallback, useContext } from 'react'

import { Grid, Icon, Link, TextareaAutosize } from '@material-ui/core'

// import AddedDocuments, { previewFile } from 'ui/atoms/AddedDocuments'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle, Title } from 'ui/atoms/Title'

import { SharingConfigContainer } from '../SharingConfig/style'

function addHttp(url) {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'http://' + url
  }
  return url
}

const NewTabLink = forwardRef((props, ref) => (
  <a target="_blank" ref={ref} {...props} />
))
NewTabLink.displayName = 'NewTabLink'

const SharingConfigReadOnly = ({ textAreaUseStatement, shareLink = null, sharingType }) => {
  const { translate } = useContext(GlobalizationContext)

  const renderShareLink = useCallback(() => {
    const link = addHttp(shareLink)
    const linkDisplay = link.split('//')[1]

    return (
      <>
        <Subtitle item xs={12}>
          {translate('EDIT_LABORATORY_SHARING_VIEW_OWN_SHARING_TITLE')}
        </Subtitle>
        <Subtitle item variant="subtitle2" xs={12}>
          {translate('EDIT_LABORATORY_SHARING_VIEW_OWN_SHARING_DESC')}
        </Subtitle>
        <Grid item xs={12}>
          <Link
            component={NewTabLink}
            href={link}
            color="primary"
            underline="none"
          >
            {linkDisplay}{' '}
            <Icon
              style={{ fontSize: '14px' }}
              className="fas fa-external-link-alt"
            />
          </Link>
        </Grid>
      </>
    )
  }, [shareLink, translate])
  

  const renderTermsOfUse = useCallback(() => {
    const termsOfUse = textAreaUseStatement;
    return (
      <>
        <Subtitle item xs={12}>
          {translate('EDIT_LABORATORY_SHARING_VIEW_TITLE')}
        </Subtitle>
        <Subtitle item variant="subtitle2" xs={12}>
          {translate('EDIT_LABORATORY_SHARING_VIEW_DESC')}
        </Subtitle>
        <Grid item xs={12}>
        <TextareaAutosize
           maxRows={16}
           minRows={8}
          aria-label=""
          defaultValue={termsOfUse}
          style={{ resize: 'none', width: "100%", height: "100%", border: "none" }}
          disabled={true}
       
        />
        </Grid>
      </>
    )
  }, [textAreaUseStatement, translate])

  return (
    <>
      <Title item xs={12} variant="h3" $noPaddingBottom={true} $noBorder={true}>
        {translate('EDIT_LABORATORY_SHARING_VIEW')}
      </Title>
      <Grid item xs={12}>
        <SharingConfigContainer>
          <Grid container spacing={3}>
            {/* {documents?.length ? renderDocuments() : null} */}
            {(sharingType == 'PLATFORM_SHARING' ) ? renderTermsOfUse() : null}
            {shareLink ? renderShareLink() : null}
          </Grid>
        </SharingConfigContainer>
      </Grid>
    </>
  )
}

export default SharingConfigReadOnly
