import About from 'views/About'
import ContactUs from 'views/ContactUs'
import CovidCampaign from 'views/CovidCampaign'
import EquipmentDetail from 'views/EquipmentDetail'
import FAQ from 'views/FAQ'
import Home from 'views/Home'
import InstitutionView from 'views/InstitutionView'
import LaboratoryDetail from 'views/LaboratoryDetail'
import Register from 'views/Register'
import Search from 'views/Search'

import EquipmentBreadcrumb from './Fragments/Breadcrumbs/customCrumbs/equipment.breadcrumb'
import HomeBreadcrumb from './Fragments/Breadcrumbs/customCrumbs/home.breadcrumb'
import InstitutionViewBreadcrumb from './Fragments/Breadcrumbs/customCrumbs/InstitutionViewBreadcrumb'
import LaboratoryBreadcrumb from './Fragments/Breadcrumbs/customCrumbs/laboratory.breadcrumb'
import SearchBreadcrumb from './Fragments/Breadcrumbs/customCrumbs/search.breadcrumb'
import breadcrumbBuilder from './Fragments/Breadcrumbs/itemBuilder'

export const PUBLIC_ROUTES = [
  {
    path: '/',
    exact: true,
    component: Home,
    breadcrumb: HomeBreadcrumb
  },
  {
    path: '/search',
    exact: true,
    component: Search,
    breadcrumb: SearchBreadcrumb
  },
  {
    path: '/equipment/:id',
    exact: true,
    component: EquipmentDetail,
    breadcrumb: EquipmentBreadcrumb
  },
  {
    path: '/laboratory/:id',
    exact: true,
    component: LaboratoryDetail,
    breadcrumb: LaboratoryBreadcrumb
  },
  {
    path: '/suport/faq',
    exact: true,
    component: FAQ,
    breadcrumb: breadcrumbBuilder(['SUPPORT', 'FAQ'])
  },
  {
    path: '/suport/contact-us',
    exact: true,
    component: ContactUs,
    breadcrumb: breadcrumbBuilder(['SUPPORT', 'CONTACT_US'])
  },
  {
    path: '/register',
    exact: true,
    component: Register,
    breadcrumb: 'REGISTER'
  },
  {
    path: '/about',
    exact: true,
    component: About,
    breadcrumb: 'ABOUT'
  },
  // {
  //   path: '/campanha-covid',
  //   exact: true,
  //   component: CovidCampaign,
  //   breadcrumb: 'COVID_CAMPAIGN'
  // },
  {
    path: '/institution/:id',
    exact: true,
    component: InstitutionView,
    breadcrumb: InstitutionViewBreadcrumb
  }
]
