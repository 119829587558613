import React, { useState, useEffect, useContext } from 'react'

import { Grid } from '@material-ui/core'

import { LaboratoryService } from 'api'

import BaseSelectUncontrolled from 'ui/atoms/BaseSelectUncontrolled'
import BaseTextFieldPublic from 'ui/atoms/BaseTextFieldPublic.js'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const BodyFilters = props => {
  const [filterList, setFilterList] = useState()
  const [newFilterList, setNewFilterList] = useState()
  const [filterOption, setFilterOption] = useState('')
  const { translate } = useContext(GlobalizationContext)

  const { onSelectChange, onInputChange } = props

  const getEquipmentService = () => {
    // EquipmentService.getFiltersSitutation().then((resp) => {
    LaboratoryService.getFiltersSitutation().then(resp => {
      setFilterList(resp.data)
    })
  }

  const handleChange = event => {
    setFilterOption(event.target.value)
    onSelectChange(event)
  }

  useEffect(() => {
    if (!filterList?.length) return
    setNewFilterList(
      filterList.map(item => ({
        id: item.id,
        name: translate(`LABORATORY:SITUATION.${item.value}`)
      }))
    )
  }, [filterList, translate])

  useEffect(() => {
    getEquipmentService()
  }, [])

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: '20px' }}
        alignItems="baseline"
      >
        <Grid item xs={12} md={3}>
          {newFilterList && (
            <>
              <BaseSelectUncontrolled
                id="FILTER_STATUS"
                defaultValue={translate('FILTER_STATUS')}
                label=""
                name="filterLaboratoryStatus"
                options={newFilterList}
                onChange={handleChange}
                value={filterOption}
              ></BaseSelectUncontrolled>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={9}>
          <BaseTextFieldPublic
            id="FILTER_TEXT"
            placeholder={translate('SEARCH_DEFAULT')}
            handleChange={onInputChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default BodyFilters
