// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import wrapper from 'ui/atoms/Wrapper'
import { InstitutionService } from 'api'

import ContentContainer from 'ui/atoms/ContentContainer'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import AtomSpinner from 'ui/atoms/AtomSpinner'
import List from './Fragments/List'

import { primaryColor } from 'helpers/constants'
import { Overlay } from './styles'
import InstitutionData from './Fragments/InstitutionData'

const rowsPerPageList = [10, 20, 50, 100]

const InstitutionView = () => {
  const { id: institutionId } = useParams()
  const [institutionData, setInstitutionData] = useState(null)
  const [labList, setLabList] = useState([])
  const [totalLab, setTotalLab] = useState(null)
  const [totalEquip, setTotalEquip] = useState(null)
  const [isLoading, setIsloading] = useState(false)

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [pageCount, setPageCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  const initData = useCallback(async () => {
    setIsloading(true)
    const { institution, totalEquip, totalLab } =
      await InstitutionService.getInstitution(institutionId)
    setInstitutionData(institution)
    setTotalEquip(totalEquip)
    setTotalLab(totalLab)
    setIsloading(false)
  }, [institutionId])

  const getLabList = useCallback(async () => {
    try {
      const response = await InstitutionService.getInstitutionLabs(
        institutionId,
        pageSize,
        pageNumber
      )

      const { content, ...pagination } = response
      setLabList(content)
      setPageCount(pagination.totalElements)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    initData()
  }, [initData])

  useEffect(() => {
    getLabList()
  }, [getLabList])

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  if (isLoading) {
    return (
      <Overlay>
        <AtomSpinner color={primaryColor} />
      </Overlay>
    )
  }
  return (
    <ContentContainer>
      <BaseContainerHeader
        label={`${institutionData?.initials} - ${institutionData?.social_reason}`}
      />

      <InstitutionData
        institutionData={institutionData}
        totalEquip={totalEquip}
        totalLab={totalLab}
      />

      <List
        list={labList}
        rowsPerPageOptions={rowsPerPageList}
        count={pageCount}
        rowsPerPage={pageSize}
        page={pageNumber}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </ContentContainer>
  )
}

export default wrapper(InstitutionView, {
  mapState: null,
  mapDispatch: null,
  namespace: 'PUBLIC_INSTITUTION_VIEW'
})
