import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Link as MuiLink } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import { InfraestrucutureService } from 'api'
import { AuthContext } from 'context/AuthContext'
import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { NoResults } from 'ui/atoms/Table'
import wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import Loader from 'ui/molecules/Loader'

import ConfirmDialog from './Fragments/ConfirmDialog'
import InfraestructureCard from './Fragments/InfraestructureCard'
import RequestList from './Fragments/RequestList'
import { CardContainer } from './styles'

const Infraestructure = () => {
  const { translate } = useContext(GlobalizationContext)
  const { userData } = useContext(AuthContext)
  const [requestList, setRequestList] = useState(null)
  const [hasInstitutionRequest, setHasInstitutionRequest] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)

  const redirectToNewLab = () => {
    history.push(generatePrivatePath('/register-laboratory'))
  }

  const redirectToInstitutionRegister = () => {
    history.push(generatePrivatePath('/register-new-institution'))
  }

  const getInfo = useCallback(async () => {
    const requests = await InfraestrucutureService.getRequestList()

    const hasRequestsForInstitution = requests.some(
      request =>
        request.type === 'INSTITUTION' && request.situation !== 'REJECTED'
    )

    setHasInstitutionRequest(hasRequestsForInstitution)
    setRequestList(requests)
  }, [])

  useEffect(() => {
    getInfo()
  }, [getInfo])

  const shouldDisableNewInstitution = () => {
    return (
      userData.institutionRole === 'ROLE_INSTITUICAO_TOTAL' ||
      userData.institutionRole === 'ROLE_INSTITUICAO_PARCIAL' ||
      userData.institutionRole === 'ROLE_INSTITUICAO_LIMITADO' ||
      hasInstitutionRequest
    )
  }

  const renderNewLabText = () => {
    return (
      <Trans
        parent={Typography}
        t={translate}
        // @ts-ignore
        variant={'body2'}
        i18nKey={'NEW_LABORATORY_TEXT'}
        components={{
          LinkInvite: (
            <MuiLink
              component={Link}
              to={generatePrivatePath('/advertise-platform')}
            />
          )
        }}
      />
    )
  }

  const renderNewInstitutionText = () => {
    return (
      <>
        <Typography variant="body2">
          {translate('NEW_INSTITUTION_TEXT')}
        </Typography>
        <Typography variant="body2" style={{ marginTop: '16px' }}>
          {translate('NEW_INSTITUTION_TEXT_DISCLAIMER')}
        </Typography>
      </>
    )
  }

  const disabledText = hasInstitutionRequest
    ? 'HAS_INSTITUTION_REQUEST'
    : 'ALREADY_IN_INSTITUTION'

  return (
    <>
      <Loader />
      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />
      <Typography variant="body2">{translate('INTRO_TEXT')}</Typography>

      <ConfirmDialog
        open={openDialog}
        handleCloseDialog={() => setOpenDialog(false)}
        handleConfirm={redirectToInstitutionRegister}
      />
      <CardContainer>
        <InfraestructureCard
          buttonText={translate('NEW_INSTITUTION_BUTTON')}
          text={renderNewInstitutionText()}
          title={translate('NEW_INSTITUTION')}
          onClick={() => setOpenDialog(true)}
          disabled={shouldDisableNewInstitution()}
          disabledText={translate(disabledText)}
        />
        <InfraestructureCard
          buttonText={translate('NEW_LABORATORY_BUTTON')}
          text={renderNewLabText()}
          title={translate('NEW_LABORATORY')}
          onClick={redirectToNewLab}
        />
      </CardContainer>
      <Typography variant="subtitle1" style={{ marginBottom: '32px' }}>
        {translate('SOLICITATIONS_LIST')}
      </Typography>
      {requestList?.length === 0 && (
        <NoResults>{translate('NO_RESULTS')}</NoResults>
      )}
      <RequestList list={requestList} updateList={setRequestList} />
    </>
  )
}

export default wrapper(Infraestructure, {
  mapState: null,
  mapDispatch: null,
  namespace: 'INFRAESTRUCTURE'
})
