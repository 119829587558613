import axios from './axios'

export const InstitutionService = {
  getCnpj(cnpj) {
    return axios.get('/institutions/' + cnpj + '/form')
  },

  getInstitutionByTerm(value) {
    return axios.get('/institutions/term', { params: { term: value } })
  },

  getSocialReason(value) {
    return axios.get('/institutions/socialReason', { params: { term: value } })
  },

  deleteInstitutionByCnpj(cnpj) {
    return axios.delete(`/institutions/${cnpj}`)
  },

  getInstitutionData(cnpj) {
    return axios.get('/institutions/' + cnpj + '/form').then(resp => resp.data)
  },

  getInstitutionAnalysis(cnpj) {
    return axios.get(`/institutions/${cnpj}/analysis`).then(resp => resp.data)
  },

  checkIfInstitutionExists(payload) {
    return axios
      .get(`/institutions/exists`, {
        params: {
          cnpj: payload.cnpj,
          socialReason: payload.social_reason,
          initials: payload.initials
        }
      })
      .then(resp => resp.data)
  },

  getInstitution(cnpj) {
    return axios.get(`/assets/institutions/${cnpj}`).then(resp => resp.data)
  },

  getInstitutionLabs(cnpj, pageSize, pageNumber) {
    return axios
      .get(`assets/institution/${cnpj}/laboratories`, {
        params: {
          page: pageNumber,
          size: pageSize
        }
      })
      .then(resp => resp.data)
  }
}

export default InstitutionService
