import styled from 'styled-components'

import Paper from '@material-ui/core/Paper'
import { KeyboardDatePicker as CustomKeyboardDatePicker } from '@material-ui/pickers/DatePicker'

import { primaryColor } from 'helpers/constants'

import TextField from 'ui/atoms/BaseTextField'

export const CustomTextField = styled(TextField)`
  [class^='MuiFormControl-root'] {
    margin: 0;
  }
`

const ModalBody = styled(Paper)`
  .MuiDialogActions-root {
    .MuiButton-root {
      color: #777;
      font-weight: 700;

      &:last-child {
        color: ${primaryColor};
      }
    }
  }
`
export const KeyboardDatePicker = styled(CustomKeyboardDatePicker).attrs({
  DialogProps: {
    PaperComponent: ModalBody
  },
  KeyboardButtonProps: { color: 'primary' }
})`
  [class^='MuiInputBase-root'] {
    padding-right: 4px;
  }

  [class^='MuiInputAdornment-root'] > Button {
    padding: 6px;
  }
`
