import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  NoResults
} from 'ui/atoms/Table'
import Pagination from 'ui/molecules/commons/Pagination'

import Row from '../Row'

const List = ({
  list,
  count,
  classes,
  rowsPerPage,
  rowsPerPageOptions,
  page,
  onChangePage,
  onChangeRowsPerPage,
  emptyList,
  onRowAction: handleRowAction
}) => {
  const { translate } = useContext(GlobalizationContext)

  const showPagination = rowsPerPageOptions[0] < count

  const paginationComponent = showPagination ? (
    <Pagination
      rowsPerPageOptions={rowsPerPageOptions}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  ) : null

  const RowList = list.map(row => {
    return <Row item={row} onRowAction={handleRowAction} key={row.id} />
  })

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={12}>
          {!emptyList ? (
            <>
              {paginationComponent}
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '10px' }}></TableCell>
                      <TableCell>{translate('COLUMNS.LAB_NAME')}</TableCell>
                      <TableCell>{translate('COLUMNS.INSTITUTION')}</TableCell>
                      <TableCell>{translate('COLUMNS.STATUS')}</TableCell>
                      <TableCell>{translate('COLUMNS.STATE')}</TableCell>
                      <TableCell>
                        {translate('COLUMNS.ACTIVE_RESPONSIBLE')}
                      </TableCell>

                      <TableCell style={{ width: '10px' }}>
                        {translate('COMMONS:ACTIONS')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{RowList}</TableBody>
                </Table>
              </TableContainer>
              {paginationComponent}
            </>
          ) : (
            <Grid item style={{ marginTop: '20px' }}>
              <NoResults>{translate('COMMONS:NO_RESULTS')}</NoResults>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default List
