import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => {
  const drawer = {
    background: '#eaeaea',
    width: theme.measures.sideNav.open.width,
    flexShrink: 0,
    transition: 'max-width 0.4s ease-in-out'
  }

  const drawerPaper = {
    top: theme.measures.sideNav.top,
    height: `calc(100vh - ${theme.measures.sideNav.top})`,
    width: theme.measures.sideNav.open.width,
    transition: 'max-width 0.4s ease-in-out'
  }

  return {
    drawerClose: {
      ...drawer,
      maxWidth: theme.measures.sideNav.closed.width
    },
    drawerOpen: {
      ...drawer,
      maxWidth: theme.measures.sideNav.open.width
    },
    drawerPaperClose: {
      ...drawerPaper,
      maxWidth: theme.measures.sideNav.closed.width,
      zIndex: 1
    },
    drawerPaperOpen: {
      ...drawerPaper,
      maxWidth: theme.measures.sideNav.open.width,
      zIndex: 1
    },
    appBar: {
      display: 'block',
      width: '55px',
      height: '55px',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    }
  }
})
