// @ts-nocheck
import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'

import { GridContainer, Text } from '../../styles'
import InfoBox from './InfoBox'

const InstitutionData = ({ institutionData, totalEquip, totalLab }) => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <>
      <GridContainer>
        <InfoBox text={translate('TOTAL_LAB')} value={totalLab} />
        <InfoBox text={translate('TOTAL_EQUP')} value={totalEquip} />
      </GridContainer>
      <Title variant="subtitle1" $noBorder style={{ marginTop: '8px' }}>
        {translate('COMMONS:ADDRESS')}
      </Title>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        style={{ paddingBottom: '30px' }}
      >
        <Grid item xs={12}>
          <Text>
            {institutionData?.street} {institutionData?.district} - {''}
            {institutionData?.number}
          </Text>
        </Grid>
        <Grid item xs={12}>
          <Text>
            {institutionData?.city} - {institutionData?.state}
          </Text>
        </Grid>
        <Grid item xs={12}>
          <Text>
            {translate('ADDRESS:ZIP_CODE')}: {institutionData?.zipCode}
          </Text>
        </Grid>
      </Grid>

      <Title variant="subtitle1" $noBorder style={{ marginTop: '32px' }}>
        {translate('COMMONS:CONTACT')}
      </Title>
      <Grid
        container
        item
        xs={12}
        spacing={1}
        style={{ paddingBottom: '30px' }}
      >
        <Text>
          <span style={{ fontWeight: 'bold', marginRight: '8px' }}>
            {translate('COMMONS:PHONE')}
          </span>
          {institutionData?.phoneNumber}
        </Text>
      </Grid>
    </>
  )
}

export default InstitutionData
