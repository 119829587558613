import compact from 'lodash/compact'
import React, { useState, useMemo, useContext } from 'react'
import { conformToMask } from 'react-text-mask'
import styled from 'styled-components'

import { Box } from '@material-ui/core'

import { CPF_MASK } from 'helpers/constants'
import { generatePrivatePath } from 'helpers/history'

import { TableChip } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Marker, MarkerGreen, MarkerOrange, MarkerRed } from 'ui/atoms/Marker'
import { TableRow, TableCell } from 'ui/atoms/Table'
import TableActions from 'ui/atoms/TableActions'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'

import { ADMIN_ROUTES } from 'views/BackOffice/Subviews/Admin/constants/routes'

import { userListUseCase } from '../../../providers'
import DeleteUserModal from '../../DeleteUser'

const markerColors = {
  ACTIVE: MarkerGreen,
  PENDING: MarkerOrange,
  INACTIVE: MarkerRed
}

const LabMarker = styled(Marker)`
  ${props => markerColors[props.type]}
`

const StatusCell = styled(TableCell)`
  max-width: 90px;
  font-weight: 600 !important;
`

const USER_ROW_ACTIONS = [
  'MANAGE',
  'RESEND',
  'ACTIVATE',
  'INACTIVATE',
  'DELETE'
]

// Refact this component
function Row(props) {
  const { item: user, onRowAction: handleRowAction, actions } = props
  const { translate } = useContext(GlobalizationContext)
  const [modal, setModal] = useState(false)
  const [confirm, setConfirm] = useState(null)

  const redirectUrl = useMemo(
    () =>
      generatePrivatePath(ADMIN_ROUTES.USERS_EDIT, {
        pathParams: { userId: user.id_user }
      }),
    [user]
  )

  const rowActions = useMemo(() => {
    const actionList = USER_ROW_ACTIONS.filter(
      action =>
        compact([
          user.situation === 'INACTIVE' ? null : 'ACTIVATE',
          user.situation === 'ACTIVE' ? null : 'INACTIVATE'
        ]).indexOf(action) < 0
    )

    return actionList.map(actionItem => {
      const link = actionItem === 'MANAGE' ? redirectUrl : undefined

      return {
        code: actionItem,
        name: `ACTIONS.${actionItem}`,
        link
      }
    })
  }, [actions, user])

  const onRowAction = action => executeActions[action.toLowerCase()]()

  const handleResendPassword = async () => {
    try {
      await userListUseCase.resendPassword(user.id_user)
    } catch (error) {
      console.log(error)
    }
    handleRowAction()
  }

  const hasRequestPending = async () => {
    return await userListUseCase.hasRequestPending(user.id_user)
  }

  const setUserSituation = async (userId, situation) => {
    await userListUseCase.changeSituation(userId, situation)
    handleRowAction()
    handleCloseModal()
  }

  const handleSwitchUserActivation = async () => {
    try {
      const userId = user.id_user
      const newSituation = user.situation === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
      const needsConfirmation = await hasRequestPending(user.id_user)

      if (needsConfirmation) {
        setConfirm({
          actionType: 'INACTIVATE',
          action: async () => {
            await setUserSituation(userId, newSituation)
          }
        })
      } else {
        await setUserSituation(userId, newSituation)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleModalExcludeUser = () => setModal(true)

  const excluseUser = async formData => {
    await userListUseCase.deleteUser(user.id_user, formData)
    handleRowAction()
    handleCloseModal()
  }

  const handleExcludeUser = async formData => {
    try {
      const needsConfirmation = await hasRequestPending(user.id_user)
      if (needsConfirmation) {
        setConfirm({
          actionType: 'EXCLUDE',
          action: async () => {
            await excluseUser(formData)
          }
        })
      } else {
        excluseUser(formData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseModal = () => {
    setModal(false)
    setConfirm(null)
  }

  const executeActions = {
    // manage: handleGoToEditUser,
    resend: handleResendPassword,
    activate: handleSwitchUserActivation,
    inactivate: handleSwitchUserActivation,
    delete: handleModalExcludeUser
  }

  const roleChip = useMemo(() => {
    if (!user.hasRoleAdmin && !user.hasRoleGestorAcoes) return

    const roles = []

    if (user.hasRoleAdmin) {
      roles.push(translate('ROLE.ADMIN'))
    }

    if (user.hasRoleGestorAcoes) {
      roles.push(translate('ROLE.GESTOR_ACOES'))
    }

    const roleText = roles.join(' / ')

    return (
      <Box ml={2}>
        <TableChip $primary size="small" label={roleText} />
      </Box>
    )
  }, [translate, user])

  return (
    <>
      <TableRow>
        <TableCell>
          <LabMarker type={user.situation} />
        </TableCell>
        <TableCell>
          <Box display="flex">
            <Box
              display="flex"
              alignItems="center"
            >{`${user.first_name} ${user.last_name}`}</Box>
            {roleChip}
          </Box>
        </TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>
          {conformToMask(user.cpf || '', CPF_MASK).conformedValue}
        </TableCell>
        <StatusCell type={user.situation}>
          {user.situation
            ? translate(`USER:SITUATION.${user.situation}`)
            : null}
        </StatusCell>
        <TableCell style={{ width: '10px' }}>
          <TableActions
            actionList={rowActions}
            handleActionSelected={onRowAction}
            situation={user.situation}
          />
          <DeleteUserModal
            open={modal}
            onCloseDialog={handleCloseModal}
            onDialogAction={handleExcludeUser}
            user={user}
          />
          <DialogConfirm
            open={confirm}
            onConfirm={confirm?.action}
            onCancel={() => setConfirm(null)}
          >
            {confirm
              ? translate('CONFIRM_ACTION', {
                  action: translate(`CONFIRM_ACTIONS.${confirm?.actionType}`)
                })
              : null}
          </DialogConfirm>
        </TableCell>
      </TableRow>
    </>
  )
}
export default Row
