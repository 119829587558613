import React, { useContext, useState } from 'react'
import { useWatch } from 'react-hook-form'

import Grid from '@material-ui/core/Grid'

import DatePicker from 'ui/atoms/BaseDatePicker'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import ProfilePicture from './Fragments/ProfilePicture'
import { ProfilePictureContainer, PersonalData } from './styles'

const ProfileData = ({
  formMethods,
  profilePicture,
  onProfilePictureChange
}) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const { errors, control } = formMethods

  const firstName = useWatch({ control, defaultValue: '', name: 'firstName' })
  const firstLetter = firstName[0]?.toUpperCase() || ''

  return (
    <>
      <h3 className={classes.h3}>{translate('PROFILE_DATA_TITLE')}</h3>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <ProfilePictureContainer item xs={12} md>
          <ProfilePicture
            defaultValue={firstLetter}
            src={profilePicture}
            onPictureSelected={onProfilePictureChange}
          />
        </ProfilePictureContainer>
        <PersonalData item xs={12} md>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <BaseTextField
                required={true}
                label={translate('NAME')}
                placeholder={translate('COMMONS:TYPE')}
                errors={errors}
                control={control}
                name="firstName"
                maxLength={255}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseTextField
                required={true}
                label={translate('LAST_NAME')}
                placeholder={translate('COMMONS:TYPE')}
                errors={errors}
                control={control}
                name="lastName"
                maxLength={255}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <BaseTextField
                required={true}
                label={translate('CPF')}
                placeholder={translate('COMMONS:TYPE')}
                errors={errors}
                control={control}
                name="cpf_mask"
                disabled
              />
            </Grid>

            <Grid hidden>
              <BaseTextField
                required={true}
                placeholder={translate('COMMONS:TYPE')}
                errors={errors}
                control={control}
                name="cpf"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseTextField
                required={true}
                label={translate('NATIONALITY')}
                placeholder={translate('COMMONS:TYPE')}
                errors={errors}
                control={control}
                name="nationality"
                maxLength={255}
              />
            </Grid>
       
          </Grid>
        </PersonalData>
      </Grid>
    </>
  )
}

export default ProfileData
