import { AdminService, UsersService } from 'api'

import { notificationUseCases } from 'views/Notification/providers'

import UserListUseCase from './userList.useCases'

export const userListUseCase = new UserListUseCase({
  AdminService,
  UsersService,
  notificationUseCases
})
