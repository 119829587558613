import React, { useContext, useMemo } from 'react'

import { Button, Grid, Typography } from '@material-ui/core'

import { ChipContainer } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import FilterChip, { filterFieldTypeList, hasAnyFilter } from '../FilterChip'

const FilterChipList = ({ listLabel, filter, onFilterChanged, onClearAll }) => {
  const { translate } = useContext(GlobalizationContext)

  const hasFilter = useMemo(() => hasAnyFilter(filter), [filter])

  return hasFilter ? (
    <Grid item xs={12}>
      <ChipContainer>
        {listLabel ? <Typography>{translate(listLabel)}</Typography> : null}
        {filterFieldTypeList.map(field => (
          <FilterChip
            key={field}
            field={field}
            filter={filter}
            onClearFilter={onFilterChanged}
          />
        ))}
        {onClearAll ? (
          <Button variant="text" color="primary" onClick={onClearAll}>
            {translate('CLEAR_ALL')}
          </Button>
        ) : null}
      </ChipContainer>
    </Grid>
  ) : null
}

export default FilterChipList
