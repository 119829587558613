import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import ContentContainer from 'ui/atoms/ContentContainer'
import { useGlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'

import InstitutionResponsible from './Fragments/InstitutionResponsible'
import LabResponsible from './Fragments/LabResponsible'
import RequestSharing from './Fragments/RequestSharing'
import { Text, TextBox, TextContainer, TextSubTitle } from './style'

function Register() {
  const { translate } = useGlobalizationContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <ContentContainer>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Title item xs={12} $paddingTop>
          {translate('TITLE')}
        </Title>

        <TextContainer item xs={12} style={{ marginTop: '8px' }}>
          <TextSubTitle>{translate('SUBTITLE_1')}</TextSubTitle>
          <TextBox>
            <Trans parent={Text} t={translate} i18nKey="CONTENT_1" />
          </TextBox>
        </TextContainer>
        <TextContainer item xs={12}>
          <TextSubTitle>{translate('SUBTITLE_2')}</TextSubTitle>
          <TextBox>
            <Trans parent={Text} t={translate} i18nKey="CONTENT_2" />
          </TextBox>
        </TextContainer>
        <TextContainer item xs={12}>
          <TextSubTitle>{translate('SUBTITLE_3')}</TextSubTitle>
          <TextBox>
            <Trans
              parent={Text}
              t={translate}
              i18nKey="CONTENT_3"
              components={{ Link: <Link to="/register/user" /> }}
            />
          </TextBox>
        </TextContainer>
        <TextContainer item xs={12}>
          <TextSubTitle>{translate('SUBTITLE_4')}</TextSubTitle>
          <TextBox>
            <Trans parent={Text} t={translate} i18nKey="CONTENT_4" />
          </TextBox>
        </TextContainer>
      </Grid>

      <RequestSharing />
      <LabResponsible />
      <InstitutionResponsible />
    </ContentContainer>
  )
}

export default wrapper(Register, { namespace: 'PUBLIC_AREA_REGISTER' })
