import { startOfDay, isAfter } from 'date-fns'
import * as Yup from 'yup'

Yup.addMethod(Yup.date, 'greaterThan', function (fieldName) {
  return this.when(fieldName, (fieldValue, schema) => {
    return fieldValue && !isNaN(fieldValue.valueOf())
      ? schema.test({
          name: 'min',
          message: 'VALIDATION:GREATER_OR_EQUAL_THAN_INITIAL_ERROR',
          test: value => {
            const dateIsAfter = isAfter(
              startOfDay(fieldValue),
              startOfDay(value)
            )
            return !value || !dateIsAfter
          }
        })
      : schema
  })
})
