import { models } from 'powerbi-client'
import { PowerBIEmbed, EmbedType } from 'powerbi-client-react'
import React, { useCallback, useContext, useState } from 'react'
import styled from 'styled-components'

import { CampaignService } from 'api'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'

const EmbedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .report-style-class {
    flex: 1;

    iframe {
      border: none;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CovidCampaign = () => {
  const { translate } = useContext(GlobalizationContext)
  const [embedRef, setEmbedRef] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const reportConfigPromise = useCallback(async () => {
    return await CampaignService.getCovidReport().then(resp => resp.data)
  }, [])

  return (
    <ContentWrapper>
      <Title $paddingTop>{translate('TITLE')}</Title>

      <AsyncLoad promiseFn={reportConfigPromise} minHeight={150}>
        {({ id, embedUrl, accessToken }) => (
          <EmbedWrapper>
            <PowerBIEmbed
              embedConfig={{
                type: EmbedType.Report,
                id,
                embedUrl,
                accessToken,

                tokenType: models.TokenType.Aad,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false
                    }
                  },
                  background: models.BackgroundType.Transparent
                }
              }}
              eventHandlers={
                new Map([
                  [
                    'loaded',
                    function () {
                      // console.log('Report loaded')
                      setLoaded(true)
                    }
                  ],
                  [
                    'rendered',
                    function () {
                      // console.log('Report rendered')
                    }
                  ],
                  [
                    'error',
                    function (event) {
                      console.log(event.detail)
                    }
                  ]
                ])
              }
              cssClassName={'report-style-class'}
              getEmbeddedComponent={embeddedReport => {
                setEmbedRef(embeddedReport)
              }}
            />
          </EmbedWrapper>
        )}
      </AsyncLoad>
    </ContentWrapper>
  )
}

export default wrapper(CovidCampaign, {
  namespace: 'COVID_CAMPAIN'
})
