import styled from 'styled-components'

import Box from '@material-ui/core/Box'

export const WrapHeader = styled.header`
  box-shadow: 5px 5px 5px rgba(51, 51, 51, 0.15);
  position: relative;
  z-index: 2;
  background: #fff;
  > div {
    padding: 0 30px !important;
    position: relative;

    @media screen and (max-width: 640px) {
      padding: 0 15px !important;
    }
  }
`

export const BoxFlex = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const WrapBox = styled(BoxFlex)`
  align-items: center;
`

export const WrapButtons = styled(BoxFlex)`
  margin: 19px 0;
`

export const WrapLogo = styled(Box)`
  margin-right: 15px;

  @media screen and (max-width: 640px) {
    margin-right: 10px;
  }
`
