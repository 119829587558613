import React, { useEffect, useState, useContext } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import MapGL, { Marker, NavigationControl } from 'react-map-gl'
import { useParams } from 'react-router'

import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import {
  AutoSuggestAreaExpertise,
  AutoSuggestTechniqueService,
  LaboratoryService
} from 'api'
import MapService from 'api/MapService'
import { AuthContext } from 'context/AuthContext'
import { fileUploadConfig } from 'helpers/constants'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { phone } from 'helpers/generalInputMasks'
import { history, generatePrivatePath } from 'helpers/history'
import { cpfMask } from 'helpers/maskedCPF'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import AddedDocuments, { previewFile } from 'ui/atoms/AddedDocuments'
import BaseMultipleSelect from 'ui/atoms/BaseMultipleSelect'
import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import BaseTextField from 'ui/atoms/BaseTextField'
import DocumentUploader, {
  uploadFilesInSequence,
  deleteFilesInSequence
} from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RequestOpinion from 'ui/atoms/RequestOpinion'
import RequestOpinionAdjustment from 'ui/atoms/RequestOpinionAdjustment'
import Address from 'ui/molecules/Address'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import AutoCompleteChipInput from 'ui/molecules/commons/AutoCompleteChipInput'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import Line from 'assets/icons/line.svg'

import ComparsionTable from './Fragments/ComparisonTable'
import LaboratoryInstitution from './Fragments/LaboratoryInstitution'
import {
  hasCustomType,
  LaboratorySchema
} from './Fragments/LaboratoryValidationSchema'
import Responsible from './Fragments/Responsible'
import TermsOfUse from './Fragments/TermsOfUse'

const checkAlreadyExists = ({ initials, institutionCnpj }) => {
  if (!initials) return false

  return new Promise((resolve, reject) => {
    LaboratoryService.verifyLaboratory({
      initials,
      cnpj: institutionCnpj
    })
      .then(() => {
        reject({ messageKey: 'ALREADY_EXISTS' })
      })
      .catch(error => {
        if (error?.response?.data?.message == 'laboratory.waiting.approval') {
          reject({ messageKey: 'AWAITING_VALIDATION' })
        } else if (error?.response?.status === 404) {
          resolve(true)
        } else {
          reject({ messageKey: 'ALREADY_EXISTS' })
        }
      })
  })
}

function RegisterLaboratory({ readOnly = false, hideHeaders = false }) {
  const { action: routeAction, laboratoryId, isPublished } = useParams()

  const { userData } = useContext(AuthContext)
  const { translate } = useContext(GlobalizationContext)

  const [pageTitle, setPageTitle] = useState('')
  const [creationDate, setCreationDate] = useState(null)
  const [changelogList, setChangelogList] = useState([])
  const [laboratoryTypeList, setLaboratoryTypeList] = useState([])

  const [formState, setFormState] = useState({})
  const [fieldDisabled, setFieldDisabled] = useState({})
  const [documents, setDocuments] = useState([])
  const [deletedDocuments, setDeletedDocuments] = useState([])
  const [refusalDocuments, setRefusalDocuments] = useState([])
  const [representativeList, setRepresentativeList] = useState([])
  const [selectedInstitution, setSelectedInstitution] = useState(null)

  const [alreadyExistsInitialValues, setAlreadyExistsInitialValues] = useState({
    initials: '',
    name: ''
  })

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)

  const [needApprove, setNeedApprove] = useState(false)
  const [openConfirmDialogEditing, setOpenConfirmDialogEditing] =
    React.useState(false)

  const resolver = useYupValidationResolver(LaboratorySchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    criteriaMode: 'firstError'
  })

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    control,
    errors,
    reset
  } = formMethods

  const [viewport, setViewPort] = useState({
    width: '100%',
    height: '400px',
    latitude: -15.7669729,
    longitude: -47.8715076,
    zoom: 14
  })

  const [marker, setMarker] = useState({
    latitude: 0,
    longitude: 0
  })

  const selectedLaboratotyTypes = watch('typeLaboratoryList')
  const responsiblePhone = useWatch({ control, name: 'responsiblePhone' })

  useEffect(() => {
    if (responsiblePhone) {
      setValue('responsiblePhone', phone(responsiblePhone))
    }
  }, [responsiblePhone, setValue])

  useEffect(() => {
    let newFormState = 'REGISTER'
    if (!readOnly) {
      if (routeAction && laboratoryId) {
        switch (routeAction) {
          case 'approve-edit':
            newFormState = 'APPROVING_EDIT'
            break
          case 'advanced-edit':
            newFormState = 'EDITING'
            break
          case 'approve':
            newFormState = 'APPROVING'
            break
          default:
            newFormState = 'READONLY'
            break
        }
      }
    } else {
      newFormState = 'READONLY'
    }

    setFormState(newFormState)

    if (newFormState == 'APPROVING_EDIT') {
      LaboratoryService.getChangelog(laboratoryId).then(response => {
        setChangelogList(response.data)
      })
    }
  }, [routeAction, laboratoryId, readOnly])

  useEffect(() => {
    titlePage()
  }, [formState])

  useEffect(() => {
    if (!userData) return
    initData()
  }, [userData])

  useEffect(() => {
    const isNotRegistering = formState !== 'REGISTER'
    const isApproving =
      formState === 'APPROVING' || formState === 'APPROVING_EDIT'
    const isReadOnly = formState === 'READONLY'
    const allowDragMap =
      formState === 'REGISTER' || formState === 'APPROVING_EDIT'

    setFieldDisabled({
      institutionCnpj: isNotRegistering,
      socialReason: isNotRegistering,
      typeLaboratoryList: isApproving || isReadOnly,
      laboratoryCustomType: isApproving || isReadOnly,
      initials: isApproving || isReadOnly,
      name: isApproving || isReadOnly,
      about: isApproving || isReadOnly,
      techniqueList: isApproving || isReadOnly,
      areasExpertiseList: isApproving || isReadOnly,
      address: isApproving || isReadOnly,
      responsiblePhone: isApproving || isReadOnly,
      responsibleLattes: isApproving || isReadOnly,
      responsibleCpf: !isNotRegistering || isApproving || isReadOnly,
      responsibleFirstName: !isNotRegistering || isApproving || isReadOnly,
      termsOfUse: isNotRegistering,
      allowDragMap
    })
  }, [formState])

  const initData = async () => {
    const typeList = await LaboratoryService.getTypeLaboratoryList()
    setLaboratoryTypeList(typeList)

    if (laboratoryId) {
      try {
        const {
          responsible,
          institution,
          addressType,
          website,
          contactEmail,
          representativeList,
          typeLaboratoryList: currTypeLaboratoryList,
          documentList: currDocumentList,
          documentRefuseList: currRefusalDocumentList,
          complements,
          ...formData
        } = await LaboratoryService.getById(laboratoryId, isPublished).then(
          resp => {
            const { representativeList, laboratory, complements } = resp.data
            const responsible = complements ? complements.responsible : {}

            return {
              representativeList,
              responsible,
              ...laboratory,
              complements
            }
          }
        )

        const selectedIds = currTypeLaboratoryList.map(
          type => type.id_type_laboratory
        )
        const selected = typeList.filter(type => {
          return selectedIds.indexOf(type.id_type_laboratory) >= 0
        })

        const newMarker = {
          latitude: +formData.latitude,
          longitude: +formData.longitude
        }

        setViewPort({
          ...viewport,
          ...newMarker
        })
        setMarker(newMarker)

        setDocuments(currDocumentList || [])
        setRefusalDocuments(currRefusalDocumentList || [])

        setAlreadyExistsInitialValues({
          initials: formData.initials,
          name: formData.name
        })
        setRepresentativeList(representativeList)
        setCreationDate(complements?.createdDate)

        setSelectedInstitution({
          cnpj: institution.cnpj,
          initials: institution.institutionInitials,
          social_reason: institution.socialReason
        })
        reset({
          ...formData,
          addressType: addressType,
          typeLaboratoryList: selected,
          responsibleId: responsible.id,
          responsibleCpf: responsible.cpf || '',
          responsibleFirstName: responsible.firstName,
          responsibleLastName: responsible.lastName,
          responsibleEmail: responsible.email,
          responsibleLattes: responsible.link_lattes || '',
          responsiblePhone: responsible.telephone || '',
          website: website,
          contactEmail: contactEmail,
          termsOfUse: true
        })
      } catch (error) {
        if (error.response?.status === 403) {
          notificationUseCases.newError(
            'Voce não tem permissão para visualizar este laboratório'
          )
          history.push(generatePrivatePath('/laboratory'))
        }
      }
    } else {
      const responsibleData = {
        id: userData.userId,
        cpf: userData.cognitoUsername,
        name: `${userData.firstName} ${userData.lastName}`,
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        telephone: userData.telephone
      }

      setRepresentativeList([responsibleData])
      reset({
        responsibleId: responsibleData.id,
        responsibleCpf: responsibleData.cpf,
        responsibleFirstName: responsibleData.firstName,
        responsibleLastName: responsibleData.lastName,
        responsiblePhone: responsibleData.telephone,
        responsibleEmail: responsibleData.email
      })
    }
  }

  const triggerSubmit = () => {
    handleSubmit(onSubmit)()
  }

  const onSubmit = async formData => {
    setOpenConfirmDialogEditing(false)
    try {
      let returnStatus = false
      if (['REGISTER', 'EDITING'].includes(formState)) {
        returnStatus = await onSubmitRequest(formData)
      } else if (formState == 'APPROVING') {
        returnStatus = await onSubmitApproval(formData)
      } else if (formState == 'APPROVING_EDIT') {
        returnStatus = await onSubmitEditApproval(formData)
      }

      if (returnStatus) {
        window.history.back()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmitApproval = async formData => {
    let submitData = {
      laboratoryPartOfInstitution: JSON.parse(
        formData.requestOpinionUnitQuestion
      ),
      requesterPartOfLaboratory: JSON.parse(
        formData.requestOpinionResearcherQuestion
      ),
      idReasonRefuseLaboratory: formData.requestOpinionRefusalReasons,
      refusalDetail: formData.requestOpinionComplement
    }

    try {
      if (
        submitData.laboratoryPartOfInstitution &&
        submitData.requesterPartOfLaboratory
      ) {
        await LaboratoryService.postApproval(laboratoryId)
        notificationUseCases.newSuccess(
          translate('REGISTER_LABORATORY_APPROVAL')
        )
      } else {
        await LaboratoryService.postRefusal(laboratoryId, submitData)
        await uploadFilesInSequence(
          LaboratoryService.uploadRefusalDocumentList,
          laboratoryId,
          refusalDocuments || []
        )
        notificationUseCases.newSuccess(
          translate('REGISTER_LABORATORY_REFUSED')
        )
      }

      return true
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message == 'input.invalid'
      ) {
        notificationUseCases.newError('SPECIAL_CHARS')
      } else {
        notificationUseCases.newError()
      }
    }
  }

  const onSubmitEditApproval = async formData => {
    let submitData = {
      refusalDetail: formData.requestAdjustmentOpinionComplement
    }

    try {
      if (!submitData.refusalDetail) {
        await LaboratoryService.postEditApproval(laboratoryId)
        notificationUseCases.newSuccess(
          translate('REGISTER_LABORATORY_APPROVAL')
        )
      } else {
        await LaboratoryService.postRefusedEdit(laboratoryId, submitData)
        notificationUseCases.newSuccess(
          translate('REGISTER_LABORATORY_REFUSED')
        )
      }

      return true
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.message == 'input.invalid'
      ) {
        notificationUseCases.newError('SPECIAL_CHARS')
      } else {
        notificationUseCases.newError()
      }
    }
  }

  const onSubmitRequest = async formData => {
    const hasInvalidInitials = await validateCheckAlreadyExists({
      initials: formData.initials,
      institutionCnpj: selectedInstitution.cnpj
    })
    if (hasInvalidInitials) return false

    let submitData = {
      id: laboratoryId || undefined,
      institution: selectedInstitution.cnpj,

      initials: formData.initials,
      socialReason: selectedInstitution.socialReason,
      name: formData.name,
      about: formData.about,

      zipCode: formData.zipCode,
      addressType: formData.addressType,
      region: formData.region,
      state: formData.state,
      district: formData.district,
      city: formData.city,
      street: formData.street,
      number: formData.number,
      complement: formData.complement,

      latitude: marker.latitude,
      longitude: marker.longitude,

      responsible: {
        id: formData.responsibleId,
        cpf: formData.responsibleCpf.replace(/[.-]/g, ''),
        firstName: formData.responsibleFirstName,
        lastName: formData.responsibleLastName,
        email: formData.responsibleEmail,
        link_lattes: formData.responsibleLattes,
        telephone: formData.responsiblePhone.replace(/[^\d]+/g, '')
      },

      areasExpertiseList: formData.areasExpertiseList,
      techniqueList: formData.techniqueList,
      typeLaboratoryList: formData.typeLaboratoryList,
      laboratoryCustomType: formData.laboratoryCustomType,
      website: formData.website,
      contactEmail: formData.contactEmail,

      termsOfUse: formData.termsOfUse
    }

    try {
      let newLaboratory

      if (!submitData.id) {
        newLaboratory = await LaboratoryService.postLaboratory(submitData)
        notificationUseCases.newSuccess(
          translate('REGISTER_LABORATORY_SUCCESS')
        )
      } else {
        newLaboratory = await LaboratoryService.putLaboratory(
          submitData.id,
          submitData
        )
        notificationUseCases.newSuccess(
          translate('REGISTER_LABORATORY_UPDATED')
        )
      }

      await deleteFilesInSequence(
        LaboratoryService.removeRepresentativeDocument,
        deletedDocuments || []
      )
      await uploadFilesInSequence(
        LaboratoryService.uploadRepresentativeDocument,
        newLaboratory.laboratory.laboratory,
        documents || []
      )

      return true
    } catch (error) {
      if (
        error?.response?.status === 400 &&
        error?.response?.data?.message == 'input.invalid'
      ) {
        notificationUseCases.newError('SPECIAL_CHARS')
      } else if (
        error?.response?.status === 406 &&
        error?.response?.data?.message === 'invalid.laboratory.situation'
      ) {
        notificationUseCases.newError('INVALID_LABORATORY_SITUATION')
      } else {
        notificationUseCases.newError()
      }
    }
  }

  const searchOnMap = objAddress => {
    if (!objAddress.city) return

    const addressSearch = [
      `${objAddress.street} ${objAddress.number}`,
      objAddress.city,
      objAddress.zipCode
    ].join(', ')

    MapService.geoCoding('mapbox.places', addressSearch, {
      autocomplete: false,
      country: 'br',
      types: 'address',
      language: 'pt'
    }).then(response => {
      if (response.data && response.data.features[0]) {
        const objTemp = {
          latitude: response.data.features[0].center[1],
          longitude: response.data.features[0].center[0]
        }
        const objNewViewPort = {
          ...viewport,
          latitude: response.data.features[0].center[1],
          longitude: response.data.features[0].center[0]
        }

        setViewPort(objNewViewPort)
        setMarker(objTemp)
      }
    })
  }

  const onMarkerDrag = data => {
    const objTemp = {
      latitude: data.lngLat[1],
      longitude: data.lngLat[0]
    }
    setMarker(objTemp)
  }

  const techniqueSuggestionGetter = ({ value }) => {
    return AutoSuggestTechniqueService.getSuggest(value).then(resp => resp.data)
  }

  const areaExpertiseSuggestionGetter = ({ value }) => {
    return AutoSuggestAreaExpertise.getSuggest(value).then(resp => resp.data)
  }

  const handleOnFileSelected = fileList => {
    setNeedApprove(true)
    if (!fileList || !fileList.length) return
    setDocuments(documents => documents.concat(fileList))
  }

  const handleOnRefusalFileSelected = fileList => {
    if (!fileList || !fileList.length) return

    setRefusalDocuments(documents => documents.concat(fileList))
  }

  const handleOnRemoveFile = file => {
    setNeedApprove(true)
    if (file.id) {
      setDeletedDocuments(deletedDocuments.concat(file.id))
    }

    const remaningFiles = documents.filter(item => item !== file)
    setDocuments(remaningFiles)
  }

  const handleOnRemoveRefusalFile = file => {
    setRefusalDocuments(currState => {
      return currState.filter(currFile => currFile !== file)
    })
  }

  const handleConfirmDialog = () => {
    setOpenConfirmDialog(false)
    if (formState === 'EDITING') {
      history.push(generatePrivatePath(`/laboratory/${laboratoryId}`))
    } else {
      history.push(generatePrivatePath('/laboratory'))
    }
  }

  const alreadyExistsMessages = {
    initials: {
      ALREADY_EXISTS: 'INITIALS_ALREADY_TAKEN',
      AWAITING_VALIDATION: 'INITIALS_AWAITING'
    },
    name: {
      ALREADY_EXISTS: 'NAME_ALREADY_TAKEN',
      AWAITING_VALIDATION: 'NAME_AWAITING_VALIDATION'
    }
  }
  const validateCheckAlreadyExists = async ({ initials, institutionCnpj }) => {
    if (!initials || ['APPROVING', 'READONLY'].indexOf(formState) >= 0) return
    if (
      ['EDITING'].indexOf(formState) >= 0 &&
      alreadyExistsInitialValues['initials'] === initials
    )
      return

    try {
      await checkAlreadyExists({ initials, institutionCnpj })
      return false
    } catch (error) {
      setError('initials', {
        type: 'fieldValueAlreadyTaken',
        message: translate(alreadyExistsMessages['initials'][error.messageKey]),
        shouldFocus: true
      })
      return true
    }
  }

  const saveButtonText = {
    REGISTER: 'REGISTER_LABORATORY_SAVE_REGISTER',
    EDITING: 'REGISTER_LABORATORY_SAVE_EDITING',
    APPROVING: 'REGISTER_LABORATORY_SAVE_APPROVING',
    APPROVING_EDIT: 'REGISTER_LABORATORY_SAVE_APPROVING_EDIT'
  }

  const classes = useStyles()

  const onResponsibleSelected = () => {
    setNeedApprove(true)
  }

  const editSubmit = needApprove ? (
    <Button
      name="LABORATORY_SAVE"
      fullWidth
      type={'button'}
      variant="contained"
      className={classes.baseButton}
      onClick={() => setOpenConfirmDialogEditing(true)}
    >
      {translate(saveButtonText[formState])}
    </Button>
  ) : (
    <Button
      fullWidth
      type={'button'}
      variant="contained"
      className={classes.baseButton}
      onClick={triggerSubmit}
    >
      {translate(saveButtonText[formState])}
    </Button>
  )

  const titlePage = () => {
    if (formState === 'APPROVING') {
      setPageTitle('REGISTER_LABORATORY_APPROVE_REQUEST')
    } else if (formState === 'EDITING') {
      setPageTitle('EDIT_LABORATORY_TITLE')
    } else {
      setPageTitle('REGISTER_LABORATORY_INCLUSION_REQUEST')
    }
  }

  return (
    <>
      <Loader />
      {!hideHeaders && (
        <BaseContainerHeader
          label={translate(pageTitle)}
          showCloseButton={false}
          hint="REGISTER_LABORATORY_HINT"
        />
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        {...disableSubmitOnEnter}
      >
        <input
          type="hidden"
          name="isApproving"
          ref={register}
          value={formState === 'APPROVING'}
        />
        <input
          type="hidden"
          name="isApprovingEdit"
          ref={register}
          value={formState === 'APPROVING_EDIT'}
        />
        {formState !== 'REGISTER' && (
          <input
            type="hidden"
            name="socialReason"
            value={selectedInstitution?.social_reason}
            ref={register}
          />
        )}

        <LaboratoryInstitution
          classes={classes}
          formMethods={formMethods}
          formState={formState}
          fieldDisabled={fieldDisabled}
          selectedInstitution={selectedInstitution}
          setSelectedInstitution={setSelectedInstitution}
        />
        <div className={classes.defaultContainer}>
          <h3 className={classes.h3} style={{ marginTop: '32px' }}>
            {translate('REGISTER_LABORATORY_DATA')}
          </h3>
          {creationDate && (
            <Typography
              variant="body2"
              noWrap
              style={{ marginBottom: '24px', fontWeight: 600 }}
            >
              {`${translate('COMMONS:CREATION_DATE')}: ${creationDate}`}
            </Typography>
          )}
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} md={12}>
              <BaseMultipleSelect
                disabled={fieldDisabled['typeLaboratoryList']}
                required={true}
                label={translate('REGISTER_LABORATORY_ACTIVITIES')}
                placeholder={translate('COMMONS_SELECT')}
                errors={errors}
                idKey="id_type_laboratory"
                nameKey="description"
                options={laboratoryTypeList}
                control={control}
                name="typeLaboratoryList"
              />
            </Grid>
          </Grid>

          {hasCustomType(selectedLaboratotyTypes) ? (
            <Grid container spacing={3} style={{ marginBottom: '20px' }}>
              <Grid item xs={12} md={12}>
                <BaseTextField
                  disabled={fieldDisabled['laboratoryCustomType']}
                  required={true}
                  label={translate('REGISTER_LABORATORY_OTHER_TYPE')}
                  errors={errors}
                  control={control}
                  name="laboratoryCustomType"
                />
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} md={3}>
              <BaseTextField
                disabled={fieldDisabled['initials']}
                required={true}
                label={translate('REGISTER_LABORATORY_INITIALS')}
                placeholder={translate('REGISTER_LABORATORY_INITIALS')}
                errors={errors}
                control={control}
                maxLength={20}
                name="initials"
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <BaseTextField
                disabled={fieldDisabled['name']}
                required={true}
                label={translate('REGISTER_LABORATORY_LAB_NAME')}
                placeholder={translate('REGISTER_LABORATORY_LAB_NAME')}
                errors={errors}
                control={control}
                name="name"
                maxLength={255}
                handleChange={() => setNeedApprove(true)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} md={12}>
              <BaseTextareaField
                name="about"
                errors={errors}
                control={control}
                required={true}
                maxLength={500}
                rowsMin={3}
                disabled={fieldDisabled['about']}
                label={translate('EDIT_LABORATORY_ABOUT_LAB')}
                placeholder={translate('EDIT_LABORATORY_ABOUT_LAB_PLACEHOLDER')}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} md={12}>
              <AutoCompleteChipInput
                name="techniqueList"
                control={control}
                disabled={fieldDisabled['techniqueList']}
                suggestionGetter={techniqueSuggestionGetter}
                titleAdd={translate(
                  'REGISTER_LABORATORY_TAG_ADD_TECHNIQUE_TITLE'
                )}
                title={translate('REGISTER_LABORATORY_TAG_TECHNIQUE_TITLE')}
                errors={errors}
                maxLength="20"
                onInput={e => {
                  e.target.value = e.target.value.toString().slice(0, 254);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <AutoCompleteChipInput
                name="areasExpertiseList"
                control={control}
                disabled={fieldDisabled['areasExpertiseList']}
                suggestionGetter={areaExpertiseSuggestionGetter}
                titleAdd={translate(
                  'REGISTER_LABORATORY_TAG_ADD_AREAEXPERTISE_TITLE'
                )}
                title={translate('REGISTER_LABORATORY_TAG_AREAEXPERTISE_TITLE')}
                errors={errors}
                maxLength="20"
                onInput={e => {
                  e.target.value = e.target.value.toString().slice(0, 254);
                }}
              />
            </Grid>
          </Grid>
        </div>

        <Address
          disabled={fieldDisabled['address']}
          onAddressChange={searchOnMap}
          formMethods={formMethods}
          required={true}
        />

        <div className={classes.defaultContainer}>
          <label className={classes.label}>
            {translate('POSITION_OF_THE_LABORATORY_ON_THE_MAP')}
          </label>

          <Grid item xs={12} md={12}>
            <div id="map">
              <MapGL
                {...viewport}
                mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                onViewportChange={viewport => setViewPort(viewport)}
                dragPan={fieldDisabled['allowDragMap']}
                scrollZoom={false}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    padding: '10px'
                  }}
                >
                  <NavigationControl />
                </div>
                <Marker
                  longitude={marker.longitude}
                  latitude={marker.latitude}
                  draggable={fieldDisabled['allowDragMap']}
                  onDragEnd={event => onMarkerDrag(event)}
                >
                  <div className={classes.marker}></div>
                </Marker>
              </MapGL>
            </div>
          </Grid>
        </div>
        <div className={classes.defaultContainer}>
          <h3 className={classes.h3}>
            {translate('LABORATORY_RESPONSIBLE_DATA')}
          </h3>

          <Grid
            container
            spacing={3}
            style={{ marginBottom: '20px', alignItems: 'baseline' }}
          >
            <Grid item xs={12} md={2}>
              <input name="responsibleId" type="hidden" ref={register} />

              <BaseTextField
                disabled={true}
                required={true}
                label={translate('REGISTER_USER_CPF')}
                placeholder={translate('REGISTER_USER_CPF_PLACEHOLDER')}
                errors={errors}
                control={control}
                name="responsibleCpf"
                mask={cpfMask}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Responsible
                formMethods={formMethods}
                representativeList={representativeList}
                label={translate('REGISTER_USER_NAME')}
                placeholder={translate('REGISTER_USER_NAME_PLACEHOLDER')}
                disabled={fieldDisabled['responsibleFirstName']}
                onSelected={onResponsibleSelected}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseTextField
                disabled={true}
                required={true}
                label={translate('REGISTER_USER_LAST_NAME')}
                placeholder={translate('REGISTER_USER_LAST_NAME_PLACEHOLDER')}
                errors={errors}
                control={control}
                name="responsibleLastName"
                handleChange={() => setNeedApprove(true)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} md={6}>
              <BaseTextField
                disabled={true}
                required={true}
                label={translate('REGISTER_USER_EMAIL')}
                placeholder={translate('REGISTER_USER_EMAIL_PLACEHOLDER')}
                errors={errors}
                control={control}
                name="responsibleEmail"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BaseTextField
                disabled={fieldDisabled['responsiblePhone']}
                required={true}
                label="Telefone"
                placeholder="Telefone"
                errors={errors}
                control={control}
                name="responsiblePhone"
                handleInput={phone}
              ></BaseTextField>
              <input type="hidden" name="website" ref={register} />
              <input type="hidden" name="contactEmail" ref={register} />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} md={12}>
              <BaseTextareaField
                disabled={fieldDisabled['responsibleLattes']}
                label={translate('REGISTER_USER_LATTES_CURRICULUM_LINK')}
                placeholder={translate(
                  'REGISTER_USER_LATTES_CURRICULUM_LINK_PLACEHOLDER'
                )}
                errors={errors}
                control={control}
                name="responsibleLattes"
                maxLength={700}
                hint={translate('REGISTER_USER_LATTES_CURRICULUM_LINK_HINT')}
              />
            </Grid>
          </Grid>
        </div>

        {formState === 'REGISTER' || formState === 'EDITING' ? (
          <div className={classes.defaultContainer}>
            <DocumentUploader
              label="REGISTER_LABORATORY_PROOF_LINK"
              wrapperDescription={'REGISTER_LABORATORY_ATTACH_LAB_COMPROVATION'}
              filesConfig={fileUploadConfig.laboratoryResponsible}
              onChange={handleOnFileSelected}
              currentListLength={documents.length}
            />
            <AddedDocuments
              label="Documentos adicionados"
              docs={documents}
              viewFile={previewFile}
              removeFile={handleOnRemoveFile}
            />
          </div>
        ) : (
          <AddedDocuments
            label="REGISTER_LABORATORY_PROOF_LINK"
            docs={documents}
            viewFile={previewFile}
          />
        )}

        <div className={classes.defaultContainer}>
          <Grid container spacing={3}>
            <TermsOfUse
              formMethods={formMethods}
              disabled={fieldDisabled['termsOfUse']}
            />
          </Grid>
        </div>

        {formState === 'APPROVING' ? (
          <React.Fragment>
            <div className={classes.defaultContainer}>
              <img src={Line} alt="" />
            </div>

            <RequestOpinion
              formMethods={formMethods}
              handleChange={handleOnRefusalFileSelected}
              documents={refusalDocuments}
              onViewFile={previewFile}
              onRemoveFile={handleOnRemoveRefusalFile}
            />
          </React.Fragment>
        ) : null}

        {formState === 'APPROVING_EDIT' ? (
          <>
            <ComparsionTable changelogList={changelogList} />
            <RequestOpinionAdjustment formMethods={formMethods} />
          </>
        ) : null}

        <div className={classes.defaultContainer}>
          {formState !== 'READONLY' ? (
            <Grid
              container
              justify="flex-end"
              spacing={2}
              style={{ paddingTop: '7rem' }}
            >
              <Grid item xs={12} md={2}>
                <Button
                  name="LABORATORY_CANCEL"
                  className={classes.baseButtonGrey}
                  type={'button'}
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={12} md={2}>
                {formState !== 'EDITING' ? (
                  <Button
                    name="LABORATORY_SAVE"
                    fullWidth
                    type="submit"
                    variant="contained"
                    className={classes.baseButton}
                  >
                    {translate(saveButtonText[formState])}
                  </Button>
                ) : (
                  editSubmit
                )}
              </Grid>
              <DialogConfirm
                dialogText="REGISTER_LABORATORY_CONFIRM_EDITING_DIALOG"
                open={openConfirmDialogEditing}
                onCancel={() => setOpenConfirmDialogEditing(false)}
                onConfirm={() => {
                  setOpenConfirmDialogEditing(false)
                  triggerSubmit()
                }}
              />
              <DialogConfirm
                dialogText="REGISTER_LABORATORY_CONFIRM_DIALOG"
                open={openConfirmDialog}
                onConfirm={handleConfirmDialog}
                onCancel={() => setOpenConfirmDialog(false)}
              />
            </Grid>
          ) : null}
        </div>
      </form>
    </>
  )
}

export default RegisterLaboratory
