import styled, { css } from 'styled-components'

import { hoverPrimaryColor, primaryColor } from 'helpers/constants'

export const TypeList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`

export const TypeItem = styled.li`
  color: #999999;

  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 16px;
  }

  ${props =>
    props.$active &&
    css`
      color: ${primaryColor};
    `}

  &:hover {
    cursor: pointer;
    color: ${hoverPrimaryColor};
  }
`
