import React, { useState, useEffect, useContext, useMemo } from 'react'

import { Grid } from '@material-ui/core'

import sharingSituationList from 'helpers/sharingSituationList'

import BaseSelectUncontrolled from 'ui/atoms/BaseSelectUncontrolled'
import BaseTextFieldPublic from 'ui/atoms/BaseTextFieldPublic.js'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const BodyFilters = props => {
  const [filterOption, setFilterOption] = useState('')
  const { translate } = useContext(GlobalizationContext)

  const { filterValue, onSelectChange, onInputChange } = props

  const handleChange = event => {
    setFilterOption(event.target.value)
    onSelectChange(event)
  }

  const filterList = useMemo(() => {
    return sharingSituationList.map(item => ({
      id: item,
      name: translate(`SITUATION.${item}`)
    }))
  }, [translate])

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: '20px' }}
        alignItems="baseline"
      >
        <Grid item xs={12} md={3}>
          <BaseSelectUncontrolled
            defaultValue={translate('LIST.FILTER_STATUS')}
            label=""
            name="filterStatus"
            options={filterList}
            onChange={handleChange}
            value={filterOption}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <BaseTextFieldPublic
            val={filterValue}
            placeholder={translate('LIST.SEARCH')}
            handleChange={onInputChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default BodyFilters
