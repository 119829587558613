import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'

import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

function NewAdvertisePlatform(props) {
  const { translate } = useContext(GlobalizationContext)

  const { formMethods } = props
  const { control, errors } = formMethods

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={12}>
          <BaseTextField
            disabled={false}
            required={true}
            label={translate('PUBLIC_AREA_CONTACT_US_NAME')}
            placeholder={translate('PUBLIC_AREA_CONTACT_US_ADD_NAME')}
            errors={errors}
            control={control}
            name="contact_name"
            maxLength={255}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <BaseTextField
            disabled={false}
            required={true}
            label={translate('PUBLIC_AREA_CONTACT_US_EMAIL')}
            placeholder={translate('PUBLIC_AREA_CONTACT_US_ADD_EMAIL')}
            errors={errors}
            control={control}
            name="contact_email"
            maxLength={255}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <BaseTextField
            disabled={false}
            required={true}
            label={translate('PUBLIC_AREA_CONTACT_US_SUBJECT')}
            placeholder={translate('PUBLIC_AREA_CONTACT_US_ADD_SUBJECT')}
            errors={errors}
            control={control}
            name="contact_subject"
            maxLength={255}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <BaseTextareaField
            name="contact_message"
            label={translate('PUBLIC_AREA_CONTACT_US_MESSAGE')}
            placeholder={translate('PUBLIC_AREA_CONTACT_US_ADD_MESSAGE')}
            errors={errors}
            control={control}
            disabled={false}
            required
            rows={5}
            maxLength={500}
          />
        </Grid>
      </Grid>
    </>
  )
}
export default NewAdvertisePlatform
