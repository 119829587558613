import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'

import { CPF_MASK } from './constants'

export class cpfMask extends Component {
  render() {
    const { inputRef, ...other } = this.props

    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null)
        }}
        mask={CPF_MASK}
        placeholder="999.999.999-99"
        guide={false}
      />
    )
  }
}
