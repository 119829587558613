import React, { useContext } from 'react'

import { Grid } from '@material-ui/core'

import { yearMask } from 'helpers/maskedYear'

import BaseSelect from 'ui/atoms/BaseSelect'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'
import DefaultHint from 'ui/molecules/commons/DefaultHint'
import InputAddItens from 'ui/molecules/commons/InputAddItens'
import BaseSlider from 'ui/molecules/Slider'

import FinancingOrigin from './Fragments/FinancingOrigin'
import OtherReason from './Fragments/OtherReason'

const EquipmentData = ({
  formMethods,
  fieldDisabled,
  classes,
  laboratoryList,
  situationList,
  financingOriginList,
  equipmentCode,
  readOnlyDenominations = []
}) => {
  const { control, errors } = formMethods
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <h3 className={classes.h3}>{translate('DATA')}</h3>

      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        {equipmentCode && (
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <Grid container display="flex" style={{ marginBottom: '25px' }}>
                <label className={classes.label}>{translate('CODE')}:</label>
                <p style={{ margin: '0 0 0 10px' }}>{equipmentCode}</p>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12}>
          <Grid item xs={12} md={6}>
            <BaseSelect
              disabled={fieldDisabled}
              required={true}
              placeholder={translate('LABORATORY_LIST_DEFAULT')}
              label={translate('LABORATORY_LIST_LABEL')}
              errors={errors}
              control={control}
              name="laboratoryId"
              options={laboratoryList || []}
              optionRenderer={(desc, option) =>
                `${option.initials} - ${option.name}`
              }
            ></BaseSelect>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={5}>
          <BaseSelect
            disabled={false}
            required={true}
            placeholder={translate('SITUATION_LIST_DEFAULT')}
            label={translate('SITUATION_LIST_LABEL')}
            errors={errors}
            control={control}
            name="situation"
            idKey="idReason"
            descriptionKey="description"
            options={situationList || []}
            style={{ padding: '9px 10px!important' }}
          ></BaseSelect>
        </Grid>
        <OtherReason
          situationList={situationList || []}
          formMethods={formMethods}
          translate={translate}
        />
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={8}>
          <BaseTextField
            disabled={false}
            required={true}
            label={translate('NAME')}
            placeholder={translate('NAME')}
            errors={errors}
            control={control}
            name="name"
            maxLength={255}
          ></BaseTextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            disabled={false}
            label={translate('NAME_INITIALS')}
            placeholder={translate('NAME_INITIALS')}
            errors={errors}
            control={control}
            name="initials"
            required={true}
            maxLength={12}
          ></BaseTextField>
        </Grid>
      </Grid>
      <Grid container item={true} display="flex" alignItems="baseline">
        <label className={classes.label}>
          {translate('OTHER_DENOMINATION_LABEL')}
        </label>
        <DefaultHint content="OTHER_DENOMINATION_HINT" />
      </Grid>
      <InputAddItens
        label="OTHER_DENOMINATION_LABEL"
        inputPlaceholder="OTHER_DENOMINATION_PLACEHOLDER"
        maxLimit={3}
        control={control}
        name="denominationList"
        readOnlyList={readOnlyDenominations}
      />
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            label={translate('TYPE_IDENTIFIER')}
            placeholder=""
            errors={errors}
            control={control}
            name="code"
            maxLength={255}
            hint="TYPE_IDENTIFIER_HINT"
          ></BaseTextField>
        </Grid>
        <Grid item xs={12} md={8}>
          <BaseTextField
            label={translate('IDENTIFIER')}
            placeholder=""
            errors={errors}
            control={control}
            name="identifier"
            maxLength={255}
            hint="IDENTIFIER_HINT"
          ></BaseTextField>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            label={translate('BRAND')}
            placeholder=""
            errors={errors}
            control={control}
            name="brand"
            maxLength={255}
          ></BaseTextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            label={translate('MODEL')}
            placeholder=""
            errors={errors}
            control={control}
            name="model"
            maxLength={255}
          ></BaseTextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            label={translate('YEAR')}
            placeholder=""
            errors={errors}
            control={control}
            name="purchaseYear"
            mask={yearMask}
          ></BaseTextField>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} md={6}>
          <BaseTextField
            label={translate('MANUFACTURER')}
            placeholder=""
            errors={errors}
            control={control}
            name="manufacturer"
            maxLength={255}
          ></BaseTextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <BaseTextField
            label={translate('LINK')}
            placeholder=""
            errors={errors}
            control={control}
            name="manufacturerLink"
            maxLength={255}
          ></BaseTextField>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <FinancingOrigin
            formMethods={formMethods}
            options={financingOriginList}
          />
        </Grid>
      </Grid>

      <h4 className={classes.h4}>{translate('USAGE_PROFILE')}</h4>

      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <Grid container display="flex" alignItems="center">
            <label className={classes.label} style={{ paddingBottom: '0' }}>
              {translate('SLIDER_LABEL')}
            </label>
            <DefaultHint content="SLIDER_HINT" />
          </Grid>

          <BaseSlider
            name="availabilityIndex"
            otherSliders={['multiuserIndex']}
            limitExceededMessage={translate('SLIDER_LIMIT_MESSAGE')}
            formMethods={formMethods}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <Grid container display="flex" alignItems="center">
            <label className={classes.label} style={{ paddingBottom: '0' }}>
              {translate('MULTIUSER_LABEL')}
            </label>
            <DefaultHint content="MULTIUSER_HINT" />
          </Grid>

          <BaseSlider
            name="multiuserIndex"
            otherSliders={['availabilityIndex']}
            limitExceededMessage={translate('SLIDER_LIMIT_MESSAGE')}
            formMethods={formMethods}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Wrapper(EquipmentData, {
  namespace: 'EQUIPMENT_REGISTER_DATA'
})
