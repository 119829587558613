import React, { useContext } from 'react'
import styled from 'styled-components'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import InputAddItensAutoSuggest from 'ui/molecules/commons/InputAddItensAutoSuggest'

const Title = styled.h3`
  margin-top: 25px;
`

const EquipmentResponsible = props => {
  const { formMethods, responsibleList: responsibleListProp, classes } = props
  const { translate } = useContext(GlobalizationContext)
  const { control } = formMethods

  const responsibleList = responsibleListProp || []

  const responsibleListSuggestionGetter = ({ value }) => {
    if (!responsibleList.length) return Promise.resolve([])

    return Promise.resolve(
      responsibleList.filter(item => {
        if (item.situation !== 'ACTIVE' || item.userSituation !== 'ACTIVE') {
          return false
        }

        const name = item.name.toLowerCase()
        return name.indexOf(value.toLowerCase()) >= 0
      })
    )
  }

  return (
    <>
      <Title className={classes.h3}>
        {translate('REGISTER_EQUIPMENT_RESPONSIBLE')}
      </Title>

      <InputAddItensAutoSuggest
        name="responsibleList"
        control={control}
        label="REGISTER_EQUIPMENT_ADD_RESPONSIBLE"
        inputPlaceholder="REGISTER_EQUIPMENT_ADD_RESPONSIBLE_PLACEHOLDER"
        suggestionGetter={responsibleListSuggestionGetter}
      />
    </>
  )
}

export default EquipmentResponsible
