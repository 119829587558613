import React, { useEffect, useContext } from 'react'

import { barraBrasilColor } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const barraBrasilStyle = {
  background: barraBrasilColor,
  height: '20px',
  padding: '0 0 0 10px',
  display: 'block'
}
const menuBarraTemp = {
  listStyle: 'none'
}
const linkItem = {
  fontFamily: 'sans,sans-serif',
  textDecoration: 'none',
  color: 'white'
}
function HeaderGov() {
  const { translate } = useContext(GlobalizationContext)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `//barra.brasil.gov.br/barra_2.0.js`
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div id="barra-brasil" style={barraBrasilStyle}>
      <ul id="menu-barra-temp" style={menuBarraTemp}>
        <li style={linkItem}>
          <a href="http://brasil.gov.br" style={linkItem}>
            {translate('GOVERNMENT_PORTAL')}
          </a>
        </li>
      </ul>
    </div>
  )
}

export default HeaderGov
