import * as Yup from 'yup'

export default Yup.object().shape({
  firstName: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  lastName: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),
  cpf: Yup.string().cpf().digitsOnly(),
  nationality: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim(),

  graduation: Yup.string().required('VALIDATION:REQUIRED_ERROR'),
  linkLattes: Yup.string().trim(),
  ORCID: Yup.string().trim(),
  email: Yup.string()
    .email('VALIDATION:EMAIL_ERROR')
    .required('VALIDATION:REQUIRED_ERROR'),
  telephone: Yup.string().required('VALIDATION:REQUIRED_ERROR').trim()
})
