import React, { useContext, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { AuthContext } from 'context/AuthContext'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import { history, generatePrivatePath } from 'helpers/history'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import DialogConfirm from 'ui/molecules/commons/DialogConfirm'
import Loader from 'ui/molecules/Loader'
import useStyles from 'ui/styles'

import FormSchema from './formSchema'
import ComplementaryData from './Fragments/ComplementaryData'
import ProfileData from './Fragments/ProfileData'
import UserPermissions from './Fragments/UserPermissions'
import { myProfileUseCases } from './providers'

function MyProfile() {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const { refreshCurrentUserData, signOut } = useContext(AuthContext)
  const [profileImage, setProfileImage] = useState('')
  const [permissionList, setPermissionList] = useState([])
  const [originalEmail, setOriginalEmail] = useState('')
  const [emailChanged, setEmailChanged] = useState(false)

  const resolver = useYupValidationResolver(FormSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {}
  })

  const { reset, handleSubmit } = formMethods

  const myProfileDataPromise = useCallback(async () => {
    const { profileData, permissionList, profileImage } =
      await myProfileUseCases.getMyProfile()
    reset(profileData)
    setOriginalEmail(profileData.email)
    setPermissionList(permissionList)
    setProfileImage(profileImage)
  }, [reset])

  useEffect(() => {
    myProfileDataPromise()
  }, [myProfileDataPromise])

  const onSubmit = async formData => {
    if (formData.email !== originalEmail) {
      setEmailChanged(true)
      return
    }

    await updateProfileData(formData)
  }

  const updateProfileData = async formData => {
    try {
      const {
        profileData: newProfileData,
        permissionList: newPermissionList,
        profileImage: newProfileImage
      } = await myProfileUseCases.updateMyProfile({
        formData,
        permissionList,
        profileImage
      })

      if (!emailChanged) {
        await refreshCurrentUserData()
        history.push(generatePrivatePath(''))
        reset(newProfileData)
        setPermissionList(newPermissionList)
        setProfileImage(newProfileImage)
      } else {
        await signOut()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const setPermissionDeletedStatus = (row, status) => {
    setPermissionList(oldList => {
      const newList = [...oldList]
      const itemIndex = newList.findIndex(obj => obj === row)
      newList[itemIndex] = {
        ...newList[itemIndex],
        deleted: status
      }
      return newList
    })
  }

  return (
    <>
      <Loader />
      <DialogConfirm
        open={emailChanged}
        labelCancelButton={translate('COMMONS:NO')}
        labelConfirmButton={translate('COMMONS:YES')}
        dialogText={translate('CONFIRM_EMAIL_CHANGE')}
        onConfirm={() => {
          handleSubmit(updateProfileData)()
        }}
        onCancel={() => {
          setEmailChanged(false)
        }}
      />

      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />

      <div className={classes.defaultContainer}>
        <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
          <ProfileData
            formMethods={formMethods}
            profilePicture={profileImage}
            onProfilePictureChange={setProfileImage}
          />
          <ComplementaryData formMethods={formMethods} />
          <UserPermissions
            {...{ permissionList }}
            onRowDeletedStatusChange={setPermissionDeletedStatus}
          />
          <Grid container>
            <Grid item>
              Você não poderá excluir seu perfil caso seja o responsável
              principal do seu laboratório ou único de instituição
            </Grid>
          </Grid>
          <Grid
            container
            justify="flex-end"
            spacing={2}
            style={{ paddingTop: '4rem' }}
          >
            <Grid item xs={12} md={2}>
              <Button
                id="PROFILE_CANCEL"
                className={classes.baseButtonGrey}
                type={'button'}
                variant="contained"
                fullWidth
                onClick={() => {
                  history.push(generatePrivatePath(''))
                }}
              >
                {translate('COMMONS:CANCEL')}
              </Button>
            </Grid>

            <Grid item xs={12} md={2}>
              <Button
                id="PROFILE_SAVE"
                fullWidth
                type={'submit'}
                variant="contained"
                className={classes.baseButton}
              >
                {translate('COMMONS:SAVE')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  )
}

export default Wrapper(MyProfile, {
  namespace: 'MY_PROFILE'
})
