import React from 'react'

import { history } from 'helpers/history'

import wrapper from 'ui/atoms/Wrapper'

import { GenericNotification } from './GenericNotification'

const GroupedNotificationCmp = ({
  notification,
  itemClicked,
  titleKey,
  messageKey,
  link,
  ...rest
}) => {
  const handleClick = () => {
    history.push(link)
    itemClicked()
  }

  return (
    <GenericNotification
      titleKey={titleKey}
      messageKey={messageKey}
      quantity={notification.count}
      onClick={handleClick}
      {...rest}
    />
  )
}

const GroupedNotification = wrapper(GroupedNotificationCmp, {
  namespace: 'NOTIFICATIONS'
})

export { GroupedNotification }
