import axios from './axios'

export const LabEquipService = {
  getList({ pageSize, pageNumber, filter }) {
    const request = {
      params: {
        page: pageNumber,
        size: pageSize,
        ...filter
      }
    }
    return axios.get('/assets', request)
  },

  getAssetList(type, advancedFilter = {}, { pageSize, pageNumber, filter }) {
    const config = {
      params: {
        page: pageNumber,
        size: pageSize,
        ...filter
      }
    }
    return axios.post(`/assets/${type}`, advancedFilter, config)
  },

  getAssetCarouselList(type, { pageSize, pageNumber }) {
    const config = {
      params: {
        page: pageNumber,
        size: pageSize
      }
    }
    return axios.get(`/assets/${type}-carousel`, config)
  },

  getStateList(skipLoader = true) {
    const request = {
      skipLoader
    }

    return axios.get('/assets/state-filter', request).then(resp => resp.data)
  },
  getCityList({ term, skipLoader = true }) {
    const request = {
      skipLoader,
      params: {
        term
      }
    }
    return axios.get('/assets/cities-filter', request).then(resp => resp.data)
  },
  getInstitutionList({ term, skipLoader = true }) {
    const request = {
      skipLoader,
      params: {
        term
      }
    }
    return axios
      .get('/assets/institutions-filter', request)
      .then(resp => resp.data)
  },
  getLabTypeList() {
    return axios.get('/assets/lab-filter/type-lab').then(resp => resp.data)
  },
  getLabAreaOfExpertise({ term, skipLoader = true }) {
    const request = {
      skipLoader,
      params: {
        term
      }
    }
    return axios
      .get('/assets/lab-filter/area-expertise', request)
      .then(resp => resp.data)
  },
  getLabTechnique({ term, skipLoader = true }) {
    const request = {
      skipLoader,
      params: {
        term
      }
    }
    return axios
      .get('/assets/lab-filter/techniques', request)
      .then(resp => resp.data)
  },
  getEquipTechnique({ term, skipLoader = true }) {
    const request = {
      skipLoader,
      params: {
        term
      }
    }
    return axios
      .get('/assets/equip-filter/techniques', request)
      .then(resp => resp.data)
  },
  getEquipOtherDenomination({ term, skipLoader = true }) {
    const request = {
      skipLoader,
      params: {
        term
      }
    }
    return axios
      .get('/assets/equip-filter/denominations', request)
      .then(resp => resp.data)
  }
}
export default LabEquipService
