import styled from 'styled-components'

export const AnalysisContainer = styled.div`
  background: #fafafa;
  border: 1px solid #cccccc;
  border-radius: 3px;
  box-sizing: border-box;
  margin-top: 32px;
  padding: 32px;
`

export const AnalysisInformationContainer = styled.div`
  align-items: center;
  display: flex;

  & div {
    width: auto;
  }

  & p:not(:first-child) {
    margin-left: 8px;
  }
`

export const AnalysisText = styled.p`
  font-weight: bold;
  font-size: 14px;
  position: relative;

  &:before {
    content: '\f069';
    font-family: 'Font Awesome 5 Free';
    font-size: 5px;
    font-weight: 600;
    position: absolute;
    left: -12px;
    top: 6px;
  }
`
