import React, { useCallback } from 'react'

import LabEquipService from 'api/LabEquipService'

import List, { LaboratoryItem } from 'ui/atoms/PaginatedList'

import { useAdvancedFilter } from 'views/Search/hooks/useAdvancedFilter'

import PaginatedList from '../PaginatedList'

const LaboratoryList = ({
  filter: filterString,
  advancedFilter = {},
  rowsPerPageList,
  listType,
  hideTypeControls,
  onListTypeChange: handleListTypeChange
}) => {
  const parsedAdvancedFilter = useAdvancedFilter(advancedFilter)

  const pageGetter = useCallback(
    async ({ pageSize, pageNumber }) => {
      const filter = {
        ...(filterString ? { term: filterString } : {})
      }
      try {
        const resp = await LabEquipService.getAssetList(
          'lab',
          parsedAdvancedFilter,
          {
            pageSize,
            pageNumber,
            filter
          }
        )

        return resp.data
      } catch (error) {
        console.log(error)
        return []
      }
    },
    [filterString, parsedAdvancedFilter]
  )

  return (
    <PaginatedList
      hideTypeControls={hideTypeControls}
      pageGetter={pageGetter}
      rowsPerPageList={rowsPerPageList}
      listType={listType}
      onListTypeChange={handleListTypeChange}
    >
      {({ itemList, listType }) => (
        <List type={listType}>
          {itemList.map(item => (
            <LaboratoryItem key={item.idAsset} type={listType} content={item} />
          ))}
        </List>
      )}
    </PaginatedList>
  )
}

export default LaboratoryList
