import { useCallback } from 'react'

const appendErrors = (
  name,
  validateAllFieldCriteria,
  errors,
  type,
  message
) => {
  if (validateAllFieldCriteria) {
    const error = errors[name]

    return {
      ...error,
      types: {
        ...(error && error.types ? error.types : {}),
        [type]: message || true
      }
    }
  }

  return {}
}

const isArray = value => Array.isArray(value)

const isKey = value =>
  !isArray(value) &&
  (/^\w*$/.test(value) ||
    !/\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/.test(value))

const stringToPath = input => {
  const result = []

  input.replace(
    /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g,
    (match, mathNumber, mathQuote, originalString) => {
      result.push(
        mathQuote
          ? originalString.replace(/\\(\\)?/g, '$1')
          : mathNumber || match
      )
    }
  )

  return result
}

const isNullOrUndefined = value => value == null

const isObjectType = value => typeof value === 'object'

const isObject = value =>
  !isNullOrUndefined(value) && !isArray(value) && isObjectType(value)

const set = (object, path, value) => {
  let index = -1
  const tempPath = isKey(path) ? [path] : stringToPath(path)
  const length = tempPath.length
  const lastIndex = length - 1

  while (++index < length) {
    const key = tempPath[index]
    let newValue = value

    if (index !== lastIndex) {
      const objValue = object[key]
      newValue =
        isObject(objValue) || isArray(objValue)
          ? objValue
          : !isNaN(+tempPath[index + 1])
          ? []
          : {}
    }
    object[key] = newValue
    object = object[key]
  }
  return object
}

const transformToNestObject = data =>
  Object.entries(data).reduce((previous, [key, value]) => {
    if (!isKey(key)) {
      set(previous, key, value)
      return previous
    }

    return { ...previous, [key]: value }
  }, {})

const parseErrorSchema = (error, validateAllFieldCriteria, translate) => {
  return Array.isArray(error.inner)
    ? error.inner.reduce((previous, { path, message, params, type }) => {
        const translatedMessage = translate(message, params)

        return {
          ...previous,
          ...(path
            ? previous[path] && validateAllFieldCriteria
              ? {
                  [path]: appendErrors(
                    path,
                    validateAllFieldCriteria,
                    previous,
                    type,
                    translatedMessage
                  )
                }
              : {
                  [path]: previous[path] || {
                    message: translatedMessage,
                    type,
                    ...(validateAllFieldCriteria
                      ? {
                          types: { [type]: translatedMessage || true }
                        }
                      : {})
                  }
                }
            : {})
        }
      }, {})
    : {
        [error.path]: {
          message: translate(error.message, error.params || {}),
          type: error.type
        }
      }
}

export const useYupResolver = (schema, options = {}) => {
  return useCallback(
    async (values, context = {}, validateAllFieldCriteria = false) => {
      const mergedOptions = Object.assign(
        {
          translate: null,
          abortEarly: false
        },
        options
      )

      const translate =
        mergedOptions.translate ||
        (message => {
          return message
        })

      try {
        return {
          values: await schema.validate(values, {
            ...mergedOptions,
            context
          }),
          errors: {}
        }
      } catch (e) {
        return {
          values: {},
          errors: transformToNestObject(
            parseErrorSchema(e, validateAllFieldCriteria, translate)
          )
        }
      }
    },
    [schema, options]
  )
}

export default useYupResolver
