// @ts-nocheck
import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { cnpjMask } from 'helpers/maskedCNPJ'
import { phoneMask } from 'helpers/maskedPhone'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const InstitutionData = ({ data }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <Typography variant="subtitle1">
        {translate('INSTITUTION_DATA')}
      </Typography>

      <Grid container spacing={3} style={{ marginTop: '32px' }}>
        <Grid item xs={12} md={10}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Razão Social"
            name="social_reason"
            value={data?.social_reason || ''}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Sigla"
            name="initials"
            value={data?.initials || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '24px' }}>
        <Grid item xs={12} md={3}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="CNPJ"
            mask={cnpjMask}
            name="cnpj"
            value={data?.cnpj || ''}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Telefone Institucional"
            mask={phoneMask}
            name="phoneNumber"
            value={data?.phoneNumber || ''}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default InstitutionData
