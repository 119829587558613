// @ts-nocheck
import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Grid from '@material-ui/core/Grid'

import AdminService from 'api/AdminService'
import { fileUploadConfig } from 'helpers/constants'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import AddedDocuments from 'ui/atoms/AddedDocuments'
import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import BaseTextField from 'ui/atoms/BaseTextField'
import DocumentUploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Title } from 'ui/atoms/Title'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

const Schema = Yup.object().shape({
  hasEmail: Yup.boolean(),
  responsible_email: Yup.string().when('hasEmail', (hasEmail, schema) => {
    return hasEmail
      ? schema.required('VALIDATION:REQUIRED_ERROR').email('Email inválido')
      : schema.notRequired()
  }),
  reason: Yup.string()
    .required('VALIDATION:REQUIRED_ERROR')
    .max(500, 'VALIDATION:MAX_EXCEEDED')
    .trim()
})

const DialogActivation = ({
  activation,
  close,
  cnpj,
  institutionName,
  updateList
}) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)
  const [documents, setDocuments] = useState([])

  const resolver = useYupValidationResolver(Schema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver
  })

  const { control, errors, register, handleSubmit } = formMethods

  const onFileChange = fileList => {
    if (!fileList || !fileList.length) return
    setDocuments(documents => documents.concat(fileList))
  }

  const onRemoveFile = file => {
    const remaningFiles = documents.filter(item => item !== file)
    setDocuments(remaningFiles)
  }

  const onSubmit = async formData => {
    if (activation) {
      const payload = {
        detail: formData.reason,
        email: formData.responsible_email
      }
      try {
        await AdminService.reactivateInstitution(cnpj, payload)
        await uploadFilesInSequence(
          AdminService.sendReactiveDocument,
          cnpj,
          documents
        )
        notificationUseCases.newSuccess('DEFAULT_SUCCESS')
        close()
        updateList()
      } catch (error) {
        notificationUseCases.newError(error)
      }
    } else {
      const payload = {
        detail: formData.reason
      }
      try {
        await AdminService.inactivateInstitution(cnpj, payload)
        await uploadFilesInSequence(
          AdminService.sendInactiveDocument,
          cnpj,
          documents
        )

        notificationUseCases.newSuccess('DEFAULT_SUCCESS')
        close()
        updateList()
      } catch (error) {
        notificationUseCases.newError(error)
      }
    }
  }

  // Add this to a helper function
  const uploadFilesInSequence = async (uploadMethod, id, list) => {
    const uploadedFiles = await list.reduce(
      async (promise, { file, ...savedFile }) => {
        return promise
          .then(uploadedFiles => {
            if (savedFile && savedFile.id) {
              return Promise.resolve(uploadedFiles.concat(file))
            } else {
              return uploadMethod(id, file)
                .then(resp => {
                  return uploadedFiles.concat(resp.data)
                })
                .catch(() => {
                  return uploadedFiles.concat(file)
                })
            }
          })
          .then(data => {
            return data
          })
      },
      Promise.resolve([])
    )

    return uploadedFiles
  }

  return (
    <Dialog
      open={true}
      onClose={close}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogContent>
        <Title item xs={12} $noBorder id="dialog-title">
          {activation
            ? translate('DIALOG_ACTIVATION.ACTIVATION_TITLE')
            : translate('DIALOG_ACTIVATION.INACTIVATION_TITLE')}
        </Title>
        <DialogContentText id="dialog-description">
          {activation ? (
            <Trans
              t={translate}
              i18nKey="DIALOG_ACTIVATION.ACTIVATION_TEXT"
              values={{ institution: institutionName }}
            />
          ) : (
            <Trans
              t={translate}
              i18nKey="DIALOG_ACTIVATION.INACTIVATION_TEXT"
              values={{ institution: institutionName }}
            />
          )}
        </DialogContentText>
        <BaseTextareaField
          required={true}
          errors={errors}
          control={control}
          maxLength={500}
          rows={4}
          name="reason"
          label={translate('DIALOG_ACTIVATION.REASON')}
          placeholder={
            activation
              ? translate('DIALOG_ACTIVATION.REASON_ACTIVATION_PLACEHOLDER')
              : translate('DIALOG_ACTIVATION.REASON_INACTIVATION_PLACEHOLDER')
          }
        />
        <DocumentUploader
          label={'DIALOG_ACTIVATION.DOCUMENT_UPLOAD_LABEL'}
          wrapperDescription={'DIALOG_ACTIVATION.DOCUMENT_UPLOAD_LABEL'}
          onChange={onFileChange}
          currentListLength={documents.length}
          filesConfig={fileUploadConfig.activationInstitution}
        />
        <AddedDocuments docs={documents} removeFile={onRemoveFile} />
        <input
          type="hidden"
          name={'hasEmail'}
          defaultValue={activation}
          ref={register()}
        />
        {activation && (
          <Grid container>
            <Grid item md={6}>
              <BaseTextField
                disabled={false}
                required={true}
                label={translate('DIALOG_ACTIVATION.RESPONSIBLE_EMAIL_LABEL')}
                placeholder={translate('COMMONS:INFORM')}
                errors={errors}
                control={control}
                name="responsible_email"
                hint={translate('DIALOG_ACTIVATION.RESPONSIBLE_EMAIL_TOOLTIP')}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={classes.baseButtonGrey}
          id="DIALOG_CANCEL"
          onClick={close}
        >
          {translate('COMMONS:CANCEL')}
        </Button>

        <Button
          id="DIALOG_CONFIRM"
          color="primary"
          className={classes.baseButton}
          onClick={handleSubmit(onSubmit)}
        >
          {activation
            ? translate('DIALOG_ACTIVATION.ACTIVATION')
            : translate('DIALOG_ACTIVATION.INACTIVATION')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogActivation
