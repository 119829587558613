import React, { useContext } from 'react'

import { Grid, RadioGroup } from '@material-ui/core'

import BaseTextareaField from 'ui/atoms/BaseTextareaField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const DoesntNeedComplementation = ({ formMethods }) => {
  const { translate } = useContext(GlobalizationContext)

  const { control, errors } = formMethods

  return (
    <>
      <Grid item xs={12}>
        <BaseTextareaField
          name="complementation"
          label={translate('ANALYZE.REPROVE.COMPLEMENTATION_LABEL')}
          placeholder={translate('ANALYZE.REPROVE.COMPLEMENTATION_PLACEHOLDER')}
          control={control}
          errors={errors}
          maxLength={500}
          rows={5}
          required
        />
      </Grid>
    </>
  )
}

export default DoesntNeedComplementation
