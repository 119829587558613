import React, { useContext, useEffect } from 'react'

import { Grid, Typography } from '@material-ui/core'

import { phone } from 'helpers/generalInputMasks'

import BaseDatePicker from 'ui/atoms/BaseDatePicker'
import BaseMultipleSelect from 'ui/atoms/BaseMultipleSelect'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

import EquipmentOption from 'views/BackOffice/Subviews/RequestSharing/fragments/EquipmentOption'

const Approve = ({ formMethods, sharingData }) => {
  const { translate } = useContext(GlobalizationContext)

  const { initialFormData, currentLaboratory } = sharingData

  const { control, errors, setValue } = formMethods

  const equipmentList = currentLaboratory?.equipmentList || []

  useEffect(() => {
    if (initialFormData.specificEquipment) {
      setValue('specificEquipment', initialFormData.specificEquipment)
    } else {
      setValue('specificEquipment', [])
    }
  }, [setValue, initialFormData.specificEquipment])

  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <BaseMultipleSelect
            required={true}
            label={translate('ANALYZE.APPROVE.EQUIPMENTS')}
            placeholder={translate('COMMONS_SELECT')}
            errors={errors}
            options={equipmentList}
            control={control}
            name="specificEquipment"
            selectedValueRenderer={option => `${option.code} - ${option.name}`}
            optionRenderer={option => <EquipmentOption {...option} />}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <BaseTextField
          name="responsible"
          label={translate('ANALYZE.APPROVE.RESPONSIBLE_LABEL')}
          placeholder={translate('ANALYZE.APPROVE.RESPONSIBLE_PLACEHOLDER')}
          control={control}
          errors={errors}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BaseTextField
          name="email"
          label={translate('ANALYZE.APPROVE.EMAIL_LABEL')}
          placeholder={translate('ANALYZE.APPROVE.EMAIL_PLACEHOLDER')}
          control={control}
          errors={errors}
          required
        />
      </Grid>

      <Grid container item>
        <Grid item xs={12} md={6}>
          <BaseTextField
            name="phone"
            label={translate('ANALYZE.APPROVE.PHONE')}
            placeholder={translate('COMMONS:TYPE')}
            control={control}
            errors={errors}
            required
            handleInput={phone}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={10} md={5}>
        <Grid container item spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {translate('ANALYZE.APPROVE.SUGGESTED_DATE')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <BaseDatePicker
              name="suggestedDateFrom"
              label={translate('ANALYZE.APPROVE.SUGGESTED_DATE_FROM_LABEL')}
              placeholder={translate(
                'ANALYZE.APPROVE.SUGGESTED_DATE_FROM_PLACEHOLDER'
              )}
              control={control}
              error={errors.suggestedDateFrom}
              required
              fullWidth
              disablePast
              openTo="year"
              views={['year', 'month', 'date']}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <BaseDatePicker
              name="suggestedDateTo"
              label={translate('ANALYZE.APPROVE.SUGGESTED_DATE_TO_LABEL')}
              placeholder={translate(
                'ANALYZE.APPROVE.SUGGESTED_DATE_TO_PLACEHOLDER'
              )}
              control={control}
              error={errors.suggestedDateTo}
              required
              fullWidth
              disablePast
              openTo="year"
              views={['year', 'month', 'date']}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Approve
