import { Auth } from 'aws-amplify'
import React, { useRef, useState, useContext } from 'react'
import styled from 'styled-components'

import { Button } from '@material-ui/core'

import { AuthContext } from 'context/AuthContext'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import Avatar from 'assets/images/avatar.svg'

import SubMenuLogin from './Fragments/SubMenuLogin'
import SubMenuProfile from './Fragments/SubMenuProfile'

const ButtonStyled = styled(Button)`
  border: 1px solid #009b7f;
  text-transform: none;
  padding: 5px 16px;
  font-size: 14px;
  border-radius: 2px;
  margin-left: 13px;
  text-decoration: none;
  color: #000000;
  &:hover {
    font-weight: 600;
    background: #009b7f;
    color: #fff;
  }
`

function MenuButtonLogin() {
  const { translate } = useContext(GlobalizationContext)
  const anchorLoginRef = useRef(null)
  const anchorProfileRef = useRef(null)
  const [loginOpen, setLoginOpen] = useState(false)
  const [profileOpen, setProfileOpen] = useState(false)

  const { isAuthenticated, signOut } = useContext(AuthContext)

  const handleLoginToggle = () => {
    setLoginOpen(prevOpen => !prevOpen)
  }

  const handleProfileToggle = () => {
    setProfileOpen(prevOpen => !prevOpen)
  }

  const handleLoginClose = event => {
    if (
      anchorLoginRef.current &&
      anchorLoginRef.current.contains(event.target)
    ) {
      return
    }
    setLoginOpen(false)
  }
  const handleProfileClose = event => {
    if (
      anchorProfileRef.current &&
      anchorProfileRef.current.contains(event.target)
    ) {
      return
    }
    setProfileOpen(false)
  }

  const handleSignOut = () => {
    signOut()
  }

  const LoginButton = (
    <React.Fragment>
      <ButtonStyled
        ref={anchorLoginRef}
        aria-controls={loginOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleLoginToggle}
        id="MENU_BUTTON_LOGIN"
      >
        {translate('SIGN_IN')}
      </ButtonStyled>

      <SubMenuLogin
        loginOpen={loginOpen}
        anchorEl={anchorLoginRef.current}
        handleLoginClose={handleLoginClose}
      />
    </React.Fragment>
  )

  const SignOutButton = (
    <React.Fragment>
      <div
        ref={anchorProfileRef}
        aria-controls={profileOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleProfileToggle}
        style={{ cursor: 'pointer' }}
        name="MENU_BUTTON_MY_PROFILE"
      >
        <img src={Avatar} alt="Profile" />
      </div>
      <SubMenuProfile
        profileOpen={profileOpen}
        anchorEl={anchorProfileRef.current}
        handleProfileClose={handleProfileClose}
        handleSignOut={handleSignOut}
      />
    </React.Fragment>
  )

  return isAuthenticated ? SignOutButton : LoginButton
}

export default MenuButtonLogin
