import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import { primaryColor } from 'helpers/constants'

import SubTitle from 'ui/atoms/BasePublic/SubTitle'
import DefaultHint from 'ui/molecules/commons/DefaultHint'
import InputAddItens from 'ui/molecules/commons/InputAddItens'

export const TextBox = styled(Box)`
  color: #333;
  font-size: 14px;
  line-height: 21px;

  a {
    text-decoration: none;
    color: ${primaryColor};
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
`

export const TextContainer = styled(Grid)`
  margin-left: 32px;
`

export const TextSubTitle = styled(SubTitle)`
  padding: 0;
  padding-bottom: 30px;

  ${props =>
    props.$small &&
    css`
      font-size: 14px;
    `}
`

export const ContentBox = styled(Grid).attrs({ container: true, spacinng: 3 })`
  min-height: 45px;
  background: #fafafa;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 0;
`

export const Hint = styled(DefaultHint)`
  font-size: 16px;
`

export const OtherDenominationInput = styled(InputAddItens)`
  padding-bottom: 0;
`
