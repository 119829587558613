import React, { useContext } from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'

import { history } from 'helpers'
import { md } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import logoImage from 'assets/images/logo.png'
import 'assets/scss/header.scss'

const WrapLogo = styled(Box)`
  display: flex;
  padding: 3px 0;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
`

const LogoImage = styled.img`
  width: 46px;
  height: auto;
  margin-right: 15px;
  align-self: center;
  cursor: pointer;
  @media(max-width: ${md}) {
    margin-right: 15px;
  }
}
`
const LogoTxt = styled.h1`
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  @media (max-width: ${md}) {
    display: none;
  }
`
function handleClick() {
  history.push('/')
}
function Logo() {
  const { translate } = useContext(GlobalizationContext)

  return (
    <WrapLogo display="flex" onClick={handleClick}>
      <LogoImage src={logoImage} />
      <LogoTxt>
        {translate('HOME_LOGO_PART1')}
        <br />
        {translate('HOME_LOGO_PART2')}
      </LogoTxt>
    </WrapLogo>
  )
}

export default Logo
