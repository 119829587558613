import styled from 'styled-components'

import { fontSize, primaryColor } from 'helpers/constants'

export const UpdateEquipmentContainer = styled.div`
  align-items: center;
  background: rgba(242, 242, 242, 0.2);
  border: 1px solid rgba(153, 153, 153, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 20px 0;
  padding: 12px 24px;
`

export const UpdateEquipmentText = styled.span`
  font-size: ${fontSize};
  font-weight: 600;
  margin-right: 20px;
`

export const UpdateEquipmentButton = styled.button`
  background: #ffffff;
  border: 1px solid ${primaryColor};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: ${fontSize};
  padding: 4px 8px;
`

export const UpdateEquipmentSelectedText = styled.span`
  font-size: ${fontSize};
  text-transform: uppercase;
`
