import React, { useCallback, useMemo, useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Badge, Icon, IconButton, Tooltip } from '@material-ui/core'

import { generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Marker, MarkerGreen, MarkerOrange, MarkerRed } from 'ui/atoms/Marker'
import MultilineText from 'ui/atoms/MultilineText'
import { TableRow, TableCell } from 'ui/atoms/Table'
import TableActions from 'ui/atoms/TableActions'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'

const LabMarker = styled(Marker)`
  ${props => ['FINISHED'].indexOf(props.type) >= 0 && MarkerGreen}

  ${props =>
    [
      'PENDING_APPROVAL',
      'COMPLETION_PENDING',
      'CONFIRM_USAGE',
      'DECLARE_USE'
    ].indexOf(props.type) >= 0 && MarkerOrange}

  ${props => ['REFUSED', 'CANCELED'].indexOf(props.type) >= 0 && MarkerRed}
`

const StatusCell = styled(TableCell)`
  max-width: 90px;
  font-weight: 600 !important;
`
function Row({ item, onRowAction: handleRowAction, hideActions }) {
  const { translate } = useContext(GlobalizationContext)

  const { id } = item

  const redirectUrl = useCallback(
    actionItem => {
      switch (actionItem) {
        case 'ANALYSE':
          return generatePrivatePath(REQUEST_SHARING_ROUTES.ANALYZE, {
            pathParams: { id }
          })
        case 'EDIT':
          return generatePrivatePath(REQUEST_SHARING_ROUTES.EDIT, {
            pathParams: { id }
          })
        case 'VIEW':
          return generatePrivatePath(REQUEST_SHARING_ROUTES.DETAILS, {
            pathParams: { id }
          })
        case 'DIRECT_COMMUNICATION':
          return generatePrivatePath(
            REQUEST_SHARING_ROUTES.DETAILS_COMMUNICATION,
            {
              pathParams: { id }
            }
          )
        default:
          return null
      }
    },
    [id]
  )

  const rowActions = useMemo(() => {
    return hideActions
      ? []
      : [].concat(
          item.actions.map(actionItem => ({
            code: actionItem,
            name: `LIST.ACTIONS.${actionItem}`,
            link: redirectUrl(actionItem)
          }))
        )
  }, [hideActions, item, redirectUrl])

  const onRowAction = useCallback(
    action => {
      handleRowAction(action, item)
    },
    [handleRowAction, item]
  )

  return (
    <TableRow>
      <TableCell>
        <LabMarker type={item.situation} />
      </TableCell>
      <TableCell>{item.code}</TableCell>
      <TableCell>
        <MultilineText maxLines={2}>
          {item.solicitationTitle || ''}
        </MultilineText>
      </TableCell>
      <TableCell>{`${item.requesterFirstName} ${item.requesterLastName}`}</TableCell>
      <TableCell>
        {`${item.laboratory.initials} - ${item.laboratory.name}`}
      </TableCell>
      <TableCell>{item.createdDate}</TableCell>
      <StatusCell type={item.situation}>
        {item.situation ? translate(`SITUATION.${item.situation}`) : null}
      </StatusCell>

      {!hideActions ? (
        <>
          <TableCell
            style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'right' }}
          >
            <Tooltip title={translate('LIST.ACTIONS.DIRECT_COMMUNICATION')}>
              <IconButton
                component={Link}
                to={redirectUrl('DIRECT_COMMUNICATION')}
              >
                <Badge
                  variant="dot"
                  color="error"
                  invisible={
                    item.isRequester
                      ? !item.hasRequesterMessage
                      : !item.hasResponsibleMessage
                  }
                >
                  <Icon
                    className="fas fa-comment-alt"
                    fontSize="large"
                    color={item.hasMessage ? 'primary' : 'disabled'}
                    style={{ fontSize: '20px' }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell style={{ width: '10px' }}>
            <TableActions
              actionList={rowActions}
              handleActionSelected={onRowAction}
            />
          </TableCell>
        </>
      ) : null}
    </TableRow>
  )
}
export default Row
