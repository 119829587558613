export default class LaboratoryListUseCases {
  constructor({ AdminService, notificationUseCases }) {
    this.adminService = AdminService
    this.notificationUseCases = notificationUseCases
  }

  async getAll(params) {
    return await this.adminService.getAllLaboratories(params)
  }
}
