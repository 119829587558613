import React, { useEffect, useState, useCallback, useContext } from 'react'
import MapGL, { Marker, NavigationControl } from 'react-map-gl'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Icon, IconButton, Link, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import LaboratoryService from 'api/LaboratoryService'
import { phone } from 'helpers/generalInputMasks'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Param } from 'ui/atoms/PaginatedList/style'
import RequestSharing from 'ui/atoms/RequestSharing'
import TagsList from 'ui/atoms/TagsList'
import { Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'
import Carroussel from 'ui/molecules/commons/Carroussel'
import useStyles from 'ui/styles'

import { breadcrumbUseCases } from 'views/App/Fragments/PublicRoutes/providers'

import List from './Fragments/List'

export const PCustom = styled.p`
  margin: 0;
  display: inline-block;
  margin-left: 27px;
  font-weight: 600;
  margin-right: 5px;
  font-size: 14px;
`

export const Text = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  display: inline-block;
  border: 0;
  outline: none;
  padding: 0 28px;
  border: 1px solid transparent;

  ${PCustom} + & {
    padding-left: 8px;
  }
`

const rowsPerPageList = [10, 20, 50, 100]

const DetailLaboratory = () => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  // @ts-ignore
  const { id: laboratoryId } = useParams()

  const [viewport, setViewPort] = useState({})
  const [marker, setMarker] = useState({})
  const [showMap, setShowMap] = React.useState(false)

  const [laboratory, setLaboratory] = useState(null)

  const [pageSize, setPageSize] = useState(rowsPerPageList[0])
  const [count, setCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [pagination, setPagination] = useState({})
  const [equipmentList, setEquipmentList] = useState([])

  function controlMap() {
    setShowMap(!showMap)
  }

  useEffect(() => {
    breadcrumbUseCases.setBreadcrumbData({})

    window.scrollTo(0, 0)
  }, [])

  const getLaboratory = useCallback(async () => {
    const laboratory = await LaboratoryService.getByIdPublic(laboratoryId)
    breadcrumbUseCases.setBreadcrumbData(laboratory)
    setLaboratory(laboratory)

    setViewPort({
      width: '100%',
      height: '400px',
      latitude: parseFloat(laboratory.latitude),
      longitude: parseFloat(laboratory.longitude),
      zoom: 14
    })

    setMarker({
      latitude: parseFloat(laboratory.latitude),
      longitude: parseFloat(laboratory.longitude)
    })
  }, [laboratoryId])

  const getEquipmentList = useCallback(async () => {
    const equipmentList =
      await LaboratoryService.getEquipmentListByLaboratoryPublic(
        laboratoryId,
        pageSize,
        pageNumber
      )

    setEquipmentList(equipmentList.content || [])
    setPagination(equipmentList.pageable)
    setCount(equipmentList.totalElements)
    setPageSize(equipmentList.size)
  }, [laboratoryId, pageSize, pageNumber])

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
  }

  const handleRowChange = () => {
    getEquipmentList()
  }

  const handleBack = () => {
    window.history.back()
  }

  return (
    <AsyncLoad promiseFn={getLaboratory} minHeight={150}>
      <ContentContainer>
        <Title
          item
          xs={12}
          $noPaddingBottom
          onBack={handleBack}
          action={
            laboratory?.hasSharing ? (
              <Param $situationParam $iconPrimary>
                <Icon className="icon fas fa-share-alt-square" />
                {translate('SHARING:SHARING_ENABLED')}
              </Param>
            ) : null
          }
        >
          {`${laboratory?.initials || ''} - ${laboratory?.name || ''}`}
        </Title>
        <Box pb={3}>
          <Typography>
            {`${laboratory?.institutionInitials || ''} - ${
              laboratory?.socialReason || ''
            }`}
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {laboratory?.imgUrlList && laboratory?.imgUrlList.length > 0 && (
            <Grid item xs={12}>
              <Carroussel
                images={laboratory?.imgUrlList}
                dots={true}
                infinite={true}
                slidesToShow={1}
              />
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            spacing={1}
            style={{ paddingBottom: '30px' }}
          >
            <Title
              variant="subtitle1"
              $noBorder
              $noPaddingBottom
              item
              xs={12}
              action={
                <Typography variant="body1" noWrap>
                  {`${translate('COMMONS:CREATION_DATE')}: ${
                    laboratory?.createdDate || '-'
                  }`}
                </Typography>
              }
            >
              {translate('ABOUT_LAB')}
            </Title>
            <Grid item xs={12}>
              <Text>{laboratory?.about}</Text>
            </Grid>
          </Grid>

          {laboratory?.address ? (
            <Grid
              container
              item
              xs={12}
              spacing={1}
              style={{ paddingBottom: '30px' }}
            >
              <Title
                variant="subtitle1"
                $noBorder
                $noPaddingBottom
                item
                xs={12}
              >
                {translate('ADRESS')}
              </Title>
              <Grid item xs={12}>
                <Text>{laboratory?.address.street}</Text>
              </Grid>
              <Grid item xs={12}>
                <Text>{`${laboratory?.address.district} - ${laboratory?.address.city} - ${laboratory?.address.state}`}</Text>
              </Grid>
              <Grid item xs={12}>
                <Text>{`${translate('ZIP_CODE')} ${
                  laboratory?.address.zipCode
                }`}</Text>
              </Grid>
              <Grid item xs={12}>
                <Text>
                  <Link variant="body2" onClick={controlMap}>
                    {translate('LOCATION')}
                  </Link>
                </Text>
              </Grid>
              <Grid item xs={12}>
                {showMap ? (
                  <MapGL
                    {...viewport}
                    mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
                    onViewportChange={viewport => setViewPort(viewport)}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        padding: '10px'
                      }}
                    >
                      <NavigationControl />
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: '8px'
                      }}
                    >
                      <IconButton
                        className="fas fa-times"
                        style={{ color: 'white' }}
                        onClick={controlMap}
                      />
                    </div>
                    <Marker
                      longitude={marker.longitude}
                      latitude={marker.latitude}
                      draggable={false}
                    >
                      <div className={classes.marker}></div>
                    </Marker>
                  </MapGL>
                ) : null}
              </Grid>
            </Grid>
          ) : null}

          {laboratory?.contact ? (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              style={{ paddingBottom: '30px' }}
            >
              <Title
                variant="subtitle1"
                $noBorder
                $noPaddingBottom
                item
                xs={12}
              >
                {translate('CONTACT')}
              </Title>
              <Grid item xs={12}>
                <PCustom>{translate('RESPONSIBLE')}:</PCustom>
                <Text>{laboratory?.contact.name}</Text>
              </Grid>
              <Grid item xs={12}>
                <PCustom>{translate('TELEPHONE')}:</PCustom>
                <Text>
                  {laboratory?.contact.phone
                    ? phone(laboratory?.contact.phone)
                    : ''}
                </Text>
              </Grid>
              <Grid item xs={12}>
                <PCustom>{translate('EMAIL')}:</PCustom>
                <Text>{laboratory?.emailLab}</Text>
              </Grid>
              <Grid item xs={12}>
                <PCustom>{translate('WEBSITE')}:</PCustom>
                <Text>{laboratory?.website}</Text>
              </Grid>
            </Grid>
          ) : null}

          {laboratory?.areaExpertiseList || laboratory?.techniqueList ? (
            <Grid container item xs={12} style={{ paddingBottom: '30px' }}>
              {laboratory?.areaExpertiseList && (
                <TagsList
                  title="AREA_EXPERTISE"
                  list={laboratory?.areaExpertiseList}
                />
              )}
              {laboratory?.techniqueList && (
                <TagsList title="TECHNIQUE" list={laboratory?.techniqueList} />
              )}
            </Grid>
          ) : null}

          <Grid container item xs={12} style={{ paddingBottom: '30px' }}>
            <Title variant="subtitle1" $noBorder $noPaddingBottom item xs={12}>
              {translate('EQUIPMENT')}
            </Title>
            <Grid item xs={12}>
              <List
                classes={classes}
                rowsPerPageOptions={rowsPerPageList}
                count={count}
                rowsPerPage={pageSize}
                page={pageNumber}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                equipmentList={equipmentList}
                emptyList={Boolean(pagination?.empty)}
                onRowChange={handleRowChange}
                promiseFn={getEquipmentList}
              />
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
      {laboratory?.hasSharing ? (
        <RequestSharing item={{ ...laboratory, id: laboratoryId }} type="lab" />
      ) : null}
    </AsyncLoad>
  )
}

export default wrapper(DetailLaboratory, {
  mapDispatch: null,
  mapState: null,
  namespace: 'PUBLIC_LABORATORY_DETAILS'
})
