import React, { useCallback, useContext, useState } from 'react'
import styled from 'styled-components'

import LabEquipService from 'api/LabEquipService'

import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import Wrapper from 'ui/atoms/Wrapper'
import BannerSearch from 'ui/molecules/BannerSearch'
import Carroussel from 'ui/organisms/Carroussel'

import BannerCovid from './Fragments/BannerCovid'
import InfoSection from './Fragments/InfoSection'
import ScienceSection from './Fragments/ScienceSection'

const Background = styled.div`
  background-color: #fafafa;
  padding-bottom: 1px;
`

function Home() {
  const { translate } = useContext(GlobalizationContext)

  const [laboratoryList, setLaboratoryList] = useState([])
  const [equipmentList, setEquipmentList] = useState([])

  const pageSize = 12
  const pageNumber = 0

  const getLaboratoryList = useCallback(async () => {
    try {
      const resp = await LabEquipService.getAssetCarouselList('lab', {
        pageSize,
        pageNumber
      })

      setLaboratoryList(resp.data.content)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getEquipmentList = useCallback(async () => {
    try {
      const resp = await LabEquipService.getAssetCarouselList('equip', {
        pageSize,
        pageNumber
      })

      setEquipmentList(resp.data.content)
    } catch (error) {
      console.log(error)
    }
  }, [])

  return (
    <>
      <div style={{ position: 'relative', width: '100%' }}>
        <BannerSearch />
        {/* <BannerCovid /> */}
      </div>
      <Background>
        <ContentContainer>
          <InfoSection />
        </ContentContainer>
      </Background>
      <Background>
        <ContentContainer>
          <ScienceSection />
        </ContentContainer>
      </Background>
      <Background>
        <ContentContainer>
          <Carroussel
            title="LABS"
            items={laboratoryList || []}
            history="LAB"
            promiseFn={getLaboratoryList}
            subtitle={translate('LAB_SUBTITLE')}
          />
        </ContentContainer>
      </Background>
      <ContentContainer>
        <Carroussel
          title="EQUIPMENT"
          items={equipmentList || []}
          history="EQUIP"
          promiseFn={getEquipmentList}
          subtitle={translate('EQUIP_SUBTITLE')}
        />
      </ContentContainer>
    </>
  )
}

export default Wrapper(Home, {
  namespace: 'HOME',
  mapState: null,
  mapDispatch: null
})
