import React, { useContext } from 'react'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import TableHeadComponent from './TableHeader'
import TableRowComponent from './TableRow'

const RepresentativeList = ({
  disabled,
  representants,
  resendInvite,
  setRepresentants,
  showWarning
}) => {
  const { translate } = useContext(GlobalizationContext)

  const updateRepresentant = updatedRepresentant => {
    setRepresentants(updatedRepresentant)
  }

  return (
    <TableContainer component={Paper} style={{ marginTop: '32px' }}>
      <Table aria-label={translate('REPRESENTATIVE_LIST.ARIA_LABEL')}>
        <TableHeadComponent />
        <TableBody>
          {representants?.map((row, index) => (
            <TableRowComponent
              index={index}
              key={index}
              row={row}
              showWarning={showWarning}
              updateRepresentant={updateRepresentant}
              resendInvite={resendInvite}
              disabled={disabled}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RepresentativeList
