import React, { useContext } from 'react'

import capitalize from 'lodash/capitalize'

import { TableChip } from 'ui/atoms/Chip'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { InfoMessage } from 'ui/atoms/Messages'
import useStyles from 'ui/styles'

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  StatusMarker,
  MarkerContainer
} from './style'

const UserData = ({ permissions }) => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const getPermission = item => {
    const permission = item.split('_').slice(-1)
    return capitalize(permission)
  }

  const getPermissionType = item => {
    const [, type] = item.split('_')
    return type
  }

  const getUnitName = item => {
    return `${item.initials} - ${item.labId ? item.nameLab : item.nameInst}`
  }

  return (
    <>
      <h3 className={classes.h3}>{translate('PERMISSIONS_TITLE')}</h3>

      {permissions.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{translate('INSTITUTION')}</TableCell>
                <TableCell>{translate('INSTITUTION_TYPE')}</TableCell>
                <TableCell>{translate('PERMISSION')}</TableCell>
                <TableCell>{translate('STATUS')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions.map((permission, index) => (
                <TableRow
                  key={`${permission.nameInst}_${permission.nameLab}_${permission.permission}_${permission.situation}_${permission.type}`}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <MarkerContainer>
                      <StatusMarker type={permission.situation} />
                      {getUnitName(permission)}
                    </MarkerContainer>
                  </TableCell>
                  <TableCell>
                    <span>{translate(getPermissionType(permission.type))}</span>
                    {permission.unique_responsible ? (
                      <TableChip
                        size="small"
                        label={translate('ONLY_RESPONSIBLE')}
                      />
                    ) : permission.principal_responsible ? (
                      <TableChip
                        size="small"
                        label={translate('MAIN_RESPONSIBLE')}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {translate(getPermission(permission.permission))}
                  </TableCell>
                  <TableCell>
                    {permission.situation
                      ? translate(
                          `LABORATORY:SITUATION.${permission.situation}`
                        )
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
              {!permissions.length ? (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    {translate('NO_PERMISSIONS')}
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <InfoMessage>{translate('NO_PERMISSIONS')}</InfoMessage>
      )}
    </>
  )
}

export default UserData
