import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as Yup from 'yup'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'

import { primaryColor } from 'helpers/constants'
import { disableSubmitOnEnter } from 'helpers/disableSubmitOnEnter'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import RadioGroup from 'ui/atoms/RadioGroup'
import DefaultHint from 'ui/molecules/commons/DefaultHint'
import useStyles from 'ui/styles'

const PermissionRadioGroup = styled(RadioGroup)`
  &.MuiFormControl-root {
    margin-bottom: 0;
  }
`

const InviteSchema = Yup.object().shape({
  name: Yup.string(),
  invite_email: Yup.string().required('REQUIRED_ERROR').email('Email inválido'),
  rolesType: Yup.string().oneOf(
    [
      'ROLE_INSTITUICAO_TOTAL',
      'ROLE_INSTITUICAO_PARCIAL',
      'ROLE_INSTITUICAO_LIMITADA'
    ],
    'REQUIRED_ERROR'
  )
})

const roleList = [
  {
    id: 'ROLE_INSTITUICAO_TOTAL',
    label: 'ROLES.TOTAL',
    hint: 'ROLES.TOTAL_HINT'
  },
  {
    id: 'ROLE_INSTITUICAO_PARCIAL',
    label: 'ROLES.PARTIAL',
    hint: 'ROLES.PARTIAL_HINT'
  },
  {
    id: 'ROLE_INSTITUICAO_LIMITADA',
    label: 'ROLES.LIMITED',
    hint: 'ROLES.LIMITED_HINT'
  }
]

const InviteModal = ({ email, onInvite, onClose }) => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const resolver = useYupValidationResolver(InviteSchema, { translate })
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {
      invite_email: email
    }
  })

  const onSubmit = formData => {
    const invite = {
      id: 0,
      name: formData.name,
      email: formData.invite_email,
      permission: formData.rolesType,
      situation: 'PENDING'
    }

    onInvite(invite)
  }

  return (
    <Dialog maxWidth={'md'} fullWidth open={true} onClose={onClose}>
      <DialogContent style={{ paddingBottom: '20px' }}>
        <form onSubmit={handleSubmit(onSubmit)} {...disableSubmitOnEnter}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h3 className={classes.h3}>{translate('INVITE_MODAL.TITLE')}</h3>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <BaseTextField
                required={true}
                readOnly
                label={translate('INVITE_MODAL.EMAIL')}
                placeholder={translate('INVITE_MODAL.EMAIL')}
                errors={errors}
                control={control}
                name="invite_email"
              ></BaseTextField>
            </Grid>
            <Grid item xs={12} md={5}>
              <BaseTextField
                label={translate('INVITE_MODAL.NAME')}
                placeholder={translate('INVITE_MODAL.NAME')}
                errors={errors}
                control={control}
                name="name"
              ></BaseTextField>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ paddingTop: '1.5rem' }}>
            <Grid item xs={12} md={12}>
              <label className={classes.label}>
                {translate('INVITE_MODAL.PERMISSION')}
              </label>
              <div
                className={
                  errors.rolesType
                    ? classes.permissionContainerError
                    : classes.permissionContainer
                }
              >
                <PermissionRadioGroup
                  control={control}
                  name="rolesType"
                  options={roleList}
                  columns={roleList.length}
                  labelRenderer={option => (
                    <>
                      {translate(option.label)}
                      <DefaultHint
                        icon="fas fa-info-circle"
                        color={primaryColor}
                        content={option.hint}
                      />
                    </>
                  )}
                />
              </div>
              {errors.rolesType && (
                <div className={'permission-error-container'}>
                  <FormHelperText error>
                    {errors.rolesType.message}
                  </FormHelperText>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={3}
            style={{
              paddingTop: '1.5rem',
              flexWrap: 'initial',
              justifyContent: 'flex-end'
            }}
          >
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                style={{ float: 'left' }}
                className={classes.baseButtonGrey}
                type={'button'}
                variant="contained"
                onClick={onClose}
              >
                {translate('COMMONS:CANCEL')}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{
                maxWidth: '18%',
                flexBasis: '18%'
              }}
            >
              <Button
                className={classes.baseButton}
                type="submit"
                variant="contained"
              >
                {translate('INVITE_MODAL.ADD_INVITE')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default InviteModal
