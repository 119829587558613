import styled, { css } from 'styled-components'

import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MaterialAutocomplete from '@material-ui/lab/Autocomplete'

export const AutocompletePaper = styled(Paper)`
  .MuiAutocomplete-listbox {
    margin: 0;
    padding: 0;
  }

  .MuiAutocomplete-option {
    padding: 0;

    &[aria-disabled='true'] {
      opacity: 1;
    }
  }
`

export const AutocompletePopper = styled(Popper)``

export const Autocomplete = styled(MaterialAutocomplete).attrs({
  PopperComponent: AutocompletePopper,
  PaperComponent: AutocompletePaper
})`
  .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
    padding-top: 0;
  }
`

export const OptionContainer = styled.div`
  display: flex;
  width: 100%;

  ${props =>
    props.disabled &&
    css`
      background: #cccccc;
    `}
`

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 5px 15px;

  .name {
    font-weight: 600;
    font-size: 14px;
  }

  .email {
    font-size: 12px;
  }
`

export const OptionSituation = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`
