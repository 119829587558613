// @ts-nocheck
import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AddedDocuments, { previewFile } from 'ui/atoms/AddedDocuments'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const ResponsibleData = ({ data, responsibleDocuments }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <>
      <Typography variant="subtitle1" style={{ marginTop: '32px' }}>
        {translate('RESPONSIBLE_DATA')}
      </Typography>

      <Grid container spacing={3} style={{ marginTop: '22px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            label="CPF"
            name="responsibleCPF"
            value={data?.cpf || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Nome"
            name="responsibleName"
            value={data?.firstName || ''}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Sobrenome"
            name="responsibleLastName"
            value={data?.lastName || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '24px' }}>
        <Grid item xs={12} md={4}>
          <BaseTextField
            required={true}
            disabled={true}
            placeholder="Informe"
            label="Email Institucional"
            name="responsibleEmail"
            value={data?.email || ''}
          />
        </Grid>
      </Grid>
      <Typography variant="subtitle2" style={{ marginTop: '32px' }}>
        {translate('INSTITUTION_BOND_TEXT')}
      </Typography>

      <AddedDocuments docs={responsibleDocuments} viewFile={previewFile} />
    </>
  )
}

export default ResponsibleData
