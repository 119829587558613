import clsx from 'clsx'
import React from 'react'
import styled, { css } from 'styled-components'

import { Icon, IconButton } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { primaryColor } from 'helpers/constants'

/**
 * @param {object} props
 * @param {boolean} props.$noPaddingBottom
 * @param {boolean} props.$paddingTop
 * @param {boolean} props.$noBorder
 * @param {boolean} props.gutterBottom
 * @param {import('@material-ui/core/styles/createTypography').Variant} props.variant
 * @param {import('@material-ui/core/Typography/index').TypographyProps} props.TypographyProps
 */
const _Title = ({
  WrapperComponent = Grid,
  variant = 'h2',
  gutterBottom = true,
  TypographyProps = {},
  action = null,
  onBack,
  children,
  ...props
}) => {
  return (
    <WrapperComponent
      className={clsx('title-wrapper', props.className)}
      {...props}
    >
      <Typography
        variant={variant}
        gutterBottom={gutterBottom}
        {...TypographyProps}
      >
        <Box display="flex" flexDirection="row">
          {onBack ? (
            <Box display="flex" alignItems="center" paddingRight={1}>
              <IconButton color="primary" onClick={onBack}>
                <Icon className="fas fa-arrow-left" />
              </IconButton>
            </Box>
          ) : null}
          <Box flexGrow={1} display="flex" alignItems="center">
            {children}
          </Box>
          {action ? (
            <Box display="flex" alignItems="center">
              {action}
            </Box>
          ) : null}
        </Box>
      </Typography>
      <hr className="border-bottom" />
    </WrapperComponent>
  )
}

export const Title = styled(_Title)`
  ${props => css`
    && {
      padding-bottom: 4px;
    }

    ${props.$noPaddingBottom &&
    css`
      && {
        padding-bottom: 0;
      }
    `}

    ${props.$paddingTop &&
    css`
      margin-top: 30px;
    `}

    ${props.$noBorder &&
    css`
      .border-bottom {
        display: none;
      }
    `}
  `}

  .title-wrapper {
    padding-bottom: 0;
  }

  .border-bottom {
    border: 0;
    height: 1px;
    background-color: ${primaryColor};
  }
`

const defaultSubtitleProps = {
  variant: 'subtitle1',
  $noBorder: true,
  $paddingTop: false,
  $noPaddingBottom: false
}

export const Subtitle = styled(Title).attrs(props => ({
  ...defaultSubtitleProps,
  ...props
}))``
