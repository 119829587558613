export default class UserListUseCases {
  constructor({ AdminService, UsersService, notificationUseCases }) {
    this.adminService = AdminService
    this.usersService = UsersService
    this.notificationUseCases = notificationUseCases
  }

  async getAllUsers(params) {
    return await this.adminService.getAllUsers(params)
  }

  async getUserSituations() {
    return await this.usersService.getUserSituations()
  }

  async changeSituation(id, newSituation) {
    try {
      await this.adminService.changeSituation(id, newSituation)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
    } catch (error) {
      this.notificationUseCases.newError(error)
    }
  }

  async deleteUser(id, reason) {
    const formData = new FormData()
    const json = JSON.stringify({ description: reason.details })
    const details = new Blob([json], {
      type: 'application/json'
    })

    formData.append('reason', details)
    if (reason.files[0]?.file) {
      formData.append('file', reason.files[0]?.file)
    }

    try {
      await this.adminService.deleteUser(id, formData)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
    } catch (error) {
      this.notificationUseCases.newError(error)
    }
  }

  async resendPassword(id) {
    try {
      await this.adminService.resendPassword(id)
      this.notificationUseCases.newSuccess('DEFAULT_SUCCESS')
    } catch (error) {
      this.notificationUseCases.newError()
    }
  }

  async hasRequestPending(id) {
    try {
      await this.adminService.hasRequestPending(id)
      return false
    } catch (error) {
      if (error.response?.data?.message === 'request.sharing.found') {
        return true
      }
      throw error
    }
  }
}
