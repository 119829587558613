import * as Yup from 'yup'

export default Yup.object().shape({
  specificEquipment: Yup.array()
    .of(Yup.object())
    .required('VALIDATION:REQUIRED_ERROR'),
  responsible: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
  suggestedDateFrom: Yup.date()
    .nullable()
    .typeError('VALIDATION:INVALID_DATE')
    .min(new Date(), 'VALIDATION:GREATER_OR_EQUAL_THAN_TODAY_ERROR')
    .required('VALIDATION:REQUIRED_ERROR'),
  suggestedDateTo: Yup.date()
    .nullable()
    .typeError('VALIDATION:INVALID_DATE')
    .required('VALIDATION:REQUIRED_ERROR')
    .min(new Date(), 'VALIDATION:GREATER_OR_EQUAL_THAN_TODAY_ERROR')
    .greaterThan('suggestedDateFrom')
})
