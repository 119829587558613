import React from 'react'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

import { sm } from 'helpers/constants'

import { AsyncLoad } from 'ui/atoms/AsyncLoad'
import Title from 'ui/atoms/Carroussel/Title'
import CarrousselItem from 'ui/molecules/Carroussel'

const WrapCarroussel = styled(Grid)`
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
  @media (max-width: ${sm}) {
    padding: 0 10px;
  }
`

const Subtitle = styled.p`
margin-bottom: 40px;
`

const backgroundGreyStyle = {
  backgroundColor: '#fafafa',
  overflow: 'hidden'
}

const Carroussel = ({
  backgroundGrey,
  title,
  items,
  history,
  subtitle,
  promiseFn = async () => {}
}) => {
  return (
    <div style={backgroundGrey ? backgroundGreyStyle : null}>
      <Title title={title} history={history} />
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <AsyncLoad promiseFn={promiseFn} minHeight={550}>
        <WrapCarroussel item xs={12}>
          <CarrousselItem items={items} history={history} />
        </WrapCarroussel>
      </AsyncLoad>
    </div>
  )
}
export default Carroussel
