import React, { useContext } from 'react'
import { Trans } from 'react-i18next'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import wrapper from 'ui/atoms/Wrapper'

import {
  NotificationContainer,
  NotificationTitle,
  NotificationTitleDivider,
  NotificationMessage,
  NotificationAction,
  NotificationTitleContainer,
  NotificationQuantity,
  NotificationMessageContainer
} from './style'

const GenericNotificationCmp = ({
  titleKey = '',
  messageKey = '',
  message = null,
  notification,
  showTitle = false,
  quantity,
  onClick = () => {},
  asList,
  children
}) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <NotificationContainer
      onClick={onClick}
      className="notification-container"
      asList={asList}
    >
      {showTitle && (
        <>
          <NotificationTitleContainer className="title-container">
            <NotificationTitle className="title-container-title">
              {translate(titleKey)}
            </NotificationTitle>
            {quantity && (
              <NotificationQuantity className="title-container-quantity">
                {quantity}
              </NotificationQuantity>
            )}
          </NotificationTitleContainer>
          <NotificationTitleDivider className="title-container-divider" />
        </>
      )}
      <NotificationMessageContainer className="message-container">
        {messageKey ? (
          <NotificationMessage className="message-container-message">
            <Trans t={translate} i18nKey={messageKey} values={notification} />
          </NotificationMessage>
        ) : null}
        {message}
        {!showTitle && quantity && (
          <NotificationQuantity className="message-container-quantity">
            {quantity}
          </NotificationQuantity>
        )}
      </NotificationMessageContainer>
      {children && (
        <NotificationAction className="actions-container">
          {children}
        </NotificationAction>
      )}
    </NotificationContainer>
  )
}

const GenericNotification = wrapper(GenericNotificationCmp, {
  namespace: 'NOTIFICATIONS'
})

export { GenericNotification }
