// @ts-nocheck
import React, { useContext, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { fileUploadConfig } from 'helpers/constants'

import AddedDocuments from 'ui/atoms/AddedDocuments'
import BaseSelect from 'ui/atoms/BaseSelect'
import DocumentUploader from 'ui/atoms/DocumentUploader'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { WarningMessage } from 'ui/atoms/Messages'

import { Label, Text, TextContainer } from '../../styles'

const ResponsibleData = ({
  documents,
  formMethods,
  onChangeResponsible,
  onRemoveFile,
  onSelectFiles,
  user,
  userList
}) => {
  const { translate } = useContext(GlobalizationContext)
  const { control, errors, setValue } = formMethods

  useEffect(() => {
    setValue('responsibleSelect', user?.id || '')
  })

  const selectNewResponsible = event => {
    const newResponsible = userList.find(user => {
      return user.id === event.target.value
    })

    onChangeResponsible(newResponsible)
  }

  return (
    <>
      <Typography variant="subtitle1" style={{ marginBottom: '24px' }}>
        {translate('REGISTER_RESPONSIBLE_DATA')}
      </Typography>

      <WarningMessage>
        {translate('REGISTER_RESPONSIBLE_WARNING_MESSAGE')}
      </WarningMessage>

      <Typography variant="subtitle2" style={{ margin: '20px 0 8px 0' }}>
        {translate('REGISTER_RESPONSIBLE_NAME')}
      </Typography>
      <Grid item xs={12} md={6}>
        <BaseSelect
          required={true}
          placeholder={translate('REQUEST_OPINION_SELECT')}
          errors={errors}
          control={control}
          name="responsibleSelect"
          options={userList}
          handleChange={selectNewResponsible}
        ></BaseSelect>
      </Grid>

      <TextContainer>
        <Label>{translate('REGISTER_INSTITUTION_REPRESENTATIVES_EMAIL')}</Label>
        <Text>{user?.email}</Text>

        <Label>{translate('REGISTER_INSTITUTION_REPRESENTATIVES_CPF')}</Label>
        <Text>{user?.cpf}</Text>
      </TextContainer>

      <Grid container style={{ marginTop: '40px' }}>
        <DocumentUploader
          label={'REGISTER_INSTITUTION_BOND_TEXT'}
          wrapperDescription={'REGISTER_DOCUMENT_UPLOAD'}
          onChange={onSelectFiles}
          currentListLength={documents.length}
          filesConfig={fileUploadConfig.institution}
        />
        <AddedDocuments docs={documents} removeFile={onRemoveFile} />
      </Grid>
    </>
  )
}

export default ResponsibleData
