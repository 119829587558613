import React, { useContext } from 'react'

import { Grid } from '@material-ui/core'

import BaseTextFieldPublic from 'ui/atoms/BaseTextFieldPublic.js'
import { GlobalizationContext } from 'ui/atoms/Globalization'

const BodyFilters = ({ onInputChange }) => {
  const { translate } = useContext(GlobalizationContext)

  return (
    <Grid
      container
      spacing={3}
      style={{ marginBottom: '20px' }}
      alignItems="baseline"
    >
      <Grid item xs={12}>
        <BaseTextFieldPublic
          id="FILTER_TEXT"
          placeholder={translate('SEARCH_DEFAULT')}
          handleChange={onInputChange}
        />
      </Grid>
    </Grid>
  )
}

export default BodyFilters
