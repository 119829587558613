import isFunction from 'lodash/isFunction'
import React from 'react'
import { Async } from 'react-async'

import { DefaultLoader } from 'ui/atoms/AsyncLoad/DefaultLoader'

export const AsyncLoad = ({
  children,
  color = '',
  minHeight,
  ...promiseParams
}) => {
  return (
    <Async {...promiseParams}>
      <Async.Pending>
        <DefaultLoader color={color} minHeight={minHeight} />
      </Async.Pending>
      <Async.Fulfilled>
        {response => (isFunction(children) ? children(response) : children)}
      </Async.Fulfilled>
      <Async.Rejected>
        {error => (isFunction(children) ? children(error) : children)}
      </Async.Rejected>
    </Async>
  )
}
