import axios from './axios'

export const AdvertisePlatform = {
  sendAdvertise(AdvertiseData) {
    return axios.post('/disclosePlatform/send', AdvertiseData)
  },
  resendById(id) {
    return axios.post(`/disclosePlatform/${id}/resend`)
  },
  getAll() {
    return axios.get('/disclosePlatform')
  },
  getFiltered({ term, size, page }, requestOptions = {}) {
    return axios.get('/disclosePlatform', {
      ...requestOptions,
      params: {
        size,
        term,
        page
      }
    })
  },
  canSend() {
    return axios.get('/disclosePlatform/can-send')
  }
}

export default AdvertisePlatform
