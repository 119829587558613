import axios from './axios'

export const UsersService = {
  getMe() {
    return axios.get('/users/me').then(resp => resp.data)
  },

  getToken(code) {
    return axios
      .get('/auth/token', { params: { code } })
      .then(resp => resp.data)
  },

  requestNewPassword(userData) {
    return (
      axios
        // .post("users/reset-password", { cpfOrEmail: user })
        .post('users/reset-password', userData)
        .then(resp => resp.data)
    )
  },

  getCpfFromToken(token) {
    return axios
      .get(`users/reset-password/${token}/user`)
      .then(resp => resp.data)
  },

  changePassword(formData) {
    return axios.post('users/change-password', formData).then(resp => resp.data)
  },

  registerUser(userData) {
    return axios.post('/users', userData)
  },

  getPermissionFilters() {
    return axios
      .get('/users/permission/filter', { skipLoader: true })
      .then(resp => resp.data)
  },

  getInstitution() {
    return axios.get('/users/institution/form').then(resp => resp.data)
  },

  getProfiles() {
    return axios.get('/users/permissions').then(resp => resp.data)
  },

  getMyProfile() {
    return axios.get(`/users/my-data`).then(resp => resp.data)
  },

  setMyProfile(postData) {
    return axios.put(`/users/advanced`, postData).then(resp => resp.data)
  },

  setMyProfileImage(profileImage) {
    var formData = new FormData()
    formData.append('file', profileImage, profileImage.name)
    formData.append('cropImage', profileImage)
    formData.append('positionX', '0')
    formData.append('positionY', '0')
    return axios.post(`users/profile-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  getUserSituations() {
    return axios.get('users/situation').then(resp => resp.data)
  },

  putTermOfUse(userId) {
    return axios
      .put(`users/sign-terms-of-use/${userId}/update`)
      .then(resp => resp.data)
  }
}

export default UsersService
