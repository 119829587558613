import React, { useMemo } from 'react'
import {
  Link,
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom'

import { Box } from '@material-ui/core'

import { mountPrivatePath } from 'helpers'

import { useGlobalizationContext } from 'ui/atoms/Globalization'
import { Tab, Tabs } from 'ui/atoms/Tabs'
import wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeadeder from 'ui/molecules/BaseContainerHeader'
import Loader from 'ui/molecules/Loader'

import { ADMIN_ROUTES } from './constants/routes'
import { ROUTES } from './routes'

const TAB_ROUTES = [
  { key: 'USERS', route: mountPrivatePath(ADMIN_ROUTES.USERS) },
  { key: 'INSTITUTION', route: mountPrivatePath(ADMIN_ROUTES.INSTITUTIONS) },
  { key: 'LABORATORY', route: mountPrivatePath(ADMIN_ROUTES.LABORATORIES) }
]

const Admin = () => {
  const location = useLocation()
  const { translate } = useGlobalizationContext()

  const currentTab = useMemo(() => {
    const tab = TAB_ROUTES.find(tab => {
      const match = matchPath(location.pathname, {
        path: tab.route,
        exact: true
      })

      return match
    })

    return tab?.route
  }, [location.pathname])

  return (
    <>
      <Loader />

      {currentTab && (
        <>
          <BaseContainerHeadeder
            label={translate('TITLE')}
            showCloseButton={false}
          />
          <Box component={Tabs} value={currentTab} pb={1}>
            {TAB_ROUTES.map(tab => (
              <Tab
                key={tab.key}
                label={translate(`TABS.${tab.key}`)}
                value={tab.route}
                to={tab.route}
                component={Link}
              />
            ))}
          </Box>
        </>
      )}

      <Switch>
        {ROUTES.map(route => (
          <Route key={route.path} {...route} exact={true} />
        ))}

        {!currentTab && <Redirect to={ROUTES[0].path} />}
      </Switch>
    </>
  )
}

export default wrapper(Admin, { namespace: 'ADMIN' })
