import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'

import { primaryColor } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const WrapTitle = styled(Grid)`
  margin-top: 24px;
`

const TitleStyled = styled(Grid)`
  font-size: 24px;
  color: #333333;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid ${primaryColor};
  padding-bottom: 16px;
  margin: 0;
  display: block;
`

const ButtonStyled = styled(Link)`
  font-weight: 500;
  border: 1px solid #009b7f;
  padding: 7px 16px;
  font-size: 14px;
  border-radius: 2px;
  margin-left: 13px;
  text-transform: none;
  text-decoration: none;
  color: #000000;
  &:hover {
    text-shadow: 1px 0px 1px rgba(255, 255, 255, 0.5);
    background: #009b7f;
    color: #fff;
  }
`

function Title(props) {
  const { translate } = useContext(GlobalizationContext)
  return (
    <>
      <WrapTitle container alignItems="baseline" justify="space-between">
        <TitleStyled item component="h2" md={3} xs={6}>
          {translate(props.title)}
        </TitleStyled>
        <ButtonStyled to={`/search?type=${props.history}`}>
          {translate('SEE_MORE')}
        </ButtonStyled>
      </WrapTitle>
    </>
  )
}
export default Title
