import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { TableRow, TableCell } from 'ui/atoms/Table'
import useStyles from 'ui/styles'

import { Marker } from '../../../styles'

const ActionsCell = styled(TableCell)`
  width: 50px;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const Row = ({ item }) => {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const getCurrentSituationText = () => {
    if (item.situation === 'ACTIVE') return 'Ativo.'
    return item.situation === 'INACTIVE' &&
      item.reasonExclusionInactivationEquip?.name === 'Em manutenção'
      ? translate('SITUATION.MAINTANCE')
      : translate('SITUATION.BROKEN')
  }

  return (
    <>
      <TableRow className={classes.equipmentActive}>
        <TableCell>
          <Tooltip title={getCurrentSituationText()}>
            <Marker // @ts-ignore
              type={
                item.situation === 'INACTIVE'
                  ? item.reasonExclusionInactivationEquip?.name
                  : item.situation
              }
            />
          </Tooltip>
        </TableCell>
        <TableCell>{item.code}</TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell>{item.brand}</TableCell>
        <TableCell>{item.model}</TableCell>

        <ActionsCell>
          <Link to={`/equipment/${item.id}`}>
            <IconButton>
              <VisibilityIcon />
            </IconButton>
          </Link>
        </ActionsCell>
      </TableRow>
    </>
  )
}
export default Row
