import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'

import Typography from '@material-ui/core/Typography'

import AdminService from 'api/AdminService'
import { generatePrivatePath, history } from 'helpers/history'
import useYupValidationResolver from 'helpers/useYupValidationResolver'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import RepresentantsList from 'ui/atoms/RepresentantsList'
import wrapper from 'ui/atoms/Wrapper'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import Loader from 'ui/molecules/Loader'

import { notificationUseCases } from 'views/Notification/providers'

import { ADMIN_ROUTES } from '../../constants/routes'
import formSchema from './formSchema'
import ActionsButton from './Fragments/ActionsButton'
import Analysis from './Fragments/Analysis'
import InstitutionData from './Fragments/InstitutionData'
import ResponsibleData from './Fragments/ResponsibleData'

const InstitutionAnalysis = () => {
  // @ts-ignore
  const { institutionId } = useParams()
  const [institutionData, setInstitutionData] = useState(null)
  const [represantativeList, setRepresantativeList] = useState([])
  const [responsible, setResponsible] = useState(null)
  const [responsibleDocuments, setResponsibleDocuments] = useState([])
  const [documents, setDocuments] = useState([])
  const { translate } = useContext(GlobalizationContext)

  const resolver = useYupValidationResolver(formSchema, { translate })
  const formMethods = useForm({
    mode: 'onBlur',
    resolver
  })

  const initData = useCallback(async () => {
    const institutionData = await AdminService.getInstitution(institutionId)

    setInstitutionData(institutionData?.institution)
    setRepresantativeList(institutionData?.representative_list)
    setResponsible(institutionData?.responsible)
    setResponsibleDocuments(institutionData?.institution.documentList)
  }, [institutionId])

  useEffect(() => {
    initData()
  }, [initData])

  const onCLose = () => {
    history.push(generatePrivatePath(ADMIN_ROUTES.INSTITUTIONS))
  }

  const onSubmit = async formData => {
    const shouldBePartOf = formData.institutionShouldBePartOfPlatform === 'YES'
    const isMaxResponsible = formData.requesterIsMaxResponsible === 'YES'

    const payload = {
      institutionShouldBePartOfPlatform: shouldBePartOf,
      requesterIsMaxResponsible: isMaxResponsible,
      refusalDetail: formData.refusalDetail
    }

    try {
      if (shouldBePartOf && isMaxResponsible) {
        await AdminService.approveInstitutionSolicitation(
          institutionData.cnpj,
          payload
        )
      } else {
        await AdminService.refuseInstitutionSolicitation(
          institutionData.cnpj,
          payload
        )
        await uploadFilesInSequence(
          AdminService.sendRefusalDocument,
          institutionData.cnpj,
          documents
        )
      }
      notificationUseCases.newSuccess(
        translate('MESSAGES.REGISTRATION_SUCCESS')
      )
      onCLose()
    } catch {
      notificationUseCases.newError(translate('MESSAGES.SOLICITATION_ERROR'))
    }
  }

  const uploadFilesInSequence = async (uploadMethod, id, list) => {
    const uploadedFiles = await list.reduce(
      async (promise, { file, ...savedFile }) => {
        return promise
          .then(uploadedFiles => {
            if (savedFile && savedFile.id) {
              return Promise.resolve(uploadedFiles.concat(file))
            } else {
              return uploadMethod(id, file)
                .then(resp => {
                  return uploadedFiles.concat(resp.data)
                })
                .catch(() => {
                  return uploadedFiles.concat(file)
                })
            }
          })
          .then(data => {
            return data
          })
      },
      Promise.resolve([])
    )

    return uploadedFiles
  }

  const onFileChange = fileList => {
    if (!fileList || !fileList.length) return
    setDocuments(documents => documents.concat(fileList))
  }

  const onRemoveFile = file => {
    const remaningFiles = documents.filter(item => item !== file)
    setDocuments(remaningFiles)
  }

  return (
    <>
      <Loader />
      <BaseContainerHeader label={translate('TITLE')} showCloseButton={false} />
      <InstitutionData data={institutionData} />
      <ResponsibleData
        data={responsible}
        responsibleDocuments={responsibleDocuments}
      />

      <Typography variant="subtitle1" style={{ marginTop: '32px' }}>
        {translate('RESPONSIBLE')}
      </Typography>

      <RepresentantsList
        disabled={true}
        representants={represantativeList}
        setRepresentants={setRepresantativeList}
      />
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Analysis
          documents={documents}
          formMethods={formMethods}
          onRemoveFile={onRemoveFile}
          onSelectFiles={onFileChange}
        />
        <ActionsButton handleClose={onCLose} />
      </form>
    </>
  )
}

export default wrapper(InstitutionAnalysis, {
  mapState: null,
  mapDispatch: null,
  namespace: 'INSTITUTION_ANALYSIS'
})
