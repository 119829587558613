import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'phone', function (errorMessage) {
  return this.test('phone', errorMessage, function (phone) {
    const { path, createError } = this
    const sanitizedPhone = phone.replace(/\D/g, '')

    if (!(sanitizedPhone.length >= 10 && sanitizedPhone.length <= 11)) {
      return createError({ path, message: errorMessage })
    }

    return true
  })
})
