import React, { useContext } from 'react'

import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import { GlobalizationContext } from 'ui/atoms/Globalization'

const ConfirmDialog = ({ open, handleCloseDialog, handleConfirm }) => {
  const { translate } = useContext(GlobalizationContext)
  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Typography variant="subtitle2">
          {translate('EQUIPMENT_LIST_CONFIRM_UPDATE_TITLE')}
        </Typography>
        <Typography variant="body2">
          {translate('EQUIPMENT_LIST_CONFIRM_UPDATE_TEXT')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} style={{ color: '#666' }}>
          {translate('COMMONS:CANCEL')}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {translate('COMMONS:CONTINUE')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
