import throttle from 'lodash/throttle'
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { Controller, useWatch } from 'react-hook-form'

import BaseTextField from '../BaseTextField'
import { GlobalizationContext } from '../Globalization'
import Option from './Fragments/Option'
import OptionList from './Fragments/OptionList'
import { Autocomplete as CustomAutocomplete, Loader } from './style'

export * from './style'

function stripDiacritics(string) {
  return typeof string.normalize !== 'undefined'
    ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    : string
}

export function createFilterOptions({
  ignoreAccents = true,
  ignoreCase = true,
  keepSelected = false,
  limit,
  matchFrom = 'any',
  stringify,
  trim = false,
  value,
  multiple,
  getOptionSelected
}) {
  return (options, { inputValue, getOptionLabel }) => {
    let input = trim ? inputValue.trim() : inputValue
    if (ignoreCase) {
      input = input.toLowerCase()
    }
    if (ignoreAccents) {
      input = stripDiacritics(input)
    }
    const filteredOptions = options.filter(option => {
      if (
        keepSelected &&
        (multiple ? value.current : [value.current]).some(
          value2 => value2 !== null && getOptionSelected(option, value2)
        )
      ) {
        return true
      }

      let candidate = (stringify || getOptionLabel)(option)
      if (ignoreCase) {
        candidate = candidate.toLowerCase()
      }
      if (ignoreAccents) {
        candidate = stripDiacritics(candidate)
      }

      return matchFrom === 'start'
        ? candidate.indexOf(input) === 0
        : candidate.indexOf(input) > -1
    })

    return typeof limit === 'number'
      ? filteredOptions.slice(0, limit)
      : filteredOptions
  }
}

const Autocomplete = ({
  control,
  name,
  label,
  placeholder,
  disabled,
  multiple = false,
  defaultValue = multiple ? [] : null,
  minInputLength = 3,
  optionRenderer = null,
  noMinInputLengthText: noMinInputLengthTextProp,
  noOptionsText: noOptionsTextProp,
  clearOnClose = false,
  allowClear = false,
  optionGetter = async () => {},
  createNewLabelRenderer = () => {},
  keepSelected,
  createNew,
  createNewLabelKey,
  AutocompleteProps = null,
  keepInputOnSelect = false,
  required = false,
  error = null
}) => {
  const { translate } = useContext(GlobalizationContext)
  const inputRef = useRef()

  const noMinInputLengthText = translate(
    noMinInputLengthTextProp || 'COMMONS:TYPE_AT_LEAST',
    { qty: minInputLength }
  )
  const noOptionsText = translate(noOptionsTextProp || 'NO_REGISTER_FOUND')

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = React.useState('')
  const request = useRef(null)
  const noMinInputLength = inputValue.length < minInputLength
  const [requestedOptions, setRequestedOptions] = useState([])

  const currValue = useRef(defaultValue)
  const watchedValue = useWatch({ name, control, defaultValue })
  currValue.current = watchedValue

  const defaultGetOptionLabel = useCallback(
    option => (typeof option === 'string' ? option : option.name),
    []
  )
  const getOptionLabel =
    AutocompleteProps?.getOptionLabel || defaultGetOptionLabel

  const defautGetOptionSelect = useCallback((item, value) => item === value, [])
  const getOptionSelected =
    AutocompleteProps?.getOptionSelected || defautGetOptionSelect

  const renderOption = useCallback(
    (option, state) => {
      return (
        <Option
          multiple={multiple}
          allowClear={allowClear}
          option={option}
          state={state}
          getOptionLabel={getOptionLabel}
          optionRenderer={optionRenderer}
        />
      )
    },
    [optionRenderer, getOptionLabel, allowClear, multiple]
  )

  const autocompleteProps = useMemo(() => {
    return {
      ...{
        ListboxComponent: OptionList,
        ListboxProps: {
          noMinInputLength
        },
        disableClearable: true,
        blurOnSelect: true,
        filterOptions: createFilterOptions({
          keepSelected:
            typeof keepSelected !== 'undefined' ? keepSelected : true,
          value: currValue,
          multiple,
          getOptionSelected
        }),
        renderOption,
        getOptionLabel
      },
      ...(AutocompleteProps || {}),
      ...{ getOptionSelected, getOptionLabel }
    }
  }, [
    AutocompleteProps,
    keepSelected,
    getOptionLabel,
    getOptionSelected,
    multiple,
    noMinInputLength,
    renderOption
  ])

  const fetch = useMemo(
    () =>
      throttle(async (requestParams, callback) => {
        try {
          const response = await optionGetter(requestParams)
          callback(response)
        } catch {
          callback([])
        }
      }, 300),
    [optionGetter]
  )

  React.useEffect(() => {
    if (!open) {
      return undefined
    }

    let newOptions = []

    if (watchedValue) {
      newOptions = [].concat(watchedValue)
    }

    if (requestedOptions.length) {
      const filteredResults = requestedOptions.filter(item => {
        return newOptions.reduce((acc, currItem) => {
          return acc && !autocompleteProps.getOptionSelected(item, currItem)
        }, true)
      })
      newOptions = [].concat(newOptions, ...filteredResults)
    }

    setOptions([].concat(newOptions))
  }, [open, watchedValue, requestedOptions, autocompleteProps])

  React.useEffect(() => {
    if (!open) return undefined

    let active = true

    if (request.current?.cancel) {
      request.current.cancel()
    }

    setRequestedOptions([])
    if (noMinInputLength) return
    setLoading(true)

    request.current = fetch({ input: inputValue }, results => {
      if (active) {
        request.current = null

        let newItem = []
        if (createNew && inputValue) {
          newItem = [
            {
              [createNewLabelKey || 'name']: createNewLabelRenderer(inputValue),
              inputValue,
              isNew: true
            }
          ]
        }

        setRequestedOptions([].concat(newItem, results))
        setLoading(false)
      }
    })

    return () => {
      active = false
    }
  }, [open, fetch, inputValue, noMinInputLength])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const handleOnChange = ({ evt, option, reason, controllerOnChange }) => {
    if (reason === 'remove-option' && evt.type !== 'click') return

    if (autocompleteProps.onChange) {
      autocompleteProps.onChange(evt, option, controllerOnChange, createNew)
    } else if (option.isNew) {
      controllerOnChange(createNew(option.inputValue))
    } else {
      controllerOnChange(option)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      onFocus={() => {
        return inputRef.current?.focus()
      }}
      render={({ onChange: controllerOnChange, ...controlProps }) => (
        <CustomAutocomplete
          {...controlProps}
          {...autocompleteProps}
          ListboxProps={{
            ...autocompleteProps.ListboxProps,
            noMinInputLengthText,
            noOptionsText,
            loading
          }}
          multiple={multiple}
          onChange={(evt, option, reason) =>
            handleOnChange({ evt, option, reason, controllerOnChange })
          }
          disabled={disabled}
          options={options}
          loading={loading}
          loadingText={<Loader />}
          onOpen={() => setOpen(true)}
          onClose={() => {
            setOpen(false)
            setInputValue('')
          }}
          onInputChange={(event, newInputValue, reason) => {
            if (keepInputOnSelect && reason === 'reset') {
              return
            }
            setInputValue(newInputValue)
          }}
          noOptionsText={
            noMinInputLength ? noMinInputLengthText : noOptionsText
          }
          renderInput={({ inputProps: renderProps, ...params }) => {
            const { value, ref, ...inputProps } = renderProps

            const computedValue = clearOnClose
              ? inputValue
              : inputValue || value

            return (
              <BaseTextField
                {...params}
                errors={{ [name]: error }}
                id={name}
                required={required}
                value={computedValue}
                inputRef={newRef => {
                  ref.current = newRef
                  inputRef.current = newRef
                }}
                label={label}
                placeholder={placeholder}
                inputProps={{
                  ...inputProps,
                  autoComplete: 'nope'
                }}
              />
            )
          }}
        />
      )}
    />
  )
}

export default Autocomplete
