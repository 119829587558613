import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
  Grid,
  Link as MuiLink,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { generatePrivatePath } from 'helpers/history'

import ContentContainer from 'ui/atoms/ContentContainer'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import TagsList from 'ui/atoms/TagsList'
import { Subtitle, Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'
import Carroussel from 'ui/molecules/commons/Carroussel'
import useStyles from 'ui/styles'

import {
  MultiuserHint,
  PCustom,
  Text,
  StyledTableRow
} from 'views/EquipmentDetail/style'

function ViewEquipment({ equipment }) {
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  // const
  const RowList = useMemo(
    () =>
      equipment.parameterList
        ? equipment.parameterList.map((parameter, index) => {
            return (
              <StyledTableRow key={parameter.id}>
                <TableCell scope="row" align="center">
                  {index + 1}
                </TableCell>
                <TableCell scope="row" align="left">
                  {parameter.name}
                </TableCell>
                <TableCell scope="row" align="left">
                  {parameter.value}
                </TableCell>
                <TableCell scope="row" align="left">
                  {parameter.unit}
                </TableCell>
              </StyledTableRow>
            )
          })
        : null,
    [equipment.parameterList]
  )

  const imageList = useMemo(() => {
    return (equipment.imageList || []).map(({ path }) => path)
  }, [equipment.imageList])

  const handleBack = () => {
    window.history.back()
  }

  return (
    <ContentContainer>
      <Title item xs={12} onBack={handleBack} style={{ marginTop: '20px' }}>
        {translate('TITLE')}
      </Title>
      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        {imageList.length > 0 && (
          <Grid item xs={12}>
            <Carroussel
              images={imageList}
              dots={true}
              infinite={true}
              slidesToShow={3}
            />
          </Grid>
        )}

        <Title
          item
          xs={12}
          $noPaddingBottom
          $paddingTop
          action={
            <Typography variant="h4" noWrap>
              {`${translate('COMMONS:LAST_UPDATED')}: ${
                equipment.updatedDate || '-'
              }`}
            </Typography>
          }
        >
          {equipment.name || ''}
        </Title>

        {equipment.multiuserIndex ? (
          <Grid item xs={12}>
            <Typography variant="h4" style={{ display: 'flex' }}>
              <MultiuserHint content="MULTIUSER_HINT" />
              {translate('MULTIUSER')}
            </Typography>
          </Grid>
        ) : null}

        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ paddingBottom: '30px' }}
        >
          <Subtitle item xs={12}>
            {translate('ABOUT')}
          </Subtitle>

          <Grid item xs={12}>
            <Text>{equipment.about}</Text>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ paddingBottom: '30px' }}
        >
          <Subtitle item xs={12}>
            {translate('ORIGIN')}
          </Subtitle>

          <Grid item xs={12}>
            <PCustom>
              <MuiLink
                component={Link}
                variant="body2"
                to={generatePrivatePath(
                  `/laboratory/${equipment.laboratory.id}`
                )}
              >
                {equipment.laboratory.initials}
              </MuiLink>
            </PCustom>
            <Text>{equipment.laboratory.name}</Text>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ paddingBottom: '30px' }}
        >
          <Subtitle item xs={12}>
            {translate('DATA')}
          </Subtitle>

          <Grid item xs={12}>
            <PCustom>{translate('BRAND')}</PCustom>
            <Text>{equipment.brand}</Text>
          </Grid>
          <Grid item xs={12}>
            <PCustom>{translate('MODEL')}</PCustom>
            <Text>{equipment.model}</Text>
          </Grid>

          {equipment.manufacturer ? (
            <Grid item xs={12}>
              <PCustom>{translate('MANUFACTURER')}</PCustom>
              <Text>{equipment.manufacturer}</Text>
            </Grid>
          ) : null}

          {equipment.manufacturerLink ? (
            <Grid item xs={12}>
              <PCustom>{translate('MANUFACTURER_LINK')}</PCustom>
              <Text>{equipment.manufacturerLink}</Text>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <PCustom>{translate('PURCHASEYEAR')}</PCustom>
            <Text>{equipment.yearPurchase}</Text>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ paddingTop: '24px', paddingBottom: '30px' }}
        >
          <Grid item xs={12}>
            <PCustom>{translate('CODE_PLATFORM')}:</PCustom>
            <Text>{equipment.code}</Text>
          </Grid>

          {equipment?.denominationList?.length ? (
            <Grid item xs={12}>
              <PCustom>{translate('OTHER_DENOMANATION')}:</PCustom>
              <Text>
                {equipment.denominationList.map(item => item.name).join(', ')}
              </Text>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <PCustom>{translate('SITUATION')}:</PCustom>
            <Text>
              {translate(`EQUIPMENT:SITUATION.${equipment.situation}`)}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <PCustom>{translate('COMMONS:CREATION_DATE')}:</PCustom>
            <Text>{equipment.createdDate}</Text>
          </Grid>
        </Grid>

        {equipment.techniqueList && (
          <Grid item container xs={12} style={{ marginBottom: '25px' }}>
            <TagsList
              title="TECHINIQUE_LIST"
              list={equipment.techniqueList}
              customGrid={12}
              style={{ paddingLeft: '27px' }}
            />
          </Grid>
        )}

        {equipment.financingList.length ? (
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingBottom: '30px' }}
          >
            <Subtitle item xs={12}>
              {translate('FINANCING')}
            </Subtitle>

            {equipment.financingList.map(item => (
              <Grid item xs={12} key={item.id}>
                <PCustom>{item.name}</PCustom>
                {item.customDescription ? (
                  <Text>{item.customDescription}</Text>
                ) : null}
              </Grid>
            ))}
          </Grid>
        ) : null}

        {equipment?.parameterList?.length ? (
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingBottom: '30px' }}
          >
            <Subtitle item xs={12}>
              {translate('PARAMETER')}
            </Subtitle>
            <Grid item xs={12}>
              <div style={{ marginLeft: '27px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: '2%',
                          paddingLeft: '15px',
                          paddingRight: '15px'
                        }}
                        align="center"
                      >
                        #
                      </TableCell>
                      <TableCell align="left">
                        {translate('REGISTER_EQUIPMENT_PARAMETERS_NAME')}
                      </TableCell>
                      <TableCell align="left">
                        {translate('REGISTER_EQUIPMENT_PARAMETERS_VALUE')}
                      </TableCell>
                      <TableCell align="left" colSpan={2}>
                        {translate('REGISTER_EQUIPMENT_PARAMETERS_UNIT')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{RowList}</TableBody>
                </Table>
              </div>
            </Grid>
          </Grid>
        ) : null}

        {equipment.contact && (
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{ paddingBottom: '30px' }}
          >
            <Subtitle item xs={12}>
              {translate('EDIT_LABORATORY_CONTACT')}
            </Subtitle>
            <Grid item xs={12}>
              <PCustom>{translate('EDIT_LABORATORY_RESPONSIBLE')}:</PCustom>
              <Text>{equipment.contact.name}</Text>
            </Grid>
            <Grid item xs={12}>
              <PCustom style={{ width: '89px' }}>
                {translate('EDIT_LABORATORY_TELEPHONE')}:
              </PCustom>
              <Text>{equipment.contact.phone}</Text>
            </Grid>
            {equipment.email && (
              <Grid item xs={12}>
                <PCustom style={{ width: '89px' }}>
                  {translate('EDIT_LABORATORY_EMAIL')}:
                </PCustom>
                <Text>{equipment.email}</Text>
              </Grid>
            )}
            {equipment.website && (
              <Grid item xs={12}>
                <PCustom style={{ width: '89px' }}>
                  {translate('EDIT_LABORATORY_WEBSITE')}:
                </PCustom>
                <Text>{equipment.website}</Text>
              </Grid>
            )}
          </Grid>
        )}

        <Grid
          container
          item
          xs={12}
          spacing={2}
          style={{ paddingBottom: '30px' }}
        >
          <Subtitle item xs={12}>
            {translate('USAGE_PROFILE')} {/* Perfil de uso do equipamento */}
          </Subtitle>
          <Grid item xs={12}>
            <PCustom>{translate('INTERNAL_PROJECTS')}:</PCustom>
            <Text>{equipment.availabilityIndex}%</Text>
          </Grid>
          <Grid item xs={12}>
            <PCustom>{translate('MULTIUSER_INDEX')}:</PCustom>
            <Text>{equipment.multiuserIndex}%</Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={2}
        style={{ paddingBottom: '30px' }}
        justify="flex-end"
      >
        <Button
          type={'button'}
          variant="contained"
          className={classes.baseButtonGrey}
          onClick={handleBack}
          disabled={false}
        >
          {translate('COMMONS:GOBACK')}
        </Button>
      </Grid>
    </ContentContainer>
  )
}

export default wrapper(ViewEquipment, {
  namespace: 'PUBLIC_EQUIPMENT_DETAILS'
})
