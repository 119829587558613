import React from 'react'

import Icon from '@material-ui/core/Icon'

import { TypeList, TypeItem } from './style'

const ListType = ({ listType, onListTypeChange: handleListTypeChange }) => {
  return (
    <TypeList>
      <TypeItem
        $active={listType === 'LIST'}
        onClick={() => handleListTypeChange('LIST')}
      >
        <Icon className="fas fa-list" />
      </TypeItem>
      <TypeItem
        $active={listType === 'GRID'}
        onClick={() => handleListTypeChange('GRID')}
      >
        <Icon className="fas fa-border-all" />
      </TypeItem>
    </TypeList>
  )
}

export default ListType
