import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Header from 'ui/organisms/Header/Public'

import Breadcrumb from './Fragments/Breadcrumbs'
import Footer from './Fragments/Footer'
import { PUBLIC_ROUTES } from './routes'

const PublicRoutes = () => {
  return (
    <>
      <Header />
      <Breadcrumb />
      <Switch>
        {PUBLIC_ROUTES.map((route, key) => (
          <Route key={key} {...route} />
        ))}
      </Switch>
      <Footer />
    </>
  )
}

export default PublicRoutes
