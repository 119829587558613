import React from 'react'

import SvgIcon from '../svgIcon'
import { ReactComponent as iconRingingBell } from './RingingBell.svg'

const IconCopy = props => (
  <SvgIcon component={iconRingingBell} viewBox="0 0 16 19" {...props} />
)

export default IconCopy
