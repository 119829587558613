export default [
  {
    id: 'HIGH_SCHOOL_TECHNICAL_PROFESSIONAL',
    name: 'ACADEMIC_EDUCATION:HIGH_SCHOOL_TECHNICAL_PROFESSIONAL'
  },
  { id: 'GRADUATION', name: 'ACADEMIC_EDUCATION:GRADUATION' },
  { id: 'SPECIALIZATION', name: 'ACADEMIC_EDUCATION:SPECIALIZATION' },
  { id: 'MASTERS', name: 'ACADEMIC_EDUCATION:MASTERS' },
  {
    id: 'PROFESSIONAL_MASTERS',
    name: 'ACADEMIC_EDUCATION:PROFESSIONAL_MASTERS'
  },
  { id: 'DOCTORATE', name: 'ACADEMIC_EDUCATION:DOCTORATE' },
  { id: 'POSTDOC', name: 'ACADEMIC_EDUCATION:POSTDOC' }
]
