import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'

import { history, generatePrivatePath } from 'helpers/history'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import { Subtitle } from 'ui/atoms/Title'

import { REQUEST_SHARING_ROUTES } from 'views/BackOffice/Subviews/RequestSharing/constants/routes'

const PendingApproval = ({ sharingRequest }) => {
  const { translate } = useContext(GlobalizationContext)
  const { id } = useParams()

  const goToAnalyzeRequest = () => {
    history.push(
      generatePrivatePath(REQUEST_SHARING_ROUTES.ANALYZE, {
        pathParams: { id }
      })
    )
  }

  return (
    <>
      <Grid container spacing={2}>
        <Subtitle
          item
          xs={12}
          action={
            !sharingRequest.isRequester ? (
              <Button
                variant="contained"
                color="primary"
                onClick={goToAnalyzeRequest}
              >
                {translate('DETAILS.PENDING_APPROVAL.ANALYZE')}
              </Button>
            ) : null
          }
        >
          {translate('DETAILS.PENDING_APPROVAL.TITLE')}
        </Subtitle>

        <Grid item xs={12}>
          <TableContainer>
            <Table style={{ maxWidth: '600px' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '10px' }}>#</TableCell>
                  <TableCell>
                    {translate('DETAILS.PENDING_APPROVAL.RESPONSIBLE_NAME')}
                  </TableCell>
                  <TableCell>
                    {translate('DETAILS.PENDING_APPROVAL.RESPONSIBLE_EMAIL')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sharingRequest.responsibleList.map((responsible, index) => (
                  <TableRow key={responsible.email}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{responsible.name}</TableCell>
                    <TableCell>{responsible.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default PendingApproval
