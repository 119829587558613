import clsx from 'clsx'
import React from 'react'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'

import DefaultHint from 'ui/molecules/commons/DefaultHint'

const useStyles = makeStyles(theme => ({
  baseHeaderText: {
    marginBottom: '0rem',
    marginTop: '0rem',
    fontSize: '20px'
  },
  baseHeader: {
    paddingTop: '2rem',
    paddingBottom: '2rem'
  },
  baseHeadLine: {
    backgroundColor: '#009B7F',
    color: '#009B7F',
    height: '1px',
    border: 'none'
  },
  baseCloseButton: {
    padding: '0px'
  }
}))

const classBox = {
  display: 'flex',
  flexGrow: 0,
  alignItems: 'baseline',
  flexShrink: 1,
  width: '100%',
  maxWidth: '450px',
  justifyContent: 'flex-end'
}

function BaseContainerHeadeder(props) {
  const {
    label,
    showCloseButton,
    filter,
    hint,
    onClose = () => {},
    className,
    ref,
    ...rest
  } = props
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.baseHeader, className)}
      {...rest}
      ref={ref}
      tabIndex={-1}
    >
      <Box display="flex" style={{ alignItems: 'flex-end' }}>
        <Box flexGrow={1}>
          <h3 className={classes.baseHeaderText}>{label}</h3>
        </Box>
        {filter ? <Box style={classBox}>{filter}</Box> : null}
        {hint ? (
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <DefaultHint content={hint} />
          </div>
        ) : null}
        {showCloseButton && (
          <Box flexGrow={0} flexShrink={1}>
            <IconButton
              aria-label="close"
              className={classes.baseCloseButton}
              onClick={onClose}
            >
              <CancelIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <hr className={classes.baseHeadLine} />
    </div>
  )
}
export default BaseContainerHeadeder
