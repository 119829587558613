import React, { useContext } from 'react'

import { GlobalizationContext } from 'ui/atoms/Globalization'

import {
  BreadcrumbSeparator,
  BreadcrumbItem,
  BreadCrumbs,
  BreadcrumbItemLink
} from './style'

export default function BreadcrumbBuilder(fixedText) {
  const crumbs = [].concat(fixedText).map(item => ({ text: item }))

  const Breadcrumb = ({ match, item }) => {
    const { translate } = useContext(GlobalizationContext)

    return (
      <BreadCrumbs separator={<BreadcrumbSeparator />}>
        {crumbs.map((crumb, index) =>
          React.createElement(
            item,
            { key: `${index}_${match.url}`, to: match.url },
            translate(crumb.text)
          )
        )}
      </BreadCrumbs>
    )
  }

  return Breadcrumb
}
