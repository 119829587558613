export const ADMIN_ROUTES = {
  ADMIN: '/admin',
  USERS: '/admin/users',
  USERS_EDIT: '/admin/users/:userId',
  INSTITUTIONS: '/admin/institutions',
  INSTITUTIONS_EDIT: '/admin/institutions/:institutionId',
  INSTITUTION_ANALYSIS: '/admin/institutions/:institutionId/analyze',
  LABORATORIES: '/admin/laboratories',
  LABORATORIES_EDIT: '/admin/laboratories/:laboratoryId'
}
