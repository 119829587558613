import React, { useContext } from 'react'

import { Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import { history } from 'helpers'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import BaseContainerHeader from 'ui/molecules/BaseContainerHeader'
import useStyles from 'ui/styles'

const InfoSection = () => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  const redirectToParticipate = () => {
    history.push(`/register`)
  }

  return (
    <Grid container spacing={3} style={{ marginTop: '40px' }}>
      <Grid item xs={12} md={6}>
        <BaseContainerHeader
          label={translate('INFO_SECTION_TITLE')}
          showCloseButton={false}
        />
        <Typography variant="body2">
          {translate('INFO_SECTION_INTRO_TEXT')}
        </Typography>
        <Button
          variant="contained"
          className={classes.baseButton}
          style={{ float: 'left', marginTop: '40px' }}
          onClick={redirectToParticipate}
        >
          {translate('BE_PART_BUTTON')}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/RvyyaED3Pr8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write;
encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Grid>
    </Grid>
  )
}

export default InfoSection
