import React, { useState, useContext, useCallback, useEffect } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import { useDropzone } from 'react-dropzone'
import ReactCrop from 'react-image-crop'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import AddIcon from '@material-ui/icons/AddAPhoto'

import { fileUploadConfig } from 'helpers/constants'

import { GlobalizationContext } from 'ui/atoms/Globalization'
import useStyles from 'ui/styles'

import { DropContainer, Text, ErrorContainer } from './style'

const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement('canvas')
  const width = (image.naturalWidth * crop.width) / 100
  const height = (image.naturalHeight * crop.height) / 100
  const x = (image.naturalWidth * crop.x) / 100
  const y = (image.naturalHeight * crop.y) / 100
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')

  ctx.drawImage(image, x, y, width, height, 0, 0, width, height)

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        //reject(new Error('Canvas is empty'));
        console.error('Canvas is empty')
        return
      }
      blob.name = fileName
      resolve(blob)
    }, 'image/png')
  })
}

const ASPECT_RATIO = 1
const DEFAULT_CROP = { unit: '%', width: 100, aspect: ASPECT_RATIO }

const filesConfig = fileUploadConfig.myProfile
const extensions = Object.keys(filesConfig.extensions)
const accept = extensions.length ? extensions.join(', ') : '*/*'
const acceptAll = accept.indexOf('*/*') >= 0
const extensionsName = extensions.map(extension => {
  return extension.replace('image/', '').replace('application/', '')
})

const ChangePictureDialog = ({
  src,
  open,
  onPictureSelected: handlePictureSelected,
  onClose: handleClose
}) => {
  const classes = useStyles()
  const { translate } = useContext(GlobalizationContext)

  const [imgSrc, setImgSrc] = useState(null)
  const [crop, setCrop] = useState({ ...DEFAULT_CROP })
  const [currentImg, setCurrentImg] = useState(null)

  const [maxFilesExceededError, setMaxFilesExceededError] = useState(false)
  const [fileMaxSizeExceededError, setFileMaxSizeExceededError] =
    useState(false)
  const [fileExtensionError, setFileExtensionError] = useState(false)

  useEffect(() => {
    setMaxFilesExceededError(false)
    setFileMaxSizeExceededError(false)
    setFileExtensionError(false)
  }, [open])

  useEffect(() => {
    setImgSrc(src || null)
  }, [src, open])

  const checkFilesValidity = useCallback(selectedFiles => {
    const files = selectedFiles.filter(file => {
      return acceptAll || extensions.indexOf(file.type) >= 0
    })

    if (files.length != selectedFiles.length) {
      setFileExtensionError(true)
      return false
    }

    if (files && files.length) {
      if (files.length > filesConfig.maxFiles) {
        setMaxFilesExceededError(true)
        return false
      }

      const fileList = [...files]

      const biggerThanAllowedList = fileList.filter(file => {
        return filesConfig.extensions[file.type] < file.size
      })

      if (biggerThanAllowedList.length) {
        setFileMaxSizeExceededError(true)
        return false
      }

      return true
    }
  }, [])

  const onFilesSelected = useCallback(
    selectedFiles => {
      setMaxFilesExceededError(false)
      setFileMaxSizeExceededError(false)
      setFileExtensionError(false)

      const isValid = checkFilesValidity(selectedFiles)
      if (!isValid) return

      const objectUrl = URL.createObjectURL(selectedFiles[0])
      setImgSrc(objectUrl)
    },
    [checkFilesValidity]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFilesSelected
  })

  const onImageLoaded = image => {
    const width =
      image.width / ASPECT_RATIO < image.height * ASPECT_RATIO
        ? 100
        : ((image.height * ASPECT_RATIO) / image.width) * 100
    const height =
      image.width / ASPECT_RATIO > image.height * ASPECT_RATIO
        ? 100
        : (image.width / ASPECT_RATIO / image.height) * 100
    const y = (100 - height) / 2
    const x = (100 - width) / 2

    setCrop({
      unit: '%',
      x,
      y,
      ASPECT_RATIO,
      ...DEFAULT_CROP
    })

    setCurrentImg(image)
    return false // Return false when setting crop state in here.
  }

  const onCropChange = (crop, percentCrop) => {
    setCrop(percentCrop)
  }

  const onClose = () => {
    handleClose()
  }

  const savePicture = useCallback(async () => {
    const newImage = await getCroppedImg(currentImg, crop, 'newImage.png')

    handlePictureSelected({
      tempUrl: URL.createObjectURL(newImage),
      blob: newImage
    })
  }, [crop, currentImg, handlePictureSelected])

  const hasError =
    maxFilesExceededError || fileMaxSizeExceededError || fileExtensionError

  return (
    <Dialog fullWidth open={open}>
      <DialogContent style={{ padding: '16px 16px 8px 16px' }}>
        {imgSrc && (
          <ReactCrop
            style={{ width: '100%' }}
            imageStyle={{ width: '100%' }}
            src={imgSrc}
            crossorigin="anonymous"
            crop={crop}
            ruleOfThirds
            keepSelection
            circularCrop
            onImageLoaded={onImageLoaded}
            onChange={onCropChange}
          />
        )}

        <DropContainer {...getRootProps()}>
          <input
            id="file"
            type="file"
            style={{ display: 'none' }}
            {...getInputProps({
              multiple: filesConfig.maxFiles > 1,
              accept: accept
            })}
          />
          <Text>{translate('DROP_OR_CLICK_TO_CHANGE')}</Text>
          <AddIcon />
        </DropContainer>
        {hasError && (
          <ErrorContainer>
            <FormHelperText error style={{ fontSize: '14px', fontWeight: 500 }}>
              {maxFilesExceededError
                ? translate('FILE_UPLOAD_MAX_LIMIT_REACHED', {
                    number: filesConfig.maxFiles
                  })
                : fileMaxSizeExceededError
                ? translate('FILE_UPLOAD_MAX_SIZE_LIMIT_REACHED')
                : fileExtensionError
                ? translate('FILE_UPLOAD_INVALID_EXTENSION', {
                    extensions: extensionsName.join(', ')
                  })
                : null}
            </FormHelperText>
          </ErrorContainer>
        )}
      </DialogContent>
      <DialogActions style={{ padding: '8px 16px 16px 16px' }}>
        <Button className={classes.baseButtonGrey} onClick={onClose}>
          {translate('CANCEL')}
        </Button>
        <Button className={classes.baseButton} onClick={savePicture}>
          {translate('CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangePictureDialog
