import axios from 'axios';

const defaultAxios = axios.create({
    baseURL: 'https://api.mapbox.com',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*"
    }    
})

defaultAxios.interceptors.request.use(async function (config) {

    return config;
  }, function (error) {
    console.error('error', error)
    // Do something with request error
    return Promise.reject(error);
  });

export default defaultAxios