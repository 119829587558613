import axios from './axios'

export const SharingService = {
  getAll({ pageSize, pageNumber, filter }, requestOptions) {
    return axios.get('/request-sharing', {
      params: {
        page: pageNumber,
        size: pageSize,
        ...filter
      },
      ...{ requestOptions }
    })
  },

  getById(id, requestOptions = {}) {
    return axios
      .get(`/request-sharing/${id}`, {
        ...requestOptions
      })
      .then(resp => resp.data)
  },

  getLastPendingCompletionAnalysis(id, requestOptions = {}) {
    return axios
      .get(`/request-sharing/${id}/last-pending-completion-analysis`, {
        ...requestOptions
      })
      .then(resp => resp.data)
  },

  getSituation(id, requestOptions = {}) {
    return axios
      .get(`/request-sharing/${id}/situation`, {
        ...requestOptions
      })
      .then(resp => resp.data)
  },

  getRefusalReasons(config = {}) {
    return axios.get(`reason-refuse-sharing`, config).then(resp => resp.data)
  },

  getSecurityHazards(config = {}) {
    return axios
      .get(`request-sharing/security-hazard`, config)
      .then(resp => resp.data)
  },

  getByLabId(id, config = {}) {
    return axios
      .get(`request-sharing/lab/${id}`, config)
      .then(resp => resp.data)
  },

  getByEquipId(id, config = {}) {
    return axios
      .get(`request-sharing/equip/${id}`, config)
      .then(resp => resp.data)
  },

  getLab(term, config = {}) {
    return axios
      .get(`request-sharing/lab-term`, { ...config, params: { term } })
      .then(resp => resp.data)
  },

  getInstitution(term, config = {}) {
    return axios
      .get(`request-sharing/inst-term`, { ...config, params: { term } })
      .then(resp => resp.data)
  },

  postSharing(requestData) {
    return axios.post(`request-sharing`, requestData).then(resp => resp.data)
  },

  putSharing(id, requestData) {
    return axios
      .put(`request-sharing/${id}`, requestData)
      .then(resp => resp.data)
  },

  uploadMDS(id, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(`/request-sharing/${id}/msds-document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  uploadAdditionalInfo(id, file) {
    var formData = new FormData()
    formData.append('file', file)

    return axios.post(`/request-sharing/${id}/additional-document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  deleteDocument(id) {
    return axios.delete(`/request-sharing/document/${id}`)
  },

  refuseSharing(id, requestData) {
    return axios
      .put(`/request-sharing/${id}/refuse`, requestData)
      .then(resp => resp.data)
  },

  approveSharing(id, requestData) {
    return axios
      .put(`/request-sharing/${id}/approve`, requestData)
      .then(resp => resp.data)
  },

  editApproval(id, requestData) {
    return axios
      .put(`/request-sharing/${id}/analysis`, requestData)
      .then(resp => resp.data)
  },

  confirmUsage(id) {
    return axios
      .put(`/request-sharing/${id}/confirm-usage`)
      .then(resp => resp.data)
  },

  declareUsage(id, requestData) {
    return axios
      .put(`/request-sharing/${id}/declare-usage`, requestData)
      .then(resp => resp.data)
  },

  cancelRequest(id, requestData) {
    return axios
      .put(`/request-sharing/${id}/cancel`, requestData)
      .then(resp => resp.data)
  },

  delete(id) {
    return axios.delete(`/request-sharing/${id}`)
  },

  getUnreadCount(id, config) {
    return axios
      .get(`request-sharing/${id}/message/count`, {
        ...config
      })
      .then(resp => resp.data.count)
  },

  getMessagesBefore(id, date, config) {
    return axios
      .get(`request-sharing/${id}/message-before-date`, {
        ...config,
        params: { date }
      })
      .then(resp => resp.data)
  },

  getMessagesAfter(id, date, config) {
    return axios
      .get(`request-sharing/${id}/message-after-date`, {
        ...config,
        params: { date }
      })
      .then(resp => resp.data)
  },

  sendMessage(id, message, config) {
    return axios
      .post(`request-sharing/${id}/message`, { message }, { ...config })
      .then(resp => resp.data)
  }
}

export default SharingService
