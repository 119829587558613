import React, { useState } from 'react'

import { Box, Grid } from '@material-ui/core'

import { useGlobalizationContext } from 'ui/atoms/Globalization'
import { Tab, Tabs } from 'ui/atoms/Tabs'
import { Title } from 'ui/atoms/Title'
import wrapper from 'ui/atoms/Wrapper'

import RegisterLab from 'views/BackOffice/Subviews/LaboratoryRegister'

import Situation from './Fragments/Situation'

const ShowSituation = ({ laboratoryData }) => {
  const { translate } = useGlobalizationContext()
  const { situation } = laboratoryData?.laboratory

  const [currTab, setCurrTab] = useState('SITUATION')

  const handleBack = () => {
    window.history.back()
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        height: '100%'
      }}
    >
      <Box>
        <Grid container>
          <Title item xs={12} $paddingTop onBack={handleBack}>
            {translate(`TITLE.${situation}`)}
          </Title>
        </Grid>
      </Box>

      <Box pb={2}>
        <Tabs onChange={(event, newTab) => setCurrTab(newTab)} value={currTab}>
          <Tab label={translate('TABS.SITUATION')} value={'SITUATION'} />
          <Tab label={translate('TABS.SOLICITATION')} value={'SOLICITATION'} />
        </Tabs>
      </Box>

      <Box flex="1 1 100%">
        {{
          SITUATION: <Situation {...{ laboratoryData }} />,
          SOLICITATION: <RegisterLab readOnly hideHeaders />
        }[currTab] || null}
      </Box>
    </Box>
  )
}

export default wrapper(ShowSituation, { namespace: 'LABORATORY_SITATION' })
