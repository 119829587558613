import moment from 'moment'
import React, { useState, useContext } from 'react'

import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import AdvertiseService from 'api/AdvertisePlatform'
import { primaryColor } from 'helpers/constants'

import AtomSpinner from 'ui/atoms/AtomSpinner'
import BaseTextField from 'ui/atoms/BaseTextField'
import { GlobalizationContext } from 'ui/atoms/Globalization'
import { NoResults } from 'ui/atoms/Table'
import { Title } from 'ui/atoms/Title'
import Pagination from 'ui/molecules/commons/Pagination'
import useStyles from 'ui/styles'

import { notificationUseCases } from 'views/Notification/providers'

import ButtonResend from './ButtonResend'

const AdvertiseAgain = props => {
  const {
    advertiseSentList = [],
    filterValue,
    onFilterChange: handleFilterChange,
    count = 0,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    isEmpty,
    isLoading = false
  } = props
  const { translate } = useContext(GlobalizationContext)
  const classes = useStyles()

  const [sentItems, setSentItems] = useState([])

  const handleResendClick = id => {
    AdvertiseService.resendById(id)
      .then(() => {
        notificationUseCases.newSuccess(translate('INVITE_RESEND_SUCCESS'))
        setSentItems(sentItems => [...sentItems, id])
      })
      .catch(() => {
        notificationUseCases.newError(translate('INVITE_RESEND_ERROR'))
      })
  }

  return (
    <Grid container spacing={2} style={{ paddingTop: '2rem' }}>
      <Title item xs={12} variant="h3" $noBorder>
        {translate('INVITE_AGAIN_SUBTITLE')}
      </Title>

      <Grid item xs={12} md={6}>
        <BaseTextField
          fullWidth
          value={filterValue}
          onChange={e => handleFilterChange(e.target.value)}
          placeholder={translate('FILTER')}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        {!isLoading ? (
          isEmpty ? (
            <NoResults>{translate('NO_RESULTS')}</NoResults>
          ) : (
            <>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{translate('EMAIL')}</TableCell>
                      <TableCell>{translate('NAME')}</TableCell>
                      <TableCell>{translate('TYPE')}</TableCell>
                      <TableCell>{translate('INVITE_DATE')}</TableCell>
                      <TableCell align="center" width="10px">
                        {translate('RESEND_INVITE')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {advertiseSentList.map(item => (
                      <TableRow key={item.id}>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          {item.type === 'INSTITUTION'
                            ? translate('COMMONS:INSTITUTION')
                            : translate('COMMONS:LABORATORY')}
                        </TableCell>
                        <TableCell>
                          {moment(item.updatedDate).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell align="center">
                          <ButtonResend
                            id={item.id}
                            sent={sentItems.includes(item.id)}
                            onClick={handleResendClick}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
              />
            </>
          )
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="150px"
          >
            <AtomSpinner color={primaryColor} />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default AdvertiseAgain
