import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { primaryColor } from 'helpers/constants'

import AtomSpinner from 'ui/atoms/AtomSpinner'

const Overlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 100000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.13);
`

function Loader() {
  const requestCounter = useSelector(state => state.request.currentRequests)

  return (
    <>
      {requestCounter > 0 && (
        <Overlay>
          <AtomSpinner color={primaryColor} />
        </Overlay>
      )}
    </>
  )
}

export default Loader
